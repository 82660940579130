import { DistributionRoutes } from "../../../routes/routes";

export enum RedeferStep {
  DistributionElections = "Distribution elections",
  Review = "Review & submit",
  Confirmation = "Confirmation",
}

export interface RedeferRoutes {
  route: string;
  stepNumber: number;
  step: RedeferStep;
}

export const IndividualSteps: RedeferRoutes[] = [
  {
    stepNumber: 0,
    step: RedeferStep.DistributionElections,
    route: DistributionRoutes.RedeferIndividual,
  },
  {
    stepNumber: 1,
    step: RedeferStep.Review,
    route: DistributionRoutes.RedeferIndividualReview,
  },
  {
    stepNumber: 2,
    step: RedeferStep.Confirmation,
    route: DistributionRoutes.RedeferIndividualConfirm,
  },
];
