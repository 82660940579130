import { EnrollmentTools } from "../models/enrollment-tools/enrollment-tools";
import { get } from "../repositories/participant";

export async function getEnrollmentTools(
  planId: number,
  empId: number,
  role: string
): Promise<EnrollmentTools> {
  return get(`/plans/${planId}/employees/${empId}/enrollment-tools`, { role });
}
