import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "../../store/store";
import DebugCard from "./debug-card";

// DebugCard components, like this one, only show when ?debug=true is in the URL
export default function DebugHeader() {
  const { empId, planId, role } = useSelector((state) => state.session);
  const { contractId } = useSelector((state) => state.plan.info);
  const impersonated = localStorage.getItem("impersonated") === "true";
  const enterpriseId = localStorage.getItem("enterpriseId");
  const accessToken = localStorage.getItem("accessToken");
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const currentDate = new Date();
  const offset = currentDate.getTimezoneOffset();
  const localTime = currentDate.toLocaleTimeString();

  const impersonatedDisplay = impersonated
    ? "Impersonated"
    : "Not impersonated";
  return (
    <>
      <DebugCard>
        <p>
          <strong>USER</strong> | <span>Emp: {empId}</span> |{" "}
          <span>Plan: {planId}</span> | <span>Contract: {contractId}</span> |{" "}
          <span>Role: {role}</span> | <span>Enterprise: {enterpriseId}</span> |{" "}
          {impersonatedDisplay} | Token: {accessToken}
        </p>
        <p>
          <strong>LINKS</strong> |{" "}
          <Link to="/devtools">
            <i className="fa fa-link"></i> Devtools
          </Link>{" "}
          |{" "}
          <Link to="/accounts-landing">
            <i className="fa fa-link"></i> Accounts landing
          </Link>
        </p>
        <p>
          <strong>TIMEZONE</strong> | <span>Zone: {timeZone}</span> |{" "}
          <span>Local time: {localTime}</span> | <span>Offset: {offset}</span>
        </p>
      </DebugCard>
    </>
  );
}
