import React, { useEffect, useState } from "react";
import { ParticipantEvent } from "../../models/ParticipantEvent";
import { getMessages } from "../../services/enrollment";
import { useSelector } from "../../store/store";
import { customEnrollmentLink, enrollmentLink } from "../../util/constants";
import { Collapse } from "../collapse/collapse";
import { hideEnrollmentVideoLinkSelector } from "../../selectors/plan-info";

type Props = {
  event: ParticipantEvent;
};

export function OpenEventCard(props: Props) {
  const { empId, role, planId } = useSelector((state) => state.session);
  const isSeniorManagementPenskePlan = [22286].includes(planId);
  const { event } = props;
  const hideEnrollmentVideoLink = useSelector(hideEnrollmentVideoLinkSelector);

  const [enrollmentMessage, setEnrollmentMessage] = useState<string>("");
  const [participantLandingMessage, setParticipantLandingMessage] =
    useState<string>("");
  useEffect(() => {
    fetchEnrollmentMessage();
    fetchParticipantLandingMessage();

    async function fetchEnrollmentMessage() {
      const allPlanInfo = await getMessages(event.eventId, empId);
      if (allPlanInfo && allPlanInfo.enrollmentMessage) {
        setEnrollmentMessage(allPlanInfo.enrollmentMessage);
      } else {
        setEnrollmentMessage("");
      }
    }

    async function fetchParticipantLandingMessage() {
      const allPlanInfo = await getMessages(event.eventId, empId);
      if (allPlanInfo && allPlanInfo.participantLandingMessage) {
        setParticipantLandingMessage(allPlanInfo.participantLandingMessage);
      } else {
        setParticipantLandingMessage("");
      }
    }
  }, [empId, event.eventId]);

  function getEnrollmentRole(role: string) {
    switch (role) {
      case "MEMBER":
        return "participant";
      default:
        return role;
    }
  }

  function generateEnrollmentLink(): string {
    if (isSeniorManagementPenskePlan) {
      return customEnrollmentLink(event.eventId, empId, planId);
    } else {
      return enrollmentLink(event.eventId, empId, getEnrollmentRole(role));
    }
  }

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="panel panel-default">
          <div className="panel-heading-dashboard text-midnight">
            <p>{event.eventConfiguration.eventName}</p>
          </div>
          <div className="panel-body">
            <div dangerouslySetInnerHTML={{ __html: enrollmentMessage }} />
            <a data-testid={"enrollment-link"} href={generateEnrollmentLink()}>
              Enroll now <i className="fa fa-angle-right" aria-hidden="true" />
            </a>
            <Collapse text={participantLandingMessage} />
          </div>
          {!hideEnrollmentVideoLink && (
            <div className="panel-footer">
              <small>
                Wondering what to expect?{" "}
                <a
                  href="https://bcove.video/2WUzDkE"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Opens in a new window"
                >
                  Click here{" "}
                  <i className="fa fa-external-link" aria-hidden="true" />
                  <span className="sr-only">
                    for a video about online enrollment
                  </span>
                </a>{" "}
                to learn more
              </small>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
