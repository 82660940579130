import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AsyncStatus } from "../models/async-status";
import { getInvestmentAllocations } from "../services/investment-allocations";
import { AccountAllocation } from "../models/account-allocation";

export const fetchAllocations = createAsyncThunk(
  "fetch-investment-allocations",
  async (
    { planId, empId, role }: { planId: number; empId: number; role: string },
    thunkApi
  ) => {
    return getInvestmentAllocations({ planId, empId, role });
  }
);

type State = {
  allocations: AccountAllocation[];
  status: AsyncStatus;
};

const initialState: State = {
  allocations: [],
  status: AsyncStatus.not_fetched,
};

const allocationsSlice = createSlice({
  name: "allocations",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllocations.pending, (state, action) => {
      return { ...state, status: AsyncStatus.loading };
    });
    builder.addCase(
      fetchAllocations.fulfilled,
      (state, action: { payload: AccountAllocation[]; type: string }) => {
        const allocations = action.payload;

        return {
          ...state,
          allocations,
          status: AsyncStatus.done,
        };
      }
    );
    builder.addCase(fetchAllocations.rejected, (state, action) => {
      return { ...state, status: AsyncStatus.error };
    });
    builder.addDefaultCase((state, action) => state);
  },
});

export default allocationsSlice.reducer;
