import React, { useEffect, useState } from "react";
import { useSelector } from "../../../../store/store";
import { BeneficiaryState } from "../../../../reducers/beneficiary-reducer";
import {
  beneficiariesAreDesignated,
  hasValidAllocations,
  hasZeroPercentAllocations,
  validateBeneficiaryTypes,
} from "../../../../components/beneficiary/beneficiary-util";
import { BeneficiaryAlertText } from "../../../../models/beneficiaries/beneficiary-text";

type Props = {
  showErrors: boolean;
};
export default function BeneficiaryAlertDisplay(props: Props) {
  const { showErrors } = props;
  return (
    <>
      <BeneficiaryErrors showErrors={showErrors}></BeneficiaryErrors>
      <BeneficiaryAlert></BeneficiaryAlert>
    </>
  );
}

function BeneficiaryErrors(props: Props) {
  const { showErrors } = props;
  const { beneficiaries } = useSelector<BeneficiaryState>(
    (state) => state.beneficiaries
  );
  const [messages, setMessages] = useState<string[]>([]);
  const invalidAllocations: boolean = !hasValidAllocations(beneficiaries);
  const hasDesignatedBeneficiaries: boolean =
    beneficiariesAreDesignated(beneficiaries);
  const zeroPercentAllocations: boolean =
    hasZeroPercentAllocations(beneficiaries);

  useEffect(() => {
    const messages: string[] = [];
    if (hasDesignatedBeneficiaries) {
      const beneficiariesTypesValid: boolean =
        validateBeneficiaryTypes(beneficiaries);
      if (!beneficiariesTypesValid) {
        messages.push(BeneficiaryAlertText.NO_CONTINGENT_WITHOUT_PRIMARY);
      }
    } else {
      messages.push(BeneficiaryAlertText.NO_BENEFICIARIES);
    }
    if (invalidAllocations) {
      messages.push(BeneficiaryAlertText.INVALID_ALLOCATIONS);
    } else if (zeroPercentAllocations) {
      messages.push(BeneficiaryAlertText.ZERO_PERCENT_ALLOCATIONS);
    }

    setMessages(messages);
  }, [
    invalidAllocations,
    hasDesignatedBeneficiaries,
    beneficiaries,
    zeroPercentAllocations,
  ]);

  return (
    <>
      {showErrors &&
        messages.map((message) => {
          return (
            <div
              key={message}
              data-testid={"beneficiary-error-alert"}
              id="summary-bene-page-error"
              className="alert alert-danger"
              data-log="onload"
              role="alert"
            >
              <p
                data-testid={"error-message"}
                className="alert-danger alert-icon"
              >
                {message}
              </p>
            </div>
          );
        })}
    </>
  );
}

function BeneficiaryAlert() {
  const { hasChanged, hasAddedOrDeleted } = useSelector<BeneficiaryState>(
    (state) => state.beneficiaries
  );

  let message = "";
  if (hasChanged) {
    message = BeneficiaryAlertText.MADE_CHANGES;
  }
  if (hasAddedOrDeleted) {
    message = BeneficiaryAlertText.ADDED_OR_DELETED;
  }

  return hasChanged || hasAddedOrDeleted ? (
    <div
      data-testid={"beneficiary-alert"}
      id="summary-bene-page-alert"
      data-log="onload"
      className="alert alert-info alert-icon"
      role="alert"
    >
      <p data-testid={"alert-message"}>{message}</p>
    </div>
  ) : (
    <></>
  );
}
