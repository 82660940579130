import { ScheduledDistributionForRedeferral } from "../models/distributions/scheduled-distribution-event";
import { RedeferralInstructionDto } from "../models/redeferral/redeferral-instruction-dto";
import { RedeferralStatus } from "../models/redeferral/redeferral-status";
import { get, post } from "../repositories/participant";
import { MarketCloseDate } from "../models/market-close-date";

type Args = {
  planId: number;
  empId: number;
  role: string;
  accountId?: number;
};

type PostArgs = {
  planId: number;
  empId: number;
  role: string;
  accountId?: number;
  data: any;
};

export async function getRedeferralInstructionDTOsForAccount(
  role: string,
  accountId: number
): Promise<RedeferralInstructionDto[]> {
  return get(`/redeferralInstruction/account/${accountId}`, {
    role,
  });
}

export async function saveRedeferralInstructionForAccount({
  planId,
  empId,
  accountId,
  role,
  data,
}: PostArgs) {
  return post(
    `/redeferralInstruction/plan/${planId}/emp/${empId}/account/${accountId}`,
    { role },
    data
  );
}

export async function saveRedeferralInstructions({
  planId,
  empId,
  accountId,
  role,
  data,
}: PostArgs) {
  return post(
    `/redeferralInstruction/plan/${planId}/employee/${empId}/account/${accountId}`,
    { role },
    data
  );
}

export async function deleteRedeferralInstruction(
  role: string,
  instructionId: number
): Promise<any> {
  return post(`/redeferralInstruction/instruction/${instructionId}/delete`, {
    role,
  });
}

export async function getScheduledDistributionsForRedeferral({
  planId,
  empId,
  role,
  accountId,
}: Args): Promise<ScheduledDistributionForRedeferral[]> {
  return get(
    `/plans/${planId}/employees/${empId}/account/${accountId}/scheduled`,
    {
      role,
    }
  );
}

export async function getAccountRedeferralStatuses({
  planId,
  empId,
  role,
  accountId,
}: Args): Promise<RedeferralStatus[]> {
  return get(`/plans/${planId}/employees/${empId}/accounts/redeferralStatus`, {
    role,
  });
}

export async function getMarketCloseDates({
  planId,
  empId,
  role,
}: Args): Promise<MarketCloseDate[]> {
  return get(`/plans/${planId}/employees/${empId}/market-close-dates`, {
    role,
  });
}

export async function postRedeferralInstructions({
  planId,
  empId,
  accountId,
  role,
  data,
}: PostArgs) {
  return post(
    `/plans/${planId}/employees/${empId}/account/${accountId}`,
    {
      role,
    },
    data
  );
}
