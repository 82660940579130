import React from "react";
import { investmentAdvisorLink } from "../../util/constants";
import "./investment-advisor-link.scss";
import investmentInfoImage from "./investments-info.png";

function InvestmentAdvisorLink() {
  function openInvestmentAdvisor(event) {
    event.preventDefault();
    window.open(investmentAdvisorLink, "popup", "width=600,height=500");
  }

  React.useEffect(() => {
    // have to check that jquery is defined on window to satisfy eslint
    // before activating horizon.js tooltip plugin
    if (typeof window.$ !== "undefined") {
      window.$(".investment-advisor-link[data-toggle=tooltip]").tooltip();
    }
  }, []);

  return (
    <a
      href={investmentAdvisorLink}
      target="_blank"
      rel="noopener noreferrer"
      className="investment-advisor-link left-align btn btn-link btn-link-inline"
      onClick={openInvestmentAdvisor}
      data-toggle="tooltip"
      title="These are reference investments, for a definition see below."
    >
      <span className="show">Investment Advisor/</span>
      <span>Investment Option</span>
      <img
        className="investment-advisor-image"
        alt=""
        src={investmentInfoImage}
      />
    </a>
  );
}

export default InvestmentAdvisorLink;
