import React from "react";
import "./section-with-links.css";
import { SectionWithLink } from "./sectionWithLink";
import Icon from "../icon/icon";

export interface Props {
  links: SectionWithLink[];
  title: string;
  icon: string;
}

function SectionWithLinks(props: Props) {
  const { title, icon, links } = props;

  function displayLinks() {
    if (links.length > 1) {
      return multipleLinks();
    } else {
      return singleLink();
    }
  }

  function singleLink() {
    return links.map((link: SectionWithLink, index: number) => (
      <div
        key={index}
        data-testid="single-link-section"
        className="callout dashboard-callout bg-light-blue"
      >
        <p>{link.help_text}</p>
        <div>
          <a href={link.url} className="btn btn-primary">
            {link.name} <Icon name="arrow-right" />
          </a>
        </div>
      </div>
    ));
  }

  function multipleLinks() {
    return (
      <ul
        data-testid="multiple-link-section"
        className="onboarding-dashboard-links"
      >
        {links.map((link: SectionWithLink, index: number) => (
          <li key={index}>
            <a href={link.url}>
              {link.name}{" "}
              {link.isExternal ? <Icon name="external-link" /> : null}
            </a>
          </li>
        ))}
      </ul>
    );
  }

  return (
    <div className="util-margin-top-50 row">
      <div className="col-md-5">
        <div className="text-center text-midnight">
          <Icon name={`${icon} fa-3x`} />
          <h2 className="h3">{title}</h2>
        </div>
      </div>
      <div className="col-md-7">{displayLinks()}</div>
    </div>
  );
}

export default SectionWithLinks;
