import { property, query } from 'lit/decorators.js';
import { PdsGlobalHeaderDropdownItem } from '../global-header-dropdown-item/global-header-dropdown-item';
import { pdsCustomElement as customElement } from '../../decorators/pds-custom-element';
import styles from './global-header-user-menu-dropdown-view.scss?inline';

/**
 * @summary This component is a dropdown item that is only meant to be utilized within the global header. It should have all the functionalities
 * of a PdsGlobalHeaderDropdownItem but is styled differently.
 *
 * @slot label Required: The label for the user menu, restricted to pds-global-header-user-menu-label
 * @fires pds-global-header-user-menu-open A custom event dispatched on opening the user menu
 * @fires pds-global-header-user-menu-close A custom event dispatched on closing the user menu
 */
@customElement('pds-global-header-user-menu-dropdown-view', {
  category: 'component',
  type: 'component',
  styles,
})
export class PdsGlobalHeaderUserMenuDropdownView extends PdsGlobalHeaderDropdownItem {
  @property({ type: Boolean })
  declare current: false;

  @query('.pds-c-global-header-user-menu-dropdown-view__menu')
  declare menu: HTMLElement;

  @query('.pds-c-global-header-user-menu-dropdown-view__button')
  declare button: HTMLElement;

  firstUpdated(): void {
    super.firstUpdated();
    this.addEventListener(
      'pds-global-header-dropdown-item-open',
      (e: Event) => {
        e.stopPropagation();

        this.dispatchEvent(
          new CustomEvent('pds-global-header-user-menu-open', {
            bubbles: true,
            composed: true,
          }),
        );
      },
    );

    this.addEventListener(
      'pds-global-header-dropdown-item-close',
      (e: Event) => {
        e.stopPropagation();

        this.dispatchEvent(
          new CustomEvent('pds-global-header-user-menu-close', {
            bubbles: true,
            composed: true,
          }),
        );
      },
    );
  }
}
