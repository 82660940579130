import React from "react";
import { Modal } from "../../components/modal/modal";

type Props = {
  linkText?: React.ReactNode | string;
  infoIcon?: boolean;
};

export function PrrModal(props: Props) {
  return (
    <Modal
      title={"PRR FAQ"}
      linkText={props.linkText}
      infoIcon={props.infoIcon}
    >
      <div id="prr-faq">
        <p>
          Your Account has been established to track the value of your benefit
          under the Plan. The Plan is a non-qualified deferred compensation
          plan. Your benefit under the Plan is a contractual obligation of your
          employer. The value of your Account, at any time, is determined by
          reference to certain investments described in Plan materials that have
          been made available to you ("Reference Investments"). The Reference
          Investments may or may not be actually held or set aside by your
          employer. Nothing that follows is intended to state or imply anything
          to the contrary.
        </p>
        <hr role="presentation" />
        <p>Q: What is rate of return?</p>
        <p>
          The rate of return represents the performance of the Reference
          Investments you have selected under the Plan. Your benefit under the
          Plan will be based in part on your selection.
        </p>
        <p>
          The rate of return illustrates the combined percentage change, net of
          all fees, weighted by timing of credits under the Plan and the
          selection and/or change of Reference Investments as well as benefit
          payments, over the time period of this report. The rate of return
          takes into consideration increased benefits credited under the plan,
          transfers, withdrawals, expenses, and net asset value changes or
          earnings credited to Reference Investments at the time of the
          calculation. Past performance does not predict future results.
        </p>
        <hr role="presentation" />
        <p>Q: Why don't all the available Reference Investment options show?</p>
        <p>
          A: Only Reference Investments with a balance at the end of the time
          period specified will have PRR displayed. The total Personal Rate of
          Return does include the return from all Reference Investments for the
          period specified.
        </p>
        <hr role="presentation" />
        <p>
          Q: Why doesn't the total rate of return match the rate of return for
          each of the Reference Investments?
        </p>
        <p>
          A: If you had Account activity during the period for other Reference
          Investments, the return is included with the total even though the
          Reference Investments that no longer have a balance are not displayed
          individually.
        </p>
        <hr role="presentation" />
        <p>Q: Why is the rate of return displayed as N/A?</p>
        <p>
          A: The rates of return for Reference Investments are not available
          ("N/A") because they may not provide a meaningful rate of return for
          the time period specified on the display. Due to short-term volatility
          in certain investment markets, we may require some time to pass
          following a significant transaction before the methodology used to
          generate a rate of return will display a meaningful number for a
          Reference Investment.
        </p>
        <hr role="presentation" />
        <p>
          Q: Why is the personalized rate of return different than the return
          shown for the Reference Investment?
        </p>
        <p>
          A: Your personalized rate of return reflects your Account activity
          only. The rate of return for the Reference Investment does not take
          into consideration your individual Account activity but reflects the
          investment performance of the specific Reference Investment.
        </p>
        <hr role="presentation" />
        <p>Q: How is the How is rate of return calculated?</p>
        <p>
          A: The basic formula used to calculate personalized rate of return is
          called the Modified Dietz method. Modified Dietz is a common way to
          evaluate a portfolio's return using time-weighted analysis, accounting
          for the timing of all cash flows. The basic formula is Net Earnings
          divided by Weighted Average Account Balance. Net Earnings are earnings
          after all expenses. In more detail:
        </p>
        <div className="bg-light-gray">
          <p>
            Net Earnings = Ending Balance
            <span aria-hidden="true"> - </span>
            <span className="sr-only">minus</span>
            Beginning Balance
            <span aria-hidden="true"> - </span>
            <span className="sr-only">minus</span>
            Net Transactions
          </p>
        </div>
        <div className="bg-light-gray util-margin-top-30">
          <p>
            Calendar Day Value =<span aria-hidden="true"> ( </span>
            <span className="sr-only">open parenthesis</span>
            Calendar Days in Period
            <span aria-hidden="true"> - </span>
            <span className="sr-only">minus</span>
            number of days since the beginning of the period in which cash flow
            occurred
            <span aria-hidden="true"> ) </span>
            <span className="sr-only">close parenthesis</span>
            <span aria-hidden="true">/ </span>
            <span className="sr-only">divided by</span>
            calendar days in period
          </p>
        </div>
        <div className="bg-light-gray util-margin-vert-30">
          <p>
            Weighted Average Account Balance = Beginning Balance + Each Cash
            Flow
            <span aria-hidden="true"> * </span>
            <span className="sr-only">multiplied by</span>
            Calendar Day Value
          </p>
        </div>
        <div className="bg-light-gray">
          <p>
            PRR = Net Earnings<span aria-hidden="true"> / </span>
            <span className="sr-only">divided by</span> Weighted Average Account
            Balance
          </p>
        </div>
      </div>
    </Modal>
  );
}
