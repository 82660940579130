import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Page, PageHeader } from "../../../components/page";
import { ContributionRates } from "../../../models/contributions";
import { updateData } from "../../../reducers/contributions/contribution-rates";
import { ContributionRoutes } from "../../../routes/routes";
import { contributionRateStatus, useStatuses } from "../../../selectors/status";
import { useAppDispatch, useSelector } from "../../../store/store";
import { formatDateWithoutTimeZone } from "../../../util/date-util";
import { updateContributionRateFromFormValues } from "../summary/contribution-amounts/contribution-amounts-util";
import ManageContributionsCalculationsBox from "./manage-contributions-calculations-box";
import ManageContributionsFormInputs from "./manage-contributions-form-inputs";
import "./manage-contributions.scss";
import { PlanCode } from "../../../models/plan/plan-info-model";

export default function ManageContributionsPage() {
  const history = useHistory();
  const { isLoadingStatus } = useStatuses([contributionRateStatus]);
  const {
    data: contributionRates,
    currentSalary,
    payCheckFrequency,
  } = useSelector((state) => state.contributions.contributionRates);

  const { contributionEffectiveDate, planCode } = useSelector(
    (state) => state.plan.info
  );
  let dateFormatted = "";
  if (contributionEffectiveDate) {
    dateFormatted = formatDateWithoutTimeZone(
      contributionEffectiveDate,
      "MM/dd/yyyy"
    );
  }

  const availableAccounts = useSelector((state) => state.accounts.available);

  const hasAvailableAccounts = availableAccounts.length > 0;
  const is457bPlan = planCode.toLowerCase() === PlanCode.type457b;
  const shouldAllowContributionChanges = is457bPlan
    ? hasAvailableAccounts
    : true;

  const dispatch = useAppDispatch();
  const { register, handleSubmit, watch, errors, setValue, reset } = useForm();

  function isEmpty(e: any) {
    return e === undefined || e === null;
  }

  useEffect(() => {
    const defaultValues: any = contributionRates.reduce(
      (acc, compType: ContributionRates) => {
        const {
          contributionAmount,
          otherText,
          contributionElectionType: electionType,
          contributionTypeName: name,
        } = compType;
        const value = !isEmpty(contributionAmount)
          ? contributionAmount
          : otherText;

        return {
          ...acc,
          [`${name}Type`]: electionType,
          [`${name}Value`]: value,
        };
      },
      {}
    );
    defaultValues.currentSalary = currentSalary;
    defaultValues.payCheckFrequency = payCheckFrequency;
    reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, contributionRates]);

  const allFields = watch();

  function onSubmit(updatedContributions: any) {
    const { currentSalary, payCheckFrequency } = updatedContributions;
    const data: ContributionRates[] = contributionRates.map(
      (contributionRate) =>
        updateContributionRateFromFormValues(
          contributionRate,
          updatedContributions
        )
    );
    dispatch(
      updateData({
        currentSalary,
        payCheckFrequency,
        data,
        allFields,
      })
    );
    history.push(ContributionRoutes.Review);
  }

  return (
    <Page
      className="manage-contributions"
      name="manage-contributions"
      printable
      loading={isLoadingStatus}
    >
      <PageHeader name="manage-contributions">Manage contributions</PageHeader>
      {!shouldAllowContributionChanges && (
        <section className={"contribution-changes-not-allowed"}>
          <p>
            You have no active accounts available at this time. Please contact
            your plan sponsor.
          </p>
        </section>
      )}
      {shouldAllowContributionChanges && (
        <section className={"contribution-changes-section"}>
          <p>
            Your plan allows you to make changes to your contributions on a
            monthly basis.
          </p>
          <p>Any changes will become effective on {dateFormatted}.</p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={"manage-contributions-layout row"}>
              <div className={"col-xs-6"}>
                <ManageContributionsFormInputs
                  register={register}
                  errors={errors}
                  allFields={allFields}
                  setValue={setValue}
                />
              </div>
              <div className={"col-xs-6"}>
                <ManageContributionsCalculationsBox
                  isReview={false}
                  allFields={allFields}
                  register={register}
                  errors={errors}
                />
              </div>
            </div>
          </form>
        </section>
      )}
    </Page>
  );
}
