import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AsyncStatus } from "../models/async-status";
import { Session } from "../models/session";
import {
  attemptSessionFromEnterpriseId,
  extractParamContext,
  getFromSessionString,
  getSessionFromContract,
} from "../util/session";
import qs from "query-string";

async function getSessionFromParameters(): Promise<Session> {
  const query = window.location.search;
  const { planId, empId, role, contractId } = qs.parse(query);

  if (planId && empId) {
    return extractParamContext(
      empId.toString(),
      planId.toString(),
      role ? role.toString() : "MEMBER"
    );
  } else if (empId && contractId) {
    return getSessionFromContract(
      empId.toString(),
      contractId.toString(),
      role ? role.toString() : "MEMBER"
    );
  }
  return {} as Session;
}

export const fetchSession = createAsyncThunk("fetch-session", async () => {
  let sessionObject: Session = {} as Session;

  if (window.location.search) {
    sessionObject = await getSessionFromParameters();
  }

  if (!sessionObject.planId && !sessionObject.empId) {
    sessionObject = await getFromSessionString();
  }

  if (!sessionObject.planId && !sessionObject.empId) {
    sessionObject = await attemptSessionFromEnterpriseId();
  }

  if (!sessionObject.planId || !sessionObject.empId) {
    throw Error("No session found");
  }

  if (!sessionObject.role) {
    sessionObject.role = "MEMBER";
  }

  return sessionObject;
});

const initialState: Session = {
  status: AsyncStatus.not_fetched,
  role: "MEMBER",
  planId: -1,
  empId: -1,
  isAdvisorOrEmployer: false,
  isAdvisor: false,
};

const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    setSession: (state, action) => {
      return { ...state, ...action.payload, status: AsyncStatus.done };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSession.pending, (state, action) => {
      return { ...state, status: AsyncStatus.loading };
    });
    builder.addCase(fetchSession.fulfilled, (state, action) => {
      return { ...state, ...action.payload, status: AsyncStatus.done };
    });
    builder.addCase(fetchSession.rejected, (state, action) => {
      return { ...state, status: AsyncStatus.error };
    });
    builder.addDefaultCase((state, action) => state);
  },
});

export const { setSession } = sessionSlice.actions;
export default sessionSlice.reducer;
