import axios, { ResponseType } from "axios";
import "core-js/es/object/from-entries";
import { apiHost } from "../util/constants";
import { formatDateWithoutTimeZone } from "../util/date-util";
import { TrsStatementsResource } from "../models/trs-statements";

function buildUrl(path: string) {
  const formattedPath = path.charAt(0) === "/" ? path : `/${path}`;
  return `${apiHost}/rnq/services/participantwrapperservice${formattedPath}`;
}

type GetParams = {
  role?: string;
  planId?: number;
  empId?: number;
  fileName?: string;
  documentId?: number;
  extension?: string;
  resourceId?: number;
  accountId?: number;
  contractId?: string;
  estimatedProjection?: string;
  sourceBalance?: string;
  messageTypes?: string;
  filter?: string;
  eventName?: string;
  forecastDate?: string;
  selectedYear?: string;
  effectiveDate?: string;
  previousEffectiveDate?: string;
  accountIds?: string;
  rateOfReturn?: number;
  isAdvisorOrEmployer?: boolean;
  beginDate?: string;
  endDate?: string;
};

export async function get(
  path: string,
  params: GetParams = {},
  responseType?: ResponseType
): Promise<any> {
  const apiToken = localStorage.getItem("accessToken");

  const { data } = await axios.get(buildUrl(path), {
    ...(responseType && { responseType: responseType }),
    headers: {
      authorization: `Bearer ${apiToken}`,
    },
    params,
  });
  return data;
}

export async function callLegacyTRSStatements(
  path: string,
  role: string,
  isAdvisorOrEmployer: boolean,
  responseType?: ResponseType
): Promise<any> {
  const apiToken = localStorage.getItem("accessToken");

  const { data } = await axios.get(buildUrl(path), {
    withCredentials: true,
    ...(responseType && { responseType: responseType }),
    headers: {
      authorization: `Bearer ${apiToken}`,
    },
    params: {
      role: role,
      isAdvisorOrEmployer: isAdvisorOrEmployer,
      withCredentials: true,
    },
  });
  return data;
}

export function trsUrl(
  empId: string,
  contractId: string,
  isNotProd: boolean,
  isAdvisorOrEmployer: boolean
) {
  return `https://api${
    isNotProd ? ".pilot" : ""
  }.principal.com/retirement/v1/participantdocuments/statements?id_num=${empId}&contract_num=${contractId}&stmtType=TRSSTMT&isAdvisorOrEmployer=${isAdvisorOrEmployer}`;
}

export async function callClientToClientTRSStatements(
  contractId: string,
  empId: number,
  authToken: string,
  isPilotOrTest: boolean,
  isAdvisorOrEmployer: boolean
): Promise<TrsStatementsResource> {
  const url = trsUrl(
    empId.toString(),
    contractId,
    isPilotOrTest,
    isAdvisorOrEmployer
  );
  const { data } = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });
  return data;
}

export async function download(
  path: string,
  params: GetParams = {},
  responseType?: ResponseType
): Promise<any> {
  const apiToken = localStorage.getItem("accessToken");

  const { data } = await axios.get(buildUrl(path), {
    ...(responseType && { responseType: "arraybuffer" }),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/pdf",
      authorization: `Bearer ${apiToken}`,
    },
    params,
  });
  downloadFile(data);
}

export function buildDownloadUrl(path: string, params: GetParams = {}): string {
  const { role, fileName, documentId } = params;
  const url = buildUrl(path);
  let addParams = `?withCredentials=true&role=${role}&fileName=${fileName}&documentId=${documentId}`;
  const token = localStorage.getItem("accessToken");
  if (token && token !== "null") {
    addParams = addParams + `&code=${token}`;
  }

  return url + addParams;
}

export function buildTrsDownloadUrl(
  path: string,
  params: GetParams = {}
): string {
  const { role } = params;
  const url = buildUrl(path);
  let addParams = `?withCredentials=true&role=${role}`;
  const token = localStorage.getItem("accessToken");
  if (token && token !== "null") {
    addParams = addParams + `&code=${token}`;
  }
  return url + addParams;
}

function downloadFile(data?: any) {
  const downloadURL = `data:application/pdf;base64,${data}`;
  const aElement = document.createElement("a");
  aElement.download = `STATEMENT.pdf`;
  aElement.href = downloadURL;
  aElement.click();
  aElement.remove();
}

export async function downloadPlanInfoFiles(
  path: string,
  params: GetParams = {},
  responseType?: ResponseType
): Promise<any> {
  const apiToken = localStorage.getItem("accessToken");
  const { data } = await axios.get(buildUrl(path), {
    ...(responseType && { responseType: "arraybuffer" }),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/pdf",
      authorization: `Bearer ${apiToken}`,
    },
    params,
  });
  downloadPlanInfoFile(data, params.extension);
}

function downloadPlanInfoFile(data?: any, extension?: string) {
  let downloadURL = "";
  const aElement = document.createElement("a");
  if (extension === "(PDF)") {
    aElement.download = "data.pdf";
    downloadURL = `data:application/pdf;base64,${data}`;
  } else if (extension === "(Word)") {
    aElement.download = "data.doc";
    downloadURL = `data:application/msword;base64,${data}`;
  } else if (extension === "(Excel)") {
    aElement.download = "data.xls";
    downloadURL = `data:application/vnd.ms-excel;base64,${data}`;
  }
  aElement.href = downloadURL;
  aElement.click();
  aElement.remove();
}

export async function downloadExcel(
  path: string,
  params: GetParams = {},
  fileName: string
): Promise<any> {
  const apiToken = localStorage.getItem("accessToken");
  axios({
    url: buildUrl(path),
    headers: { authorization: `Bearer ${apiToken}` },
    params,
    method: "GET",
    responseType: "blob",
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `${fileName} ${formatDateWithoutTimeZone(new Date(), "MM/dd/yyyy")}.xlsx`
    );
    document.body.appendChild(link);
    link.click();
  });
}

export async function downloadExcelWithRequestBody(
  path: string,
  params: GetParams = {},
  body: {},
  fileName: string
): Promise<any> {
  const apiToken = localStorage.getItem("accessToken");
  axios({
    url: buildUrl(path),
    headers: { authorization: `Bearer ${apiToken}` },
    params,
    data: body,
    method: "POST",
    responseType: "blob",
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `${fileName} ${formatDateWithoutTimeZone(new Date(), "MM/dd/yyyy")}.xlsx`
    );
    document.body.appendChild(link);
    link.click();
  });
}

export async function post(
  path: string,
  params: GetParams = {},
  body?: any,
  responseType?: ResponseType
): Promise<any> {
  const apiToken = localStorage.getItem("accessToken");

  const { data } = await axios.post(buildUrl(path), body, {
    ...(responseType && { responseType: responseType }),
    headers: {
      authorization: `Bearer ${apiToken}`,
    },
    params,
  });
  return data;
}
