import React from "react";
import {
  Accordion,
  AccordionDropdown,
  AccordionWell,
} from "../accordion/accordion";
import Info from "../../pages/investments/summary/info";

export default function PrrAccordion() {
  return (
    <Accordion name={"PRR FAQ"} id={1}>
      <AccordionDropdown
        id={1}
        name={
          <span className="accordion-title">
            <sup>[1]</sup>
            {" PRR Frequently Asked Questions"}
          </span>
        }
        classNames={["distribution-grid"]}
      ></AccordionDropdown>
      <AccordionWell id={1} classNames={["well"]}>
        <Info />
      </AccordionWell>
    </Accordion>
  );
}
