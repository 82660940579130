import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AsyncStatus } from "../models/async-status";
import { InvestmentV2 } from "../models/investment-v2";
import { getAllInvestments } from "../services/investment-data";

type FetchSingleDayInvestmentsArgs = {
  planId: number;
  empId: number;
  role: string;
  lastBusinessDate: string;
};

export const fetchSingleDayInvestments = createAsyncThunk(
  "fetch-single-day-investments",
  async ({
    planId,
    empId,
    role,
    lastBusinessDate,
  }: FetchSingleDayInvestmentsArgs) => {
    return getAllInvestments({
      planId,
      empId,
      role,
      startDate: lastBusinessDate,
      endDate: lastBusinessDate,
    });
  }
);

type SingleDayInvestmentsState = {
  data: InvestmentV2[];
  status: AsyncStatus;
};
const initialState: SingleDayInvestmentsState = {
  data: [],
  status: AsyncStatus.not_fetched,
};

const singleDayInvestmentSlice = createSlice({
  name: "singleDayInvestments",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSingleDayInvestments.pending, (state, action) => {
      return { ...state, status: AsyncStatus.loading };
    });
    builder.addCase(fetchSingleDayInvestments.fulfilled, (state, action) => {
      return { ...state, data: action.payload, status: AsyncStatus.done };
    });
    builder.addCase(fetchSingleDayInvestments.rejected, (state, action) => {
      return { ...state, status: AsyncStatus.error };
    });
    builder.addDefaultCase((state, action) => state);
  },
});

export default singleDayInvestmentSlice.reducer;
