import { createSelector } from "@reduxjs/toolkit";
import { parseISO } from "date-fns";
import { filterScheduleDistributionsByAccounts } from "../pages/distributions/distribution-scheduled/scheduled-distribution-util";
import {
  filterAccountsByInvestmentFound,
  generateEstimatedDistributions,
  needsEstimatedDistribution,
} from "../pages/distributions/distribution-summary/estimated-distributions/estimated-distribution-generate-util";
import { RootState } from "../store/store";
import { accountsWithUnscheduledDistributionsSelector } from "./account-distributions";
import { selectedInvestmentsSelector } from "./investments";
import { scheduledDistributionsWithAccountsSelector } from "./scheduled-distribution-events";

export const participantLevelElectionsSelector = (state: RootState) =>
  state.distributions.participantLevelElections.participantLevelElections;

export const projectedRetirementDateSelector = (state: RootState) =>
  parseISO(state.projectedRetirementDate.projectedRetirementDate);

export const accountsWithInvestmentsSelector = createSelector(
  [accountsWithUnscheduledDistributionsSelector, selectedInvestmentsSelector],
  (accounts, investments) => {
    return filterAccountsByInvestmentFound(accounts, investments);
  }
);

export const accountsNeedingEstimatedDistributionSelector = createSelector(
  [
    accountsWithUnscheduledDistributionsSelector,
    scheduledDistributionsWithAccountsSelector,
  ],
  (accounts, scheduledDistributions) => {
    return needsEstimatedDistribution(accounts, scheduledDistributions);
  }
);

export const generatedEstimatedDistributionsSelector = createSelector(
  [
    accountsNeedingEstimatedDistributionSelector,
    participantLevelElectionsSelector,
    projectedRetirementDateSelector,
    selectedInvestmentsSelector,
  ],
  (accounts, elections, projectedRetirementDate, investments) => {
    const generatedDistributions = generateEstimatedDistributions(
      accounts,
      elections,
      projectedRetirementDate,
      investments
    );
    return filterScheduleDistributionsByAccounts(
      generatedDistributions,
      accounts
    );
  }
);
