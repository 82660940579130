import React from "react";
import { ScheduledDistributionForRedeferral } from "../../../../models/distributions/scheduled-distribution-event";
import Balance from "../../../../components/numbers/balance";
import { formatDateWithoutTimeZone } from "../../../../util/date-util";

interface Props {
  scheduledDistributions: ScheduledDistributionForRedeferral[];
}

export default function RedeferIndividualPaymentReviewTable(props: Props) {
  const { scheduledDistributions } = props;

  return (
    <>
      <div style={{ marginBottom: 20 }}>
        <table data-testid="redeferral-table" className="table">
          <tbody>
            <tr
              style={{
                background: "#caebfa",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              <td className="table-head">Distribution</td>
              <td className="table-head">Estimated Distribution</td>
              <td className="table-head">Current Distribution Date</td>
              <td className="table-head">Pending Distribution Date</td>
              <td className="table-head">New Distribution Date</td>
            </tr>
            {scheduledDistributions.map(
              (
                scheduledDistribution: ScheduledDistributionForRedeferral,
                index
              ) => {
                return (
                  <tr
                    style={
                      index % 2
                        ? { background: "#f1f1f1" }
                        : { background: "white" }
                    }
                  >
                    <td className="table-head" style={{ textAlign: "center" }}>
                      {scheduledDistribution.installNum}
                    </td>
                    <td className="table-head" style={{ textAlign: "center" }}>
                      <Balance
                        showDollar={true}
                        value={scheduledDistribution.estimatedDistribution}
                      />
                    </td>
                    <td className="table-head" style={{ textAlign: "center" }}>
                      {formatDateWithoutTimeZone(
                        scheduledDistribution.scheduledDate,
                        "MM/dd/yyy"
                      )}
                    </td>
                    <td className="table-head" style={{ textAlign: "center" }}>
                      {scheduledDistribution.pendingDistributionDate}
                    </td>
                    <td className="table-head" style={{ textAlign: "center" }}>
                      {scheduledDistribution.isRedeferred &&
                        scheduledDistribution.newDistributionDate !== null &&
                        formatDateWithoutTimeZone(
                          scheduledDistribution.newDistributionDate,
                          "MM/dd/yyyy"
                        )}
                    </td>
                  </tr>
                );
              }
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}
