import React from "react";
import Balance from "../../../components/numbers/balance";
import { InvestmentV2 } from "../../../models/investment-v2";
import { selectedInvestmentsByMoneyTypeSelector } from "../../../selectors/investments";
import { useSelector } from "../../../store/store";
import { calculateTotalContributionsThisYear } from "./manage-contributions-utilities";
import { PayCheckFrequency } from "./paycheck-frequency";

type Props = {
  totalModifiedAmount: number;
  totalContributionAmount: number;
  allFields: any;
};

function ContributionLimits(props: Props) {
  const { totalModifiedAmount, totalContributionAmount, allFields } = props;
  const newDate = new Date();
  const year = newDate.getFullYear();
  const { contributionLimit } = useSelector((state) => state.plan.info);
  const investmentsByMoneyType: InvestmentV2[] = useSelector(
    selectedInvestmentsByMoneyTypeSelector
  );
  const contributionsYTD = calculateTotalContributionsThisYear(
    investmentsByMoneyType
  );

  const payCheckFrequency: PayCheckFrequency = allFields.payCheckFrequency;
  const bonusPayCheckFrequency: PayCheckFrequency =
    allFields.bonusPayCheckFrequency;
  const contributionAmountLeft: number = contributionLimit! - contributionsYTD;
  const remainingWeeks: number = remainingWeeksFromCurrentDate();

  const totalAmount: number =
    totalModifiedAmount *
      actualRemainingWeeksForPayCheck(payCheckFrequency, remainingWeeks) +
    totalContributionAmount *
      actualRemainingWeeksForBonus(bonusPayCheckFrequency, remainingWeeks);
  const contributionChangesTotal: number =
    contributionLimit! - (contributionsYTD + totalAmount);

  return (
    <>
      <div className="manage-contributions-text">
        <p data-testid="contributionsYTD">
          You've contributed{" "}
          <span className="bold">
            <Balance showDollar value={contributionsYTD} />
          </span>{" "}
          so far this year.
        </p>
        <p data-testid="contributionAmountLeft">
          That means that you have{" "}
          <span className="bold">
            <Balance showDollar value={contributionAmountLeft} />
          </span>{" "}
          until you hit the {year} maximum contribution limit of{" "}
          <span className="bold">
            <Balance showDollar value={contributionLimit!} />
          </span>{" "}
          .
        </p>
        {updatedContributionChanges(contributionChangesTotal)}
      </div>
    </>
  );
}

function actualRemainingWeeksForPayCheck(
  payCheckFrequency: PayCheckFrequency,
  remainingWeeks: number
): number {
  switch (payCheckFrequency) {
    case PayCheckFrequency.WEEKLY:
      return remainingWeeks;
    case PayCheckFrequency.BIWEEKLY:
      return remainingWeeks / 2;
    case PayCheckFrequency.MONTHLY:
      return remainingWeeks / 4;
    case PayCheckFrequency.BIMONTHLY:
      return remainingWeeks / 8;
    default:
      return 0;
  }
}

function actualRemainingWeeksForBonus(
  bonusPayCheckFrequency: PayCheckFrequency,
  remainingWeeks: number
): number {
  switch (bonusPayCheckFrequency) {
    case PayCheckFrequency.ANNUALLY:
      return remainingWeeks / 52;
    case PayCheckFrequency.QUARTERLY:
      return remainingWeeks / 13;
    case PayCheckFrequency.MONTHLY:
      return remainingWeeks / 4;
    default:
      return 0;
  }
}

function remainingWeeksFromCurrentDate() {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;
  const currentDay = currentDate.getDate();
  const currentFormattedDate: any = new Date(
    currentYear,
    currentMonth,
    currentDay
  );
  const lastDateOfYear: any = new Date(currentYear, 12, 31);
  return Math.round(
    (lastDateOfYear - currentFormattedDate) / (7 * 24 * 60 * 60 * 1000)
  );
}

function updatedContributionChanges(contributionChangesTotal: number) {
  if (contributionChangesTotal > 0) {
    return (
      <>
        Based upon your updated contribution changes, you are projected to be{" "}
        <span className="bold">under</span> the maximum contribution limit by{" "}
        <span className="bold">
          <Balance value={contributionChangesTotal} showDollar />.
        </span>
      </>
    );
  }
  return (
    <>
      Based upon your updated contribution changes, you are projected to be{" "}
      <span className="bold italic">over</span> the maximum contribution limit
      by{" "}
      <span className="bold italic">
        <Balance value={Math.abs(contributionChangesTotal)} showDollar />.
      </span>
    </>
  );
}

export default ContributionLimits;
