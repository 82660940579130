import React from "react";
import { UnscheduledDistributionElection } from "../../../../models/distributions/unscheduled-distribution-election";
import "./future-election-message.scss";

type Props = {
  firstAccountElection: UnscheduledDistributionElection | undefined;
};

export default function FutureElectionMessage(props: Props) {
  const { firstAccountElection } = props;
  if (!firstAccountElection) {
    return null;
  }
  const { isFuture, effectiveDate } = firstAccountElection;
  if (isFuture) {
    return (
      <p className="future-election-message">
        These unscheduled distribution elections are not effective until{" "}
        {effectiveDate}
      </p>
    );
  }
  return null;
}
