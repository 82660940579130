import React, { useEffect } from "react";
import LinkButton from "../link-button/link-button";
import Icon from "../icon/icon";
import "./print-link.scss";

export interface Props {
  removeHeader?: boolean;
  pdfText?: boolean;
}

export default function PrintLink(props: Props) {
  const { removeHeader, pdfText } = props;
  useEffect(() => {
    window.addEventListener("beforeprint", noPrintHotjar);

    return () => window.removeEventListener("beforeprint", noPrintHotjar);

    function noPrintHotjar() {
      const hotjar: HTMLElement = document.querySelector(
        "#_hj_feedback_container"
      ) as HTMLElement;
      const navbar: HTMLElement = document.querySelector(
        ".plan-header"
      ) as HTMLElement;
      if (hotjar) {
        hotjar.classList.add("no-print");
      }
      if (removeHeader && navbar) {
        navbar.classList.add("no-print");
      }
    }
  });

  function print() {
    window.print();
  }

  return (
    <div className="print-link no-print">
      <LinkButton onClick={print}>
        <Icon name="print" />
        Print {pdfText ? "or Save Page as PDF" : "Page"}
      </LinkButton>
    </div>
  );
}
