import React, { useEffect, useState } from "react";
import { PrrModal } from "src/pages/prr-faq/prr-modal";
import AssetClassModal from "../../../components/asset-class-modal/asset-class-modal";
import { Card, CardContent } from "../../../components/card";
import CardHeader from "../../../components/card/card-header";
import { Page, PageHeader } from "../../../components/page";
import TableV2 from "../../../components/table/tableV2";
import { aggregatedSelectedInvestmentsSelector } from "../../../selectors/investments";
import {
  accountsStatusSelector,
  investmentsStatusSelector,
  useStatuses,
} from "../../../selectors/status";
import { useSelector } from "../../../store/store";
import { buildHexValueColumn } from "../../investments/performance/performance-table/build-columns-utils";
import {
  buildInvestmentOptionColumn,
  buildPrrAmtColumn,
} from "../../investments/summary/investment-table/build-investment-column-util";
import SummaryFilter from "../../investments/summary/summary-filter/summary-filter";
import "./prr-page.scss";
import PrrTotalDisplay from "./prr-total-display";
import { useLiabilitiesRestApi } from "../../../selectors/feature-flags";
import { statementBalanceSelector } from "src/selectors/statement-balance";

export default function PrrPage() {
  const { isLoadingStatus } = useStatuses([
    investmentsStatusSelector,
    accountsStatusSelector,
  ]);
  const aggregatedInvestments = useSelector(
    aggregatedSelectedInvestmentsSelector
  );
  const useLiabilitiesApi = useSelector(useLiabilitiesRestApi);

  const [planBeginDate, setPlanBeginDate] = useState("");
  const statementBalanceData = useSelector(statementBalanceSelector);

  useEffect(() => {
    console.log("investments aggregated: ", aggregatedInvestments);
  }, [aggregatedInvestments]);

  useEffect(() => {
    if (
      statementBalanceData &&
      statementBalanceData.dashboardBalance &&
      statementBalanceData.dashboardBalance.balanceInformation
    ) {
      const formattedEndDate = convertDateFormat(
        statementBalanceData.dashboardBalance.balanceInformation.beginDate
      );
      setPlanBeginDate(formattedEndDate);
    }
  }, [statementBalanceData]);

  function convertDateFormat(dateString: any) {
    const [year, month, day] = dateString.split("-");
    return `${month}/${day}/${year}`;
  }

  const prrColumns = [
    buildHexValueColumn(),
    buildInvestmentOptionColumn(),
    buildPrrAmtColumn(true, useLiabilitiesApi),
  ];

  return (
    <Page name="rate-of-return" printable loading={false}>
      <PageHeader name="rate-of-return">Personalized Rate of Return</PageHeader>
      <div className="prr-summary">
        <div className="prr-summary-data">
          <p>
            Your Personalized Rate of Return is a percentage that represents the
            performance of this account over period of time. If you have
            questions about this rate and how it's calculated, please reference
            our FAQ on the sidebar.
          </p>
          <PrrTotalDisplay />
          <hr className="dotted util-margin-vert-30" role="presentation" />
          <SummaryFilter beginDate={planBeginDate} />
          <TableV2 columns={prrColumns} data={aggregatedInvestments} />
        </div>
        <div className="prr-helpful-hints">
          <Card>
            <div className={"hints-content"}>
              <CardHeader>Helpful Hints</CardHeader>
              <CardContent>
                <AssetClassModal linkText="Asset Class Risk Spectrum" />
                <PrrModal linkText="PRR Important Information & FAQs" />
              </CardContent>
            </div>
          </Card>
        </div>
      </div>
    </Page>
  );
}
