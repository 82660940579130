import React from "react";
import { Beneficiary } from "../../../../models/beneficiaries/beneficiary";

type Props = {
  header: string;
  beneficiaries: Beneficiary[];
};
export default function BeneficiariesReviewDisplayList(props: Props) {
  const { header, beneficiaries } = props;
  return (
    <>
      <h3 className="h4">{header}</h3>
      <ul className="list-unstyled">
        {beneficiaries.map((beneficiary: Beneficiary) => {
          return (
            <li key={beneficiary.guid}>
              {beneficiary.beneficiaryInfo.name}&nbsp;-&nbsp;
              {beneficiary.allocationAmount}%
            </li>
          );
        })}
      </ul>
    </>
  );
}
