import {DashboardBalance} from "../dashboard/dashboard-balance";
import {AccountPrr} from "./account-prr";

export interface StatementBalance {
    dashboardBalance: DashboardBalance;
    accountPrrs: AccountPrr[];
}

export function getPrrValue(statementBalance: StatementBalance) {
    if (statementBalance && statementBalance.dashboardBalance && statementBalance.dashboardBalance.balanceInformation) {
        return statementBalance.dashboardBalance.balanceInformation.prr / 100;
    } else {
        return "N/A";
    }
}
