import _sortBy from "lodash/sortBy";
import React from "react";
import "./disclosure-links.scss";

type Props = {
  disclosureIds: number[];
};

export default function DisclosureLinks(props: Props) {
  const { disclosureIds } = props;
  const sortedDisclosureIds = disclosureIds
    ? _sortBy(disclosureIds, (id) => Number(id))
    : [];

  return (
    <sup>
      {sortedDisclosureIds &&
        sortedDisclosureIds.map((id, index) => (
          <div className="disclosure-links" key={id}>
            {index > 0 && ", "}
            <a key={id} href={`#${id}`}>
              {`[${id}]`}
              <span className="sr-only">, footnote</span>
            </a>
          </div>
        ))}
    </sup>
  );
}
