import React from "react";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from "../../../components/card";
import classnames from "classnames";

type Props = {
  url: string;
  disableNewMoveBalances: boolean;
  moveBalancesLimitText: string;
  allowPendingTransactionChanges: boolean;
};

function CurrentInvestmentsCard(props: Props) {
  const {
    url,
    disableNewMoveBalances,
    moveBalancesLimitText,
    allowPendingTransactionChanges,
  } = props;

  const buttonClasses = classnames("btn btn-primary");

  return (
    <Card fillHeight>
      <CardHeader>
        Transfer Balances Between Your <strong>Current</strong> Investments
      </CardHeader>
      <CardContent>
        <div className="fa-stack fa-3x" aria-hidden="true">
          <i className="fa fa-circle fa-stack-2x text-midnight"></i>
          <i className="fa fa-arrows-h fa-stack-1x fa-inverse"></i>
        </div>
        <p className="util-margin-top-30">
          Transfer balances between the{" "}
          <strong>investments you already have</strong>. This won't affect new
          contributions
        </p>
      </CardContent>
      <CardFooter>
        <a
          data-testid="move-balances-button"
          id="invest-contributions-button"
          className={buttonClasses}
          href={url}
        >
          Move Balances
        </a>
        {getDisabledText(disableNewMoveBalances)}
        {getPendingText(disableNewMoveBalances, allowPendingTransactionChanges)}
        {getLimitText(moveBalancesLimitText)}
      </CardFooter>
    </Card>
  );
}

function getDisabledText(disableNewMoveBalances: boolean) {
  if (disableNewMoveBalances) {
    return (
      <div className="info-text">
        <span>You have reached your transfer limit for this plan.</span>
      </div>
    );
  }
}

function getPendingText(
  disableNewMoveBalances: boolean,
  allowPendingTransactionChanges: boolean
) {
  if (disableNewMoveBalances && allowPendingTransactionChanges) {
    return (
      <div className="info-text">
        <span>You may still cancel a pending transaction.</span>
      </div>
    );
  }
}

function getLimitText(moveBalancesLimitText: string) {
  if (moveBalancesLimitText) {
    return (
      <div className="info-text">
        <span>{moveBalancesLimitText}</span>
      </div>
    );
  }
}

export default CurrentInvestmentsCard;
