import { StatementQuickView } from "./quick-view/statement-quick-view";
import AccountDropdown from "../../components/account-dropdown/account-dropdown";
import DateSelector from "./date-selector/date-selector";
import StatementInformationPanel from "./statement-information-panel";
import React from "react";
import { investmentsStatusSelector, useStatuses } from "../../selectors/status";

export default function OnDemandStatement() {
  const { isLoadingStatus } = useStatuses([investmentsStatusSelector]);
  return (
    <div>
      <div className={"row"}>
        <div className={"col-md-12"}>
          <StatementQuickView
            data-testid="statement-quick-view"
            isLoading={isLoadingStatus}
          />
          <hr className={"no-print"} role="presentation" />
        </div>
      </div>
      <section className="row generate-custom-statement no-print">
        <div className={"col-md-12"}>
          <h2 className="h1">Generate a custom statement</h2>
          <AccountDropdown />
          <DateSelector />
        </div>
      </section>
      <StatementInformationPanel />
    </div>
  );
}
