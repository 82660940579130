import axios from "axios";
import "core-js/es/object/from-entries";
import { apiHost } from "../util/constants";

function buildUrl() {
  return `${apiHost}/rnq/services/participantwrapperservice/user-activity`;
}

export type UserActivityBody = {
  enterpriseId: string;
  timestamp: string;
  app: string;
  isImposter: boolean;
  contractId: string;
  empId: string;
  planId: string;
  urlDomain: string;
  urlFilePath: string;
  urlParameters: string;
  urlAnchors: string;
};

export async function post(body: UserActivityBody): Promise<any> {
  const apiToken = localStorage.getItem("accessToken");
  if (apiToken) {
    const { data } = await axios.post(buildUrl(), body, {
      headers: {
        authorization: `Bearer ${apiToken}`,
      },
    });

    return data;
  }
}
