import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {getPlanContacts, PlanContacts} from "../services/contacts";
import {AsyncStatus} from "../models/async-status";
import {fetchAllocations} from "./allocations";

export const fetchPlanContacts = createAsyncThunk(
    "fetch-plan-contacts",
    async (
        {contractId}: { contractId: string},
        thunkApi
    ) => {
        return getPlanContacts(contractId);
    }
);

type State = {
    planContacts: PlanContacts | null;
    status: AsyncStatus;
}

const initialState: State = {
    planContacts: null,
    status: AsyncStatus.not_fetched,
};

const planContactsSlice = createSlice({
    name: "planContactsSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchPlanContacts.pending, (state, action) => {
            return { ...state, status: AsyncStatus.loading };
        });
        builder.addCase(
            fetchPlanContacts.fulfilled,
            (state, action: { payload: PlanContacts}) => {
                const planContacts = action.payload;

                return {
                    ...state,
                    planContacts,
                    status: AsyncStatus.done,
                };
            }
        );
        builder.addCase(fetchAllocations.rejected, (state, action) => {
            return { ...state, status: AsyncStatus.error };
        });
        builder.addDefaultCase((state, action) => state);
    },
});

export default planContactsSlice.reducer
