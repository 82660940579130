import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AsyncStatus } from "../../models/async-status";
import { getAvailableUnscheduledDistributionEvents } from "../../services/distributions";
import { UnscheduledDistributionEvent } from "../../models/distributions/unscheduled-distribution-event";

export const fetchUnscheduledDistributionEvents = createAsyncThunk(
  "fetch-unscheduled-events",
  async (
    { planId, empId, role }: { planId: number; empId: number; role: string },
    thunkApi
  ) => {
    return getAvailableUnscheduledDistributionEvents({ planId, empId, role });
  }
);

type State = {
  availableUnscheduledEvents: UnscheduledDistributionEvent[];
  status: AsyncStatus;
};

const initialState: State = {
  availableUnscheduledEvents: [],
  status: AsyncStatus.not_fetched,
};

const unscheduledDistributionEventsSlice = createSlice({
  name: "availableUnscheduledEvents",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchUnscheduledDistributionEvents.pending,
      (state, action) => {
        return { ...state, status: AsyncStatus.loading };
      }
    );
    builder.addCase(
      fetchUnscheduledDistributionEvents.fulfilled,
      (
        state,
        action: { payload: UnscheduledDistributionEvent[]; type: string }
      ) => {
        const availableUnscheduledEvents = action.payload;

        return {
          ...state,
          availableUnscheduledEvents,
          status: AsyncStatus.done,
        };
      }
    );
    builder.addCase(
      fetchUnscheduledDistributionEvents.rejected,
      (state, action) => {
        return { ...state, status: AsyncStatus.error };
      }
    );
    builder.addDefaultCase((state, action) => state);
  },
});

export default unscheduledDistributionEventsSlice.reducer;
