import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AsyncStatus } from "../../models/async-status";
import { ScheduledDistributionElection } from "../../models/distributions/ScheduledDistributionElection";
import { getScheduledDistributionElections } from "../../services/distributions";

export const fetchScheduledElections = createAsyncThunk(
  "fetch-scheduled-elections",
  async (
    { planId, empId, role }: { planId: number; empId: number; role: string },
    thunkApi
  ) => {
    return getScheduledDistributionElections({ planId, empId, role });
  }
);

type State = {
  scheduledElections: ScheduledDistributionElection[];
  status: AsyncStatus;
};

const initialState: State = {
  scheduledElections: [] as ScheduledDistributionElection[],
  status: AsyncStatus.not_fetched,
};

const scheduledElectionsSlice = createSlice({
  name: "scheduledElections",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchScheduledElections.pending, (state, action) => {
      return { ...state, status: AsyncStatus.loading };
    });
    builder.addCase(
      fetchScheduledElections.fulfilled,
      (
        state,
        action: { payload: ScheduledDistributionElection[]; type: string }
      ) => {
        const scheduledElections = action.payload;

        return {
          ...state,
          scheduledElections,
          status: AsyncStatus.done,
        };
      }
    );
    builder.addCase(fetchScheduledElections.rejected, (state, action) => {
      return { ...state, status: AsyncStatus.error };
    });
    builder.addDefaultCase((state, action) => state);
  },
});

export default scheduledElectionsSlice.reducer;
