import React, { useState } from "react";
import AccountContributionsTable from "../../../components/contributions/account-contributions-table";
import { DashboardTab } from "../../../components/dashboard/dashboard-tabbed-view/dashboard-tab";
import { InvestmentAllocationTableWrapper } from "../../contributions/investment-allocations/investment-allocation-table-wrapper";
import { AccountDistributionDetailsWrapper } from "../../distributions/account-distribution-detail-wrapper";
import InvestmentTable from "../../investments/summary/investment-table/investment-table";
import AccountDetailsTable from "./account-details-table";
import { useSelector } from "../../../store/store";
import {
  hideAllocationsTabSelector,
  hideContributionsTabSelector,
  hideDistributionsTabSelector,
  hideInvestmentsTabSelector,
} from "../../../selectors/plan-info";

export enum AccountInfoTabNames {
  DETAILS = "Details",
  INVESTMENTS = "Investments",
  ALLOCATIONS = "Allocations",
  CONTRIBUTIONS = "Contributions",
  DISTRIBUTIONS = "Distributions",
}

export const AccountInfoTabsComponents = {
  [AccountInfoTabNames.DETAILS]: {
    component: AccountDetailsTable,
    iconClass: "fa-list-ul",
  },
  [AccountInfoTabNames.INVESTMENTS]: {
    component: InvestmentTable,
    iconClass: "fa-line-chart",
  },
  [AccountInfoTabNames.ALLOCATIONS]: {
    component: InvestmentAllocationTableWrapper,
    iconClass: "fa-pie-chart",
  },
  [AccountInfoTabNames.CONTRIBUTIONS]: {
    component: AccountContributionsTable,
    iconClass: "fa-money",
  },
  [AccountInfoTabNames.DISTRIBUTIONS]: {
    component: AccountDistributionDetailsWrapper,
    iconClass: "fa-check-circle",
  },
};

type AccountInfoTabsProps = {
  setSelectedTab: (tab: AccountInfoTabNames) => void;
  selectedTab: AccountInfoTabNames;
};

export function AccountInfoTabs(props: AccountInfoTabsProps) {
  const { setSelectedTab, selectedTab } = props;
  const [, setShouldUpdateTour] = useState("");
  const hideInvestmentsTab = useSelector(hideInvestmentsTabSelector);
  const hideAllocationsTab = useSelector(hideAllocationsTabSelector);
  const hideContributionsTab = useSelector(hideContributionsTabSelector);
  const hideDistributionsTab = useSelector(hideDistributionsTabSelector);

  return (
    <>
      {Object.entries(AccountInfoTabsComponents).map(
        ([tabName, { iconClass }]) => {
          return (
            <>
              {(tabName === "Details" ||
                (!hideInvestmentsTab && tabName === "Investments") ||
                (!hideAllocationsTab && tabName === "Allocations") ||
                (!hideContributionsTab && tabName === "Contributions") ||
                (!hideDistributionsTab && tabName === "Distributions")) && (
                <DashboardTab
                  key={tabName}
                  tabName={tabName}
                  setSelectedTab={setSelectedTab}
                  setShouldUpdateTour={setShouldUpdateTour}
                  selectedTab={selectedTab}
                  iconClass={iconClass}
                />
              )}
            </>
          );
        }
      )}
    </>
  );
}
