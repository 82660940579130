import React from "react";
import { AccountWithUnscheduledDistributions } from "../../../../models/account";
import { DistributionPayment } from "../../../../models/distributions/distribution-payment";
import { EstimatedDistribution } from "../../../../models/distributions/estimated-distribution";
import { ScheduledDistributionEvent } from "../../../../models/distributions/scheduled-distribution-event";
import { UnscheduledDistributionElection } from "../../../../models/distributions/unscheduled-distribution-election";
import { useInvestments } from "../../../../selectors/investments";
import { useSelector } from "../../../../store/store";
import { filterElectionByProvisionAndDefaultElections } from "../../../../util/distributions-util";
import { hasEstimatedDistributions } from "../../../../util/estimated-distribution-util";
import EstimatedDistributionsTable from "../estimated-distributions/estimated-distributions-table";
import { buildEstimatedDistributionsToShow } from "../scheduled-distributions-table/summary-scheduled-distributions-util";
import SummaryScheduledDistributionsTable from "../scheduled-distributions-table/summary-scheduled-distributions-table";

type Props = {
  account: AccountWithUnscheduledDistributions;
  futureDistributions: ScheduledDistributionEvent[];
  distributionPayments: DistributionPayment[];
};

export function SingleAccountDistributionTable(props: Props) {
  const { account, futureDistributions, distributionPayments } = props;
  const { investments } = useInvestments();

  const { participantLevelElections } = useSelector(
    (state) => state.distributions.participantLevelElections
  );

  const participantElectionsForSingleAccount: UnscheduledDistributionElection[] =
    filterElectionByProvisionAndDefaultElections(
      participantLevelElections,
      account.provisionVersionNumber
    );

  const elections = [
    ...participantElectionsForSingleAccount,
    ...account.unscheduledDistributionElections,
  ];

  const hasEstimatedDistribution = hasEstimatedDistributions(
    elections,
    account,
    futureDistributions
  );

  let estimatedDistributions: EstimatedDistribution[] | undefined;
  if (hasEstimatedDistribution) {
    estimatedDistributions = buildEstimatedDistributionsToShow(
      account.id,
      elections,
      investments
    );
  }

  let age: number | undefined;
  if (elections && elections.length > 0) {
    age = elections[0].seniorityAge;
  }

  if (estimatedDistributions) {
    return (
      <EstimatedDistributionsTable
        age={age}
        estimatedDistributions={estimatedDistributions}
      />
    );
  }

  return (
    <SummaryScheduledDistributionsTable distributions={distributionPayments} />
  );
}
