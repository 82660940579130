import { get, post } from "../repositories/participant";
import {
  Beneficiary,
  BeneficiaryWrapper,
} from "src/models/beneficiaries/beneficiary";
import { v4 as uuidv4 } from "uuid";

type Args = {
  planId: number;
  empId: number;
  role: string;
};

type OnlineBeneficiaryArgs = {
  empId: number;
  role: string;
  filter: string;
};

type PostArgs = {
  planId: number;
  empId: number;
  role: string;
  data: BeneficiaryWrapper;
};

export async function getBeneficiaries({
  planId,
  empId,
  role,
}: Args): Promise<Beneficiary[]> {
  const data = await get(`/employees/${empId}/plans/${planId}/beneficiary`, {
    role,
  });
  return data.map((beneficiary: Partial<Beneficiary>) => {
    return { ...beneficiary, guid: uuidv4() };
  });
}

export async function postBeneficiaries({
  planId,
  empId,
  role,
  data,
}: PostArgs) {
  return post(
    `/employees/${empId}/plans/${planId}/beneficiary`,
    { role },
    data
  );
}

export async function getOnlineBeneficiaryPlans({
  empId,
  role,
  filter,
}: OnlineBeneficiaryArgs) {
  return get(`/employees/${empId}/planList`, { role, filter });
}
