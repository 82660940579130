import "@principal/design-system/pds-styles.min.css";
import React from "react";
import {Provider} from "react-redux";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import LogUserActivity from "src/util/log-user-activity";
import {EnrollmentContextProvider} from "../contexts/enrollment-context/enrollment-context";
import {TogglesProvider} from "../contexts/toggles-context/toggles-context";
import DevTools from "../pages/dev-tools/dev-tools";
import ErrorBoundary from "../pages/errors/error-boundary";
import Error404 from "../pages/errors/Error404";
import {InvestmentAdvisor} from "../pages/investments/investment-advisor";
import AccountsLandingPage from "../pages/overview/accounts-landing/accounts-landing-page";
import Landing from "../pages/transition/landing";
import {PrefetchWrapper} from "../prefetch-wrapper";
import ContributionRoutes from "../routes/contribution-routes";
import DistributionRoutes from "../routes/distribution-routes";
import EnrollmentRoutes from "../routes/enrollment-routes";
import InvestmentsRoutes from "../routes/investment-routes";
import PlanOverviewRoutes from "../routes/plan-overview-routes";
import PlanningCenterOverviewRoutes from "../routes/planning-center-overview-routes";
import {store} from "../store/store";
import ScrollToTop from "../util/scroll-to-top";
import "./App.scss";
import AuthWrapper from "./auth/auth-wrapper";
import DebugHeader from "./card/debug-header";
import PlanHeader from "./plan-header/plan-header";
import SiteFooter from "./site-footer/site-footer";
import SiteNav from "./site-nav/site-nav";

function App() {
  return (
    <BrowserRouter basename="/nq-participant">
      <ScrollToTop />
      <ErrorBoundary>
        <Provider store={store}>
          <LogUserActivity />
          <Switch>
            <Route path="/public/investment-advisor">
              <InvestmentAdvisor />
              <SiteFooter />
            </Route>
            <Route path="/landing">
              <LandingRoute />
              <SiteFooter />
            </Route>
            <Route path="/accounts-landing">
              <AuthWrapper>
                <SiteNav />
                <Switch>
                  <AccountsLandingPage />
                </Switch>
                <SiteFooter />
              </AuthWrapper>
            </Route>
            <Route>
              <AuthenticatedRoutes />
            </Route>
          </Switch>
        </Provider>
      </ErrorBoundary>
    </BrowserRouter>
  );
}

function LandingRoute() {
  return (
    <Providers>
      <EnrollmentContextProvider>
        <Landing />
      </EnrollmentContextProvider>
    </Providers>
  );
}

function AuthenticatedRoutes() {
  return (
    <Providers>
      <PlanHeader />
      <DebugHeader />
      <Switch>
        <Route path="/investments">
          <InvestmentsRoutes />
        </Route>
        <Route path="/contributions">
          <ContributionRoutes />
        </Route>
        <Route exact path="/devtools">
          <DevTools />
        </Route>
        <Route path="/distributions">
          <DistributionRoutes />
        </Route>
        <Route path="/overview">
          <PlanOverviewRoutes />
        </Route>
        <Route path="/enrollmentcenter">
          <EnrollmentRoutes />
        </Route>
        <Route path="/planningResources">
          <PlanningCenterOverviewRoutes />
        </Route>
        <Route>
          <Error404 />
        </Route>
      </Switch>
      <SiteFooter />
    </Providers>
  );
}

function Providers({ children }: { children: React.ReactNode }) {
  return (
    <AuthWrapper>
      <PrefetchWrapper>
        <TogglesProvider>{children}</TogglesProvider>
      </PrefetchWrapper>
    </AuthWrapper>
  );
}

export default App;
