import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AsyncStatus } from "../../models/async-status";
import { ContributionRates } from "../../models/contributions";
import {
  deleteFutureContributionRates,
  getContributionRates,
  saveContributionRates,
} from "../../services/contributions";
import { GetAllInvestmentsArgs } from "../../services/investment-data";
import { PayCheckFrequency } from "../../pages/contributions/manage-contributions/paycheck-frequency";

export const fetchContributionRates = createAsyncThunk(
  "fetch-contribution-rates",
  async (args: GetAllInvestmentsArgs) => {
    return getContributionRates(args);
  }
);

export const removeContributionRates = createAsyncThunk(
  "delete-contribution-rates",
  async ({
    planId,
    empId,
    role,
  }: {
    planId: number;
    empId: number;
    role: string;
  }) => {
    return deleteFutureContributionRates({
      planId,
      empId,
      role,
    });
  }
);

export const updateContributionRates = createAsyncThunk(
  "update-contribution-rates",
  async ({
    planId,
    empId,
    role,
    data,
  }: {
    planId: number;
    empId: number;
    role: string;
    data: ContributionRates[];
  }) => {
    return saveContributionRates({
      planId,
      empId,
      role,
      data,
    });
  }
);

export type ContributionRateState = {
  data: ContributionRates[];
  currentSalary: number | undefined;
  payCheckFrequency: PayCheckFrequency;
  status: AsyncStatus;
  updateStatus: AsyncStatus;
  allFields: any;
};
const initialState: ContributionRateState = {
  data: [],
  currentSalary: undefined,
  status: AsyncStatus.not_fetched,
  updateStatus: AsyncStatus.not_fetched,
  payCheckFrequency: PayCheckFrequency.WEEKLY,
  allFields: {},
};

type updatedData = {
  currentSalary: number;
  payCheckFrequency: PayCheckFrequency;
  data: ContributionRates[];
  allFields: any;
};

const contributionRateSlice = createSlice({
  name: "contributionrates",
  initialState,
  reducers: {
    resetUpdateStatus: (state) => {
      return {
        ...state,
        updateStatus: AsyncStatus.not_fetched,
      };
    },
    updateData: (state, action: { payload: updatedData; type: string }) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchContributionRates.pending, (state, action) => {
      return { ...state, status: AsyncStatus.loading };
    });
    builder.addCase(fetchContributionRates.fulfilled, (state, action) => {
      return { ...state, data: action.payload, status: AsyncStatus.done };
    });
    builder.addCase(fetchContributionRates.rejected, (state, action) => {
      return { ...state, status: AsyncStatus.error };
    });
    builder.addCase(updateContributionRates.pending, (state, action) => {
      return { ...state, updateStatus: AsyncStatus.loading };
    });
    builder.addCase(updateContributionRates.fulfilled, (state) => {
      return {
        ...state,
        updateStatus: AsyncStatus.done,
      };
    });
    builder.addCase(updateContributionRates.rejected, (state, action) => {
      return { ...state, updateStatus: AsyncStatus.error };
    });
    builder.addCase(removeContributionRates.pending, (state, action) => {
      return { ...state, updateStatus: AsyncStatus.loading };
    });
    builder.addCase(removeContributionRates.fulfilled, (state) => {
      return {
        ...state,
        updateStatus: AsyncStatus.done,
      };
    });
    builder.addCase(removeContributionRates.rejected, (state, action) => {
      return { ...state, updateStatus: AsyncStatus.error };
    });
    builder.addDefaultCase((state, action) => state);
  },
});

export const { updateData, resetUpdateStatus } = contributionRateSlice.actions;
export default contributionRateSlice.reducer;
