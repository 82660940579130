import React from "react";
import classnames from "classnames";

import { DataPoint, DataPointDisplay } from "../types/data-point";
import { Styles } from "../types/styles";
import { calculateY } from "../util";
import { PointFormatter } from "../types/point-formatter";
import { DisplayBox } from "./display-box";

interface PointProps extends DataPoint {
  values: DataPointDisplay[];
  scale: Function;
  styles: Styles;
  handleSelect: Function;
  isSelected: boolean;
  pointFormatter?: PointFormatter;
  groupName: string;
}

export function Point(props: PointProps) {
  let { color } = props;
  const {
    scale,
    date,
    values,
    styles,
    handleSelect,
    isSelected,
    pointFormatter,
    groupName,
  } = props;
  const blue = "#035fa4";
  const { point, axis } = styles;
  const strokeWidth = 2;
  const sortedValues = values.sort();

  const lineX = scale(date);
  const rectX = lineX - styles.point.width / 2;

  const stemY1 = calculateY(styles, axis.y);
  const stemY2 = calculateY(styles, axis.y + point.stemHeight);
  const rectY = calculateY(styles, axis.y + point.stemHeight + point.height);

  const textCenterHeight = point.height / 2 - strokeWidth;
  const textY = calculateY(
    styles,
    axis.y + point.stemHeight + textCenterHeight
  );

  const itemClassName = classnames([
    "item",
    {
      selected: isSelected,
    },
  ]);

  const textClassName = classnames([
    "label",
    {
      selected: isSelected,
    },
  ]);

  if (values.length > 1) {
  }

  if (!color) {
    color = "#999999";
  }
  if (isSelected) {
    color = blue;
  }

  return (
    <g
      className={itemClassName}
      onClick={() => {
        handleSelect(date);
      }}
    >
      {sortedValues.map((value: any, index) => {
        let val;
        if (index > 0) {
          val = `${index + 1} ${groupName}`;
        } else {
          val = pointFormatter ? pointFormatter(value) : value;
        }
        return (
          <DisplayBox
            key={index}
            rectX={rectX}
            point={point}
            rectY={rectY}
            isSelected={isSelected}
            lineX={lineX}
            textY={textY}
            textClassName={textClassName}
            value={val}
            yOffset={index * 5}
            color={color}
          />
        );
      })}
      <line x1={lineX} x2={lineX} y1={stemY1} y2={stemY2} stroke={color} />
    </g>
  );
}
