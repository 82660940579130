import React from "react";
import { useSelector } from "../../../../store/store";
import { PlanCode } from "../../../../models/plan/plan-info-model";

type Props = {
  seniorityAge?: number;
};

export default function EstimatedDistributionAccountDisclaimer(props: Props) {
  const planCode = useSelector((state) => state.plan.info.planCode);
  const { seniorityAge } = props;
  if (planCode === "EXCESS" || planCode.toLowerCase() === PlanCode.type457b) {
    return (
      <p>
        The estimated distribution(s) are based on your election for Separation
        from Service {seniorityAge && <>after age {seniorityAge}</>} and your
        current account balance.
      </p>
    );
  }

  return null;
}
