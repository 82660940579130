import React from "react";
import DateDisplay from "../../../components/date-display/date-display";
import {
  Panel,
  PanelBody,
  PanelHeading,
} from "../../../components/panel/panel";
import { useInvestments } from "../../../selectors/investments";
import { ACCOUNT_CATEGORY_PRE_409A } from "../../../util/constants/account-category-409A";
import ActivityByContribSource from "../panels/activity-by-contrib-source";
import ActivityByInvestment from "../panels/activity-by-investment";
import InvestmentAllocations from "../panels/investment-allocations";
import "./account-display.scss";
import AccountName from "../../../components/account-name/account-name";

function AccountDisplay(props) {
  const { account } = props;
  const { investments } = useInvestments();

  const filteredInvestments = investments.filter(
    (inv) => inv.accountId === account.id
  );
  const hasInvestments = filteredInvestments.length > 0;

  return (
    <section className="print-break-before">
      <div className="row">
        <div className={"col-md-12"}>
          <div className="account-display">
            <h3 className="h2">
              <AccountName name={account.name} id={account.id} asLink={false} />
            </h3>
            <span className="account409info">
              This account is{" "}
              {account.category409A === ACCOUNT_CATEGORY_PRE_409A && "not"}{" "}
              subject to 409A.
            </span>
            <span className="date-display">
              <DateDisplay />
            </span>
          </div>
        </div>
      </div>

      {hasInvestments ? (
        <AccountTables filteredInvestments={filteredInvestments} />
      ) : (
        <NoValuesMessage />
      )}
    </section>
  );
}

function NoValuesMessage() {
  return (
    <div className="row">
      <div className={"col-md-12"}>
        <span>This account has no associated investments.</span>
      </div>
    </div>
  );
}

function AccountTables(props) {
  const { filteredInvestments } = props;

  return (
    <>
      <div className="row">
        <div className={"col-md-12"}>
          <Panel className={"table-wrapper statement-overflow"}>
            <PanelHeading>Account activity by contribution source</PanelHeading>
            <PanelBody>
              <ActivityByContribSource investments={filteredInvestments} />
            </PanelBody>
          </Panel>
        </div>
      </div>

      <div className="row">
        <div className={"col-md-12"}>
          <Panel className={"table-wrapper statement-overflow"}>
            <PanelHeading>Account activity by investment</PanelHeading>
            <PanelBody>
              <ActivityByInvestment investments={filteredInvestments} />
            </PanelBody>
          </Panel>
        </div>
      </div>

      <div className="row">
        <div className={"col-md-12"}>
          <Panel className={"table-wrapper statement-overflow"}>
            <PanelHeading date>
              Investment allocations
              <strong>
                <sup>*</sup>
                <span className="sr-only">, footnote below</span>
              </strong>
            </PanelHeading>
            <PanelBody>
              <InvestmentAllocations investments={filteredInvestments} />
              <p>
                <strong>
                  <span className="sr-only">Footnote </span>
                  <sup>*</sup>
                </strong>
                &nbsp;The above reflects instructions on file as of the end of
                the reporting period.
              </p>
            </PanelBody>
          </Panel>
        </div>
      </div>
    </>
  );
}

export default AccountDisplay;
