import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AsyncStatus } from "../models/async-status";
import { BusinessDate } from "../models/business-date";
import { getLastBusinessDate } from "../services/last-business-date";

export const fetchLastBusinessDate = createAsyncThunk(
  "fetch-last-business-date",
  async () => {
    return getLastBusinessDate();
  }
);

type State = {
  lastBusinessDate?: string;
  startDate?: string;
  endDate?: string;
  investmentSummaryStartDate?: string;
  investmentSummaryEndDate?: string;
  status: AsyncStatus;
};

const initialState: State = {
  lastBusinessDate: undefined,
  startDate: undefined,
  endDate: undefined,
  status: AsyncStatus.not_fetched,
  investmentSummaryStartDate: undefined,
  investmentSummaryEndDate: undefined,
};

const datesSlice = createSlice({
  name: "dates",
  initialState,
  reducers: {
    setEndDate: (state, action: { payload: Date; type: string }) => ({
      ...state,
      endDate: action.payload.toISOString(),
    }),
    setStartDate: (state, action: { payload: Date; type: string }) => ({
      ...state,
      startDate: action.payload.toISOString(),
    }),
    setInvestmentSummaryDates: (
      state,
      action: {
        payload: {
          investmentSummaryStartDate: string;
          investmentSummaryEndDate: string;
        };
      }
    ) => ({
      ...state,
      investmentSummaryStartDate: action.payload.investmentSummaryStartDate,
      investmentSummaryEndDate: action.payload.investmentSummaryEndDate,
    }),
    setDates: (
      state,
      action: { payload: { startDate: string; endDate: string }; type: string }
    ) => ({
      ...state,
      startDate: action.payload.startDate,
      endDate: action.payload.endDate,
    }),
    resetEndDate: (state, action: { type: string }) => ({
      ...state,
      endDate: state.lastBusinessDate,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLastBusinessDate.pending, (state, action) => {
      return { ...state, status: AsyncStatus.loading };
    });
    builder.addCase(
      fetchLastBusinessDate.fulfilled,
      (state, action: { payload: BusinessDate; type: string }) => {
        const businessDate = action.payload;
        const emptyTime = "T00:00:00.000Z";
        const lastBusinessDateInISO = businessDate.lastBusinessDate + emptyTime;
        const startDateInISO = businessDate.startDate + emptyTime;
        return {
          ...state,
          endDate: lastBusinessDateInISO,
          lastBusinessDate: lastBusinessDateInISO,
          startDate: startDateInISO,
          status: AsyncStatus.done,
        };
      }
    );
    builder.addCase(fetchLastBusinessDate.rejected, (state, action) => {
      return { ...state, status: AsyncStatus.error };
    });
    builder.addDefaultCase((state, action) => state);
  },
});

export const {
  setEndDate,
  setStartDate,
  setDates,
  setInvestmentSummaryDates,
  resetEndDate,
} = datesSlice.actions;
export default datesSlice.reducer;
