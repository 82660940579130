import { StatementBalance } from "../models/statements/statement-balance";
import {
  getStatementBalance,
  GetStatementBalanceArgs,
} from "../services/statement-balance";
import { AsyncStatus } from "../models/async-status";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const fetchStatementBalance = createAsyncThunk(
  "fetch-statement-balances",
  async (args: GetStatementBalanceArgs) => {
    return getStatementBalance(args);
  }
);

type statementBalanceState = {
  data: StatementBalance;
  status: AsyncStatus;
};
const initialState: statementBalanceState = {
  data: {} as StatementBalance,
  status: AsyncStatus.not_fetched,
};

const sessionSlice = createSlice({
  name: "statementBalance",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchStatementBalance.pending, (state, action) => {
      return { ...state, status: AsyncStatus.loading };
    });
    builder.addCase(fetchStatementBalance.fulfilled, (state, action) => {
      return { ...state, data: action.payload, status: AsyncStatus.done };
    });
    builder.addCase(fetchStatementBalance.rejected, (state, action) => {
      return { ...state, status: AsyncStatus.error };
    });
    builder.addDefaultCase((state, action) => state);
  },
});

export default sessionSlice.reducer;
