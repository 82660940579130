import { get } from "../repositories/participant";
import { SourceBalanceRequest } from "../models/balances-by-source/source-balance-request";

export async function getSourceBalanceDetails(
  planId: number,
  empId: number,
  role: string,
  effectiveDate: string,
  previousEffectiveDate: string,
  accountIds: string
): Promise<SourceBalanceRequest> {
  return get(`/plans/${planId}/employees/${empId}/balance-by-source-details`, {
    role,
    effectiveDate,
    previousEffectiveDate,
    accountIds,
  });
}
