import React from "react";
import { Beneficiary } from "../../../../models/beneficiaries/beneficiary";

type ViewDetailsProps = {
  beneficiaryType: string;
  index: number;
  beneficiaryToUse: Beneficiary;
};

export function ViewDetailsTable(props: ViewDetailsProps) {
  const { beneficiaryType, index, beneficiaryToUse } = props;

  return (
    <div className={"table-responsive"}>
      <table className={"table"}>
        <tbody>
          <tr>
            <th>Full name:</th>
            <td data-testid={`${beneficiaryType}-name-${index}`}>
              {beneficiaryToUse.beneficiaryInfo.name}
            </td>
          </tr>
          <tr>
            <th>Type:</th>
            <td data-testid={`${beneficiaryType}-type-${index}`}>
              {beneficiaryToUse.beneficiaryInfo.type}
            </td>
          </tr>
          <tr>
            <th>Relationship:</th>
            <td data-testid={`${beneficiaryType}-relationship-${index}`}>
              {beneficiaryToUse.beneficiaryInfo.relationship}
            </td>
          </tr>
          <tr>
            <th>Address:</th>
            <td data-testid={`${beneficiaryType}-address-${index}`}>
              {beneficiaryToUse.beneficiaryInfo.address.addressLine1}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
