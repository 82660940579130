import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AsyncStatus } from "../../models/async-status";
import { MoneyTypes } from "../../models/plan/money-types";
import { getMoneyTypes } from "../../services/money-types";

export const fetchMoneyTypes = createAsyncThunk(
  "fetch-money-types",
  async (planId: number, thunkApi) => {
    return getMoneyTypes(planId);
  }
);

const initialState: MoneyTypes = {
  data: [],
  status: AsyncStatus.not_fetched,
};

const moneyTypesSlice = createSlice({
  name: "money-types",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMoneyTypes.pending, (state, action) => {
      return { ...state, status: AsyncStatus.loading };
    });
    builder.addCase(fetchMoneyTypes.fulfilled, (state, action) => {
      return { ...state, data: action.payload, status: AsyncStatus.done };
    });
    builder.addCase(fetchMoneyTypes.rejected, (state, action) => {
      return { ...state, status: AsyncStatus.error };
    });
    builder.addDefaultCase((state, action) => state);
  },
});

export default moneyTypesSlice.reducer;
