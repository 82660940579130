import React from "react";
import { ForecastDistribution } from "../../../models/distributions/forecast-distribution";
import { currencyFormatter } from "../../../util/currency";
import { formatDateWithoutTimeZone, parseDate } from "../../../util/date-util";
import { PrrCalculationModal } from "../../../components/modal/prr-calculation-modal";

type Props = {
  distributions: ForecastDistribution[];
  rateOfReturn: number;
};

export function DetailedDistributionsOccurringTable(props: Props) {
  const { distributions, rateOfReturn } = props;

  return (
    <>
      <table className="table">
        <tbody>
          <tr>
            <td className="text-bold">Distribution Date</td>
            <td className="text-bold">Distribution Status</td>
            <td className="text-bold">Installment</td>
            <td className="text-bold">Estimated Account Balance</td>
            <td className="text-bold">Estimated Payout</td>
          </tr>
          {distributions.map((distribution) => {
            return (
              <tr>
                <td>
                  {formatDateWithoutTimeZone(
                    parseDate(distribution.payoutDate),
                    "M/dd/yyyy"
                  )}
                </td>
                <td>{distribution.distributionStatus}</td>
                <td>{distribution.distributionPayout}</td>
                <td>
                  {currencyFormatter(distribution.estimatedAccountBalance)}
                  <PrrCalculationModal
                    distribution={distribution}
                    rateOfReturn={rateOfReturn}
                  />
                </td>
                <td>{currencyFormatter(distribution.payoutAmount)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}
