import React from "react";
import AssetClassModal from "../../../../components/asset-class-modal/asset-class-modal";
import "./asset-class-risk-spectrum.scss";
import AssetRiskImage from "./asset-risk.gif";

const AssetClassRiskSpectrum = () => {
  return (
    <div
      data-testid="asset-class-risk-spectrum"
      className="asset-class-risk-spectrum"
    >
      <div className="width-100">
        <img src={AssetRiskImage} alt="" />
      </div>
      <div className="width-100">
        <AssetClassModal />
      </div>
      <div className="width-100">
        <p>
          Note: Investments in the "Other" category are not assessed in the risk
          spectrum.
        </p>
      </div>
    </div>
  );
};

export default AssetClassRiskSpectrum;
