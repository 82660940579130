import { parseISO } from "date-fns";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { isLoadingStatus } from "../../../models/async-status";
import { setDates } from "../../../reducers/dates";
import { useDates } from "../../../selectors/dates";
import {
  formatDateWithoutTimeZone,
  getEndOfMonth,
  getStartOfMonth,
  subtractMonths,
} from "../../../util/date-util";
import StatementDate from "../statement-date";
import "./date-selector.scss";

export default function DateSelector() {
  const dispatch = useDispatch();
  const { endDate, startDate, lastBusinessDate, status } = useDates();
  const isLoading = isLoadingStatus(status);

  const [tempStartDate, setTempStartDate] = useState<Date>();
  const [tempEndDate, setTempEndDate] = useState<Date>();
  const [selectedPeriod, setSelectedPeriod] = useState<string>();

  function handleSubmit() {
    let start = tempStartDate
      ? tempStartDate
      : formatDateWithoutTimeZone(startDate!);
    let end = tempEndDate ? tempEndDate : formatDateWithoutTimeZone(endDate!);

    if (selectedPeriod === "month") {
      start = getStartOfPeriod(1);
    }

    if (selectedPeriod === "quarter") {
      start = getStartOfPeriod(3);
    }

    if (selectedPeriod === "six-month") {
      start = getStartOfPeriod(6);
    }

    if (selectedPeriod === "year") {
      start = getStartOfPeriod(12);
    }

    if (selectedPeriod !== "custom") {
      end = getEndOfPreviousMonth();
    }

    dispatch(
      setDates({
        startDate: formatDateWithoutTimeZone(start, "yyyy-MM-dd"),
        endDate: formatDateWithoutTimeZone(end, "yyyy-MM-dd"),
      })
    );
  }

  function getStartOfPeriod(monthsToSubtract: number) {
    return getStartOfMonth(subtractMonths(new Date(), monthsToSubtract));
  }

  function getEndOfPreviousMonth() {
    return getEndOfMonth(subtractMonths(new Date(), 1));
  }

  function handleClick(period: string) {
    if (selectedPeriod !== period) {
      setSelectedPeriod(period);
    }
  }

  return (
    <>
      <div className="btn-group date-btn-group" role="group">
        <DateSelectButton
          handleClick={handleClick}
          selectedPeriod={selectedPeriod}
          period={"month"}
        >
          Last month
        </DateSelectButton>
        <DateSelectButton
          handleClick={handleClick}
          selectedPeriod={selectedPeriod}
          period={"quarter"}
        >
          Last 3 months
        </DateSelectButton>
        <DateSelectButton
          handleClick={handleClick}
          selectedPeriod={selectedPeriod}
          period={"six-month"}
        >
          Last 6 months
        </DateSelectButton>
        <DateSelectButton
          handleClick={handleClick}
          selectedPeriod={selectedPeriod}
          period={"year"}
        >
          Last 12 months
        </DateSelectButton>
        <DateSelectButton
          handleClick={handleClick}
          selectedPeriod={selectedPeriod}
          period={"custom"}
        >
          Custom
        </DateSelectButton>
      </div>

      {selectedPeriod === "custom" && (
        <StatementDate
          lastBusinessDate={parseISO(lastBusinessDate!)}
          startDate={tempStartDate || parseISO(startDate!)}
          setStartDate={(date: Date, e: React.PointerEvent) => {
            e.preventDefault();
            setTempStartDate(date);
          }}
          endDate={tempEndDate || parseISO(endDate!)}
          setEndDate={(date: Date, e: React.PointerEvent) => {
            e.preventDefault();
            setTempEndDate(date);
          }}
        />
      )}

      <button
        className={"btn btn-primary generate-button"}
        onClick={handleSubmit}
        disabled={isLoading || !selectedPeriod}
        data-testid={"overview-generate-statement-button"}
      >
        Generate statement
        {isLoading && <i className="fa fa-spinner fa-spin" />}
      </button>
    </>
  );
}

type DateSelectButtonProps = {
  period: string;
  selectedPeriod?: string;
  handleClick: (period: string) => void;
  children: React.ReactNode;
};

function DateSelectButton(props: DateSelectButtonProps) {
  const { period, selectedPeriod, handleClick } = props;
  const isSelectedPeriod = period === selectedPeriod;

  return (
    <button
      type="button"
      data-testid={`${period}-button`}
      className={`btn btn-default ${isSelectedPeriod ? "active" : ""}`}
      onClick={() => handleClick(period)}
      aria-pressed={isSelectedPeriod ? "true" : "false"}
    >
      {props.children}
    </button>
  );
}
