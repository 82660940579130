import React from "react";
import { Page, PageHeader } from "../../../components/page";
import { DistributionDetails } from "./distribution-details";
import { AccountDistributionBalanceTotals } from "./distribution-details-balance-totals";
import "./distribution-summary-page.scss";

export default function DistributionSummaryPage() {
  return (
    <Page name="distribution-summary" printable loading={false}>
      <div className={"row"}>
        <div className={"col-sm-12 col-md-6 col-lg-6"}>
          <PageHeader name="distribution-summary">
            Distribution details
          </PageHeader>
        </div>
        <div className="col-sm-6 col-md-4 col-lg-4 col-md-offset-1 col-lg-offset-1">
          <AccountDistributionBalanceTotals />
        </div>
      </div>
      <br />
      <DistributionDetails />
    </Page>
  );
}
