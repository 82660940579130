import _round from "lodash/round";
import { ContributionType } from "../../../models/contributions";
import { PayCheckFrequency } from "./paycheck-frequency";
import { InvestmentV2 } from "../../../models/investment-v2";

export function calculateSalaryPercentage(
  payCheckAmount: number,
  basePayCheckAmount: number
): number {
  if (payCheckAmount !== 0) {
    return basePayCheckAmount / payCheckAmount;
  }
  return 0;
}

export function calculateTotalAnnualAmount(
  totalCheckAmount: number,
  payCheckFrequency: PayCheckFrequency
) {
  return totalCheckAmount * getPayChecksPerYear(payCheckFrequency);
}

export function calculatePaycheckAmount(
  salaryAmount: number,
  payCheckFrequency: PayCheckFrequency
) {
  switch (payCheckFrequency) {
    case PayCheckFrequency.BIMONTHLY:
      return _round(salaryAmount / 24, 2);
    case PayCheckFrequency.MONTHLY:
      return _round(salaryAmount / 12, 2);
    case PayCheckFrequency.WEEKLY:
      return _round(salaryAmount / 52, 2);
    case PayCheckFrequency.BIWEEKLY:
      return _round(salaryAmount / 26, 2);
    case PayCheckFrequency.ANNUALLY:
      return _round(salaryAmount / 1, 2);
    case PayCheckFrequency.QUARTERLY:
      return _round(salaryAmount / 4, 2);
    default:
      return _round(salaryAmount, 2);
  }
}

export function getPayChecksPerYear(payCheckFrequency: PayCheckFrequency) {
  switch (payCheckFrequency) {
    case PayCheckFrequency.BIMONTHLY:
      return 24;
    case PayCheckFrequency.MONTHLY:
      return 12;
    case PayCheckFrequency.WEEKLY:
      return 52;
    case PayCheckFrequency.BIWEEKLY:
      return 26;
    case PayCheckFrequency.ANNUALLY:
      return 1;
    case PayCheckFrequency.QUARTERLY:
      return 4;
    default:
      return 0;
  }
}

export function getPayFrequencyText(payCheckFrequency: PayCheckFrequency) {
  switch (payCheckFrequency) {
    case PayCheckFrequency.WEEKLY:
      return "every week";
    case PayCheckFrequency.BIWEEKLY:
      return "every other week";
    case PayCheckFrequency.MONTHLY:
      return "once per month";
    case PayCheckFrequency.BIMONTHLY:
      return "twice per month";
    case PayCheckFrequency.ANNUALLY:
      return "once every year";
    case PayCheckFrequency.QUARTERLY:
      return "once every quarter";
    default:
      return "not set";
  }
}

export type ContributionOutput = {
  name: string;
  contributionAmount: number | string;
  type: string;
  salaryPercentage?: number;
  contributionTypeId: number | string;
};

export function calculateContributionOutput(
  payCheckAmount: number,
  type: string,
  contributionAmount: number | string,
  name: string,
  contributionTypeId: number | string
): ContributionOutput {
  if (type === ContributionType.DOLLAR) {
    const contributionAmountNumber: number = Number(contributionAmount);
    return {
      name,
      contributionTypeId,
      contributionAmount: contributionAmountNumber,
      salaryPercentage: calculateSalaryPercentage(
        payCheckAmount,
        contributionAmountNumber
      ),
      type: ContributionType.DOLLAR,
    };
  }
  if (type === ContributionType.PERCENT) {
    const contributionAmountNumber: number = Number(contributionAmount);
    return {
      name,
      contributionTypeId,
      contributionAmount: calculateContributionAmountByPercent(
        payCheckAmount,
        contributionAmountNumber
      ),
      salaryPercentage: contributionAmountNumber / 100,
      type: ContributionType.PERCENT,
    };
  }
  return {
    name,
    contributionTypeId,
    contributionAmount: contributionAmount,
    type: ContributionType.OTHER,
  };
}

export function calculateContributionAmountByPercent(
  paycheckAmount: number,
  contributionAmountPercent: number
): number {
  return paycheckAmount * (contributionAmountPercent / 100);
}

export function calculateTotalContributionsThisYear(
  investments: InvestmentV2[]
): number {
  return investments.reduce(
    (sum, investment) => (investment.contributionAmt || 0) + sum,
    0
  );
}
