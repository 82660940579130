import React, { useEffect, useState } from "react";
import RedeferNavHeader from "../redefer-nav-header";
import { Skeleton } from "../../../../components/skeleton/skeleton";
import {
  accountRedeferralStatusesStatusSelector,
  redeferralInstructionDtosStatus,
  scheduledDistributionsForRedeferralStatus,
  useStatuses,
} from "../../../../selectors/status";
import { Page } from "../../../../components/page";
import { accountRedeferralStatusesSelector } from "../../../../selectors/redeferrals";
import { RedeferralStatus } from "../../../../models/redeferral/redeferral-status";
import { useAppDispatch, useSelector } from "../../../../store/store";
import { getRedeferralStateForAccount } from "../redefer-util";
import { useParams } from "react-router-dom";
import { DISTRIBUTION_REDEFERRAL_DISCLAIMER } from "../../../../util/constants";
import { FinalRedeferralInstruction } from "../../../../models/distributions/scheduled-distribution-event";
import {
  formatDateWithoutTimeZone,
  parseDate,
} from "../../../../util/date-util";
import Balance from "../../../../components/numbers/balance";
import { RedeferralInstructionDto } from "../../../../models/redeferral/redeferral-instruction-dto";
import { fetchRedeferralInstructionDtos } from "../../../../reducers/redeferral/redeferral-instruction-dto";
import { fetchScheduledDistributionsForRedeferral } from "../../../../reducers/redeferral/scheduledDistributionForRedeferral";

export default function RedeferIndividualPaymentConfirm() {
  const dispatch = useAppDispatch();
  const { isLoadingStatus } = useStatuses([
    redeferralInstructionDtosStatus,
    scheduledDistributionsForRedeferralStatus,
  ]);
  const { accountId } = useParams();
  const { empId, planId, role } = useSelector((state) => state.session);
  const [redeferralDistributions, setRedeferralDistributions] = useState<
    FinalRedeferralInstruction[]
  >([]);

  const allRedeferralStates: RedeferralStatus[] = useSelector(
    accountRedeferralStatusesSelector
  );
  const accountRedeferralState = getRedeferralStateForAccount(
    allRedeferralStates,
    accountId
  );
  const scheduledDistributionsForRedeferral = useSelector(
    (state) =>
      state.redeferrals.scheduledDistributionsForRedeferral
        .scheduledDistributionsForRedeferral
  );
  const redeferralInstructionDTOs = useSelector(
    (state) =>
      state.redeferrals.redeferralInstructionDtos.redeferralInstructionDTOs
  );

  useEffect(() => {
    const sessionString = sessionStorage.getItem("session_string");
    sessionStorage.clear();
    if (sessionString !== null) {
      sessionStorage.setItem("session_string", sessionString);
    }
  }, []);

  useEffect(() => {
    dispatch(
      fetchScheduledDistributionsForRedeferral({
        planId,
        empId,
        accountId: accountId,
        role,
      })
    );
    dispatch(
      fetchRedeferralInstructionDtos({
        accountId,
        role,
      })
    );
  }, [empId, planId, role, dispatch]);

  useEffect(() => {
    if (
      redeferralInstructionDTOs.length > 0 &&
      scheduledDistributionsForRedeferral.length > 0
    ) {
      setRedeferralDistributions(mapDataToRedeferralDistributions());
    }
  }, [redeferralInstructionDTOs, scheduledDistributionsForRedeferral]);

  function mapDataToRedeferralDistributions(): FinalRedeferralInstruction[] {
    const distributionForRedeferrals: FinalRedeferralInstruction[] = [];

    scheduledDistributionsForRedeferral.forEach((dist) => {
      const instruction = getInstruction(
        dist.installNum,
        redeferralInstructionDTOs
      );
      const distribution = {
        installNum: dist.installNum,
        estimatedDistribution: dist.estimatedDistribution,
        scheduledDate: dist.scheduledDate
          ? dist.scheduledDate
          : instruction?.previousPayoutStartDate,
        newDistributionDate: instruction ? instruction.payoutStartDate : null,
        effectiveAsOfDate: instruction ? instruction.effectiveAsOfDate : null,
      } as FinalRedeferralInstruction;
      distributionForRedeferrals.push(distribution);
    });

    return distributionForRedeferrals;
  }

  function getInstruction(
    installNum: number,
    instructions: RedeferralInstructionDto[]
  ): RedeferralInstructionDto | null {
    let instruction = null;
    instructions.forEach((inst) => {
      if (inst.installmentNumber === installNum) {
        instruction = inst;
      }
    });
    return instruction;
  }

  return (
    <>
      <Page name={"redefer-confirm"} loading={isLoadingStatus} printable>
        <RedeferNavHeader
          stepNumber={3}
          accountRedeferralState={accountRedeferralState}
        />
        <Skeleton
          selectors={[accountRedeferralStatusesStatusSelector]}
          height={90}
          width={180}
        >
          {accountRedeferralState && (
            <>
              <div className="row">
                <div className="col-md-12">
                  <p>
                    Your request to change your distribution election(s) has
                    been received. If you wish to cancel this change(s) please{" "}
                    {/* TODO: link to pending page*/}
                    <a href={""}>
                      view your pending distribution elections
                    </a>{" "}
                    and confirm your cancellation.
                  </p>
                  <div className="alert alert-warning">
                    <p>
                      Section 409A of the Internal Revenue Code imposes
                      restrictions when making a change to your scheduled
                      distribution election. This includes, but is not limited
                      to, a requirement that the change be requested at least
                      twelve months in advance of the scheduled date and be
                      delayed for a minimum of five years from the scheduled
                      date.
                    </p>
                  </div>

                  <p>
                    <strong>{accountRedeferralState.accountName}</strong>
                  </p>
                  <div style={{ marginBottom: 20 }}>
                    <table data-testid="redeferral-table" className="table">
                      <tbody>
                        <tr
                          style={{
                            background: "#caebfa",
                            fontWeight: "bold",
                            textAlign: "center",
                          }}
                        >
                          <td className="table-head">Distribution</td>
                          <td className="table-head">Estimated Distribution</td>
                          <td className="table-head">
                            Current Distribution Date
                          </td>
                          <td className="table-head">
                            Pending Distribution Date
                          </td>
                          <td className="table-head">New Distribution Date</td>
                          <td className="table-head">Effective Date</td>
                        </tr>
                        {redeferralDistributions.map(
                          (
                            scheduledDistribution: FinalRedeferralInstruction,
                            index
                          ) => {
                            return (
                              <tr
                                style={
                                  index % 2
                                    ? { background: "#f1f1f1" }
                                    : { background: "white" }
                                }
                              >
                                <td
                                  className="table-head"
                                  style={{ textAlign: "center" }}
                                >
                                  {scheduledDistribution.installNum}
                                </td>
                                <td
                                  className="table-head"
                                  style={{ textAlign: "center" }}
                                >
                                  <Balance
                                    showDollar={true}
                                    value={
                                      scheduledDistribution.estimatedDistribution
                                    }
                                  />
                                </td>
                                <td
                                  className="table-head"
                                  style={{ textAlign: "center" }}
                                >
                                  {formatDateWithoutTimeZone(
                                    scheduledDistribution.scheduledDate,
                                    "MM/dd/yyy"
                                  )}
                                </td>
                                <td
                                  className="table-head"
                                  style={{ textAlign: "center" }}
                                >
                                  {formatDateWithoutTimeZone(
                                    scheduledDistribution.pendingDistributionDate,
                                    "MM/dd/yyy"
                                  )}
                                </td>
                                <td
                                  className="table-head"
                                  style={{ textAlign: "center" }}
                                >
                                  {formatDateWithoutTimeZone(
                                    scheduledDistribution.newDistributionDate,
                                    "MM/dd/yyy"
                                  )}
                                </td>
                                <td
                                  className="table-head"
                                  style={{ textAlign: "center" }}
                                >
                                  {formatDateWithoutTimeZone(
                                    scheduledDistribution.effectiveAsOfDate,
                                    "MM/dd/yyy"
                                  )}
                                </td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </table>
                  </div>
                  <hr />
                  <p>
                    You can view and change Distribution Elections for other
                    accounts, if applicable, from the {""}
                    <a href={"/distributions/summary"}>
                      Distribution Elections Summary.
                    </a>
                  </p>
                </div>
                <br />
                <div className="col-md-12">
                  <p>
                    The Estimated Distribution amount(s) listed are based upon
                    your current account balance and do not factor in a vesting
                    schedule, if applicable. Your actual distribution may be
                    different than the amount(s) listed above.
                  </p>
                  {DISTRIBUTION_REDEFERRAL_DISCLAIMER}
                </div>
              </div>
            </>
          )}
        </Skeleton>
      </Page>
    </>
  );
}
