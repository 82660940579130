import { useEffect } from "react";
import { AccountBalanceByDate } from "../models/account-balances-by-date";
import { AsyncStatus } from "../models/async-status";
import { fetchAccountsBalancesByDate } from "../reducers/account-balances-by-date";
import { useAppDispatch, useSelector } from "../store/store";
import { datesSelector } from "./dates";
import { sessionSelector } from "./session";

export function useAccountBalances(startDate?: string): {
  accountBalancesByDate: AccountBalanceByDate[];
  status: AsyncStatus;
} {
  const dispatch = useAppDispatch();
  const { planId, empId, role } = useSelector(sessionSelector);
  const { startDate: defaultStartDate, endDate } = useSelector(datesSelector);
  const actualStartDate = startDate || defaultStartDate;
  const { data, lastFetchedStartDate, lastFetchedEndDate, status } =
    useSelector((state) => state.accountBalances);

  useEffect(() => {
    if (
      actualStartDate !== lastFetchedStartDate ||
      endDate !== lastFetchedEndDate
    ) {
      dispatch(
        fetchAccountsBalancesByDate({
          planId,
          empId,
          role,
          startDate: actualStartDate,
          endDate,
        })
      );
    }
  }, [
    dispatch,
    planId,
    empId,
    role,
    actualStartDate,
    endDate,
    lastFetchedStartDate,
    lastFetchedEndDate,
  ]);

  return { accountBalancesByDate: data, status };
}
