import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AsyncStatus } from "../models/async-status";
import { getNqAccounts } from "../services/enrollment";
import { NqAccount } from "../models/nq-accounts.model";

export const fetchNqMultipleAccountsDetails = createAsyncThunk(
  "fetch-nq-multiple-accounts-details",
  async ({ enterpriseId }: { enterpriseId: string }, thunkApi) => {
    return getNqAccounts(enterpriseId);
  }
);

type State = {
  data: NqAccount[];
  status: AsyncStatus;
};

const initialState: State = {
  data: [],
  status: AsyncStatus.not_fetched,
};

const nqMultipleAccountDetails = createSlice({
  name: "nqAccountDetails",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchNqMultipleAccountsDetails.pending, (state, action) => {
      return { ...state, status: AsyncStatus.loading };
    });
    builder.addCase(
      fetchNqMultipleAccountsDetails.fulfilled,
      (state, action: { payload: NqAccount[]; type: string }) => {
        return { ...state, data: action.payload, status: AsyncStatus.done };
      }
    );
    builder.addCase(
      fetchNqMultipleAccountsDetails.rejected,
      (state, action) => {
        return { ...state, status: AsyncStatus.error };
      }
    );
    builder.addDefaultCase((state, action) => state);
  },
});

export default nqMultipleAccountDetails.reducer;
