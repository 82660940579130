import _get from "lodash/get";
import _groupBy from "lodash/groupBy";
import { InvestmentBalance } from "../models/investment-balance";
import { InvestmentV2 } from "../models/investment-v2";
import { MoneyType } from "../models/moneytype";
import { calculateTotalEndBalanceV2, sortInvestments } from "./investments";

export function filterInvestmentsByAccountIds(
  investments: InvestmentV2[],
  selectedAccountIds: number[]
): InvestmentV2[] {
  return investments.filter(
    ({ accountId }) => accountId && selectedAccountIds.includes(accountId)
  );
}

export function getInvestmentByMoneyType(
  investments: InvestmentV2[] = [],
  moneyTypes: MoneyType[] = []
) {
  const aggregatedInvestments = flatten(aggregateByMoneyTypeId(investments));
  // @ts-ignore
  const aggregatedByMoneyType = aggregatedInvestments.map((investment) => {
    const match = moneyTypes.find(
      (moneyType) => investment.moneyTypeId === moneyType.moneyTypeId
    );
    if (match) {
      return { ...investment, moneyTypeName: match.moneyName };
    }
    return { ...investment };
  });

  return filterEmptyInvestments(aggregatedByMoneyType);
}

export function getSortedInvestments(filteredInvestments: InvestmentV2[] = []) {
  const investmentsWithBalance = filterEmptyInvestments(filteredInvestments);

  const aggregatedInvestments = flatten(
    aggregateByInvestmentId(investmentsWithBalance)
  );

  const totalBalance = calculateTotalEndBalanceV2(aggregatedInvestments);
  const investmentsWithBalanceRatio = aggregatedInvestments.map(
    (investment: InvestmentV2) => {
      if (investment.endBalance) {
        return {
          ...investment,
          balanceRatio:
            investment.endBalance.cashBalance.balance / totalBalance,
        };
      }
      return investment;
    }
  );
  return sortInvestments(investmentsWithBalanceRatio);
}

export const reduceByMoneyTypeId = reduceBy("moneyTypeId");
export const reduceByInvestmentId = reduceBy("id");
export const reduceByAccountId = reduceBy("accountId");

type InvestmentsReducer = (investments: InvestmentV2[]) => InvestmentV2[];

export function reduceBy(name: string): InvestmentsReducer {
  return (investments: InvestmentV2[] = []) => {
    const aggregated = aggregateBy(name)(investments);
    return flatten(aggregated);
  };
}

type Aggregator = (arr: any[]) => any[][];
export const aggregateByAccountId: Aggregator = aggregateBy("accountId");
export const aggregateByMoneyTypeId: Aggregator = aggregateBy("moneyTypeId");
export const aggregateByAssetClass: Aggregator = aggregateBy("assetClass");
export const aggregateByInvestmentId: Aggregator = aggregateBy("id");

function aggregateBy(by: string): Aggregator {
  return (arr = []) => {
    const investmentsObject = _groupBy(arr, by);
    return Object.values(investmentsObject);
  };
}

export function flatten(investments: InvestmentV2[][] = []): InvestmentV2[] {
  return investments.map(reduceInvestments);
}

export function reduceInvestments(
  investments: InvestmentV2[] = []
): InvestmentV2 {
  if (investments.length === 0) {
    return {} as InvestmentV2;
  }
  return investments.reduce(addInvestments);
}

export function mapInvestmentsToAssetChart(investments: InvestmentV2[] = []) {
  const investmentsWithBalance = filterEmptyInvestments(investments);

  const aggregatedInvestments = flatten(
    aggregateByInvestmentId(investmentsWithBalance)
  );

  const totalBalance = calculateTotalEndBalanceV2(aggregatedInvestments);
  const investmentsWithBalanceRatio = aggregatedInvestments.map((obj) => {
    if (obj.endBalance) {
      return {
        ...obj,
        balanceRatio: obj.endBalance.cashBalance.balance / totalBalance,
      };
    }
    return obj;
  });
  return sortInvestments(investmentsWithBalanceRatio);
}

export function filterEmptyInvestments(
  investments: InvestmentV2[] = []
): InvestmentV2[] {
  return investments.filter((investment) => {
    const endBalance = _get(investment, "endBalance.cashBalance.balance");
    const startBalance = _get(investment, "beginBalance.cashBalance.balance");
    const transfer = _get(investment, "transferAmt");

    return endBalance || startBalance || transfer;
  });
}

function addBalances(i1?: InvestmentBalance, i2?: InvestmentBalance) {
  return {
    cashBalance: {
      vestedBalance: add(i1, i2, "cashBalance.vestedBalance"),
      balance: add(i1, i2, "cashBalance.balance"),
      vestedPercent: _get(i1, "cashBalance.vestedPercent", 0),
    },
    shareBalance: {
      balance: add(i1, i2, "shareBalance.balance"),
      totalBalance: add(i1, i2, "shareBalance.totalBalance"),
    },
  } as InvestmentBalance;
}

export function addInvestments(
  i1: InvestmentV2,
  i2: InvestmentV2
): InvestmentV2 {
  if (!i1) {
    return i2;
  }
  return {
    ...i1,
    sharePrice: i1.sharePrice ? i1.sharePrice : i2.sharePrice,
    averageYearlyContributionAmt: add(i1, i2, "averageYearlyContributionAmt"),
    contributionAmtToDate: add(i1, i2, "contributionAmtToDate"),
    contributionAmt: add(i1, i2, "contributionAmt"),
    distributionAmt: add(i1, i2, "distributionAmt"),
    dividendAmt: add(i1, i2, "dividendAmt"),
    earningAmt: add(i1, i2, "earningAmt"),
    forfeitAmt: add(i1, i2, "forfeitAmt"),
    transferAmt: add(i1, i2, "transferAmt"),
    ajcaTransferInAmt: add(i1, i2, "ajcaTransferInAmt"),
    ajcaTransferOutAmt: add(i1, i2, "ajcaTransferOutAmt"),
    companyTransferInAmt: add(i1, i2, "companyTransferInAmt"),
    companyTransferOutAmt: add(i1, i2, "companyTransferOutAmt"),
    netTransactionAmt: add(i1, i2, "netTransactionAmt"),
    feeAmt: add(i1, i2, "feeAmt"),
    timeWeightedBalance: add(i1, i2, "timeWeightedBalance"),
    beginBalance: addBalances(i1.beginBalance, i2.beginBalance),
    endBalance: addBalances(i1.endBalance, i2.endBalance),
  };
}

function add(
  i1: InvestmentBalance | InvestmentV2 | undefined,
  i2: InvestmentBalance | InvestmentV2 | undefined,
  accessor: string
) {
  return _get(i1, accessor, 0) + _get(i2, accessor, 0);
}
