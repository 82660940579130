import classNames from "classnames";
import React, { useState } from "react";
import BSModal from "react-bootstrap/Modal";
import "./modal.scss";

type Props = {
  linkText?: React.ReactNode | string;
  infoIcon?: boolean;
  title: string;
  children: React.ReactNode;
  size?: "sm" | "lg" | "xl";
  footer?: boolean;
  iconClassName?: string;
  asButtonCss?: boolean;
  className?: string;
};

Modal.defaultProps = {
  size: "lg",
  infoIcon: true,
  iconClassName: "icon-blue",
};

export function Modal(props: Props) {
  const {
    linkText,
    title,
    children,
    size,
    footer,
    iconClassName,
    infoIcon,
    asButtonCss,
    className,
  } = props;

  const [show, setShow] = useState(false);

  function showModal(e: React.MouseEvent) {
    setShow(true);
  }

  const buttonClass = classNames(
    {
      "btn btn-primary": asButtonCss,
      "modal-link": !asButtonCss,
    },
    className
  );

  return (
    <>
      <button
        className={buttonClass}
        onClickCapture={showModal}
        data-testid={"generic-modal-button"}
      >
        {linkText}
        {infoIcon && (
          <i
            className={`modal-link-icon fa fa-info-circle ${iconClassName}`}
            aria-hidden="true"
          />
        )}
      </button>
      <BSModal
        show={show}
        size={size}
        backdropClassName="modal-backdrop"
        animation={false}
        onHide={() => setShow(false)}
      >
        <BSModal.Header closeButton>
          <BSModal.Title as={"h2"}>{title}</BSModal.Title>
        </BSModal.Header>

        <BSModal.Body>{children}</BSModal.Body>
        {footer && (
          <BSModal.Footer>
            <button
              className="btn btn-primary"
              type="button"
              onClick={() => setShow(false)}
            >
              Close
            </button>
          </BSModal.Footer>
        )}
      </BSModal>
    </>
  );
}
