import { html, nothing } from 'lit';
import {
  property,
  query,
  queryAssignedElements,
  state,
} from 'lit/decorators.js';
import * as focusTrap from 'focus-trap';
import { pdsCustomElement as customElement } from '../../decorators/pds-custom-element';
import { PdsElement } from '../PdsElement';
import '../button/button';
import '../global-header-link-item/global-header-link-item';
import '../global-header-mobile-tray/global-header-mobile-tray';
import '../layout-container/layout-container';
import '@principal/design-system-icons-web/menu';
import '@principal/design-system-icons-web/x';
import '../logo/logo';
import '../user-avatar/user-avatar';
import '../global-header-user-menu/global-header-user-menu';
import '../global-header-dropdown-link/global-header-dropdown-link';
import styles from './global-header.scss?inline';

/**
 * @summary Principal's global header
 *
 * @slot logo Optional: The navigation's logo area (defaulting to the Principal logo), restricted to pds-logo, img, and svg elements
 * @slot default Optional: The navigation's main content area (dropdowns, links, search, etc), restricted to pds-list elements
 * @slot utility Optional: The navigation's utility area, defaulting to a global-header-user-menu component
 * @slot user-menu-links Optional: User menu links to be displayed in the user menu dropdown/mobile tray
 *
 * @fires pds-global-header-click Custom event dispatched on link click (used for logo and skiplink)
 * @fires pds-global-header-hamburger-menu-open Custom event dispatched on opening the hamburger menu
 */

@customElement('pds-global-header', {
  category: 'component',
  type: 'component',
  styles,
})
export class PdsGlobalHeader extends PdsElement {
  /**
   * Controls rendering of the global header within a PDS layout container
   * - **default** uses the default configuration layout container
   * - **narrow** uses the narrow variant layout container
   * - **none** does not render the global header witin a layout container
   */
  @property()
  layoutContainer: 'default' | 'narrow' | 'none' = 'default';

  /**
   * Allows menu items to stay in main header at sm/md breakpoints instead of moving into the mobile tray
   */
  @property({ type: Boolean })
  breakFaster: boolean = false;

  /**
   * Flag for authenticated/non-authenticated content.
   */
  @property({ type: Boolean })
  loggedIn: boolean = false;

  /**
   * Link to the main content, or use 'none' to disable the skip content link
   */
  @property({ type: String })
  skipToMainContentHref: string = '#main';

  /**
   * Link for the logo
   */
  @property({ type: String })
  logoHref: string = 'https://www.principal.com';

  /**
   * Custom aria label describing the logo element
   * - Default value is "Link to Principal homepage"
   */
  @property()
  logoAriaLabel: string;

  /**
   * User's name to display in the user menu
   */
  @property()
  fullName: string;

  /**
   * User's initials to display in the user menu
   */
  @property()
  initials: string;

  /**
   * Flag to display notifications in the user menu
   */
  @property()
  hasNotifications: boolean;

  /**
   * Tracks the open/closed state of the hamburger menu
   * @internal
   */
  @state()
  isHamburgerMenuActive: boolean = false;

  /**
   * @internal
   */
  @state()
  trap: focusTrap.FocusTrap;

  /**
   * Retrieves the menu items from the default slot
   * @internal
   */
  @queryAssignedElements({ slot: undefined, selector: 'pds-list' })
  mainMenuList: HTMLElement[];

  /**
   * @internal
   */
  @query('pds-global-header-mobile-tray')
  mobileTray: HTMLElement;

  /**
   * @internal
   */
  @query('.pds-c-global-header__hamburger-menu-button')
  hamburgerMenuBtnElement: HTMLElement;

  /**
   * @internal
   */
  get classNames() {
    return {
      breakFaster: this.breakFaster === true,
    };
  }

  connectedCallback() {
    super.connectedCallback();
    this.initLocalization();
    this.addEventListener(
      'pds-global-header-mobile-tray-close',
      this.closeHamburgerButton,
    );
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.removeEventListener(
      'pds-global-header-mobile-tray-close',
      this.closeHamburgerButton,
    );
  }

  /**
   * If the user passes in a custom aria-label, that will be populated.
   * If not, the label will be automated and language localized.
   * @internal
   */
  getLogoAriaLabel() {
    if (this.logoAriaLabel) {
      return this.logoAriaLabel;
    }
    const localizedAriaLabel = this.translateText('link-to-principal-homepage');
    return localizedAriaLabel;
  }

  /**
   * Adds variant, size attributes and inline style to slotted pds-list elements
   * @internal
   */
  addAttributesToMainMenu() {
    if (this.mainMenuList && this.mainMenuList.length !== 0) {
      if (!this.isMobile() || this.isBreakFaster()) {
        this.mainMenuList[0].setAttribute('orientation', 'horizontal');
      } else {
        this.mainMenuList[0].removeAttribute('orientation');
      }

      this.mainMenuList[0].setAttribute('spacing', 'none');
      this.mainMenuList[0].classList.add(this.classEl('main-list'));

      const listItems = this.getMainMenuListItems();

      if (listItems && listItems.length > 0) {
        listItems.forEach((item) => {
          // for the mobile tray, the list items need flex-flow: column in order to span the width of the content slot
          if (this.isMobile()) {
            // eslint-disable-next-line no-param-reassign
            item.shadowRoot!.querySelector('li')!.style.flexFlow = 'column';
          } else {
            // eslint-disable-next-line no-param-reassign
            item.shadowRoot!.querySelector('li')!.style.flexFlow = '';
          }
        });
      }
    }

    return '';
  }

  addStylingClasses() {
    const listItems = this.getMainMenuListItems();
    if (listItems && listItems.length > 0) {
      listItems.forEach((item) => {
        const slot = item.shadowRoot!.querySelector('slot');
        const slotElement = slot!.assignedElements()[0];
        const { tagName } = slotElement;

        if (
          tagName === 'PDS-GLOBAL-HEADER-LINK-ITEM' ||
          tagName === 'PDS-GLOBAL-HEADER-DROPDOWN-ITEM' ||
          tagName === 'PDS-GLOBAL-HEADER-USER-MENU'
        ) {
          if (slotElement && slotElement.shadowRoot) {
            const slottedEl = slotElement.shadowRoot.querySelector(
              '.pds-c-global-header-link-item, .pds-c-global-header-dropdown-item, .pds-c-global-header-user-menu',
            );
            if (slottedEl && this.breakFaster) {
              slottedEl.classList.add('break-faster');
            }
          }
        }
      });
    }
  }

  override firstUpdated() {
    super.firstUpdated();
    this.setWindowResizeHandler();

    if (this.mainMenuList && this.mainMenuList.length > 0) {
      this.addAttributesToMainMenu();
      this.addStylingClasses();
    }
  }

  updated() {
    if (!this.showHamburgerMenu()) {
      this.closeDropdownItems();
    }
  }

  /**
   * Flag for mobile view
   * @internal
   */
  isMobile() {
    return (
      this.responsiveViewportSize === 'xs' ||
      this.responsiveViewportSize === 'sm' ||
      this.responsiveViewportSize === 'md'
    );
  }

  /**
   * Flag for break faster view
   * @internal
   */
  isBreakFaster() {
    return (
      this.breakFaster &&
      (this.responsiveViewportSize === 'sm' ||
        this.responsiveViewportSize === 'md')
    );
  }

  /**
   * Display/hide hamburger menu
   * @internal
   */
  showHamburgerMenu() {
    return (
      this.responsiveViewportSize === 'xs' ||
      ((this.responsiveViewportSize === 'sm' ||
        this.responsiveViewportSize === 'md') &&
        !this.breakFaster)
    );
  }

  /**
   * Gets the main menu list items
   * @internal
   */
  getMainMenuListItems() {
    return this.mainMenuList[0].shadowRoot
      ?.querySelector('slot')
      ?.assignedElements();
  }

  /**
   * Flag used to reduce the size of the hamburger menu icon and smaller logo at xs/sm breakpoints
   * @internal
   */
  isSmallScreen() {
    return (
      this.responsiveViewportSize === 'xs' ||
      this.responsiveViewportSize === 'sm'
    );
  }

  handleClick(e: { target: { textContent: string } }) {
    const detailSummaryInfo = e.target.textContent
      ? e.target.textContent.trim()
      : this.getLogoAriaLabel();

    const customEvent = new CustomEvent('pds-global-header-click', {
      bubbles: true,
      composed: true,
      detail: {
        summary: detailSummaryInfo,
      },
    });

    this.dispatchEvent(customEvent);
  }

  handleSlotChange(e: Event) {
    this.handleSlotValidation(e);
    this.requestUpdate();
    this.addAttributesToMainMenu();
    this.addStylingClasses();
  }

  /**
   * Called from hamburger menu button. Opens navigation panel
   * @internal
   */
  openHamburgerMenu() {
    this.isHamburgerMenuActive = true;

    setTimeout(() => {
      this.initializeHamburgerMenuTrap();
      this.trap.activate();
    }, 10);

    const customEvent = new CustomEvent(
      'pds-global-header-hamburger-menu-open',
      {
        bubbles: true,
        composed: true,
      },
    );

    this.dispatchEvent(customEvent);

    // Since the close button for the hamburger menu is in a different component, we need to listen to its
    // custom event and change the state accordingly
    this.addEventListener('pds-global-header-mobile-tray-close', () => {
      this.isHamburgerMenuActive = false;

      if (this.trap) {
        this.trap.deactivate();
      }
    });
  }

  /**
   * Closes the navigation panel and triggers the closing of open dropdown items in the mobile tray
   * @internal
   */
  closeHamburgerButton() {
    this.isHamburgerMenuActive = false;
    this.closeDropdownItems();

    if (this.trap) {
      this.trap.deactivate();
    }
  }

  /**
   * Closes the dropdown items in the global header if they are open
   */
  closeDropdownItems() {
    if (this.mainMenuList[0]) {
      const listItems = this.getMainMenuListItems();
      if (listItems && listItems.length > 0) {
        listItems.forEach((item) => {
          if (item.shadowRoot) {
            const slot = item.shadowRoot.querySelector('slot');
            let slotElement;
            let tagName;
            if (slot) {
              [slotElement] = slot.assignedElements();
              tagName = slotElement.tagName;
            }
            if (tagName === 'PDS-GLOBAL-HEADER-DROPDOWN-ITEM') {
              if (slotElement && slotElement.shadowRoot) {
                const dropdownEl = slotElement.shadowRoot.querySelector(
                  '.pds-c-global-header-dropdown-item',
                ) as HTMLElement;

                if (
                  dropdownEl &&
                  dropdownEl.classList.contains('pds-is-open')
                ) {
                  dropdownEl.classList.add('no-transition');
                  dropdownEl.querySelector('button')?.click();
                  // Timeout to give the dropdown time to close before removing the no-transition class
                  setTimeout(() => {
                    dropdownEl.classList.remove('no-transition');
                  }, 5);
                  const dropdownMenu = dropdownEl.querySelector(
                    '.pds-c-global-header-dropdown-item__menu',
                  ) as HTMLElement;
                  // Sets the position to absolute to align with desktop view
                  dropdownMenu.style.setProperty('position', 'absolute');
                }
              }
            }
          }
        });
      }
    }
  }

  /**
   * Returns hamburger menu markup
   * @internal
   */
  getHamburgerMenuMarkup() {
    return html`<pds-button
        class="${this.classEl('hamburger-menu-button')}"
        variant="icon"
        type="button"
        size="${this.isSmallScreen() ? 'sm' : 'lg'}"
        name="hamburger-menu"
        aria-expanded="${this.isHamburgerMenuActive}"
        aria-haspopup="dialog"
        ariaLabel="${this.translateText('menu')}"
        @click=${this.openHamburgerMenu}
      >
        <pds-icon-menu></pds-icon-menu>
      </pds-button>
      <pds-global-header-mobile-tray
        variant="left"
        open="${this.isHamburgerMenuActive ? true : nothing}"
      >
        <span slot="main"
          ><slot @slotchange=${this.handleSlotChange}></slot
        ></span>
      </pds-global-header-mobile-tray>`;
  }

  /**
   * Initialize focus trap for the hamburger menu
   */
  initializeHamburgerMenuTrap() {
    const trapSelectors = [];
    const hamburgerMenuItems = (
      this.mobileTray.shadowRoot!.querySelector(
        'slot[name="main"]',
      ) as HTMLSlotElement
    ).assignedElements()[0];

    // Non-focusable Web component
    const closeBtnElement = this.mobileTray.shadowRoot!.querySelector(
      '.pds-c-global-header-mobile-tray__close-button',
    ) as HTMLElement;

    // Focusable native element in the Shadow DOM
    const closeBtn = this.mobileTray.shadowRoot
      ?.querySelector('.pds-c-global-header-mobile-tray__close-button')
      ?.shadowRoot!.querySelector('button') as HTMLElement;

    const hamburgerMenuBtn =
      this.hamburgerMenuBtnElement.shadowRoot!.querySelector(
        'button',
      ) as HTMLElement;

    trapSelectors.push(hamburgerMenuItems as HTMLElement);
    trapSelectors.push(closeBtnElement);
    this.trap = focusTrap.createFocusTrap(trapSelectors, {
      initialFocus: closeBtn,
      allowOutsideClick: true,
      clickOutsideDeactivates: true,
      returnFocusOnDeactivate: true,
      setReturnFocus: hamburgerMenuBtn,
      tabbableOptions: {
        getShadowRoot: true,
        displayCheck: 'none',
      },
    });
  }

  /**
   * Returns the nav slot markup
   * @internal
   */
  getSlotMarkup() {
    return html`
      <a
        class="${this.classEl('logo-link')}"
        href="${this.logoHref}"
        aria-label="${this.getLogoAriaLabel()}"
        @click=${this.handleClick}
        ><slot
          allowed-elements="pds-logo, img, svg"
          name="logo"
          @slotchange=${this.handleSlotValidation}
          ><pds-logo></pds-logo></slot
      ></a>
      <slot
        allowed-elements="pds-list"
        @slotchange=${this.handleSlotChange}
      ></slot>
      <div class="${this.classEl('utilities')}">
        <slot name="utility">
          ${this.loggedIn
            ? html`<pds-global-header-user-menu
                fullName=${this.fullName}
                initials=${this.initials}
                notifications=${this.hasNotifications || nothing}
              >
                <slot name="user-menu-links"></slot
              ></pds-global-header-user-menu>`
            : nothing}</slot
        >
      </div>
    `;
  }

  getNavMarkup() {
    return html`<nav
      aria-label="global header navigation"
      class=${this.getClass()}
    >
      ${this.skipToMainContentHref !== 'none'
        ? html`<a
            href="${this.skipToMainContentHref}"
            class="${this.classEl('skip-to-main')}"
            @click=${this.handleClick}
            >${this.translateText('skip-to-content')}</a
          >`
        : nothing}
      ${this.showHamburgerMenu() ? this.getHamburgerMenuMarkup() : nothing}
      ${this.getSlotMarkup()}
    </nav>`;
  }

  render() {
    return html` ${this.layoutContainer !== 'none'
      ? html`<pds-layout-container variant=${this.layoutContainer}
          >${this.getNavMarkup()}</pds-layout-container
        >`
      : this.getNavMarkup()}`;
  }
}
