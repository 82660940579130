import { downloadPlanInfoFiles } from "../repositories/participant";

export async function viewDocument(
  planId: number,
  empId: number,
  extension: string,
  resourceId: number,
  role: string
): Promise<String> {
  return downloadPlanInfoFiles(
    `/plans/${planId}/employees/${empId}/viewDocument`,
    { role, extension, resourceId }
  );
}
