import { AsyncStatus } from "../async-status";

export interface PlanDetails {
  redeferralOptions: string;
  idNum: number;
  contractId: number;
  planId: number;
  planAdminGuide: boolean;
  maximumNumberOfReferralsAllowed: number;
  restrictNumberOfRedeferrals: boolean;
  treatFirstInstallmentsLumpSum: boolean;
  acceptW4: boolean;
  restrictActiveParticipantAddress: boolean;
  wireFeesPaidBy: string;
  planAllowsWireACH: AllowsWireACH | undefined;
  isFormChangeRedeferralAllowed: boolean;
  isTimeChangeRedeferralAllowed: boolean;
  status: AsyncStatus;
}

export enum AllowsWireACH {
  WIRE_ONLY = "Wire Only",
  ACH_ONLY = "ACH Only",
  WIRE_AND_ACH = "Wire and ACH",
}
