import React from "react";
import { useHistory } from "react-router-dom";
import "./error-500.scss";

function Error500() {
  const history = useHistory();

  function handleClick() {
    history.goBack();
  }

  return (
    <section
      data-testid="error-500"
      className="svg-page svg-pipes util-padding-top-20 util-padding-bottom-10"
    >
      <div className="text-center">
        <h1>We’re sorry.</h1>
        <p>
          It looks like something went wrong on our end. We’ll work on getting
          that fixed.
        </p>
        <p>
          <button
            data-testid="returnToPrevious"
            onClick={handleClick}
            className="btn btn-link btn-link-inline"
          >
            <i className="fa fa-angle-left" aria-hidden="true" /> Head back to
            the previous page{" "}
          </button>
        </p>
      </div>
    </section>
  );
}

export default Error500;
