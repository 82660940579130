import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AsyncStatus } from "../../models/async-status";
import { getEstimatedBenefitAccountInfo } from "../../services/estimated-benefit-account-info";

export const fetchEstimatedBenefitAccountInfo = createAsyncThunk(
  "fetch-estimated-benefit-account-info",
  async (
    {
      planId,
      empId,
      accountId,
      role,
    }: { planId: number; empId: number; accountId: number; role: string },
    thunkApi
  ) => {
    return getEstimatedBenefitAccountInfo(planId, empId, accountId, role);
  }
);

type State = {
  currentAge: number;
  payoutDate: string;
  effectiveDate: string;
  status: AsyncStatus;
};

const initialState: State = {
  status: AsyncStatus.not_fetched,
  currentAge: 0,
  payoutDate: new Date().toJSON(),
  effectiveDate: new Date().toJSON(),
};

const estimatedBenefitAccountInfoSlice = createSlice({
  name: "estimated-benefit-account-info",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchEstimatedBenefitAccountInfo.pending,
      (state, action) => {
        return { ...state, status: AsyncStatus.loading };
      }
    );
    builder.addCase(
      fetchEstimatedBenefitAccountInfo.fulfilled,
      (state, action) => {
        return { ...state, ...action.payload, status: AsyncStatus.done };
      }
    );
    builder.addCase(
      fetchEstimatedBenefitAccountInfo.rejected,
      (state, action) => {
        return {
          ...state,
          status: AsyncStatus.error,
        };
      }
    );
    builder.addDefaultCase((state, action) => state);
  },
});

export default estimatedBenefitAccountInfoSlice.reducer;
