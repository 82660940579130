import _some from "lodash/some";
import React from "react";
import { filteredScheduledElectionsSelector } from "../../selectors/scheduled-elections";
import { useSelector } from "../../store/store";
import { DistributionDisclaimer } from "./distribution-summary/disclaimers/distribution-disclaimer";
import ModifiedMethodDisclaimer from "./distribution-summary/disclaimers/modified-methods-disclaimer";
import PhantomStockDisclaimer from "./distribution-summary/disclaimers/phantom-stock-disclaimer";
import PlanCodeMessage from "./distribution-summary/disclaimers/plan-code-message";
import ShawDisclaimer from "./distribution-summary/disclaimers/shaw-disclaimer";
import ModifiedMethodComments from "./distribution-summary/modified-method-comments/modified-method-comments";
import Pre409ADisclaimer from "./distribution-summary/pre409A-disclaimer";
import { AccountDistributionsTable } from "./distribution-summary/account-distributions-table";
import { PlanCode } from "../../models/plan/plan-info-model";
import { TaxImplication409aModal } from "../../components/modal/tax-implication-modals/tax-implication-409a-modal";
import { TaxImplication457bModal } from "../../components/modal/tax-implication-modals/tax-implication-457b-modal";

export function AccountDistributionDetailsWrapper() {
  const { selected: selectedAccounts } = useSelector((state) => state.accounts);
  const filteredScheduledElections = useSelector(
    filteredScheduledElectionsSelector
  );
  const planCode = useSelector((state) => state.plan.info.planCode);
  const isExcess = planCode === "EXCESS";
  const is457b = planCode.toLowerCase() === PlanCode.type457b;
  const showComments = _some(filteredScheduledElections, "showComment");

  return (
    <>
      {isExcess && (
        <p className="taxImplicationText">
          Upon a distribution event, your distribution will be subject to income
          tax. For more details see
          <TaxImplication409aModal
            infoIcon={false}
            linkText={"this information."}
          />
        </p>
      )}
      {is457b && (
        <p className="taxImplicationText">
          Upon a distribution event, your distribution will be subject to income
          tax. For more details see
          <TaxImplication457bModal
            infoIcon={false}
            linkText={"this information."}
          />
        </p>
      )}
      <div className="account-distribution-headers-and-table">
        <AccountDistributionsTable
          showPaidOutAccounts={true}
          isAccountSummary={true}
        />
      </div>
      {showComments && (
        <>
          <ModifiedMethodComments
            accounts={selectedAccounts}
            scheduledElections={filteredScheduledElections}
          />
          <ModifiedMethodDisclaimer />
        </>
      )}
      <DistributionDisclaimer>
        <PlanCodeMessage />
        <Pre409ADisclaimer />
        <ShawDisclaimer />
        <PhantomStockDisclaimer />
      </DistributionDisclaimer>
    </>
  );
}
