import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AsyncStatus } from "../models/async-status";
import { FinancialWellnessArticle } from "../models/dashboard/financial-wellness-articles";
import { getFinancialWellnessResource } from "../services/financial-wellness-service";

export const fetchFinancialWellnessArticles = createAsyncThunk(
  "fetch-financial-wellness-articles",
  async () => {
    return getFinancialWellnessResource();
  }
);

type State = {
  financialWellnessArticles: FinancialWellnessArticle[];
  status: AsyncStatus;
};

const initialState: State = {
  financialWellnessArticles: [] as FinancialWellnessArticle[],
  status: AsyncStatus.not_fetched,
};

const financialWellnessArticlesSlice = createSlice({
  name: "financialWellnessArticles",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFinancialWellnessArticles.pending, (state, action) => {
      return { ...state, status: AsyncStatus.loading };
    });
    builder.addCase(
      fetchFinancialWellnessArticles.fulfilled,
      (
        state,
        action: { payload: FinancialWellnessArticle[]; type: string }
      ) => {
        return {
          ...state,
          financialWellnessArticles: action.payload,
          status: AsyncStatus.done,
        };
      }
    );
    builder.addCase(
      fetchFinancialWellnessArticles.rejected,
      (state, action) => {
        return { ...state, status: AsyncStatus.error };
      }
    );
    builder.addDefaultCase((state, action) => state);
  },
});

export default financialWellnessArticlesSlice.reducer;
