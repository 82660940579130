import React from "react";
import { Disclosures } from "../models/disclosures";
import InvestmentDisclosure from "../pages/investments/performance/investment-disclosures/investment-disclosure";

type Props = {
  disclosures: Disclosures[];
};

export default function DisclosureList(props: Props) {
  const { disclosures } = props;

  return (
    <div data-testid="disclosures">
      {disclosures.map((disclosure: Disclosures) => {
        return <InvestmentDisclosure {...disclosure} key={disclosure.id} />;
      })}
    </div>
  );
}
