import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AccountBalancesByDate } from "../models/account-balances-by-date";
import { AsyncStatus } from "../models/async-status";
import {
  GetAccountBalancesArgs,
  getAccountBalancesByDate,
} from "../services/accounts";

export const fetchAccountsBalancesByDate = createAsyncThunk(
  "fetch-account-balances-by-date",
  async (args: GetAccountBalancesArgs) => {
    const data = await getAccountBalancesByDate(args);
    return {
      data,
      lastFetchedStartDate: args.startDate,
      lastFetchedEndDate: args.endDate,
    };
  }
);

const initialState: AccountBalancesByDate = {
  data: [],
  status: AsyncStatus.not_fetched,
};

const accountBalancesByDateSlice = createSlice({
  name: "account-balances-by-date",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAccountsBalancesByDate.pending, (state, action) => {
      return { ...state, status: AsyncStatus.loading };
    });
    builder.addCase(fetchAccountsBalancesByDate.fulfilled, (state, action) => {
      return { ...state, ...action.payload, status: AsyncStatus.done };
    });
    builder.addCase(fetchAccountsBalancesByDate.rejected, (state, action) => {
      return { ...state, status: AsyncStatus.error };
    });
    builder.addDefaultCase((state, action) => state);
  },
});

export default accountBalancesByDateSlice.reducer;
