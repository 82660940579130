import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getPlanStatements } from "src/services/plan-statements";
import { AsyncStatus } from "../../models/async-status";
import { StatementsHelper } from "../../models/planstatements/statements-helper";

export const fetchPlanStatements = createAsyncThunk(
  "fetch-plan-statements",
  async (
    { planId, empId, role }: { planId: number; empId: number; role: string },
    thunkApi
  ) => {
    return getPlanStatements(planId, empId, role);
  }
);

type State = {
  planStatements: StatementsHelper[];
  definedBenefitPlan: Boolean;
  displayDBAndDCStatements: Boolean;
  status: AsyncStatus;
};

const initialState: State = {
  status: AsyncStatus.not_fetched,
  planStatements: [],
  definedBenefitPlan: false,
  displayDBAndDCStatements: false,
};

const planStatementsSlice = createSlice({
  name: "plan-statements",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPlanStatements.pending, (state, action) => {
      return { ...state, status: AsyncStatus.loading };
    });
    builder.addCase(fetchPlanStatements.fulfilled, (state, action) => {
      return { ...state, ...action.payload, status: AsyncStatus.done };
    });
    builder.addCase(fetchPlanStatements.rejected, (state, action) => {
      return {
        ...state,
        status: AsyncStatus.error,
      };
    });
    builder.addDefaultCase((state, action) => state);
  },
});

export default planStatementsSlice.reducer;
