import React from "react";
import InvestmentLoadingTable from "../../../../components/loading-tables/investment-loading-table";
import TableV2 from "../../../../components/table/tableV2";
import AssetClassRiskSpectrum from "../asset-class-risk-spectrum/asset-class-risk-spectrum";
import {
  aggregatedInvestmentSelector,
  aggregatedSelectedInvestmentsSelector,
} from "../../../../selectors/investments";
import { hidePrrSelector } from "../../../../selectors/plan-info";
import {
  accountsStatusSelector,
  investmentsStatusSelector,
  sessionStatusSelector,
  useStatuses,
} from "../../../../selectors/status";
import { useSelector } from "../../../../store/store";
import {
  buildHexValueColumn,
  buildSharePriceColumn,
} from "../../performance/performance-table/build-columns-utils";
import {
  buildBalancePercentColumn,
  buildCurrentBalanceColumn,
  buildInvestmentOptionColumn,
  buildPrrAmtColumn,
  buildSharesColumn,
  buildTotalSharesColumn,
} from "./build-investment-column-util";
import { useLiabilitiesRestApi } from "../../../../selectors/feature-flags";

type Props = {
  columns?: any;
  onlyInvestmentsWithActivity?: boolean;
};
export default function InvestmentTable(props: Props) {
  const investments = useSelector(aggregatedSelectedInvestmentsSelector);
  const useLiabilitiesApi = useSelector(useLiabilitiesRestApi);

  const aggregatedInvestment = useSelector(aggregatedInvestmentSelector);
  const endBalance = aggregatedInvestment.endBalance?.cashBalance.balance;

  const hidePrr = useSelector(hidePrrSelector);
  const { isLoadingStatus } = useStatuses([
    investmentsStatusSelector,
    sessionStatusSelector,
    accountsStatusSelector,
  ]);

  const defaultColumns = [
    buildHexValueColumn(),
    buildInvestmentOptionColumn(),
    buildCurrentBalanceColumn(),
    buildBalancePercentColumn(endBalance),
    buildSharePriceColumn(),
    buildTotalSharesColumn(),
    buildSharesColumn(),
  ];

  if (!hidePrr) {
    defaultColumns.push(buildPrrAmtColumn(false, useLiabilitiesApi));
  }

  const { columns } = props;

  if (isLoadingStatus) {
    return <InvestmentLoadingTable />;
  }

  return (
    <>
      <TableV2
        columns={columns ? columns : defaultColumns}
        data={investments}
      />
      <div className="util-margin-top-20">
        <AssetClassRiskSpectrum />
      </div>
    </>
  );
}
