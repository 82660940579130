import React from "react";
import { AccountInfoLink } from "../../../components/account-info-link/account-info-link";
import { AccountWithUnscheduledDistributions } from "../../../models/account";
import { ScheduledDistributionEvent } from "../../../models/distributions/scheduled-distribution-event";
import { useSelector } from "../../../store/store";
import {
  filterElectionByProvisionAndDefaultElections,
  filterEventsByAccountProvisionVersion,
} from "../../../util/distributions-util";
import "./account-distribution-details.scss";
import DistributionContactInfo from "./unscheduled-distributions/distribution-contact-info";
import FutureElectionMessage from "./unscheduled-distributions/future-election-message";
import UnscheduledDistributionTable from "./unscheduled-distributions/unscheduled-distribution-table";

type Props = {
  account: AccountWithUnscheduledDistributions;
  firstFutureScheduledDistribution: ScheduledDistributionEvent;
};

export default function AccountDistributionDetail(props: Props) {
  const { account, firstFutureScheduledDistribution } = props;
  const { unscheduledDistributionElections } = account;
  const {
    participantLevelElections,
    unscheduledDistributionEventsByProvision,
  } = useSelector((state) => state.distributions);

  const participantElectionsForAccount =
    filterElectionByProvisionAndDefaultElections(
      participantLevelElections.participantLevelElections,
      account.provisionVersionNumber
    );
  const availableEventsByAccountProvisionNumber =
    filterEventsByAccountProvisionVersion(
      account.provisionVersionNumber,
      unscheduledDistributionEventsByProvision.availableEventsForAllProvisions
    );
  return (
    <>
      <UnscheduledDistributionTable
        unscheduledDistributionEventsByProvision={
          availableEventsByAccountProvisionNumber
        }
        participantLevelElections={participantElectionsForAccount}
        accountLevelElections={unscheduledDistributionElections}
      />
      <AccountInfoLink account={account} />
      <DistributionContactInfo
        firstFutureScheduledDistribution={firstFutureScheduledDistribution}
      />
      <FutureElectionMessage
        firstAccountElection={unscheduledDistributionElections[0]}
      />
    </>
  );
}
