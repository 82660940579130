import React from "react";
import ConditionalDisplayInput from "../../../../components/conditional-display-input/conditional-display-input";
import { Beneficiary } from "../../../../models/beneficiaries/beneficiary";
import { updateBeneficiary } from "../../../../reducers/beneficiary-reducer";
import { useAppDispatch, useSelector } from "../../../../store/store";
import "./beneficiary-panel-body.scss";

type Props = {
  beneficiary: Beneficiary;
};

export default function BeneficiaryAllocation(props: Props) {
  const { beneficiary } = props;
  const editing: boolean = useSelector((state) => state.beneficiaries.editing);
  const dispatch = useAppDispatch();

  function handleChange(e: any) {
    if (e.target.validity.valid) {
      dispatch(
        updateBeneficiary({
          ...beneficiary,
          allocationAmount: parseFloat(e.target.value),
        })
      );
    }
  }

  return (
    <div className="input-suffix">
      <ConditionalDisplayInput
        editable={editing}
        displayValue={`${beneficiary.allocationAmount}%`}
        displayId="allocationAmount"
      >
        <input
          id={`beneficiary-input-${beneficiary.guid}`}
          data-testid={`allocation-input-${beneficiary.guid}`}
          name={`beneficiary-input-${beneficiary.guid}`}
          type="number"
          min="0"
          max="100"
          step=".01"
          className="form-control without_arrows"
          value={beneficiary.allocationAmount}
          onChange={handleChange}
        />
        <span className="suffix">% </span>
      </ConditionalDisplayInput>
    </div>
  );
}
