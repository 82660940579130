export interface Step {
  name: string;
  status: StepStatus;
}

export enum StepStatus {
  CURRENT,
  FINISHED,
  UNFINISHED,
}
