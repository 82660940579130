import React from "react";
import { ReactourStep } from "reactour";

export const toursteps: ReactourStep[] = [
  {
    selector: "#tsm-header-my-profile",
    content: () => (
      <>
        <div className="h2">Profile</div>
        <p>
          You can enter and update your contact information, password, security
          and other preferences. Be sure to keep this updated so we can let you
          know about your account and important plan information.
        </p>
      </>
    ),
  },
  {
    selector: "#tsm-header-messages",
    content: () => (
      <>
        <div className="h2">Message center</div>
        <p>
          You'll find messages from us about your account in the message center.
        </p>
      </>
    ),
  },
  {
    selector: ".account-panel",
    content: () => (
      <>
        <div className="h2">Account information</div>
        <p>
          Here's a quick overview of your account status as of the noted date
          along with some of your key retirement planning information.
        </p>
      </>
    ),
  },
  {
    selector: "#dashboard-tabs",
    action: (node) => {
      const element: HTMLElement = document.querySelector(
        "[data-testid='Performance-tab']"
      ) as HTMLElement;
      if (element) {
        element.click();
        node.focus();
      }
    },
    content: () => (
      <>
        <div className="h2">Performance</div>
        <p>
          Your balance for up to the last two years. For performance per
          investment, go to the investment tab for more information.{" "}
        </p>
      </>
    ),
  },
  {
    selector: "#dashboard-tabs",
    action: (node) => {
      const element: HTMLElement = document.querySelector(
        "[data-testid='Accounts-tab']"
      ) as HTMLElement;
      if (element) {
        element.click();
        node.focus();
      }
    },
    content: () => (
      <>
        <div className="h2">Accounts</div>
        <p>Take a quick look at your balances per account.</p>
      </>
    ),
  },
  {
    selector: ".dashboard-contribution-panel",
    action: (node) => {
      const element: HTMLElement = document.querySelector(
        "[data-testid='dashboard-contribution-panel']"
      ) as HTMLElement;
      if (element) {
        element.click();
        node.focus();
      }
    },
    content: () => (
      <>
        <div className="h2">Contributions</div>
        <p>
          Take a look at your current contribution rates and quick access to
          your contribution summary.
        </p>
      </>
    ),
  },
  {
    selector: "",
    content: () => (
      <>
        <div className="h2">Thanks!</div>
        <p>
          We're glad you're here — watch for new website features and tours or
          stop by any time to retake the tour.
        </p>
      </>
    ),
  },
];
