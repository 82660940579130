import { InvestmentV2 } from "../../../models/investment-v2";

export function mapInvestmentsToAccounts(
  investments: InvestmentV2[],
  accounts: any[]
) {
  return accounts.map((account) => {
    const filteredInvestments = investments.filter(
      (investment) => investment.accountId === account.id
    );
    return { ...account, investments: filteredInvestments };
  });
}
