import trimStart from "lodash/trimStart";
import qs from "query-string";
import { toBase64String } from "./base-64-util";
import {
  ConfidentialClientDeferredCompPlannerURL,
  ConfidentialClientEmployee,
  ConfidentialClientField,
} from "./custom-clients";
import { jsfHost } from "./constants";

export function buildPensionContextLink(
  empId,
  contractId,
  action,
  role = "MEMBER",
  extraParams = {}
) {
  const memberId = toBase64String(empId);
  return buildLegacyLinkForRole(role, "/pensionContext.faces", {
    memberId,
    contractId,
    action,
    ...extraParams,
  });
}

export function buildDeferredCompPlannerURL(planId) {
  if (planId === ConfidentialClientEmployee) {
    return ConfidentialClientDeferredCompPlannerURL + "366776";
  } else if (planId === ConfidentialClientField) {
    return ConfidentialClientDeferredCompPlannerURL + "366777";
  } else {
    return "https://nq.individuals.principal.com/nq-compensation-planner/";
  }
}

export function buildRedeferralLinkParams(accountId, name = "") {
  const noWhiteSpaceName = name.replace(/\s/g, "");
  return { accountId, accountName: noWhiteSpaceName };
}

export function buildPendingRedeferralLink(
  empId,
  contractId,
  action,
  account,
  role
) {
  const { name, id } = account;
  const accountId = toBase64String(id);
  const params = buildRedeferralLinkParams(accountId, name);
  return buildPensionContextLink(empId, contractId, action, role, params);
}

export function buildModernDashboardLink() {
  return `/nq-participant/overview/dashboard`;
}

export function buildLinkforOverviewBenefit(path) {
  return `/nqParticipant/overview/db/${path}`;
}

export function buildLinkforLegacyOverviewBenefit(
  empId,
  contractId,
  action,
  role
) {
  const params = "";
  return buildPensionContextLink(empId, contractId, action, role, params);
}

export function buildLinkForNewOverviewBenefitPage(
  planId,
  empId,
  contractId,
  role
) {
  const params = { planId, empId, contractId, role };
  const query = qs.stringify(params);
  return `/nq-participant/overview/overview-benefit?${query}`;
}

export function buildLinkForDBPlanStatementPage(planId, empId, role) {
  const params = { planId, empId, role };
  const query = qs.stringify(params);
  return `/nq-participant/overview/plan-statements?${query}`;
}

export function buildLinkForDefinedBenefitAboutPage(planId, empId, role) {
  const params = { planId, empId, role };
  const query = qs.stringify(params);
  return `/nq-participant/overview/defined-benefit/about?${query}`;
}

export function buildManageContributionsLink(params = {}) {
  return buildLinkForContributions(params, "manage-contributions");
}

export function buildContributionsSummaryLink(params = {}) {
  return buildLinkForContributions(params, "summary");
}

export function buildLinkForContributions({ planId, empId, role }, type) {
  const params = { planId, empId, role };
  const query = qs.stringify(params);
  return `/nq-participant/contributions/${type}?${query}`;
}

export function buildLinkForEstimatedBenefitCalculator(planId, empId, role) {
  const params = { planId, empId, role };
  const query = qs.stringify(params);
  return `/nq-participant/enrollmentcenter/estimated-benefit-calculator?${query}`;
}

export function buildLinkForPlanOverviewPage(planId, empId, role) {
  const params = { planId, empId, role };
  const query = qs.stringify(params);
  return `/nq-participant/overview/plan-overview?${query}`;
}

export function buildLinkForPlanInfoAndFormPage(planId, empId, role) {
  const params = { planId, empId, role };
  const query = qs.stringify(params);
  return `/nq-participant/overview/plan-info-summary?${query}`;
}

export function buildLinkForStreamOfPaymentPending(
  planId,
  empId,
  accountId,
  role
) {
  const params = { planId, empId, accountId, role };
  const query = qs.stringify(params);
  return `/distributions/redeferral/streamOfPaymentPending?${query}`;
}

export function isLocal() {
  return getActualHost() === "localhost";
}

export function getActualHost() {
  return window.location.hostname;
}

export function buildLegacyLinkForRole(role, path, originalParams = {}) {
  console.log("params:", originalParams);
  let params = originalParams;
  const accessToken = localStorage.getItem("accessToken");
  if (accessToken) {
    params = { code: localStorage.getItem("accessToken"), ...originalParams };
  }
  const currentHost = getActualHost();
  const query = qs.stringify(params);
  const formattedPath = trimStart(path, "/");

  if (query) {
    return formatLinkForRole(
      `https://${jsfHost}/nqParticipant/${formattedPath}?${query}`,
      role
    );
  }
  return formatLinkForRole(
    `https://${currentHost}/nqParticipant/${formattedPath}`,
    role
  );
}

export function formatLinkForRole(link, role) {
  if (role === "EMPLOYER") {
    const employerHost = link.replace("secure05", "secure02");
    return employerHost.replace("/nqParticipant/", "/nqParticipant/employers/");
  }

  if (role === "ADVISOR") {
    const advisorHost = link.replace("secure05", "advisors");
    return advisorHost.replace("/nqParticipant/", "/nqParticipant/advisors/");
  }

  return link;
}
