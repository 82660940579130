import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ToolLink } from "src/models/plan-info-and-summary/tool-link";
import { getPlanningCenterTools } from "src/services/planning-center-tools";
import { AsyncStatus } from "../../models/async-status";

export const fetchPlanningCenterTools = createAsyncThunk(
  "fetch-planning-center-tools",
  async (
    { planId, empId, role }: { planId: number; empId: number; role: string },
    thunkApi
  ) => {
    return getPlanningCenterTools(planId, empId, role);
  }
);

type State = {
  toolLinks: ToolLink[];
  status: AsyncStatus;
};

const initialState: State = {
  status: AsyncStatus.not_fetched,
  toolLinks: [],
};

const planningCenterToolsSlice = createSlice({
  name: "planning-center-tools",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPlanningCenterTools.pending, (state, action) => {
      return { ...state, status: AsyncStatus.loading };
    });
    builder.addCase(fetchPlanningCenterTools.fulfilled, (state, action) => {
      return { ...state, ...action.payload, status: AsyncStatus.done };
    });
    builder.addCase(fetchPlanningCenterTools.rejected, (state, action) => {
      return {
        ...state,
        status: AsyncStatus.error,
      };
    });
    builder.addDefaultCase((state, action) => state);
  },
});

export default planningCenterToolsSlice.reducer;
