import React, { useState } from "react";
import { Modal } from "./modal";
import { ForecastDistribution } from "../../models/distributions/forecast-distribution";
import { currencyFormatter } from "../../util/currency";

type Props = {
  distribution: ForecastDistribution;
  rateOfReturn: number;
};

export function PrrCalculationModal(props: Props) {
  const { distribution, rateOfReturn } = props;
  const [isExpanded, setIsExpanded] = useState(false);

  function toggleExpanded() {
    setIsExpanded(!isExpanded);
  }

  const expandIcon = isExpanded ? (
    <i
      className={"fa fa-chevron-down expand-icon"}
      onClick={toggleExpanded}
      style={{ color: "#035fa4", cursor: "pointer" }}
    />
  ) : (
    <i
      className={"fa fa-chevron-right expand-icon"}
      onClick={toggleExpanded}
      style={{ color: "#035fa4", cursor: "pointer" }}
    />
  );

  return (
    <Modal title="Calculation explanation">
      <div id="info" className="info">
        <p>Here's how the estimated account balance is being calculated:</p>
        <h2 style={{ textAlign: "center" }}>
          {currencyFormatter(distribution.estimatedAccountBalance)} ={" "}
          {currencyFormatter(distribution.principalBalance)}(1 +{" "}
          {rateOfReturn / 100})
          <sup>({distribution.numberOfTimePeriodsElapsed}/12)</sup>
        </h2>
        <div>
          {expandIcon}
          <b>More Info</b>
          {isExpanded && (
            <>
              <p />
              <p>Formula used: A=P(1+r)^(t/n)</p>
              <p>
                <u>What does each variable mean?</u>
              </p>
              <p>
                <i>A</i> - final balance (i.e. estimated account balance)
              </p>
              <p>
                <i>P</i> - principal balance (i.e. account balance at this
                distribution)
              </p>
              <p>
                <i>r</i> - rate of return, in decimal format (Ex: 7% = 0.07)
              </p>
              <p>
                <i>t/n</i> - years elapsed where t is months and n is months in
                a year (12). (EX: 18/12 = 1.5 years)
              </p>
              <p>
                <u>Note</u>: Interest is being applied annually
              </p>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
}
