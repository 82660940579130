import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getForecastDistributionsResourceFromGraphClick } from "../../services/distributions";
import { AsyncStatus } from "../../models/async-status";
import { ForecastDistributionsResource } from "../../models/distributions/forecast-distribution";

export const fetchForecastDistributionsResourceFromGraphClick =
  createAsyncThunk(
    "fetch-forecast-distribution-resource-from-graph",
    async (
      {
        planId,
        empId,
        role,
        eventName,
        forecastDate,
        selectedYear,
        rateOfReturn,
      }: {
        planId: number;
        empId: number;
        role: string;
        eventName: string;
        forecastDate: string;
        selectedYear: string;
        rateOfReturn: number;
      },
      thunkApi
    ) => {
      return getForecastDistributionsResourceFromGraphClick({
        planId,
        empId,
        role,
        eventName,
        forecastDate,
        selectedYear,
        rateOfReturn,
      });
    }
  );

type State = {
  forecastDistributionsResource: ForecastDistributionsResource;
  status: AsyncStatus;
};

const initialState: State = {
  forecastDistributionsResource: {
    forecastDistributions: [],
    forecastDistributionRolledUp: [],
    forecastExcludedAccounts: [],
    forecastDistributionsForGraph: [],
  } as ForecastDistributionsResource,
  status: AsyncStatus.not_fetched,
};

const forecastDistributionsResourceFromGraphSlice = createSlice({
  name: "forecast-distributions-resource-from-graph",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchForecastDistributionsResourceFromGraphClick.pending,
      (state, action) => {
        return { ...state, status: AsyncStatus.loading };
      }
    );
    builder.addCase(
      fetchForecastDistributionsResourceFromGraphClick.fulfilled,
      (state, action: { payload: ForecastDistributionsResource }) => {
        const forecastDistributionsResource = action.payload;

        return {
          ...state,
          forecastDistributionsResource,
          status: AsyncStatus.done,
        };
      }
    );
    builder.addCase(
      fetchForecastDistributionsResourceFromGraphClick.rejected,
      (state, action) => {
        return { ...state, status: AsyncStatus.error };
      }
    );
    builder.addDefaultCase((state, action) => state);
  },
});

export default forecastDistributionsResourceFromGraphSlice.reducer;
