import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AsyncStatus } from "../models/async-status";
import { getContextByEnterpriseId } from "../services/context-service";
import { EmployeeContextByRole } from "../models/employee-context";

export const fetchContext = createAsyncThunk(
  "fetch-context",
  async (thunkApi) => {
    return getContextByEnterpriseId();
  }
);

type State = {
  context: EmployeeContextByRole;
  status: AsyncStatus;
};

const initialState: State = {
  context: {} as EmployeeContextByRole,
  status: AsyncStatus.not_fetched,
};

const contextSlice = createSlice({
  name: "context",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchContext.pending, (state, action) => {
      return { ...state, status: AsyncStatus.loading };
    });
    builder.addCase(
      fetchContext.fulfilled,
      (state, action: { payload: EmployeeContextByRole; type: string }) => {
        const context = action.payload;

        return {
          ...state,
          context,
          status: AsyncStatus.done,
        };
      }
    );
    builder.addCase(fetchContext.rejected, (state, action) => {
      return { ...state, status: AsyncStatus.error };
    });
    builder.addDefaultCase((state, action) => state);
  },
});

export default contextSlice.reducer;
