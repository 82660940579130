import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchAccountLevelElections } from "../reducers/distributions/account-distributions";
import { fetchParticipantLevelElections } from "../reducers/distributions/participantDistributions";
import { fetchScheduledElections } from "../reducers/distributions/scheduled-elections";
import { fetchScheduledDistributionEvents } from "../reducers/distributions/scheduledDistributionEvent";
import { fetchUnscheduledDistributionEvents } from "../reducers/distributions/unscheduledDistributionEvents";
import { fetchAccountRedeferralStatuses } from "../reducers/redeferral/redeferral-status";
import { useSelector } from "../store/store";
import { fetchUnscheduledDistributionProvisionEvents } from "../reducers/distributions/unscheduledDistributionEventsByProvision";
import { fetchPaymentPendingAccountInfo } from "src/reducers/stream-of-payment-pending/stream-of-payment-pending";
import { useLocation } from "react-router-dom";

type Props = {
  children: React.ReactNode;
};

export function DistributionWrapper(props: Props) {
  const { children } = props;
  const dispatch = useDispatch();
  const { planId, empId, role } = useSelector((state) => state.session);
  const search = useLocation().search;
  const accountId = Number(new URLSearchParams(search).get("accountId"));

  useEffect(() => {
    dispatch(fetchAccountLevelElections({ planId, empId, role }));
    dispatch(fetchScheduledElections({ planId, empId, role }));
    dispatch(fetchScheduledDistributionEvents({ planId, empId, role }));
    dispatch(fetchParticipantLevelElections({ planId, empId, role }));
    dispatch(fetchUnscheduledDistributionEvents({ planId, empId, role }));
    dispatch(
      fetchUnscheduledDistributionProvisionEvents({ planId, empId, role })
    );
    dispatch(
      fetchPaymentPendingAccountInfo({ planId, empId, accountId, role })
    );
    dispatch(
      fetchPaymentPendingAccountInfo({ planId, empId, accountId, role })
    );
    dispatch(fetchAccountRedeferralStatuses({ planId, empId, role }));
  }, [planId, empId, accountId, role, dispatch]);

  return <>{children}</>;
}
