import { EstimatedBenefitAccountInfo } from "../models/enrollment-tools/estimated-benefit-account-info";
import { get } from "../repositories/participant";

export async function getEstimatedBenefitAccountInfo(
  planId: number,
  empId: number,
  accountId: number,
  role: string
): Promise<EstimatedBenefitAccountInfo> {
  return get(`/plans/${planId}/employees/${empId}/estBenefit-account-info`, {
    role,
    accountId,
  });
}
