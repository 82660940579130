import React from "react";
import {
  LastReceivedDate,
  LastBusinessDate,
} from "../../../components/numbers/last-business-date";
import "./average-annual-and-prices-as-of-dates.scss";

export default function AverageAnnualAndPricesAsOfDatesV2() {
  return (
    <div className="average-annual-and-prices-as-of-dates">
      <p className="date-descriptor">
        Average annual total returns through{" "}
        <strong>
          <LastReceivedDate />
        </strong>
      </p>
      <p className="date-descriptor">
        Share/Unit Prices and Year to Date Change as of{" "}
        <strong>
          <LastBusinessDate />
        </strong>
      </p>
    </div>
  );
}
