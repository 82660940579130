import RedeferIndividual from "./redefer-individual";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { IndividualSteps, RedeferRoutes } from "./redefer-step";
import {
  accountsStatusSelector,
  investmentsStatusSelector,
  redeferralInstructionStatus,
  scheduledDistributionsStatus,
  scheduledElectionsStatus,
  sessionStatusSelector,
  useStatuses,
} from "../../../selectors/status";
import { Page, PageHeader } from "../../../components/page";

export default function RedeferIndividualWrapper() {
  const { isLoadingStatus } = useStatuses([
    sessionStatusSelector,
    accountsStatusSelector,
    investmentsStatusSelector,
    scheduledElectionsStatus,
    scheduledDistributionsStatus,
    redeferralInstructionStatus,
  ]);
  const [currentStep, setCurrentStep] = useState<RedeferRoutes>(
    IndividualSteps[0]
  );
  const [editable, setEditable] = useState<boolean>(true);
  const history = useHistory();

  const handleStepChange = (position: number) => {
    const step = IndividualSteps.find((x) => x.stepNumber === position);
    if (step) {
      if (step.stepNumber > 0) {
        setEditable(false);
      } else {
        setEditable(true);
      }
      setCurrentStep(step);
      history.push(step.route);
    }
  };

  return (
    <Page printable name="redefer-individual" loading={isLoadingStatus}>
      <PageHeader name="redefer-individual">
        Change your scheduled distributions
      </PageHeader>
      <RedeferIndividual
        currentPosition={currentStep}
        steps={IndividualSteps}
        handleStepChange={handleStepChange}
        editable={editable}
      />
    </Page>
  );
}
