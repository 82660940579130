import { RootState } from "../store/store";

export const isRustled = (state: RootState) =>
  state.featureFlags.featureFlagDetails.enabledFeatures.some(
    (str) => str.toLowerCase() === "rustled"
  );

export const isFeatureFlagTextEnabled = (state: RootState) =>
  state.featureFlags.featureFlagDetails.enabledFeatures.some(
    (str) => str.toLowerCase() === "featureflagtext"
  );

export const isNavChangesEnabled = (state: RootState) =>
  state.featureFlags.featureFlagDetails.enabledFeatures.some(
    str => str.toLowerCase() === "addnavchanges"
  );

export const useLiabilitiesRestApi = (state: RootState) =>
  state.featureFlags.featureFlagDetails.enabledFeatures.some(
    str => str.toLowerCase() === "useliabilitiesrestapi"
  );

export const isUserActivityLoggingEnabled = (state: RootState) =>
  state.featureFlags.featureFlagDetails.enabledFeatures.some(
    (str) => str.toLowerCase() === "useractivitylogging"
  );

export const isContributionPanelEnabled = (state: RootState) =>
  state.featureFlags.featureFlagDetails.enabledFeatures.some(
    (str) => str.toLowerCase() === "contributionpanel"
  );

export const isDistributionHistoryEnabled = (state: RootState) =>
  state.featureFlags.featureFlagDetails.enabledFeatures.some(
    (str) => str.toLowerCase() === "distributionhistory"
  );

export const isWireFeatureEnabled = (state: RootState) =>
  state.featureFlags.featureFlagDetails.enabledFeatures.some(
    (str) => str.toLowerCase() === "wireinfoenabled"
  );
