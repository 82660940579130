import React from "react";
import { useSelector } from "../../../store/store";
import AccountDisplay from "./account-display";

function AccountsDisplay() {
  const selectedAccounts = useSelector((state) => state.accounts.selected);

  return (
    <>
      <div className={"row no-print"}>
        <div className={"col-md-12"}>
          <h2 className="h1">Individual account statements</h2>
          <hr role="presentation" />
        </div>
      </div>
      {selectedAccounts.map((account) => {
        return <AccountDisplay key={account.id} account={account} />;
      })}
    </>
  );
}

export default AccountsDisplay;
