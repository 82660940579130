import React from "react";
import { Modal } from "../modal";

type Props = {
  linkText?: React.ReactNode | string;
  infoIcon?: boolean;
};

export function TaxImplication457bModal(props: Props) {
  return (
    <Modal
      title={"457b Tax Implications"}
      linkText={props.linkText}
      infoIcon={props.infoIcon}
      className={"util-margin-0"}
    >
      <h1 className={"h3"}>
        When am I taxed on the distribution from my non-qualified deferred
        compensation account?
      </h1>
      <p>
        When you receive a distribution, upon attaining a qualifying
        distribution event as defined by your plan, you will have to pay federal
        income taxes on the distribution amount. The impact of state and local
        taxes is complex. Consult with your tax advisor to understand how much,
        if any, state tax you will incur, and which state tax laws are
        applicable to your distribution. (Note: Depending on the timing of the
        distribution, you may be taxed either under the laws of the state you
        worked in or the state where you receive your distribution, or both.)
      </p>

      <h1 className={"h3"}>
        When does FICA and FUTA taxation occur on my non-qualified deferred
        compensation?
      </h1>
      <p>
        <u>Employee Deferrals</u>: Social Security and Medicare withholding
        (FICA) on employee deferrals into non-qualified deferred compensation
        plans are subject to FICA taxes at the time of deferral, as if you have
        actually received the compensation.
      </p>
      <p>
        <u>Employer Contributions</u>: Employer contributions are subjected to
        FICA taxes when you no longer have a substantial risk of forfeiting that
        contribution (i.e., generally when you become vested in that portion of
        the employer contribution).
      </p>
      <p>
        If FICA tax is paid upon vesting, as described above, neither the
        distribution nor growth on it is subject to FICA withholding again at
        the time of payout.
      </p>
      <p>
        State unemployment tax withholding may vary, so check with your tax
        advisor to understand the impact to you.
      </p>

      <h2 className={"h4"}>
        How much taxes may be withheld on my distribution?
      </h2>
      <p>
        Distributions from deferred compensation plans are normally considered
        to be “supplemental wages ” for income tax withholding purposes.
        Supplemental wages are all wages paid by your employer that are not your
        regular wages and include your non-qualified deferred compensation
        distribution, bonuses, commissions, and certain other wages. If your
        total supplemental wages for the tax year are $1 million or less, your
        employer has multiple withholding options, such as withholding at your
        current W-4 status or at an optional flat rate of 22%. If your total
        supplemental wages exceed $1 million, your employer is required to
        withhold at a 37% flat rate tax on all supplemental wages in excess of
        $1 million.
      </p>
      <p>
        Most states follow the federal rules for state income tax withholding.
        Check with your tax advisor to understand your individual situation.
      </p>

      <h1 className={"h3"}>
        What other items should I consider before participating in a
        non-qualified deferred compensation plan?
      </h1>
      <ul>
        <li>
          Income tax rates may vary from time of deferral to time of
          distribution.
        </li>
        <li>Rollovers to other tax deferred plans are not permitted.</li>
        <li>
          There are no penalties for qualifying distributions that occur before
          age 59.5.
        </li>
        <li>Required minimum distribution rules apply.</li>
        <li>
          The amount of the distribution is reported on a W-2, if you
          participated in the plan as an employee.
        </li>
        <li>
          Your employer will be responsible for calculating FICA taxes upon
          vesting, as well as withholding income taxes at distribution.
        </li>
        <li>
          Your distribution may not be accelerated but it may be delayed
          pursuant to specific IRS rules.
        </li>
        <li>
          You are a general creditor of the company and participation in the
          plan does not guarantee payment.
        </li>
      </ul>
    </Modal>
  );
}
