import { combineReducers } from "redux";
import contributionHistory from "./contribution-history";
import contributionSummary from "./contribution-summary";
import contributionRates from "./contribution-rates";
import pendingContributions from "./pending-contribution-rates";

const contributionReducer = combineReducers({
  contributionRates,
  contributionHistory,
  contributionSummary,
  pendingContributions,
});

export default contributionReducer;
