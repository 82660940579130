import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import AccountInfoPage from "../pages/overview/account-info/account-info-page";
import PlanOverviewPage from "../pages/overview/plan-overview-summary/plan-overview-page";
import { DistributionWrapper } from "../wrappers/distributionWrapper";
import { EnrollmentContextProvider } from "../contexts/enrollment-context/enrollment-context";
import Dashboard from "../pages/dashboard/dashboard";
import PersonalDetails from "../pages/plan-info/personal-details";
import BeneficiaryReview from "../pages/plan-info/beneficiary/review/beneficiary-review";
import BeneficiaryPlanSelection from "../pages/plan-info/beneficiary/review/beneficiary-plan-selection";
import BeneficiariesWrapper from "../pages/plan-info/beneficiary/beneficiaries-wrapper";
import Statements from "../pages/statements/statements";
import PlanInfoAndSummary from "../pages/overview/plan-Info-Summary/plan-Info-Summary-page";
import PlanStatements from "../pages/overview/planstatements/plan-statements-page";
import OverviewBenefit from "../pages/overview/benefit-overview/benefit-overview-page";
import DefinedBenefitAboutPage from "../pages/overview/defined-benefit-about/defined-benefit-about-page";
import PlanContactsPage from "../pages/plan-contacts/plan-contact-page";

export default function PlanOverviewRoutes() {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.url}/plan-overview`}>
        <PlanOverviewPage />
      </Route>
      <Route path={`${match.url}/account-info`}>
        <DistributionWrapper>
          <AccountInfoPage />
        </DistributionWrapper>
      </Route>
      <Route path={`${match.url}/dashboard`}>
        <EnrollmentContextProvider>
          <DistributionWrapper>
            <Dashboard />
          </DistributionWrapper>
        </EnrollmentContextProvider>
      </Route>
      <Route path={`${match.url}/personal-details`}>
        <PersonalDetails shouldShowPaymentInfo={false} />
      </Route>
      <Route path={`${match.url}/beneficiaries/review`}>
        <BeneficiaryReview />
      </Route>
      <Route path={`${match.url}/beneficiaries/plan-selection`}>
        <BeneficiaryPlanSelection />
      </Route>
      <Route path={`${match.url}/beneficiaries`}>
        <BeneficiariesWrapper />
      </Route>
      <Route path={`${match.url}/statements`}>
        <Statements />
      </Route>
      <Route path={`${match.url}/plan-info-summary`}>
        <PlanInfoAndSummary />
      </Route>
      <Route path={`${match.url}/plan-statements`}>
        <PlanStatements />
      </Route>
      <Route path={`${match.url}/overview-benefit`}>
        <OverviewBenefit />
      </Route>
      <Route path={`${match.url}/defined-benefit/about`}>
        <DefinedBenefitAboutPage />
      </Route>
        <Route path={`${match.url}/plan-contacts`}>
            <PlanContactsPage/>
        </Route>
    </Switch>
  );
}
