import React, { useEffect, useState } from "react";
import { balancesBySourceStatus, useStatuses } from "../../../selectors/status";
import { fetchBalancesBySource } from "../../../reducers/balances-by-source/balances-by-source";
import { useAppDispatch, useSelector } from "../../../store/store";
import { Page, PageHeader } from "src/components/page";
import ChangeView from "./change-view";
import { Account } from "../../../models/balances-by-source/account";
import ContributionSources from "./contribution-sources";
import { isSame } from "../../../util/date-util";
import AccountName from "../../../components/account-name/account-name";

export default function BalancesBySourcePage() {
  const { balancesBySource } = useSelector((state) => state.balancesBySource);
  const { planId, empId, role } = useSelector((state) => state.session);
  const { isLoadingStatus } = useStatuses([balancesBySourceStatus]);
  const [showChangeView, setChangeViewModal] = useState(false);
  const [selectedAccounts, setSelectedAccounts] = useState<Account[]>([]);
  const accounts = balancesBySource.accountList ?? [];
  const [asOfDate, setAsOfDate] = useState(new Date());
  const dispatch = useAppDispatch();
  const [hasOfDateBeenSet, setHasOfDateBeenSet] = useState(false);

  useEffect(() => {
    if (hasDateChanged()) {
      dispatchSources();
    }
  }, [dispatch, planId, empId, role, asOfDate]);

  useEffect(() => {
    if (!hasDateChanged()) {
      dispatchSources();
    }
  }, [dispatch, planId, empId, role, selectedAccounts, asOfDate]);

  const dispatchSources = () => {
    dispatch(
      fetchBalancesBySource({
        planId,
        empId,
        role,
        effectiveDate: getAsOfDate().toISOString(),
        previousEffectiveDate: new Date(
          balancesBySource.previousAsOfDate
        ).toISOString(),
        accountIds: selectedAccountIds.toString(),
      })
    );
  };

  const setAsOfDateHandler = (date: Date) => {
    setHasOfDateBeenSet(true);
    setAsOfDate(date);
  };
  const getStaticAsOfDate: () => Date = () => {
    return new Date(balancesBySource.asOfDate);
  };

  const getAsOfDate = () => {
    return hasOfDateBeenSet
      ? asOfDate
      : new Date(balancesBySource.asOfDate) ?? asOfDate;
  };

  const openChangeView = () => {
    setChangeViewModal(true);
  };

  const hasDateChanged = (): boolean => {
    return !isSame(asOfDate, new Date(balancesBySource.previousAsOfDate));
  };

  const asOfDateLocalValue = new Date(getAsOfDate()).toLocaleDateString();
  const allAccountsSelected =
    balancesBySource.allAccountsSelected && !selectedAccounts.length;
  const selectedAccountIds =
    selectedAccounts && selectedAccounts.map((account) => account.accountId);
  const tempContributionSources =
    balancesBySource.contributionSources &&
    balancesBySource.contributionSources.map((source: any) => ({
      ...source,
      [source.investments]:
        source.investments &&
        source.investments.filter((investment: any) => {
          return investment.associatedAccounts.every((account: number) =>
            selectedAccountIds.includes(account)
          );
        }),
    }));

  return (
    <Page name={"planning-center-tools"} loading={isLoadingStatus}>
      <PageHeader name={"planning-center-tools"}>
        Balances by Contribution Source
      </PageHeader>

      <div className="util-margin-top-15">
        <table
          data-testid="personal-info-table"
          className="table"
          style={{ border: "1px solid lightgray" }}
        >
          <tbody>
            <tr>
              <td
                style={{
                  padding: "10px",
                  color: "#003366",
                  fontWeight: "bold",
                }}
              >
                <span>Customize Your View</span>
              </td>
            </tr>
            <tr style={{ fontWeight: "bold" }}>
              <td
                colSpan={2}
                className="table-head"
                style={{ borderTop: "unset" }}
              >
                <span>As of Date: {asOfDateLocalValue}</span>
              </td>
              <td
                colSpan={2}
                className="table-head"
                style={{ borderTop: "unset" }}
              >
                <span>
                  Accounts:{" "}
                  {allAccountsSelected
                    ? "Viewing All Account(s)"
                    : "See List Below"}
                </span>
              </td>
              <td
                colSpan={2}
                className="table-head"
                style={{ borderTop: "unset" }}
              >
                <a onClick={openChangeView}>Change View</a>
              </td>
            </tr>
          </tbody>
        </table>
        {selectedAccounts && selectedAccounts.length ? (
          <div
            style={{
              padding: "10px",
              border: "1px solid lightgray",
              borderTop: "unset",
              marginTop: "-24px",
              marginBottom: "10px",
            }}
          >
            <span>
              You have selected to view your Balances by Contribution Source for
              the account(s) listed below:
            </span>
            {selectedAccounts.map(({ accountId, accountName }) => {
              return <AccountName name={accountName} id={accountId} />;
            })}
          </div>
        ) : (
          ""
        )}
      </div>
      <div>
        The following information is current as of {asOfDateLocalValue}.
      </div>
      <div className="util-margin-top-15">
        <ContributionSources contributionSources={tempContributionSources} />
      </div>
      <div className="util-margin-top-15">
        These values may not include recent activity.
      </div>

      <div className="util-margin-top-15">
        The amounts listed on the website represent a measure of your benefits
        under a nonqualified benefit agreement between you and your plan
        sponsor. It is not a representation that your plan sponsor holds any
        assets for paying these benefits.
      </div>

      <div className="util-margin-top-15">
        At any time, the measure of your benefits is determined by reference to
        the performance of available investments as described in your Plan
        materials ("Reference Investments"). Throughout this site you will see
        terms such as Investment Option or Investment Advisor. These are
        indicators of the Reference Investments made available to measure your
        benefit(s) in your Nonqualified Plan.
      </div>

      {showChangeView ? (
        <ChangeView
          showChangeViewModal={showChangeView}
          accounts={accounts}
          selectedAccounts={selectedAccounts}
          setSelectedAccounts={setSelectedAccounts}
          initialAsOfDate={getStaticAsOfDate()}
          asOfDate={getAsOfDate()}
          setAsOfDate={setAsOfDateHandler}
          hide={hide}
        />
      ) : (
        ""
      )}
    </Page>
  );

  function hide() {
    setChangeViewModal(false);
  }
}
