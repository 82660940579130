import _flatMap from "lodash/flatMap";
import _map from "lodash/map";
import _sortBy from "lodash/sortBy";
import _uniq from "lodash/uniq";
import React from "react";
import { Column } from "react-table";
import TableV2 from "../../../../components/table/tableV2";
import { PaymentType } from "../../../../models/distributions/payment-type";
import { UnscheduledDistributionElection } from "../../../../models/distributions/unscheduled-distribution-election";
import { UnscheduledDistributionEvent } from "../../../../models/distributions/unscheduled-distribution-event";
import { UnscheduledDistributionEventCategory } from "../../../../models/distributions/unscheduled-distribution-event-category";
import { filterUnavailableElections } from "../../../../util/distributions-util";
import {
  addDisplayOrderToElections,
  filterOutDuplicateElectionsForEventCategory,
  groupElectionsByCategory,
} from "./unscheduled-distributions-table-util";
import "./unscheduled-distributions-table.scss";

export interface Props {
  accountLevelElections: UnscheduledDistributionElection[];
  participantLevelElections: UnscheduledDistributionElection[];
  unscheduledDistributionEventsByProvision: UnscheduledDistributionEvent[];
}

function buildEventHeader(
  category: UnscheduledDistributionEventCategory,
  sortedAvailableEvents: UnscheduledDistributionEvent[]
) {
  if (category === UnscheduledDistributionEventCategory.CUSTOM_QDE) {
    const customEvent: UnscheduledDistributionEvent | undefined =
      sortedAvailableEvents.find(
        (event) =>
          event.eventCategory ===
          UnscheduledDistributionEventCategory.CUSTOM_QDE
      );
    if (customEvent) {
      return customEvent.name;
    }
  }
  return category;
}

export default function UnscheduledDistributionTable(props: Props) {
  const {
    accountLevelElections,
    participantLevelElections,
    unscheduledDistributionEventsByProvision,
  } = props;

  const sortedAvailableEvents: UnscheduledDistributionEvent[] = _sortBy(
    unscheduledDistributionEventsByProvision,
    "displayOrder"
  );
  const categories: UnscheduledDistributionEventCategory[] = _uniq(
    _map(sortedAvailableEvents, "eventCategory")
  );
  const electionsSortedByDisplayOrder = addDisplayOrderToElections(
    accountLevelElections,
    participantLevelElections
  );
  const electionsByCategory: UnscheduledDistributionElection[][] =
    groupElectionsByCategory(electionsSortedByDisplayOrder);

  const filteredElections: UnscheduledDistributionElection[][] =
    filterOutDuplicateElectionsForEventCategory(electionsByCategory);
  const flattenedElections = _flatMap(filteredElections);

  const electionsFilteredUnavailableEvents = filterUnavailableElections(
    flattenedElections,
    unscheduledDistributionEventsByProvision
  );

  const columns: Column[] = categories.map((category) => {
    const elections = electionsFilteredUnavailableEvents.filter((election) => {
      return election.eventCategory === category;
    });
    const eventHeader = buildEventHeader(category, sortedAvailableEvents);
    return buildUnscheduledDistributionEventColumns(eventHeader, elections);
  });

  return (
    <>
      <div className="unscheduled-distributions-table-header">
        Distribution settings for this account
      </div>
      <div className="unscheduled-distribution-table">
        <TableV2
          columns={columns}
          data={[{}]}
          className="grid-table-wrapper"
          hasFooter={false}
          tableName="unscheduled-distribution-table"
        />
      </div>
    </>
  );
}

function buildUnscheduledDistributionEventColumns(
  eventHeader: string,
  elections: UnscheduledDistributionElection[]
): any {
  return {
    Header: <div>{eventHeader}</div>,
    accessor: `${eventHeader}`,
    Cell: () => {
      return renderEventElections(elections);
    },
  };
}

function renderElection(
  election: UnscheduledDistributionElection,
  hasMultipleEvents: boolean
) {
  const { eventCategory, paymentMethod, eventId } = election;
  const isSeparationFromService =
    eventCategory ===
    UnscheduledDistributionEventCategory.SEPARATION_FROM_SERVICE;
  const paymentMethodIsAvailable = paymentMethod !== PaymentType.NOT_AVAILABLE;

  const showName =
    isSeparationFromService && paymentMethodIsAvailable && hasMultipleEvents;

  return showName ? (
    <ElectionWithEventName key={eventId} election={election} />
  ) : (
    <ElectionWithoutEventName key={eventId} election={election} />
  );
}

function renderEventElections(
  elections: UnscheduledDistributionElection[] = []
) {
  if (elections.length > 0) {
    const hasMultipleEvents = elections.length > 1;
    return elections.map((election) =>
      renderElection(election, hasMultipleEvents)
    );
  }

  return <div>Not on File</div>;
}

type ElectionDisplay = {
  election: UnscheduledDistributionElection;
};

function ElectionWithEventName({ election }: ElectionDisplay) {
  return (
    <div className="row-cell">
      {election.eventName}: <strong>{election.paymentMethod}</strong>
    </div>
  );
}

function ElectionWithoutEventName({ election }: ElectionDisplay) {
  return <div className="row-cell">{election.paymentMethod}</div>;
}
