import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import IndividualInvestment from "../pages/investments/individual-investment/individual-investment";
import Manage from "../pages/investments/manage-investments/manage-investments";
import PerformancePage from "../pages/investments/performance/performance-page";
import Research from "../pages/investments/research/research";
import Summary from "../pages/investments/summary/summary";
import PrrPage from "../pages/overview/prr/prr-page";
import InvestmentAllocationPage from "../pages/contributions/investment-allocations/investment-allocation-page";

export default function InvestmentsRoutes() {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.url}/manage`}>
        <Manage />
      </Route>
      <Route path={`${match.url}/research`}>
        <Research />
      </Route>
      <Route path={`${match.url}/summary`}>
        <Summary />
      </Route>
      <Route path={`${match.url}/individual-investment/:investmentId`}>
        <IndividualInvestment />
      </Route>
      <Route path={`${match.url}/performance`}>
        <PerformancePage />
      </Route>
      <Route path={`${match.url}/investment-allocations`}>
        <InvestmentAllocationPage />
      </Route>
      <Route path={`${match.url}/rate-of-return`}>
        <PrrPage />
      </Route>
    </Switch>
  );
}
