import React from "react";
import {
  buildDateColumn,
  buildNumberColumn,
  buildTextColumn,
  ColumnWithClass,
} from "../../distribution-scheduled/build-schedule-distributions-columns-util/build-schedule-distributions-columns-utils";

export function buildDistributionDateColumn(): ColumnWithClass {
  const Header = () => (
    <span className="left-align header">
      Installment Date
      <span className="no-wrap show">(MM/DD/YYYY)</span>
    </span>
  );
  return buildDateColumn(Header, "distributionDate", "left-align", false);
}

export function buildAccountNameNodeColumn(): ColumnWithClass {
  const Header = () => <span className="header left-align">Account</span>;
  return buildTextColumn(Header, "accountNameNode", "left-align");
}

export function buildInstallmentColumn(): ColumnWithClass {
  const Header = () => <span className="header right-align">Installment</span>;
  return buildTextColumn(Header, "installment", "center-align");
}

export function buildStatusColumn(): ColumnWithClass {
  const Header = () => <span className="header left-align">Status</span>;
  return buildTextColumn(Header, "status", "center-align");
}

export function buildEstimatedDistributionColumn(): ColumnWithClass {
  const Header = () => (
    <span className="header">Estimated Distribution(s)</span>
  );
  return buildNumberColumn(
    Header,
    "estimatedDistribution",
    "right-align",
    true
  );
}

export function buildActualDistributionColumn(): ColumnWithClass {
  const Header = () => <span className="header">Actual Distribution(s)</span>;
  return buildNumberColumn(Header, "actualAmount", "center-align", true, false);
}

export function buildEstimatedAmountColumn(): ColumnWithClass {
  const Header = () => (
    <span className="header">Estimated Distribution(s)</span>
  );
  return buildNumberColumn(Header, "distributionAmount", "center-align", true);
}
