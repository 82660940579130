export enum UnscheduledDistributionEventCategory {
  NONE = "NONE",
  SEPARATION_FROM_SERVICE = "Separation From Service",
  CHANGE_IN_CONTROL = "Change in Control",
  DEATH = "Death",
  DISABILITY = "Disability",
  CUSTOM_QDE = "Custom Distribution Event",
  UNFORESEEABLE_EMERGENCY = "Unforeseeable Emergency",
  HAIRCUT = "Haircut",
  HEART_ACT_DISTRIBUTION = "HEART Act Distribution",
  TRANSFER_TO_457B = "Transfer to 457(b)",
  ELECTIVE_DISTRIBUTION = "Elective Distribution",
  INVOLUNTARY_SEPARATION_FROM_SERVICE = "Involuntary Separation",
  FORFEITURE_EVENT_VALUATION = "Forfeiture Event",
  SEPARATION_FROM_SERVICE_DUE_TO_CHANGE_IN_CONTROL = "Separation From Service - CC",
}
