import React from "react";
import BSModal from "react-bootstrap/Modal";
import { formatDateWithoutTimeZone } from "../../../../util/date-util";
import { buildManageContributionsLink } from "../../../../util/nq-participant-link-builder";

type beneModalProps = {
  showDeferralSuccessModal: boolean;
  hideDeferralSuccessModal: any;
  deferralEffectiveDate: Date;
  session: any;
};

export default function PendingDeferralElectionsCancel(props: beneModalProps) {
  const dateFormatted = formatDateWithoutTimeZone(
    props.deferralEffectiveDate,
    "MM/dd/yyyy"
  );
  const { showDeferralSuccessModal, hideDeferralSuccessModal, session } = props;
  return (
    <BSModal
      show={showDeferralSuccessModal}
      onHide={hideDeferralSuccessModal}
      backdropClassName="modal-backdrop"
      animation={false}
    >
      <BSModal.Header closeButton>
        <BSModal.Title as={"h2"}>Pending deferral elections</BSModal.Title>
      </BSModal.Header>
      <BSModal.Body>
        <p className="updated-contributions-success">
          You've successfully cancelled your deferral election changes that were
          set to be effective <b>{dateFormatted}</b>
        </p>
        <div>
          <span>
            If you wish to set new deferral elections,{" "}
            <a href={buildManageContributionsLink(session)}>click here</a>
          </span>
        </div>
      </BSModal.Body>
      <BSModal.Footer>
        <button
          className="btn btn-link"
          type="button"
          onClick={hideDeferralSuccessModal}
        >
          Close
        </button>
      </BSModal.Footer>
    </BSModal>
  );
}
