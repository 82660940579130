import React from "react";
import {
  contributionRatePostStatus,
  useStatuses,
} from "../../../selectors/status";
import { useSelector } from "../../../store/store";

type Props = {
  onSubmit?: any;
  isReview: any;
};

export default function ContributionsReviewSubmitButton(props: Props) {
  const { hasError } = useStatuses([contributionRatePostStatus]);
  const { isAdvisorOrEmployer } = useSelector((state) => state.session);
  const { onSubmit, isReview } = props;
  const impersonated = localStorage.getItem("impersonated") === "true";
  const submitBtnDisabled = isAdvisorOrEmployer || impersonated;

  const buttonText = isReview ? "Submit" : "Review";

  function handleSubmit() {
    onSubmit();
  }

  return (
    <>
      {!hasError && (
        <div className="review-button pull-right">
          <button
            onClick={() => handleSubmit()}
            type="submit"
            disabled={submitBtnDisabled}
            data-testid="contribution-rates-submit"
            className="btn btn-primary"
          >
            {buttonText}
          </button>
        </div>
      )}
      {submitBtnDisabled && (
        <>
          <br />
          <br />
          <br />
          <div className="alert alert-info small pull-right">
            <span>
              Only participants can review and submit changes to their deferral
              elections.
            </span>
          </div>
        </>
      )}
    </>
  );
}
