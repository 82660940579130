import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import PlanningCenterPage from "../pages/planning-center-overview/planning-center-page";

export default function PlanningCenterOverviewRoutes() {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.url}/planning-center`}>
        <PlanningCenterPage />
      </Route>
    </Switch>
  );
}
