import { AsyncStatus } from "../models/async-status";
import { RootState, useSelector } from "../store/store";

export const datesSelector = (state: RootState) => state.dates;
type Dates = {
  startDate?: string;
  endDate?: string;
  investmentSummaryStartDate?: string;
  investmentSummaryEndDate?: string;
  lastBusinessDate?: string;
  status: AsyncStatus;
};

export function useDates(): Dates {
  const {
    startDate,
    endDate,
    lastBusinessDate,
    investmentSummaryStartDate,
    investmentSummaryEndDate,
    status,
  } = useSelector(datesSelector);

  return {
    startDate,
    endDate,
    lastBusinessDate,
    investmentSummaryStartDate,
    investmentSummaryEndDate,
    status,
  };
}
