import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { getInstallmentFrequencyText, calculateInstallmentsPerYear, InstallmentFrequency } from "../../../models/distributions/installment-frequency";
import { GeneralOption } from "../../../models/generalOption";
import { RedeferralStatus } from "../../../models/redeferral/redeferral-status";
import { ScheduledDistributionConfig } from "../../../models/ScheduledDistributionProvision";
import {
  getMarketClosedDates,
  getScheduledDistributionConfig,
} from "../../../services/enrollment";
import { useSelector } from "../../../store/store";
import "./redefer-begin-stream-of-payment.scss";
import {
  getMonthName,
  getNewAdjustedDate,
  makeDateFromDMY,
} from "../../../util/date-util";
import {
  ALLOWED_DISTRIBUTION_INSTALLMENTS,
  ALLOWED_DISTRIBUTION_LUMP_SUM,
  VALUE_INSTALLMENTS,
  VALUE_LUMP_SUM,
} from "../../../util/constants/distribution-constants";
import { capitalizeFirstLetter } from "../../../util/string-util";
import {
  getAvailableMonths,
  getAvailableYears,
  getDaysForMonth,
} from "../../../util/redeferral-defined-date-util";

interface Props {
  status: RedeferralStatus;
}

export default function RedeferBeginStreamOfPayment(props: Props) {
  const history = useHistory();

  const { status } = props;
  const { planId } = useSelector((state) => state.session);

  const [provisionVersion, setProvisionVersion] = useState<number | null>(null);
  const [marketClosedDates, setMarketClosedDates] = useState<string[] | null>(
    null
  );
  const [scheduledDistributionConfig, setScheduledDistributionConfig] =
    useState<ScheduledDistributionConfig | null>(null);

  const [errors, setErrors] = useState<string[]>([]);

  const [selectedYear, setSelectedYear] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedDay, setSelectedDay] = useState("");
  const [selectedPayoutType, setSelectedPayoutType] = useState("");
  const [selectedInstallmentFrequency, setSelectedInstallmentFrequency] =
    useState("");
  const [selectedInstallmentCount, setSelectedInstallmentCount] = useState("");

  const [availableYears, setAvailableYears] = useState<number[]>([]);
  const [availableMonths, setAvailableMonths] = useState<number[]>([]);
  const [availableDays, setAvailableDays] = useState<GeneralOption[]>([]);

  const initialInstallmentFrequencies =
    getAvailableInstallmentFrequencies(selectedPayoutType);
  const [availableInstallmentFrequencies, setAvailableInstallmentFrequencies] =
    useState<string[]>(initialInstallmentFrequencies);
  const initialAvailableInstallmentCounts = getAvailableInstallmentCounts();
  const [availableInstallmentCounts, setAvailableInstallmentCounts] = useState<
    number[]
  >(initialAvailableInstallmentCounts);

  useEffect(() => {
    fetchScheduledDistributionProvision();
    fetchMarketClosedDates();

    async function fetchScheduledDistributionProvision() {
      const schedDistConfig = await getScheduledDistributionConfig(
        planId,
        status.accountTypeId
      );
      if (schedDistConfig && status) {
        setProvisionVersion(schedDistConfig.provisionVersion);
        setScheduledDistributionConfig(schedDistConfig);
        setAvailableInstallmentCounts(
          getAvailableInstallmentCounts(schedDistConfig)
        );
        const frequencies = getAvailableInstallmentFrequencies(
          selectedPayoutType,
          schedDistConfig
        );
        setAvailableInstallmentFrequencies(frequencies);
        setAvailableYears(
          getAvailableYears(
            schedDistConfig.planDefinedScheduledDistributionRules.definedMonth,
            schedDistConfig.planDefinedScheduledDistributionRules.definedDay,
            status.minimumRedeferralDateString
          )
        );
        setAvailableMonths(
          getAvailableMonths(
            schedDistConfig.planDefinedScheduledDistributionRules.definedDay,
            status.minimumRedeferralDateString,
            selectedYear
          )
        );
        setAvailableDays(
          getDaysForMonth(
            marketClosedDates,
            status.minimumRedeferralDateString,
            selectedYear,
            selectedMonth
          )
        );
      }
    }

    async function fetchMarketClosedDates() {
      const mcd = await getMarketClosedDates();
      if (mcd) {
        setMarketClosedDates(mcd);
      }
    }
  }, [planId]);

  useEffect(() => {
    if (scheduledDistributionConfig?.allowedDistributionTypes?.length === 1) {
      if (
        scheduledDistributionConfig.allowedDistributionTypes[0] ===
        ALLOWED_DISTRIBUTION_INSTALLMENTS
      ) {
        setSelectedPayoutType(VALUE_INSTALLMENTS);
        if (availableInstallmentFrequencies?.length === 1) {
          setSelectedInstallmentFrequency(availableInstallmentFrequencies[0]);
        }
        if (availableInstallmentCounts?.length === 1) {
          setSelectedInstallmentCount(availableInstallmentCounts[0].toString());
        }
      } else if (
        scheduledDistributionConfig.allowedDistributionTypes[0] ===
        ALLOWED_DISTRIBUTION_LUMP_SUM
      ) {
        setSelectedPayoutType(VALUE_LUMP_SUM);
      }
    }
  }, [
    scheduledDistributionConfig,
    availableInstallmentFrequencies,
    availableInstallmentCounts,
  ]);

  useEffect(() => {
    if (selectedPayoutType != "") {
      refreshAvailablePayoutOptions(selectedPayoutType);
    }
  }, [selectedPayoutType]);

  function refreshAvailableDays(year: string, month: string) {
    let actualMonth = month;
    if (scheduledDistributionConfig) {
      if (
        scheduledDistributionConfig.planDefinedScheduledDistributionRules &&
        scheduledDistributionConfig.planDefinedScheduledDistributionRules
          .definedMonth
      ) {
        actualMonth = (
          scheduledDistributionConfig.planDefinedScheduledDistributionRules
            .definedMonth - 1
        ).toString();
      }
      setAvailableMonths(
        getAvailableMonths(
          scheduledDistributionConfig.planDefinedScheduledDistributionRules
            .definedDay,
          status.minimumRedeferralDateString,
          year
        )
      );
    }
    setAvailableDays(
      getDaysForMonth(
        marketClosedDates,
        status.minimumRedeferralDateString,
        year,
        actualMonth
      )
    );
  }

  function refreshAvailablePayoutOptions(payoutType: string) {
    setAvailableInstallmentFrequencies(
      getAvailableInstallmentFrequencies(
        payoutType,
        scheduledDistributionConfig
      )
    );
    setAvailableInstallmentCounts(
      getAvailableInstallmentCounts(scheduledDistributionConfig)
    );
    if (availableInstallmentFrequencies?.length === 1) {
      setSelectedInstallmentFrequency(availableInstallmentFrequencies[0]);
    }
    if (availableInstallmentCounts?.length === 1) {
      setSelectedInstallmentCount(availableInstallmentCounts[0].toString());
    }
    if (selectedPayoutType !== "INSTALLMENTS") {
      setSelectedInstallmentCount("");
      setSelectedInstallmentFrequency("");
    }
  }

  function getAvailableInstallmentFrequencies(
    payoutType: string,
    scheduledDistributionConfig?: ScheduledDistributionConfig | null
  ): string[] {
    const frequencies: string[] = [];
    if (scheduledDistributionConfig == null) {
      return frequencies;
    }
    return scheduledDistributionConfig.frequencyOptions;
  }

  function getMinimumRedeferralYear(): number {
    return Number(status.minimumRedeferralDateString.split("/")[2]);
  }

  function getMinimumRedeferralMonth(): number {
    return Number(status.minimumRedeferralDateString.split("/")[0]);
  }

  function getMinimumRedeferralDay(): number {
    return Number(status.minimumRedeferralDateString.split("/")[1]);
  }

  function getMinimumDate(): Date {
    return makeDateFromDMY(
      getMinimumRedeferralDay(),
      getMinimumRedeferralMonth(),
      getMinimumRedeferralYear()
    );
  }

  function getAvailableInstallmentCounts(
    scheduledDistributionConfig?: ScheduledDistributionConfig | null
  ): number[] {
    const counts: number[] = [];
    if (scheduledDistributionConfig == null) {
      return counts;
    }
    return scheduledDistributionConfig.allowedInstallmentCounts;
  }

  function handlePayoutTypeChange(event: React.ChangeEvent<HTMLSelectElement>) {
    const { value } = event.target;
    setSelectedPayoutType(value);
  }

  function handleInstallmentFrequencyChange(
    event: React.ChangeEvent<HTMLSelectElement>
  ) {
    const { value } = event.target;
    setSelectedInstallmentFrequency(value);
  }

  function handleInstallmentCountChange(
    event: React.ChangeEvent<HTMLSelectElement>
  ) {
    const { value } = event.target;
    setSelectedInstallmentCount(value);
  }

  function handleYearChange(event: React.ChangeEvent<HTMLSelectElement>) {
    const { value } = event.target;
    setSelectedYear(value);
    setSelectedMonth("");
    setSelectedDay("");
    refreshAvailableDays(value, selectedMonth);
  }

  function handleMonthChange(event: React.ChangeEvent<HTMLSelectElement>) {
    const { value } = event.target;
    setSelectedMonth(value);
    setSelectedDay("");
    refreshAvailableDays(selectedYear, value);
  }

  function handleDayChange(event: React.ChangeEvent<HTMLSelectElement>) {
    const { value } = event.target;
    setSelectedDay(value);
  }

  function refreshErrors() {
    const foundErrors = [];

    if (status.planAllowsFormChangeRedeferral) {
      if (selectedPayoutType === "") {
        foundErrors.push("Please select a payout type.");
      }
      if (selectedPayoutType === "INSTALLMENTS") {
        if (selectedInstallmentFrequency === "") {
          foundErrors.push("Please select an installment frequency.");
        }
        if (selectedInstallmentCount === "") {
          foundErrors.push("Please select installment years.");
        }
      }
    }

    if (status.planAllowsTimeChangeRedeferral) {
      const monthIsRequired =
        !scheduledDistributionConfig?.planDefinedScheduledDistributionRules
          .definedMonth;
      if (selectedMonth === "" && monthIsRequired) {
        foundErrors.push("Please select a month.");
      }
      const dayIsRequired =
        !scheduledDistributionConfig?.planDefinedScheduledDistributionRules
          .definedDay;
      if (selectedDay === "" && dayIsRequired) {
        foundErrors.push("Please select a day.");
      }
      if (selectedYear === "") {
        foundErrors.push("Please select a year.");
      }
    }

    setErrors(foundErrors);
    return foundErrors;
  }

  function getReviewURL() {
    const newDateParams =
      status.planAllowsTimeChangeRedeferral &&
      scheduledDistributionConfig &&
      marketClosedDates
        ? "date=" +
          getNewAdjustedDate(
            selectedYear,
            selectedMonth,
            selectedDay,
            scheduledDistributionConfig.planDefinedScheduledDistributionRules
              .definedMonth,
            scheduledDistributionConfig.planDefinedScheduledDistributionRules
              .definedDay,
            scheduledDistributionConfig.planDefinedScheduledDistributionRules
              .adjustDateBack,
            marketClosedDates,
            getMinimumDate()
          )
        : "";

    let newMethodParams = "";
    if (status.planAllowsFormChangeRedeferral) {
      newMethodParams = `payoutType=${selectedPayoutType}`;
      if (selectedInstallmentFrequency !== "") {
        newMethodParams += `&installmentFrequency=${selectedInstallmentFrequency}`;
      }
      if (selectedInstallmentCount !== "") {
        newMethodParams += `&installmentCount=${selectedInstallmentCount}`;
      }
    }

    const joinIfNecessary =
      status.planAllowsFormChangeRedeferral &&
      status.planAllowsTimeChangeRedeferral
        ? `&`
        : ``;
    return `/distributions/redeferral/review/account/${status.accountId}?${newDateParams}${joinIfNecessary}${newMethodParams}`;
  }

  function continueToReview() {
    const foundErrors = refreshErrors();
    if (foundErrors.length === 0) {
      history.push(getReviewURL());
    }
  }

  function makeFriendlyInstallmentFrequencyText(inputString: string): string {
    return capitalizeFirstLetter(inputString);
  }

  function makeFriendlyPayoutMethodText(inputString: string): string {
    if (inputString === ALLOWED_DISTRIBUTION_INSTALLMENTS) {
      return (
        VALUE_INSTALLMENTS.toString().charAt(0).toUpperCase() +
        VALUE_INSTALLMENTS.toString().substr(1).toLowerCase()
      );
    } else if (inputString === ALLOWED_DISTRIBUTION_LUMP_SUM) {
      return "Lump Sum";
    }
    return "No Payout Method";
  }

  function getHeaderText() {
    if (
      status &&
      status.planAllowsTimeChangeRedeferral &&
      status.planAllowsFormChangeRedeferral
    ) {
      return `To change your scheduled distribution, select a new distribution date and a new distribution method.`;
    } else if (status && status.planAllowsTimeChangeRedeferral) {
      return `To change your scheduled distribution, select a new distribution date.`;
    } else if (status && status.planAllowsFormChangeRedeferral) {
      return `To change your scheduled distribution, select a new distribution method.`;
    } else {
      return null;
    }
  }

  if (provisionVersion !== null && scheduledDistributionConfig !== null) {
    const headerText = getHeaderText();
    const definedMonthNumber =
      scheduledDistributionConfig.planDefinedScheduledDistributionRules
        .definedMonth;
    const definedDay =
      scheduledDistributionConfig.planDefinedScheduledDistributionRules
        .definedDay;
    return (
      <>
        <section id="redefer-begin-header" data-testid="redefer-begin-header">
          <p>{headerText}</p>
          <div className="alert alert-warning">
            <p>
              Changes to nonqualified deferred compensation plan elections must
              be made in accordance with IRC Section 409A rules. The rules
              include the requirement that the change must be made at least 12
              months prior to the scheduled payment date and delays the payment
              for at least 5 years. See Internal Revenue Code Section
              409A(a)(4)(C) for complete rules.
            </p>
          </div>
        </section>
        <section id="redefer-method-selection">
          <hr />
          <p>
            <strong>
              1. How would you like the benefits for this account to be
              distributed?
            </strong>
          </p>
          <p>
            <em>
              Your previously selected distribution method is:{" "}
              {status.scheduledDistributionMethod}
            </em>
          </p>
          {status.planAllowsFormChangeRedeferral && (
            <>
              <div className="row">
                <div className="col-md-12">
                  <p>Select a new payout method:</p>
                </div>
                <div className="col-md-4">
                  <label htmlFor="payoutTypeInput" className="selectLabel">
                    Payout Method
                  </label>
                  {scheduledDistributionConfig.allowedDistributionTypes
                    .length === 1 && (
                    <>
                      <p
                        className="single-option-text"
                        data-testid="payoutTypeText"
                      >
                        {makeFriendlyPayoutMethodText(
                          scheduledDistributionConfig
                            .allowedDistributionTypes[0]
                        )}
                      </p>
                    </>
                  )}
                  {scheduledDistributionConfig.allowedDistributionTypes.length >
                    1 && (
                    <select
                      onChange={handlePayoutTypeChange}
                      className={"form-control"}
                      id="payoutTypeInput"
                      data-testid="payoutTypeInput"
                      name="payoutType"
                      defaultValue={""}
                    >
                      <option value={""} disabled={true}>
                        Select a Payout Type
                      </option>
                      {scheduledDistributionConfig.allowedDistributionTypes.includes(
                        ALLOWED_DISTRIBUTION_INSTALLMENTS
                      ) && (
                        <option
                          value={VALUE_INSTALLMENTS}
                          data-testid="installments-option"
                        >
                          Installments
                        </option>
                      )}
                      {scheduledDistributionConfig.allowedDistributionTypes.includes(
                        ALLOWED_DISTRIBUTION_LUMP_SUM
                      ) && (
                        <option
                          value={VALUE_LUMP_SUM}
                          data-testid="lump-sum-option"
                        >
                          Lump Sum
                        </option>
                      )}
                    </select>
                  )}
                </div>
                {selectedPayoutType === VALUE_INSTALLMENTS && (
                  <>
                    <div className="col-md-4">
                      <label
                        htmlFor="installmentFrequencyInput"
                        className="selectLabel"
                      >
                        Installment Frequency
                      </label>
                      {availableInstallmentFrequencies.length === 1 && (
                        <>
                          <p className="single-option-text">
                            {makeFriendlyInstallmentFrequencyText(
                              availableInstallmentFrequencies[0]
                            )}
                          </p>
                        </>
                      )}
                      {availableInstallmentFrequencies.length > 1 && (
                        <select
                          onChange={handleInstallmentFrequencyChange}
                          className={"form-control"}
                          id="installmentFrequencyInput"
                          data-testid="installmentFrequencyInput"
                          name="installmentFrequency"
                          defaultValue={""}
                        >
                          <option
                            value={""}
                            disabled={true}
                            data-testid="default-installments-frequency-option"
                          >
                            Select an Installment Frequency
                          </option>
                          {availableInstallmentFrequencies.map((frequency) => (
                            <option
                              value={frequency}
                              data-testid={
                                frequency + "-installments-frequency-option"
                              }
                            >
                              {getInstallmentFrequencyText(frequency)}
                            </option>
                          ))}
                        </select>
                      )}
                    </div>
                    <div className="col-md-4">
                      <label
                        htmlFor="installmentCountInput"
                        className="selectLabel"
                      >
                        Installment Years
                      </label>
                      {availableInstallmentCounts.length === 1 && (
                        <p className="single-option-text">
                          {availableInstallmentCounts[0]}
                        </p>
                      )}
                      {availableInstallmentCounts.length > 1 && (
                        <select
                          onChange={handleInstallmentCountChange}
                          className={"form-control"}
                          id="installmentCountInput"
                          data-testid="installmentCountInput"
                          name="installmentCount"
                          defaultValue={""}
                        >
                          <option
                            value={""}
                            disabled={true}
                            data-testid="default-installments-count-option"
                          >
                            Select Installment Years
                          </option>
                          {availableInstallmentCounts.map(
                            (installmentCount) => (
                              <option
                                value={installmentCount}
                                data-testid={
                                  installmentCount +
                                  "-installments-count-option"
                                }
                              >
                                {installmentCount}
                              </option>
                            )
                          )}
                        </select>
                      )}
                    </div>
                  </>
                )}
              </div>
                { selectedInstallmentCount != "" &&
                selectedInstallmentFrequency != "ANNUAL" && (
                    <p className="util-margin-top-5">
                        <em>
                            {getInstallmentFrequencyText(selectedInstallmentFrequency)}
                            {" "}installments over {selectedInstallmentCount} years ({
                                calculateInstallmentsPerYear(selectedInstallmentFrequency)
                                * parseInt(selectedInstallmentCount)
                            } installments).
                        </em>
                    </p>
                )}
            </>
          )}
          {!status.planAllowsFormChangeRedeferral && (
            <>
              <p>
                Your plan does not allow you to change your distribution method.
              </p>
            </>
          )}
        </section>
        <section id="redefer-date-selection">
          <hr />
          <p>
            <strong>
              2. When would you like the benefits for this account to be
              distributed?
            </strong>
          </p>
          <p>
            <em>
              You previously selected distribution date is:{" "}
              {status.scheduledDistributionPayoutStartDate}
            </em>
          </p>
          {status.planAllowsTimeChangeRedeferral && (
            <>
              <div className="row">
                <div className="col-md-12">
                  <p>
                    Your new date must be five years or more from your
                    previously selected distribution date. The earliest you may
                    select is {status.minimumRedeferralDateString}.
                  </p>
                  <p>Select a new payout start date:</p>
                </div>
                <div className="col-md-4">
                  <label htmlFor="yearInput" className="selectLabel">
                    Year
                  </label>
                  <select
                    onChange={handleYearChange}
                    className={"form-control"}
                    id="yearInput"
                    data-testid="yearInput"
                    name="year"
                    defaultValue={""}
                  >
                    <option
                      value={""}
                      disabled={true}
                      data-testid="default-year-option"
                      selected={"" === selectedYear}
                    >
                      Select a Year
                    </option>
                    {availableYears.map((yearNumber) => (
                      <option
                        value={yearNumber}
                        data-testid={yearNumber + "-year-option"}
                        selected={yearNumber.toString() === selectedYear}
                      >
                        {yearNumber}
                      </option>
                    ))}
                  </select>
                </div>
                {definedMonthNumber && (
                  <>
                    <div className="col-md-4">
                      <strong>Month</strong>
                      <p data-testid="monthDefined">
                        Your plan does not allow you to elect a month for the
                        payout.
                      </p>
                      {!scheduledDistributionConfig
                        .planDefinedScheduledDistributionRules
                        .hideDefinedMonth && (
                        <>
                          <p data-testid="reveal-defined-month">
                            Your payout will start in{" "}
                            {getMonthName(definedMonthNumber - 1)}.
                          </p>
                        </>
                      )}
                    </div>
                  </>
                )}
                {!definedMonthNumber && (
                  <>
                    <div className="col-md-4">
                      <label htmlFor="monthInput" className="selectLabel">
                        Month
                      </label>
                      <select
                        className={"form-control"}
                        onChange={handleMonthChange}
                        id="monthInput"
                        data-testid="monthInput"
                        name="month"
                        defaultValue={""}
                      >
                        <option
                          value={""}
                          disabled={true}
                          data-testid={"default-month-option"}
                          selected={"" === selectedMonth}
                        >
                          Select a Month
                        </option>
                        {availableMonths.map((monthNumber) => (
                          <option
                            value={monthNumber}
                            data-testid={monthNumber + "-month-option"}
                            selected={monthNumber.toString() === selectedMonth}
                          >
                            {getMonthName(monthNumber)}
                          </option>
                        ))}
                      </select>
                    </div>
                  </>
                )}
                {definedDay && (
                  <>
                    <div className="col-md-4">
                      <strong>Day</strong>
                      <p data-testid="dayDefined">
                        Your plan does not allow you to elect a day for the
                        payout.
                      </p>
                      {!scheduledDistributionConfig
                        .planDefinedScheduledDistributionRules
                        .hideDefinedDay && (
                        <>
                          <p data-testid="reveal-defined-day">
                            Your payout will start on day {definedDay}.
                          </p>
                        </>
                      )}
                    </div>
                  </>
                )}
                {!definedDay && (
                  <>
                    <div className="col-md-4">
                      <label htmlFor="dayInput" className="selectLabel">
                        Day
                      </label>
                      <select
                        onChange={handleDayChange}
                        className={"form-control"}
                        id="dayInput"
                        data-testid="dayInput"
                        name="day"
                        defaultValue={""}
                      >
                        <option
                          value={""}
                          disabled={true}
                          data-testid={"default-day-option"}
                          selected={"" === selectedDay}
                        >
                          Select a Day
                        </option>
                        {availableDays.map((dayNumber) => (
                          <option
                            value={dayNumber.value}
                            disabled={dayNumber.disabled}
                            data-testid={dayNumber.value + "-day-option"}
                            selected={dayNumber.value === selectedDay}
                          >
                            {dayNumber.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </>
                )}
              </div>
            </>
          )}
          {!status.planAllowsTimeChangeRedeferral && (
            <>
              <p>
                Your plan does not allow you to change your distribution date.
              </p>
            </>
          )}
        </section>
        <section
          id="redeferral-begin-conclude"
          className="row util-margin-top-10"
        >
          <hr />
          {errors.length > 0 && (
            <div className="col-md-12">
              <div className="alert alert-danger">
                <p>Please fix errors before continuing:</p>
                <ul>
                  {errors.map((error) => (
                    <li>{error}</li>
                  ))}
                </ul>
              </div>
            </div>
          )}
          <div className="col-md-12">
            <div className="pull-right">
              <Link
                className="btn btn-default util-margin-left-5"
                to={"/distributions/summary"}
              >
                Cancel
              </Link>
              <button
                className="btn btn-primary util-margin-left-5"
                onClick={() => continueToReview()}
              >
                Continue
              </button>
            </div>
          </div>
        </section>
      </>
    );
  } else {
    return <p>Loading...</p>;
  }
}
