import React from "react";
import Card from "../../../components/card/card";
import CardContent from "../../../components/card/card-content";
import CardFooter from "../../../components/card/card-footer";
import CardHeader from "../../../components/card/card-header";

type Props = {
  url: string;
};

export function TransferRestrictionsCard(props: Props) {
  const { url } = props;
  return (
    <Card fillHeight>
      <CardHeader>Transfer Restrictions</CardHeader>
      <CardContent>
        <div className="fa-stack fa-3x">
          <i
            className="fa fa-circle fa-stack-2x text-midnight"
            aria-hidden="true"
          />
          <i
            className="fa fa-exchange fa-stack-1x fa-inverse"
            aria-hidden="true"
          />
        </div>
        <p className="util-margin-vert-30">
          View transfer restrictions for this investment.
        </p>
      </CardContent>
      <CardFooter>
        <a
          id="invest-contributions-button"
          className="btn btn-primary"
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          title="Opens in a new window"
        >
          View Transfer Restrictions
        </a>
      </CardFooter>
    </Card>
  );
}
