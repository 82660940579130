import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AsyncStatus } from "../../models/async-status";
import { RedeferralStatus } from "../../models/redeferral/redeferral-status";
import { getAccountRedeferralStatuses } from "../../services/redeferral";

export const fetchAccountRedeferralStatuses = createAsyncThunk(
  "fetch-account-redeferral-status",
  async (
    { planId, empId, role }: { planId: number; empId: number; role: string },
    thunkApi
  ) => {
    return getAccountRedeferralStatuses({
      planId,
      empId,
      role,
    });
  }
);

type State = {
  accountRedeferralStatuses: RedeferralStatus[];
  status: AsyncStatus;
};

const initialState: State = {
  accountRedeferralStatuses: [],
  status: AsyncStatus.not_fetched,
};

const accountRedeferralStatusesSlice = createSlice({
  name: "accountRedeferralStatuses",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAccountRedeferralStatuses.pending, (state, action) => {
      return { ...state, status: AsyncStatus.loading };
    });
    builder.addCase(
      fetchAccountRedeferralStatuses.fulfilled,
      (state, action: { payload: RedeferralStatus[]; type: string }) => {
        const accountRedeferralStatuses = action.payload;

        return {
          ...state,
          accountRedeferralStatuses,
          status: AsyncStatus.done,
        };
      }
    );
    builder.addCase(
      fetchAccountRedeferralStatuses.rejected,
      (state, action) => {
        return { ...state, status: AsyncStatus.error };
      }
    );
    builder.addDefaultCase((state, action) => state);
  },
});

export default accountRedeferralStatusesSlice.reducer;
