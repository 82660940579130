import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AsyncStatus } from "../../models/async-status";
import { PlanInfoModel } from "../../models/plan/plan-info-model";
import { getPlanInfo } from "../../services/plans";

export const fetchPlanInfo = createAsyncThunk(
  "fetch-plan-info",
  async (
    { planId, empId, role }: { planId: number; empId: number; role: string },
    thunkApi
  ) => {
    return getPlanInfo({ planId, empId, role });
  }
);

const initialState: PlanInfoModel = {
  contractId: "",
  disclaimers: [],
  employerContactPhoneNumber: "",
  supportOnlineRedeferralDuringEventOnly: false,
  streamOfPayment: false,
  supportsShawModel: false,
  participantName: "",
  planCode: "",
  planName: "",
  allowContinuousEnrollment: false,
  status: AsyncStatus.not_fetched,
  contributionEffectiveDate: "",
  contributionLimit: 0,
  allowDistributionChanges: false,
  planFlags: {
    shouldHideAccountNumber: false,
    shouldHidePersonalizedRateOfReturn: false,
    shouldHideFinancialWellnessArticles: false,
    shouldHidePersonalInformation: false,
    shouldHidePTCTracker: false,
    shouldHideAccountOverviewOnDashboard: false,
    shouldHideDashboardTabbedView: false,
    shouldHidePerformanceTabOnDashboard: false,
    shouldHideAccountsTabOnDashboard: false,
    shouldHideInvestmentsTabOnAccountOverview: false,
    shouldHideAllocationsTabOnAccountOverview: false,
    shouldHideContributionsTabOnAccountOverview: false,
    shouldHideDistributionsTabOnAccountOverview: false,
    shouldHidePrimaryBeneficiaries: false,
    shouldHideContingentBeneficiaries: false,
    shouldHideAllAccountsOnOnDemandStatement: false,
    shouldHideContributionBreakdownOnOnDemandStatement: false,
    shouldHideInvestmentAllocationOnOnDemandStatement: false,
    shouldHideTotalsInThisPeriodOnOnDemandStatement: false,
    shouldHideTotalAccountBalanceChartOnOnDemandStatement: false,
    shouldHideIndividualAccountStatements: false,
    shouldHideDeferralElectionsOnContributionSummary: false,
    shouldHideContributionsResourcesOnContributionSummary: false,
    shouldHideContributionAmountsOnContributionSummary: false,
    shouldHideFutureInvestmentsOnChangeInvestments: false,
    shouldHideCurrentInvestmentsOnChangeInvestments: false,
    shouldHideScheduledDistributionsTimelineOnDistributions: false,
    shouldHideDistributionElectionSummaryOnDistributions: false,
    shouldHideScheduledDistributionsOnDistributions: false,
    shouldHideDistributionHistoryOnDistributions: false,
    shouldHideZeroMoneyTypes: false,
    shouldHideContributionPanel: false,
    shouldHideEnrollmentVideoLink: false,
    shouldShowTrsStatements: false,
    shouldHideDistributionForecastingTab: false,
    shouldHideStatements: false,
    shouldHideOnDemandStatement: false,
  },
};

const planInfoSlice = createSlice({
  name: "plan-info",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPlanInfo.pending, (state, action) => {
      return { ...state, status: AsyncStatus.loading };
    });
    builder.addCase(fetchPlanInfo.fulfilled, (state, action) => {
      return { ...state, ...action.payload, status: AsyncStatus.done };
    });
    builder.addCase(fetchPlanInfo.rejected, (state, action) => {
      return { ...state, status: AsyncStatus.error };
    });
    builder.addDefaultCase((state, action) => state);
  },
});

export default planInfoSlice.reducer;
