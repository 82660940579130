import { get } from "../repositories/participant";

type Args = {
  planId: number;
  empId: number;
  role: string;
};

export async function getPrivileges({ planId, empId, role }: Args) {
  return get(`/plans/${planId}/employees/${empId}/privileges`, { role });
}
