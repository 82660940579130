import React from "react";
import { useSelector } from "../../store/store";
import { jsfDomain, NOT_AVAILABLE } from "../../util/constants";

function PersonalInfoTable() {
  const personalDetails = useSelector((state) => state.participant.data);
  const isAdvisorOrEmployer = useSelector(
    (state) => state.session.isAdvisorOrEmployer
  );

  return (
    <>
      <table data-testid="personal-info-table" className="table">
        <thead>
          <tr>
            <th>
              <h3>
                Personal information<em>*</em>
              </h3>
            </th>
            <th>
              {!isAdvisorOrEmployer && (
                <a
                  className="btn btn-default pull-right"
                  href={jsfDomain + "yourprofile/isea/member/yourprofile.jsp"}
                  data-testid="update-info"
                >
                  Update your information
                </a>
              )}
              {isAdvisorOrEmployer && (
                <button
                  className="btn btn-default pull-right"
                  aria-disabled={true}
                  disabled
                  data-testid="update-info"
                >
                  Update your information
                </button>
              )}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Name</td>
            <td data-testid="name" data-hj-suppress="true">
              {buildName(personalDetails)}
            </td>
          </tr>
          <tr>
            <td>Address</td>
            <td data-testid="address" data-hj-suppress="true">
              {personalDetails.address.fullAddress}
            </td>
          </tr>
          <tr>
            <td>Date of Birth</td>
            <td data-testid="dateOfBirth" data-hj-suppress="true">
              {personalDetails.dateOfBirthFormatted
                ? personalDetails.dateOfBirthFormatted
                : NOT_AVAILABLE}
            </td>
          </tr>
          <tr>
            <td>Date of Employment</td>
            <td data-testid="employmentDate" data-hj-suppress="true">
              {personalDetails.employmentDateFormatted
                ? personalDetails.employmentDateFormatted
                : NOT_AVAILABLE}
            </td>
          </tr>
          <tr>
            <td>Date of Termination</td>
            <td data-testid="terminationDate" data-hj-suppress="true">
              {personalDetails.terminationDateFormatted
                ? personalDetails.terminationDateFormatted
                : NOT_AVAILABLE}
            </td>
          </tr>
          <tr>
            <td>Home Phone</td>
            <td data-testid="homePhone" data-hj-suppress="true">
              {personalDetails.homePhone
                ? personalDetails.homePhone
                : NOT_AVAILABLE}
            </td>
          </tr>
          <tr>
            <td>Day Phone</td>
            <td data-testid="dayPhone" data-hj-suppress="true">
              {personalDetails.dayPhone || NOT_AVAILABLE}
            </td>
          </tr>
          <tr>
            <td>Email Address</td>
            <td data-testid="emailAddress" data-hj-suppress="true">
              {personalDetails.emailAddress || NOT_AVAILABLE}
            </td>
          </tr>
        </tbody>
      </table>
      <div className="row">
        <div className="col-xs-8">
          <span>
            <em>
              * Updates to your personal information will be effective within 3
              business days.
            </em>
          </span>
        </div>
      </div>
    </>
  );
}

function buildName(personalDetails) {
  let name = "";
  if (personalDetails.firstName) {
    name += personalDetails.firstName;
  }
  if (personalDetails.middleName) {
    name += " " + personalDetails.middleName;
  }
  if (personalDetails.lastName) {
    name += " " + personalDetails.lastName;
  }
  return name;
}

export default PersonalInfoTable;
