import React from "react";
import { PdsCard, PdsLink } from "@principal/design-system-react";
import "./enroll-help-card.scss";
import { PdsIconArrowRight } from "@principal/design-system-icons-react";
import {
  customPlanInfoLink,
  planningResourcesLink,
} from "../../util/constants";
import { PlanType } from "../../models/ParticipantEvent";

import {
  shouldHideEducationHubSelector,
  shouldHideToolsSelector,
  shouldHidePlanInfoAndFormsSelector,
} from "../../selectors/plan-info";
import { useSelector } from "../../store/store";

interface EnrollmentHelpProps {
  planId: number;
  empId: number;
  planType: PlanType;
  tutorialLink: string[];
}

const EnrollmentHelpCard = (props: EnrollmentHelpProps) => {
  const showEducationHubEnabled = useSelector(shouldHideEducationHubSelector);
  const showToolsEnabled = useSelector(shouldHideToolsSelector);
  const showPlanInfoAndFormsEnabled = useSelector(
    shouldHidePlanInfoAndFormsSelector
  );
  const educationHubMarginSize = !showEducationHubEnabled ? "10%" : "-20%";
  const toolHubMarginSize =
    !showEducationHubEnabled || !showToolsEnabled ? "10%" : "-20%";
  return (
    <div className="card-container">
      <PdsCard onClick={function ou() {}}>
        <div slot="header" style={{ fontSize: "140%", margin: "5%" }}>
          Need help enrolling?
        </div>
        <div slot="header" style={{ margin: "5%", marginTop: "-5%" }}>
          Use these resources below to better understand your plan.
        </div>
        {!showEducationHubEnabled && (
          <div
            slot="footer"
            style={{
              fontWeight: "bold",
              marginTop: "-20%",
              marginLeft: "-5%",
              fontSize: "95%",
            }}
          >
            Need more information about what nonqualified plans are?
          </div>
        )}
        {!showEducationHubEnabled && (
          <div slot="footer" style={{ marginLeft: "-5%" }}>
            This link will show NQDC educational videos, basics, how to maximize
            your plan, and anticipating a distribution.
            <PdsLink
              href={planningResourcesLink(
                props.planId,
                props.empId,
                props.planType
              )}
            >
              {" "}
              <span
                style={{
                  fontSize: "130%",
                  display: "inline-flex",
                  alignItems: "center",
                }}
              >
                {" "}
                Nonqualified learning center
                <PdsIconArrowRight size="sm" color={"#0061a0"} />
              </span>
            </PdsLink>
          </div>
        )}
        {!showToolsEnabled && (
          <div
            slot="footer"
            style={{
              fontWeight: "bold",
              marginLeft: "-5%",
              fontSize: "95%",
              marginTop: educationHubMarginSize,
            }}
          >
            Not sure what to expect?
          </div>
        )}
        {!showToolsEnabled && (
          <div slot="footer" style={{ marginLeft: "-5%" }}>
            View this educational video which explains a Nonqualified Deferred
            Compensation Plan and its benefits.
            <PdsLink href={props.tutorialLink[0]}>
              {" "}
              <span
                style={{
                  fontSize: "130%",
                  display: "inline-flex",
                  alignItems: "center",
                }}
              >
                Nonqualified plan tutorial
                <PdsIconArrowRight size="sm" color={"#0061a0"} />
              </span>
            </PdsLink>
          </div>
        )}
        {!showPlanInfoAndFormsEnabled && (
          <div
            slot="footer"
            style={{
              fontWeight: "bold",
              marginLeft: "-5%",
              fontSize: "95%",
              marginTop: toolHubMarginSize,
            }}
          >
            Need more information about your plan?
          </div>
        )}
        {!showPlanInfoAndFormsEnabled && (
          <div slot="footer" style={{ marginLeft: "-5%" }}>
            View these forms to review plan details and other information.
            <PdsLink href={customPlanInfoLink(props.planId, props.empId)}>
              {" "}
              <span
                style={{
                  fontSize: "130%",
                  display: "inline-flex",
                  alignItems: "center",
                }}
              >
                Plan info and forms
                <PdsIconArrowRight size="sm" color={"#0061a0"} />
              </span>
            </PdsLink>
          </div>
        )}
        <div slot="footer" style={{ marginLeft: "-5%", marginTop: "10%" }}>
          These resources are for general information only and are not intended
          to provide specific or recommendations to any individual. All
          situations and investors described are hypothetical. Please consult
          your attorney, accountant or financial or tax advisor with regard to
          your individual situation.
        </div>
      </PdsCard>
    </div>
  );
};

export default EnrollmentHelpCard;
