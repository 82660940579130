import React from "react";
import { timeFormat } from "d3-time-format";
import { Styles } from "../types/styles";

const showYear = timeFormat("%Y");

export type TickProps = {
  date: Date;
  styles: Styles;
  x: number;
};

export default function Tick({ x, date, styles }: TickProps) {
  const { tick, axis, height } = styles;
  const axisY = height - axis.y;

  // we use this since we align to the middle of the text height
  const halfFontSize = tick.fontSize / 2;
  const textY = axisY + tick.marginTop + halfFontSize;

  return (
    <text x={x} y={textY} className="tick-label" fontSize={tick.fontSize}>
      {showYear(date)}
    </text>
  );
}
