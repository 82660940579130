import React, { useEffect, useState } from "react";
import { Page, PageHeader, PageSubheader } from "../../../components/page";
import { Skeleton } from "../../../components/skeleton/skeleton";
import { ManageInvestments as ManageInvestmentsType } from "../../../models/manage-investments";
import { getManageInvestments } from "../../../services/investment-data";
import { useSelector } from "../../../store/store";
import { formatLinkForRole } from "../../../util/nq-participant-link-builder";
import CurrentInvestmentsCard from "./current-investments-card";
import FutureInvestmentsCard from "./future-investments-card";
import "./manage-investments.scss";
import {
  hideCurrentInvestmentsSelector,
  hideFutureInvestmentsSelector,
} from "../../../selectors/plan-info";

const name = "manage-investments";

function ManageInvestments() {
  const { planId, empId, role } = useSelector((state) => state.session);
  const [loading, setLoading] = useState(true);
  const [manageInvestments, setManageInvestments] = useState<
    ManageInvestmentsType | undefined
  >();
  const [error, setError] = useState(false);
  const hideFutureInvestments = useSelector(hideFutureInvestmentsSelector);
  const hideCurrentInvestments = useSelector(hideCurrentInvestmentsSelector);

  useEffect(() => {
    async function fetchData() {
      try {
        const fetchedManageInvestments = await getManageInvestments(
          planId,
          empId,
          role
        );

        setManageInvestments(fetchedManageInvestments);
      } catch (e) {
        setError(true);
      }
    }

    fetchData();
    setLoading(false);
  }, [planId, empId, role]);

  return (
    <Page name={name} loading={false} className={"manage-investments"}>
      <PageHeader name={name}>Change Investments</PageHeader>
      <PageSubheader>
        {!hideFutureInvestments &&
          !hideCurrentInvestments &&
          "Change how your account balance is allocated today or direct how future contributions should be allocated."}
        {!hideFutureInvestments &&
          hideCurrentInvestments &&
          "Direct how future contributions should be allocated."}
        {!hideCurrentInvestments &&
          hideFutureInvestments &&
          "Change how your account balance is allocated today."}
      </PageSubheader>
      <div className="row callout-wrapper">
        {!hideFutureInvestments && (
          <div className="col-md-6 col-xs-12 callout-container animate-in-slide-up">
            <Skeleton
              selectors={[]}
              isLoadingFetch={loading}
              width={535}
              height={373}
            >
              {manageInvestments?.showFutureContribution && (
                <FutureInvestmentsCard
                  url={formatLinkForRole(
                    manageInvestments.futureContributionLink,
                    role
                  )}
                  disableNewFutureContribution={
                    manageInvestments.disableNewFutureContribution
                  }
                  futureContributionsLimitText={
                    manageInvestments.futureContributionsLimitText
                  }
                  allowPendingTransactionChanges={
                    manageInvestments.pendingInvestmentDirectionTransactionCount >
                    0
                  }
                />
              )}
            </Skeleton>
          </div>
        )}
        {!hideCurrentInvestments && (
          <div className="col-md-6 col-xs-12 callout-container animate-in-slide-up">
            <Skeleton
              selectors={[]}
              isLoadingFetch={loading}
              width={535}
              height={373}
            >
              {manageInvestments?.showMoveBalances && (
                <CurrentInvestmentsCard
                  url={formatLinkForRole(
                    manageInvestments.moveBalancesLink,
                    role
                  )}
                  disableNewMoveBalances={
                    manageInvestments.disableNewMoveBalances
                  }
                  moveBalancesLimitText={
                    manageInvestments.moveBalancesLimitText
                  }
                  allowPendingTransactionChanges={
                    manageInvestments.pendingRebalTransactionCount > 0 ||
                    manageInvestments.pendingTransferTransactionCount > 0
                  }
                />
              )}
            </Skeleton>
          </div>
        )}
      </div>
    </Page>
  );
}

export default ManageInvestments;
