import React from "react";
import { DistributionPayment } from "../../../../models/distributions/distribution-payment";
import "../../distribution-scheduled/scheduled-distribution-table.scss";
import {
  buildAccountNameNodeColumn,
  buildActualDistributionColumn,
  buildDistributionDateColumn,
  buildEstimatedAmountColumn,
  buildInstallmentColumn,
  buildStatusColumn,
} from "./build-summary-schedule-distributions-columns-utils";
import AccountName from "../../../../components/account-name/account-name";
import SortableTable from "../../../../components/table/sortable-table";
import { ColumnWithClass } from "../../distribution-scheduled/build-schedule-distributions-columns-util/build-schedule-distributions-columns-utils";

type Props = {
  distributions: DistributionPayment[];
  isDistributionHistory?: boolean;
};

export default function SummaryScheduledDistributionsTable(props: Props) {
  const { distributions, isDistributionHistory } = props;

  if (distributions.length === 0 && !isDistributionHistory) {
    return <p>Currently there are no scheduled distributions.</p>;
  } else if (isDistributionHistory && distributions.length === 0) {
    return <p>It doesn't look like you have any distribution history yet.</p>;
  }

  distributions.forEach((distribution) => {
    const { accountName, accountId } = distribution;
    distribution.accountNameNode = (
      <AccountName name={accountName} id={accountId} asLink={false} />
    );
  });

  const columns: ColumnWithClass[] = setupColumns();

  function setupColumns() {
    const orderedColumns = [
      buildDistributionDateColumn(),
      buildAccountNameNodeColumn(),
      buildStatusColumn(),
      buildInstallmentColumn(),
    ];
    if (!props.isDistributionHistory) {
      orderedColumns.push(buildEstimatedAmountColumn());
    }
    orderedColumns.push(buildActualDistributionColumn());
    return orderedColumns;
  }

  return (
    <div className="scheduled-distributions-table">
      <SortableTable
        columns={columns}
        data={distributions}
        hasFooter={false}
        tableName="summary-scheduled-distributions-table"
      />
    </div>
  );
}
