import _get from "lodash/get";
import React from "react";
import { InvestmentV2 } from "../../models/investment-v2";
import { nqAccountSingleStatus } from "../../selectors/status";
import { useSelector } from "../../store/store";
import { reduceInvestments } from "../../util/investment-context-util";
import { Card, CardFooter, CardHeader } from "../card";
import { LiabilityCalcAccountBalanceDate } from "../dashboard/dashboard-account-overview/dashboard-account-overview";
import { LiabilityCalcAccountBalanceTotal } from "../dashboard/dashboard-account-overview/dashboard-account-overview-util";
import { Skeleton } from "../skeleton/skeleton";

interface Props {
  investments: InvestmentV2[];
}

export default function AccountBalanceCard(props: Props) {
  const { investments } = props;
  const { data: nqAccountData } = useSelector(
    (state) => state.nqAccountDetailsSlice
  );

  const totals = reduceInvestments(investments);
  const totalBalance = _get(totals, "endBalance.cashBalance.balance", 0);
  const hasBalance = totalBalance > 0;

  return (
    <>
      {hasBalance ? (
        <>
          <hr className="dotted" role="presentation" />
          <Card>
            <CardHeader>
              <span className="show">Your account balance</span>
              <Skeleton selectors={[nqAccountSingleStatus]} height={76}>
                {nqAccountData && (
                  <LiabilityCalcAccountBalanceTotal account={nqAccountData} />
                )}
              </Skeleton>
            </CardHeader>
            <CardFooter className="panel-footer">
              <small>
                As of:{" "}
                {nqAccountData && (
                  <LiabilityCalcAccountBalanceDate account={nqAccountData} />
                )}
              </small>
            </CardFooter>
          </Card>
        </>
      ) : null}
    </>
  );
}
