import React, { useEffect } from "react";
import { Page, PageHeader } from "src/components/page";
import _isEmpty from "lodash/isEmpty";
import { overviewBenefitStatus, useStatuses } from "../../../selectors/status";
import { fetchOverviewBenefit } from "../../../reducers/overview-benefit/overview-benefit";
import { useAppDispatch, useSelector } from "../../../store/store";
import {
  buildLinkForDBPlanStatementPage,
  buildLinkForDefinedBenefitAboutPage,
  buildLinkforLegacyOverviewBenefit,
} from "src/util/nq-participant-link-builder";

export default function OverviewBenefitsPage() {
  const { overviewBenefit } = useSelector((state) => state.overviewBenefit);
  const { planId, empId, role } = useSelector((state) => state.session);
  const { isLoadingStatus } = useStatuses([overviewBenefitStatus]);
  const dispatch = useAppDispatch();
  const location = window.location;
  const params = location.search.substr(1);
  const contractId = new URLSearchParams(params).get("contractId") || "";

  useEffect(() => {
    dispatch(fetchOverviewBenefit({ contractId, planId, empId, role }));
  }, [dispatch, contractId, planId, empId, role]);

  const links = {
    cashBalanceCurrentBenefit: buildLinkforLegacyOverviewBenefit(
      empId,
      contractId,
      `cashbalancecurrentbenefits`,
      role
    ),
    currentBenefit: buildLinkforLegacyOverviewBenefit(
      empId,
      contractId,
      `currentbenefits`,
      role
    ),
    projectedBenefit: buildLinkforLegacyOverviewBenefit(
      empId,
      contractId,
      `projectedbenefits`,
      role
    ),
    accountDetails: buildLinkforLegacyOverviewBenefit(
      empId,
      contractId,
      `cashbalanceaccountdetails`,
      role
    ),
  };

  return (
    <Page name={"overview-benefit"} loading={isLoadingStatus}>
      <PageHeader name={"overview benefit"}>Your Benefit Overview</PageHeader>
      <div className="util-margin-top-15">
        The following information is available to you for understanding your
        benefit:
      </div>
      <ul>
        {
          <div>
            <li>
              <a
                href={buildLinkForDefinedBenefitAboutPage(planId, empId, role)}
              >
                About Your Benefit
              </a>
            </li>
            <div>View information about Nonqualified plans.</div>
          </div>
        }
        {overviewBenefit.showDBCashProjectedBenefit &&
        overviewBenefit.cashBalancePlan &&
        overviewBenefit.dbShowCurrentBenefits ? (
          <div className="util-margin-top-15">
            <li>
              <a
                href={links.cashBalanceCurrentBenefit}
                target="_blank"
                rel="noopener noreferrer"
              >
                Current Benefits
              </a>
            </li>
            <div>View your current plan benefit.</div>
          </div>
        ) : (
          ""
        )}
        {overviewBenefit.showDBNonCashProjectedBenefit &&
        !overviewBenefit.cashBalancePlan &&
        overviewBenefit.dbShowCurrentBenefits ? (
          <div className="util-margin-top-15">
            <li>
              <a
                href={links.currentBenefit}
                target="_blank"
                rel="noopener noreferrer"
              >
                Current Benefits
              </a>
            </li>
            <div>View your current plan benefit.</div>
          </div>
        ) : (
          ""
        )}
        {overviewBenefit.showDBNonCashProjectedBenefit &&
        !overviewBenefit.cashBalancePlan &&
        !_isEmpty(overviewBenefit.projectedBenefits) ? (
          <div className="util-margin-top-15">
            <li>
              <a
                href={links.projectedBenefit}
                target="_blank"
                rel="noopener noreferrer"
              >
                Projected Benefits
              </a>
            </li>
            <div>View your projected plan benefit.</div>
          </div>
        ) : (
          ""
        )}
        {overviewBenefit.showDBCashProjectedBenefit &&
        overviewBenefit.cashBalancePlan &&
        overviewBenefit.dbShowCurrentBenefits ? (
          <div className="util-margin-top-15">
            <li>
              <a
                href={links.accountDetails}
                target="_blank"
                rel="noopener noreferrer"
              >
                Account Detail
              </a>
            </li>
            <div>View details about your account balance.</div>
          </div>
        ) : (
          ""
        )}
        {overviewBenefit.showStatement ? (
          <div className="util-margin-top-15">
            <li>
              <a href={buildLinkForDBPlanStatementPage(planId, empId, role)}>
                Statements
              </a>
            </li>
            <div>View your benefit statements for this plan.</div>
          </div>
        ) : (
          ""
        )}
      </ul>
      <div className="util-margin-top-15">
        The amounts listed on the website represent a measure of your benefit
        under a nonqualified benefit agreement between you and your plan
        sponsor. It is not a representation that your plan sponsor holds any
        assets for paying these benefits.
      </div>
    </Page>
  );
}
