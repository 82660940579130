import { customEnrollmentLink, enrollmentLink } from "./constants";
import { ParticipantEvent } from "../models/ParticipantEvent";

export function generateEnrollmentLink(
  event: ParticipantEvent,
  role: string
): string {
  const isSeniorManagementPenskePlan = [22286].includes(
    event.eventConfiguration.planId
  );
  if (isSeniorManagementPenskePlan) {
    return customEnrollmentLink(
      event.eventId,
      event.empId,
      event.eventConfiguration.planId
    );
  } else {
    return enrollmentLink(event.eventId, event.empId, getEnrollmentRole(role));
  }
}

function getEnrollmentRole(role: string) {
  switch (role) {
    case "MEMBER":
      return "participant";
    default:
      return role;
  }
}
