import React, { useEffect, useState } from "react";
import StatementsSVG from "../statements-quick-view-icon.svg";
import QuickQuarterView from "./quick-quarter-view";
import "./statement-quick-view.scss";

export function StatementQuickView(props) {
  const { isLoading } = props;
  const [quickViewQuarters, setQuickViewQuarters] = useState([1, 2, 3, 4]);
  const [quickViewYears, setQuickViewYears] = useState([
    2000, 2000, 2000, 2000,
  ]);

  useEffect(() => {
    calcPreviousQuarters();

    function calcPreviousQuarters() {
      const today = new Date();
      const currentQuarter = Math.floor((today.getMonth() + 3) / 3);
      const previousQuarter = currentQuarter - 1;
      const previousQuartersArray = [];
      const previousYearsArray = [];

      let yearTracker = today.getFullYear();
      let quarterToAdd = previousQuarter;
      for (let i = 0; i <= 3; i++) {
        if (quarterToAdd === 0) {
          quarterToAdd = 4;
          yearTracker = today.getFullYear() - 1;
        }
        previousQuartersArray.push(quarterToAdd);
        previousYearsArray.push(yearTracker);
        quarterToAdd--;
      }
      setQuickViewQuarters(previousQuartersArray);
      setQuickViewYears(previousYearsArray);
    }
  }, []);

  function buildDateRangeString(quarter, year) {
    const quarterTemplates = [
      "placeholder for 0",
      [`Jan 01, ${year}`, `Mar 31, ${year}`],
      [`Apr 01, ${year}`, `Jun 30, ${year}`],
      [`Jul 01, ${year}`, `Sep 30, ${year}`],
      [`Oct 1, ${year}`, `Dec 31, ${year}`],
    ];

    return quarterTemplates[quarter];
  }

  return (
    <div className="row" data-testid="quick-view-row">
      <div className="statements-quick-view">
        <div className="statements-left-content">
          <img
            src={StatementsSVG}
            alt=""
            style={{ height: "100%", width: "100%" }}
          />
        </div>

        <div className="row statements-right-content">
          <h2>Statement Quick View</h2>
          <p>View a statement from the last four quarters.</p>
          <div className="row">
            {quickViewQuarters.map((quarter, index) => (
              <QuickQuarterView
                key={quarter}
                index={index}
                quarter={quarter}
                year={quickViewYears[index]}
                dateRange={buildDateRangeString(quarter, quickViewYears[index])}
                isLoading={isLoading}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
