import React from "react";
import { Disclaimer } from "../../models/plan/plan-info-model";
import { useSelector } from "../../store/store";

type Props = {
  showOnlyShaw: boolean;
};

DisclaimersDisplay.defaultProps = {
  showOnlyShaw: true,
};

const shawDisclaimerId = 70;

function isShawDisclaimer(disclaimer: Disclaimer) {
  return disclaimer.id === shawDisclaimerId;
}

export default function DisclaimersDisplay(props: Props) {
  const { showOnlyShaw } = props;
  const { disclaimers } = useSelector((state) => state.plan.info);

  let disclaimerItems = disclaimers;
  if (showOnlyShaw) {
    disclaimerItems = disclaimerItems.filter(isShawDisclaimer);
  }

  return (
    <div id="disclaimers-display">
      {disclaimerItems.map(({ id, message }) => (
        <p key={id}>{message}</p>
      ))}
    </div>
  );
}
