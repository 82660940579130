export enum AsyncStatus {
  not_fetched = "Not Fetched",
  loading = "Loading",
  error = "Error",
  done = "Fetched",
}

export function isLoadingStatus(status: AsyncStatus) {
  return status === AsyncStatus.not_fetched || status === AsyncStatus.loading;
}

export function isOnlyLoadingStatus(status: AsyncStatus) {
  return status === AsyncStatus.loading;
}
