import { RedeferralStatus } from "../../../models/redeferral/redeferral-status";

export enum RedeferralType {
  NOT_SET,
  STREAM_OF_PAYMENT,
  INDIVIDUAL_PAYMENT,
  SHAW_WITHOUT_PENDING,
  SHAW_WITH_PENDING,
}

export function getRedeferralFlowType(
  redeferralStatus: RedeferralStatus | undefined
): RedeferralType {
  if (redeferralStatus === null || redeferralStatus === undefined) {
    return RedeferralType.NOT_SET;
  } else if (redeferralStatus.redeferralType === "STREAM_OF_PAYMENT") {
    return RedeferralType.STREAM_OF_PAYMENT;
  } else if (redeferralStatus.redeferralType === "INDIVIDUAL_PAYMENT") {
    return RedeferralType.INDIVIDUAL_PAYMENT;
  } else if (redeferralStatus.redeferralType === "SHAW_WITHOUT_PENDING") {
    return RedeferralType.SHAW_WITHOUT_PENDING;
  } else {
    return RedeferralType.SHAW_WITH_PENDING;
  }
}
