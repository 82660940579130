import { createSelector } from "@reduxjs/toolkit";
import {
  DistributionPayment,
  FutureDistributionPayment,
  PastDistributionPayment,
} from "../models/distributions/distribution-payment";
import { filterScheduleDistributionsByAccounts } from "../pages/distributions/distribution-scheduled/scheduled-distribution-util";
import { buildDistributionPayments } from "../pages/distributions/distribution-summary/scheduled-distributions-table/summary-scheduled-distributions-util";
import { accountsWithUnscheduledDistributionsSelector } from "./account-distributions";
import { generatedEstimatedDistributionsSelector } from "./estimated-distributions";
import { selectedInvestmentsSelector } from "./investments";
import { scheduledDistributionsWithAccountsSelector } from "./scheduled-distribution-events";
import { filteredScheduledElectionsSelector } from "./scheduled-elections";

export const distributionPaymentsSelector = createSelector(
  [
    scheduledDistributionsWithAccountsSelector,
    selectedInvestmentsSelector,
    filteredScheduledElectionsSelector,
  ],
  (
    distributions,
    investments,
    filteredScheduledElections
  ): DistributionPayment[] => {
    return buildDistributionPayments(
      distributions,
      investments,
      filteredScheduledElections
    );
  }
);

export const futureDistributionPaymentsSelector = createSelector(
  [
    scheduledDistributionsWithAccountsSelector,
    selectedInvestmentsSelector,
    filteredScheduledElectionsSelector,
  ],
  (
    distributions,
    investments,
    filteredScheduledElections
  ): (PastDistributionPayment | FutureDistributionPayment)[] => {
    const distributionPayments = buildDistributionPayments(
      distributions,
      investments,
      filteredScheduledElections
    );

    return distributionPayments.filter((distribution) => {
      return distribution.isFuture;
    });
  }
);

export const generatedDistributionPaymentsSelector = createSelector(
  [
    generatedEstimatedDistributionsSelector,
    selectedInvestmentsSelector,
    filteredScheduledElectionsSelector,
    accountsWithUnscheduledDistributionsSelector,
  ],
  (
    distributions,
    investments,
    filteredScheduledElections,
    accounts
  ): FutureDistributionPayment[] => {
    const generatedDistributions = filterScheduleDistributionsByAccounts(
      distributions,
      accounts
    );
    return buildDistributionPayments(
      generatedDistributions,
      investments,
      filteredScheduledElections
    );
  }
);
