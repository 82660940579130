import { OverviewBenefitDetails } from "src/models/overview-benefit/overview-benefit-details";
import { get } from "../repositories/participant";

export async function getBenefitOverviewDetails(
  planId: number,
  empId: number,
  contractId: string,
  role: string
): Promise<OverviewBenefitDetails> {
  return get(`/plans/${planId}/employees/${empId}/overview-benefit-details`, {
    role,
    contractId,
  });
}
