import React from "react";
import AccountDistributionDetails from "./account-distribution-details";

type Props = {
  showPaidOutAccounts?: boolean;
  isAccountSummary?: boolean;
};

export function AccountDistributionsTable(props: Props) {
  const { showPaidOutAccounts, isAccountSummary } = props;

  return (
    <>
      <div>
        <AccountDistributionDetails
          showPaidOutAccounts={showPaidOutAccounts}
          isAccountSummary={isAccountSummary}
        />
      </div>
    </>
  );
}
