import React from "react";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import Percent from "../../../../components/numbers/percent";
import { mapInvestmentsToAssetChart } from "../../../../util/investment-context-util";
import { calculateAssetClassTotals } from "../../../../util/investments";
import "./asset-class-chart.scss";
import ChartLabel from "./chart-label";

function AssetClassChart(props) {
  const sortedInvestments = mapInvestmentsToAssetChart(props.investments);
  const assetClasses = calculateAssetClassTotals(sortedInvestments);
  // pass props.headingLevel to avoid skipped headings for a11y
  const Heading = `h${props.headingLevel || "5"}`;

  if (assetClasses.length === 0) {
      return (<></>)
  }

  return (
    <div className="asset-class-chart" data-testid="asset-class-chart">
      <Heading className="h5">Balance by Asset Class</Heading>
      <ResponsiveContainer
        width="100%"
        height="100%"
        maxHeight={200}
        minWidth={1}
        minHeight={1}
      >
        <PieChart>
          <Pie
            data={assetClasses}
            dataKey="balanceRatio"
            nameKey="assetClass"
            animationBegin={0}
            animationDuration={1500}
          >
            {assetClasses.map((entry, index) => (
              <Cell key={index} fill={assetClasses[index].hexValue} />
            ))}
          </Pie>
          <Tooltip formatter={(value) => <Percent value={value} />} />
        </PieChart>
      </ResponsiveContainer>
      <div className="labels-wrapper">
        <div className="labels">
          {assetClasses.map((assetClass, index) => (
            <ChartLabel key={index} assetClass={assetClass} index={index} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default AssetClassChart;
