import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AsyncStatus } from "../../models/async-status";
import { PlanDetails } from "../../models/plan/plan-details";
import { getPlanDetails } from "../../services/plans";

export const fetchPlanDetails = createAsyncThunk(
  "fetch-plan-details",
  async (
    { planId, empId, role }: { planId: number; empId: number; role: string },
    thunkApi
  ) => {
    return getPlanDetails({ planId, empId, role });
  }
);

const initialState: PlanDetails = {
  redeferralOptions: "",
  restrictActiveParticipantAddress: false,
  idNum: 0,
  contractId: 0,
  planId: 0,
  planAdminGuide: false,
  maximumNumberOfReferralsAllowed: 0,
  treatFirstInstallmentsLumpSum: false,
  acceptW4: false,
  wireFeesPaidBy: "",
  planAllowsWireACH: undefined,
  restrictNumberOfRedeferrals: false,
  isFormChangeRedeferralAllowed: false,
  isTimeChangeRedeferralAllowed: false,
  status: AsyncStatus.not_fetched,
};

const planDetailsSlice = createSlice({
  name: "plan-details",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPlanDetails.pending, (state, action) => {
      return { ...state, status: AsyncStatus.loading };
    });
    builder.addCase(fetchPlanDetails.fulfilled, (state, action) => {
      return { ...state, ...action.payload, status: AsyncStatus.done };
    });
    builder.addCase(fetchPlanDetails.rejected, (state, action) => {
      return {
        ...state,
        status: AsyncStatus.error,
      };
    });
    builder.addDefaultCase((state, action) => state);
  },
});

export default planDetailsSlice.reducer;
