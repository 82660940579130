import _get from "lodash/get";
import XLSX, { ColInfo, WorkSheet } from "xlsx";
import {
  DistributionCountInfo,
  ExcelSubSheetData,
  ExcelWorksheet,
} from "../../../../models/distributions/excel/excel-worksheet";
import { downloadExcelWithRequestBody } from "../../../../repositories/participant";

export type Row = Col[];
export type Col = any;

export async function exportFile(
  planId: number,
  empId: number,
  role: string,
  accounts: number[]
) {
  await downloadExcelWithRequestBody(
    `/plan/${planId}/employee/${empId}/report/distribution-election-summary`,
    { role },
    { accountIds: accounts },
    "Distribution_election_summary"
  );
}

function mergeWSCols(
  ws: WorkSheet,
  scheduledDistributionsCountInfo: DistributionCountInfo,
  estimatedDistributionsCountInfo: DistributionCountInfo
) {
  const colHeaderSpacing = 3;
  const rowHeaderSpacing = 4;
  const scheduledColEndRange =
    scheduledDistributionsCountInfo.maxPayoutsCount + colHeaderSpacing;
  const estimatedColEndRange =
    estimatedDistributionsCountInfo.maxPayoutsCount + colHeaderSpacing;
  const scheduledRowEndRange =
    scheduledDistributionsCountInfo.accountsCount + rowHeaderSpacing;

  if (scheduledDistributionsCountInfo.accountsCount === 0) {
    ws["!merges"] = [
      {
        s: { r: 0, c: colHeaderSpacing },
        e: { r: 0, c: scheduledColEndRange },
      },
    ];
  } else {
    ws["!merges"] = [
      {
        s: { r: 0, c: colHeaderSpacing },
        e: { r: 0, c: scheduledColEndRange },
      },
      {
        s: { r: scheduledRowEndRange, c: colHeaderSpacing },
        e: { r: scheduledRowEndRange, c: estimatedColEndRange },
      },
    ];
  }
}

function getValueLength(value: any) {
  if (typeof value === "number") {
    return 10;
  }
  if (typeof value === "string") {
    return value.length;
  }
  if (value instanceof Date) {
    return 10;
  }
  return 0;
}

export function autofitColumns(worksheet: WorkSheet, rows: Row[]) {
  const columnWidth: number[] = [];
  rows.forEach((columns) => {
    if (columns) {
      columns.forEach((column, columnIndex) => {
        const currentMaxWidth = _get(columnWidth, `[${columnIndex}]`, 0);
        columnWidth[columnIndex] = Math.max(
          currentMaxWidth,
          getValueLength(column)
        );
      });
    }
  });
  worksheet["!cols"] = columnWidth.map(toColInfo);
}

function toColInfo(width: number): ColInfo {
  return { width };
}

function getDistributionPaymentsAndElectionsWS(
  data: ExcelSubSheetData
): WorkSheet {
  const distributionsAndElections: ExcelWorksheet =
    data.distributionsAndElectionsSubSheet;
  const ws = XLSX.utils.aoa_to_sheet(distributionsAndElections.data);
  autofitColumns(ws, distributionsAndElections.data);
  return ws;
}
