import _get from "lodash/get";
import React from "react";
import { Area, Line } from "recharts";
import {
  getDayOfWeek,
  getWeekNumber,
  isAfter,
  isSameDay,
  parseDate,
  subtractMonths,
} from "./date-util";

export function getResolutionFilter(start, end) {
  const startDate = parseDate(start);
  const endDate = parseDate(end);

  if (isAfter(startDate, subtractMonths(endDate, 1))) {
    return filterByDay;
  }
  if (isAfter(startDate, subtractMonths(endDate, 3))) {
    return filterByWeek(startDate, endDate);
  }
  return filterBiweek(startDate, endDate);
}

export function filterByDay() {
  return true;
}

export function filterByWeek(startDate, endDate) {
  return (item) => {
    const date = parseDate(item.date);

    if (isSameDay(startDate, date)) {
      return true;
    }

    const endDayOfWeek = getDayOfWeek(endDate);
    const currentDayOfWeek = getDayOfWeek(date);
    return endDayOfWeek === currentDayOfWeek;
  };
}

export function filterBiweek(startDate, endDate) {
  const endDayOfWeek = getDayOfWeek(endDate);
  const evenOrOddWeek = getWeekNumber() % 2;

  return (item) => {
    const date = parseDate(item.date);

    if (isSameDay(startDate, date)) {
      return true;
    }
    const weekday = getDayOfWeek(date);
    const weekNumber = getWeekNumber(date);
    return weekday === endDayOfWeek && weekNumber % 2 === evenOrOddWeek;
  };
}

export function getColor(index) {
  const colorValues = [
    "#1f77b4",
    "#ff7f0e",
    "#2ca02c",
    "#d62728",
    "#9467bd",
    "#8c564b",
    "#e377c2",
    "#7f7f7f",
    "#bcbd22",
    "#17becf",
  ];
  return colorValues[index % 10];
}

export function sumByInvestmentIdForAccount(accountId) {
  return (acc, investment) => {
    if (investment.accountId !== accountId) {
      return acc;
    }
    const { id } = investment;
    acc[id] =
      _get(acc, id, 0) + _get(investment, "endBalance.cashBalance.balance", 0);
    return acc;
  };
}

export function renderLine(dataKey, index = 0, options = { strokeWidth: 1 }) {
  return (
    <Line
      type="monotone"
      dataKey={dataKey}
      stroke={getColor(index)}
      strokeWidth={options.strokeWidth}
      activeDot={{ r: 5 }}
      dot={false}
    />
  );
}

export function renderArea(dataKey, index = 0, options = { strokeWidth: 1 }) {
  return (
    <Area
      type="linear"
      dataKey={dataKey}
      stroke={"#4b4687"}
      fillOpacity={1}
      strokeWidth={options.strokeWidth}
      fill={"url(#color0)"}
      activeDot={{ r: 5 }}
      connectNulls={false}
      dot={false}
    />
  );
}
