import _sortBy from "lodash/sortBy";
import React from "react";
import { Account } from "../../../../models/account";
import { AccountType } from "../../../../models/distributions/account-type";
import { accountActiveRedeferralFlag } from "./modified-method-comments";
import { ScheduledDistributionElection } from "../../../../models/distributions/ScheduledDistributionElection";

export function MethodCommentsWrapper(
  scheduledElections: ScheduledDistributionElection[],
  filteredAccountsByActiveRedeferral: Account[]
) {
  const sortedFilteredAccountsByActiveRedeferral = _sortBy(
    filteredAccountsByActiveRedeferral,
    "name"
  );
  return (
    <>
      {sortedFilteredAccountsByActiveRedeferral.map((account: Account) => {
        const { id } = account;
        const filteredScheduledDistributionsByAccountId =
          scheduledElections.filter(
            (scheduledDistribution) => scheduledDistribution.accountId === id
          );

        if (
          filteredScheduledDistributionsByAccountId &&
          filteredScheduledDistributionsByAccountId.length > 0
        ) {
          return (
            <React.Fragment key={account.id}>
              {filteredScheduledDistributionsByAccountId &&
                mapMethodComments(
                  filteredScheduledDistributionsByAccountId[0],
                  account
                )}
            </React.Fragment>
          );
        }
        return null;
      })}
    </>
  );
}

function mapMethodComments(
  election: ScheduledDistributionElection,
  account: Account
) {
  let notRetirementAccount = true;
  if (account.type === AccountType.RETIREMENT) {
    notRetirementAccount = false;
  }
  return (
    <div className="comments-grid">
      <span className="column-1 row-1">
        {account.name} {accountActiveRedeferralFlag(account)}
      </span>
      <span className="column-2 row-1">{account.type}</span>
      {notRetirementAccount && (
        <span className="column-3 row-1">{staticAdditionalText()}</span>
      )}
      <span className="column-4 row-1">{election.comments}</span>
    </div>
  );
}

function staticAdditionalText() {
  return (
    <>
      You have elected to re-defer this account. The payout date is effective 12
      months from the date you made the election to re-defer and is only
      applicable if you are still in service on that date.
    </>
  );
}
