import _flatten from "lodash/flatten";
import _get from "lodash/get";
import _round from "lodash/round";
import _some from "lodash/some";
import { AccountWithUnscheduledDistributions } from "../../../../models/account";
import { InstallmentFrequency } from "../../../../models/distributions/installment-frequency";
import { PaymentType } from "../../../../models/distributions/payment-type";
import {
  GeneratedScheduledDistributionEvent,
  ScheduledDistributionEvent,
} from "../../../../models/distributions/scheduled-distribution-event";
import { UnscheduledDistributionElection } from "../../../../models/distributions/unscheduled-distribution-election";
import { UnscheduledDistributionEventCategory } from "../../../../models/distributions/unscheduled-distribution-event-category";
import { InvestmentV2 } from "../../../../models/investment-v2";
import {
  addMonths,
  addWeeks,
  addYears,
  formatDateWithoutTimeZone,
} from "../../../../util/date-util";
import { filterScheduledDistributionsByAccountId } from "../../../../util/distributions-util";
import { hasEstimatedDistributions } from "../../../../util/estimated-distribution-util";
import { calculateTotalEndBalanceV2 } from "../../../../util/investments";
import { getElectionToUse } from "../scheduled-distributions-table/summary-scheduled-distributions-util";

export function needsEstimatedDistribution(
  selectedAccounts: AccountWithUnscheduledDistributions[],
  filteredScheduledDistributions: ScheduledDistributionEvent[]
): AccountWithUnscheduledDistributions[] {
  return selectedAccounts.filter((account) => {
    const filteredDistributionsByAccountId =
      filterScheduledDistributionsByAccountId(
        account.id,
        filteredScheduledDistributions
      );
    return hasEstimatedDistributions(
      [],
      account,
      filteredDistributionsByAccountId,
      true
    );
  });
}

export function determineElectionToUse(
  account: AccountWithUnscheduledDistributions,
  filteredParticipantLevelElections?: UnscheduledDistributionElection[]
) {
  if (account && account.unscheduledDistributionElections.length > 0) {
    return getElectionToUse(account.unscheduledDistributionElections);
  } else if (
    filteredParticipantLevelElections &&
    filteredParticipantLevelElections.length > 0
  ) {
    return getElectionToUse(filteredParticipantLevelElections);
  }

  return undefined;
}

export function getInstallmentData(
  account: AccountWithUnscheduledDistributions,
  investments: InvestmentV2[],
  filteredParticipantLevelElections?: UnscheduledDistributionElection[]
) {
  const election = determineElectionToUse(
    account,
    filteredParticipantLevelElections
  );

  const installmentYears = getInstallmentYears(election);
  const installmentFrequency = getInstallmentFrequency(election);
  const installmentAmount = getInstallmentAmount(
    installmentFrequency,
    installmentYears,
    investments
  );

  return {
    election,
    installmentYears,
    installmentFrequency,
    installmentAmount,
  };
}

export function paymentPerInstallment(
  totalBalance: number,
  installmentFrequency: InstallmentFrequency,
  installmentYears: number
) {
  switch (installmentFrequency) {
    case InstallmentFrequency.ANNUAL:
      return _round(totalBalance / installmentYears, 2);
    case InstallmentFrequency.SEMIANNUAL:
      return _round(totalBalance / installmentYears / 2, 2);
    case InstallmentFrequency.QUARTERLY:
      return _round(totalBalance / installmentYears / 4, 2);
    case InstallmentFrequency.MONTHLY:
      return _round(totalBalance / installmentYears / 12, 2);
    case InstallmentFrequency.WEEKLY:
      return _round(totalBalance / installmentYears / 52, 2);
    case InstallmentFrequency.BIWEEKLY:
      return _round(totalBalance / installmentYears / 52 / 2, 2);
    default:
      return _round(totalBalance / installmentYears, 2);
  }
}

export function getEstimatedDate(
  retirementDate: Date,
  increment: number,
  installmentFrequency: InstallmentFrequency
) {
  switch (installmentFrequency) {
    case InstallmentFrequency.ANNUAL:
      return addYears(retirementDate, increment);
    case InstallmentFrequency.SEMIANNUAL:
      return addMonths(retirementDate, increment * 6);
    case InstallmentFrequency.QUARTERLY:
      return addMonths(retirementDate, increment * 3);
    case InstallmentFrequency.MONTHLY:
      return addMonths(retirementDate, increment);
    case InstallmentFrequency.WEEKLY:
      return addWeeks(retirementDate, increment);
    case InstallmentFrequency.BIWEEKLY:
      return addWeeks(retirementDate, increment * (1 / 2));
    default:
      return retirementDate;
  }
}

export function getEstimatedTotalInstallments(
  installmentYears: number,
  installmentFrequency: InstallmentFrequency
) {
  switch (installmentFrequency) {
    case InstallmentFrequency.ANNUAL:
      return installmentYears;
    case InstallmentFrequency.SEMIANNUAL:
      return installmentYears * 2;
    case InstallmentFrequency.QUARTERLY:
      return installmentYears * 4;
    case InstallmentFrequency.MONTHLY:
      return installmentYears * 12;
    case InstallmentFrequency.WEEKLY:
      return installmentYears * 52;
    case InstallmentFrequency.BIWEEKLY:
      return installmentYears * 104;
    default:
      return installmentYears;
  }
}

function getPaymentType(installmentYears: number, paymentMethodId: number) {
  if (installmentYears > 1) {
    return PaymentType.INSTALLMENT;
  }
  // custom distributions should always have paymentMethodIds of > 999
  if (paymentMethodId > 999) {
    return PaymentType.CUSTOM_METHOD;
  }
  return PaymentType.LUMPSUM;
}

function getInstallmentYears(
  election: UnscheduledDistributionElection | undefined
) {
  return _get(election, "installmentYears", 1);
}

function getInstallmentFrequency(
  election: UnscheduledDistributionElection | undefined
) {
  return _get(election, "installmentFrequency", InstallmentFrequency.NOT_SET);
}

function getInstallmentAmount(
  installmentFrequency: InstallmentFrequency,
  installmentYears: number,
  investments: InvestmentV2[]
) {
  const totalBalance = calculateTotalEndBalanceV2(investments);

  return paymentPerInstallment(
    totalBalance,
    installmentFrequency,
    installmentYears
  );
}

export function filterAccountsByInvestmentFound(
  selectedAccounts: AccountWithUnscheduledDistributions[],
  investments: InvestmentV2[]
) {
  return selectedAccounts.filter((account) => {
    return _some(investments, (investment) => {
      return investment.accountId === account.id;
    });
  });
}

export function generateEstimatedDistributions(
  accountsNeedingEstimatedDistributions: AccountWithUnscheduledDistributions[],
  participantLevelElections: UnscheduledDistributionElection[],
  retirementDate: Date,
  filteredInvestments: InvestmentV2[]
): GeneratedScheduledDistributionEvent[] {
  return _flatten(
    accountsNeedingEstimatedDistributions.map((retirementAccount) => {
      let filteredParticipantLevelElections = participantLevelElections.filter(
        (participantLevelElection) => {
          return participantLevelElection.accountId === retirementAccount.id;
        }
      );

      if (filteredParticipantLevelElections.length === 0) {
        filteredParticipantLevelElections = participantLevelElections.filter(
          (participantLevelElection) => {
            return (
              participantLevelElection.eventCategory ===
              UnscheduledDistributionEventCategory.SEPARATION_FROM_SERVICE
            );
          }
        );
      }

      return generateDistributions(
        retirementAccount,
        filteredParticipantLevelElections,
        retirementDate,
        filteredInvestments
      );
    })
  );
}

export function generateDistributions(
  account: AccountWithUnscheduledDistributions,
  filteredParticipantLevelElections: UnscheduledDistributionElection[],
  retirementDate: Date,
  filteredInvestmentsByAccount: InvestmentV2[]
): GeneratedScheduledDistributionEvent[] {
  const { id, name, type } = account;

  const {
    election,
    installmentYears,
    installmentFrequency,
    installmentAmount,
  } = getInstallmentData(
    account,
    filteredInvestmentsByAccount,
    filteredParticipantLevelElections
  );

  const startDate = formatDateWithoutTimeZone(retirementDate);
  const generatedDistributions: GeneratedScheduledDistributionEvent[] = [];
  const estimatedTotalInstallments = getEstimatedTotalInstallments(
    installmentYears,
    installmentFrequency
  );

  const paymentMethodId = election?.paymentMethodId ?? 0;
  const paymentType = getPaymentType(installmentYears, paymentMethodId);
  // estimatedTotalInstallments defaults to 1
  for (let i = 0; i < estimatedTotalInstallments; i++) {
    const schedDate = getEstimatedDate(retirementDate, i, installmentFrequency);
    const estimatedDateString = formatDateWithoutTimeZone(schedDate);
    const generatedDistribution: GeneratedScheduledDistributionEvent = {
      accountName: name,
      accountId: id,
      accountType: type,
      scheduledDate: estimatedDateString,
      paymentType: paymentType,
      paymentMethodId: paymentMethodId,
      paymentMethod: election?.paymentMethod,
      installmentFrequency: installmentFrequency,
      installNum: i + 1,
      totalInstallments: estimatedTotalInstallments,
      yearsPayout: installmentYears,
      installmentAmount: installmentAmount,
      availableForRedeferral: false,

      isFuture: true,
      status: "Scheduled",
      payoutStartDate: startDate,
      showComments: true,
      color: "#4b4687",
    };

    generatedDistributions.push(generatedDistribution);
  }

  return generatedDistributions;
}
