import React from "react";
import { Modal } from "../../components/modal/modal";

export function VestedInfoModal() {
  return (
    <Modal
      title="What is vested balance?"
      linkText={<span className="sr-only">What is vested balance?</span>}
    >
      <div id="vested-info" className="vested-info">
        <p>
          The amounts listed represent a measure of your benefits under a
          nonqualified benefit agreement between you and your plan sponsor. It
          is not a representation that your plan sponsor holds any assets for
          paying these benefits. Vested Balances shown represent the portion of
          your benefit account(s) available for distribution, upon a qualifying
          distribution event.
        </p>
      </div>
    </Modal>
  );
}
