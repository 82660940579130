import React from "react";

export interface Props {
  value: string;
}

export default function String(props: Props) {
  const { value } = props;
  return <div>{value}</div>;
}

export const valOrDefault = (input: string | undefined | null, defaultVal: string = "N/a") => {
  const isBlank = (input: string) => /^ *$/.test(input);

  if (input === undefined || input === null || isBlank(input)) {
    return defaultVal;
  }
  return input;
}
