import React from "react";
import DateDisplay from "../../components/date-display/date-display";
import "./account-snapshot.scss";
import StatementsOverview from "./overview/statements-overview";
import ContributionBreakdown from "./panels/contribution-breakdown/contribution-breakdown";
import TotalAccountBalanceChart from "./panels/total-account-balance-chart";
import TotalInvestmentAllocationsByRisk from "./panels/total-investment-allocations-by-risk";
import TotalsInThisPeriodTable from "./panels/totals-in-this-period-table";
import { useSelector } from "../../store/store";
import {
  hideAccountBalanceChartSelector,
  hideContributionBreakdownSelector,
  hideInvestmentAllocationsSelector,
  hideTotalsInThisPeriodSelector,
} from "../../selectors/plan-info";

function AccountSnapshotSection() {
  const hideContributionBreakdown = useSelector(
    hideContributionBreakdownSelector
  );
  const hideInvestmentAllocations = useSelector(
    hideInvestmentAllocationsSelector
  );
  const hideTotalsInThisPeriod = useSelector(hideTotalsInThisPeriodSelector);
  const hideAccountBalanceChart = useSelector(hideAccountBalanceChartSelector);

  return (
    <section className="overview-section">
      <div className="row">
        <div className={"col-md-12"}>
          <h2 className="h1">Overview - all accounts</h2>
          <DateDisplay />
          <hr role="presentation" />
        </div>
      </div>
      <div className="row">
        <StatementsOverview />
      </div>
      <div className="row">
        {!hideContributionBreakdown && (
          <div className={"col-md-12"}>
            <ContributionBreakdown />
          </div>
        )}
        {!hideInvestmentAllocations && (
          <div className={"col-md-12"}>
            <TotalInvestmentAllocationsByRisk />
          </div>
        )}
        {!hideTotalsInThisPeriod && (
          <div className={"col-md-6"}>
            <TotalsInThisPeriodTable />
          </div>
        )}
        {!hideAccountBalanceChart && (
          <div className={"col-md-6"}>
            <TotalAccountBalanceChart />
          </div>
        )}
      </div>
    </section>
  );
}

export default AccountSnapshotSection;
