import React from "react";
import { Page, PageHeader } from "../../../components/page";
import ScheduledDistributionsWrapper from "./scheduled-distributions-wrapper";

export default function DistributionScheduledPage() {
  return (
    <Page name="scheduled distributions page" printable loading={false}>
      <PageHeader name="scheduled distributions">
        Scheduled Distributions
      </PageHeader>
      <ScheduledDistributionsWrapper />
    </Page>
  );
}
