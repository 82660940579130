import React from "react";
import { Page, PageHeader, PageSubheader } from "../../../components/page";
import AccountAllocationTable from "./account-allocation-table";

export default function AccountAllocations() {
  return (
    <Page name="account-allocations" printable loading={false}>
      <PageHeader name="account-allocations">Account allocations</PageHeader>
      <PageSubheader>
        When contributions are made, the amount contributed will be allocated
        according to the allocations we have on file for the earnings period of
        the contribution type. Only account(s) eligible for future contributions
        will be listed below.
        <strong>
          &nbsp;If allowed by the plan, these allocations can only be changed
          during your plan's annual enrollment event.
        </strong>
      </PageSubheader>
      <AccountAllocationTable />
      <p>
        The amounts listed on the website represent a measure of your benefits
        under a nonqualified benefit agreement between you and your plan
        sponsor. It is not a representation that your plan sponsor holds any
        assets for paying these benefits.
      </p>
    </Page>
  );
}
