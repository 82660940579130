import React from "react";
import { useToggle } from "react-use";
import classnames from "classnames";

export interface Props {
  text: string;
}

export const Collapse = (props: Props) => {
  const { text } = props;
  const [isOn, toggleIsOn] = useToggle(false);
  const showMoreLess = isOn ? "Show less" : "Show more";
  const iconClass = classnames(["fa", isOn ? "fa-angle-up" : "fa-angle-down"]);

  return (
    <>
      {collapsedText(text, isOn)}
      <div className="text-center">
        <button
          className="btn btn-link btn-link-inline text-muted"
          onClick={toggleIsOn}
        >
          <small>
            {showMoreLess} <i className={iconClass} aria-hidden="true"></i>
          </small>
        </button>
      </div>
    </>
  );
};

function collapsedText(text: string, show: boolean) {
  return show ? (
    <div className="util-padding-top-20">
      <div dangerouslySetInnerHTML={{ __html: text }} />
    </div>
  ) : (
    <></>
  );
}
