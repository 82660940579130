import React from "react";
import { Link } from "react-router-dom";
import { Page, PageHeader } from "../../../components/page";
import { Skeleton } from "../../../components/skeleton/skeleton";
import {
  planInfoStatusSelector,
  sessionStatusSelector,
} from "../../../selectors/status";
import { useSelector } from "../../../store/store";
import {
  buildLegacyLinkForRole,
  buildPensionContextLink,
} from "../../../util/nq-participant-link-builder";
import "./research.scss";

function Research() {
  const { empId, role } = useSelector((state) => state.session);
  const contractId = useSelector((state) => state.plan.info.contractId);

  const otherLinks = {
    styles: buildLegacyLinkForRole(role, "/investment/research/styles.faces", {
      underPlan: true,
    }),
    news: buildLegacyLinkForRole(role, "/investment/research/news.faces", {
      underPlan: true,
    }),
  };

  const participantLinks = {
    styles: buildPensionContextLink(
      empId,
      contractId,
      "investmentResearchStyles"
    ),
    prices: buildPensionContextLink(
      empId,
      contractId,
      "investmentResearchDailyPrices"
    ),
    news: buildPensionContextLink(empId, contractId, "investmentResearchNews"),
  };

  const links = role !== "MEMBER" ? otherLinks : participantLinks;
  const loadSelectors = [sessionStatusSelector, planInfoStatusSelector];

  return (
    <Page name="investments-research" loading={false}>
      <PageHeader name="investments-research">Investment Research</PageHeader>

      <ul className="investment-research-list">
        <li className="double">
          <Skeleton selectors={loadSelectors} height={18} width={200}>
            <Link to={"/investments/performance"}>Investment Performance</Link>
          </Skeleton>
          <p>Historical performance of investment options.</p>
        </li>
        <li className="double">
          <Skeleton selectors={loadSelectors} height={18} width={200}>
            <a href={links.styles}>Investment Styles</a>
          </Skeleton>
          <p>Morningstar information of investment options.</p>
        </li>
        <li className="double">
          <Skeleton selectors={loadSelectors} height={18} width={200}>
            <a href={links.news}>Investments News &amp; Updates</a>
          </Skeleton>
          <p>News and changes to investment options offered by the plan.</p>
        </li>
        <li className="double">
          <Skeleton selectors={loadSelectors} height={18} width={200}>
            <a
              href="https://www.principalfunds.com/investor/docs/otoh/otoh-current-issue.pdf"
              target="_blank"
              rel="noopener noreferrer"
              title="Weekly Economic Commentary (This link opens in a new window)"
            >
              Market &amp; Economic News
            </a>
          </Skeleton>
          <p>
            A weekly economic commentary provided by Principal Global Investors.
          </p>
        </li>
      </ul>
    </Page>
  );
}

export default Research;
