import React, { useState } from "react";
import {
  BeneficiaryState,
  startEditing,
} from "../../../reducers/beneficiary-reducer";
import { useAppDispatch, useSelector } from "../../../store/store";
import { BeneficiariesPanel } from "./panel-body/beneficiaries-panel";
import BeneficiaryAlertDisplay from "./alert-display/beneficiary-alert-display";
import BeneficiariesDisplayNavigation from "./beneficiaries-display-navigation";
import { BeneficiaryDisclaimerText } from "../../../models/beneficiaries/beneficiary-text";
import {
  hideContingentBeneficiariesSelector,
  hidePrimaryBeneficiariesSelector,
} from "../../../selectors/plan-info";

export function BeneficiariesDisplay() {
  const dispatch = useAppDispatch();
  const [showErrors, setShowErrors] = useState(false);
  const { editing, canEditBeneficiaries } = useSelector<BeneficiaryState>(
    (state) => state.beneficiaries
  );
  const hidePrimaryBeneficiairies = useSelector(
    hidePrimaryBeneficiariesSelector
  );
  const hideContingentBeneficiaires = useSelector(
    hideContingentBeneficiariesSelector
  );

  return (
    <>
      {!canEditBeneficiaries && (
        <strong>
          <p className={"alert alert-info"}>
            {BeneficiaryDisclaimerText.ONLINE_NOT_SUPPORTED}
          </p>
        </strong>
      )}
      {!editing && canEditBeneficiaries && (
        <div className={"text-center"}>
          {(!hidePrimaryBeneficiairies || !hideContingentBeneficiaires) && (
            <button
              type="button"
              id="add-edit-bene"
              className="btn btn-primary js-add-edit-bene-btn"
              onClick={() => {
                dispatch(startEditing());
              }}
              data-gtm="call-to-action"
            >
              Add or Edit Beneficiaries
            </button>
          )}
        </div>
      )}
      {editing ? (
        <></>
      ) : (
        <h2 className={"h3"}>Current beneficiary designation</h2>
      )}
      <div className="row">
        {!hidePrimaryBeneficiairies && <BeneficiariesPanel isPrimary />}
        {!hideContingentBeneficiaires && (
          <BeneficiariesPanel isPrimary={false} />
        )}
      </div>
      {editing && (
        <>
          {<BeneficiaryAlertDisplay showErrors={showErrors} />}
          <div className="row">
            <BeneficiariesDisplayNavigation
              showErrors={showErrors}
              setShowErrors={setShowErrors}
            ></BeneficiariesDisplayNavigation>
          </div>
        </>
      )}
    </>
  );
}
