import React from "react";
import { useSelector } from "../../../../store/store";
import _get from "lodash/get";

export default function PhantomStockDisclaimer() {
  const { disclaimers } = useSelector((state) => state.plan.info);
  const hasDisclaimer = disclaimers && disclaimers.length > 0;
  if (hasDisclaimer) {
    const disclaimer = _get(disclaimers, "[0].message");
    return <p>{disclaimer}</p>;
  }
  return null;
}
