import { createSelector } from "@reduxjs/toolkit";
import { AccountAllocation } from "../models/account-allocation";
import { RootState, useSelector } from "../store/store";

const allocationsSelector = (state: RootState) => state.allocations.allocations;
const accountIdsSelector = (state: RootState) => state.accounts.selectedIds;
const allocationsForAccountSelector = createSelector(
  [allocationsSelector, accountIdsSelector],
  (allocations, selectedIds) =>
    allocations.find(
      (allocation: AccountAllocation) => allocation.id === selectedIds[0]
    )
);

export function useAllocations() {
  const allocationsForAccount = useSelector(allocationsForAccountSelector);
  return {
    allocationsForAccount,
  };
}
