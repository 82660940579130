import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { viewPlanningCenterDocument } from "src/services/view-planning-center-document";
import { AsyncStatus } from "../../models/async-status";

export const fetchViewPlanningCenterDocument = createAsyncThunk(
  "fetch-view-planning-center-document",
  async (
    {
      planId,
      empId,
      extension,
      resourceId,
      role,
    }: {
      planId: number;
      empId: number;
      extension: string;
      resourceId: number;
      role: string;
    },
    thunkApi
  ) => {
    return viewPlanningCenterDocument(
      planId,
      empId,
      extension,
      resourceId,
      role
    );
  }
);

type State = {
  status: AsyncStatus;
};

const initialState: State = {
  status: AsyncStatus.not_fetched,
};

const viewPlanningCenterDocumentSlice = createSlice({
  name: "view-planning-center-document",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchViewPlanningCenterDocument.pending,
      (state, action) => {
        return { ...state, status: AsyncStatus.loading };
      }
    );
    builder.addCase(
      fetchViewPlanningCenterDocument.fulfilled,
      (state, action) => {
        return { ...state, ...action.payload, status: AsyncStatus.done };
      }
    );
    builder.addCase(
      fetchViewPlanningCenterDocument.rejected,
      (state, action) => {
        return {
          ...state,
          status: AsyncStatus.error,
        };
      }
    );
    builder.addDefaultCase((state, action) => state);
  },
});

export default viewPlanningCenterDocumentSlice.reducer;
