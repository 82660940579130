import React, { CSSProperties } from "react";
import LoadingSkeleton from "react-loading-skeleton";
import { StatusSelector, useStatuses } from "../../selectors/status";

type Props = {
  selectors: StatusSelector[];
  children: React.ReactNode;
  count?: number;
  width?: number;
  height?: number;
  isLoadingFetch?: boolean;
  style?: CSSProperties;
};

export function Skeleton(props: Props) {
  const { isLoadingFetch } = props;
  const { isLoadingStatus } = useStatuses(props.selectors);

  if (isLoadingStatus || isLoadingFetch) {
    return (
      <LoadingSkeleton
        count={props.count}
        width={props.width}
        height={props.height}
        style={props.style}
      />
    );
  }

  return <>{props.children}</>;
}
