import { PTCTrackerResource } from "../models/distributions/ptc-tracker";
import { ScheduledDistributionEvent } from "../models/distributions/scheduled-distribution-event";
import { ScheduledDistributionElection } from "../models/distributions/ScheduledDistributionElection";
import { UnscheduledDistributionElection } from "../models/distributions/unscheduled-distribution-election";
import { UnscheduledDistributionEvent } from "../models/distributions/unscheduled-distribution-event";
import { get } from "../repositories/participant";
import { ForecastDistributionsResource } from "../models/distributions/forecast-distribution";

type Args = {
  planId: number;
  empId: number;
  role: string;
  accountId?: number;
  eventName?: string;
  forecastDate?: string;
  selectedYear?: string;
  rateOfReturn?: number;
};

export async function getParticipantLevelElections({
  planId,
  empId,
  role,
}: Args): Promise<UnscheduledDistributionElection[]> {
  return get(
    `/employees/${empId}/plans/${planId}/distributions/unscheduled/participant`,
    {
      role,
    }
  );
}

export async function getAvailableUnscheduledDistributionEvents({
  planId,
  empId,
  role,
}: Args): Promise<UnscheduledDistributionEvent[]> {
  return get(
    `/employees/${empId}/plans/${planId}/distributions/unscheduled/available`,
    {
      role,
    }
  );
}

export async function getAvailableEventsForAllProvisions({
  planId,
  empId,
  role,
}: Args): Promise<UnscheduledDistributionEvent[]> {
  return get(
    `/employees/${empId}/plans/${planId}/distributions/unscheduled/availableEventsForAllProvisions`,
    {
      role,
    }
  );
}

export async function getAccountLevelElections({
  planId,
  empId,
  role,
}: Args): Promise<UnscheduledDistributionElection[]> {
  return get(
    `/employees/${empId}/plans/${planId}/distributions/unscheduled/accounts`,
    {
      role,
    }
  );
}

export async function getScheduledDistributions({
  planId,
  empId,
  role,
}: Args): Promise<ScheduledDistributionEvent[]> {
  return get(`/v2/plans/${planId}/employees/${empId}/distributions/scheduled`, {
    role,
  });
}

export async function getScheduledDistributionElections({
  planId,
  empId,
  role,
}: Args): Promise<ScheduledDistributionElection[]> {
  return get(
    `/employees/${empId}/plans/${planId}/distributions/scheduled/elections`,
    {
      role,
    }
  );
}

export async function getPTCTracker({
  planId,
  empId,
  role,
}: Args): Promise<PTCTrackerResource> {
  return get(`/employees/${empId}/plans/${planId}/ptctracker`, {
    role,
  });
}

export async function getForecastDistributionsResource({
  planId,
  empId,
  role,
  eventName,
  forecastDate,
  rateOfReturn,
}: Args): Promise<ForecastDistributionsResource> {
  return get(
    `/employees/${empId}/plans/${planId}/distributions/forecastDistributions/resource`,
    {
      role,
      eventName,
      forecastDate,
      rateOfReturn,
    }
  );
}

export async function getForecastDistributionsResourceFromGraphClick({
  planId,
  empId,
  role,
  eventName,
  forecastDate,
  selectedYear,
  rateOfReturn,
}: Args): Promise<ForecastDistributionsResource> {
  return get(
    `/employees/${empId}/plans/${planId}/distributions/forecastDistributions/resource/graph`,
    {
      role,
      eventName,
      forecastDate,
      selectedYear,
      rateOfReturn,
    }
  );
}
