import React from "react";
import { PlanCode } from "../../../models/plan/plan-info-model";
import { useSelector } from "../../../store/store";

export default function ContributionsSummaryAnchorInfo() {
  const planCode = useSelector((state) => state.plan.info.planCode);
  const is457b = planCode.toLowerCase() === PlanCode.type457b;

  return (
    <>
      <dl id="[1]">
        <dt>
          <span className="sr-only">Footnote&nbsp;</span>
          <sup>[1]</sup>
        </dt>
        {is457b && (
          <dd>
            The information provided in this chart is based on the most recent
            enrollment information we have on file.
          </dd>
        )}
        {!is457b && (
          <dd>
            Deferral election(s) may only be changed on an annual basis. The
            information provided in this chart is based on the most recent
            enrollment information we have on file.
          </dd>
        )}
      </dl>
      <dl id="[2]">
        <dt>
          <span className="sr-only">Footnote&nbsp;</span>
          <sup>[2]</sup>
        </dt>
        <dd>
          Contributions since inception include only contributions made into the
          plan since Principal Life Insurance Company began recordkeeping the
          Plan.
        </dd>
      </dl>
      <p>
        The amounts listed on the website represent a measure of your benefits
        under a nonqualified benefit agreement between you and your plan
        sponsor. It is not a representation that your plan sponsor holds any
        assets for paying these benefits.
      </p>
    </>
  );
}
