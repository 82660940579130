import React from "react";
import { Skeleton } from "../../../components/skeleton/skeleton";
import { Account } from "../../../models/account";
import { AccountCategory } from "../../../models/account-category";
import { planInfoStatusSelector } from "../../../selectors/status";
import { useSelector } from "../../../store/store";

export default function Pre409ADisclaimer() {
  const { employerContactPhoneNumber } = useSelector(
    (state) => state.plan.info
  );
  const accounts = useSelector((state) => state.accounts.available);
  if (hasPre409A(accounts)) {
    return (
      <p>
        The deferral rules on Pre 409A accounts are governed by your Plan
        Document. Please contact us at{" "}
        <Skeleton
          selectors={[planInfoStatusSelector]}
          height={20}
          width={120}
          count={1}
        >
          {employerContactPhoneNumber}
        </Skeleton>{" "}
        to discuss the options.
      </p>
    );
  }
  return null;
}

function hasPre409A(accounts: Account[]) {
  return accounts.some(
    (account) =>
      account.category409A === AccountCategory.ACCOUNT_CATEGORY_PRE_409A
  );
}
