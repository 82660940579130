import React from "react";
import { InvestmentV2 } from "../../../models/investment-v2";
import { InvestmentOptionProfileCard } from "./investment-option-profile-card";
import { TransferRestrictionsCard } from "./transfer-restrictions-card";

type InvestmentDataProps = {
  investment: InvestmentV2;
};

export function IndividualInvestmentDisplay(props: InvestmentDataProps) {
  const { name, assetClass, links, hexValue } = props.investment;
  const { investmentOptionProfile, transferRestrictionsLink }: any = links;

  return (
    <>
      <h2 className="h3">
        <span className="text-bold">Full Name: </span>
        {name}
      </h2>
      <span data-testid="asset-class">
        Asset Class:
        <span
          className="hex-display"
          style={{ backgroundColor: `#${hexValue}` }}
        />
        {assetClass}
      </span>
      <p className="h3">
        Choose one of the options below to view additional investment details:
      </p>
      <div className="row">
        {investmentOptionProfile && (
          <div className="option-div col-md-6 animate-in-slide-up">
            <InvestmentOptionProfileCard url={investmentOptionProfile} />
          </div>
        )}
        {transferRestrictionsLink && (
          <div className="option-div col-md-6 animate-in-slide-up">
            <TransferRestrictionsCard url={transferRestrictionsLink} />
          </div>
        )}
      </div>
    </>
  );
}
