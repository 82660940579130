import { get } from "../repositories/participant";
import { AccountAtAGlance } from "../models/accounts-at-a-glance/account-at-a-glance";

export async function getAccountsAtAGlance(
  planId: number,
  empId: number,
  role: string
): Promise<AccountAtAGlance[]> {
  return get(
    `/employees/${empId}/plans/${planId}/contributions/accounts-at-a-glance`,
    { role }
  );
}
