import React from "react";
import { BeneficiaryType } from "../../../../models/beneficiaries/beneficiary";
import BeneficiaryModal, {
  ModalAction,
} from "../../add-beneficiary/beneficiary-modal";
import BeneficiaryPanelBody from "./beneficiary-panel-body";

type Props = {
  isPrimary: boolean;
};

export function BeneficiariesPanel(props: Props) {
  const { isPrimary } = props;
  const beneType = isPrimary
    ? BeneficiaryType.PRIMARY
    : BeneficiaryType.CONTINGENT;

  return (
    <div className="col-xl-6">
      <div className={"well"}>
        <div className={"row"}>
          <div className={"col-xs-6 text-wrap text-wrap-mobile"}>
            {<Header isPrimary={isPrimary} />}
          </div>
          <div className={"col-xs-6 text-right text-wrap"}>
            <BeneficiaryModal
              modalAction={ModalAction.ADD}
              beneficiaryType={beneType}
            />
          </div>
        </div>
        <BeneficiaryPanelBody isPrimary={isPrimary} />
      </div>
    </div>
  );
}

type HeaderProps = {
  isPrimary: boolean;
};

function Header(props: HeaderProps) {
  const { isPrimary } = props;
  const headerSpan = isPrimary ? (
    <span>Primary</span>
  ) : (
    <span>
      Contingent <small className="text-muted">(secondary)</small>
    </span>
  );

  return (
    <>
      <h2 className="h4 text-nowrap">{headerSpan}</h2>
    </>
  );
}
