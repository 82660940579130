import React from "react";
import {
  ContributionRates as Rates,
  ContributionType,
} from "../../../models/contributions";
import { useSelector } from "../../../store/store";
import {
  buildDeferredCompPlannerURL,
  buildPensionContextLink,
} from "../../../util/nq-participant-link-builder";
import Balance from "../../numbers/balance";
import Percent from "../../numbers/percent";
import compWidget from "./deferred-comp-widget.jpg";

export function DashboardContributionOverview() {
  const { planId, empId, role } = useSelector((state) => state.session);
  const { contractId } = useSelector((state) => state.plan.info);
  const contributionRates = useSelector(
    (state) => state.contributions.contributionRates.data
  );
  const contributionSummaryBySourceLink = buildPensionContextLink(
    empId,
    contractId,
    "contributionSummaryBySource",
    role
  );
  const deferredCompPlannerUrl = buildDeferredCompPlannerURL(planId);

  return (
    <>
      <div
        className="col-md-6 col-sm-12"
        data-testid="dashboard-contribution-overview"
      >
        <h3 className="h3 util-margin-vert-0 util-margin-bottom-15 text-midnight">
          Your contributions
        </h3>
        <ContributionRates rates={contributionRates} />
        <a
          href={contributionSummaryBySourceLink}
          className="btn btn-default util-margin-top-10"
        >
          View contributions
          <i className="fa fa-arrow-right" aria-hidden="true" />
        </a>
      </div>
      <div className="col-md-6 col-sm-12">
        <p className="text-bold">Will you meet your income goal?</p>
        <p>
          See if deferred comp plan benefits provide enough income to last
          between the age you plan to retire and the year other benefits begin.{" "}
        </p>
        <div className="text-center">
          <img src={compWidget} alt="Comp planner widget" />
          <a
            href={deferredCompPlannerUrl}
            className="btn btn-default util-margin-top-10"
          >
            Plan your future
            <i className="fa fa-arrow-right" aria-hidden="true" />
          </a>
        </div>
      </div>
    </>
  );
}

type ContributionRatesProps = {
  rates: Rates[];
};

export function ContributionRates(props: ContributionRatesProps) {
  const { rates } = props;
  return (
    <>
      {rates.map((rate: Rates, index: string | number | null | undefined) => {
        return (
          <div key={index} data-testid={`contribution-${index}`}>
            <span className="text-bold">
              <ContributionRate rate={rate} />
            </span>
            &nbsp;per payment of {rate.contributionTypeName}
          </div>
        );
      })}
    </>
  );
}

type ContributionRateProps = {
  rate: Rates;
};

function ContributionRate(props: ContributionRateProps) {
  const { contributionAmount, contributionElectionType, otherText } =
    props.rate;
  if (
    contributionAmount !== undefined &&
    contributionElectionType === ContributionType.PERCENT
  ) {
    return <Percent value={contributionAmount} isPercentage={true} />;
  }
  if (
    contributionAmount !== undefined &&
    contributionElectionType === ContributionType.DOLLAR
  ) {
    return <Balance value={contributionAmount} showDollar={true} />;
  }
  if (contributionElectionType === ContributionType.OTHER) {
    return <span>{otherText}</span>;
  }
  return null;
}
