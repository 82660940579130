import { ErrorMessage } from "@hookform/error-message";
import classNames from "classnames";
import React from "react";
import { RegisterOptions } from "react-hook-form";
import { BeneficiaryModalValidationText } from "../../models/beneficiaries/beneficiary-text";

type Props = {
  register: (options: RegisterOptions) => React.Ref<any>;
  errors: any;
  relationship: string | undefined;
};

export default function RelationshipTypeSelect(props: Props) {
  const { register, errors, relationship } = props;

  const relationshipTypes = [
    "Child/Stepchild",
    "Spouse",
    "Parent",
    "Sibling",
    "Grandchild",
    "Other relative",
    "Trust/Estate",
    "Institution/Charitable Org",
    "Other",
  ];

  const hasOldRelationshipType =
    relationship && !relationshipTypes.includes(relationship);

  const relationshipsToUse = hasOldRelationshipType
    ? [relationship, ...relationshipTypes]
    : [...relationshipTypes];

  const hasErrors = errors.relationship;
  const className = classNames("form-group", {
    "has-error": hasErrors,
  });
  return (
    <div className={className} data-testid="relationship-select">
      <label htmlFor="relationship">Relationship</label>
      <select
        id="relationship"
        name="relationship"
        data-testid="relationship"
        className={"form-control"}
        aria-invalid={errors.relationship ? "true" : "false"}
        ref={register({
          required: BeneficiaryModalValidationText.SELECT_A_RELATIONSHIP,
        })}
      >
        <option value=""> Select relationship...</option>
        {relationshipsToUse.map((relationship) => (
          <option
            key={relationship}
            value={relationship}
            data-testid={`${relationship}-option`}
          >
            {relationship}
          </option>
        ))}
      </select>
      <ErrorMessage errors={errors} name="relationship" />
    </div>
  );
}
