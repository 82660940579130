import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/cjs/Button";
import BSModal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { Page, PageHeader } from "../../../../components/page";
import { AlertLevel } from "../../../../models/alert";
import { AsyncStatus } from "../../../../models/async-status";
import {
  ContributionRates,
  ContributionType,
} from "../../../../models/contributions";
import {
  resetUpdateStatus,
  updateContributionRates,
} from "../../../../reducers/contributions/contribution-rates";
import { ContributionRoutes } from "../../../../routes/routes";
import {
  contributionRatePostStatus,
  useStatuses,
} from "../../../../selectors/status";
import { useAppDispatch, useSelector } from "../../../../store/store";
import { formatDateWithoutTimeZone } from "../../../../util/date-util";
import Alert from "../../../errors/alert";
import ManageContributionsCalculationsBox from "../manage-contributions-calculations-box";
import "./manage-contributions-review.scss";
import { fetchPendingContributionRates } from "../../../../reducers/contributions/pending-contribution-rates";
import ContributionsReviewSubmitButton from "../contributions-review-submit-button";

export default function ManageContributionsReview() {
  const dispatch = useAppDispatch();
  const { contributionEffectiveDate } = useSelector((state) => state.plan.info);
  const { hasError } = useStatuses([contributionRatePostStatus]);
  const [showModal, setShowModal] = useState(false);
  const { planId, empId, role } = useSelector((state) => state.session);
  const {
    data: contributionRates,
    allFields,
    updateStatus,
  } = useSelector((state) => state.contributions.contributionRates);

  let dateFormatted = "";
  if (contributionEffectiveDate) {
    dateFormatted = formatDateWithoutTimeZone(
      contributionEffectiveDate,
      "MM/dd/yyyy"
    );
  }

  useEffect(() => {
    const hasSaved = updateStatus === AsyncStatus.done;
    setShowModal(hasSaved);
    dispatch(fetchPendingContributionRates({ planId, empId, role }));
  }, [dispatch, updateStatus, planId, empId, role]);

  function onSubmit() {
    const contributionRatesWithEffectiveDate: ContributionRates[] =
      contributionRates.map((contributionRate: ContributionRates) => {
        return {
          ...contributionRate,
          effectiveDate: contributionEffectiveDate,
        };
      });
    dispatch(
      updateContributionRates({
        planId,
        empId,
        role,
        data: contributionRatesWithEffectiveDate,
      })
    );
  }

  function isEmpty(e: any) {
    return e === undefined || e === null;
  }

  const postErrorMessage =
    "There was an issue updating your information. Please try again later.";
  return (
    <Page name={"manage-contributions-review"} printable loading={false}>
      <PageHeader name={"manage-contributions-review-header"}>
        Contributions - Review & Submit
      </PageHeader>
      <Alert
        alertLevel={AlertLevel.DANGER}
        showAlert={hasError}
        message={postErrorMessage}
      />
      <div className={"row manage-contributions-review"}>
        <div className={"col-md-6 updated-row"}>
          <div className={"contributions"}>
            {showModal && (
              <ConfirmationModal
                effectiveDate={contributionEffectiveDate}
                showModal={showModal}
              />
            )}
            <h3>You are requesting to update the following contributions:</h3>
            <hr className="dotted" role="presentation" />
            {contributionRates.map((rate: ContributionRates) => {
              const {
                contributionAmount,
                contributionTypeName,
                contributionTypeId,
                contributionElectionType: electionType,
                otherText,
              } = rate;
              const value = !isEmpty(contributionAmount)
                ? contributionAmount
                : otherText;

              const prefix =
                electionType === ContributionType.DOLLAR ? "$" : undefined;
              const suffix =
                electionType === ContributionType.PERCENT ? "%" : undefined;
              return (
                <div className={"contribution"} key={contributionTypeId}>
                  <div className={"contributionName"}>
                    {contributionTypeName}
                  </div>
                  <div className={"contributionValue"}>
                    {prefix}
                    {value}
                    {suffix}
                  </div>
                </div>
              );
            })}
            <hr className="dotted" role="presentation" />
          </div>
          <div className={"actions-row"}>
            <Link to={ContributionRoutes.ManageContributions}>
              <Button
                className="btn-default"
                onClick={() => dispatch(resetUpdateStatus())}
              >
                <i className="fa fa-arrow-left" /> Back
              </Button>
            </Link>
            <div className="pull-right">
              <ContributionsReviewSubmitButton
                isReview={true}
                onSubmit={onSubmit}
              />
            </div>
            <br />
            <br />
            <p className="effective-date pull-right">
              These changes will become effective {dateFormatted}.
            </p>
          </div>
        </div>
        <div className={"col-md-6"}>
          <ManageContributionsCalculationsBox
            isReview={true}
            allFields={allFields}
          />
        </div>
      </div>
    </Page>
  );
}

type beneModalProps = {
  showModal: boolean;
  effectiveDate: string;
};

export function ConfirmationModal(props: beneModalProps) {
  const dateFormatted = formatDateWithoutTimeZone(
    props.effectiveDate,
    "MM/dd/yyyy"
  );
  const dispatch = useAppDispatch();
  const { showModal } = props;
  return (
    <BSModal
      show={showModal}
      size={"lg"}
      backdropClassName="modal-backdrop"
      animation={false}
      backdrop="static"
      centered
      className="beneficiaries-confirm-modal"
    >
      <BSModal.Header>
        <BSModal.Title as={"h2"}>Contributions updated</BSModal.Title>
      </BSModal.Header>
      <BSModal.Body>
        <p className="updated-contributions-success">
          Congratulations! You have successfully updated your contributions.
        </p>
        <p className="effective-date">
          These changes will become effective <b>{dateFormatted}</b>.
        </p>
      </BSModal.Body>
      <BSModal.Footer>
        <Link to={ContributionRoutes.Summary}>
          <Button
            className="btn-default"
            onClick={() => dispatch(resetUpdateStatus())}
          >
            Take me back to contributions <i className="fa fa-arrow-right" />
          </Button>
        </Link>
      </BSModal.Footer>
    </BSModal>
  );
}
