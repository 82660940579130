import React, { ReactNode } from "react";

interface Props {
  className: string;
  children: ReactNode;
  onClick?: any;
  disabled?: boolean;
  type?: "button" | "submit" | "reset" | undefined;
}

function Button(props: Props) {
  return (
    <button
      disabled={props.disabled}
      {...props}
      className={`btn ${props.className}`}
      onClick={props.onClick}
      type={props.type}
    >
      {props.children}
    </button>
  );
}

export default Button;
