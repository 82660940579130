import { createSlice } from "@reduxjs/toolkit";

type State = {
  projectedRetirementDate: string;
};

const initialState: State = {
  projectedRetirementDate: new Date().toISOString(),
};

const projectedRetirementDateSlice = createSlice({
  name: "projectedRetirementDate",
  initialState,
  reducers: {
    setProjectedRetirementDate: (
      state,
      action: { payload: string; type: string }
    ) => ({
      ...state,
      projectedRetirementDate: action.payload,
    }),
  },
});

export const { setProjectedRetirementDate } =
  projectedRetirementDateSlice.actions;
export default projectedRetirementDateSlice.reducer;
