import { combineReducers } from "redux";
import scheduledDistributionsForRedeferral from "../redeferral/scheduledDistributionForRedeferral";
import marketCloseDates from "../redeferral/marketCloseDates";
import redeferralInstructions from "../redeferral/redeferral-instructions";
import redeferralInstructionDtos from "../redeferral/redeferral-instruction-dto";

const redeferralReducer = combineReducers({
  scheduledDistributionsForRedeferral,
  marketCloseDates,
  redeferralInstructions,
  redeferralInstructionDtos,
});

export default redeferralReducer;
