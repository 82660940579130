import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AsyncStatus } from "../models/async-status";
import { InvestmentV2 } from "../models/investment-v2";
import {
  getAllInvestments,
  GetAllInvestmentsArgs,
} from "../services/investment-data";

export const fetchInvestments = createAsyncThunk(
  "fetch-investments",
  async (args: GetAllInvestmentsArgs) => {
    return getAllInvestments(args);
  }
);

type InvestmentsState = {
  data: InvestmentV2[];
  useLiabilitiesRest: boolean;
  status: AsyncStatus;
};
const initialState: InvestmentsState = {
  data: [],
  useLiabilitiesRest: false,
  status: AsyncStatus.not_fetched,
};

const sessionSlice = createSlice({
  name: "investments",
  initialState,
  reducers: {
    setUseLiabilities: (state, action: { payload: boolean }) => {
      const investments = state.data;
      investments.forEach(inv => inv.useLiabilitiesRest = action.payload);
      return { ...state, useLiabilitiesRest: action.payload, data: investments};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchInvestments.pending, (state, action) => {
      return { ...state, status: AsyncStatus.loading };
    });
    builder.addCase(fetchInvestments.fulfilled, (state, action) => {
      const investments: InvestmentV2[] = action.payload;
      investments.forEach(inv => inv.useLiabilitiesRest = state.useLiabilitiesRest);
      return { ...state, data: investments, status: AsyncStatus.done };
    });
    builder.addCase(fetchInvestments.rejected, (state, action) => {
      return { ...state, status: AsyncStatus.error };
    });
    builder.addDefaultCase((state, action) => state);
  },
});

export const { setUseLiabilities } = sessionSlice.actions;
export default sessionSlice.reducer;
