import React, { useEffect, useState } from "react";
import { AsyncStatus } from "../../models/async-status";
import { ParticipantEvent } from "../../models/ParticipantEvent";
import { getOpenEvents } from "../../services/enrollment";
import { useSelector } from "../../store/store";

type EnrollmentContextType = {
  openEvents: ParticipantEvent[];
  isLoadingStatus: boolean;
  hasError: boolean;
};

export const EnrollmentContext = React.createContext<EnrollmentContextType>(
  {} as EnrollmentContextType
);

type Props = {
  children?: React.ReactNode;
};

export function EnrollmentContextProvider({ children }: Props) {
  const { status, empId, planId } = useSelector((state) => state.session);
  const [openEvents, setOpenEvents] = useState<ParticipantEvent[]>([]);
  const [hasError, setHasError] = useState<boolean>(false);
  const [isLoadingStatus, setIsLoadingStatus] = useState<boolean>(true);

  function filterEvents(openEvents: ParticipantEvent[]) {
    return openEvents.filter(
      (event) =>
        event.eventConfiguration.isEventOngoing &&
        !event.electionSummary.isParticipantEnrollmentCompleted
    );
  }

  useEffect(() => {
    if (status === AsyncStatus.done) {
      fetchOpenEvents();
    }

    async function fetchOpenEvents() {
      try {
        const data = await getOpenEvents(empId, planId);
        const openEvents = filterEvents(data);
        setOpenEvents(openEvents);
        setIsLoadingStatus(false);
      } catch (e) {
        setHasError(true);
      }
    }
  }, [planId, empId, status]);

  return (
    <EnrollmentContext.Provider
      value={{
        openEvents,
        isLoadingStatus,
        hasError,
      }}
    >
      {children}
    </EnrollmentContext.Provider>
  );
}
