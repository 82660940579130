import React, { Dispatch, SetStateAction } from "react";
import {
  hasValidAllocations,
  hasZeroPercentAllocations,
  participantHasMultipleOnlinePlans,
  validateBeneficiaryTypes,
} from "../../../components/beneficiary/beneficiary-util";
import { OverviewRoutes } from "../../../routes/routes";
import {
  BeneficiaryState,
  cancelEdit,
} from "../../../reducers/beneficiary-reducer";
import { useAppDispatch, useSelector } from "../../../store/store";
import { useHistory } from "react-router-dom";

type Props = {
  showErrors: boolean;
  setShowErrors: Dispatch<SetStateAction<boolean>>;
};
export default function BeneficiariesDisplayNavigation(props: Props) {
  const { beneficiaries, onlineBeneficiaryPlans } =
    useSelector<BeneficiaryState>((state) => state.beneficiaries);

  const invalidAllocations: boolean = !hasValidAllocations(beneficiaries);
  const invalidBeneficiaryTypes: boolean =
    !validateBeneficiaryTypes(beneficiaries);
  const zeroPercentAllocations: boolean =
    hasZeroPercentAllocations(beneficiaries);
  const hasErrors =
    invalidAllocations || invalidBeneficiaryTypes || zeroPercentAllocations;

  const { setShowErrors } = props;
  const dispatch = useAppDispatch();
  const { planId } = useSelector((state: { session: any }) => state.session);
  const history = useHistory();
  const hasMultipleOnlinePlans: boolean = participantHasMultipleOnlinePlans(
    planId,
    onlineBeneficiaryPlans
  );

  const url = hasMultipleOnlinePlans
    ? OverviewRoutes.BeneficiaryPlanSelection
    : OverviewRoutes.BeneficiaryReview;
  const continueButtonText = hasMultipleOnlinePlans
    ? "Continue to Plan Selection"
    : "Review";

  function continueToNextPage() {
    setShowErrors(true);
    if (!hasErrors) {
      history.push(url);
    }
  }

  return (
    <div className="col-md-12 util-margin-bottom-10">
      <button
        className="btn btn-primary pull-right cta-text util-margin-left-10"
        onClick={continueToNextPage}
      >
        {continueButtonText}
      </button>
      <button
        onClick={() => {
          dispatch(cancelEdit());
        }}
        data-testid={"beneficiary-cancel-button"}
        className={"btn btn-link pull-right"}
      >
        Cancel
      </button>
    </div>
  );
}
