import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ProjectionList } from "src/models/enrollment-tools/projection-list";
import { calculateEstimatedBenefit } from "src/services/estimated-benefit-calculation";
import { AsyncStatus } from "../../models/async-status";

export const fetchEstimatedCalculation = createAsyncThunk(
  "fetch-estimated-calculation",
  async (
    {
      planId,
      empId,
      base64EstimatedProjection,
      role,
    }: {
      planId: number;
      empId: number;
      base64EstimatedProjection: string;
      role: string;
    },
    thunkApi
  ) => {
    return calculateEstimatedBenefit(
      planId,
      empId,
      base64EstimatedProjection,
      role
    );
  }
);

type State = {
  projectionList: ProjectionList[];
  retirement: Boolean;
  status: AsyncStatus;
};

const initialState: State = {
  status: AsyncStatus.not_fetched,
  projectionList: [],
  retirement: false,
};

const estimatedCalculationSlice = createSlice({
  name: "estimated-calculation",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchEstimatedCalculation.pending, (state, action) => {
      return { ...state, status: AsyncStatus.loading };
    });
    builder.addCase(fetchEstimatedCalculation.fulfilled, (state, action) => {
      return { ...state, ...action.payload, status: AsyncStatus.done };
    });
    builder.addCase(fetchEstimatedCalculation.rejected, (state, action) => {
      return {
        ...state,
        status: AsyncStatus.error,
      };
    });
    builder.addDefaultCase((state, action) => state);
  },
});

export default estimatedCalculationSlice.reducer;
