import {
  EmployeeContext,
  EmployeeContextByRole,
} from "../models/employee-context";
import * as participantRepo from "../repositories/participant";

export async function getContext(
  contractId: string,
  empId: string
): Promise<EmployeeContext> {
  return participantRepo.get(
    `/context/contractId/${contractId}/employees/${empId}`
  );
}

export async function getContextByEnterpriseId(
  role: string = "MEMBER"
): Promise<EmployeeContextByRole> {
  return participantRepo.get(`/context`, { role });
}
