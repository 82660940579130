import { downloadExcel } from "../repositories/participant";

export async function getForecastDistributionsReport(
  planId: number,
  empId: number,
  role: string,
  eventName: string,
  forecastDate: string,
  rateOfReturn: number
): Promise<any> {
  return downloadExcel(
    `/plan/${planId}/employee/${empId}/report/forecast-distributions`,
    { eventName, forecastDate, rateOfReturn, role },
    "Distribution Forecasting Summary"
  );
}

export async function getScheduledDistributionsReport(
  planId: number,
  empId: number,
  role: string
): Promise<any> {
  return downloadExcel(
    `/plan/${planId}/employee/${empId}/report/scheduled-distributions`,
    { role },
    "Scheduled Distribution Summary"
  );
}
