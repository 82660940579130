import {
  buildTrsDownloadUrl,
  callClientToClientTRSStatements,
} from "../repositories/participant";
import { TrsStatementsResource } from "../models/trs-statements";

export function buildTrsStatementPdfDownloadUrl(
  planId: number,
  empId: number,
  documentInstanceId: string,
  role: string
): string {
  return decodeURI(
    buildTrsDownloadUrl(
      `/plans/${planId}/employees/${empId}/document/${documentInstanceId}/downloadTrsStatementPdf`,
      { role }
    )
  );
}

export async function getTrsStatements(
  contractId: string,
  empId: number,
  authToken: string,
  isPilotOrTest: boolean,
  isAdvisorOrEmployer: boolean
): Promise<TrsStatementsResource> {
  return callClientToClientTRSStatements(
    contractId,
    empId,
    authToken,
    isPilotOrTest,
    isAdvisorOrEmployer
  );
}
