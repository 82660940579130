import _round from "lodash/round";
import React from "react";

export function getRoundedValue(value: number | undefined, precision: number) {
  if (value === undefined) {
    return value;
  }

  return _round(Math.abs(value), precision).toLocaleString("en-US", {
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  });
}

type Props = {
  value?: number;
  precision?: number;
};

export default function FixedDecimal(props: Props): JSX.Element {
  const { value, precision = 2 } = props;
  if (!value || value === 0) {
    return <>0.00</>;
  }

  const roundedValue = getRoundedValue(value, precision);

  if (value < 0) {
    return <>-{roundedValue}</>;
  }
  return <>{roundedValue}</>;
}
