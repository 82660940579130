import React, { useState } from "react";
import ForecastingGraph from "../../../components/chart/forecasting-graph";
import { getRoundedValue } from "../../../components/numbers/fixed-decimal";
import { Skeleton } from "../../../components/skeleton/skeleton";
import {
  ForecastDistribution,
  ForecastDistributionForGraph,
} from "../../../models/distributions/forecast-distribution";
import { useInvestments } from "../../../selectors/investments";
import { forecastDistributionsResourceStatus } from "../../../selectors/status";
import {
  addDays,
  formatDateWithoutTimeZone,
  isAfter,
  isSameDay,
  parseDate,
  parseISOStringIntoDate,
} from "../../../util/date-util";
import { getDistributionTotalCurrentBalance } from "../../../util/distributions-util";
import { TabChangeFunction } from "../distribution-summary/distribution-details";
import ForecastingBarGraph from "../../../components/chart/forecasting-bar-graph";
import "./disctribution-forecasting.scss";

export interface Props {
  forecastDistributions: ForecastDistribution[];
  forecastDistributionsForGraph: ForecastDistributionForGraph[];
  calculateFunction: Function;
  changeTabs: TabChangeFunction;
}

export function DistributionForecastingGraph(props: Props) {
  const {
    forecastDistributions,
    forecastDistributionsForGraph,
    calculateFunction,
  } = props;
  const { filteredInvestments } = useInvestments();
  const totalBalance: number =
    getDistributionTotalCurrentBalance(filteredInvestments);
  const aggregatedDistributions: BalancePoint[] = [];
  const dates: string[] = [];

  const filteredForecastDistributions = forecastDistributions.filter(
    (distribution) =>
      isAfter(new Date(distribution.payoutDate), new Date()) ||
      isSameDay(new Date(distribution.payoutDate), new Date())
  );

  function sortDistributionsByDate(
    aggregatedDistributions: BalancePoint[]
  ): BalancePoint[] {
    // using quick-sort algorithm
    const length = aggregatedDistributions.length - 1;
    let index = 0;

    if (aggregatedDistributions.length < 2) {
      //base case
      return aggregatedDistributions;
    }
    const pivot = aggregatedDistributions[length];
    const left = []; // left-hand side array
    const right = []; // right-hand side array

    while (index < length) {
      // compare and push
      if (aggregatedDistributions[index].date < pivot.date) {
        left.push(aggregatedDistributions[index]);
      } else {
        right.push(aggregatedDistributions[index]);
      }
      index++;
    }
    return [
      ...sortDistributionsByDate(left),
      pivot,
      ...sortDistributionsByDate(right),
    ];
  }

  function createDistributionsDataPoints(): BalancePoint[] {
    filteredForecastDistributions.forEach((distribution) => {
      const parsedDate = parseDate(distribution.payoutDate);

      if (
        !dates.includes(formatDateWithoutTimeZone(parsedDate, "MM/dd/yyyy"))
      ) {
        aggregatedDistributions.push({
          date: parsedDate,
          "Total Balance": distribution.estimatedTotalBalanceOfAllAccounts,
        });
      }
      dates.push(formatDateWithoutTimeZone(parsedDate, "MM/dd/yyyy"));
    });

    const sortedDistributions = sortDistributionsByDate(
      aggregatedDistributions
    );
    return addDummyDataPoints(sortedDistributions);
  }

  function addDummyDataPoints(distributions: BalancePoint[]): BalancePoint[] {
    // only add this if earliest date is not today, so graph starts from today
    if (distributions.length !== 0) {
      if (
        formatDateWithoutTimeZone(distributions[0].date, "MM/dd/yyyy") !==
        formatDateWithoutTimeZone(new Date(), "MM/dd/yyyy")
      ) {
        distributions.unshift({
          date: parseDate(new Date()),
          "Total Balance": totalBalance,
        });
      }

      // Add dummy data point for day after last distribution so graph shows the last distributions payouts.
      // If all accounts are paid out, then graph will show total balance going to 0
      distributions.push({
        date: parseDate(
          addDays(distributions[distributions.length - 1].date, 1)
        ),
        "Total Balance": getTotalBalanceForLastDataPoint(
          parseDate(distributions[distributions.length - 1].date)
        ),
      });
    }
    return distributions;
  }

  function getTotalBalanceForLastDataPoint(lastDistributionDate: Date): number {
    let totalPayouts = 0;
    let totalAccountBalance = 0;
    forecastDistributions.forEach((distribution) => {
      if (
        formatDateWithoutTimeZone(distribution.payoutDate, "MM/dd/yyyy") ===
        formatDateWithoutTimeZone(lastDistributionDate, "MM/dd/yyyy")
      ) {
        totalPayouts += distribution.payoutAmount;
        totalAccountBalance = distribution.estimatedTotalBalanceOfAllAccounts;
      }
    });
    return totalAccountBalance - totalPayouts < 0
      ? 0
      : totalAccountBalance - totalPayouts;
  }

  return (
    <>
      <div>
        <h2>Estimated Distribution</h2>
        <p>
          Use the graph below to see your distributions over time. Clicking on
          the graph will update the tables below to show distributions occurring
          within that selected year.
        </p>
      </div>
      <Skeleton selectors={[forecastDistributionsResourceStatus]} height={300}>
        <Chart
          aggregatedDistributions={createDistributionsDataPoints()}
          forecastDistributionsForGraph={forecastDistributionsForGraph}
          calculateFunction={calculateFunction}
        />
      </Skeleton>
    </>
  );
}

type BalancePoint = { date: Date; "Total Balance": number };
type ChartProps = {
  aggregatedDistributions: BalancePoint[];
  forecastDistributionsForGraph: ForecastDistributionForGraph[];
  calculateFunction: Function;
};

function Chart(props: ChartProps) {
  const {
    aggregatedDistributions,
    forecastDistributionsForGraph,
    calculateFunction,
  } = props;
  const [graph, setGraph] = useState("Balance");
  const isBalanceGraphSelected = graph === "Balance";
  const isDistributionsGraphSelected = graph !== "Balance";

  function toggleGraph() {
    if (graph === "Balance") {
      setGraph("Distributions");
    } else {
      setGraph("Balance");
    }
  }

  return (
    <>
      {isBalanceGraphSelected && (
        <ForecastingGraph
          data={aggregatedDistributions}
          formatTooltip={(value: any, name: string) => [
            `$${getRoundedValue(value, 2)}`,
            name,
          ]}
          formatDate={(date: string) =>
            formatDateWithoutTimeZone(date, "MMM yyyy")
          }
          calculateFunction={calculateFunction}
        />
      )}
      {isDistributionsGraphSelected && (
        <ForecastingBarGraph
          forecastDistributionsForGraph={forecastDistributionsForGraph}
          formatTooltip={(value: any, name: string) => [
            `$${getRoundedValue(value, 2)}`,
            name,
          ]}
          calculateFunction={calculateFunction}
        />
      )}
      <br />
      <div style={{ maxWidth: "400px", marginLeft: "265px" }}>
        <div className="pds-segmentedControl">
          <input
            id="segmentedControl-balance"
            name="segmentedControl-balance"
            type="radio"
            checked={isBalanceGraphSelected}
            data-gtm="filter"
            data-gtm-label="first"
            onChange={() => toggleGraph()}
          />
          <label htmlFor="segmentedControl-balance">Balance</label>
          <input
            id="segmentedControl-distributions"
            name="segmentedControl-distributions"
            type="radio"
            checked={isDistributionsGraphSelected}
            data-gtm="filter"
            data-gtm-label="second"
            onChange={() => toggleGraph()}
          />
          <label htmlFor="segmentedControl-distributions">Distributions</label>
        </div>
      </div>
    </>
  );
}
