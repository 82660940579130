import React from "react";

type Props = {
  min?: number;
  max?: number;
  increment?: number;
};

export default function ContributionInputRange(props: Props) {
  const { min, max, increment } = props;
  const outputText = getOutPutText(min, max, increment);
  return <p className="italic">{outputText}</p>;
}

export function getOutPutText(
  min: number | undefined,
  max: number | undefined,
  increment: number | undefined
) {
  const minText = isNumber(min) && min !== -1 ? `Minimum value of ${min} ` : "";
  const maxText = isNumber(max) && max !== -1 ? `Maximum value of ${max} ` : "";
  const incrementText = increment ? `in increments of ${increment}` : "";
  const undefinedMaxText =
    !isNumber(max) || max === -1 ? " No max defined." : "";

  if (min !== undefined && min !== -1 && max !== undefined && max !== -1) {
    return `Value must be in between ${min} - ${max} ` + incrementText + ".";
  }

  return minText + maxText + incrementText + "." + undefinedMaxText;
}

export function isNumber(value: number | undefined) {
  return typeof value === "number";
}
