import _some from "lodash/some";
import {
  Account,
  AccountWithUnscheduledDistributions,
} from "../models/account";
import { ScheduledDistributionEvent } from "../models/distributions/scheduled-distribution-event";
import { ScheduledDistributionElection } from "../models/distributions/ScheduledDistributionElection";
import { UnscheduledDistributionElection } from "../models/distributions/unscheduled-distribution-election";
import { UnscheduledDistributionEvent } from "../models/distributions/unscheduled-distribution-event";
import { InvestmentV2 } from "../models/investment-v2";
import { PayoutStatus } from "../models/payout-status";
import {
  aggregateByInvestmentId,
  filterEmptyInvestments,
  flatten,
} from "./investment-context-util";
import {
  calculateTotalEndBalanceV2,
  calculateTotalVestedBalanceV2,
} from "./investments";
import { UnscheduledDistributionEventCategory } from "../models/distributions/unscheduled-distribution-event-category";
import { PlanCode } from "../models/plan/plan-info-model";

export function filterUnscheduledDistributionsByAccountId(
  accountId: number,
  unscheduledDistributions: UnscheduledDistributionElection[]
): UnscheduledDistributionElection[] {
  return unscheduledDistributions.filter(
    (unscheduledDistribution) => unscheduledDistribution.accountId === accountId
  );
}

export function filterScheduledDistributionsByAccountId(
  accountId: number,
  scheduledDistributions: ScheduledDistributionEvent[]
): ScheduledDistributionEvent[] {
  return scheduledDistributions.filter(
    (scheduledDistribution) => scheduledDistribution.accountId === accountId
  );
}

export function filterScheduledElectionsByAccountIds(
  accountIds: number[],
  scheduledElections: ScheduledDistributionElection[]
): ScheduledDistributionElection[] {
  return scheduledElections.filter((scheduledElection) =>
    accountIds.includes(scheduledElection.accountId)
  );
}

export function filterDistributionsByPayoutStatus(accounts: Account[]) {
  return accounts.filter(
    (account) =>
      account.payoutStatus !== PayoutStatus.COMPLETELY_PAID_OUT &&
      account.payoutStatus !== PayoutStatus.PAID_OUT_HAS_BALANCE
  );
}

export function addUnscheduledDistributionsElectionsToAccounts(
  accounts: Account[],
  unscheduledDistributions: UnscheduledDistributionElection[]
): AccountWithUnscheduledDistributions[] {
  return accounts.map((account) => {
    const { id } = account;
    return {
      ...account,
      unscheduledDistributionElections:
        filterUnscheduledDistributionsByAccountId(id, unscheduledDistributions),
    };
  });
}

export function getDistributionTotalCurrentBalance(
  filteredInvestments: InvestmentV2[]
) {
  const investmentsWithBalance: InvestmentV2[] =
    filterEmptyInvestments(filteredInvestments);

  const aggregatedInvestments: InvestmentV2[] = flatten(
    aggregateByInvestmentId(investmentsWithBalance)
  );
  return calculateTotalEndBalanceV2(aggregatedInvestments);
}

export function getDistributionTotalVestedBalance(
  filteredInvestments: InvestmentV2[]
) {
  const investmentsWithBalance: InvestmentV2[] =
    filterEmptyInvestments(filteredInvestments);

  const aggregatedInvestments: InvestmentV2[] = flatten(
    aggregateByInvestmentId(investmentsWithBalance)
  );
  return calculateTotalVestedBalanceV2(aggregatedInvestments);
}

export function getPlanMessage(planCode: string) {
  if (planCode.toLowerCase() === PlanCode.type457b) {
    return "In the event you experience any of the qualifying distribution events listed below your vested balance will be distributed according to the election.";
  }
  if (planCode === "457(f)" || planCode === "457(F)" || planCode === "LTIP") {
    return "Below are your scheduled distribution election(s), including your qualifying distribution election(s). If you separate from service prior to your vested date, your applicable balances will be forfeited.";
  }
  return null;
}

export function filterElectionByProvisionAndDefaultElections(
  elections: UnscheduledDistributionElection[],
  provisionVersion: number
): UnscheduledDistributionElection[] {
  return elections.filter(
    (election) =>
      election.provisionVersion === provisionVersion || election.defaultElection
  );
}

export function filterUnavailableElections(
  elections: UnscheduledDistributionElection[],
  eventsAvailableOnPlan: UnscheduledDistributionEvent[]
): UnscheduledDistributionElection[] {
  return elections.filter((election) => {
    const isSeparationFromServiceEvent =
      election.eventCategory ===
      UnscheduledDistributionEventCategory.SEPARATION_FROM_SERVICE;
    if (isSeparationFromServiceEvent) {
      return eventsAvailableOnPlan.find(
        (availableElection) =>
          availableElection.eventCategory ===
          UnscheduledDistributionEventCategory.SEPARATION_FROM_SERVICE
      );
    }
    return eventsAvailableOnPlan.find(
      (availableElection) => availableElection.id === election.eventId
    );
  });
}

export function filterEventsByAccountProvisionVersion(
  accountProvisionVersion: number,
  eventsOnPlan: UnscheduledDistributionEvent[]
): UnscheduledDistributionEvent[] {
  return eventsOnPlan.filter((event) => {
    return event.provisionVersion === accountProvisionVersion;
  });
}

export function shouldShowEstimatedDistribution(
  elections: UnscheduledDistributionElection[]
): boolean {
  return _some(elections, (election) =>
    [5, 12, 2, 1, 9, 10, 14, 13].includes(election.eventId)
  );
}
