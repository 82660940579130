import { AsyncStatus } from "../async-status";
import { PlanFlags } from "./plan-flags";

export type Disclaimer = {
  id: number;
  message: string;
};

export type PlanInfoModel = {
  status: AsyncStatus;
  planName: string;
  contractId: string;
  participantName: string;
  employerContactPhoneNumber: string;
  streamOfPayment: boolean;
  supportsShawModel: boolean;
  supportOnlineRedeferralDuringEventOnly: boolean;
  allowContinuousEnrollment: boolean;
  planCode: string;
  disclaimers: Disclaimer[];
  contributionEffectiveDate: string;
  contributionLimit?: number;
  allowDistributionChanges: boolean;
  planFlags: PlanFlags;
};

export enum PlanCode {
  type457b = "457(b)",
}
