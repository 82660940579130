import {
  Account,
  AccountWithScheduledDistribution,
} from "../../../models/account";
import { InvestmentV2 } from "../../../models/investment-v2";
import _get from "lodash/get";
import _orderBy from "lodash/orderBy";
import _take from "lodash/take";

export function getAccountsWithBalance(
  accountWithScheduledDistributions: AccountWithScheduledDistribution[],
  investmentsByAccount: InvestmentV2[]
): AccountWithScheduledDistribution[] {
  return accountWithScheduledDistributions.map((account) => {
    const investment = investmentsByAccount.find(
      (investment) => investment.accountId === account.id
    );
    const total = _get(investment, "endBalance.cashBalance.balance", 0);
    const vested = _get(investment, "endBalance.cashBalance.vestedBalance", 0);
    return { ...account, total, vested };
  });
}

export function sortAndFilterAccounts(
  mappedAccounts: Account[]
): AccountWithScheduledDistribution[] {
  const orderByTotal = _orderBy(
    mappedAccounts,
    ["total", (account) => sortByPayoutStatus(account)],
    ["desc", "asc"]
  );
  return _take(orderByTotal, 3);
}

function sortByPayoutStatus(account: Account): number {
  const order = {
    NOT_IN_PAYOUT: 1,
    IN_PAYOUT: 2,
    PAID_OUT_HAS_BALANCE: 3,
    COMPLETELY_PAID_OUT: 4,
  };

  return order[account.payoutStatus];
}
