import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AsyncStatus } from "../models/async-status";
import { FeatureFlagDetails } from "../models/feature-flag-details";
import { getFlagDetails } from "../services/flag-service";

export const fetchFeatureFlags = createAsyncThunk(
  "fetch-feature-flags",
  async () => {
    return getFlagDetails();
  }
);

export type State = {
  featureFlagDetails: FeatureFlagDetails;
  status: AsyncStatus;
};

export type Action = {
  payload: boolean;
  type: string;
};

const initialState: State = {
  featureFlagDetails: {
    flags: [],
    allFeatures: ["rustled", "distForecasting", "userActivityLogging"],
    enabledFeatures: [],
    disabledFeatures: ["rustled", "distForecasting", "userActivityLogging"],
  },
  status: AsyncStatus.not_fetched,
};

const featureFlagReducer = (flagName: string, state: State, action: Action) => {
  const featureFlagDetails = state.featureFlagDetails;
  if (action.payload) {
    if (!featureFlagDetails.enabledFeatures.includes(flagName)) {
      featureFlagDetails.enabledFeatures.push(flagName);
    }
    const index = featureFlagDetails.disabledFeatures.indexOf(flagName);
    if (index > -1) {
      featureFlagDetails.disabledFeatures.splice(index, 1);
    }
  } else {
    if (!featureFlagDetails.disabledFeatures.includes(flagName)) {
      featureFlagDetails.disabledFeatures.push(flagName);
    }
    const index = featureFlagDetails.enabledFeatures.indexOf(flagName);
    if (index > -1) {
      featureFlagDetails.enabledFeatures.splice(index, 1);
    }
  }
};

const featureFlagSlice = createSlice({
  name: "featureFlags",
  initialState,
  reducers: {
    setIsRustled: (state, action: Action) => {
      featureFlagReducer("rustled", state, action);
    },
    setIsDistributionForecasting: (state, action: Action) => {
      featureFlagReducer("distForecasting", state, action);
    },
    setIsUserActivityLoggingEnabled: (state, action: Action) => {
      featureFlagReducer("userActivityLogging", state, action);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFeatureFlags.pending, (state, action) => {
      return { ...state, status: AsyncStatus.loading };
    });
    builder.addCase(
      fetchFeatureFlags.fulfilled,
      (state, action: { payload: FeatureFlagDetails; type: string }) => {
        const featureFlagDetails = action.payload;

        return {
          ...state,
          featureFlagDetails: featureFlagDetails,
          status: AsyncStatus.done,
        };
      }
    );
    builder.addCase(fetchFeatureFlags.rejected, (state, action) => {
      return { ...state, status: AsyncStatus.error };
    });
    builder.addDefaultCase((state, action) => state);
  },
});

export const {
  setIsRustled,
  setIsDistributionForecasting,
  setIsUserActivityLoggingEnabled,
} = featureFlagSlice.actions;
export default featureFlagSlice.reducer;
