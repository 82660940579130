import _drop from "lodash/drop";
import React from "react";

CustomizedAxisTick.defaultProps = {
  width: 50,
  maxChars: 10,
  fontSize: 12,
  lineHeight: 14,
  fill: "#333",
};

export default function CustomizedAxisTick(props) {
  const { x, y, payload, width, lineHeight, fontSize, fill } = props;

  const split = payload.value.split(" ");
  const chunks = [split[0], _drop(split).join(" ")];
  const tspans = chunks.map((s, i) => (
    <tspan key={`span-key-${i}`} x={0} y={lineHeight} dy={i * lineHeight}>
      {s}
    </tspan>
  ));
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        width={width}
        height="auto"
        textAnchor="middle"
        fontSize={fontSize}
        fill={fill}
      >
        {tspans}
      </text>
    </g>
  );
}
