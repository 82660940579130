export interface Message {
  header: string;
  subHeader: string;
  description: string;
  longText: string;
  messageAction: MessageAction;
  messageName: MessageName;
  eventEndDate: string;
}

export interface MessageAction {
  name: string;
  url: string;
}

export enum MessageName {
  PLAN_EVENT = "PLAN_EVENT",
  ENROLLMENT = "ENROLLMENT",
  DEFERRAL_CHANGE = "DEFERRAL_CHANGE",
  DEFERRAL_PENDING = "DEFERRAL_PENDING",
  REDEFERRAL = "REDEFERRAL",
  BENEFICIARY = "BENEFICIARY",
  GLOBAL = "GLOBAL",
  PLAN_MESSAGE = "PLAN_MESSAGE",
  PARTICIPANT = "PARTICIPANT",
}
