export interface UiLogBody {
  message: string;
  url: string | null | undefined;
  event: string | null | undefined;
  emp: string | null | undefined;
  plan: string | null | undefined;
  level: LogLevel | null | undefined;
}

export enum LogLevel {
  ERROR = "error",
  DEBUG = "debug",
  TRACE = "trace",
  WARN = "warn",
  INFO = "info",
}
