import { html, isServer, nothing } from 'lit';
import { property, state } from 'lit/decorators.js';
import { pdsCustomElement as customElement } from '../../decorators/pds-custom-element';
import { PdsElement } from '../PdsElement';
import styles from './secondary-navigation-level-two.scss?inline';
import '../heading/heading';
import '../secondary-navigation-link/secondary-navigation-link';
import '../list/list';
import '../list-item/list-item';
import '../accordion/accordion';
import { required } from '../../decorators/required';

/**
 * @summary This component generates the level two navigation within the secondary navigation, and generates a heading over slotted list contents at desktop and either a accordion or a link (depending on props) at mobile.
 *
 * @slot default Required: A list of pds-secondary-navigation-link items
 */
@customElement('pds-secondary-navigation-level-two', {
  category: 'component',
  type: 'component',
  state: 'stable',
  styles,
})
export class PdsSecondaryNavigationLevelTwo extends PdsElement {
  protected override firstUpdated() {
    super.firstUpdated();
    this.setWindowResizeHandler();
    this.determineActivePage();
  }

  async updated() {
    // Waiting for updateComplete to ensure that the slot has been rendered
    await this.updateComplete;
    if (typeof this.activePageCallback === 'function') {
      this.activePageCallback();
    }
  }

  /**
   * The text of the top tier item (whether a parent or link). This is a **required** property.
   */
  @required
  @property({ type: String })
  text: string = '';

  /**
   * If the top tier item is a link, the href for it
   */
  @property({ reflect: true })
  href: string;

  /**
   * Set to true for visual representation of the user's current page
   * If not set, component logic will determine the value by matching the window location's href to the component's href property
   */
  @property()
  activePage: boolean = false;

  /**
   * @optional
   * For instances when SecondaryNavigationLevelTwo is used as a link, this specifies the target to open the linked document
   */
  @property()
  target?: '_self' | '_blank' | '_parent' | '_top';

  /**
   * @optional
   * For instances when SecondaryNavigationLevelTwo is used as a link, this specifies information about a linked document
   * Automatically set to 'noopener noreferrer' when target is '_blank'
   */
  @property()
  rel?: string;

  /**
   * - **default** stacks to collapsed view at md viewport
   * - **faster** stacks to collapsed view one breakpoint smaller than default
   * - **slower** stacks to collapsed view one breakpoint larger than default
   */
  @property()
  break: 'faster' | 'slower' | 'default' = 'default';

  /**
   * Checks if it's a desktop view
   * @internal
   */
  @state()
  isDesktopView: boolean = true;

  /**
   * Function to determine if this is the active page
   * This function looks at the url of each link item
   * and determines if the current URL matches the nav item URL
   * This is the default behavior of the activePageCallback prop
   */
  determineActivePage(): void {
    // Handle when this component is just a single link
    if (this.href) {
      if (window.location.href === this.href) {
        this.activePage = true;
      }
      // bail because we know this is a single link and don't need to continue
      return;
    }

    // Not a single link, but a list of links
    // Loop through links and determine if href matches the current url
    const links = this.querySelectorAll('pds-secondary-navigation-link');
    if (links) {
      links.forEach((link) => {
        if (link.getAttribute('href') === window.location.href) {
          link.setAttribute('activePage', 'true');
          link.setAttribute('ariaCurrent', 'page');
          if (this.target) {
            link.setAttribute('target', this.target);
          }
          if (this.rel) {
            link.setAttribute('rel', this.rel);
          }
        }
      });
    }
  }

  /**
   * Callback function to determine if this is the active page
   * Defaults to looking at the url of each secondary nav level two item
   * and determines if the current URL matches the nav item URL
   *
   * If it is not passed, determineActivePage() will be called instead
   */
  @property({ type: Function })
  activePageCallback?: Function;

  handleSlotChange() {
    this.requestUpdate();
  }

  checkForMobileView() {
    this.isDesktopView = false;
    if (
      ['xs', 'sm', 'md'].includes(this.responsiveViewportSize) &&
      this.break === 'default'
    ) {
      return true;
    }
    if (
      ['xs', 'sm'].includes(this.responsiveViewportSize) &&
      this.break === 'faster'
    ) {
      return true;
    }
    if (
      ['xs', 'sm', 'md', 'lg'].includes(this.responsiveViewportSize) &&
      this.break === 'slower'
    ) {
      return true;
    }

    this.isDesktopView = true;
    return false;
  }

  /**
   * internal
   */
  get classNames() {
    return {
      'desktop-view': this.isDesktopView,
    };
  }

  render() {
    // https://docs.principal.com/display/FEDX/Use+noopener+and+noreferrer+when+opening+a+new+tab+or+window
    if (this.target === '_blank') {
      this.rel = 'noopener noreferrer';
    }

    if (
      !isServer &&
      window &&
      window.visualViewport &&
      this.checkForMobileView() &&
      this.href
    ) {
      return html`<div class=${this.getClass()}>
        <div class="${this.classMod('mobile')}">
          <pds-secondary-navigation-link
            class="${this.classEl('menu-item')} ${this.classMod(
              'menu-item-link',
            )}"
            href="${this.href}"
            activePage="${this.activePage || nothing}"
            target="${this.target || nothing}"
            rel="${this.rel || nothing}"
            ariaCurrent="${this.activePage ? 'page' : nothing}"
            >${this.text}</pds-secondary-navigation-link
          >
        </div>
      </div>`;
    }
    if (
      !isServer &&
      window &&
      window.visualViewport &&
      this.checkForMobileView()
    ) {
      return html`<div class=${this.getClass()}>
        <div class="${this.classMod('mobile')}">
          <pds-accordion variant="default" class="${this.classEl('menu-item')}"
            ><span slot="summary-title">${this.text}</span
            ><span slot="accordion-content"
              ><slot
                @slotchange="${this.handleSlotChange}"
                class="${this.classEl('slot')}"
              ></slot></span
          ></pds-accordion>
        </div>
      </div>`;
    }
    if (this.href) {
      return html`<div class=${this.getClass()}>
        <div class="${this.classMod('desktop')}">
          <pds-secondary-navigation-link
            class="${this.classEl('menu-item')} ${this.classMod(
              'menu-item-link',
            )}"
            activePage="${this.activePage || nothing}"
            href="${this.href}"
            target="${this.target || nothing}"
            rel="${this.rel || nothing}"
            ariaCurrent="${this.activePage ? 'page' : nothing}"
            >${this.text}</pds-secondary-navigation-link
          >
        </div>
      </div>`;
    }
    return html`<div class=${this.getClass()}>
      <div class="${this.classMod('desktop')}">
        <div class="${this.classEl('menu-item')}">
          <span class="${this.classEl('category-title')}">${this.text}</span>
          <slot
            @slotchange="${this.handleSlotChange}"
            class="${this.classEl('slot')}"
          ></slot>
        </div>
      </div>
    </div>`;
  }
}
