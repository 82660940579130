import React from "react";
import { ErrorMessage } from "@hookform/error-message";
import classNames from "classnames";

type Props = {
  register: any;
  errors: any;
};

export default function CountrySelect(props: Props) {
  const { register, errors } = props;

  const hasErrors = errors.address?.country;
  const className = classNames("form-group", {
    "has-error": hasErrors,
  });
  return (
    <div className={className}>
      <label htmlFor={"address.country"}>Country</label>
      <select
        data-testid="address.country"
        id="address.country"
        name="address.country"
        data-index="0"
        className="form-control js-foreign-address"
        ref={register({})}
      >
        <option value="">Select a country...</option>
        <option value="AA">ARUBA - AA</option>
        <option value="AC">ANTIGUA AND BARBUDA - AC</option>
        <option value="AF">AFGHANISTAN - AF</option>
        <option value="AG">ALGERIA - AG</option>
        <option value="AI">ASCENSION - AI</option>
        <option value="AJ">AZERBAIJAN - AJ</option>
        <option value="AL">ALBANIA - AL</option>
        <option value="AM">ARMENIA - AM</option>
        <option value="AN">ANDORRA - AN</option>
        <option value="AO">ANGOLA - AO</option>
        <option value="AQ">SAMOA - AQ</option>
        <option value="AR">ARGENTINA - AR</option>
        <option value="AS">AUSTRALIA - AS</option>
        <option value="AU">AUSTRIA - AU</option>
        <option value="AV">ANGUILLA - AV</option>
        <option value="AY">ANTARCTICA - AY</option>
        <option value="BA">BAHRAIN - BA</option>
        <option value="BB">BARBADOS - BB</option>
        <option value="BC">BOTSWANA - BC</option>
        <option value="BD">BERMUDA - BD</option>
        <option value="BE">BELGIUM - BE</option>
        <option value="BF">BAHAMAS - BF</option>
        <option value="BG">BANGLADESH - BG</option>
        <option value="BH">BELIZE - BH</option>
        <option value="BK">BOSNIA-HERCEGOVINA - BK</option>
        <option value="BL">BOLIVIA - BL</option>
        <option value="BM">MYANMAR - BM</option>
        <option value="BN">BENIN - BN</option>
        <option value="BO">BELARUS - BO</option>
        <option value="BP">SOLOMON ISLANDS - BP</option>
        <option value="BQ">NAVASSA ISLAND - BQ</option>
        <option value="BR">BRAZIL - BR</option>
        <option value="BS">BASSAS DA INDIA - BS</option>
        <option value="BT">BHUTAN - BT</option>
        <option value="BU">BULGARIA - BU</option>
        <option value="BV">BOUVET ISLAND - BV</option>
        <option value="BX">BRUNEI DARUSSALAM - BX</option>
        <option value="BY">BURUNDI - BY</option>
        <option value="CA">CANADA - CA</option>
        <option value="CB">CAMBODIA - CB</option>
        <option value="CD">CHAD - CD</option>
        <option value="CE">SRI LANKA - CE</option>
        <option value="CF">CONGO - CF</option>
        <option value="CG">ZAIRE - CG</option>
        <option value="CH">CHINA - CH</option>
        <option value="CI">CHILE - CI</option>
        <option value="CJ">CAYMAN ISLANDS - CJ</option>
        <option value="CK">COCOS - CK</option>
        <option value="CL">CANARY ISLANDS - CL</option>
        <option value="CM">CAMEROON - CM</option>
        <option value="CN">COMOROS - CN</option>
        <option value="CO">COLOMBIA - CO</option>
        <option value="CQ">NORTHERN MARIANA ISLANDS - CQ</option>
        <option value="CS">COSTA RICA - CS</option>
        <option value="CT">CENTRAL AFRICAN REPUBLIC - CT</option>
        <option value="CU">CUBA - CU</option>
        <option value="CV">CAPE VERDE - CV</option>
        <option value="CW">COOK ISLANDS - CW</option>
        <option value="CY">CYPRUS - CY</option>
        <option value="DA">DENMARK - DA</option>
        <option value="DJ">DJIBOUTI - DJ</option>
        <option value="DO">DOMINICA - DO</option>
        <option value="DR">DOMINICAN REPUBLIC - DR</option>
        <option value="EC">ECUADOR - EC</option>
        <option value="EG">EGYPT - EG</option>
        <option value="EI">IRELAND - EI</option>
        <option value="EK">EQUATORIAL GUINEA - EK</option>
        <option value="EN">ESTONIA - EN</option>
        <option value="ER">ERITREA - ER</option>
        <option value="ES">EL SALVADOR - ES</option>
        <option value="ET">ETHIOPIA - ET</option>
        <option value="EZ">CZECH REPUBLIC - EZ</option>
        <option value="FG">FRENCH GUYANA - FG</option>
        <option value="FI">FINLAND - FI</option>
        <option value="FJ">FIJI - FJ</option>
        <option value="FK">FALKLAND ISLANDS - FK</option>
        <option value="FM">MICRONESIA - FM</option>
        <option value="FO">FAROE ISLANDS - FO</option>
        <option value="FP">FRENCH POLYNESIA - FP</option>
        <option value="FQ">BAKER ISLAND - FQ</option>
        <option value="FR">FRANCE - FR</option>
        <option value="GA">GAMBIA - GA</option>
        <option value="GB">GABON - GB</option>
        <option value="GG">GEORGIA - GG</option>
        <option value="GH">GHANA - GH</option>
        <option value="GI">GIBRALTAR - GI</option>
        <option value="GJ">GRENADA - GJ</option>
        <option value="GK">GUERNSEY - GK</option>
        <option value="GL">GREENLAND - GL</option>
        <option value="GM">GERMANY - GM</option>
        <option value="GN">GREAT BRITAIN - GN</option>
        <option value="GP">GUADELOUPE - GP</option>
        <option value="GQ">GUAM - GQ</option>
        <option value="GR">GREECE - GR</option>
        <option value="GT">GUATEMALA - GT</option>
        <option value="GV">GUINEA - GV</option>
        <option value="GY">GUYANA - GY</option>
        <option value="HA">HAITI - HA</option>
        <option value="HK">HONG KONG - HK</option>
        <option value="HM">HEARD AND MCDONALD ISLANDS - HM</option>
        <option value="HO">HONDURAS - HO</option>
        <option value="HQ">HOWLAND ISLAND - HQ</option>
        <option value="HR">CROATIA - HR</option>
        <option value="HU">HUNGARY - HU</option>
        <option value="IC">ICELAND - IC</option>
        <option value="ID">INDONESIA - ID</option>
        <option value="IM">ISLE OF MAN - IM</option>
        <option value="IN">INDIA - IN</option>
        <option value="IO">BRITISH INDIAN OCEAN TERRITORY - IO</option>
        <option value="IP">CLIPPERTON ISLAND - IP</option>
        <option value="IR">IRAN - IR</option>
        <option value="IS">ISRAEL - IS</option>
        <option value="IT">ITALY - IT</option>
        <option value="IV">COTE D'IVOIRE - IV</option>
        <option value="IZ">IRAQ - IZ</option>
        <option value="JA">JAPAN - JA</option>
        <option value="JE">JERSEY - JE</option>
        <option value="JM">JAMAICA - JM</option>
        <option value="JN">JAN MAYEN - JN</option>
        <option value="JO">JORDAN - JO</option>
        <option value="KE">KENYA - KE</option>
        <option value="KG">KYRGHYZSTAN - KG</option>
        <option value="KI">KIRIBATI - KI</option>
        <option value="KN">NORTH KOREA - KN</option>
        <option value="KO">KOSOVO - KO</option>
        <option value="KQ">KINGMAN REEF - KQ</option>
        <option value="KR">CHRISTMAS ISLAND (PACIFIC) - KR</option>
        <option value="KS">SOUTH KOREA - KS</option>
        <option value="KT">CHRISTMAS ISLAND - KT</option>
        <option value="KU">KUWAIT - KU</option>
        <option value="KZ">KAZAKHSTAN - KZ</option>
        <option value="LA">LAOS - LA</option>
        <option value="LE">LEBANON - LE</option>
        <option value="LG">LATVIA - LG</option>
        <option value="LH">LITHUANIA - LH</option>
        <option value="LI">LIBERIA - LI</option>
        <option value="LO">SLOVAKIA - LO</option>
        <option value="LQ">PALMYRA ATOLL - LQ</option>
        <option value="LS">LIECHTENSTIEN - LS</option>
        <option value="LT">LESOTHO - LT</option>
        <option value="LU">LUXEMBOURG - LU</option>
        <option value="LY">LIBYAN JAMAHIRIYA - LY</option>
        <option value="MA">MADAGASCAR - MA</option>
        <option value="MB">MARTINIQUE - MB</option>
        <option value="MC">MACAO - MC</option>
        <option value="MD">MOLDOVA - MD</option>
        <option value="ME">METROPOLE (EUROPEAN PART OF COUNTRY - ME</option>
        <option value="MF">MAYOTTE - MF</option>
        <option value="MG">MONGOLIA - MG</option>
        <option value="MH">MONTSERRAT - MH</option>
        <option value="MI">MALAWI - MI</option>
        <option value="MK">MACEDONIA - MK</option>
        <option value="ML">MALI - ML</option>
        <option value="MN">MONACO - MN</option>
        <option value="MO">MOROCCO - MO</option>
        <option value="MP">MAURITIUS - MP</option>
        <option value="MQ">MIDWAY ISLANDS - MQ</option>
        <option value="MR">MAURITANIA - MR</option>
        <option value="MS">MARIANA ISLANDS - MS</option>
        <option value="MT">MALTA - MT</option>
        <option value="MU">OMAN - MU</option>
        <option value="MV">MALDIVES - MV</option>
        <option value="MW">MONTENEGRO - MW</option>
        <option value="MX">MEXICO - MX</option>
        <option value="MY">MALAYSIA - MY</option>
        <option value="MZ">MOZAMBIQUE - MZ</option>
        <option value="NC">NEW CALEDONIA - NC</option>
        <option value="NE">NIUE - NE</option>
        <option value="NF">NORFOLK ISLAND - NF</option>
        <option value="NG">NIGER - NG</option>
        <option value="NH">VANUATU - NH</option>
        <option value="NI">NIGERIA - NI</option>
        <option value="NL">NETHERLANDS - NL</option>
        <option value="NN">NORTHERN IRELAND - NN</option>
        <option value="NO">NORWAY - NO</option>
        <option value="NP">NEPAL - NP</option>
        <option value="NR">NAURU - NR</option>
        <option value="NS">SURINAME - NS</option>
        <option value="NT">NETHERLANDS ANTILLES - NT</option>
        <option value="NU">NICARAGUA - NU</option>
        <option value="NZ">NEW ZEALAND - NZ</option>
        <option value="PA">PARAGUAY - PA</option>
        <option value="PC">PITCAIRN ISLANDS - PC</option>
        <option value="PE">PERU - PE</option>
        <option value="PK">PAKISTAN - PK</option>
        <option value="PL">POLAND - PL</option>
        <option value="PM">PANAMA - PM</option>
        <option value="PO">PORTUGAL - PO</option>
        <option value="PP">PAPUA NEW GUINEA - PP</option>
        <option value="PS">TRUST TERRITORY OF PACIFIC ISLANDS - PS</option>
        <option value="PU">GUINEA BISSAU - PU</option>
        <option value="PW">PALAU ISLANDS - PW</option>
        <option value="QA">QATAR - QA</option>
        <option value="RE">REUNION - RE</option>
        <option value="RM">MARSHALL ISLANDS - RM</option>
        <option value="RO">ROMANIA - RO</option>
        <option value="RP">PHILIPPINES - RP</option>
        <option value="RS">RUSSIA - RS</option>
        <option value="RW">RWANDA - RW</option>
        <option value="SA">SAUDI ARABIA - SA</option>
        <option value="SB">SAINT PIERRE AND MIQUELON - SB</option>
        <option value="SC">SAINT KITTS AND NEVIS - SC</option>
        <option value="SD">SUDAN - SD</option>
        <option value="SE">SEYCHELLES - SE</option>
        <option value="SF">SOUTH AFRICA - SF</option>
        <option value="SG">SENEGAL - SG</option>
        <option value="SH">SAINT HELENA - SH</option>
        <option value="SI">SLOVENIA - SI</option>
        <option value="SL">SIERRA LEONE - SL</option>
        <option value="SM">SAN MARINO - SM</option>
        <option value="SN">SINGAPORE - SN</option>
        <option value="SO">SOMALIA - SO</option>
        <option value="SP">SPAIN - SP</option>
        <option value="SR">SERBIA - SR</option>
        <option value="ST">SAINT LUCIA - ST</option>
        <option value="SV">SVALBARD - SV</option>
        <option value="SW">SWEDEN - SW</option>
        <option value="SX">S GEORGIA AND S SANDWICH ISLAND - SX</option>
        <option value="SY">SYRIA - SY</option>
        <option value="SZ">SWITZERLAND - SZ</option>
        <option value="TC">UNITED ARAB EMIRATES - TC</option>
        <option value="TD">TRINIDAD AND TOBAGO - TD</option>
        <option value="TH">THAILAND - TH</option>
        <option value="TI">TAJIKISTAN - TI</option>
        <option value="TK">TURKS AND CAICOS - TK</option>
        <option value="TL">TOKELAU - TL</option>
        <option value="TN">TONGA - TN</option>
        <option value="TO">TOGO - TO</option>
        <option value="TP">SAO TOME AND PRINCIPE - TP</option>
        <option value="TR">TRISTAN DA CUNHA - TR</option>
        <option value="TS">TUNISIA - TS</option>
        <option value="TU">TURKEY - TU</option>
        <option value="TV">TUVALU - TV</option>
        <option value="TW">TAIWAN - TW</option>
        <option value="TX">TURKMENISTAN - TX</option>
        <option value="TZ">TANZANIA - TZ</option>
        <option value="UG">UGANDA - UG</option>
        <option value="UK">UNITED KINGDOM - UK</option>
        <option value="UP">UKRAINE - UP</option>
        <option value="US">UNITED STATES OF AMERICA - US</option>
        <option value="UV">BURKINA FASO - UV</option>
        <option value="UY">URUGUAY - UY</option>
        <option value="UZ">UZBEKISTAN - UZ</option>
        <option value="VC">ST VINCENT AND THE GRENADINES - VC</option>
        <option value="VE">VENEZUELA - VE</option>
        <option value="VI">VIRGIN ISLANDS (BRITISH) - VI</option>
        <option value="VM">VIETNAM - VM</option>
        <option value="VQ">VIRGIN ISLANDS - VQ</option>
        <option value="VT">VATICAN CITY - VT</option>
        <option value="WA">NAMIBIA - WA</option>
        <option value="WF">WALLIS AND FUTUNA - WF</option>
        <option value="WI">WESTERN SAHARA - WI</option>
        <option value="WQ">WAKE ISLAND - WQ</option>
        <option value="WS">WESTERN SAMOA - WS</option>
        <option value="WZ">SWAZILAND - WZ</option>
        <option value="YM">YEMEN - YM</option>
        <option value="YO">YUGOSLAVIA - YO</option>

        <option value="ZA">ZAMBIA - ZA</option>
        <option value="ZB">CURACAO - ZB</option>
        <option value="ZI">ZIMBABWE - ZI</option>
      </select>
      <ErrorMessage errors={errors} name="address.country" />
    </div>
  );
}
