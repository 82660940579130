import {
  getDayOptionsByYearAndMonth,
  getNumbersBetween,
  isWeekendDMY,
  MONTH_NUMBERS,
} from "./date-util";
import { buildOption, GeneralOption } from "../models/generalOption";

const currentYear = new Date().getFullYear();

export function getAvailableYears(
  definedMonth: number | null,
  definedDay: number | null,
  minimumRedeferralDateString: string
) {
  const minimumRedeferralYear = getMinimumRedeferralYear(
    minimumRedeferralDateString
  );
  const maxYear = minimumRedeferralYear + 50; // There is no defined maximum, so for now we're just using Minimum + 50.
  const adjustedMinimumRedeferralYear = doesRealMinimumRolloverByYear(
    definedMonth,
    definedDay,
    minimumRedeferralDateString
  )
    ? minimumRedeferralYear + 1
    : minimumRedeferralYear;
  return getNumbersBetween(adjustedMinimumRedeferralYear, maxYear);
}

export function doesRealMinimumRolloverByYear(
  definedMonth: number | null,
  definedDay: number | null,
  minimumRedeferralDateString: string
): boolean {
  // if plan defined month is BEFORE minimum month, we must rollover to the next year
  const mustRolloverYearForMonth =
    definedMonth !== null &&
    definedMonth < getMinimumRedeferralMonth(minimumRedeferralDateString);
  // if plan defined day is BEFORE minimum day, and minimum month is December, we must rollover to the next year
  const mustRolloverYearForDay =
    definedDay !== null &&
    definedDay < getMinimumRedeferralDay(minimumRedeferralDateString) &&
    getMinimumRedeferralMonth(minimumRedeferralDateString) === 12;
  return mustRolloverYearForMonth || mustRolloverYearForDay;
}

export function getAvailableMonths(
  definedDay: number | null,
  minimumRedeferralDateString: string,
  selectedYear: string
): number[] {
  const isInMinimumYear =
    selectedYear !== "" &&
    Number(selectedYear) ===
      getMinimumRedeferralYear(minimumRedeferralDateString);
  if (isInMinimumYear) {
    // If we're in the minimum year, we also need to disable months that are before the minimum month.
    const OFFSET = -1; // Months are off by one (January = 0, February = 1, etc)
    const minimumMonth =
      getMinimumRedeferralMonth(minimumRedeferralDateString) + OFFSET;
    const adjustedMinimumMonth = doesRealMinimumRolloverByMonth(
      definedDay,
      minimumRedeferralDateString
    )
      ? minimumMonth + 1
      : minimumMonth;
    return MONTH_NUMBERS.filter((month) => {
      return month >= adjustedMinimumMonth;
    });
  } else {
    return MONTH_NUMBERS;
  }
}

export function doesRealMinimumRolloverByMonth(
  definedDay: number | null,
  minimumRedeferralDateString: string
): boolean {
  // if plan defined day is BEFORE minimum day, and minimum month is NOT December, we must rollover to the next year
  return (
    definedDay !== null &&
    definedDay < getMinimumRedeferralDay(minimumRedeferralDateString) &&
    getMinimumRedeferralMonth(minimumRedeferralDateString) !== 12
  );
}

export function getDaysForMonth(
  marketClosedDates: string[] | null,
  minimumRedeferralDateString: string,
  yearString: string,
  monthString: string
): GeneralOption[] {
  let month: number;
  if (monthString === "") {
    month = 0;
  } else {
    month = Number(monthString);
  }

  let year: number;
  if (yearString === "") {
    year = currentYear;
  } else {
    year = Number(yearString);
  }

  const monthWithOffset = month + 1;
  const relevantMarketClosedDates = getRelevantMarketClosedDates(
    marketClosedDates,
    year,
    monthWithOffset
  );
  const minimumYear = getMinimumRedeferralYear(minimumRedeferralDateString);
  const minimumMonth = getMinimumRedeferralMonth(minimumRedeferralDateString);
  const minimumDay = getMinimumRedeferralDay(minimumRedeferralDateString);
  const isMinimumMonth =
    year === minimumYear && monthWithOffset === minimumMonth;

  return getDayOptionsByYearAndMonth(year, monthWithOffset)
    .map((day) => {
      return buildOption(day.toString(), day, false);
    })
    .filter((dayOption) => {
      // If we're in the minimum redeferral month, we need to remove days before the minimum.
      return !(isMinimumMonth && dayOption.value < minimumDay);
    })
    .map((dayOption) => {
      // Disable market closed days & weekends
      const isMarketClosedDate = relevantMarketClosedDates.includes(
        dayOption.value
      );
      if (isMarketClosedDate) {
        dayOption.disabled = true;
        dayOption.name += " (Market Closed Day)";
      }

      if (isWeekendDMY(dayOption.value, monthWithOffset, year)) {
        dayOption.disabled = true;
        dayOption.name += " (Market Closed Day - Weekend)";
      }

      return dayOption;
    });
}

export function getRelevantMarketClosedDates(
  marketClosedDates: string[] | null,
  selectedYear: number,
  selectedMonth: number
): number[] {
  if (!marketClosedDates) {
    return [];
  }
  return marketClosedDates
    .filter((date) => {
      const datePieces = date.split("-");
      const year = Number(datePieces[0]);
      const month = Number(datePieces[1]);
      return selectedYear === year && selectedMonth === month;
    })
    .map((date) => {
      return Number(date.split("-")[2]);
    });
}

export function getLastAvailableDayForMonth(
  selectedYear: string,
  monthValue: number,
  planDefinedDay: number
): number {
  const lastDayOfMonth = new Date(
    Number(selectedYear),
    monthValue,
    0
  ).getDate();
  if (planDefinedDay <= lastDayOfMonth) {
    return planDefinedDay;
  } else {
    return lastDayOfMonth;
  }
}

export function getMinimumRedeferralYear(
  minimumRedeferralDateString: string
): number {
  return Number(minimumRedeferralDateString.split("/")[2]);
}

export function getMinimumRedeferralMonth(
  minimumRedeferralDateString: string
): number {
  return Number(minimumRedeferralDateString.split("/")[0]);
}

export function getMinimumRedeferralDay(
  minimumRedeferralDateString: string
): number {
  return Number(minimumRedeferralDateString.split("/")[1]);
}

export function getYearFromDate(dateString: string): number {
  return Number(dateString.split("-")[0]);
}

export function getMonthFromDate(dateString: string): number {
  return Number(dateString.split("-")[1]) - 1;
}

export function getDayFromDate(dateString: string): number {
  return Number(dateString.split("-")[2]);
}
