import { RootState } from "../store/store";
import { createSelector } from "@reduxjs/toolkit";
import { PayoutStatus } from "../models/payout-status";

export const selectedAccountIdsSelector = (state: RootState) =>
  state.accounts.selectedIds;
export const selectedAccountsSelector = (state: RootState) =>
  state.accounts.selected;

export const selectedAccountsNotInPayoutSelector = createSelector(
  [selectedAccountsSelector],
  (accounts) =>
    accounts.filter(
      (account) =>
        account.payoutStatus !== PayoutStatus.COMPLETELY_PAID_OUT &&
        account.payoutStatus !== PayoutStatus.PAID_OUT_HAS_BALANCE
    )
);
