import React, { useContext, useState } from "react";
import InfoCircle from "../info-circle/InfoCircle";
import RectangleProp from "../orange-rectangle/RectangleProp";
import "./enrollmentCard.scss";
import Button from "../buttons/button";
import { BrowserRouter, Link } from "react-router-dom";
import { EnrollmentContext } from "../../contexts/enrollment-context/enrollment-context";
import { Accordion } from "../accordion/accordion";
import { CollapseEnrollment } from "../collapse/collapseEnrollment";
import { generateEnrollmentLink } from "../../util/nq-enroll-now-link";
import { ParticipantEvent } from "../../models/ParticipantEvent";
import { useSelector } from "../../store/store";
import { PdsLink } from "@principal/design-system-react";
import { AsyncStatus } from "../../models/async-status";

import { NqAccount } from "../../models/nq-accounts.model";
import { fetchNqMultipleAccountsDetails } from "../../reducers/nq-accounts-multiple";
import { sessionStatusSelector, useStatuses } from "../../selectors/status";
import OpenEventRect from "../enroll-events/openEventRect";

interface EnrollmentCardProps {
  date: number;
  title: string;
  endDate: number;
  eventId: number;
  empId: number;
  event: ParticipantEvent;
  openEvents: ParticipantEvent[];
  completedEvents: number;
}

const EnrollmentCard = (props: EnrollmentCardProps) => {
  const date = new Date(props.endDate);
  const formattedEndDate = date.toLocaleDateString();
  const [isRectanglePropVisible, setRectanglePropVisibility] = useState(true);
  const { role } = useSelector((state) => state.session);
  const handleRectanglePropVisibility = (isVisible: boolean) => {
    setRectanglePropVisibility(isVisible);
  };

  if (props.openEvents.length === 0) {
    return <OpenEventRect />;
  }
  const isLastEvent =
    props.eventId === props.openEvents[props.openEvents.length - 1].eventId;
  return (
    <div className="pds-card pds-productCard">
      <div className="separation"></div>

      <div
        className={
          isLastEvent ||
          0 === props.openEvents.length - props.completedEvents - 1
            ? "last-event"
            : "box-contents"
        }
      >
        <div className="pds-card-section">
          <div className="row">
            <p className="pds-typography-body-14 yearly-new-enrollment">
              {props.date} {props.event.eventConfiguration.eventType} enrollment
            </p>
            <p
              className="pds-typography-body-14"
              style={{ textAlign: "left", marginTop: "-1%" }}
            >
              Our records indicate you are eligible to participate in the
              following plan: Nonqualified Deferred
            </p>
            <p
              className="pds-typography-body-14"
              style={{ textAlign: "left", marginTop: "-1.5%" }}
            >
              Compensation plan of {props.title}
            </p>

            {isRectanglePropVisible && (
              <div style={{ paddingTop: "-1%" }}>
                <RectangleProp
                  text={formattedEndDate}
                  onVisibilityChange={handleRectanglePropVisibility}
                />
                <div className="raised-padding1 ">
                  <PdsLink
                    button="primary"
                    href={generateEnrollmentLink(props.event, role)}
                  >
                    <span className="custom-button">Enroll now</span>
                  </PdsLink>
                </div>
              </div>
            )}
            {!isRectanglePropVisible && (
              <PdsLink
                button="primary"
                href={generateEnrollmentLink(props.event, role)}
              >
                <span className="custom-button">Enroll now</span>
              </PdsLink>
            )}
            <CollapseEnrollment
              text={formattedEndDate}
              dateYear={props.date}
              endDate={formattedEndDate}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default EnrollmentCard;
