import _find from "lodash/find";
import _flatMap from "lodash/flatMap";
import _get from "lodash/get";
import _groupBy from "lodash/groupBy";
import {
  FutureDistributionPayment,
  PastDistributionPayment,
  ScheduledDistributionPagePayment,
} from "../../../../models/distributions/distribution-payment";
import { EstimatedDistribution } from "../../../../models/distributions/estimated-distribution";
import { InstallmentType } from "../../../../models/distributions/installment-type";
import { PaymentType } from "../../../../models/distributions/payment-type";
import {
  PaymentMethod,
  PayoutMethod,
} from "../../../../models/distributions/payout-method";
import { ScheduledDistributionEvent } from "../../../../models/distributions/scheduled-distribution-event";
import { ScheduledDistributionElection } from "../../../../models/distributions/ScheduledDistributionElection";
import { UnscheduledDistributionElection } from "../../../../models/distributions/unscheduled-distribution-election";
import { InvestmentV2 } from "../../../../models/investment-v2";
import {
  buildInstallmentEstimatedDistributions,
  buildLumpInstallEstimatedDistributions,
} from "../../../../util/estimated-distribution-util";
import { filterInvestmentsByAccountIds } from "../../../../util/investment-context-util";
import { calculateTotalEndBalanceV2 } from "../../../../util/investments";
import {
  buildDistributionMethod,
  filterFutureDistributions,
  filterPastDistributions,
  getDistributionWithTotalInstallmentsRemaining,
  getEstimatedDistributionForInitialLumpWithInstallments,
  getEstimatedDistributionLumpSum,
  getFirstScheduledDistributionElectionByAccountId,
  getFixedInstallmentPaymentAmount,
  getInstallmentPaymentAmount,
} from "../../distribution-scheduled/scheduled-distribution-util";
import { getEstimatedTotalInstallments } from "../estimated-distributions/estimated-distribution-generate-util";

export function buildDistributionPayments(
  distributions: ScheduledDistributionEvent[],
  investments: InvestmentV2[],
  scheduledDistributionElections: ScheduledDistributionElection[],
  isScheduledDistributionPage: boolean = false
) {
  const distributionsByAccount: ScheduledDistributionEvent[][] =
    getDistributionWithTotalInstallmentsRemaining(distributions);

  const mappedAccounts = distributionsByAccount.map(
    (distributionsForAccount) => {
      const scheduledDistributionsGroups = _groupBy(
        distributionsForAccount,
        "status"
      );
      const { Distributed: distributed = [], Scheduled: scheduled = [] } =
        scheduledDistributionsGroups;

      //past distributions should already be filtered via the groupby, but some pilot data is wrong so filtering again
      const filteredPastDistributions = filterPastDistributions(distributed);
      const pastDistributions = filteredPastDistributions.map((event) => {
        return new PastDistributionPayment(event);
      });

      //future distributions should already be filtered via the groupby, but some pilot data is wrong so filtering again
      const filteredFutureDistributions = filterFutureDistributions(scheduled);

      const totalBalance = getTotalBalance(
        filteredFutureDistributions,
        investments
      );

      const futureDistributions = filteredFutureDistributions.map((event) => {
        const id = event.accountId;
        const filteredElectionById: ScheduledDistributionElection =
          getFirstScheduledDistributionElectionByAccountId(
            scheduledDistributionElections,
            id
          )!;

        const initialLumpAmount = getLumpAmount(
          filteredElectionById,
          totalBalance
        );

        const color = _get(event, "color");
        const estimatedDistribution = getEstimatedPaymentAmount(
          event,
          investments,
          initialLumpAmount,
          pastDistributions.length
        );
        if (isScheduledDistributionPage) {
          const distributionMethod = buildDistributionMethod(event);
          return new ScheduledDistributionPagePayment(
            event,
            id,
            estimatedDistribution,
            distributionMethod
          );
        }
        return new FutureDistributionPayment(
          event,
          estimatedDistribution,
          color,
          totalBalance
        );
      });
      if (isScheduledDistributionPage) {
        return futureDistributions;
      }

      return [...pastDistributions, ...futureDistributions];
    }
  );
  return _flatMap(mappedAccounts);
}

function getEstimatedPaymentAmount(
  distributionEvent: ScheduledDistributionEvent,
  investments: InvestmentV2[],
  initialLumpAmount?: number,
  installmentsDistributed?: number
): number {
  const {
    accountId,
    paymentType,
    installNum,
    totalInstallmentsRemaining,
    installmentType,
    installmentAmount,
  } = distributionEvent;
  if (paymentType === PaymentType.INSTALLMENT) {
    if (installmentType === InstallmentType.FIXED_AMOUNTS) {
      return getFixedInstallmentPaymentAmount(
        accountId,
        investments,
        totalInstallmentsRemaining,
        installNum,
        installmentAmount!,
        installmentsDistributed!
      );
    }

    return getInstallmentPaymentAmount(
      accountId,
      investments,
      totalInstallmentsRemaining,
      installmentsDistributed!
    );
  }

  if (paymentType === PaymentType.INITIAL) {
    return getEstimatedDistributionForInitialLumpWithInstallments(
      accountId,
      investments,
      installNum,
      totalInstallmentsRemaining,
      initialLumpAmount,
      installmentsDistributed!
    );
  }

  const investmentFound = investments.filter(
    (investment) => investment.accountId === accountId
  );
  return getEstimatedDistributionLumpSum(accountId, investmentFound);
}

export function getTotalRemainingInstallments(
  election: UnscheduledDistributionElection | undefined
) {
  if (election && election.installmentFrequency && election.installmentYears) {
    return getEstimatedTotalInstallments(
      election.installmentYears,
      election.installmentFrequency
    );
  } else {
    return undefined;
  }
}

export function buildEstimatedDistributionsToShow(
  accountId: number,
  elections: UnscheduledDistributionElection[],
  investments: InvestmentV2[]
): EstimatedDistribution[] | undefined {
  const election: UnscheduledDistributionElection | undefined =
    getElectionToUse(elections);

  function getAmountAsDollars(
    accountInvestments: InvestmentV2[],
    installmentAmount: number
  ) {
    const totalBalance: number = calculateTotalEndBalanceV2(accountInvestments);
    return (totalBalance * installmentAmount) / 100;
  }

  if (election) {
    const totalRemaining = getTotalRemainingInstallments(election);
    const accountInvestments = filterInvestmentsByAccountIds(investments, [
      accountId,
    ]);
    let { installmentAmount = 0 } = election;
    if (
      election.initialPaymentMethodId === PaymentMethod.PERCENT &&
      accountInvestments
    ) {
      installmentAmount = getAmountAsDollars(
        accountInvestments,
        installmentAmount
      );
    }
    if (election.payoutMethod === PayoutMethod.LUMP_INSTALL && totalRemaining) {
      return buildLumpInstallEstimatedDistributions(
        accountId,
        accountInvestments,
        totalRemaining,
        installmentAmount,
        0
      );
    }
    if (election.payoutMethod === PayoutMethod.INSTALLMENTS && totalRemaining) {
      return buildInstallmentEstimatedDistributions(
        accountId,
        accountInvestments,
        totalRemaining,
        0
      );
    }
    if (election.paymentMethod === PayoutMethod.LUMP_SUM) {
      return [
        {
          installment: 1,
          estimatedDistribution: getEstimatedDistributionLumpSum(
            accountId,
            accountInvestments
          ),
        },
      ];
    }
  }
}

export function getElectionToUse(
  elections: UnscheduledDistributionElection[]
): UnscheduledDistributionElection | undefined {
  let potentialElection: UnscheduledDistributionElection | undefined;
  potentialElection = hasElectionWithEventId(elections, 2);
  if (potentialElection) {
    return potentialElection;
  }
  potentialElection = hasElectionWithEventId(elections, 9);
  if (potentialElection) {
    return potentialElection;
  }
  potentialElection = hasElectionWithEventId(elections, 14);
  if (potentialElection) {
    return potentialElection;
  }
  potentialElection = hasElectionWithEventId(elections, 1);
  if (potentialElection) {
    return potentialElection;
  }
  potentialElection = hasElectionWithEventId(elections, 10);
  if (potentialElection) {
    return potentialElection;
  }
  potentialElection = hasElectionWithEventId(elections, 13);
  if (potentialElection) {
    return potentialElection;
  }
  potentialElection = hasElectionWithEventId(elections, 5);
  if (potentialElection) {
    return potentialElection;
  }
  return hasElectionWithEventId(elections, 12);
}

function hasElectionWithEventId(
  elections: UnscheduledDistributionElection[],
  eventId: number
): UnscheduledDistributionElection | undefined {
  return _find(elections, ["eventId", eventId]);
}

function getTotalBalance(
  distributionsForAccount: ScheduledDistributionEvent[],
  investments: InvestmentV2[]
) {
  const accountId = _get(distributionsForAccount[0], "accountId");
  const filteredInvestments = investments.filter(
    (investment) => investment.accountId === accountId
  );

  return calculateTotalEndBalanceV2(filteredInvestments);
}

export function getLumpAmount(
  filteredElectionById: ScheduledDistributionElection,
  totalBalance: number
) {
  const lumpPaymentType = _get(filteredElectionById, "lumpMeth");
  if (lumpPaymentType === "%") {
    const lumpPercent = _get(filteredElectionById, "lumpAmount");
    if (lumpPercent) {
      return totalBalance * (lumpPercent / 100);
    }
    return undefined;
  }
  return _get(filteredElectionById, "lumpAmount");
}
