export interface ContributionRates {
  contributionTypeName: string;
  contributionTypeId: number;
  contributionElectionType: ContributionType;
  contributionAmount?: number;
  otherText?: string;
  effectiveDate: string;
  availableElections: ElectionOption[];
  moreInfoText?: string;
  sdainfoUnavailable: boolean;
}

export type ElectionOption = {
  id: number;
  electionType: ContributionType;
  maximum?: number;
  minimum?: number;
  increment?: number;
};

export interface PendingContributionRate {
  contributionRateId: ContributionRateId;
  contributionElectionType: ContributionType;
  contributionAmount: number;
  contributionElectionTypeId: number;
  deferralEffectiveDate: Date;
  contributionOtherText: string;
  lastModifiedDate: string;
  lastModifiedBy: string;
}

export interface ContributionHistory {
  accountId: number;
  accountName: string;
  moneyTypeId: number;
  moneyTypeName: string;
  transactionAmount: number;
  transactionDate: string;
  transactionType: string;
  transactionId: number;
}

export interface ContributionSummary {
  accountId: number;
  accountName: string;
  contributionsSinceInception: number | null;
  contributionsYTD: number;
  currentBalance: number | null;
  currentVestedBalance: number;
  moneyTypeId: number;
  moneyTypeName: string;
}

export enum ContributionType {
  PERCENT = "PERCENT",
  DOLLAR = "DOLLAR",
  OTHER = "OTHER",
  NO_DEFERRAL = "NO_DEFERRAL",
}

export type ContributionRateId = {
  empId: number;
  planId: number;

  contributionTypeId: number;
  effectiveDate: string;
};
