import { RedeferralDateInput } from "../../../components/redefferal/redeferral-date-input/redeferral-date-input";
import React from "react";
import { ScheduledDistributionForRedeferral } from "../../../models/distributions/scheduled-distribution-event";
import { useAppDispatch, useSelector } from "../../../store/store";
import { RedeferralDateUtil } from "../../../components/redefferal/redeferral-date-input/redeferral-date-util";
import Date from "../../../components/numbers/date";
import { userFacingDate } from "../../../util/date-util";
import { updateScheduledDistribution } from "../../../reducers/redeferral/scheduledDistributionForRedeferral";
import RedeferralCheckbox from "../../../components/redefferal/redeferral-checkbox/redeferral-checkbox";

export interface Props {
  scheduledDistribution: ScheduledDistributionForRedeferral;
  editable: boolean;
}

export default function RedeferTableRow(props: Props) {
  const { scheduledDistribution, editable } = props;
  const marketCloseDates = useSelector(
    (state) => state.redeferrals.marketCloseDates.marketCloseDates
  );
  const redeferralDateUtil: RedeferralDateUtil = new RedeferralDateUtil(
    scheduledDistribution,
    marketCloseDates
  );
  const dispatch = useAppDispatch();

  const updateData = (fieldName: string, value: any) => {
    const update = {
      ...scheduledDistribution,
      [fieldName]: value,
    };
    dispatch(updateScheduledDistribution(update));
  };

  return (
    <tr key={scheduledDistribution.installNum}>
      <BuildTableRow>{scheduledDistribution.installNum}</BuildTableRow>
      <BuildTableRow>
        <Date
          date={scheduledDistribution.scheduledDate}
          formatter={userFacingDate}
        />
      </BuildTableRow>
      <BuildTableRow>
        <Date
          date={scheduledDistribution.pendingDistributionDate}
          formatter={userFacingDate}
        />
      </BuildTableRow>
      <BuildTableRow>{scheduledDistribution.status}</BuildTableRow>
      <BuildTableRow>{scheduledDistribution.distributionAmount}</BuildTableRow>
      <BuildTableRow>
        <RedeferralCheckbox
          scheduledDistribution={scheduledDistribution}
          onChange={updateData}
          editable={editable}
        />
      </BuildTableRow>
      <BuildTableRow>
        <RedeferralDateInput
          isEnabled={scheduledDistribution.isRedeferred}
          onChange={(e) =>
            updateData("newDistributionDate", redeferralDateUtil.getValidDay(e))
          }
          redeferralDateUtil={redeferralDateUtil}
          editable={editable}
        />
      </BuildTableRow>
    </tr>
  );
}

export interface RowProps {
  children: React.ReactNode;
}

export function BuildTableRow(props: RowProps) {
  const { children } = props;
  return <td>{children}</td>;
}
