export interface GeneralOption {
  name: string;
  value: any;
  disabled: boolean;
}

export function buildOption(
  name: string,
  value: any,
  disabled: boolean
): GeneralOption {
  return {
    name,
    value,
    disabled,
  };
}
