import React from "react";
import { OnlineBeneficiaryPlan } from "../../../models/beneficiaries/beneficiary";
import { BeneficiaryState } from "../../../reducers/beneficiary-reducer";
import { sessionSelector } from "../../../selectors/session";
import { useSelector } from "../../../store/store";
import { AdditionalPlanSelect } from "./review/beneficiary-plan-checkboxes";
import "./beneficiary-plans.scss";

type Props = {
  register: any;
  watch: any;
};

export default function BeneficiaryPlans(props: Props) {
  const { register, watch } = props;
  const { onlineBeneficiaryPlans } = useSelector<BeneficiaryState>(
    (state) => state.beneficiaries
  );
  const { planId } = useSelector(sessionSelector);
  const allowedBeneficiaryPlans = onlineBeneficiaryPlans.filter(
    (plan) => plan.allowOnlineBeneficiaryChanges && plan.id !== planId
  );
  const hasAllowedPlans = allowedBeneficiaryPlans.length > 0;

  const unavailablePlans = onlineBeneficiaryPlans.filter(
    (plan) => !plan.allowOnlineBeneficiaryChanges
  );
  const hasUnavailablePlans = unavailablePlans.length > 0;

  return (
    <div className={"beneficiary-plans"}>
      {hasAllowedPlans && (
        <AdditionalPlanSelect
          plans={allowedBeneficiaryPlans}
          register={register}
          watch={watch}
        />
      )}
      {hasUnavailablePlans && (
        <UnavailablePlansDisplay plans={unavailablePlans} />
      )}
    </div>
  );
}

export type BeneficiaryPlanProps = {
  plans: OnlineBeneficiaryPlan[];
  register?: any;
  watch?: any;
};

function UnavailablePlansDisplay(props: BeneficiaryPlanProps) {
  const { plans } = props;
  return (
    <>
      <h4>These plans are unavailable for online beneficiary changes:</h4>
      <ul>
        {plans.map((plan) => {
          const { id, name } = plan;
          return <li key={id}>{name}</li>;
        })}
      </ul>
    </>
  );
}
