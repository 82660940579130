export function addDescriptionsToAssetClasses(assetClasses, descriptions) {
  return assetClasses.map((assetClass) => {
    const assetDescription = descriptions[assetClass.classId];
    const assetClassWithDescription = {
      ...assetClass,
      description: assetDescription,
    };
    return assetClassWithDescription;
  });
}

// Real Estate(7) and Employee Securities(9) are both very uncommon asset classes to have for an NQ plan, so are being filtered out.
export function filterAssetClasses(assetClasses) {
  return assetClasses.filter((assetClass) => {
    const isNotRealEstate = assetClass.classId !== 7;
    const isNotEmployeeSecurities = assetClass.classId !== 9;
    const isAValidAssetIf = isNotRealEstate && isNotEmployeeSecurities;
    return isAValidAssetIf;
  });
}
