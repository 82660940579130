import { PaymentPendingAccountInfo } from "../models/stream-of-payment-pending/payment-pending-account-info";
import { get } from "../repositories/participant";

export async function getStreamOfPaymentPendingAccountInfo(
  planId: number,
  empId: number,
  accountId: number,
  role: string
): Promise<PaymentPendingAccountInfo> {
  return get(
    `/plans/${planId}/employees/${empId}/payment-pending-account-info`,
    { role, accountId }
  );
}
