import React from "react";
import { useToggle } from "react-use";
import classnames from "classnames";
import "./collapsible.scss";

export interface Props {
  text: string;
  dateYear: number;
  endDate: string;
}

export const CollapseEnrollment = (props: Props) => {
  const { text } = props;
  const [isOn, toggleIsOn] = useToggle(false);
  const showMoreLess = isOn ? "Show less" : "Show more";
  const iconClass = classnames(["fa", isOn ? "fa-angle-up" : "fa-angle-down"]);

  return (
    <div className="text-center">
      {collapsedText(text, isOn, props.endDate, props.dateYear)}
      <div
        style={{
          position: "absolute",
          left: "40%",
          transform: "translateX(-50%)",
          marginTop: "-.5%",
        }}
      >
        <button
          className="btn btn-link btn-link-inline text-muted"
          onClick={toggleIsOn}
        >
          <small className="show-more-less" style={{ color: "#0076CF" }}>
            <span style={{ verticalAlign: "middle" }}>{showMoreLess} </span>
            <i
              style={{ color: "#0076CF", verticalAlign: "middle" }}
              className={iconClass}
              aria-hidden="true"
            ></i>
          </small>
        </button>
      </div>
    </div>
  );
};

function collapsedText(
  text: string,
  show: boolean,
  formattedEndDate: string,
  dateYear: number
) {
  return show ? (
    <div className="util-padding-top-20">
      <p
        className="pds-typography-body-14"
        style={{ textAlign: "left", paddingRight: "10%" }}
      >
        Now is the time to make your elections for the {dateYear + 1} plan year.
        All elections must be completed and submitted no later than{" "}
        {formattedEndDate}. If you are an existing participant in the plan and
        do not take any action, your {dateYear} elections will remain in force.
      </p>
      <p
        className="pds-typography-body-14"
        style={{ textAlign: "left", paddingRight: "10%" }}
      >
        Please refer to the plan contacts or educational resources if you need
        any additional information on this important benefit or assistance
        completing the process.
      </p>
    </div>
  ) : (
    <></>
  );
}
