import React from "react";
import { Styles } from "../types/styles";
import Tick from "./tick";
import { createDateRange, calculateY } from "../util";

type AxisProps = {
  maxDate: Date;
  minDate: Date;
  styles: Styles;
  scale: Function;
  width: number;
};

export function Axis({ minDate, maxDate, styles, scale, width }: AxisProps) {
  const axisY = calculateY(styles, styles.axis.y);
  const minYear: number = minDate.getFullYear();
  const maxYear: number = maxDate.getFullYear();
  const years: Date[] = createDateRange(minYear, maxYear);

  return (
    <g className="axis-group">
      <line x1={0} x2={width} y1={axisY} y2={axisY} className="x-axis" />
      <text className="label" x={0} y={axisY + 12}>
        Years
      </text>
      {years.map((year) => (
        <Tick
          key={year.getFullYear()}
          date={year}
          x={scale(year)}
          styles={styles}
        />
      ))}
    </g>
  );
}
