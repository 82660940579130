import {
  Beneficiary,
  BeneficiaryType,
  OnlineBeneficiaryPlan,
} from "../../models/beneficiaries/beneficiary";
import _mapValues from "lodash/mapValues";
import _keyBy from "lodash/keyBy";
import _find from "lodash/find";

export function buildDefaultOnlineBeneficiaryPlans(plans: any) {
  return plans.map((plan: any) => {
    const { id, planName, allowOnlineBeneficiaryChanges } = plan;
    return {
      id,
      name: planName,
      selected: false,
      allowOnlineBeneficiaryChanges,
    };
  });
}

export function mapSelectedPlans(
  selectedPlans: any,
  plans: OnlineBeneficiaryPlan[]
) {
  return plans.map((plan) => {
    return { ...plan, selected: selectedPlans[plan.id] };
  });
}

export function mapPlansById(plans: OnlineBeneficiaryPlan[]) {
  return _mapValues(_keyBy(plans, "id"), "selected");
}

export function getSelectedPlans(plans: OnlineBeneficiaryPlan[]) {
  return plans.filter((plan: OnlineBeneficiaryPlan) => plan.selected);
}

export function getPlanIds(plans: OnlineBeneficiaryPlan[]) {
  return plans.map((plan) => plan.id);
}

export function hasValidAllocations(beneficiaries: Beneficiary[]): boolean {
  const primaryBeneficiaries = getPrimaryBeneficiaries(beneficiaries);
  const contingentBeneficiaries = getContingentBeneficiaries(beneficiaries);
  return (
    hasValidAllocation(primaryBeneficiaries) &&
    hasValidAllocation(contingentBeneficiaries)
  );
}

export function hasZeroPercentAllocations(
  beneficiaries: Beneficiary[]
): boolean {
  const primaryBeneficiaries = getPrimaryBeneficiaries(beneficiaries);
  const contingentBeneficiaries = getContingentBeneficiaries(beneficiaries);
  return (
    hasZeroPercentAllocation(primaryBeneficiaries) ||
    hasZeroPercentAllocation(contingentBeneficiaries)
  );
}

export function validateBeneficiaryTypes(
  beneficiaries: Beneficiary[]
): boolean {
  const primaryBeneficiaries = getPrimaryBeneficiaries(beneficiaries);
  const contingentBeneficiaries = getContingentBeneficiaries(beneficiaries);
  const hasPrimary = primaryBeneficiaries.length > 0;
  const hasContingent = contingentBeneficiaries.length > 0;
  const hasContingentWithoutPrimary = !hasPrimary && hasContingent;
  return hasPrimary && !hasContingentWithoutPrimary;
}

export function beneficiariesAreDesignated(
  beneficiaries: Beneficiary[]
): boolean {
  const primaryBeneficiaries = getPrimaryBeneficiaries(beneficiaries);
  const contingentBeneficiaries = getContingentBeneficiaries(beneficiaries);
  const hasPrimary = primaryBeneficiaries.length > 0;
  const hasContingent = contingentBeneficiaries.length > 0;
  return hasPrimary || hasContingent;
}

export function determineIfPlanAllowsBeneficiaryChanges(
  id: number,
  plans: OnlineBeneficiaryPlan[]
): boolean {
  const plan = _find(plans, ["id", id]);
  return Boolean(plan && plan.allowOnlineBeneficiaryChanges);
}

function hasValidAllocation(beneficiaries: Beneficiary[]): boolean {
  if (beneficiaries.length === 0) {
    return true;
  }
  let total = 0;
  beneficiaries.map((beneficiary) => {
    if (beneficiary.allocationAmount) {
      total += beneficiary.allocationAmount;
    } else {
      total += 0;
    }
  });
  total = Math.round(total * 100) / 100;
  return total === 100;
}

function hasZeroPercentAllocation(beneficiaries: Beneficiary[]): boolean {
  if (beneficiaries.length === 0) {
    return false;
  }
  for (let i = 0; i < beneficiaries.length; i++) {
    if (!beneficiaries[i].allocationAmount) {
      return true;
    }
  }
  return false;
}

export function getBeneficiariesToUse(
  beneficiaries: Beneficiary[],
  isPrimary: boolean
) {
  return isPrimary
    ? getPrimaryBeneficiaries(beneficiaries)
    : getContingentBeneficiaries(beneficiaries);
}

export function getPrimaryBeneficiaries(
  beneficiaries: Beneficiary[]
): Beneficiary[] {
  return beneficiaries.filter(
    (beneficiary) =>
      beneficiary.beneficiaryInfo.type === BeneficiaryType.PRIMARY
  );
}

export function getContingentBeneficiaries(
  beneficiaries: Beneficiary[]
): Beneficiary[] {
  return beneficiaries.filter(
    (beneficiary) =>
      beneficiary.beneficiaryInfo.type === BeneficiaryType.CONTINGENT
  );
}

export function participantHasMultipleOnlinePlans(
  planId: number,
  planList: OnlineBeneficiaryPlan[]
) {
  if (planList.length < 1) {
    return false;
  }
  if (planList.length > 1) {
    return true;
  }
  return planId !== planList[0].id;
}
//bump commit NQENG-59
