import React from "react";
import {
  ContributionRates,
  ContributionType,
  PendingContributionRate,
} from "../../../../models/contributions";
import {
  PlanCode,
  PlanInfoModel,
} from "../../../../models/plan/plan-info-model";

type CellInfo = {
  value?: any;
};

export type DeferralElectionsDisplay = {
  contributionTypeName: string;
  currentDeferralElection: string;
  effectiveDate: string;
};

export type PendingDeferralElectionsDisplay = {
  pendingDeferralElection: string;
};

export function buildContributionType() {
  const Header = () => (
    <span className="left-align header">Contribution Type</span>
  );
  return {
    Header,
    accessor: "contributionTypeName",
    disableSortBy: false,
    className: "left-align",
    Cell: (info: CellInfo) => {
      const { value } = info;
      if (!value) {
        return "";
      }
      return value;
    },
  };
}

export function buildCurrentDeferralElectionsColumn() {
  const Header = () => (
    <span className="left-align header">
      Current Deferral Elections{" "}
      <sup>
        [
        <a href="#[1]">
          <span className="sr-only">Footnote&nbsp;</span>1
        </a>
        ]
      </sup>
    </span>
  );

  return {
    Header,
    accessor: "currentDeferralElection",
    disableSortBy: false,
    className: "left-align",
    Cell: (info: CellInfo) => {
      const { value } = info;
      if (!value) {
        return "";
      }
      return value;
    },
  };
}

export function buildNewDeferralElectionsColumn() {
  const Header = () => (
    <span className="left-align header">
      New Deferral Elections{" "}
      <sup>
        [
        <a href="#[1]">
          <span className="sr-only">Footnote&nbsp;</span>1
        </a>
        ]
      </sup>
    </span>
  );

  return {
    Header,
    accessor: "pendingDeferralElection",
    disableSortBy: false,
    className: "left-align",
    Cell: (info: CellInfo) => {
      const { value } = info;
      if (!value) {
        return "";
      }
      return value;
    },
  };
}

export function buildEffectiveDateColumn() {
  const Header = () => (
    <span className="left-align header">Effective Date</span>
  );

  return {
    Header,
    accessor: "effectiveDate",
    disableSortBy: false,
    className: "left-align",
    Cell: (info: CellInfo) => {
      const { value } = info;
      if (!value) {
        return "";
      }
      return value;
    },
  };
}

export function createPendingDeferralElectionDisplay(
  contributionRates: PendingContributionRate[]
): PendingDeferralElectionsDisplay[] {
  return contributionRates.map((contributionRate) => {
    const {
      contributionAmount,
      contributionOtherText,
      contributionElectionTypeId,
    } = contributionRate;
    const hasAmount = !isEmpty(contributionAmount);
    const contributionValue = hasAmount
      ? contributionAmount
      : contributionOtherText;
    const pendingDeferralElection = buildPendingDeferralElection(
      contributionElectionTypeId,
      contributionValue
    );
    return {
      pendingDeferralElection,
    };
  });
}

export function createDeferralElectionDisplay(
  contributionRates: ContributionRates[]
): DeferralElectionsDisplay[] {
  return contributionRates.map((contributionRate) => {
    const {
      contributionTypeName,
      contributionElectionType,
      contributionAmount,
      otherText,
      effectiveDate,
    } = contributionRate;
    const hasAmount = !isEmpty(contributionAmount);
    const contributionValue = hasAmount ? contributionAmount : otherText;
    const currentDeferralElection = buildCurrentDeferralElection(
      contributionElectionType,
      contributionValue
    );
    return {
      contributionTypeName,
      currentDeferralElection,
      effectiveDate,
    };
  });
}

function isEmpty(e: any) {
  return e === undefined || e === null;
}

export function buildCurrentDeferralElection(
  contributionElectionType: string,
  contributionValue: number | string | undefined
): string {
  const contributionAmount =
    typeof contributionValue === "number"
      ? contributionValue.toFixed(2)
      : contributionValue;
  switch (contributionElectionType) {
    case ContributionType.PERCENT:
      return `${contributionAmount}% per payment`;
    case ContributionType.DOLLAR:
      return `$${contributionAmount} per payment`;
    case ContributionType.NO_DEFERRAL:
      return "No deferral";
    default:
      if (typeof contributionAmount === "string") {
        return contributionAmount;
      }
      return "";
  }
}

export function buildPendingDeferralElection(
  contributionElectionTypeId: number,
  contributionValue: number | string | undefined
): string {
  const contributionAmount =
    typeof contributionValue === "number"
      ? contributionValue.toFixed(2)
      : contributionValue;
  switch (contributionElectionTypeId) {
    case 1:
      return `${contributionAmount}% per payment`;
    case 2:
      return `$${contributionAmount} per payment`;
    case 4:
      return "No deferral";
    default:
      if (typeof contributionAmount === "string") {
        return contributionAmount;
      }
      return "";
  }
}

export function deferralButtonCheck(
  planInfo: PlanInfoModel,
  pendingContributions: PendingContributionRate[]
) {
  return (
    planInfo.allowContinuousEnrollment &&
    planInfo.planCode.toLowerCase() === PlanCode.type457b &&
    pendingContributions.length === 0
  );
}

export function pendingContributionCheck(
  planInfo: PlanInfoModel,
  pendingContributions: PendingContributionRate[]
) {
  return (
    planInfo.allowContinuousEnrollment &&
    planInfo.planCode.toLowerCase() === PlanCode.type457b &&
    pendingContributions.length > 0
  );
}
