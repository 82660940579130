import { property } from 'lit/decorators.js';
import { pdsCustomElement as customElement } from '../../decorators/pds-custom-element';
import { PdsLink } from '../link/link';
import styles from './global-header-dropdown-link.scss?inline';

/**
 * @summary This component is a link that is only meant to be utilized within the global header dropdown item. It
 * should have all the functionalities of a normal PDSLink but is styled differently.
 *
 * @slot icon-left Optional: This slot holds an icon to the left of the link, restricted to pds-icon.
 * @slot default Required: This slot holds the link contents.
 * @slot icon-right Optional: This slot holds an icon to the right of the link, restricted to pds-icon.
 *
 * @see PdsLink
 */
@customElement('pds-global-header-dropdown-link', {
  category: 'component',
  type: 'component',
  styles,
})
export class PdsGlobalHeaderDropdownLink extends PdsLink {
  @property()
  declare variant: 'default';

  // Need this property to be default 'sm', so that the global-header-dropdown-link icons are set to small
  @property()
  size: 'sm' = 'sm';

  /**
   * Whether or not to display the link as bold text
   */
  @property({ type: Boolean })
  bold: boolean = false;

  /**
   * @internal
   */
  get classNames() {
    return {
      // @ts-ignore Lit is perfectly fine with this, but TS somehow throws an error
      ...super.classNames,
      [this.variant]: !!this.variant,
      bold: !!this.bold,
    };
  }

  firstUpdated(): void {
    super.firstUpdated();
    // Catch the pds-link-click event on the link and dispatch a new event
    this.addEventListener('pds-link-click', (e: Event) => {
      e.stopPropagation();
      const customEvent = e as CustomEvent;

      this.dispatchEvent(
        new CustomEvent('pds-global-header-dropdown-link-click', {
          bubbles: true,
          composed: true,
          detail: customEvent.detail,
        }),
      );
    });
  }
}
