import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import EnrollmentToolsPage from "../pages/enrollment-center/enrollment-tools-page";
import EstimatedBenefitCalculatorPage from "../pages/enrollment-center/estimated-benefit-calculator-page";
import EnrollmentCenterRevamp from "../pages/enrollment-center-revamp/EnrollmentCenterRevamp";

export default function EnrollmentRoutes() {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.url}/tools`}>
        <EnrollmentToolsPage />
      </Route>
      <Route path={`${match.url}/estimated-benefit-calculator`}>
        <EstimatedBenefitCalculatorPage />
      </Route>
      <Route path={`${match.url}/enrollment-center`}>
        <EnrollmentCenterRevamp />
      </Route>
    </Switch>
  );
}
