export function capitalizeFirstLetter(value: string) {
  return value.charAt(0).toUpperCase() + value.slice(1);
}

export function addStringPadding(
  value: string | number,
  expectedLength: number
): string {
  let paddedString = value.toString();
  while (paddedString.length < expectedLength) {
    paddedString = "0" + paddedString;
  }
  return paddedString;
}

export function replaceUnderscoresWithSpaces(value: string) {
  return value.replace(/_/gi, " ");
}

export function displayValueNotOnFile(value: any): string {
  if (!value || value === "") {
    return "Not on file";
  }
  return value.toString();
}
