import { Account } from "../models/account";
import { AccountBalanceByDate } from "../models/account-balances-by-date";
import { get } from "../repositories/participant";
import { formatDateWithoutTimeZone } from "../util/date-util";

type Args = {
  planId: number;
  empId: number;
  role: string;
};

export async function getAccounts({
  planId,
  empId,
  role,
}: Args): Promise<Account[]> {
  return get(`/employees/${empId}/plans/${planId}/v2/accounts`, {
    role,
  });
}

export type GetAccountBalancesArgs = {
  empId: number;
  planId: number;
  role: string;
  startDate?: string;
  endDate?: string;
};

class GetAccountBalancesParams {
  role: string;
  startDate?: string;
  endDate?: string;

  constructor(args: GetAccountBalancesArgs) {
    this.role = args.role;
    if (args.startDate) {
      this.startDate = formatDateWithoutTimeZone(args.startDate);
    }
    if (args.endDate) {
      this.endDate = formatDateWithoutTimeZone(args.endDate);
    }
  }
}

export async function getAccountBalancesByDate(
  args: GetAccountBalancesArgs
): Promise<AccountBalanceByDate[]> {
  const params: GetAccountBalancesParams = new GetAccountBalancesParams(args);

  if (params.startDate !== "N/A" && params.endDate !== "N/A") {
    return get(
      `/employees/${args.empId}/plans/${args.planId}/accounts/balances`,
      params
    );
  } else {
    return [];
  }
}
