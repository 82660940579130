import _round from "lodash/round";

export const asPercent = (val, precision = 2) => {
  const stringAsPercent = _round(100 * val, [precision]).toFixed(precision);
  return parseFloat(stringAsPercent);
};

export const asGrowthRatio = (start, end) => {
  return (end - start) / start;
};

export const asGrowth = (percent, precision = 2) => {
  const stringGrowth = _round(percent - 100, [precision]).toFixed(precision);
  return parseFloat(stringGrowth);
};

export const round = (val, precision = 2) => {
  return _round(val, precision).toFixed(precision);
};

export const asPercentOf = (numerator, denominator, precision = 2) => {
  const stringPercentOf = asPercent(numerator / denominator, precision);
  return parseFloat(stringPercentOf);
};
