export enum OverviewRoutes {
  PlanOverview = "/overview/plan-overview",
  BalancesByAccount = "/overview/account-info",
  Dashboard = "/overview/dashboard",
  PersonalDetails = "/overview/personal-details",
  PlanStatements = "/overview/plan-statements",
  OverviewBenefit = "/overview/overview-benefit",
  PlanInfoAndSummary = "/overview/plan-info-summary",
  BeneficiaryReview = "/overview/beneficiaries/review",
  BeneficiaryPlanSelection = "/overview/beneficiaries/plan-selection",
  DefinedBenefitAbout = "/overview/defined-benefit/about",
}

export enum NoContextRoutes {
  AccountsLanding = "/accounts-landing",
}

export enum ContributionRoutes {
  AccountAllocations = "/contributions/account-allocations",
  Summary = "/contributions/summary",
  Review = "/contributions/manage-contributions/review",
  ManageContributions = "/contributions/manage-contributions",
  BalancesBySource = "/contributions/balances-by-source",
}

export enum DistributionRoutes {
  Summary = "/distributions/summary",
  ScheduledDistributions = "/distributions/scheduled",
  RedeferIndividual = "/distributions/redefer/individual",
  RedeferIndividualReview = "/distributions/redefer/individual/review",
  RedeferIndividualConfirm = "/distributions/redefer/individual/confirm",
  PaymentPending = "/distributions/redeferral/streamOfPaymentPending",
  RedeferralsSummary = "/distributions/redeferral/summary",
  RedeferralsForAccount = "/distributions/redeferral/account",
  RedeferralBegin = "/distributions/redeferral/begin/account",
  RedeferralReview = "/distributions/redeferral/review/account",
  RedeferralConfirm = "/distributions/redeferral/confirm/account",
}

export enum InvestmentRoutes {
  Manage = "/investments/manage",
  Research = "/investments/research",
  Summary = "/investments/summary",
  IndividualInvestment = "/investments/individual-investment",
  InvestmentAllocations = "/investments/investment-allocations",
  Performance = "/investments/performance",
  RateOfReturn = "/investments/rate-of-return",
}

export enum EnrollmentRoutes {
  Tools = "/enrollmentcenter/tools",
  EstimatedBenefitCalculator = "/enrollmentcenter/estimated-benefit-calculator",
}

export enum PlanningCenterOverviewRoutes {
  PlanningCenter = "/planningResources/planning-center",
}
