import React from "react";
import {
  Accordion,
  AccordionDropdown,
  AccordionWell,
} from "../../../components/accordion/accordion";
import {
  ContributionRates,
  ElectionOption,
} from "../../../models/contributions";
import ContributionInteraction from "./contribution-interaction";

type Props = {
  index: number;
  compensationInfo: ContributionRates;
  register: (options: any) => React.Ref<any>;
  errors: any;
  setValue: Function;
  compensationType: string;
  increaseErrorCount: any;
  decreaseErrorCount: any;
};

export default function ContributionsAccordion(props: Props) {
  const {
    index,
    compensationInfo,
    register,
    errors,
    setValue,
    compensationType,
    increaseErrorCount,
    decreaseErrorCount,
  } = props;
  const { contributionTypeName, availableElections } = compensationInfo;

  return (
    <Accordion id={index}>
      <AccordionDropdown
        id={index}
        name={<span>{contributionTypeName}</span>}
      />
      <AccordionWell
        id={index}
        classNames={["well", "contribution-interaction"]}
      >
        <ContributionInteraction
          compensationName={contributionTypeName}
          compensationType={compensationType}
          availableElections={availableElections}
          setValue={setValue}
          register={register}
          errors={errors}
          increaseErrorCount={increaseErrorCount}
          decreaseErrorCount={decreaseErrorCount}
        />
      </AccordionWell>
    </Accordion>
  );
}

export function findValueTypeSelected(
  availableElections: ElectionOption[],
  contributionElectionType: string
) {
  return availableElections.filter((election) => {
    return election.electionType === contributionElectionType;
  });
}
