import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { Page, PageHeader } from "../../../components/page";
import { AsyncStatus } from "../../../models/async-status";
import {
  BeneficiaryState,
  fetchBeneficiaries,
  fetchOnlineBeneficiaryPlans,
  setIsEditable,
} from "../../../reducers/beneficiary-reducer";
import {
  accountsStatusSelector,
  beneficiariesStatus,
  beneficiaryPlansStatus,
  planInfoStatusSelector,
  privilegesStatus,
  sessionStatusSelector,
  useStatuses,
} from "../../../selectors/status";
import { useAppDispatch, useSelector } from "../../../store/store";
import { BeneficiariesDisclaimerDisplay } from "./beneficiaries-disclaimer-display";
import { BeneficiariesDisplay } from "./beneficiaries-display";
import { BeneficiariesInfoTextDisplay } from "./beneficiaries-info-text-display";
import "./beneficiaries.scss";

export default function BeneficiariesWrapper() {
  const { planId, empId, role } = useSelector((state) => state.session);
  const { beneficiaryStatus, plansStatus, onlineBeneficiaryPlans } =
    useSelector<BeneficiaryState>((state) => state.beneficiaries);
  const { advisorAccessToPlanConfidential } = useSelector(
    (state) => state.privileges.data
  );
  const { isAdvisor } = useSelector((state) => state.session);
  const { isLoadingStatus } = useStatuses([
    sessionStatusSelector,
    planInfoStatusSelector,
    accountsStatusSelector,
    beneficiariesStatus,
    beneficiaryPlansStatus,
    privilegesStatus,
  ]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (beneficiaryStatus === AsyncStatus.not_fetched) {
      dispatch(fetchBeneficiaries({ planId, empId, role }));
    }
  }, [dispatch, beneficiaryStatus, planId, empId, role]);

  useEffect(() => {
    dispatch(setIsEditable(planId));
  }, [onlineBeneficiaryPlans, dispatch, planId]);

  useEffect(() => {
    if (plansStatus === AsyncStatus.not_fetched) {
      dispatch(
        fetchOnlineBeneficiaryPlans({
          empId,
          role,
          filter: "allowOnlineBeneficiaryChanges",
        })
      );
    }
  }, [dispatch, plansStatus, empId, role]);

  if (!advisorAccessToPlanConfidential && isAdvisor) {
    return (
      <Redirect
        to={{
          pathname: "/overview/dashboard",
          state: {
            planId: { planId },
            empId: { empId },
            role: "ADVISOR",
            parentEid: "",
          },
        }}
      />
    );
  } else {
    return (
      <Page
        printable
        name="beneficiaries"
        loading={isLoadingStatus}
        className="beneficiaries"
      >
        <PageHeader name="beneficiaries">Beneficiaries</PageHeader>
        <div className={"row"}>
          <div className={"col-md-9"}>
            <BeneficiariesInfoTextDisplay />
            <BeneficiariesDisplay />
            <BeneficiariesDisclaimerDisplay />
          </div>
        </div>
      </Page>
    );
  }
}
