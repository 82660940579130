import { PlanType } from "../models/ParticipantEvent";

function determineApiHost() {
  if (process.env.NODE_ENV === "test") {
    return "http://api.test.com";
  }
  if (isEnv("pilot") || isEnv("local")) {
    return "https://api.pilot.principal.com";
  }
  return "https://api.principal.com";
}

function determineIsPilotOrTest() {
  return (
    process.env.NODE_ENV === "test" || isEnv("pilot") || isEnv("localhost")
  );
}

function determineTokenHost() {
  if (process.env.NODE_ENV === "test") {
    return "http://api.test.com";
  }
  if (isEnv("pilot") || isEnv("local")) {
    return "https://api.pilot.principal.com";
  }
  return "https://api.principal.com";
}

function determineJSFHost() {
  if (isEnv("test")) {
    return "secure05.test.principal.com";
  }
  if (isEnv("pilot") || isEnv("local")) {
    return "secure05.pilot.principal.com";
  }
  return "secure05.principal.com";
}

function determineJSFDomain() {
  if (process.env.NODE_ENV === "test") {
    return "https://secure05.test.principal.com/";
  }
  if (isEnv("pilot")) {
    return "https://secure05.pilot.principal.com/";
  }
  if (isEnv("local")) {
    return "https://localhost.staging.principal.com:443/";
  }
  return "https://secure05.principal.com/";
}

function determineLoginHost() {
  if (process.env.NODE_ENV === "test" || isEnv("pilot") || isEnv("local")) {
    return "https://login.pilot.principal.com/login/";
  }
  return "https://login.principal.com/login/";
}

function determineDrupalHost() {
  if (process.env.NODE_ENV === "test") {
    return "https://content.ris.test.principal.com";
  }
  if (isEnv("pilot")) {
    //return "https://content.ris.pilot.principal.com";
    return "https://api.pilot.theprincipal.net";
  }
  if (isEnv("local")) {
    return "http://localhost:443";
  }
  return "https://content.ris.principal.com";
}

function determineCustomEnrollmentHost() {
  if (process.env.NODE_ENV === "test") {
    return "https://nq.individuals.test.principal.com/";
  }
  if (isEnv("pilot")) {
    return "https://nq.individuals.pilot.principal.com/";
  }
  if (isEnv("local")) {
    return "http://localhost:443/";
  }
  return "https://nq.individuals.principal.com/";
}

function isEnv(env) {
  return window.location.host.includes(env);
}

export const apiHost = determineApiHost();
export const isPilotOrTest = determineIsPilotOrTest();
export const drupalHost = determineDrupalHost();
export const tokenHost = determineTokenHost();
export const jsfDomain = determineJSFDomain();
export const jsfHost = determineJSFHost();
export const loginPath = determineLoginHost();
export const customEnrollmentHost = determineCustomEnrollmentHost();

export const enterpriseApiKey = "RNQITRNQPARTICIP";
export const securityTokenPath =
  "/rnq/services/tokenservice/authorize/nq-participant";
export const loggingEndpoint = "/rnq/services/enrollment/logger/";
export const logoutPath = jsfDomain + "https://login.pilot.principal.com/login";
export const NOT_AVAILABLE = "Not available";
export const individualInvestmentPerformanceLink = (investmentId) => {
  return `/investments/individual-investment/${investmentId}`;
};

export const DISTRIBUTION_REDEFERRAL_DISCLAIMER = `
    Your account balance represents a measure of your benefit under 
    the nonqualified deferred compensation plan and is an unfunded, 
    contractual obligation from your employer.
    It is not a representation that your employer holds a particular 
    asset for funding this obligation.
    This amount may change based on future contributions, market fluctuations, and any
    applicable vesting schedules.`;

// quarterly performance type is linked to "B" in the cperftype field
export const performanceType = {
  monthly: "M",
  quarterly: "B",
};
export const investmentAdvisorLink =
  "/nq-participant/public/investment-advisor";
export const enrollmentLink = (eventId, empId, role) =>
  `${jsfDomain}rnqEnrollment/?eventId=${eventId}&empId=${empId}&role=${role}`;

export const customEnrollmentLink = (eventId, empId, planId) =>
  `${customEnrollmentHost}custom-enrollment/?eventId=${eventId}&empId=${empId}&planId=${planId}`;

export const customPlanInfoLink = (planId, empId) =>
  `${jsfDomain}nq-participant/overview/plan-info-summary?planId=${planId}&empId=${empId}`;

export const planningResourcesLink = (planId, empId, planType) => {
  if (isEnv("pilot") || isEnv("local")) {
    if (planType === PlanType.EXCESS_PLAN) {
      return `https://www.pilot.principal.com/nqlearn?planId=${planId}&empId=${empId}`;
    } else {
      return `https://www.pilot.principal.com/nqlearn457?planId=${planId}&empId=${empId}`;
    }
  } else {
    if (planType === PlanType.EXCESS_PLAN) {
      return `https://www.principal.com/nqlearn?planId=${planId}&empId=${empId}`;
    } else {
      return `https://www.principal.com/nqlearn457?planId=${planId}&empId=${empId}`;
    }
  }
};
