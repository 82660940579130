import React, { useState, useRef } from "react";
import "./InfoCircle.scss";

const InfoCircle = () => {
  const [showInfo, setShowInfo] = useState(false);
  const buttonRef = useRef(null);

  const overlayStyle = {
    position: "absolute",
    top: buttonRef.current
      ? buttonRef.current.offsetTop + buttonRef.current.offsetHeight + 10
      : 0,
    left: buttonRef.current ? buttonRef.current.offsetLeft - 155 : 0,
    width: "330px",
    height: "70px",
    backgroundColor: "#464646",
    borderRadius: "5px",
    display: showInfo ? "block" : "none",
    padding: "10px",
    color: "white",
    textAlign: "center",
  };

  const handleMouseEnter = () => {
    setShowInfo(true);
  };

  const handleMouseLeave = () => {
    setShowInfo(false);
  };

  return (
    <div>
      <div
        className="circle-style"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        ref={buttonRef}
      >
        <span className="questionmark-style">?</span>
      </div>
      <div style={overlayStyle}>
        <div className="triangle-style" />
        Open events gives you more insight on whether you have any upcoming
        tasks for the plan year.
      </div>
    </div>
  );
};

export default InfoCircle;
