import classnames from "classnames";
import React from "react";

function ErrorWrapper(props) {
  const { showError, errorMessage, isValid } = props;

  const displayError = showError && !isValid;

  const classes = classnames([
    "form-group",
    {
      "has-error": displayError,
    },
  ]);

  return (
    <div className={classes}>
      {props.children}
      <ErrorMessage displayError={displayError} message={errorMessage} />
    </div>
  );
}

/**
 * @return {null}
 */
function ErrorMessage(props) {
  const { displayError, message } = props;
  if (!displayError) {
    return null;
  }

  return (
    <span className={"error-message"} data-testid="error-span">
      <i className="fa fa-exclamation-triangle" /> {message}
    </span>
  );
}

export default ErrorWrapper;
