import React from "react";
import { useDispatch } from "react-redux";
import { Navigation } from "@nq/participant-nav";
import "@nq/participant-nav/dist/index.css";
import SkipLink from "../skip-link/skip-link";
import { useLocation } from "react-router-dom";
import Rustle from "../misc/rustle";
import { getQueryStringParam } from "../../services/feature-flag-service";
import { isRustled } from "../../selectors/feature-flags";
import { setIsRustled } from "../../reducers/feature-flags-reducer";
import { FeatureParam } from "../../models/feature-flag";
import { useSelector } from "../../store/store";
import qs from "query-string";

function PlanHeader() {
  const dispatch = useDispatch();
  const { empId, planId, role } = useSelector((state) => state.session);
  const rustled = useSelector(isRustled);
  const query = window.location.search;
  const { disableNavbar } = qs.parse(query);

  const shouldLoadNavbar = !disableNavbar;
  const location = useLocation();
  React.useEffect(() => {
    dispatch(setIsRustled(getQueryStringParam(FeatureParam.RUSTLED)));
  }, [dispatch]);

  return (
    <>
      {rustled && <Rustle />}
      <SkipLink />
      {shouldLoadNavbar && (
        <Navigation
          key={location.pathname}
          getOwnSession={false}
          empId={empId}
          planId={planId}
          role={role}
        />
      )}
    </>
  );
}

export default PlanHeader;
