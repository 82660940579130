import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getDefinedBenefitAboutPageDescriptions } from "../../services/defined-benefit-about";
import { AsyncStatus } from "../../models/async-status";

export const fetchDefinedBenefitAbout = createAsyncThunk(
  "fetch-defined-benefit-about",
  async (
    { empId, planId, role }: { empId: number; planId: number; role: string },
    thunkApi
  ) => {
    return getDefinedBenefitAboutPageDescriptions(empId, planId, role);
  }
);

type State = {
  definedBenefitDescription1: string;
  definedBenefitDescription2: string;
  definedBenefitDescription3: string;
  status: AsyncStatus;
};

const initialState: State = {
  status: AsyncStatus.not_fetched,
  definedBenefitDescription1: "",
  definedBenefitDescription2: "",
  definedBenefitDescription3: "",
};

const definedBenefitAboutSlice = createSlice({
  name: "defined-benefit-about",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDefinedBenefitAbout.pending, (state, action) => {
      return { ...state, status: AsyncStatus.loading };
    });
    builder.addCase(fetchDefinedBenefitAbout.fulfilled, (state, action) => {
      return { ...state, ...action.payload, status: AsyncStatus.done };
    });
    builder.addCase(fetchDefinedBenefitAbout.rejected, (state, action) => {
      return {
        ...state,
        status: AsyncStatus.error,
      };
    });
    builder.addDefaultCase((state, action) => state);
  },
});

export default definedBenefitAboutSlice.reducer;
