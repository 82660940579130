import React from "react";

export default function StartDateErrorMessage() {
  return (
    <div
      className="alert alert-danger alert-icon"
      data-testid="invalid-startDate-error-message"
      role="alert"
    >
      End Date cannot be prior to Start Date.
    </div>
  );
}
