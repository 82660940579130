import React from "react";
import Skeleton from "react-loading-skeleton";
import { PTCDistribution } from "../../../models/distributions/ptc-tracker";
import { Address } from "../../../models/Employee";
import {
  participantDetailsStatus,
  ptcStatus,
  useStatuses,
} from "../../../selectors/status";
import { useSelector } from "../../../store/store";
import { getDateStringWithoutTimezoneFromDashDate } from "../../../util/date-util";
import {
  Accordion,
  AccordionDropdown,
  AccordionWell,
} from "../../accordion/accordion";
import { Modal } from "../../modal/modal";
import Balance from "../../numbers/balance";
import { Panel, PanelBody, PanelHeading } from "../../panel/panel";
import "./PTCTracker.scss";
import { PTCTrackerSteps } from "./PTCTrackerSteps";

export function PTCTracker() {
  const { isLoadingStatus } = useStatuses([
    ptcStatus,
    participantDetailsStatus,
  ]);
  const ptcTracker = useSelector(
    (state) => state.distributions.ptcTracker.ptcTracker
  );
  const address = useSelector((state) => state.participant.data.address);

  if (isLoadingStatus) {
    return (
      <Panel>
        {" "}
        <PanelHeading>
          <Skeleton count={1} />
        </PanelHeading>
        <PanelBody>
          <Skeleton count={4} />
        </PanelBody>
      </Panel>
    );
  }
  let { ptcDistributions } = ptcTracker;
  if (!ptcDistributions) {
    ptcDistributions = [];
  }

  if (ptcDistributions.length > 0) {
    return (
      <Panel>
        <PanelHeading noPrint>Distribution tracker</PanelHeading>
        <PanelBody>
          {ptcDistributions.map((distribution, index) => (
            <Distribution
              key={index}
              ptcDistribution={distribution}
              index={index}
              address={address}
            />
          ))}
        </PanelBody>
      </Panel>
    );
  }
  return null;
}

type DistributionProps = {
  ptcDistribution: PTCDistribution;
  index: number;
  address: Address;
};

function Distribution(props: DistributionProps) {
  const { ptcDistribution, index, address } = props;
  if (ptcDistribution.initiatedDate) {
    return (
      <div key={index} data-testid={"distribution-" + index}>
        <Accordion id={index}>
          <AccordionDropdown
            id={index}
            name={<InitiatedStep date={ptcDistribution.initiatedDate} />}
          />
        </Accordion>
        <AccordionWell id={index}>
          <ul className="list-group">
            <DateStep
              ptcDistribution={ptcDistribution}
              date={ptcDistribution.initiatedDate}
              stepName={PTCTrackerSteps.INITIATED}
              address={address}
            />
            <DateStep
              ptcDistribution={ptcDistribution}
              date={ptcDistribution.valuedDate}
              stepName={PTCTrackerSteps.VALUED}
              address={address}
            />
            <DateStep
              ptcDistribution={ptcDistribution}
              date={ptcDistribution.paymentSentDate}
              stepName={PTCTrackerSteps.SENT}
              address={address}
            />
            <DateStep
              ptcDistribution={ptcDistribution}
              date={ptcDistribution.paidDate}
              stepName={PTCTrackerSteps.PAID}
              address={address}
            />
          </ul>
          <div>
            Last distribution update received on:{" "}
            {getDateStringWithoutTimezoneFromDashDate(
              ptcDistribution.lastUpdateReceivedDate
            )}
          </div>
        </AccordionWell>
      </div>
    );
  }

  return null;
}

type InitiatedStepProps = {
  date: string;
};

function InitiatedStep(props: InitiatedStepProps) {
  const { date } = props;
  if (date) {
    return (
      <>
        Distribution {PTCTrackerSteps.INITIATED} on{" "}
        {getDateStringWithoutTimezoneFromDashDate(date)}
      </>
    );
  }

  return null;
}

type DateStepProps = {
  date: string | null;
  stepName: string;
  ptcDistribution: PTCDistribution;
  address: Address;
};

function DateStep(props: DateStepProps) {
  const { date, stepName, ptcDistribution, address } = props;
  if (date) {
    return (
      <li className="list-group-item" data-testid={stepName}>
        <div className="list-bullet-active">
          <i className="fa fa-check" aria-hidden="true" />
        </div>
        Distribution {stepName} on{" "}
        {getDateStringWithoutTimezoneFromDashDate(date)}
        {stepName === PTCTrackerSteps.PAID && (
          <DistributionMethod
            ptcDistribution={ptcDistribution}
            address={address}
          />
        )}
      </li>
    );
  }
  return null;
}

function DistributionMethod(props: ModalProps) {
  const { ptcDistribution, address } = props;
  return (
    <div className="distribution-method" data-testid="distribution-method">
      <div>
        Method:{" "}
        <DistributionModal
          ptcDistribution={ptcDistribution}
          address={address}
        />
      </div>
      <div>
        Gross:{" "}
        <Balance
          showDollar={true}
          value={ptcDistribution.grossDistributionAmount}
        />
      </div>
      <div>
        Net:{" "}
        <Balance
          showDollar={true}
          value={ptcDistribution.netDistributionAmount}
        />
      </div>
    </div>
  );
}

type ModalProps = {
  ptcDistribution: PTCDistribution;
  address: Address;
};

function DistributionModal(props: ModalProps) {
  const { ptcDistribution, address } = props;
  return (
    <Modal
      title="Distribution Information"
      linkText={ptcDistribution.paymentMethod}
    >
      <Panel>
        <PanelHeading>Destination</PanelHeading>
        <PanelBody>{address.fullAddress}</PanelBody>
      </Panel>
      <Panel>
        <PanelHeading>Tax information</PanelHeading>
        <PanelBody>
          <div>
            Federal Tax Withholding:{" "}
            <Balance
              showDollar={true}
              value={ptcDistribution.federalTaxWithHolding}
            />
          </div>
          State Tax Withholding:{" "}
          <Balance
            showDollar={true}
            value={ptcDistribution.stateTaxWithHolding}
          />
        </PanelBody>
      </Panel>
    </Modal>
  );
}
