import classNames from "classnames";
import React from "react";
import { AlertLevel } from "../../models/alert";

type Props = {
  alertLevel: AlertLevel;
  showAlert: boolean;
  message: string;
};

export default function Alert(props: Props) {
  const { alertLevel, showAlert, message } = props;
  const className = classNames("alert alert-icon", alertLevel);
  return (
    <>
      {showAlert && (
        <div
          className={className}
          role="alert"
          style={{
            wordWrap: "break-word",
            whiteSpace: "pre-wrap",
            wordBreak: "break-word",
          }}
        >
          {message}
        </div>
      )}
    </>
  );
}
