import isEqual from "date-fns/isEqual";
import React from "react";
import Balance from "../../../../components/numbers/balance";
import { Skeleton } from "../../../../components/skeleton/skeleton";
import { FutureDistributionTimelinePayment } from "../../../../models/distributions/distribution-payment";
import {
  investmentsStatusSelector,
  scheduledDistributionsStatus,
  scheduledElectionsStatus,
} from "../../../../selectors/status";
import { parseDate } from "../../../../util/date-util";
import "./scheduled-distribution-total.scss";

type Props = {
  scheduledDistributions: FutureDistributionTimelinePayment[];
  selectedDate: Date | undefined;
};
export default function ScheduledDistributionTotal(props: Props) {
  const { scheduledDistributions, selectedDate } = props;
  if (!selectedDate) {
    return null;
  }
  const filteredDistributionsBySelectedDate = scheduledDistributions.filter(
    (distribution) =>
      isEqual(
        parseDate(distribution.payoutDate).getFullYear(),
        selectedDate.getFullYear()
      )
  );
  const numPayments = filteredDistributionsBySelectedDate.length;
  const balance = filteredDistributionsBySelectedDate.reduce(function (
    prev,
    cur
  ) {
    const { value } = cur;
    return prev + (value || 0);
  },
  0);

  return (
    <div
      className="total-distribution"
      data-testid="total-distribution-balance"
    >
      <Skeleton
        selectors={[
          scheduledDistributionsStatus,
          investmentsStatusSelector,
          scheduledElectionsStatus,
        ]}
        height={50}
        width={300}
      >
        <div className="text-bold">
          <p className="util-margin-vert-0">{numPayments} Payments </p>
          <p>
            Total distribution: <Balance value={balance} showDollar />
          </p>
        </div>
      </Skeleton>
    </div>
  );
}
