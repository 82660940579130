import React, { useState } from "react";
import { FormInput } from "../../../components/form/form-input";
import {
  ContributionType,
  ElectionOption,
} from "../../../models/contributions";
import ContributionInputRange from "./contribution-input-range";
import ContributionTypeButtons from "./contribution-type-buttons";
import { findValueTypeSelected } from "./contributions-accordion";

type Props = {
  compensationName: string;
  compensationType: string;
  availableElections: ElectionOption[];
  setValue: Function;
  register: (options: any) => React.Ref<any>;
  errors: any;
  increaseErrorCount: any;
  decreaseErrorCount: any;
};

ContributionInteraction.defaultProps = {
  min: 0,
  max: 500000000,
};

export default function ContributionInteraction(props: Props): JSX.Element {
  const {
    compensationName,
    compensationType,
    setValue,
    register,
    errors,
    availableElections,
    increaseErrorCount,
    decreaseErrorCount,
  } = props;

  const inputType = getInputType(compensationType as ContributionType);
  const prefix = compensationType === ContributionType.DOLLAR ? "$" : undefined;
  const suffix =
    compensationType === ContributionType.PERCENT ? "%" : undefined;

  const name = `${compensationName}Value`;
  const hideContributionTypeButtons = shouldHideContributionTypeButtons(
    availableElections,
    prefix,
    suffix
  );

  const [currentErrors, setErrors]: any = useState({ [name]: { type: "" } });
  const [isError, setError] = useState(false);

  let min: any;
  let max: any;
  let increment: number | undefined;

  const valueTypeOptionSelected = findValueTypeSelected(
    availableElections,
    compensationType
  );

  if (valueTypeOptionSelected.length > 0) {
    min = valueTypeOptionSelected[0].minimum;
    max = valueTypeOptionSelected[0].maximum;
    increment = valueTypeOptionSelected[0].increment;
  }

  function isIncrement(val: number) {
    return increment ? val % increment === 0 : true;
  }

  const isOther = compensationType === ContributionType.OTHER;
  const errorMessage = isOther
    ? "Value must be no more than 50 characters long"
    : `Value must be an increment of ${increment}`;

  function validate(val: number | string) {
    if (typeof val === "number") {
      return isIncrement(val);
    }
    return val.length < 250;
  }

  function validateDeferralLimit(val: number | string) {
    if (val < min) {
      setCurrentErrors("min");
    } else if (val > max) {
      setCurrentErrors("max");
    } else {
      setCurrentErrors("");
    }
  }

  function setCurrentErrors(val: string) {
    if (val && !isError) {
      increaseErrorCount();
      setError(true);
    } else if (!val && isError) {
      decreaseErrorCount();
      setError(false);
    }
    setErrors({ [name]: { type: val } });
  }

  function resetErrorsInInteraction() {
    setCurrentErrors("");
  }

  return (
    <>
      <div className="user-contribution-input">
        <FormInput
          register={register}
          name={name}
          text={compensationName}
          options={{
            min,
            max,
            validate: validate,
          }}
          errors={errors}
          type={inputType}
          prefix={prefix}
          suffix={suffix}
          hasWhiteSpace={true}
          increment={increment}
          validateDeferralLimit={validateDeferralLimit}
        />

        <input
          type="text"
          aria-hidden="true"
          hidden={true}
          ref={register({})}
          name={`${compensationName}Type`}
        />
        {!hideContributionTypeButtons && (
          <ContributionTypeButtons
            compensationName={compensationName}
            compensationType={compensationType}
            setValue={setValue}
            resetErrorsInInteraction={resetErrorsInInteraction}
            availableElections={availableElections}
          />
        )}
      </div>
      {currentErrors[name] &&
        currentErrors[name].type === "min" &&
        getErrorMessage(`Value must be no less than ${min}`)}
      {currentErrors[name] &&
        currentErrors[name].type === "max" &&
        getErrorMessage(`Value must be no more than ${max}`)}
      {currentErrors[name] &&
        currentErrors[name].type === "validate" &&
        getErrorMessage(errorMessage)}
      {(min || max || increment) && inputType !== "text" && (
        <ContributionInputRange min={min} max={max} increment={increment} />
      )}
    </>
  );
}

export function getErrorMessage(message: String) {
  return (
    <div className="form-group has-error">
      <span className="has-error-message help-block">{message}</span>
    </div>
  );
}

export function getInputType(compensationType: ContributionType): string {
  if (
    compensationType === ContributionType.PERCENT ||
    compensationType === ContributionType.DOLLAR
  ) {
    return "number";
  }
  return "text";
}

export function shouldHideContributionTypeButtons(
  availableElections: ElectionOption[],
  prefix: string | undefined,
  suffix: string | undefined
): boolean {
  if (availableElections.length === 1) {
    const onlyDollarElection =
      availableElections[0].electionType === ContributionType.DOLLAR &&
      prefix !== undefined;
    const onlyPercentElection =
      availableElections[0].electionType === ContributionType.PERCENT &&
      suffix !== undefined;
    if (onlyDollarElection || onlyPercentElection) {
      return true;
    }
  }
  return false;
}
