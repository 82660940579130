import React from "react";
import DisclaimersDisplay from "../../../../components/disclaimers/disclaimers-display";
import { Account } from "../../../../models/account";
import InvestmentDisclosure from "./investment-disclosure";

type Props = {
  selectedAccounts: Account[];
  showDefaultAnchors: boolean;
  hasOutsideColi: boolean;
};

InvestmentStaticDisclosures.defaultProps = {
  showDefaultAnchors: true,
  hasOutsideColi: false,
};

export default function InvestmentStaticDisclosures(props: Props) {
  let { showDefaultAnchors } = props;
  const { selectedAccounts, hasOutsideColi } = props;

  if (selectedAccounts.length === 0) {
    showDefaultAnchors = false;
  }

  return (
    <div data-testid="investment-static-disclosures">
      <DisclaimersDisplay showOnlyShaw={true} />
      {hasOutsideColi && (
        <p>
          © 2021 Morningstar, Inc. All Rights Reserved. Part of the mutual fund
          and/or insurance investment data contained herein: (1) is proprietary
          to Morningstar and/or its content providers; (2) may not be copied or
          distributed; and (3) is not warranted to be accurate, complete, or
          timely. Neither Morningstar nor its content providers are responsible
          for any damages or losses arising from any use of this information.
        </p>
      )}
      <p>
        Investment results shown represent historical performance and do not
        guarantee future results. Investment returns and principal values
        fluctuate with changes in interest rates and other market conditions so
        the value, when redeemed may be worth more or less than original costs.
        Current performance may be lower or higher than the performance data
        shown.
      </p>
      <p>
        <i>
          The gross total investment expense figure does not reflect any waivers
          or caps on the mutual fund or underlying mutual fund in which a
          Separate Account invests. Returns displayed are always based on net
          total investment expense. For more information on net total investment
          expense figures, please access the Investment Option Profile available
          by selecting the investment option name links provided below.
        </i>
      </p>
      {showDefaultAnchors && (
        <>
          <InvestmentDisclosure id="A1" text={a1Text} />
          <InvestmentDisclosure id="B1" text={b1Text} />
        </>
      )}

      <p>
        The amounts listed on the website represent a measure of your benefits
        under a nonqualified benefit agreement between you and your plan
        sponsor. It is not a representation that your plan sponsor holds any
        assets for paying these benefits.
      </p>
      <p>
        Investment performance data is updated on the tenth of each month. At
        the beginning of each month until the tenth, data from the previous
        month will still be shown.
      </p>
      <p>
        At any time, the measure of your benefits is determined by reference to
        the performance of available investments as described in your Plan
        materials ("Reference Investments"). Throughout this site you will see
        terms such as Investment Option or Investment Advisor. These are
        indicators of the Reference Investments made available to measure your
        benefit(s) in your Nonqualified Plan.
      </p>
    </div>
  );
}

const a1Text =
  "Unannualized rate of return (change in value) since the start of the " +
  "year. All returns shown here are after Total Investment Expense of the " +
  "investment option.";

const b1Text =
  "For mutual fund investments the term share price refers to the net asset " +
  "value (NAV).";
