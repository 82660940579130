import React, { useContext } from "react";
import { EnrollmentContext } from "../../contexts/enrollment-context/enrollment-context";
import { OpenEventCard } from "./open-event-card";
import { Panel, PanelBody, PanelHeading } from "../panel/panel";
import Skeleton from "react-loading-skeleton";

export function EnrollmentEventsWrapper() {
  const { openEvents, isLoadingStatus } = useContext(EnrollmentContext);

  if (isLoadingStatus) {
    return (
      <Panel>
        <PanelHeading>
          <Skeleton count={1} />
        </PanelHeading>
        <PanelBody>
          <Skeleton count={5} />
        </PanelBody>
      </Panel>
    );
  }

  return (
    <>
      {" "}
      {openEvents.map((event) => (
        <OpenEventCard key={event.eventId} event={event} />
      ))}
    </>
  );
}
