import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AsyncStatus } from "../../models/async-status";
import { ContributionHistory } from "../../models/contributions";
import { getContributionHistory } from "../../services/contributions";
import { GetAllInvestmentsArgs } from "../../services/investment-data";

export const fetchContributionHistory = createAsyncThunk(
  "fetch-contribution-history",
  async (args: GetAllInvestmentsArgs) => {
    return getContributionHistory(args);
  }
);

type ContributionHistoryState = {
  data: ContributionHistory[];
  status: AsyncStatus;
};
const initialState: ContributionHistoryState = {
  data: [],
  status: AsyncStatus.not_fetched,
};

const contributionHistorySlice = createSlice({
  name: "contributionHistory",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchContributionHistory.pending, (state, action) => {
      return { ...state, status: AsyncStatus.loading };
    });
    builder.addCase(fetchContributionHistory.fulfilled, (state, action) => {
      return { ...state, data: action.payload, status: AsyncStatus.done };
    });
    builder.addCase(fetchContributionHistory.rejected, (state, action) => {
      return { ...state, status: AsyncStatus.error };
    });
    builder.addDefaultCase((state, action) => state);
  },
});

export default contributionHistorySlice.reducer;
