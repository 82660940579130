import React from "react";
import { PayCheckFrequency } from "./paycheck-frequency";

type Props = {
  register: (options: any) => React.Ref<any>;
};

export default function ManageContributionsBonusPayFrequencySelector(
  props: Props
): JSX.Element {
  const { register } = props;

  return (
    <div className="frequency-selector" data-testid="bonusPayFrequency-select">
      <label htmlFor="payFrequency">Pay frequency</label>
      <select
        ref={register}
        defaultValue={PayCheckFrequency.USEPAYCHECKFREQUENCY}
        className="form-control"
        data-testid="bonusPayFrequencyInput"
        name="bonusPayCheckFrequency"
      >
        <option value={PayCheckFrequency.ANNUALLY}>
          {PayCheckFrequency.ANNUALLY}
        </option>
        <option value={PayCheckFrequency.QUARTERLY}>
          {PayCheckFrequency.QUARTERLY}
        </option>
        <option value={PayCheckFrequency.MONTHLY}>
          {PayCheckFrequency.MONTHLY}
        </option>
        <option value={PayCheckFrequency.USEPAYCHECKFREQUENCY}>
          {PayCheckFrequency.USEPAYCHECKFREQUENCY}
        </option>
      </select>
    </div>
  );
}
