import React from "react";
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import {
  mapPlansById,
  mapSelectedPlans,
} from "../../../../components/beneficiary/beneficiary-util";
import { Page, PageHeader } from "../../../../components/page";
import {
  BeneficiaryState,
  updateOnlineBeneficiaryPlans,
} from "../../../../reducers/beneficiary-reducer";
import {
  accountsStatusSelector,
  beneficiariesStatus,
  beneficiaryPlansStatus,
  planInfoStatusSelector,
  sessionStatusSelector,
  useStatuses,
} from "../../../../selectors/status";
import { useAppDispatch, useSelector } from "../../../../store/store";
import "./beneficiary-plan-selection.scss";
import BeneficiaryPlans from "../beneficiary-plans";

export default function BeneficiaryPlanSelection() {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { isLoadingStatus } = useStatuses([
    sessionStatusSelector,
    planInfoStatusSelector,
    accountsStatusSelector,
    beneficiariesStatus,
    beneficiaryPlansStatus,
  ]);
  const { onlineBeneficiaryPlans } = useSelector<BeneficiaryState>(
    (state) => state.beneficiaries
  );
  const { info: currentPlanInfo } = useSelector((state) => state.plan);

  const hasPlans = onlineBeneficiaryPlans.length > 0;

  function onSubmit(selectedPlans: any) {
    const updatedPlans = mapSelectedPlans(
      selectedPlans,
      onlineBeneficiaryPlans
    );
    dispatch(updateOnlineBeneficiaryPlans(updatedPlans));
    history.push("/overview/beneficiaries/review");
  }

  const { handleSubmit, register, watch } = useForm({
    defaultValues: mapPlansById(onlineBeneficiaryPlans),
  });

  return (
    <Page
      name={"plan selection"}
      loading={isLoadingStatus}
      printable
      className={"beneficiary-plan-selection"}
    >
      <PageHeader name={"plan-selection-header"}>
        Beneficiaries - additional plan selection
      </PageHeader>
      <br />
      <div className={"row"}>
        <div className={"col-md-9"}>
          <p>You are submitting beneficiaries for:</p>
          <div>
            <p>
              <strong>{currentPlanInfo.planName}</strong>
            </p>
            <p>Contract number: {currentPlanInfo.contractId}.</p>
          </div>
          <hr />
        </div>
      </div>
      <div className={"row"}>
        <div className={"col-md-9"}>
          <form onSubmit={handleSubmit(onSubmit)}>
            {hasPlans && <BeneficiaryPlans register={register} watch={watch} />}
            <div className={"row"}>
              <div className={"col-xs-12 review-button-row"}>
                <Link to={"/overview/beneficiaries"}>
                  <i className="fa fa-angle-left" aria-hidden="true" /> Back
                </Link>
                <button
                  type={"submit"}
                  className={"btn btn-primary pull-right cta-text"}
                >
                  Review
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Page>
  );
}
