import { InvestmentV2 } from "../models/investment-v2";
import { ManageInvestments } from "../models/manage-investments";
import * as participantRepo from "../repositories/participant";
import * as enrollmentRepo from "../repositories/enrollment";
import { InvestmentOption } from "../models/investment-option";

export async function getManageInvestments(
  planId: number,
  empId: number,
  role: string
): Promise<ManageInvestments> {
  return participantRepo.get(
    `/plans/${planId}/employees/${empId}/manage-investments`,
    { role }
  );
}

export function getAvailableInvestments(
  planId: number
): Promise<InvestmentOption[]> {
  return enrollmentRepo.get(
    `/plan/${planId}/investmentOptions/includingClosed`
  );
}

interface IndividualInvestmentArgs {
  empId: number;
  planId: number;
  role: string;
  investmentId: number;
}

export async function getIndividualInvestment({
  planId,
  empId,
  role,
  investmentId,
}: IndividualInvestmentArgs): Promise<InvestmentV2> {
  return participantRepo.get(
    `/employees/${empId}/plans/${planId}/investments/${investmentId}`,
    { role }
  );
}

export type GetAllInvestmentsArgs = {
  empId: number;
  planId: number;
  role: string;
  startDate?: string;
  endDate?: string;
};

class GetAllInvestmentsParams {
  role: string;
  startDate?: string;
  endDate?: string;

  constructor(args: GetAllInvestmentsArgs) {
    this.role = args.role;
    if (args.startDate) {
      this.startDate = args.startDate;
    }
    if (args.endDate) {
      this.endDate = args.endDate;
    }
  }
}

export async function getAllInvestments(
  args: GetAllInvestmentsArgs
): Promise<InvestmentV2[]> {
  const params: GetAllInvestmentsParams = new GetAllInvestmentsParams(args);

  return participantRepo.get(
    `/employees/${args.empId}/plans/${args.planId}/investments`,
    params
  );
}

export async function getInvestmentBalances(
  args: GetAllInvestmentsArgs
): Promise<InvestmentV2[]> {
  const params: GetAllInvestmentsParams = new GetAllInvestmentsParams(args);

  return participantRepo.get(
    `/employees/${args.empId}/plans/${args.planId}/investments/balances`,
    params
  );
}
