import React from "react";
import { Account } from "../../../../models/account";
import { ScheduledDistributionElection } from "../../../../models/distributions/ScheduledDistributionElection";
import { RedeferralStatus } from "../../../../models/redeferral/redeferral-status";
import { accountRedeferralStatusesSelector } from "../../../../selectors/redeferrals";
import { useSelector } from "../../../../store/store";
import { MethodCommentsWrapper } from "./modified-method-wrapper";
import { filterAccountsByActiveRedeferral } from "./modified-method-comments-util";

type Props = {
  accounts: Account[];
  scheduledElections: ScheduledDistributionElection[];
};

export default function ModifiedMethodComments(props: Props) {
  const { scheduledElections, accounts } = props;
  const accountRedeferralStatuses: RedeferralStatus[] = useSelector(
    accountRedeferralStatusesSelector
  );
  const accountIdsWithActiveRedeferral: number[] = accountRedeferralStatuses
    .filter((status) => status.hasActiveRedeferral)
    .map((status) => status.accountId);
  const accountsWithActiveRedeferral = filterAccountsByActiveRedeferral(
    accounts,
    accountIdsWithActiveRedeferral
  );

  if (accounts.length > 0) {
    return (
      <div className="modified-method-comments">
        {renderMethodComments(scheduledElections, accountsWithActiveRedeferral)}
      </div>
    );
  }
  return null;
}

function renderMethodComments(
  scheduledElections: ScheduledDistributionElection[],
  filteredAccountsByActiveRedeferral: Account[]
) {
  return (
    <>
      <strong>Important Information</strong>
      {ModifiedMethodCommentsHeaders()}
      {MethodCommentsWrapper(
        scheduledElections,
        filteredAccountsByActiveRedeferral
      )}
    </>
  );
}

function ModifiedMethodCommentsHeaders() {
  return (
    <div className="comments-grid headers">
      <span className="column-1 row-1">Account</span>
      <span className="column-2 row-1">Account Type</span>
      <span className="column-3 row-1">Additional Information</span>
      <span className="column-4 row-1">Comment</span>
    </div>
  );
}

export function accountActiveRedeferralFlag(account: Account) {
  if (account.type === "Retirement") {
    return (
      <i
        data-testid="yellow-flag"
        className="fa fa-flag yellow-flag"
        aria-hidden="true"
      />
    );
  }
  return (
    <i
      data-testid="orange-flag"
      className="fa fa-flag orange-flag"
      aria-hidden="true"
    />
  );
}
