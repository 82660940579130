import React from "react";

type IconProps = {
  name: string;
};

export default function Icon(props: IconProps) {
  const { name } = props;
  const classNames = `fa fa-${name}`;
  return <i className={classNames} aria-hidden="true" />;
}
