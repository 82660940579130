import { html } from 'lit';
import { ChevronLeft } from 'lucide-static';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { state } from 'lit/decorators.js';
import { pdsCustomIconElement as customElement } from '../../../decorators/pds-icon';
import { PdsIcon } from '../PdsIcon';

@customElement('pds-icon-chevron-left')
export class PdsIconChevronLeft extends PdsIcon {
  @state()
  icon = ChevronLeft;

  render() {
    return html`${unsafeHTML(this.setIconAttributes())}`;
  }
}
