import { html, PropertyValues } from 'lit';
import { property, query } from 'lit/decorators.js';
import { pdsCustomElement as customElement } from '../../decorators/pds-custom-element';
import { PdsElement } from '../PdsElement';
import styles from './tab-button.scss?inline';
import { required } from '../../decorators/required';

/**
 * @summary This component is a tab button element, to be included in pds-tab-button-list
 *
 * @fires pds-tab-button-click A custom event dispatched on the click of the tab button
 * @fires pds-tab-button-keypress A custom event dispatched on the keypress when a button has focus
 *
 * @slot default Required: This slot accepts the contents of the button.
 */
@customElement('pds-tab-button', {
  category: 'component',
  type: 'component',
  styles,
})
export class PdsTabButton extends PdsElement {
  /**
   * Adds a buttonId to the tab button
   * This **must** match the corresponding 'aria-labelledby' on a pds-tab-content element
   * This is a **required** property.
   */
  @required
  @property({ type: String, reflect: true })
  buttonId: string = '';

  /**
   * Id of the content that this tab button controls
   */
  @required
  @property({ type: String })
  ariaControls: string;

  /**
   * Set this boolean to true if this is the selected tab
   * @internal
   */
  @property({ type: Boolean, reflect: true })
  selected: boolean = false;

  /**
   * @internal
   */
  @query('button')
  button: HTMLButtonElement;

  /**
   * @internal
   */
  select(keyboardNavigation = false) {
    this.selected = true;
    if (keyboardNavigation) {
      this.button.focus();
    }
  }

  /**
   * @internal
   */
  isMac() {
    return navigator.userAgent.indexOf('Mac') > -1;
  }

  protected firstUpdated(): void {
    if (this.isMac()) {
      this.setAttribute('role', 'tab');
      this.setAttribute('selected', this.selected.toString());
    } else {
      this.shadowRoot?.querySelector('button')?.setAttribute('role', 'tab');
      this.shadowRoot
        ?.querySelector('button')
        ?.setAttribute('selected', this.selected.toString());
    }
    if (this.ariaControls) {
      this.setAttribute('aria-controls', this.ariaControls);
    }
    if (this.buttonId) {
      this.setAttribute('id', this.buttonId);
    }
  }

  protected updated(changedProperties: PropertyValues): void {
    if (changedProperties.has('ariaControls')) {
      this.setAttribute('aria-controls', this.ariaControls);
    }
    if (changedProperties.has('buttonId')) {
      this.setAttribute('id', this.buttonId);
    }
  }

  /**
   * @internal
   */
  deSelect() {
    this.selected = false;
  }

  /**
   * @internal
   */
  handleClick() {
    const customEvent = new CustomEvent('pds-tab-button-click', {
      bubbles: true,
      composed: true,
      detail: {
        summary: this.buttonId,
      },
    });

    this.dispatchEvent(customEvent);
  }

  /**
   * @internal
   */
  handleKeydown(event: KeyboardEvent) {
    if (
      event.key === 'Home' ||
      event.key === 'End' ||
      event.key === 'ArrowLeft' ||
      event.key === 'ArrowRight'
    ) {
      event.stopPropagation();
      event.preventDefault();

      const customEvent = new CustomEvent(`pds-tab-button-keypress`, {
        bubbles: true,
        composed: true,
        detail: {
          tab: this.buttonId,
          key: event.key,
        },
      });
      this.dispatchEvent(customEvent);
    }
  }

  /**
   * @internal
   */
  get classNames() {
    return {
      'is-active': this.selected,
    };
  }

  render() {
    return html`<button
      class=${this.getClass()}
      @click=${this.handleClick}
      @keydown=${this.handleKeydown}
      type="button"
      part="button"
      tabindex=${this.selected ? '0' : '-1'}
      id="${this.buttonId}"
    >
      <span><slot></slot></span>
    </button>`;
  }
}
