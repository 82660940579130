import React from "react";
import {
  Panel,
  PanelBody,
  PanelHeading,
} from "../../../../components/panel/panel";
import { useInvestments } from "../../../../selectors/investments";
import { useSelector } from "../../../../store/store";
import ContributionBreakdownChart from "./contribution-breakdown-chart";
import { getSortedData } from "./contribution-breakdown-utils";
import {
  Bar,
  BarChart,
  Cell,
  LabelList,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { getRoundedValue } from "../../../../components/numbers/fixed-decimal";

export default function ContributionBreakdown() {
  const { investments } = useInvestments();
  const moneyTypes = useSelector((state) => state.plan.moneyTypes.data);
  const sortedInvestments = getSortedData(investments, moneyTypes).filter(
    (item) => item.balance !== 0
  );
  const numberOfChartItems = 6;
  const moreThanNumberOfChartItemsInvestments =
    sortedInvestments.length > numberOfChartItems;

  return (
    <div>
      {!moreThanNumberOfChartItemsInvestments ? (
        <Panel className={"col-md-12"}>
          <PanelHeading>Contribution breakdown</PanelHeading>
          <PanelBody>
            <ContributionBreakdownChart sortedInvestments={sortedInvestments} />
          </PanelBody>
        </Panel>
      ) : (
        <Panel className={"col-md-12"}>
          <PanelHeading>Contribution breakdown</PanelHeading>
          <PanelBody>
            <VerticalContributionBreakdownChart
              sortedInvestments={sortedInvestments}
              numberOfItems={numberOfChartItems}
            />
          </PanelBody>
        </Panel>
      )}
    </div>
  );
}

function VerticalContributionBreakdownChart(props) {
  const { sortedInvestments, numberOfItems } = props;

  const isLessThanNumberOfItems = sortedInvestments.length <= numberOfItems;

  return (
    <ResponsiveContainer
      width="100%"
      height={isLessThanNumberOfItems ? 300 : sortedInvestments.length * 50}
      className={"time-series-chart-wrapper"}
    >
      <BarChart
        data={sortedInvestments}
        margin={{
          bottom: 10,
          top: 10,
          left: 20,
          right: 0,
        }}
        layout={"vertical"}
      >
        <XAxis type={"number"} hide={true} />
        <YAxis
          dataKey={"moneyName"}
          type={"category"}
          tickMargin={1}
          tick={{ fontSize: 12, width: 100 }}
        />
        <Bar
          dataKey={"balance"}
          isAnimationActive={false}
          type={"number"}
          key={"bar"}
          fill={"#0d7cc7"}
        >
          {sortedInvestments.map((entry) => (
            <Cell key={`cell-${entry.moneyName}`} />
          ))}
          <LabelList
            dataKey={"balance"}
            position={"insideLeft"}
            formatter={(label) => `$${getRoundedValue(label, 0)}`}
            fill={"#000000"}
          />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}
