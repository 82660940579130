import * as participantRepo from "../repositories/participant";

export async function getAllDisclosures({ empId, planId, role }) {
  const params = { role };

  return participantRepo.get(
    `/employees/${empId}/plans/${planId}/disclosures`,
    params
  );
}
