import { formatDateWithoutTimeZone } from "../util/date-util";
import { PaymentType } from "./distributions/payment-type";
import {
  getInstallmentFrequencyText,
  InstallmentFrequency,
} from "./distributions/installment-frequency";
import { InstallmentType } from "./distributions/installment-type";

export interface RedeferralInstruction extends FormRedeferralSettings {
  accountId: number;
  accountName: string;
  previousAccountName: string;
  payoutStartDate: string | null;
  installmentType?: InstallmentType | null;
  installmentNumber?: number;
  previousPayoutStartDate?: string;
  isIndividualFlow?: boolean;
}

export interface FormRedeferralSettings {
  payoutMethodType: PaymentType | null;
  installmentFrequency: InstallmentFrequency | null;
  installmentYears: number | null;
}

export function getTimeRedeferralSettings(
  selectedDate: string | null | undefined
): string | null {
  if (selectedDate) {
    return formatDateWithoutTimeZone(selectedDate, "yyyy-MM-dd");
  } else {
    return null;
  }
}

export function getFormRedeferralSettings(
  selectedPayoutType: string | null | undefined,
  selectedInstallmentFrequency: string | null,
  newInstallmentCount: string | null
): FormRedeferralSettings {
  if (selectedPayoutType === "LUMP_SUM") {
    return {
      payoutMethodType: PaymentType.LUMPSUM,
      installmentFrequency: null,
      installmentYears: null,
    };
  } else if (selectedPayoutType === "INSTALLMENTS") {
    return {
      payoutMethodType: PaymentType.INSTALLMENT,
      installmentFrequency: getInstallmentFrequencyText(
        selectedInstallmentFrequency
      ),
      installmentYears: Number(newInstallmentCount),
    };
  } else {
    // For Redeferrals, only Lump Sum and Installments are available. If neither are selected, there is no payout method.
    return {
      payoutMethodType: null,
      installmentFrequency: null,
      installmentYears: null,
    };
  }
}
