import React from "react";
import "./skip-link.scss";

export default function SkipLink() {
  return (
    <a
      href="#main-content"
      className="sr-only sr-only-focusable header-skip-link"
    >
      Skip to main content
    </a>
  );
}
