import React, { useState } from "react";
import "./rectangle-features.scss";
import { PdsIconAlertCircle } from "@principal/design-system-icons-react";
interface RectangleProps {
  text: string;
  onVisibilityChange: (isVisible: boolean) => void;
}

const RectangleProp: React.FC<RectangleProps> = ({
  text,
  onVisibilityChange,
}) => {
  const [isVisible, setIsVisible] = useState(true);

  const handleClick = () => {
    setIsVisible(false);
    onVisibilityChange(false);
  };

  if (!isVisible) {
    return null;
  }
  return (
    <div>
      <div className="orange-rectangle" style={{ marginTop: "-1%" }}>
        <div style={{ marginTop: "-.5%" }}>
          <PdsIconAlertCircle size="sm" />
        </div>

        {/*<div className="circle">!</div>*/}
        <p className="text">
          You have until {text} to make your elections online.
        </p>
        <span className="close-icon" onClick={handleClick}>
          X
        </span>
      </div>
    </div>
  );
};

export default RectangleProp;
