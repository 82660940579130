import { Timeline } from "./timeline";
import React from "react";
import { getRoundedValue } from "../../../../components/numbers/fixed-decimal";
import { FutureDistributionTimelinePayment } from "../../../../models/distributions/distribution-payment";

type TimelineProps = {
  setSelectedDate: Function;
  selectedDate: Date | undefined;
  startDate: Date | undefined;
  data: FutureDistributionTimelinePayment[];
};

export function DistributionTimeline(props: TimelineProps) {
  const { setSelectedDate, selectedDate, startDate, data } = props;

  function handleSelect(date: Date) {
    setSelectedDate(date);
  }

  return (
    <Timeline
      data={data}
      startDate={startDate}
      pointFormatter={(value: number) => formatTimelineInvestmentValue(value)}
      onSelect={handleSelect}
      selectedDate={selectedDate}
      styles={{ height: 120, margin: { top: 0 } }}
    />
  );
}

export function formatTimelineInvestmentValue(value: number) {
  if (value > 0) {
    return `$${getRoundedValue(value, 2)}`;
  }
  return "No investments";
}
