import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import { LastBusinessDate } from "../../../components/numbers/last-business-date";
import ContributionAmounts from "./contribution-amounts/contribution-amounts";
import ContributionSources from "./contribution-sources/contribution-sources";
import ContributionsSummaryResourcesLinks from "./contributions-summary-resources-links";
import "./contributions-summary.scss";
import DeferralElections from "./deferal-elections/deferral-elections";
import { useSelector } from "../../../store/store";
import {
  hideContributionAmountsSelector,
  hideContributionsResourcesSelector,
  hideDeferralElectionsSelector,
  hideZeroMoneyTypes,
} from "../../../selectors/plan-info";

export default function ContributionsSummaryWrapper() {
  const hideDeferralElections = useSelector(hideDeferralElectionsSelector);
  const hideContributionsResources = useSelector(
    hideContributionsResourcesSelector
  );
  const hideContributionAmounts = useSelector(hideContributionAmountsSelector);
  const hideMoneyTypesWithNoValue = useSelector(hideZeroMoneyTypes);

  return (
    <div className="contributions-summary">
      <div className="top">
        {!hideDeferralElections && <DeferralElections />}
        {!hideContributionsResources && <ContributionsSummaryResourcesLinks />}
      </div>
      {!hideContributionAmounts && (
        <div className="contribution-amounts">
          <h2 className="h4">Your Contribution Amounts</h2>
          <p>
            Balance as of <LastBusinessDate />
          </p>
          <Tabs defaultActiveKey="source" transition={false}>
            <Tab title="Contributions by source" eventKey="source">
              <ContributionAmounts
                isByAccount={false}
                hideZeroValues={hideMoneyTypesWithNoValue ?? false}
              />
              <div className="contribution-sources">
                <ContributionSources isByAccount={false} />
              </div>
            </Tab>
            <Tab title="Contributions by account" eventKey="account">
              <ContributionAmounts isByAccount={true} hideZeroValues={false} />
              <div className="contribution-sources">
                <ContributionSources isByAccount={true} />
              </div>
            </Tab>
          </Tabs>
        </div>
      )}
    </div>
  );
}
