import React from "react";
import DisclaimersDisplay from "../../../components/disclaimers/disclaimers-display";
import { PrrModal } from "../../prr-faq/prr-modal";

type Props = {
  superScripts: boolean;
};

Info.defaultProps = {
  superScripts: true,
};

export default function Info(props: Props) {
  const { superScripts } = props;

  return (
    <>
      {renderDefaultSuperScripts(superScripts)}
      <DisclaimersDisplay showOnlyShaw={true} />
      <p>
        The amounts listed on the website represent a measure of your benefits
        under a nonqualified benefit agreement between you and your plan
        sponsor. It is not a representation that your plan sponsor holds any
        assets for paying these benefits.
      </p>
      <p>
        At any time, the measure of your benefits is determined by reference to
        the performance of available investments as described in your Plan
        materials ("Reference Investments"). Throughout this site you will see
        terms such as Investment Option or Investment Advisor. These are
        indicators of the Reference Investments made available to measure your
        benefit(s) in your Nonqualified Plan.
      </p>
      <p>
        Investment results shown represent historical performance and do not
        guarantee future results. Investment returns and principal values
        fluctuate with changes in interest rates and other market conditions so
        the value, when redeemed may be worth more or less than original costs.
        For more performance information, including most recent month-end
        performance, visit the Principal Sponsor Service Center SM at
        www.principal.com, contact your representative of the Principal
        Financial Group® (The Principal®), or contact our Sales Department at
        800-999-4031. The subaccount’s sponsor has no legal obligation to
        provide financial support to the subaccount, and you should not expect
        that the sponsor will provide financial support to the subaccount at any
        time. Money Market Funds are not guaranteed by the Federal Deposit
        Insurance Corporation or any other government agency. Although these
        Funds seek to preserve the value of your investment at $1.00 per share,
        it is possible to lose money when investing in these funds. The total
        investment expense gross figure does not reflect any waivers or caps on
        the mutual fund or underlying mutual fund in which a Separate Account
        invests. Returns displayed are always based on net total investment
        expense.
      </p>
      <p>
        Sub-Advised Options include Principal mutual funds of the Principal
        Funds, Inc. series. Principal Funds, Inc. is distributed by Principal
        Funds Distributor, Inc. Securities are offered through Principal
        Securities, Inc., 800-247-1737, member SIPC and/or independent
        broker/dealers. Securities sold by a Principal Securities Registered
        Representative are offered through Principal Securities. Principal Funds
        Distributor and Principal Securities are members of the Principal
        Financial Group®, Des Moines, IA 50392. Returns shown for periods of
        less than one year are not annualized. All returns displayed here are
        after net Total Investment Expense of the investment option. Any
        operating expenses of a mutual fund or underlying mutual fund that are
        part of Total Investment Expense Net are obtained from the mutual fund's
        most recent prospectus. The operating expenses shown as part of net
        Total Investment Expense include voluntary expense limits and fee
        credit. For a Mutual Fund investment option, Total Investment Expense
        gross equals the sum of (a) the total fund operating expenses plus (b)
        if the mutual fund invests in other mutual funds, the weighted-average
        management fee of those other mutual funds, as listed in the most recent
        prospectus. The actual Total Investment Expense may change if the mutual
        fund investment option's allocation of assets to other mutual funds
        changes. Money Market Funds are not guaranteed by the Federal Deposit
        Insurance Corporation or any other government agency. Although these
        Funds seek to preserve the value of your investment at $1.00 per share,
        it is possible to lose money when investing in these funds. The 7-Day
        Simple Yield % more closely reflects the current earnings of this money
        market investment option than the total return displayed. Insurance
        issued and plan administrative services provided by Principal Life
        Insurance Company. Principal Funds, Inc. is distributed by Principal
        Funds Distributor, Inc. Securities offered through Principal Securities,
        Inc., 800-247-1737, member SIPC and/or independent broker/ dealers.
        Securities sold by a Principal Securities Registered Representative are
        offered through Principal Securities. Principal Life, Principal Funds
        Distributor, and Principal Securities are members of the Principal
        Financial Group®, Des Moines, IA 50392. Before investing in mutual
        funds, investors should carefully consider the investment objectives,
        risks, charges and expenses of the funds. This and other information is
        contained in the free prospectus, which can be obtained from your local
        representative, by visiting www.principal.com, or by contacting us at
        1-800-547-7754. Please read the prospectus carefully before investing.
        This report includes investment options that contain information from a
        variety of sources. Morningstar generally provides holdings information,
        operations data, and rankings or statistics proprietary to Morningstar.
        Morningstar is also the source of information on certain mutual funds.
        (C) 2009 Morningstar, Inc. All Rights Reserved. Part of the mutual fund
        data contained herein: (1) is proprietary to Morningstar and/or its
        content providers: (2) may not be copied or distributed; and (3) is not
        warranted to be accurate, complete, or timely. Neither Morningstar nor
        its content providers are responsible for any damages or losses arising
        from any use of this information.
      </p>
    </>
  );
}

function renderDefaultSuperScripts(superScripts: boolean) {
  if (superScripts) {
    return (
      <dl id="[1]">
        <dt>
          <sup>
            <span className="sr-only">Footnote </span>[1]
          </sup>
        </dt>{" "}
        <dd>
          <PrrModal linkText="PRR FAQ" />
          <p>
            Your Personalized Rate of Return (PRR) is a percentage which
            represents the performance of your account(s) year to date for the
            calendar year.
          </p>
        </dd>
      </dl>
    );
  }
  return;
}
