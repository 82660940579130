import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AsyncStatus } from "../../models/async-status";
import { PlanConfig } from "../../models/plan/plan-config";
import { getPlanConfig } from "../../services/plans";

export const fetchPlanConfig = createAsyncThunk(
  "fetch-plan-config",
  async (
    { planId, empId, role }: { planId: number; empId: number; role: string },
    thunkApi
  ) => {
    return getPlanConfig({ planId, empId, role });
  }
);

const initialState: PlanConfig = {
  transitionPageEnabled: false,
  transitionPageEndDate: "",
  paymentInfoTableEnabled: false,
  onlineStatementsEnabled: false,
  status: AsyncStatus.not_fetched,
};

const planConfigSlice = createSlice({
  name: "plan-config",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPlanConfig.pending, (state, action) => {
      return { ...state, status: AsyncStatus.loading };
    });
    builder.addCase(fetchPlanConfig.fulfilled, (state, action) => {
      return { ...state, ...action.payload, status: AsyncStatus.done };
    });
    builder.addCase(fetchPlanConfig.rejected, (state, action) => {
      return {
        ...state,
        status: AsyncStatus.error,
      };
    });
    builder.addDefaultCase((state, action) => state);
  },
});

export default planConfigSlice.reducer;
