import React, { useState } from "react";
import { Page, PageHeader } from "../../../components/page/index";
import { performanceType } from "../../../util/constants";
import PerformanceWrapper from "./performance-wrapper";

const name = "performance";
const { monthly: MONTHLY } = performanceType;

export default function PerformancePage() {
  const [performanceType, setPerformanceType] = useState<string>("M");

  const pageType: string =
    performanceType === MONTHLY ? "Monthly" : "Quarterly";

  return (
    <Page printable name={name} loading={false}>
      <PageHeader name={name}>Investment Performance: {pageType}</PageHeader>
      <PerformanceWrapper
        performanceType={performanceType}
        setPerformanceType={setPerformanceType}
      />
    </Page>
  );
}
