import React from "react";
import LoadingSpinner from "../loading-spinner/loading-spinner";
import { logoutPath } from "../../util/constants";

export interface Props {
  isLoadingToken?: boolean;
  isLoadingSession?: boolean;
  isValidToken?: boolean;
  isLoadingFeatureFlags?: boolean;
  children?: React.ReactNode;
}

function AuthRenderer(props: Props) {
  const {
    isLoadingToken,
    isLoadingSession,
    isValidToken,
    isLoadingFeatureFlags,
    children,
  } = props;
  if (isLoadingToken || isLoadingSession || isLoadingFeatureFlags) {
    return <LoadingSpinner />;
  }
  if (isValidToken) {
    return <>{children}</>;
  }
  window.location.assign(logoutPath);
  return <LoadingSpinner />;
}

export default AuthRenderer;
