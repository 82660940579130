import _get from "lodash/get";
import _sortBy from "lodash/sortBy";
import { reduceByMoneyTypeId } from "../../../../util/investment-context-util";

export function getSortedData(investments, moneyTypes) {
  const aggregatedInvestments = reduceByMoneyTypeId(investments);
  const moneyTypesWithBalances = addBalances(moneyTypes, aggregatedInvestments);
  return _sortBy(moneyTypesWithBalances, "moneyTypeId");
}

function addBalances(moneyTypes, aggregatedInvestments) {
  return moneyTypes.map((moneyType) => {
    const match = aggregatedInvestments.find(
      (investment) => investment.moneyTypeId === moneyType.moneyTypeId
    );
    const balance = _get(match, "endBalance.cashBalance.balance", 0);
    return { ...moneyType, balance };
  });
}
