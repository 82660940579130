import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Page } from "../../../components/page";
import {
  Panel,
  PanelBody,
  PanelHeading,
} from "../../../components/panel/panel";
import { AsyncStatus } from "../../../models/async-status";
import { NqAccount } from "../../../models/nq-accounts.model";
import { fetchNqMultipleAccountsDetails } from "../../../reducers/nq-accounts-multiple";
import { sessionStatusSelector, useStatuses } from "../../../selectors/status";
import { useSelector } from "../../../store/store";
import "./accounts-landing-page.scss";

export default function AccountsLandingPage() {
  const dispatch = useDispatch();
  const { isLoadingStatus } = useStatuses([sessionStatusSelector]);
  const enterpriseId = localStorage.getItem("enterpriseId");
  const { data: nqAccounts, status: nqAccountsStatus } = useSelector(
    (state) => state.nqMultipleAccountDetails
  );

  useEffect(() => {
    if (!!enterpriseId) {
      dispatch(
        fetchNqMultipleAccountsDetails({
          enterpriseId: enterpriseId,
        })
      );
    }
  }, [enterpriseId, dispatch]);

  function getNonQualifiedAccountsDisplay() {
    function onlyUnique(value: any, index: number, self: any) {
      return self.indexOf(value) === index;
    }

    function getDisclaimerId(disclaimer: string): string {
      const index = uniqueDisclaimers.indexOf(disclaimer);
      if (index === -1) {
        return "";
      } else {
        return (index + 1).toString();
      }
    }

    if (nqAccountsStatus !== AsyncStatus.done) {
      return <p>Loading your nonqualified accounts...</p>;
    } else if (nqAccounts.length === 0) {
      return <p>You have no nonqualified accounts.</p>;
    }

    const disclaimers = nqAccounts.map((account) => account.disclaimer);
    const uniqueDisclaimers = disclaimers.filter(onlyUnique);

    return (
      <div className="row accounts-landing">
        <div className="account-card-group">
          {nqAccounts.map((account: NqAccount) => (
            <div className="account-card" key={account.planId}>
              <Panel>
                <PanelHeading>
                  <em>{account.planCategoryWording}</em>
                  <br />
                  {account.planNameDisplayText}
                </PanelHeading>
                <PanelBody>
                  <div className="text-center">
                    <LiabilityCalcAccountBalance
                      account={account}
                      disclaimerID={getDisclaimerId(account.disclaimer)}
                    />
                    {account.personalSummaryInfoMessage && (
                      <div
                        className="alert alert-info"
                        dangerouslySetInnerHTML={{
                          __html: account.personalSummaryInfoMessage,
                        }}
                      />
                    )}
                    {account.personalSummaryErrorMessage && (
                      <>
                        <div
                          className="alert alert-danger"
                          dangerouslySetInnerHTML={{
                            __html: account.personalSummaryErrorMessage,
                          }}
                        />
                      </>
                    )}
                    <a
                      href={account.participantExperienceUrl}
                      className="btn btn-primary util-margin-top-10"
                    >
                      View account details
                    </a>
                    {account.enrollmentDirectiveLink && (
                      <>
                        <br />
                        <a
                          href={account.enrollmentDirectiveLink}
                          className="btn btn-primary util-margin-top-10"
                        >
                          {account.enrollmentDirectiveLinkText}
                        </a>
                      </>
                    )}
                  </div>
                </PanelBody>
              </Panel>
            </div>
          ))}
          <hr />
          {uniqueDisclaimers.map((disclaimer: string, index: number) => (
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <p>
                    <sup>{index + 1}</sup>
                    {disclaimer}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <Page name="account-landing" loading={isLoadingStatus}>
      <h1>Your nonqualified accounts</h1>
      {getNonQualifiedAccountsDisplay()}
    </Page>
  );
}

interface AccountBalanceProps {
  account: NqAccount;
  disclaimerID: string;
}

function LiabilityCalcAccountBalance(props: AccountBalanceProps) {
  const { account, disclaimerID } = props;

  if (account.hasBalance) {
    return (
      <>
        <div className="row">
          <div>
            Total account balance
            <sup>{disclaimerID}</sup>
          </div>
          <div className="h4">
            {account.balances[0].totalBalanceDisplayText}
          </div>
        </div>
        <hr />
        <div>
          Vested account balance
          <sup>{disclaimerID}</sup>
        </div>
        <div className="h4">{account.balances[0].vestedBalanceDisplayText}</div>
        <hr role="presentation" />
      </>
    );
  } else {
    return (
      <>
        <div className="row">
          <div>Total account balance</div>
          <div className="h4">$0.00</div>
          <hr role="presentation" />
        </div>
      </>
    );
  }
}
