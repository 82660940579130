import { html, nothing } from 'lit';
import {
  property,
  query,
  queryAssignedElements,
  state,
} from 'lit/decorators.js';
import { pdsCustomElement as customElement } from '../../decorators/pds-custom-element';
import { PdsElement } from '../PdsElement';
import styles from './user-avatar.scss?inline';

/**
 * @summary User avatar with indicator for application notifications.  For use within the global header only
 *
 * @slot media Optional: Replaces the default svg with a user's image, restricted to svg, img
 */
@customElement('pds-user-avatar', {
  category: 'component',
  type: 'component',
  styles,
})
export class PdsUserAvatar extends PdsElement {
  /**
   * Flag to indicate if the user has notifications in the consuming application
   */
  @property()
  hasNotifications: boolean = false;

  /**
   * User initials for user avatar. Truncated if the consumer passes in more than 2 initials
   */
  @property()
  initials?: string;

  /**
   * Tracks if the passed media slot is empty or not in order to render either initials or an avatar
   * @internal
   */
  @state()
  mediaSlotIsEmpty: boolean = true;

  /**
   * Tracks whether or not the component should be hidden if there are no initials or avatar
   * @internal
   */
  @state()
  hideRender: boolean = false;

  /**
   * The media slot element
   * @internal
   */
  @query('slot[name="media"]')
  mediaSlot: HTMLSlotElement;

  /**
   * The media slot's assigned elements
   * @internal
   */
  @queryAssignedElements({ slot: 'media' })
  mediaSlotElements: HTMLElement[];

  protected override firstUpdated() {
    super.firstUpdated();
    this.handleSlotValidation('media');

    this.mediaSlotIsEmpty = !this.isMediaSlotPopulated(
      this.mediaSlotElements[0],
    );

    this.hideRender =
      (!this.initials || this.initials === '') && this.mediaSlotIsEmpty;
  }

  /**
   * Checks if the media slot is populated by recursively examining the element and its assigned elements.
   *
   * @param {Element | null} element - The element to check.
   * @param {number} [depth=3] - The maximum depth to recurse into assigned elements.
   * @returns {boolean} - Returns true if an element with a tag name of 'IMG', 'SVG', or 'SPAN' is found, otherwise false.
   * @internal
   */
  isMediaSlotPopulated(element: Element | null, depth: number = 3): boolean {
    if (!element || depth === 0) {
      return false;
    }

    const validTags = ['IMG', 'SVG', 'SPAN'];
    if (validTags.includes(element.tagName)) {
      return true;
    }

    if (element.tagName === 'SLOT') {
      const assignedElements = (element as HTMLSlotElement).assignedElements();

      // switching to a forEach causes both the initials and avatar to render, which is incorrect
      /* eslint-disable no-restricted-syntax */
      for (const assignedElement of assignedElements) {
        if (this.isMediaSlotPopulated(assignedElement, depth - 1)) {
          return true;
        }
      }
    }

    return false;
  }

  /**
   * @internal
   */
  handleSlotChange(event: Event) {
    const mediaContent = (
      event.target as HTMLSlotElement
    ).assignedElements()[0];

    if (mediaContent) {
      this.mediaSlotIsEmpty = !this.isMediaSlotPopulated(mediaContent);
    }

    this.hideRender =
      (!this.initials || this.initials === '') && this.mediaSlotIsEmpty;
  }

  render() {
    if (this.hideRender) {
      console.error(
        'UserAvatar requires either user initials or an image in the media slot to render the component',
      );
      return nothing;
    }

    if (this.initials && this.initials.length !== 2) {
      console.warn('Initials in user-avatar should contain two characters');
      this.initials =
        this.initials.length > 2
          ? this.initials.substring(0, 2)
          : this.initials;
    }

    return html`<span class=${this.getClass()}>
      <span class="${this.classEl('media')}">
        ${this.initials && (this.slotEmpty('media') || this.mediaSlotIsEmpty)
          ? html`<span>${this.initials.toUpperCase()}</span>`
          : nothing}
        <slot
          name="media"
          allowed-elements="img, svg, slot, span"
          @slotchange=${this.handleSlotChange}
        ></slot>
      </span>
      ${this.hasNotifications
        ? html`<span class="${this.classEl('notification-identifier')}"></span>`
        : nothing}
    </span>`;
  }
}
