import React, { useEffect } from "react";
import { Page, PageHeader } from "src/components/page";
import { fetchPlanStatements } from "../../../reducers/planstatements/plan-statements";
import { fetchTrsStatements } from "../../../reducers/trs-statements/trs-statements";
import { showTrsStatementsSelector } from "../../../selectors/plan-info";
import {
  planInfoStatusSelector,
  planStatementStatus,
  useStatuses,
} from "../../../selectors/status";
import { trsStatementsResource } from "../../../selectors/trs-statements";
import { buildPdfDownloadUrl } from "../../../services/download-pdf";
import { useAppDispatch, useSelector } from "../../../store/store";
import { isPilotOrTest } from "../../../util/constants";
import { formatDateWithoutTimeZone } from "../../../util/date-util";
import TrsStatementDownloadLink from "./trs-statement-download-link";
import { useAuth } from "react-oidc-context";

export default function PlanStatementsPage() {
  const { planstatements } = useSelector((state) => state.planStatements);
  const { isAdvisorOrEmployer } = useSelector((state) => state.session);
  const trsStatements = useSelector(trsStatementsResource);
  const { planId, empId, role } = useSelector((state) => state.session);
  const { contractId } = useSelector((state) => state.plan.info);
  const showTrsStatementsEnabled = useSelector(showTrsStatementsSelector);
  const { isLoadingStatus } = useStatuses([
    planStatementStatus,
    planInfoStatusSelector,
  ]);
  const dispatch = useAppDispatch();
  const auth = useAuth();

  useEffect(() => {
    dispatch(fetchPlanStatements({ planId, empId, role }));
    if (showTrsStatementsEnabled) {
      const authToken = auth?.user?.access_token;
      if (authToken === undefined) {
        console.log("authToken missing");
      } else {
        dispatch(
          fetchTrsStatements({
            contractId,
            empId,
            authToken,
            isPilotOrTest,
            isAdvisorOrEmployer,
          })
        );
      }
    }
  }, [
    dispatch,
    planId,
    empId,
    role,
    showTrsStatementsEnabled,
    contractId,
    isAdvisorOrEmployer,
    isPilotOrTest,
  ]);

  const buildPdfHref = (fileName: string, documentId: number): string => {
    return buildPdfDownloadUrl(planId, empId, fileName, documentId, role);
  };

  return (
    <Page name={"plan-statements"} loading={isLoadingStatus}>
      <PageHeader name={"plan statements"}>
        {!planstatements.definedBenefitPlan
          ? "Your Account Statements"
          : "Your Benefit Statements"}
      </PageHeader>

      {planstatements.planStatements.length > 0 ||
      (trsStatements.trsStatements !== null &&
        trsStatements.trsStatements?.length > 0) ? (
        <p>Below you will find your historical statement(s) for this plan.</p>
      ) : (
        <p>
          At this time you do not have any historical statement(s) available.
        </p>
      )}

      {showTrsStatementsEnabled &&
        trsStatements.trsStatements !== null &&
        trsStatements.trsStatements?.length > 0 && (
          <>
            <span style={{ fontWeight: "bold" }}>All Plan Statements</span>
            <TrsStatementDownloadLink
              trsStatements={trsStatements.trsStatements}
            />
          </>
        )}

      {planstatements.planStatements.map((statement) => (
        <div>
          {!planstatements.definedBenefitPlan ? (
            <span style={{ fontWeight: "bold" }}>
              {statement.statementHeader}
            </span>
          ) : (
            ""
          )}
          {statement.documents.map((document) => (
            <div style={{ marginTop: "4px" }}>
              <a
                target={"_blank"}
                href={buildPdfHref(document.fileName, document.documentId)}
              >
                {planstatements.definedBenefitPlan ? "Statement for " : ""}
                {formatDateWithoutTimeZone(document.startDate, "MM/dd/yyyy")}
                {!planstatements.definedBenefitPlan
                  ? `- ${formatDateWithoutTimeZone(
                      document.endDate,
                      "MM/dd/yyyy"
                    )}`
                  : ""}
              </a>
            </div>
          ))}
        </div>
      ))}

      <div className="util-margin-top-15">
        {planstatements.planStatements.length ? (
          <span>
            Please note, the information provided on the statement(s) was deemed
            accurate on the date it was posted to the website.
          </span>
        ) : (
          ""
        )}
        {planstatements.displayDBAndDCStatements &&
        !planstatements.definedBenefitPlan ? (
          <div>
            Please visit your NQ Defined Benefit plan(s) to view additional
            details, including statements.
          </div>
        ) : (
          ""
        )}
        {planstatements.displayDBAndDCStatements &&
        planstatements.definedBenefitPlan ? (
          <div>
            Please visit your NQ Defined Contribution plan(s) to view additional
            details, including statements.
          </div>
        ) : (
          ""
        )}
        {
          <div>
            The amounts listed on the website represent a measure of your
            benefits under a nonqualified benefit agreement between you and your
            plan sponsor. It is not a representation that your plan sponsor
            holds any assets for paying these benefits.
            {!planstatements.definedBenefitPlan
              ? " Vested Balances shown on the website represent the portion of your benefit account(s) available for distribution, upon a qualifying distribution event."
              : ""}
          </div>
        }
      </div>
    </Page>
  );
}
