import React, { useEffect, useState } from "react";
import { RedeferralStatus } from "../../../../models/redeferral/redeferral-status";
import { useAppDispatch, useSelector } from "../../../../store/store";
import { Link, useHistory } from "react-router-dom";
import LoadingSpinner from "../../../../components/loading-spinner/loading-spinner";
import {
  fetchScheduledDistributionsForRedeferral,
  updateScheduledDistribution,
} from "../../../../reducers/redeferral/scheduledDistributionForRedeferral";
import {
  marketClosedDatesStatus,
  scheduledDistributionsForRedeferralStatus,
  useStatuses,
} from "../../../../selectors/status";
import { fetchMarketCloseDates } from "../../../../reducers/redeferral/marketCloseDates";
import { ScheduledDistributionForRedeferral } from "../../../../models/distributions/scheduled-distribution-event";
import { formatDateWithoutTimeZone } from "../../../../util/date-util";
import Balance from "../../../../components/numbers/balance";
import RedeferBeginIndividualPaymentNewDateDisplay from "./redefer-individual-payment-new-date-display";
import { getSessionStorageKey } from "../../../../util/redeferral-util";

interface Props {
  status: RedeferralStatus;
}

export default function RedeferBeginIndividualPayment(props: Props) {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { status } = props;
  const { planId, empId, role } = useSelector((state) => state.session);
  const [errors, setErrors] = useState<string[]>([]);
  const [scheduledDistributions, setScheduledDistributions] = useState<
    ScheduledDistributionForRedeferral[]
  >([]);
  const { isLoadingStatus } = useStatuses([
    scheduledDistributionsForRedeferralStatus,
    marketClosedDatesStatus,
  ]);

  const scheduledDistributionsForRedeferral = useSelector(
    (state) =>
      state.redeferrals.scheduledDistributionsForRedeferral
        .scheduledDistributionsForRedeferral
  );
  const marketCloseDates = useSelector(
    (state) => state.redeferrals.marketCloseDates.marketCloseDates
  );

  useEffect(() => {
    dispatch(
      fetchScheduledDistributionsForRedeferral({
        planId,
        empId,
        accountId: status.accountId,
        role,
      })
    );
    dispatch(fetchMarketCloseDates({ planId, empId, role }));
  }, [dispatch, planId, empId, role]);

  useEffect(() => {
    if (sessionStorage.length > 0 && doesSessionStorageExistForAccount()) {
      setScheduledDistributions(getScheduledDistributions());
    }
    if (sessionStorage.length === 0 || !doesSessionStorageExistForAccount()) {
      scheduledDistributionsForRedeferral.forEach((distribution) => {
        sessionStorage.setItem(
          getSessionStorageKey(distribution.accountId, distribution.installNum),
          JSON.stringify({
            accountId: distribution.accountId,
            planDeferralConfig: distribution.planDeferralConfig,
            minimumRedeferDate: distribution.minimumRedeferDate,
            installNum: distribution.installNum,
            estimatedDistribution: distribution.estimatedDistribution,
            scheduledDate: distribution.scheduledDate,
            status: distribution.status,
            pendingDistributionDate: distribution.pendingDistributionDate,
            isRedeferred: distribution.isRedeferred
              ? distribution.isRedeferred
              : false,
            newDistributionDate: distribution.newDistributionDate
              ? distribution.newDistributionDate
              : null,
          })
        );
      });
      setScheduledDistributions(getScheduledDistributions());
    }
  }, [scheduledDistributionsForRedeferral]);

  function getScheduledDistributions(): ScheduledDistributionForRedeferral[] {
    const distributions: ScheduledDistributionForRedeferral[] = [];
    scheduledDistributionsForRedeferral.forEach((distribution) => {
      const dist = sessionStorage.getItem(
        getSessionStorageKey(status.accountId, distribution.installNum)
      );
      if (dist !== null) {
        const parsedObject = JSON.parse(dist);
        distributions.push(parsedObject);
      }
    });
    return distributions;
  }

  function doesSessionStorageExistForAccount(): boolean {
    const distribution = scheduledDistributionsForRedeferral[0];
    const dist = sessionStorage.getItem(
      getSessionStorageKey(status.accountId, distribution?.installNum)
    );
    return dist !== null;
  }

  function continueToReview() {
    const foundErrors = refreshErrors();
    if (foundErrors.length === 0) {
      history.push(
        `/distributions/redeferral/individual/review/account/${status.accountId}`
      );
    }
  }

  function refreshErrors(): string[] {
    const foundErrors = [];

    let isAtLeastOneRedeferredSelected = false;
    let isNewDistributionDateNotSelected = false;
    scheduledDistributions.forEach((distribution) => {
      if (distribution.isRedeferred) {
        isAtLeastOneRedeferredSelected = true;
      }
      if (
        distribution.isRedeferred &&
        (distribution.newDistributionDate === null ||
          distribution.newDistributionDate === undefined)
      ) {
        isNewDistributionDateNotSelected = true;
      }
    });

    if (!isAtLeastOneRedeferredSelected) {
      foundErrors.push("Please select a distribution you want to re-defer.");
    }
    if (isNewDistributionDateNotSelected) {
      foundErrors.push("Please enter a new distribution date.");
    }

    setErrors(foundErrors);
    return foundErrors;
  }

  const updateData = (
    fieldName: string,
    value: any,
    scheduledDistribution: ScheduledDistributionForRedeferral
  ) => {
    const update = {
      ...scheduledDistribution,
      [fieldName]: value,
    };
    dispatch(updateScheduledDistribution(update));
    sessionStorage.setItem(
      getSessionStorageKey(
        scheduledDistribution.accountId,
        scheduledDistribution.installNum
      ),
      JSON.stringify(update)
    );
    window.dispatchEvent(new Event("sessionUpdate"));
  };

  window.addEventListener("sessionUpdate", () => {
    setScheduledDistributions(getScheduledDistributions());
  });

  if (isLoadingStatus) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      <section id="redefer-begin-header" data-testid="redefer-begin-header">
        <p>
          Select the scheduled distribution you wish to change and enter a new
          distribution date.
        </p>
        <div className="alert alert-warning">
          <p>
            Changes to nonqualified deferred compensation plan elections must be
            made in accordance with IRC Section 409A rules. The rules include
            the requirement that the change must be made at least 12 months
            prior to the scheduled payment date and delays the payment for at
            least 5 years. See Internal Revenue Code Section 409A(a)(4)(C) for
            complete rules.
          </p>
        </div>
      </section>

      <p>
        <strong>{status.accountName}</strong>
      </p>
      <div style={{ marginBottom: 20 }}>
        <table data-testid="redeferral-table" className="table">
          <tbody>
            <tr
              style={{
                background: "#caebfa",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              <td className="table-head">Distribution</td>
              <td className="table-head">Current Distribution Date</td>
              <td className="table-head">Pending Distribution Date</td>
              <td className="table-head">Status</td>
              <td className="table-head">Estimated Distribution</td>
              <td className="table-head">Re-defer this Distribution</td>
              <td className="table-head">New Distribution Date</td>
            </tr>
            {scheduledDistributions.map(
              (
                scheduledDistribution: ScheduledDistributionForRedeferral,
                index
              ) => {
                return (
                  <tr
                    style={
                      index % 2
                        ? { background: "#f1f1f1" }
                        : { background: "white" }
                    }
                  >
                    <td className="table-head" style={{ textAlign: "center" }}>
                      {scheduledDistribution.installNum}
                    </td>
                    <td className="table-head" style={{ textAlign: "center" }}>
                      {formatDateWithoutTimeZone(
                        scheduledDistribution.scheduledDate,
                        "MM/dd/yyy"
                      )}
                    </td>
                    <td className="table-head" style={{ textAlign: "center" }}>
                      {scheduledDistribution.pendingDistributionDate}
                    </td>
                    <td className="table-head" style={{ textAlign: "center" }}>
                      {scheduledDistribution.status}
                    </td>
                    <td className="table-head" style={{ textAlign: "center" }}>
                      <Balance
                        showDollar={true}
                        value={scheduledDistribution.estimatedDistribution}
                      />
                    </td>
                    <td className="table-head" style={{ textAlign: "center" }}>
                      <input
                        type="checkbox"
                        id={`${scheduledDistribution.installNum}-isRedeferred`}
                        name={`${scheduledDistribution.installNum}-isRedeferred`}
                        checked={scheduledDistribution.isRedeferred}
                        onChange={(e) =>
                          updateData(
                            "isRedeferred",
                            !scheduledDistribution.isRedeferred,
                            scheduledDistribution
                          )
                        }
                      />
                    </td>
                    <td className="table-head" style={{ textAlign: "center" }}>
                      <RedeferBeginIndividualPaymentNewDateDisplay
                        scheduledDistribution={scheduledDistribution}
                        marketCloseDates={marketCloseDates}
                      />
                    </td>
                  </tr>
                );
              }
            )}
          </tbody>
        </table>
      </div>

      <hr />
      {errors.length > 0 && (
        <div className="col-md-12">
          <div className="alert alert-danger">
            <p>Please fix errors before continuing:</p>
            <ul>
              {errors.map((error) => (
                <li>{error}</li>
              ))}
            </ul>
          </div>
        </div>
      )}
      <div className="pull-right">
        <Link
          className="btn btn-default util-margin-left-5"
          to={"/distributions/summary"}
        >
          Cancel
        </Link>
        <button
          className="btn btn-primary util-margin-left-5"
          data-testid="continue-button"
          onClick={() => continueToReview()}
        >
          Continue
        </button>
      </div>
    </div>
  );
}
