import React, { useEffect, useState } from "react";
import AccountName from "../../../components/account-name/account-name";
import { LiabilityCalcAccountBalanceTotal } from "../../../components/dashboard/dashboard-account-overview/dashboard-account-overview";
import { LiabilityCalcAccountBalanceVested } from "../../../components/dashboard/dashboard-account-overview/dashboard-account-overview-util";
import Balance from "../../../components/numbers/balance";
import Percent from "../../../components/numbers/percent";
import TableV2 from "../../../components/table/tableV2";
import { Account } from "../../../models/account";
import { useInvestments } from "../../../selectors/investments";
import { hidePrrSelector } from "../../../selectors/plan-info";
import { useSelector } from "../../../store/store";
import {
  calculateTotalEndBalanceV2,
  calculateTotalVestedBalanceV2,
} from "../../../util/investments";
import { mapInvestmentsToAccounts } from "./plan-overview-util";
import { PayoutStatus } from "../../../models/payout-status";
import { statementBalanceSelector } from "../../../selectors/statement-balance";

type Props = {
  showPaidOutAccounts?: boolean;
  showZeroBalanceAccounts?: boolean;
};

export default function PlanOverviewTable(props: Props) {
  const { showPaidOutAccounts, showZeroBalanceAccounts } = props;
  const availableAccounts: Account[] = useSelector(
    (state) => state.accounts.available
  );

  const hidePrr = useSelector(hidePrrSelector);
  const { data: nqAccountData } = useSelector(
    (state) => state.nqAccountDetailsSlice
  );
  const [accounts, setAccounts] = useState(filterAvailableAccounts());
  const { investments } = useInvestments();
  const mappedAccounts = mapInvestmentsToAccounts(investments, accounts);
  const statementBalance = useSelector(statementBalanceSelector);

  useEffect(() => {
    if (showPaidOutAccounts) {
      setAccounts(availableAccounts);
    } else {
      setAccounts(filterAvailableAccounts());
    }
  }, [showPaidOutAccounts, availableAccounts]);

  function filterAvailableAccounts(): Account[] {
    return availableAccounts.filter(
      (account) =>
        account.payoutStatus !== PayoutStatus.COMPLETELY_PAID_OUT &&
        account.payoutStatus !== PayoutStatus.PAID_OUT_HAS_BALANCE
    );
  }

  if (availableAccounts.length === 0) {
    return (
      <>
        <p>No accounts available.</p>
      </>
    );
  }

  const basePRR = statementBalance.dashboardBalance?.balanceInformation?.prr;
  const totalPRR = basePRR / 100;
  const prrColumn = {
    Header: <div className="bold"> Personalized Rate of Return</div>,
    accessor: "prr",
    Footer: (
      <div className={"bold"}>
        <Percent value={totalPRR} />
      </div>
    ),
  };

  const columns = [
    {
      Header: <div className="bold">Account</div>,
      accessor: "name",
      Footer: <div className="bold">Total</div>,
    },
    {
      Header: <div className="bold"> Current Balance</div>,
      accessor: "currentBalance",
      Footer: (
        <div className="bold">
          {nqAccountData && (
            <LiabilityCalcAccountBalanceTotal account={nqAccountData} />
          )}
        </div>
      ),
    },
    {
      Header: <div className="bold">Vested Balance</div>,
      accessor: "vestedBalance",
      Footer: (
        <div>
          {nqAccountData && (
            <LiabilityCalcAccountBalanceVested account={nqAccountData} />
          )}
        </div>
      ),
    },
  ];

  if (!hidePrr) {
    columns.splice(1, 0, prrColumn);
  }

  function buildTableData(mappedAccounts: any[]) {
    const processedAccounts = mappedAccounts.map((account) => {
      const accountTotalPRR =
        statementBalance.accountPrrs?.filter(
          (statementAccount) =>
            statementAccount.accountId === account.id.toString()
        )[0].prr / 100;

      const currentBalance = calculateTotalEndBalanceV2(account.investments);
      const vestedBalance = calculateTotalVestedBalanceV2(account.investments);
      return {
        name: <AccountName name={account.name} id={account.id} />,
        prr: <Percent value={accountTotalPRR} />,
        currentBalance: <Balance showDollar value={currentBalance} />,
        vestedBalance: <Balance showDollar value={vestedBalance} />,
      };
    });

    // we don't have the total balances for each account until this point
    if (!showZeroBalanceAccounts) {
      return processedAccounts.filter((account) => {
        return account.currentBalance.props.value !== 0;
      });
    }

    return processedAccounts;
  }

  const data = buildTableData(mappedAccounts);

  return (
    <div className={"balances-by-accounts-table"}>
      <TableV2 columns={columns} data={data} />
    </div>
  );
}
