import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AsyncStatus } from "../../models/async-status";
import { getPaymentInfo } from "../../services/plans";
import { PaymentInfo } from "../../models/payment-info";

export const fetchPaymentInfo = createAsyncThunk(
  "fetch-payment-info",
  async (
    { planId, empId, role }: { planId: number; empId: number; role: string },
    thunkApi
  ) => {
    return getPaymentInfo({ planId, empId, role });
  }
);

const initialState: PaymentInfo = {
  bankState: "",
  bankRoutingNumber: "",
  bankName: "",
  bankAccountType: "",
  bankAccountNumber: "",
  bankAccountName: "",
  employeeId: undefined,
  planId: undefined,
  wireACH: "",
  forFurtherCreditFFC: "",
  wireAccountName: "",
  wireAccountNumber: "",
  wireAccountType: "",
  wireBankName: "",
  wireRoutingNumber: "",
  wireState: "",
  status: AsyncStatus.not_fetched,
};

const planPaymentInfoSlice = createSlice({
  name: "payment-info",
  initialState,
  reducers: {
    setPaymentInfo: (state, action: { payload: PaymentInfo }) => {
      return { ...state, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPaymentInfo.pending, (state, action) => {
      return { ...state, status: AsyncStatus.loading };
    });
    builder.addCase(fetchPaymentInfo.fulfilled, (state, action) => {
      return { ...state, ...action.payload, status: AsyncStatus.done };
    });
    builder.addCase(fetchPaymentInfo.rejected, (state, action) => {
      return {
        ...state,
        status: AsyncStatus.error,
      };
    });
    builder.addDefaultCase((state, action) => state);
  },
});

export const { setPaymentInfo } = planPaymentInfoSlice.actions;
export default planPaymentInfoSlice.reducer;
