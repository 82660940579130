export class Logger {
  public error(message: any, args: any): LogFunction {
    return () => console.log(message, args);
  }
  public warn(message: any, args: any): LogFunction {
    return () => console.log(message, args);
  }
  public info(message: any, args: any): LogFunction {
    return () => console.log(message, args);
  }
  public debug(message: any, args: any): LogFunction {
    return () => console.log(message, args);
  }
}
export interface LogFunction {
  (message: string, ...args: string[]): void;
  <T extends object>(
    extraContext: T,
    message?: string,
    ...args: string[]
  ): void;
}
