import isEqual from "date-fns/isEqual";
import React, { useState, useEffect } from "react";
// @ts-ignore
import useDimensions from "react-use-dimensions";

import { StylesOverride } from "./types/styles";
import { DataPoint, DataPointDisplay } from "./types/data-point";
import { PointFormatter } from "./types/point-formatter";
import { Axis } from "./axis/axis";
import { Points } from "./point/points";
import {
  defaultStyles,
  buildStyles,
  calculateDomain,
  calculateScale,
  groupByDate,
} from "./util";

import "./timeline.scss";

export type TimelineProps = {
  keyName?: string;
  data: DataPoint[];
  styles?: StylesOverride;
  onSelect: Function;
  pointFormatter?: PointFormatter;
  groupName?: string;
  startDate?: Date;
  selectedDate?: Date;
};

export function Timeline(props: TimelineProps) {
  const [ref, { width }] = useDimensions();

  const {
    data,
    keyName = "value",
    onSelect,
    selectedDate,
    pointFormatter,
    groupName = "payments",
    startDate,
  } = props;
  const styles = buildStyles(props.styles || defaultStyles);

  const [dataMinDate, dataMaxDate] = calculateDomain(data);
  const minDate = startDate ? startDate : dataMinDate;
  const scale = calculateScale(minDate, dataMaxDate, styles, width);

  const [groupedData, setGroupedData] = useState<DataPointDisplay[]>([]);
  useEffect(() => {
    setGroupedData(groupByDate(data, keyName));
  }, [data, keyName]);

  function handleSelect(date: Date) {
    if (!selectedDate || !isEqual(selectedDate, date)) {
      onSelect(date);
    }
  }

  return (
    <div ref={ref} className="timeline-wrapper">
      <svg
        version="1.1"
        viewBox={`0 0 ${width} ${styles.height}`}
        className="timeline"
      >
        <Axis
          minDate={minDate}
          maxDate={dataMaxDate}
          styles={styles}
          scale={scale}
          width={width}
        />
        <Points
          data={groupedData}
          scale={scale}
          keyName={keyName}
          styles={styles}
          handleSelect={handleSelect}
          selectedDate={selectedDate}
          pointFormatter={pointFormatter}
          groupName={groupName}
        />
      </svg>
    </div>
  );
}
