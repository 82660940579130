import _get from "lodash/get";
import _sumBy from "lodash/sumBy";
import React from "react";
import AccountName from "../../../components/account-name/account-name";
import Balance from "../../../components/numbers/balance";
import Percent from "../../../components/numbers/percent";
import TableV2 from "../../../components/table/tableV2";
import {useInvestments} from "../../../selectors/investments";
import {hidePrrSelector} from "../../../selectors/plan-info";
import {statementBalanceSelector} from "../../../selectors/statement-balance";
import {useSelector} from "../../../store/store";
import {ACCOUNT_CATEGORY_409A, ACCOUNT_CATEGORY_PRE_409A,} from "../../../util/constants/account-category-409A";
import {aggregateByAccountId, flatten,} from "../../../util/investment-context-util";
import {getPrrValue} from "../../../models/statements/statement-balance";

function StatementsOverviewTable() {
  const { available: accounts } = useSelector((state) => state.accounts);
  const hidePrr = useSelector(hidePrrSelector);
  const { investments } = useInvestments();
  const statementBalance = useSelector(statementBalanceSelector);
  const investmentsByAccount = flatten(aggregateByAccountId(investments));

  const mappedAccounts = accounts.map((account) => {
    const investment = investmentsByAccount.find(
      (investment) => investment.accountId === account.id
    );

    const total = _get(investment, "endBalance.cashBalance.balance", 0);
    const vested = _get(investment, "endBalance.cashBalance.vestedBalance", 0);
    const timeWeightedBalance = _get(investment, "timeWeightedBalance", 0);
    const earningAmt = _get(investment, "earningAmt", 0);

    return { ...account, total, vested, timeWeightedBalance, earningAmt };
  });

  const accounts409 = mappedAccounts.filter(
    (account) => account.category409A === ACCOUNT_CATEGORY_409A
  );
  const accountsPre409 = mappedAccounts.filter(
    (account) => account.category409A === ACCOUNT_CATEGORY_PRE_409A
  );

  const data = [...accountsPre409, ...accounts409];

  const dataWithNameNode = data.map((account) => {
    account.accountNameNode = (
      <AccountName name={account.name} id={account.id} asLink={false} />
    );
    return account;
  });

  const columns = [
    {
      Header: "",
      accessor: "accountNameNode",
      Footer: () => (
        <>
          <div>Total Account Balance</div>
          {!hidePrr && <div>Personalized rate of return</div>}
        </>
      ),
    },
    {
      Header: "Total",
      accessor: "total",
      className: "right",
      Cell: (props) => {
        return <Balance value={props.value} showDollar />;
      },
      Footer: (info) => {
        const total = React.useMemo(
          () => _sumBy(info.rows, "values.total"),
          [info.rows]
        );
        const earningAmt = React.useMemo(
          () => _sumBy(info.rows, "values.earningAmt"),
          [info.rows]
        );
        const timeWeightedBalance = React.useMemo(
          () => _sumBy(info.rows, "values.timeWeightedBalance"),
          [info.rows]
        );

        return (
          <>
            <div>
              <Balance value={total} showDollar />
            </div>
            <div>
              {!hidePrr && (
                <Percent
                  value={getPrrValue(statementBalance)}
                  defaultValue={"N/A"}
                  showSymbol
                />
              )}
            </div>
          </>
        );
      },
    },
    {
      Header: "Vested",
      accessor: "vested",
      Cell: ({ value }) => {
        return <Balance value={value} showDollar />;
      },
      Footer: (info) => {
        const total = React.useMemo(
          () => _sumBy(info.rows, "values.vested"),
          [info.rows]
        );

        return (
          <div>
            <Balance value={total} showDollar />
          </div>
        );
      },
      className: "right",
    },
    {
      Header: "Earning Amount",
      accessor: "earningAmt",
    },
    {
      Header: "Time-weighted Balance",
      accessor: "timeWeightedBalance",
    },
  ];

  return (
    <TableV2
      initialState={{ hiddenColumns: ["earningAmt", "timeWeightedBalance"] }}
      columns={columns}
      data={dataWithNameNode}
      className="statements-overview-table"
    />
  );
}

export default StatementsOverviewTable;
