import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AsyncStatus } from "../../models/async-status";
import { getRedeferralInstructionDTOsForAccount } from "../../services/redeferral";
import { RedeferralInstructionDto } from "../../models/redeferral/redeferral-instruction-dto";

export const fetchRedeferralInstructionDtos = createAsyncThunk(
  "fetch-redeferral-instruction-dtos",
  async (
    { accountId, role }: { accountId: number; role: string },
    thunkApi
  ) => {
    return getRedeferralInstructionDTOsForAccount(role, accountId);
  }
);

type State = {
  redeferralInstructionDTOs: RedeferralInstructionDto[];
  status: AsyncStatus;
};

const initialState: State = {
  status: AsyncStatus.not_fetched,
  redeferralInstructionDTOs: [],
};

const redeferralInstructionDtosSlice = createSlice({
  name: "redeferral-instruction-dtos",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchRedeferralInstructionDtos.pending, (state, action) => {
      return { ...state, status: AsyncStatus.loading };
    });
    builder.addCase(
      fetchRedeferralInstructionDtos.fulfilled,
      (state, action: { payload: RedeferralInstructionDto[] }) => {
        const redeferralInstructionDTOs = action.payload;

        return {
          ...state,
          redeferralInstructionDTOs,
          status: AsyncStatus.done,
        };
      }
    );
    builder.addCase(
      fetchRedeferralInstructionDtos.rejected,
      (state, action) => {
        return {
          ...state,
          status: AsyncStatus.error,
        };
      }
    );
    builder.addDefaultCase((state, action) => state);
  },
});

export default redeferralInstructionDtosSlice.reducer;
