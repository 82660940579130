import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AsyncStatus } from "../../models/async-status";
import { Message, MessageName } from "../../models/message";
import { getMessages } from "../../services/messages";

export const fetchMessages = createAsyncThunk(
  "fetch-messages",
  async (
    {
      planId,
      empId,
      role,
      messageTypesParam,
    }: {
      planId: number;
      empId: number;
      role: string;
      messageTypesParam?: MessageName[];
    },
    thunkApi
  ) => {
    return getMessages({ planId, empId, role, messageTypesParam });
  }
);
type State = {
  messages: Message[];
  status: AsyncStatus;
};

const initialState: State = {
  messages: [],
  status: AsyncStatus.not_fetched,
};

const messagesSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMessages.pending, (state, action) => {
      return { ...state, status: AsyncStatus.loading };
    });
    builder.addCase(
      fetchMessages.fulfilled,
      (state, action: { payload: Message[]; type: string }) => {
        const messages = action.payload;

        return {
          ...state,
          messages,
          status: AsyncStatus.done,
        };
      }
    );
    builder.addCase(fetchMessages.rejected, (state, action) => {
      return { ...state, status: AsyncStatus.error };
    });
    builder.addDefaultCase((state, action) => state);
  },
});

export default messagesSlice.reducer;
