import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AsyncStatus } from "../../models/async-status";
import { getAvailableEventsForAllProvisions } from "../../services/distributions";
import { UnscheduledDistributionEvent } from "../../models/distributions/unscheduled-distribution-event";

export const fetchUnscheduledDistributionProvisionEvents = createAsyncThunk(
  "fetch-unscheduled-events-by-provision",
  async (
    { planId, empId, role }: { planId: number; empId: number; role: string },
    thunkApi
  ) => {
    return getAvailableEventsForAllProvisions({ planId, empId, role });
  }
);

type State = {
  availableEventsForAllProvisions: UnscheduledDistributionEvent[];
  status: AsyncStatus;
};

const initialState: State = {
  availableEventsForAllProvisions: [],
  status: AsyncStatus.not_fetched,
};

const unscheduledDistributionEventsForAllProvisionsSlice = createSlice({
  name: "unscheduledDistributionEventsForAllProvisionsSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchUnscheduledDistributionProvisionEvents.pending,
      (state, action) => {
        return { ...state, status: AsyncStatus.loading };
      }
    );
    builder.addCase(
      fetchUnscheduledDistributionProvisionEvents.fulfilled,
      (
        state,
        action: { payload: UnscheduledDistributionEvent[]; type: string }
      ) => {
        const availableEventsForAllProvisions = action.payload;

        return {
          ...state,
          availableEventsForAllProvisions,
          status: AsyncStatus.done,
        };
      }
    );
    builder.addCase(
      fetchUnscheduledDistributionProvisionEvents.rejected,
      (state, action) => {
        return { ...state, status: AsyncStatus.error };
      }
    );
    builder.addDefaultCase((state, action) => state);
  },
});

export default unscheduledDistributionEventsForAllProvisionsSlice.reducer;
