import React from "react";
import FixedDecimal, { getRoundedValue } from "./fixed-decimal";

type Props = {
  value: number;
  showDollar?: boolean;
  precision?: number;
};

export default function Balance(props: Props) {
  const { value, showDollar, precision } = props;
  const roundTo = precision ?? 2;
  if (showDollar) {
    if (value < 0) {
      const roundedValue = getRoundedValue(value, roundTo);
      if (showDollar) {
        return <>-${roundedValue}</>;
      }
      return <>-{roundedValue}</>;
    }

    return (
      <>
        $<FixedDecimal value={value} precision={roundTo} />
      </>
    );
  }
  return <FixedDecimal value={value} precision={roundTo} />;
}
