import React from "react";

export function BeneficiariesInfoTextDisplay() {
  return (
    <p>
      Tell us who should receive your retirement account benefits should you
      pass away. Review your designations from time to time, or when life
      changes happen, to make sure they still match your wishes.
    </p>
  );
}
