import { extent } from "d3-array";
import { scaleTime } from "d3-scale";
import _get from "lodash/get";
import _groupBy from "lodash/groupBy";
import _merge from "lodash/merge";
import _range from "lodash/range";
import { DataPoint, DataPointDisplay } from "./types/data-point";

import { Styles, StylesOverride } from "./types/styles";

export const defaultStyles: Styles = {
  height: 150,
  margin: {
    left: 60,
    right: 60,
    bottom: 30,
    top: 0,
  },
  tick: {
    marginTop: 20,
    fontSize: 16,
  },
  axis: {
    y: 30,
  },
  point: {
    height: 30,
    width: 100,
    stemHeight: 40,
    fontSize: 16,
  },
};

export function buildStyles(overrides: StylesOverride): Styles {
  return _merge({}, defaultStyles, overrides);
}

export function calculateDomain(data: DataPoint[]): [Date, Date] {
  if (data.length <= 1) {
    const date = _get(data, "[0].date", new Date());
    return [
      dateAtStartOfYear(date.getFullYear()),
      dateAtEndOfYear(date.getFullYear()),
    ];
  }

  const [minDate = new Date(), maxDate = new Date()] = extent(
    data,
    (obj) => obj.date
  );

  return [
    dateAtStartOfYear(minDate.getFullYear()),
    dateAtEndOfYear(maxDate.getFullYear()),
  ];
}

export function calculateScale(
  minDate: Date,
  maxDate: Date,
  styles: Styles,
  width: number
) {
  return scaleTime()
    .domain([dateAtStartOfYear(minDate.getFullYear()), maxDate])
    .range([styles.margin.left, width - styles.margin.right]);
}

export function createDateRange(minYear: number, maxYear: number): Date[] {
  if (minYear === maxYear) {
    return [dateAtStartOfYear(minYear), dateAtStartOfYear(minYear + 1)];
  }
  return _range(minYear, maxYear + 1).map(dateAtStartOfYear);
}
export function dateAtStartOfYear(year: number): Date {
  return new Date(year, 0, 1);
}

export function dateAtEndOfYear(year: number): Date {
  return new Date(year, 11, 31);
}

export function calculateY(styles: Styles, yHeight: number): number {
  return styles.height - yHeight;
}

export function groupByDate(
  data: DataPoint[],
  keyName: string
): DataPointDisplay[] {
  const grouped = _groupBy(data, "date");
  return Object.values(grouped).map((items: any): DataPointDisplay => {
    const { date } = items[0];
    let color;
    const elementWithColor = items.find(({ color }: DataPoint) => color);
    if (elementWithColor) {
      color = elementWithColor.color;
    }
    return {
      date,
      values: items.map((obj: DataPoint) => obj[keyName]),
      color,
    };
  });
}

export function moveToEnd(
  arr: any[],
  matches: (point: any) => Boolean
): DataPointDisplay[] {
  const newArr = [...arr];
  const index = newArr.findIndex(matches);
  const spliced = newArr.splice(index, 1);
  return newArr.concat(spliced);
}
