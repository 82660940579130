import * as participantRepo from "../repositories/participant";
import { StatementBalance } from "../models/statements/statement-balance";
import { getUtcDateString, yyyy_mm_dd } from "../util/date-util";

export type GetStatementBalanceArgs = {
  contractId: string;
  empId: number;
  role: string;
  beginDate?: string;
  endDate?: string;
};

export async function getStatementBalance({
  contractId,
  empId,
  role,
  beginDate,
  endDate,
}: GetStatementBalanceArgs): Promise<StatementBalance> {
  let queryParamStartDate;
  let queryParamEndDate;
  if (beginDate) {
    queryParamStartDate = getUtcDateString(beginDate, yyyy_mm_dd);
  }
  if (endDate) {
    queryParamEndDate = getUtcDateString(endDate, yyyy_mm_dd);
  }
  return participantRepo.get(
    `/employees/${empId}/contract/${contractId}/statement-balance`,
    { beginDate: queryParamStartDate, endDate: queryParamEndDate, role }
  );
}
