import { InstallmentFrequency } from "../distributions/installment-frequency";
import { InstallmentType } from "../distributions/installment-type";
import { ScheduledDistributionForRedeferral } from "../distributions/scheduled-distribution-event";
import { PaymentType } from "../distributions/payment-type";
import { formatDateWithoutTimeZone, yyyy_mm_dd } from "../../util/date-util";

export interface Props {
  scheduledDistribution: ScheduledDistributionForRedeferral;
}

export class RedeferralInstruction {
  accountId: number;
  installmentNumber: number;
  payoutStartDate: string;
  previousPayoutStartDate: string;
  accountName: string;
  payoutMethodType: PaymentType;
  installmentFrequency: InstallmentFrequency;
  installmentType?: InstallmentType;
  installmentYears?: number;

  constructor(scheduledDistribution: ScheduledDistributionForRedeferral) {
    this.accountId = scheduledDistribution.accountId;
    this.installmentNumber = scheduledDistribution.originalInstallmentNumber;
    this.payoutStartDate = scheduledDistribution.newDistributionDate
      ? formatDateWithoutTimeZone(
          scheduledDistribution.newDistributionDate,
          yyyy_mm_dd
        )
      : formatDateWithoutTimeZone(
          scheduledDistribution.minimumRedeferDate,
          yyyy_mm_dd
        );
    this.previousPayoutStartDate = formatDateWithoutTimeZone(
      scheduledDistribution.scheduledDate,
      yyyy_mm_dd
    );
    this.accountName = scheduledDistribution.accountName;
    this.payoutMethodType = scheduledDistribution.paymentType;
    this.installmentFrequency = scheduledDistribution.installmentFrequency;
    this.installmentType = scheduledDistribution.installmentType;
    this.installmentYears = scheduledDistribution.totalInstallments;
  }
}
