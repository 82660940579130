import { ErrorMessage } from "@hookform/error-message";
import classNames from "classnames";
import React, { useState } from "react";
import { RegisterOptions } from "react-hook-form";
import "./form-input.scss";
import { AlertLevel } from "../../models/alert";
import Alert from "../../pages/errors/alert";

type FormInputProps = {
  register: (options: any) => React.Ref<any>;
  name: string;
  text: string;
  disabled?: boolean;
  options?: RegisterOptions;
  pattern?: string;
  errorMessage?: string;
  errors: any;
  hasError?: any;
  maxLength?: number;
  readOnly?: boolean;
  type?: string;
  prefix?: string;
  suffix?: string;
  increment?: number;
  hasWhiteSpace?: boolean;
  validateDeferralLimit?: any;
};

export function FormInput(props: FormInputProps) {
  const {
    maxLength,
    register,
    pattern,
    name,
    errorMessage,
    text,
    disabled,
    options,
    errors,
    hasError,
    readOnly,
    type,
    prefix,
    suffix,
    increment,
    hasWhiteSpace,
    validateDeferralLimit,
  } = props;
  const [shouldDisplayError, setShouldDisplayError] = useState(false);
  const [displayErrorMessage, setDisplayErrorMessage] = useState("");
  const className = classNames("form-group", {
    "has-error": hasError,
    "whitespace-no-wrap": !hasWhiteSpace,
  });
  const inputClassName = prefix ? "input-prefix" : suffix ? "input-suffix" : "";

  function changeHandler(event: any) {
    event.target.setCustomValidity("");
    props.validateDeferralLimit && validateDeferralLimit(event.target.value);
    if (event.target.value !== undefined && pattern) {
      const regex = new RegExp(pattern);
      if (regex.test(event.target.value)) {
        setShouldDisplayError(false);
      } else {
        if (errorMessage === "SsnTinBox") {
          const format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
          if (format.test(event.target.value)) {
            setDisplayErrorMessage("Please input without dashes");
          } else {
            setDisplayErrorMessage("Please input only 9 characters");
          }
        }
      }
    }
  }

  function markErrorInFormatting(event: any) {
    setShouldDisplayError(true);
    if (errorMessage === undefined || !errorMessage) {
      setDisplayErrorMessage("Invalid Response");
    } else {
      if (errorMessage === "SsnTinBox") {
        const format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
        if (format.test(event.target.value)) {
          setDisplayErrorMessage("Please input without dashes");
        } else {
          setDisplayErrorMessage("Please input only 9 characters");
        }
      } else {
        setDisplayErrorMessage(errorMessage);
      }
    }
  }

  function blurHandler(event: any) {
    if (pattern && event.target.value !== undefined) {
      const regex = new RegExp(pattern);
      if (regex.test(event.target.value)) {
        setShouldDisplayError(false);
      } else {
        markErrorInFormatting(event);
      }
    }
  }

  function invalidHandler(event: any) {
    event.target.setCustomValidity(" ");
    markErrorInFormatting(event);
  }

  return (
    <div className={className}>
      <span>
        <label htmlFor={name}>
          {text}
          <small className="optional">
            {options?.required || readOnly ? "" : " (optional)"}
          </small>
        </label>
      </span>
      <div className="form-group">
        <div className={inputClassName}>
          {prefix && <span className="prefix">{prefix}</span>}
          {suffix && <span className="suffix">{suffix}</span>}
          <input
            maxLength={maxLength}
            type={type}
            pattern={pattern}
            readOnly={readOnly}
            data-testid={name}
            id={name}
            name={name}
            disabled={disabled}
            className={"form-control"}
            aria-invalid={errors.name ? "true" : "false"}
            ref={register(options)}
            step={increment}
            onInvalid={invalidHandler}
            onBlur={blurHandler}
            onChange={changeHandler}
          />
        </div>
      </div>
      <div>
        <Alert
          alertLevel={AlertLevel.DANGER}
          showAlert={shouldDisplayError}
          message={displayErrorMessage}
        />
      </div>
      <ErrorMessage errors={errors} name={name} />
    </div>
  );
}
