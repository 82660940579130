import React from "react";
import { Panel, PanelBody, PanelHeading } from "../../components/panel/panel";
import PrintLink from "../../components/print-link/print-link";
import AccountsDisplay from "./account-display/accounts-display";
import AccountSnapshotSection from "./account-snapshot-section";
import StatementPrintHeader from "./statement-print-header";
import StatementsDisclaimer from "./statements-disclaimer";
import { useSelector } from "../../store/store";
import {
  hideAllAccountsSelector,
  hideIndividualAccountSelector,
} from "../../selectors/plan-info";

function StatementInformationPanel() {
  const hideAllAcounts = useSelector(hideAllAccountsSelector);
  const hideIndividualAccounts = useSelector(hideIndividualAccountSelector);

  return (
    <Panel noPrint>
      <PanelHeading noPrint center>
        <PrintLink removeHeader pdfText />
      </PanelHeading>
      <PanelBody>
        <StatementPrintHeader />
        {!hideAllAcounts && <AccountSnapshotSection />}
        {!hideIndividualAccounts && <AccountsDisplay />}
        <StatementsDisclaimer />
      </PanelBody>
      <PanelHeading center noPrint>
        <PrintLink removeHeader pdfText />
      </PanelHeading>
    </Panel>
  );
}

export default StatementInformationPanel;
