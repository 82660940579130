import { get } from "../repositories/participant";
import { Message, MessageName } from "../models/message";

type Args = {
  planId: number;
  empId: number;
  role: string;
  messageTypesParam?: MessageName[];
};

export async function getMessages({
  planId,
  empId,
  role,
  messageTypesParam,
}: Args): Promise<Message[]> {
  const messageTypes: string | null = messageTypesParam
    ? messageTypesParam.join(",")
    : null;
  if (messageTypes) {
    return get(`/plans/${planId}/employees/${empId}/messages`, {
      role,
      messageTypes,
    });
  } else {
    return get(`/plans/${planId}/employees/${empId}/messages`, {
      role,
    });
  }
}
