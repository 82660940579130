import React, { useState } from "react";
import Balance from "../../../components/numbers/balance";
import Percent from "../../../components/numbers/percent";
import { ContributionType } from "../../../models/contributions";
import { useSelector } from "../../../store/store";
import ContributionLimits from "./contribution-limits";
import ContributionSalaryInteraction from "./contribution-salary-interaction";
import "./manage-contributions-calculations-box.scss";
import ManageContributionsFormOutput from "./manage-contributions-form-output";
import {
  calculateContributionOutput,
  calculatePaycheckAmount,
  calculateTotalAnnualAmount,
  ContributionOutput,
  getPayChecksPerYear,
  getPayFrequencyText,
} from "./manage-contributions-utilities";
import { PayCheckFrequency } from "./paycheck-frequency";
import ManageContributionsBonusPayFrequencySelector from "./manage-contributions-bonus-pay-frequency-selector";

type Props = {
  allFields: any;
  register?: any;
  errors?: any;
  isReview: any;
};

export default function ManageContributionsCalculationsBox(
  props: Props
): JSX.Element {
  const contributionRates = useSelector(
    (state) => state.contributions.contributionRates.data
  );
  const [expandDropdown, setExpandDropdown] = useState(false);
  const { allFields, register, errors, isReview } = props;

  const salaryAmount = allFields.currentSalary;
  const payCheckFrequency = allFields.payCheckFrequency;
  const bonusPayCheckFrequency =
    allFields.bonusPayCheckFrequency || PayCheckFrequency.USEPAYCHECKFREQUENCY;

  const payCheckAmount = calculatePaycheckAmount(
    salaryAmount,
    payCheckFrequency
  );

  const bonusPayCheckAmount = calculatePaycheckAmount(
    salaryAmount,
    bonusPayCheckFrequency
  );

  const contributionOutputs: ContributionOutput[] = contributionRates.map(
    (compensationType) => {
      const { contributionTypeName: name, contributionTypeId } =
        compensationType;
      const type = allFields[`${name}Type`];
      const value = allFields[`${name}Value`];
      const payInfo =
        bonusPayCheckFrequency !== PayCheckFrequency.USEPAYCHECKFREQUENCY &&
        contributionTypeId === 2
          ? bonusPayCheckAmount
          : payCheckAmount;
      return calculateContributionOutput(
        payInfo,
        type,
        value,
        name,
        contributionTypeId
      );
    }
  );

  const totalModifiedAmount: number = contributionOutputs
    .filter((contrib) => {
      return (
        contrib.type !== ContributionType.OTHER &&
        contrib.contributionTypeId !== 2
      );
    })
    .reduce((sum, contrib) => {
      return Number(contrib.contributionAmount) + sum;
    }, 0);

  const totalContributionAmount: number = contributionOutputs
    .filter((contrib) => {
      return (
        contrib.type !== ContributionType.OTHER &&
        contrib.contributionTypeId === 2
      );
    })
    .reduce((sum, contrib) => {
      return Number(contrib.contributionAmount);
    }, 0);

  const totalCheckPercentage: number = contributionOutputs
    .filter((contrib) => {
      return (
        contrib.type !== ContributionType.OTHER &&
        contrib.contributionTypeId !== 2
      );
    })
    .reduce((sum, contrib) => {
      return Number(contrib.salaryPercentage) + sum;
    }, 0);

  const payCheckAnnualAmount = calculateTotalAnnualAmount(
    totalModifiedAmount,
    payCheckFrequency
  );

  const isBonus =
    bonusPayCheckFrequency !== PayCheckFrequency.USEPAYCHECKFREQUENCY;
  const payCheckFrequencyText = getPayFrequencyText(payCheckFrequency);
  const payChecksPerYear = getPayChecksPerYear(payCheckFrequency);
  const bonusPayChecksPerYear = getPayChecksPerYear(bonusPayCheckFrequency);
  const bonusPayCheckFrequencyText = getPayFrequencyText(
    bonusPayCheckFrequency
  );

  const paymentAnnualAmount = calculateTotalAnnualAmount(
    totalContributionAmount,
    isBonus ? bonusPayCheckFrequency : payCheckFrequency
  );

  const totalAnnualAmount = payCheckAnnualAmount + paymentAnnualAmount;

  const toggleFrequencyDropdown = () => {
    setExpandDropdown(!expandDropdown);
  };

  return (
    <div
      className="manage-contributions-calculate"
      data-testid={"manage-contributions-calculate"}
    >
      <div className="blue-box">
        {register && (
          <ContributionSalaryInteraction
            register={register}
            errors={errors}
            allFields={allFields}
          />
        )}
        <div className="manage-contributions-spacing">
          You would be saving a total of{" "}
          <Percent value={totalCheckPercentage} /> of your paycheck.{" "}
          <ShowInformationText />
        </div>
        {contributionOutputs &&
          contributionOutputs.map((contributionOutput, index) => {
            return (
              <div key={"key-" + index}>
                <div className="bonus-pay-frequency" key={index}>
                  <ManageContributionsFormOutput
                    key={index}
                    isBonus={isBonus && index === 1}
                    contributionOutput={contributionOutput}
                    payCheckFrequencyText={
                      isBonus && index === 1
                        ? bonusPayCheckFrequencyText
                        : payCheckFrequencyText
                    }
                  />
                </div>
                {index === 1 && !isReview ? (
                  <div className="contribution-bonus-pay-frequency">
                    <div
                      className="frequency-dropdown"
                      onClick={() => toggleFrequencyDropdown()}
                    >
                      <span className="bold text-primary">
                        Change Frequency
                      </span>
                      <span
                        className={`frequency-dropdown__icon ${
                          expandDropdown ? "up" : "down"
                        }`}
                      ></span>
                    </div>
                    <div className={expandDropdown ? "show" : "hide"}>
                      <ManageContributionsBonusPayFrequencySelector
                        key={index}
                        register={register}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            );
          })}

        <div className="manage-contributions-summary bold">
          Estimated <Balance value={totalModifiedAmount} showDollar /> saved{" "}
          {payCheckFrequencyText}.
        </div>
        <div className="manage-contributions-summary bold">
          Estimated <Balance value={totalContributionAmount} showDollar /> saved
          every bonus payment.
        </div>
        <div className="manage-contributions-summary bold">
          Could total <Balance value={totalAnnualAmount} showDollar /> annually
          ({payChecksPerYear} paychecks plus{" "}
          {isBonus ? bonusPayChecksPerYear : payChecksPerYear}{" "}
          {isBonus ? bonusPayCheckFrequency : ""} bonus check).
        </div>
        <ContributionLimits
          totalModifiedAmount={totalModifiedAmount}
          allFields={allFields}
          totalContributionAmount={totalContributionAmount}
        />
      </div>
    </div>
  );
}

function ShowInformationText() {
  return (
    <i
      className="fa fa-info-circle icon-blue"
      title="This is an estimated percentage based on your inputs."
    ></i>
  );
}
