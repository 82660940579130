import React from "react";
import { useHistory } from "react-router-dom";

function Error404() {
  const history = useHistory();

  function handleClick() {
    history.goBack();
  }

  return (
    <div className="bg">
      <main
        className="svg-page error-404 util-focus-visible-only"
        id="main-content"
        tabIndex={-1}
      >
        <div className="text-center">
          <div>
            <h1>We’re sorry.</h1>
            <p>
              We've looked everywhere and can't seem to find the page you're
              looking for.
            </p>
            <p>
              <button
                data-testid="returnToPrevious"
                onClick={handleClick}
                className="btn btn-link btn-link-inline"
              >
                <i className="fa fa-angle-left" aria-hidden="true" /> Head back
                to the previous page
              </button>
            </p>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Error404;
