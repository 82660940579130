import React from "react";
import "./pending-distributions-button.scss";

type Props = {
  link: string;
  buttonText: string;
  isAdvisorOrEmployer: boolean;
  primaryButton?: boolean;
};

export default function PendingDistributionsButton(props: Props) {
  const { link, buttonText, isAdvisorOrEmployer, primaryButton } = props;

  const primaryButtonClasses =
    "pending-distribution-button btn btn-primary btn-sm cta-text";
  const defaultButtonClasses =
    "pending-distribution-button btn btn-default btn-sm cta-text";
  const classList = primaryButton ? primaryButtonClasses : defaultButtonClasses;
  return isAdvisorOrEmployer ? (
    <button className={classList} disabled>
      {buttonText}
    </button>
  ) : (
    <a
      className={classList}
      data-testid="pending-distribution-button"
      href={link}
    >
      {buttonText}
    </a>
  );
}
