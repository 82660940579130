import React from "react";
import "./link-button.scss";

function LinkButton(props) {
  return (
    <button {...props} className={`link-button link ${props.className}`}>
      {props.children}
    </button>
  );
}

export default LinkButton;
