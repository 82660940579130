import React from "react";
import { useTable } from "react-table";
import "./table-v2.scss";
import _get from "lodash/get";

TableV2.defaultProps = {
  hasFooter: true,
};

function TableV2(props) {
  const { columns, data, initialState, tableName, hasFooter, updateData } =
    props;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
    initialState,
    updateData,
  });

  React.useEffect(() => {
    if (typeof window.$ !== "undefined") {
      window
        .$(".color-cell[data-toggle='tooltip']")
        .tooltip({ container: "body" });
    }
  }, []);

  return (
    <div
      className={`custom-table-wrapper ${props.className}`}
      data-testid={tableName}
    >
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                return column.hideHeader === true ? null : (
                  <th
                    {...column.getHeaderProps([
                      { className: column.className },
                    ])}
                  >
                    {column.render("Header")}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} data-testid="table-body">
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  if (cell.column.isColorCell) {
                    const assetClassProps = row.original.assetClass
                      ? {
                          tabIndex: 0,
                          "data-toggle": "tooltip",
                          title: row.original.assetClass,
                        }
                      : {};
                    return (
                      <td
                        {...cell.getCellProps()}
                        className={`color-cell ${_get(cell, "className", "")}`}
                        style={{
                          backgroundColor: `#${_get(cell, "value", "fff")}`,
                        }}
                        {...assetClassProps}
                      >
                        <span className="sr-only">
                          {row.original.assetClass}
                        </span>
                      </td>
                    );
                  }
                  return (
                    <td
                      {...cell.getCellProps()}
                      className={cell.column.className}
                      style={cell.column.style}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
        {hasFooter && (
          <tfoot>
            {footerGroups.map((group) => (
              <tr {...group.getFooterGroupProps()}>
                {group.headers.map((column) => {
                  return column.hideFooter === true ? null : (
                    <td
                      {...column.getFooterProps([
                        { className: column.className },
                      ])}
                    >
                      {column.render("Footer")}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tfoot>
        )}
      </table>
    </div>
  );
}

export default TableV2;
