import React from "react";
import { hideAccountNumberSelector } from "../../selectors/plan-info";
import { useSelector } from "../../store/store";
import "./account-name.scss";
import { Link } from "react-router-dom";

type Props = {
  name: string;
  id: number;
  asLink?: boolean;
};

AccountName.defaultProps = {
  asLink: true,
};

function AccountName(props: Props) {
  const { name, id, asLink } = props;
  const hideAccountNumber = useSelector(hideAccountNumberSelector);

  if (!hideAccountNumber) {
    return (
      <>
        {asLink ? <AccountNameLink name={name} id={id} /> : <span>{name}</span>}
        <span className={"account-number-display show text-muted"}>
          Account number: {id}
        </span>
      </>
    );
  }

  return (
    <>
      {asLink ? <AccountNameLink name={name} id={id} /> : <span>{name}</span>}
    </>
  );
}

type AccountNameLinkProps = {
  id: number;
  name: string;
};

function AccountNameLink(props: AccountNameLinkProps) {
  const { id, name } = props;
  return (
    <Link
      className="account-link"
      to={{
        pathname: "/overview/account-info",
        search: `?accountId=${id}`,
      }}
    >
      {name}
    </Link>
  );
}

export default AccountName;
