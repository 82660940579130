import React, { useEffect, useState } from "react";
import { NqAccount, NqAccountBalance } from "../../../models/nq-accounts.model";
import { InvestmentRoutes, OverviewRoutes } from "../../../routes/routes";
import {
  nqAccountSingleStatus,
  planInfoStatusSelector,
  sessionStatusSelector,
  useStatuses,
} from "../../../selectors/status";
import { useSelector } from "../../../store/store";
import {
  formatStringDateToMonthDayYear,
  formatYYYYMMDDDashStringDateToMonthDayYear,
  userFacingDate,
} from "../../../util/date-util";
import Balance from "../../numbers/balance";
import { LastBusinessDate } from "../../numbers/last-business-date";
import Percent from "../../numbers/percent";
import { Panel, PanelBody, PanelHeading } from "../../panel/panel";
import { Skeleton } from "../../skeleton/skeleton";
import { AccountOverviewRows } from "./AccountOverviewRows";
import {
  AccountBalance,
  AccountDetail,
} from "./dashboard-account-overview-util";
import { Link } from "react-router-dom";
import "./dashboard-account-overview.scss";
import { StatementBalance } from "src/models/statements/statement-balance";
import { statementBalanceSelector } from "src/selectors/statement-balance";

export function DashboardAccountOverview() {
  const [dashboardBalance, setDashboardBalance] = useState(
    {} as NqAccountBalance
  );
  const { shouldHidePersonalizedRateOfReturn } = useSelector(
    (state) => state.plan.info.planFlags
  );
  const { data: nqAccountData } = useSelector(
    (state) => state.nqAccountDetailsSlice
  );
  const onlineStatementsEnabled = useSelector(
    (state) => state.plan.config.onlineStatementsEnabled
  );
  const [dashboardError] = useState(false);

  const [planBeginDate, setPlanBeginDate] = useState(
      {} as string
  );
  const statementBalanceData = useSelector(
      statementBalanceSelector
  );

  useEffect(() => {
    if (
      nqAccountData &&
      nqAccountData.balances &&
      nqAccountData.balances.length > 0
    ) {
      setDashboardBalance(nqAccountData.balances[0]);
    }
  }, [nqAccountData]);

  useEffect(() => {

      if (statementBalanceData && statementBalanceData.dashboardBalance && statementBalanceData.dashboardBalance.balanceInformation) {       
        const formattedEndDate = convertDateFormat(statementBalanceData.dashboardBalance.balanceInformation.beginDate);  
        setPlanBeginDate(formattedEndDate);
      }
      
  }, [statementBalanceData]);

  function convertDateFormat(dateString: any) {
    const [year, month, day] = dateString.split('-');
    return `${month}/${day}/${year}`;
  }

  const { isLoadingStatus } = useStatuses([nqAccountSingleStatus]);

  function isBalanceNotFound() {
    return (
      !isLoadingStatus &&
      nqAccountData &&
      (!nqAccountData.balances || nqAccountData.balances.length === 0)
    );
  }

  function getBalanceNotFoundDisplay() {
    return (
      <section id="balance-not-found-display">
        <Panel className={"account-panel"}>
          <PanelHeading noPrint className={"panel-dashboard"}>
            <div className="account-panel-heading">
              <h2 className="text-white">Your balance</h2>
            </div>
          </PanelHeading>
          <PanelBody>
            {nqAccountData.hasBalanceError && (
              <p>An error occurred while retrieving your balance.</p>
            )}
            {!nqAccountData.hasBalanceError && <p>No balance</p>}
          </PanelBody>
        </Panel>
      </section>
    );
  }

  function getBalanceDisplay() {
    return (
      <section id="balance-display">
        <Panel className={"account-panel"}>
          <PanelHeading noPrint className={"panel-dashboard"}>
            <div className="account-panel-heading">
              <h2 className="text-white">Your balance</h2>
              {!dashboardError && (
                <time
                  className="pull-right account-date js-date"
                  data-testid={"asofdate"}
                >
                  <Skeleton
                    selectors={[
                      sessionStatusSelector,
                      planInfoStatusSelector,
                      nqAccountSingleStatus,
                    ]}
                  >
                    As of{" "}
                    {nqAccountData &&
                      nqAccountData.balances &&
                      nqAccountData.balances.length > 0 && (
                        <DashboardBalanceDate
                          dashboardBalance={dashboardBalance}
                        />
                      )}
                  </Skeleton>
                </time>
              )}
            </div>
            {!dashboardError && (
              <div
                className={"h2 text-white balance"}
                data-testid={"currentbalance"}
              >
                <Skeleton
                  selectors={[
                    sessionStatusSelector,
                    planInfoStatusSelector,
                    nqAccountSingleStatus,
                  ]}
                >
                  {dashboardBalance && (
                    <AccountBalance
                      name={AccountOverviewRows.END}
                      url={OverviewRoutes.Dashboard}
                      type={Balance}
                      value={dashboardBalance.endBalance}
                    />
                  )}
                </Skeleton>
              </div>
            )}
            {dashboardError && (
              <div className={"account-panel-heading"}>
                <p></p>
              </div>
            )}
          </PanelHeading>
          <PanelBody>
            {dashboardError && (
              <div className={"account-details"}>
                <p>
                  There was an error loading your account information. Please
                  try again later.
                </p>
              </div>
            )}
            {!dashboardError && (
              <div className="account-details">
                <Skeleton
                  selectors={[
                    sessionStatusSelector,
                    planInfoStatusSelector,
                    nqAccountSingleStatus,
                  ]}
                  height={48}
                >
                  {dashboardBalance && (
                    <AccountDetail
                      name={AccountOverviewRows.VESTED}
                      url={OverviewRoutes.PlanOverview}
                      type={Balance}
                      value={dashboardBalance.vestedBalance}
                    />
                  )}
                </Skeleton>
                <Skeleton
                  selectors={[
                    sessionStatusSelector,
                    planInfoStatusSelector,
                    nqAccountSingleStatus,
                  ]}
                  height={76}
                >
                  {dashboardBalance && (
                    <AccountDetail
                      name={AccountOverviewRows.BEGIN}
                      url={OverviewRoutes.PlanOverview}
                      type={Balance}
                      value={dashboardBalance.beginBalance}
                      beginDate={planBeginDate}
                    />
                  )}
                </Skeleton>
                <Skeleton
                  selectors={[
                    sessionStatusSelector,
                    planInfoStatusSelector,
                    nqAccountSingleStatus,
                  ]}
                  height={98}
                >
                  {!shouldHidePersonalizedRateOfReturn && dashboardBalance && (
                    <AccountDetail
                      name={AccountOverviewRows.PRR}
                      url={InvestmentRoutes.RateOfReturn}
                      type={() =>
                        Percent({
                          value: dashboardBalance.prr,
                          isPercentage: true,
                          showSymbol: true,
                        })
                      }
                      value={dashboardBalance.prr}
                      beginDate={planBeginDate}
                    />
                  )}
                </Skeleton>
                <Skeleton
                  selectors={[
                    sessionStatusSelector,
                    planInfoStatusSelector,
                    nqAccountSingleStatus,
                  ]}
                  height={76}
                >
                  {dashboardBalance && (
                    <AccountDetail
                      name={AccountOverviewRows.GL}
                      url={InvestmentRoutes.Summary}
                      type={Balance}
                      value={dashboardBalance.gainLoss}
                      beginDate={planBeginDate}
                    />
                  )}
                </Skeleton>

                {onlineStatementsEnabled && (
                  <div className="statements-link">
                    <Link to={"/overview/statements"}>Statements</Link>
                  </div>
                )}
              </div>
            )}
          </PanelBody>
        </Panel>
      </section>
    );
  }

  if (isBalanceNotFound()) {
    return getBalanceNotFoundDisplay();
  } else {
    return getBalanceDisplay();
  }
}

interface AccountBalanceProps {
  account: NqAccount;
}

export function LiabilityCalcAccountBalanceTotal(props: AccountBalanceProps) {
  const { account } = props;

  if (account.hasBalance) {
    return <>{account.balances[0].totalBalanceText}</>;
  } else {
    return <>$0.00</>;
  }
}

export function LiabilityCalcAccountBalanceDate(props: AccountBalanceProps) {
  const { account } = props;

  if (account.hasBalance) {
    return <>{formatStringDateToMonthDayYear(account.balances[0].asOfDate)}</>;
  } else {
    return (
      <>
        <LastBusinessDate/>
      </>
    );
  }
}

export function DashboardBalanceDate(props: any) {
  const { dashboardBalance } = props;
  if (dashboardBalance.endDate) {
    return (
      <>
        {formatYYYYMMDDDashStringDateToMonthDayYear(dashboardBalance.endDate)}
      </>
    );
  } else {
    return (
      <>
        <LastBusinessDate/>
      </>
    );
  }
}
