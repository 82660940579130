import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getForecastDistributionsResource } from "../../services/distributions";
import { AsyncStatus } from "../../models/async-status";
import { ForecastDistributionsResource } from "../../models/distributions/forecast-distribution";

export const fetchForecastDistributionsResource = createAsyncThunk(
  "fetch-forecast-distribution-resource",
  async (
    {
      planId,
      empId,
      role,
      eventName,
      forecastDate,
      rateOfReturn,
    }: {
      planId: number;
      empId: number;
      role: string;
      eventName: string;
      forecastDate: string;
      rateOfReturn: number;
    },
    thunkApi
  ) => {
    return getForecastDistributionsResource({
      planId,
      empId,
      role,
      eventName,
      forecastDate,
      rateOfReturn,
    });
  }
);

type State = {
  forecastDistributionsResource: ForecastDistributionsResource;
  status: AsyncStatus;
};

const initialState: State = {
  forecastDistributionsResource: {
    forecastDistributions: [],
    forecastDistributionRolledUp: [],
    forecastExcludedAccounts: [],
    forecastDistributionsForGraph: [],
  } as ForecastDistributionsResource,
  status: AsyncStatus.not_fetched,
};

const forecastDistributionsResourceSlice = createSlice({
  name: "forecast-distributions-resource",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchForecastDistributionsResource.pending,
      (state, action) => {
        return { ...state, status: AsyncStatus.loading };
      }
    );
    builder.addCase(
      fetchForecastDistributionsResource.fulfilled,
      (state, action: { payload: ForecastDistributionsResource }) => {
        const forecastDistributionsResource = action.payload;

        return {
          ...state,
          forecastDistributionsResource,
          status: AsyncStatus.done,
        };
      }
    );
    builder.addCase(
      fetchForecastDistributionsResource.rejected,
      (state, action) => {
        return { ...state, status: AsyncStatus.error };
      }
    );
    builder.addDefaultCase((state, action) => state);
  },
});

export default forecastDistributionsResourceSlice.reducer;
