import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AsyncStatus } from "../../models/async-status";
import { getMarketCloseDates } from "../../services/redeferral";
import { MarketCloseDate } from "../../models/market-close-date";

export const fetchMarketCloseDates = createAsyncThunk(
  "fetch-market-close-dates",
  async (
    { planId, empId, role }: { planId: number; empId: number; role: string },
    thunkApi
  ) => {
    return getMarketCloseDates({ planId, empId, role });
  }
);

type State = {
  marketCloseDates: MarketCloseDate[];
  status: AsyncStatus;
};

const initialState: State = {
  marketCloseDates: [],
  status: AsyncStatus.not_fetched,
};

const marketCloseDatesSlice = createSlice({
  name: "marketCloseDates",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMarketCloseDates.pending, (state, action) => {
      return { ...state, status: AsyncStatus.loading };
    });
    builder.addCase(
      fetchMarketCloseDates.fulfilled,
      (state, action: { payload: MarketCloseDate[]; type: string }) => {
        const marketCloseDates = action.payload;

        return {
          ...state,
          marketCloseDates,
          status: AsyncStatus.done,
        };
      }
    );
    builder.addCase(fetchMarketCloseDates.rejected, (state, action) => {
      return { ...state, status: AsyncStatus.error };
    });
    builder.addDefaultCase((state, action) => state);
  },
});

export default marketCloseDatesSlice.reducer;
