import _find from "lodash/find";
import _groupBy from "lodash/groupBy";
import _isEmpty from "lodash/isEmpty";
import { Account } from "../../../models/account";
import {
  DistributionPayment,
  ScheduledDistributionPagePayment,
} from "../../../models/distributions/distribution-payment";
import { DistributionPayoutStatus } from "../../../models/distributions/distribution-payout-status";
import { PaymentType } from "../../../models/distributions/payment-type";
import { ScheduledDistributionEvent } from "../../../models/distributions/scheduled-distribution-event";
import { ScheduledDistributionElection } from "../../../models/distributions/ScheduledDistributionElection";
import { InvestmentV2 } from "../../../models/investment-v2";
import {
  filterInvestmentsByAccountIds,
  reduceByAccountId,
} from "../../../util/investment-context-util";
import {
  calculateTotalEndBalanceV2,
  parseAccountIds,
} from "../../../util/investments";

export function filterFutureDistributions(
  distributions: ScheduledDistributionEvent[]
) {
  return distributions.filter((distribution) => distribution.isFuture);
}

export function filterFuturePayments(
  distributionPayments:
    | DistributionPayment[]
    | ScheduledDistributionPagePayment[]
) {
  return distributionPayments.filter(
    (distributionPayment) =>
      distributionPayment.status === DistributionPayoutStatus.SCHEDULED
  );
}

export function filterPastDistributions(
  distributions: ScheduledDistributionEvent[]
) {
  return distributions.filter((distribution) => !distribution.isFuture);
}

export function filterScheduleDistributionsByAccounts(
  scheduledDistributions: ScheduledDistributionEvent[],
  selectedAccountIs: Account[]
) {
  const accountIds = parseAccountIds(selectedAccountIs);
  return scheduledDistributions.filter(
    (scheduledDistribution: ScheduledDistributionEvent) =>
      scheduledDistribution.accountId &&
      accountIds.includes(scheduledDistribution.accountId)
  );
}

export function filterScheduleDistributionElectionsByAccounts(
  scheduledElections: ScheduledDistributionElection[],
  selectedAccountIs: Account[]
) {
  const accountIds = parseAccountIds(selectedAccountIs);
  return scheduledElections.filter(
    (scheduledElection: ScheduledDistributionElection) =>
      scheduledElection.accountId &&
      accountIds.includes(scheduledElection.accountId)
  );
}

export function getFirstScheduledDistributionElectionByAccountId(
  scheduledElections: ScheduledDistributionElection[],
  accountId: number
) {
  return _find(scheduledElections, (election) => {
    return election.accountId === accountId;
  });
}

export function getInvestmentsByDistributionAccountIds(
  scheduledDistributions: ScheduledDistributionEvent[],
  investments: InvestmentV2[]
) {
  const accountIds = parseAccountIds(scheduledDistributions);
  const filteredInvestmentsByScheduledDistributionIds =
    filterInvestmentsByAccountIds(investments, accountIds);
  return reduceByAccountId(filteredInvestmentsByScheduledDistributionIds);
}

function setTotalRemainingDistributions(
  numberOfDistributionsForAccount: number
) {
  return (distribution: ScheduledDistributionEvent) => {
    return {
      ...distribution,
      totalInstallmentsRemaining: numberOfDistributionsForAccount,
    };
  };
}

export function getDistributionWithTotalInstallmentsRemaining(
  distributions: ScheduledDistributionEvent[]
): ScheduledDistributionEvent[][] {
  const distributionsByAccountId: ScheduledDistributionEvent[][] =
    Object.values(_groupBy(distributions, "accountId"));

  return distributionsByAccountId.map((distributionsForAccount) => {
    const numberOfDistributionsForAccount = distributionsForAccount.length;
    const totalRemainingDistributions = setTotalRemainingDistributions(
      numberOfDistributionsForAccount
    );
    return distributionsForAccount.map(totalRemainingDistributions);
  });
}

export function buildDistributionMethod(
  scheduledDistribution: ScheduledDistributionEvent
): string {
  const {
    paymentType,
    installmentFrequency,
    yearsPayout,
    paymentMethod,
    useAltMeth,
    altMethod,
  } = scheduledDistribution;

  if (_isEmpty(scheduledDistribution)) {
    return "N/A";
  }
  if (useAltMeth) {
    return altMethod || "N/A";
  } else if (paymentType === PaymentType.LUMPSUM || yearsPayout === 0) {
    return paymentType;
  } else if (paymentType === PaymentType.INITIAL) {
    return `Initial lump sum then ${installmentFrequency.toLowerCase()} installments over ${yearsPayout} years`;
  } else if (
    paymentType === PaymentType.CUSTOM_METHOD &&
    paymentMethod &&
    paymentMethod.length > 0
  ) {
    return paymentMethod;
  } else {
    return `${installmentFrequency} ${paymentType}s over ${yearsPayout} years`;
  }
}

export function buildInstallment(
  paymentType: string,
  installNum: number,
  totalInstallments: number
): string {
  if (paymentType === PaymentType.LUMPSUM || totalInstallments === 0) {
    return "N/A";
  } else {
    return `${installNum} of ${totalInstallments}`;
  }
}

export function getLumpInstallEstimatedDistributions(
  accountId: number,
  investments: InvestmentV2[],
  totalInstallments: number,
  installmentAmount: number,
  installmentsDistributed: number
): number {
  if (investments) {
    const totalBalance: number = calculateTotalEndBalanceV2(investments);
    const totalWithoutInitial = totalBalance - installmentAmount;
    const remainingInstallments = totalInstallments - installmentsDistributed;

    return totalWithoutInitial / remainingInstallments;
  }
  return 0;
}

export function getInstallmentPaymentAmount(
  accountId: number,
  investments: InvestmentV2[],
  totalInstallments: number = 1,
  installmentsDistributed: number
): number {
  const investmentsForAccount = investments.filter(
    (investment) => investment.accountId === accountId
  );
  const remainingInstallments = totalInstallments - installmentsDistributed;
  if (investmentsForAccount.length > 0) {
    return (
      calculateTotalEndBalanceV2(investmentsForAccount) / remainingInstallments
    );
  }

  return 0;
}

export function getFixedInstallmentPaymentAmount(
  accountId: number,
  investments: InvestmentV2[],
  totalInstallments: number = 1,
  installmentNumber: number,
  installmentAmount: number,
  installmentsDistributed: number
) {
  if (installmentNumber! < totalInstallments) {
    return installmentAmount!;
  }

  const investmentsForAccount = investments.filter(
    (investment) => investment.accountId === accountId
  );
  const accountBalance = calculateTotalEndBalanceV2(investmentsForAccount);
  const remainingInstallments = totalInstallments - installmentsDistributed;
  const fixedInstallments = remainingInstallments - 1;
  const fixedAmount = installmentAmount * fixedInstallments;
  const finalBalance = accountBalance - fixedAmount;
  if (finalBalance > 0) {
    return finalBalance;
  }
  return accountBalance;
}

export function getEstimatedDistributionForInitialLumpWithInstallments(
  accountId: number,
  investments: InvestmentV2[],
  installNum: number = 1,
  totalInstallments: number = 1,
  initialLumpAmount: number = 0,
  installmentsDistributed: number
): number {
  const investmentsForAccount = investments.filter(
    (investment) => investment.accountId === accountId
  );
  if (investmentsForAccount && installNum === 1) {
    return initialLumpAmount;
  }

  let remainingInstallments = totalInstallments - installmentsDistributed;
  const hasNotPayedOutInitialLump = installmentsDistributed === 0;

  if (hasNotPayedOutInitialLump) {
    //if initial payment is scheduled but not yet distributed
    remainingInstallments = totalInstallments - 1;
  }

  if (investmentsForAccount) {
    const endBalance = calculateTotalEndBalanceV2(investmentsForAccount);
    if (hasNotPayedOutInitialLump) {
      return (endBalance - initialLumpAmount) / remainingInstallments;
    }
    return endBalance / remainingInstallments;
  }
  return 0;
}

export function getEstimatedDistributionLumpSum(
  accountId: number,
  investments: InvestmentV2[]
): number {
  if (investments) {
    return calculateTotalEndBalanceV2(investments);
  }
  return 0;
}

export function findScheduledDistributionByAccountId(
  scheduledDistributions: ScheduledDistributionEvent[],
  accountId: number
): ScheduledDistributionEvent | undefined {
  return scheduledDistributions.find((distribution) => {
    return distribution.accountId === accountId;
  });
}
