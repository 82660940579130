export enum PayoutMethod {
  LUMP_SUM = "Lump sum",
  INSTALLMENTS = "Installments",
  LUMP_INSTALL = "Initial Lump Sum w/ Installments",
}

export enum PaymentMethod {
  DOLLAR = 1,
  PERCENT = 2,
}
