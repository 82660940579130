import React from "react";
import DebugCard from "../../../components/card/debug-card";
import { RedeferralStatus } from "../../../models/redeferral/redeferral-status";

export interface Props {
  stepNumber: number;
  accountRedeferralState: RedeferralStatus | undefined;
}

function pickClassesForStep(selectedStep: number, indicatorStep: number) {
  const activeClasses = "horizon-step-indicator horizon-step-indicator-active";
  const previousClasses =
    "horizon-step-indicator horizon-step-indicator-previous";
  const futureClasses =
    "horizon-step-indicator horizon-step-indicator-disabled";
  const defaultClasses = "horizon-step-indicator";
  if (selectedStep) {
    if (selectedStep === indicatorStep) {
      return activeClasses;
    } else if (selectedStep < indicatorStep) {
      return futureClasses;
    } else {
      return previousClasses;
    }
  }
  return defaultClasses;
}

function getOptions(accountRedeferralState: RedeferralStatus) {
  const options = [];
  if (accountRedeferralState.planAllowsTimeChangeRedeferral) {
    options.push("TIME");
  }
  if (accountRedeferralState.planAllowsFormChangeRedeferral) {
    options.push("FORM");
  }
  return options.toString();
}

export default function RedeferNavHeader(props: Props) {
  const { stepNumber, accountRedeferralState } = props;

  const stepOneClasses = pickClassesForStep(stepNumber, 1);
  const stepTwoClasses = pickClassesForStep(stepNumber, 2);
  const stepThreeClasses = pickClassesForStep(stepNumber, 3);

  const showIndicator = stepNumber !== 3;
  const stepOneLink =
    stepNumber === 2 && accountRedeferralState
      ? `/distributions/redeferral/begin/account/${accountRedeferralState.accountId}`
      : "#";

  return (
    <>
      {stepNumber === 3 && <h1>Redeferral: Confirmation</h1>}
      {stepNumber !== 3 && (
        <h1>Redeferral: Change your scheduled distribution elections</h1>
      )}
      {showIndicator && (
        <ol className="indicator">
          <li className={stepOneClasses}>
            <a href={stepOneLink}>
              <span className="indicator-underline">
                Distribution elections
              </span>
            </a>
          </li>
          <li className={stepTwoClasses}>
            <a href="#">
              <span className="indicator-underline">Review & submit</span>
            </a>
          </li>
          <li className={stepThreeClasses}>
            <a href="#">
              <span className="indicator-underline">Confirmation</span>
            </a>
          </li>
        </ol>
      )}
      {accountRedeferralState && (
        <>
          <DebugCard>
            <p>
              <strong>Account name:</strong>{" "}
              {accountRedeferralState.accountName}
            </p>
            <p>
              <strong>Account type ID:</strong>{" "}
              {accountRedeferralState.accountTypeId}
            </p>
            <p>
              <strong>Account ID:</strong> {accountRedeferralState.accountId}
            </p>
            <p>
              <strong>Redeferral type:</strong>{" "}
              {accountRedeferralState.redeferralType}
            </p>
            <p>
              <strong>Redeferral changes allowed:</strong>{" "}
              {getOptions(accountRedeferralState)}
            </p>
            <p>
              <strong>Current date:</strong>{" "}
              {accountRedeferralState.scheduledDistributionPayoutStartDate}
            </p>
            <p>
              <strong>Current method:</strong>{" "}
              {accountRedeferralState.scheduledDistributionMethod}
            </p>
          </DebugCard>
        </>
      )}
    </>
  );
}
