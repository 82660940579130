import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AsyncStatus } from "../../models/async-status";
import { getPTCTracker } from "../../services/distributions";
import { PTCTrackerResource } from "../../models/distributions/ptc-tracker";

export const fetchPTCTracker = createAsyncThunk(
  "fetch-ptctracker",
  async (
    { planId, empId, role }: { planId: number; empId: number; role: string },
    thunkApi
  ) => {
    return getPTCTracker({ planId, empId, role });
  }
);

type State = {
  ptcTracker: PTCTrackerResource;
  status: AsyncStatus;
};

const initialState: State = {
  ptcTracker: {} as PTCTrackerResource,
  status: AsyncStatus.not_fetched,
};

const ptctrackerSlice = createSlice({
  name: "ptcTracker",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPTCTracker.pending, (state, action) => {
      return { ...state, status: AsyncStatus.loading };
    });
    builder.addCase(
      fetchPTCTracker.fulfilled,
      (state, action: { payload: PTCTrackerResource; type: string }) => {
        const ptcTracker = action.payload;

        return {
          ...state,
          ptcTracker,
          status: AsyncStatus.done,
        };
      }
    );
    builder.addCase(fetchPTCTracker.rejected, (state, action) => {
      return { ...state, status: AsyncStatus.error };
    });
    builder.addDefaultCase((state, action) => state);
  },
});

export default ptctrackerSlice.reducer;
