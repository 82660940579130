import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ToolLink } from "src/models/plan-info-and-summary/tool-link";
import { getEnrollmentTools } from "src/services/enrollment-tools";
import { AsyncStatus } from "../../models/async-status";

export const fetchEnrollmentTools = createAsyncThunk(
  "fetch-enrollment-tools",
  async (
    { planId, empId, role }: { planId: number; empId: number; role: string },
    thunkApi
  ) => {
    return getEnrollmentTools(planId, empId, role);
  }
);

type State = {
  toolLinks: ToolLink[];
  status: AsyncStatus;
};

const initialState: State = {
  status: AsyncStatus.not_fetched,
  toolLinks: [],
};

const enrollmentToolsSlice = createSlice({
  name: "enrollment-tools",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchEnrollmentTools.pending, (state, action) => {
      return { ...state, status: AsyncStatus.loading };
    });
    builder.addCase(fetchEnrollmentTools.fulfilled, (state, action) => {
      return { ...state, ...action.payload, status: AsyncStatus.done };
    });
    builder.addCase(fetchEnrollmentTools.rejected, (state, action) => {
      return {
        ...state,
        status: AsyncStatus.error,
      };
    });
    builder.addDefaultCase((state, action) => state);
  },
});

export default enrollmentToolsSlice.reducer;
