import React from "react";
import { Bar, BarChart, LabelList, ResponsiveContainer, XAxis } from "recharts";
import CustomizedAxisTick from "../../../../components/chart/customized-axis-tick";
import { getRoundedValue } from "../../../../components/numbers/fixed-decimal";

export default function ContributionBreakdownChart(props) {
  const { sortedInvestments } = props;

  return (
    <ResponsiveContainer
      width="100%"
      height={300}
      className={"time-series-chart-wrapper"}
    >
      <BarChart
        data={sortedInvestments}
        margin={{
          bottom: 10,
          top: 20,
        }}
      >
        <XAxis
          dataKey="moneyName"
          tick={<CustomizedAxisTick />}
          interval={0}
          height={30}
        />
        <Bar dataKey="balance" fill="#0091da" isAnimationActive={false}>
          <LabelList
            dataKey="balance"
            position="top"
            formatter={(label) => `$${getRoundedValue(label, 0)}`}
          />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}
