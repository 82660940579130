import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { viewDocument } from "src/services/download-plan-info-summary-documents";
import { AsyncStatus } from "../../models/async-status";

export const fetchViewDocument = createAsyncThunk(
  "fetch-view-document",
  async (
    {
      planId,
      empId,
      extension,
      resourceId,
      role,
    }: {
      planId: number;
      empId: number;
      extension: string;
      resourceId: number;
      role: string;
    },
    thunkApi
  ) => {
    return viewDocument(planId, empId, extension, resourceId, role);
  }
);

type State = {
  status: AsyncStatus;
};

const initialState: State = {
  status: AsyncStatus.not_fetched,
};

const viewDocumentSlice = createSlice({
  name: "view-document",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchViewDocument.pending, (state, action) => {
      return { ...state, status: AsyncStatus.loading };
    });
    builder.addCase(fetchViewDocument.fulfilled, (state, action) => {
      return { ...state, ...action.payload, status: AsyncStatus.done };
    });
    builder.addCase(fetchViewDocument.rejected, (state, action) => {
      return {
        ...state,
        status: AsyncStatus.error,
      };
    });
    builder.addDefaultCase((state, action) => state);
  },
});

export default viewDocumentSlice.reducer;
