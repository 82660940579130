import classnames from "classnames";
import React, { useEffect } from "react";
import LoadingSpinner from "../loading-spinner/loading-spinner";
import PrintLink from "../print-link/print-link";

export interface Props {
  children: React.ReactNode;
  name: string;
  className?: string;
  pdfText?: boolean;
  removeHeader?: boolean;
  printable?: boolean;
  loading: boolean;
}

export function Page(props: Props) {
  const { children, pdfText, removeHeader, printable, loading, name } = props;
  const containerClasses = classnames(
    "container",
    "content",
    "util-focus-visible-only",
    props.className
  );

  useEffect(() => {
    // Used by nq-participant-nav
    localStorage.setItem("pathName", window.location.pathname);
  }, []);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <main data-testid={`${name}-page`} tabIndex={-1} id="main-content">
      <div className={containerClasses}>
        {printable && (
          <PrintLink removeHeader={removeHeader} pdfText={pdfText} />
        )}
        {children}
      </div>
    </main>
  );
}
