import React, { useEffect, useState } from "react";
import { planInfoSummaryStatus, useStatuses } from "../../../selectors/status";
import { fetchPlanInfoSummary } from "../../../reducers/plan-info-and-summary/plan-info-summary";
import { fetchViewDocument } from "../../../reducers/view-plan-info-summary-document/view-documents";
import { useAppDispatch, useSelector } from "../../../store/store";
import { Page, PageHeader } from "src/components/page";
import { AsyncStatus } from "src/models/async-status";
import { ToolLink } from "src/models/plan-info-and-summary/tool-link";

export default function PlanInfoSummaryPage() {
  const { planInfoSummary } = useSelector((state) => state.planInfoAndSummary);
  const { viewDocuments } = useSelector((state) => state.viewDocument);
  const { planId, empId, role } = useSelector((state) => state.session);
  const { isLoadingStatus } = useStatuses([planInfoSummaryStatus]);
  const [currentToolLink, setCurrentToolLink] = useState("");
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchPlanInfoSummary({ planId, empId, role }));
  }, [dispatch, planId, empId, role]);

  const viewDocument = (
    extension: string,
    resourceId: number,
    toolLinkName: string
  ) => {
    dispatch(fetchViewDocument({ planId, empId, extension, resourceId, role }));
    setCurrentToolLink(toolLinkName);
  };

  const isClickableLink = (toolLink: ToolLink) => {
    return !!toolLink.outputLink;
  };

  return (
    <Page name={"plan-info-summary"} loading={isLoadingStatus}>
      {viewDocuments.status === AsyncStatus.error ? (
        <div className="alert alert-danger alert-icon" role="alert">
          {currentToolLink} is unavailable at this time. Please call for
          assistance.
        </div>
      ) : (
        ""
      )}
      <PageHeader name={"plan info summary"}>
        Plan Information & Forms
      </PageHeader>
      {planInfoSummary.eligibleParticipant &&
      !planInfoSummary.enrolledParticipant ? (
        <span style={{ fontWeight: "bold" }}>
          You have not yet enrolled in the Plan.
        </span>
      ) : (
        ""
      )}
      {planInfoSummary.enrolledParticipant &&
      planInfoSummary.enrolledNewParticipant ? (
        <span style={{ fontWeight: "bold" }}>
          Your enrollment information has been received and will be processed
          prior to the effective date.
        </span>
      ) : (
        ""
      )}
      {planInfoSummary.participantEnrolledNoContribution ? (
        <span style={{ fontWeight: "bold" }}>
          You have not yet received a contribution.
        </span>
      ) : (
        ""
      )}

      {
        <span>
          The following resources are available for your review. Some of these
          resources are in PDF format. In order to view a PDF file, you must
          have Adobe Acrobat Reader installed on your computer.
        </span>
      }
      <ul>
        {planInfoSummary.planInfoSummaryResource.toolLinks.map((toolLink) => (
          <li className="util-margin-top-15">
            <a
              href={isClickableLink(toolLink) ? toolLink.urlLink : "#"}
              target={isClickableLink(toolLink) ? "_blank" : "_self"}
              rel="noreferrer"
              onClick={() =>
                isClickableLink(toolLink)
                  ? true
                  : viewDocument(
                      toolLink.extension,
                      toolLink.resourceId,
                      toolLink.linkName
                    )
              }
            >
              {toolLink.linkName}
            </a>
            &nbsp;&nbsp;<span>{toolLink.extension}</span>
            <div>
              <span>{toolLink.linkDescription}</span>
            </div>
          </li>
        ))}
      </ul>
      <div className="util-margin-top-15">
        <span>
          The amounts listed on the website represent a measure of your benefits
          under a nonqualified benefit agreement between you and your plan
          sponsor. It is not a representation that your plan sponsor holds any
          assets for paying these benefits.
        </span>

        <div className="util-margin-top-15">
          These resources are for general information only and are not intended
          to provide specific advice or recommendations to any individual. All
          situations and investors described are hypothetical. Please consult
          your attorney, accountant or financial or tax advisor with regard to
          your individual situation.
        </div>
      </div>
    </Page>
  );
}
