import React from "react";
import { useDispatch } from "react-redux";
import { deleteBeneficiary } from "../../../reducers/beneficiary-reducer";

type BeneficiaryDeleteSectionProps = {
  handleClose: () => void;
  guid: string | undefined;
  closeDeleteSection: () => void;
};

export default function BeneficiaryDeleteSection(
  props: BeneficiaryDeleteSectionProps
) {
  const dispatch = useDispatch();
  const { handleClose, guid, closeDeleteSection } = props;

  function deleteBene() {
    if (guid) {
      dispatch(deleteBeneficiary(guid));
    }
    handleClose();
  }

  return (
    <div id="delete-beneficiary-section">
      <hr className="dotted" role="presentation" />
      <div className="row">
        <div className="col-xs-12">
          <p className="h5 pull-left text-left">
            Note: you are marking this beneficiary for deletion. This change
            will not be completed until you review and submit your changes.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <button
            onClick={closeDeleteSection}
            type="button"
            className="btn btn-link js-delete-beneficiary-message-hide"
          >
            Cancel
          </button>
          <button
            onClick={deleteBene}
            type="button"
            data-gtm="modal-cta"
            id="delete-beneficiary-action"
            className="btn btn-danger"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
}
