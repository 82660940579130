import React, { useEffect, useState } from "react";
import { PdsCollapsible } from "@principal/design-system-react";
import { getContactCenter } from "../services/contact-center-service";

interface ContactProps {
  planId: number;
}

const ContactCenterNumber = (props: ContactProps) => {
  const [contactCenterNumber, setContactCenterNumber] = useState<String>("");

  useEffect(() => {
    try {
      getContactCenter(props.planId).then((res) => {
        setContactCenterNumber(res);
      });
    } catch (e) {
      console.error(e);
    }
  }, []);

  function contactCenterShowIfNotEmpty(): boolean {
    return contactCenterNumber.length != 0;
  }

  if (contactCenterShowIfNotEmpty()) {
    return (
      <PdsCollapsible variant="default">
        <span
          slot="summary-text"
          style={{ fontWeight: "bold", fontSize: "170%" }}
        >
          Contact center
        </span>
        <span slot="collapsible-content" style={{ fontSize: "120%" }}>
          <p>
            <p>Customer service:</p>
            <p>{contactCenterNumber}</p>
          </p>
        </span>
      </PdsCollapsible>
    );
  } else {
    return <></>;
  }
};

export default ContactCenterNumber;
