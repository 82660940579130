import React from "react";
import { useSelector } from "../../../../store/store";
import { buildPensionContextLink } from "../../../../util/nq-participant-link-builder";

export function ContributionAmountsInfo() {
  const { empId, role } = useSelector((state) => state.session);
  const contractId = useSelector((state) => state.plan.info.contractId);
  const planSummarylink = buildPensionContextLink(
    empId,
    contractId,
    "planInfoFormsSummary",
    role
  );

  return (
    <p className="contribution-info" data-testid="contributions-amounts-info">
      Use the following information to compare how much was contributed into the
      plan with the current balances. Current balances include gains and losses.
      Each contribution source may be subject to a vesting schedule. Review your{" "}
      <a
        href={planSummarylink}
        data-testid={"plan-summary-link"}
        target="_blank"
        rel="noopener noreferrer"
        title="Opens in a new window"
      >
        Plan Summary
      </a>{" "}
      for additional information on the contribution sources eligible for
      deferral into the plan.
    </p>
  );
}
