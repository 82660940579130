import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AsyncStatus } from "../../models/async-status";
import { getParticipantLevelElections } from "../../services/distributions";
import { UnscheduledDistributionElection } from "../../models/distributions/unscheduled-distribution-election";

export const fetchParticipantLevelElections = createAsyncThunk(
  "fetch-participant-elections",
  async (
    { planId, empId, role }: { planId: number; empId: number; role: string },
    thunkApi
  ) => {
    return getParticipantLevelElections({ planId, empId, role });
  }
);

type State = {
  participantLevelElections: UnscheduledDistributionElection[];
  status: AsyncStatus;
};

const initialState: State = {
  participantLevelElections: [],
  status: AsyncStatus.not_fetched,
};

const participantLevelElectionsSlice = createSlice({
  name: "participantLevelElections",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchParticipantLevelElections.pending, (state, action) => {
      return { ...state, status: AsyncStatus.loading };
    });
    builder.addCase(
      fetchParticipantLevelElections.fulfilled,
      (
        state,
        action: { payload: UnscheduledDistributionElection[]; type: string }
      ) => {
        const participantLevelElections = action.payload;

        return {
          ...state,
          participantLevelElections,
          status: AsyncStatus.done,
        };
      }
    );
    builder.addCase(
      fetchParticipantLevelElections.rejected,
      (state, action) => {
        return { ...state, status: AsyncStatus.error };
      }
    );
    builder.addDefaultCase((state, action) => state);
  },
});

export default participantLevelElectionsSlice.reducer;
