import { createSelector } from "@reduxjs/toolkit";
import _sortBy from "lodash/sortBy";
import { Account } from "../models/account";
import { UnscheduledDistributionElection } from "../models/distributions/unscheduled-distribution-election";
import { RootState } from "../store/store";
import {
  selectedAccountsNotInPayoutSelector,
  selectedAccountsSelector,
} from "./accounts";

export const accountLevelElectionsSelector = (state: RootState) =>
  state.distributions.accountLevelElections.accountLevelElections;

export const accountsWithUnscheduledDistributionsSelector = createSelector(
  [selectedAccountsNotInPayoutSelector, accountLevelElectionsSelector],
  (accounts, elections) => {
    const accountsWithUnscheduledDistributionElections =
      addUnscheduledEventsToAccount(accounts, elections);

    return _sortBy(accountsWithUnscheduledDistributionElections, "name");
  }
);

export const accountsWithUnscheduledDistributionsSelectorNotFiltered =
  createSelector(
    [selectedAccountsSelector, accountLevelElectionsSelector],
    (accounts, elections) => {
      const accountsWithUnscheduledDistributionElections =
        addUnscheduledEventsToAccount(accounts, elections);

      return _sortBy(accountsWithUnscheduledDistributionElections, "name");
    }
  );

function addUnscheduledEventsToAccount(
  accounts: Account[],
  unscheduledDistributions: UnscheduledDistributionElection[]
) {
  return accounts.map((account) => {
    const { id } = account;
    return {
      ...account,
      unscheduledDistributionElections:
        filterUnscheduledDistributionsByAccountId(id, unscheduledDistributions),
    };
  });
}

function filterUnscheduledDistributionsByAccountId(
  accountId: number,
  unscheduledDistributions: UnscheduledDistributionElection[]
): UnscheduledDistributionElection[] {
  return unscheduledDistributions.filter(
    (unscheduledDistribution) => unscheduledDistribution.accountId === accountId
  );
}
