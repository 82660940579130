import React from "react";

import DateDisplay from "../../components/date-display/date-display";
import "./statement-print-header.scss";
import { useSelector } from "../../store/store";

const StatementPrintHeader = (props) => {
  const { planName, contractId, participantName } = useSelector(
    (state) => state.plan.info
  );

  return (
    <section className={"statement-print-header"}>
      <div className={"principal-info-wrapper"}>
        <div className={"principal-address-block"}>
          <span>PERSONAL & CONFIDENTIAL</span>
          <span>PRINCIPAL FINANCIAL GROUP</span>
          <span>PO BOX 9275</span>
          <span>DES MOINES IA 50306-9275</span>
        </div>
        <div className={"statement-date-block"}>
          <img
            src="https://www.pilot.principalcdn.com/css/horizon/v2/color-logo.png"
            alt="principal retina logo"
          />
          <span>
            Statement for the period of <DateDisplay />{" "}
          </span>
        </div>
      </div>
      <div className={"participant-info-block"}>
        <span>{participantName}</span>
        <span>
          {planName} - Contract Number: {contractId}
        </span>
      </div>
    </section>
  );
};

export default StatementPrintHeader;
