import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ProjectedBenefits } from "src/models/overview-benefit/projected-benefits";
import { getBenefitOverviewDetails } from "src/services/overview-benefit";
import { AsyncStatus } from "../../models/async-status";

export const fetchOverviewBenefit = createAsyncThunk(
  "fetch-overview-benefit",
  async (
    {
      planId,
      empId,
      contractId,
      role,
    }: { planId: number; empId: number; contractId: string; role: string },
    thunkApi
  ) => {
    return getBenefitOverviewDetails(planId, empId, contractId, role);
  }
);

type State = {
  showDBCashProjectedBenefit: Boolean;
  cashBalancePlan: Boolean;
  dbShowCurrentBenefits: Boolean;
  showDBNonCashProjectedBenefit: Boolean;
  showStatement: Boolean;
  projectedBenefits: ProjectedBenefits[];
  status: AsyncStatus;
};

const initialState: State = {
  status: AsyncStatus.not_fetched,
  showDBCashProjectedBenefit: false,
  cashBalancePlan: false,
  dbShowCurrentBenefits: false,
  showDBNonCashProjectedBenefit: false,
  showStatement: false,
  projectedBenefits: [],
};

const overviewBenefitSlice = createSlice({
  name: "overview-benefit",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchOverviewBenefit.pending, (state, action) => {
      return { ...state, status: AsyncStatus.loading };
    });
    builder.addCase(fetchOverviewBenefit.fulfilled, (state, action) => {
      return { ...state, ...action.payload, status: AsyncStatus.done };
    });
    builder.addCase(fetchOverviewBenefit.rejected, (state, action) => {
      return {
        ...state,
        status: AsyncStatus.error,
      };
    });
    builder.addDefaultCase((state, action) => state);
  },
});

export default overviewBenefitSlice.reducer;
