import { buildDownloadUrl, download } from "../repositories/participant";

export async function downloadPdf(
  planId: number,
  empId: number,
  fileName: string,
  documentId: number,
  role: string
): Promise<String> {
  return download(`/plans/${planId}/employees/${empId}/showPdf`, {
    role,
    fileName,
    documentId,
  });
}

export function buildPdfDownloadUrl(
  planId: number,
  empId: number,
  fileName: string,
  documentId: number,
  role: string
): string {
  return buildDownloadUrl(`/plans/${planId}/employees/${empId}/downloadPdf`, {
    role,
    fileName,
    documentId,
  });
}
