import _has from "lodash/has";
import { AccountWithUnscheduledDistributions } from "../models/account";
import { AccountType } from "../models/distributions/account-type";
import { EstimatedDistribution } from "../models/distributions/estimated-distribution";
import { ScheduledDistributionEvent } from "../models/distributions/scheduled-distribution-event";
import { UnscheduledDistributionElection } from "../models/distributions/unscheduled-distribution-election";
import { InvestmentV2 } from "../models/investment-v2";
import { PayoutStatus } from "../models/payout-status";
import {
  getInstallmentPaymentAmount,
  getLumpInstallEstimatedDistributions,
} from "../pages/distributions/distribution-scheduled/scheduled-distribution-util";
import { shouldShowEstimatedDistribution } from "./distributions-util";

function shouldEstimatedDistributionsBeShownForAccount(
  account: AccountWithUnscheduledDistributions,
  scheduledDistributions: ScheduledDistributionEvent[] = []
): boolean {
  const { payoutStatus, type } = account;
  const payoutStartDate = _has(account, "payoutStartDate");

  return Boolean(
    scheduledDistributions.length === 0 &&
      type === AccountType.RETIREMENT &&
      payoutStatus !== PayoutStatus.IN_PAYOUT &&
      payoutStatus !== PayoutStatus.COMPLETELY_PAID_OUT &&
      !payoutStartDate
  );
}

export function hasEstimatedDistributions(
  elections: UnscheduledDistributionElection[],
  account: AccountWithUnscheduledDistributions,
  scheduledDistributions: ScheduledDistributionEvent[],
  showEstimatedDistributionFlag: boolean = shouldShowEstimatedDistribution(
    elections
  )
): boolean {
  return (
    shouldEstimatedDistributionsBeShownForAccount(
      account,
      scheduledDistributions
    ) && showEstimatedDistributionFlag
  );
}

export function buildLumpInstallEstimatedDistributions(
  accountId: number,
  investments: InvestmentV2[],
  installmentYears: number,
  installmentAmount: number,
  installmentsDistributed: number
): EstimatedDistribution[] {
  const totalInstallments = installmentYears - 1;
  const estimatedDistribution = getLumpInstallEstimatedDistributions(
    accountId,
    investments,
    totalInstallments,
    installmentAmount,
    installmentsDistributed
  );
  const firstInstallment: EstimatedDistribution = {
    installment: 1,
    estimatedDistribution: installmentAmount,
  };
  const otherInstallments: EstimatedDistribution[] = [];
  for (let i = 1; i < totalInstallments + 1; i++) {
    otherInstallments.push({
      installment: i + 1,
      estimatedDistribution,
    });
  }
  return [firstInstallment, ...otherInstallments];
}

export function buildInstallmentEstimatedDistributions(
  accountId: number,
  investments: InvestmentV2[],
  installmentYears: number,
  installmentsDistributed: number
) {
  const estimatedDistribution = getInstallmentPaymentAmount(
    accountId,
    investments,
    installmentYears,
    installmentsDistributed
  );
  const distributions: EstimatedDistribution[] = [];
  for (let i = 1; i <= installmentYears; i++) {
    distributions.push({
      installment: i,
      estimatedDistribution: estimatedDistribution,
    });
  }
  return distributions;
}
