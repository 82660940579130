import React from "react";
import { useSelector } from "../../../../store/store";
import { PlanCode } from "../../../../models/plan/plan-info-model";

export default function PlanCodeMessage() {
  const { planCode } = useSelector((state) => state.plan.info);
  if (planCode.toLowerCase() === PlanCode.type457b) {
    return (
      <p data-testid="election-msg">
        Subsequent Elections: Notwithstanding the initial election of the
        Participant, the Participant may make one additional election to defer
        (but not accelerate) the commencement of payments from the Plan by
        filing a written election with the Committee prior to the initial
        commencement date of the payments. A Participant may make an election as
        to the method of payment at any time prior to the commencement of
        payments from the Plan.
      </p>
    );
  }
  return null;
}
