import React from "react";
import PrrAccordion from "../../../components/accordion/prr-accordion";
import DisclaimersDisplay from "../../../components/disclaimers/disclaimers-display";
import { hidePrrSelector } from "../../../selectors/plan-info";
import { useSelector } from "../../../store/store";
import {
  ContributionDisclaimer,
  InvestmentAllocationDisclaimer,
  PlanOverviewDisclaimer,
} from "../disclaimers/plan-overview-disclaimers";
import { AccountInfoTabNames } from "./account-info-tabs";

type DisclaimerProps = { selectedTab: AccountInfoTabNames };

export function AccountInfoDisclaimers({ selectedTab }: DisclaimerProps) {
  const hidePrr = useSelector(hidePrrSelector);
  switch (selectedTab) {
    case AccountInfoTabNames.INVESTMENTS:
      return (
        <>
          <DisclaimersDisplay showOnlyShaw={true} />
          {!hidePrr && <PrrAccordion />}
        </>
      );
    case AccountInfoTabNames.DETAILS:
      return <PlanOverviewDisclaimer />;
    case AccountInfoTabNames.ALLOCATIONS:
      return (
        <>
          <InvestmentAllocationDisclaimer />
        </>
      );
    case AccountInfoTabNames.CONTRIBUTIONS:
      return (
        <>
          <ContributionDisclaimer />
        </>
      );
    case AccountInfoTabNames.DISTRIBUTIONS:
      return (
        <>
          <DisclaimersDisplay showOnlyShaw={true} />
        </>
      );
    default:
      return null;
  }
}
