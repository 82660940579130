import React from "react";
import Balance from "../../../components/numbers/balance";
import Percent from "../../../components/numbers/percent";
import {Panel, PanelBody, PanelHeading,} from "../../../components/panel/panel";
import TableV2 from "../../../components/table/tableV2";
import {useInvestments} from "../../../selectors/investments";
import {hidePrrSelector} from "../../../selectors/plan-info";
import {useSelector} from "../../../store/store";
import {reduceInvestments} from "../../../util/investment-context-util";
import "./totals-in-this-period-table.scss";
import {statementBalanceSelector} from "../../../selectors/statement-balance";
import {getPrrValue} from "../../../models/statements/statement-balance";
import {
  getBeginningBalance,
  getDistributionsForfeituresExpenses, getEndingBalance
} from "../../../models/investment-v2";

export default function TotalsInThisPeriodTable() {
  const { investments } = useInvestments();
  const hidePrr = useSelector(hidePrrSelector);
  const statementBalance = useSelector(statementBalanceSelector);
  const totals = reduceInvestments(investments);
  const renamed = {
    "Beginning balance": {
      value: getBeginningBalance(totals),
      type: Balance,
    },
    Contributions: {
      value: totals.contributionAmt,
      type: Balance,
    },
    "Distributions/Forfeitures/Expenses": {
      value: getDistributionsForfeituresExpenses(totals),
      type: Balance,
    },
    Transfers: {
      value: totals.transferAmt,
      type: Balance,
    },
    Earnings: {
      value: totals.earningAmt,
      type: Balance,
    },
    "Ending balance": {
      value: getEndingBalance(totals),
      type: Balance,
    },
  };

  const entries = Object.entries(renamed);
  const rows = entries.map(([key, value]) => ({
    key,
    value,
  }));

  if (!hidePrr) {
    rows.push({
      key: "Personalized rate of return",
      value: {
        value: getPrrValue(statementBalance),
        type: Percent,
      },
    });
  }

  const columns = [
    {
      accessor: "key",
    },
    {
      accessor: "value",
      className: "right",
      Cell: (props) => {
        const { type: Component, value } = props.value;
        return <Component value={value} defaultValue={"N/A"} showDollar />;
      },
    },
  ];

  return (
    <Panel>
      <PanelHeading>Totals in this period</PanelHeading>
      <PanelBody>
        <TableV2
          columns={columns}
          data={rows}
          className="totals-in-this-period-table"
        />
      </PanelBody>
    </Panel>
  );
}
