import React, { useEffect, useState } from "react";
import {
  paymentInfoStatus,
  planDetailsStatus,
  useStatuses,
} from "../../selectors/status";
import { useAppDispatch, useSelector } from "../../store/store";
import "./personal-details.scss";
import {
  paymentInfoSelector,
  planAllowsWireACHSelector,
} from "../../selectors/plan-info";
import { AllowsWireACH } from "../../models/plan/plan-details";
import { fetchPaymentInfo } from "../../reducers/plan/payment-info";
import PaymentInfoTable, {
  BankAccountType,
  BankState,
} from "./payment-info-table";

type Props = {
  bankStates: BankState[];
  bankAccountTypes: BankAccountType[];
};

export default function PaymentInfo(props: Props) {
  const { planId, empId, role } = useSelector((state) => state.session);
  const planDetailsStatuses = useStatuses([
    planDetailsStatus,
    paymentInfoStatus,
  ]);
  const planAllowsWireACH = useSelector(planAllowsWireACHSelector);
  const dispatch = useAppDispatch();
  const paymentInfo = useSelector(paymentInfoSelector);
  const [wireACH, setWireACH] = useState("ACH");

  const allowsAch =
    planAllowsWireACH === AllowsWireACH.ACH_ONLY ||
    planAllowsWireACH === AllowsWireACH.WIRE_AND_ACH;
  const allowsWire =
    !planDetailsStatuses.hasError &&
    !planDetailsStatuses.isLoadingStatus &&
    (planAllowsWireACH === AllowsWireACH.WIRE_ONLY ||
      planAllowsWireACH === AllowsWireACH.WIRE_AND_ACH);
  const allowsWireAndAch =
    !planDetailsStatuses.hasError &&
    !planDetailsStatuses.isLoadingStatus &&
    planAllowsWireACH === AllowsWireACH.WIRE_AND_ACH;
  const isACHSelected =
    (wireACH === "ACH" && planAllowsWireACH === AllowsWireACH.WIRE_AND_ACH) ||
    planAllowsWireACH === AllowsWireACH.ACH_ONLY;
  const isWireSelected =
    (wireACH === "Wire" && planAllowsWireACH === AllowsWireACH.WIRE_AND_ACH) ||
    planAllowsWireACH === AllowsWireACH.WIRE_ONLY;

  useEffect(() => {
    setWireACH(paymentInfo.wireACH || "ACH");
  }, [paymentInfo]);

  useEffect(() => {
    dispatch(fetchPaymentInfo({ planId, empId, role }));
  }, [dispatch, planId, empId, role]);

  function toggleWireAch() {
    if (wireACH === "ACH") {
      setWireACH("Wire");
    } else {
      setWireACH("ACH");
    }
  }

  return (
    <div>
      {allowsWireAndAch && (
        <div className="row">
          <div className="col-xs-12 util-margin-top-30" id={"wire-ach-toggle"}>
            <label htmlFor={"wire-ach-toggle"}>
              Select the form of payment you prefer
            </label>
            <div style={{ maxWidth: "400px" }}>
              <div className="pds-segmentedControl ">
                <input
                  id="segmentedControl-ach"
                  data-testid="segmentedControl-ach"
                  name="segmentedControl-ach"
                  type="radio"
                  checked={isACHSelected}
                  data-gtm="filter"
                  data-gtm-label="first"
                  onChange={() => toggleWireAch()}
                />
                <label htmlFor="segmentedControl-ach">ACH</label>
                <input
                  id="segmentedControl-wire"
                  data-testid="segmentedControl-wire"
                  name="segmentedControl-wire"
                  type="radio"
                  checked={isWireSelected}
                  data-gtm="filter"
                  data-gtm-label="second"
                  onChange={() => toggleWireAch()}
                />
                <label htmlFor="segmentedControl-wire">Wire</label>
              </div>
            </div>
          </div>
        </div>
      )}
      {(allowsWire || allowsAch) && (
        <div className="row personal-details">
          <div className="col-xs-12">
            <PaymentInfoTable
              bankStates={props.bankStates}
              bankAccountTypes={props.bankAccountTypes}
              ACH={isACHSelected}
            />
          </div>
        </div>
      )}
    </div>
  );
}
