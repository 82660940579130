import React from "react";
import { Column } from "react-table";
import TableV2 from "../../../../components/table/tableV2";
import { Account } from "../../../../models/account";
import { InvestmentV2 } from "../../../../models/investment-v2";
import {
  accountsStatusSelector,
  investmentsStatusSelector,
  useStatuses,
} from "../../../../selectors/status";
import { useSelector } from "../../../../store/store";
import {
  buildEndBalanceColumn,
  buildFiveYearColumn,
  buildGrossExpenseRatioColumn,
  buildHexValueColumn,
  buildInceptionDateColumn,
  buildInstitutionNameColumn,
  buildLastFullMonthColumn,
  buildNavChangeDollarColumn,
  buildNavChangePercentColumn,
  buildNavColumn,
  buildNavYTDChangeDollarColumn,
  buildOneYearColumn,
  buildSharePriceColumn,
  buildSinceInceptionColumn,
  buildTenYearColumn,
  buildThreeMonthColumn,
  buildThreeYearColumn,
  buildYearToDateColumn,
} from "./build-columns-utils";
import LoadingPerformanceTable from "./loading-performance-table";
import { isNavChangesEnabled } from "../../../../selectors/feature-flags";

type Props = {
  performanceType: string;
  investmentsToShow: InvestmentV2[];
  showFundPrices: boolean;
};

function PerformanceTable(props: Props) {
  const { performanceType, investmentsToShow, showFundPrices } = props;
  const { selected: selectedAccounts } = useSelector((state) => state.accounts);
  const navChangesEnabled = useSelector(isNavChangesEnabled);

  const { isLoadingStatus } = useStatuses([
    investmentsStatusSelector,
    accountsStatusSelector,
  ]);

  const historyType =
    performanceType === "M" ? "monthlyHistory" : "quarterlyHistory";

  const columns: Column[] = [
    buildHexValueColumn(),
    buildInstitutionNameColumn(),
    buildEndBalanceColumn(),
    buildSharePriceColumn(),
    buildYearToDateColumn(historyType),
    buildLastFullMonthColumn(historyType),
    buildThreeMonthColumn(historyType),
    buildOneYearColumn(historyType),
    buildThreeYearColumn(historyType),
    buildFiveYearColumn(historyType),
    buildTenYearColumn(historyType),
    buildSinceInceptionColumn(historyType),
    buildInceptionDateColumn(),
    buildGrossExpenseRatioColumn(historyType),
  ];

  const fundPriceColumns: Column[] = [
    buildHexValueColumn(),
    buildInstitutionNameColumn(),
    buildEndBalanceColumn(),
    buildSharePriceColumn(),
    buildNavColumn(),
    buildNavChangeDollarColumn(),
    buildNavChangePercentColumn(),
    buildNavYTDChangeDollarColumn(),
    buildYearToDateColumn(historyType),
  ];

  if (!isLoadingStatus && investmentsToShow.length === 0) {
    return (
      <div className="missing-data">
        {renderMissingDataText(selectedAccounts)}
      </div>
    );
  }

  if (isLoadingStatus) {
    return <LoadingPerformanceTable showFundPrices={showFundPrices} />;
  }
  if (navChangesEnabled && showFundPrices) {
    return <TableV2 columns={fundPriceColumns} data={investmentsToShow} />;
  }
  return <TableV2 columns={columns} data={investmentsToShow} />;
}

function renderMissingDataText(selectedAccounts: Account[]) {
  if (selectedAccounts.length === 0) {
    return <p>No Account(s) Selected</p>;
  }
  return <p>No Investments Found From Selected Account(s)</p>;
}

export default PerformanceTable;
