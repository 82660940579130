import _some from "lodash/some";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import AccountDropdown from "../../../components/account-dropdown/account-dropdown";
import { Skeleton } from "../../../components/skeleton/skeleton";
import { FutureDistributionPayment } from "../../../models/distributions/distribution-payment";
import { FeatureParam } from "../../../models/feature-flag";
import { setIsDistributionForecasting } from "../../../reducers/feature-flags-reducer";
import { accountsWithUnscheduledDistributionsSelectorNotFiltered } from "../../../selectors/account-distributions";
import {
  futureDistributionPaymentsSelector,
  generatedDistributionPaymentsSelector,
} from "../../../selectors/distribution-payment";
import { isDistributionHistoryEnabled } from "../../../selectors/feature-flags";
import {
  hideDistributionElectionSummarySelector,
  hideDistributionForecastingSelector,
  hideDistributionHistorySelector,
  hideScheduledDistributionsSelector,
  hideScheduledDistributionsTimelineSelector,
} from "../../../selectors/plan-info";
import { filteredScheduledElectionsSelector } from "../../../selectors/scheduled-elections";
import {
  accountLevelElectionsStatusSelector,
  accountRedeferralStatusesStatusSelector,
  accountsStatusSelector,
  featureFlagStatusSelector,
  investmentsStatusSelector,
  scheduledDistributionsStatus,
  scheduledElectionsStatus,
  useStatuses,
} from "../../../selectors/status";
import {
  doesQueryStringParamExist,
  getQueryStringParam,
} from "../../../services/feature-flag-service";
import { useSelector } from "../../../store/store";
import { getPlanMessage } from "../../../util/distributions-util";
import { DistributionForecasting } from "../distribution-forecasting/distribution-forecasting";
import { DistributionHistory } from "../distribution-history/distribution-history";
import ScheduledDistributionsWrapper from "../distribution-scheduled/scheduled-distributions-wrapper";
import { AccountDistributionsTable } from "./account-distributions-table";
import { DistributionDisclaimer } from "./disclaimers/distribution-disclaimer";
import ModifiedMethodDisclaimer from "./disclaimers/modified-methods-disclaimer";
import PhantomStockDisclaimer from "./disclaimers/phantom-stock-disclaimer";
import PlanCodeMessage from "./disclaimers/plan-code-message";
import ShawDisclaimer from "./disclaimers/shaw-disclaimer";
import DistributionExcelDownloadButton from "./excel/distribution-excel-download-button";
import ModifiedMethodComments from "./modified-method-comments/modified-method-comments";
import Pre409ADisclaimer from "./pre409A-disclaimer";
import TimelineWrapper from "./timeline/timeline-wrapper";
import { setShowPaidOutAccounts } from "../../../reducers/accounts";
import { PlanCode } from "../../../models/plan/plan-info-model";
import { TaxImplication409aModal } from "../../../components/modal/tax-implication-modals/tax-implication-409a-modal";
import { TaxImplication457bModal } from "../../../components/modal/tax-implication-modals/tax-implication-457b-modal";

export interface TabChangeFunction {
  (
    event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>,
    tabName: string
  ): void;
}

export function DistributionDetails() {
  const dispatch = useDispatch();
  const { showPaidOutAccounts, paidOutAccountsExist, allAccountsPaidOut } =
    useSelector((state) => state.accounts);
  const distributionHistoryEnabled = useSelector(isDistributionHistoryEnabled);
  const hideScheduledDistributionsTimeline = useSelector(
    hideScheduledDistributionsTimelineSelector
  );
  const hideDistributionElectionSummary = useSelector(
    hideDistributionElectionSummarySelector
  );
  const hideScheduledDistributions = useSelector(
    hideScheduledDistributionsSelector
  );
  const hideDistributionHistory = useSelector(hideDistributionHistorySelector);
  const hideDistributionForecasting = useSelector(
    hideDistributionForecastingSelector
  );
  const futureDistributionPayments: FutureDistributionPayment[] = useSelector(
    futureDistributionPaymentsSelector
  );
  const accountWithUnscheduledDistributions = useSelector(
    accountsWithUnscheduledDistributionsSelectorNotFiltered
  );
  const accountWithUnscheduledDistributionsNotFiltered = useSelector(
    accountsWithUnscheduledDistributionsSelectorNotFiltered
  );
  const filteredScheduledElections = useSelector(
    filteredScheduledElectionsSelector
  );
  const generatedDistributionPayments: FutureDistributionPayment[] =
    useSelector(generatedDistributionPaymentsSelector);

  const { planCode } = useSelector((state) => state.plan.info);
  const [showGeneratedData, setShowGeneratedData] = useState<Boolean>(false);
  const planMessage = getPlanMessage(planCode);
  const isExcess = planCode === "EXCESS";
  const is457b = planCode.toLowerCase() === PlanCode.type457b;
  const [buttonText, setButtonText] = useState("View Paid Out Accounts");
  const [scheduledDistributionClassName, setScheduledDistributionClassName] =
    useState("tab-pane");
  const [distributionHistoryClassName, setDistributionHistoryClassName] =
    useState("tab-pane");

  const showComments = _some(filteredScheduledElections, "showComment");
  const activeInserviceRedeferrals = _some(filteredScheduledElections, {
    activeRedeferral: true,
    type: "In-Service",
  });

  const noAccountSelectedForUnfiltered =
    accountWithUnscheduledDistributions.length === 0 &&
    accountWithUnscheduledDistributionsNotFiltered.length === 0;
  const noAccountSelected = showPaidOutAccounts
    ? noAccountSelectedForUnfiltered
    : accountWithUnscheduledDistributions.length === 0;
  const loadSelectors = [
    scheduledDistributionsStatus,
    investmentsStatusSelector,
    featureFlagStatusSelector,
    accountRedeferralStatusesStatusSelector,
    scheduledElectionsStatus,
    accountLevelElectionsStatusSelector,
    accountsStatusSelector,
  ];

  const { isLoadingStatus } = useStatuses(loadSelectors);

  useEffect(() => {
    if (doesQueryStringParamExist(FeatureParam.DISTRIBUTION_FORECASTING)) {
      dispatch(
        setIsDistributionForecasting(
          getQueryStringParam(FeatureParam.DISTRIBUTION_FORECASTING)
        )
      );
    }
    if (hideDistributionElectionSummary && !hideScheduledDistributions) {
      setScheduledDistributionClassName("tab-pane active");
    }
    if (
      hideDistributionElectionSummary &&
      hideScheduledDistributions &&
      !hideDistributionHistory
    ) {
      setDistributionHistoryClassName("tab-pane active");
    }
  }, [
    dispatch,
    hideDistributionElectionSummary,
    hideScheduledDistributions,
    hideDistributionHistory,
  ]);

  function handleClick() {
    if (!showPaidOutAccounts) {
      dispatch(setShowPaidOutAccounts(true));
    } else {
      dispatch(setShowPaidOutAccounts(false));
    }
  }
  useEffect(() => {
    if (showPaidOutAccounts) {
      setButtonText("Hide Paid Out Accounts");
    } else {
      setButtonText("View Paid Out Accounts");
    }
  }, [showPaidOutAccounts]);

  function changeTabs(
    event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>,
    tabName: string
  ) {
    document.getElementById(tabName)?.click();
  }

  if (noAccountSelected && !isLoadingStatus) {
    return (
      <Skeleton selectors={loadSelectors}>
        <AccountDropdown
          hidePaidAccounts
          showPaidOutAccounts={showPaidOutAccounts}
        />
        <p>No Account(s) Selected.</p>
      </Skeleton>
    );
  }

  return (
    <>
      <Skeleton selectors={loadSelectors}>
        <ul className="nav nav-tabs" role="tablist">
          {!hideDistributionElectionSummary && (
            <li className="active" role="presentation">
              <a
                id="distribution-summary-tab"
                data-toggle="tab"
                data-testid={"distribution-summary-tab"}
                href="#distribution-election-summary"
                role="tab"
                aria-expanded="true"
                aria-selected="true"
                tabIndex={0}
              >
                Distribution election summary
              </a>
            </li>
          )}
          {!hideDistributionForecasting && (
            <li role="presentation">
              <a
                id="distribution-forecasting-tab"
                data-toggle="tab"
                data-testid={"distribution-forecasting-tab"}
                href="#distribution-forecasting"
                role="tab"
                aria-expanded="false"
                aria-selected="false"
                tabIndex={-1}
              >
                Distribution forecasting
              </a>
            </li>
          )}
          {hideDistributionElectionSummary && !hideScheduledDistributions ? (
            <li className="active" role="presentation">
              <a
                id="scheduled-distribution-tab"
                data-toggle="tab"
                data-testid={"scheduled-distribution-tab"}
                href="#scheduled-distribution"
                role="tab"
                aria-expanded="false"
                aria-selected="false"
                tabIndex={-1}
              >
                Scheduled distributions
              </a>
            </li>
          ) : (
            <>
              {!hideScheduledDistributions && (
                <li role="presentation">
                  <a
                    id="scheduled-distribution-tab"
                    data-toggle="tab"
                    data-testid={"scheduled-distribution-tab"}
                    href="#scheduled-distribution"
                    role="tab"
                    aria-expanded="false"
                    aria-selected="false"
                    tabIndex={-1}
                  >
                    Scheduled distributions
                  </a>
                </li>
              )}
            </>
          )}
          {hideDistributionElectionSummary &&
          hideScheduledDistributions &&
          !hideDistributionHistory &&
          distributionHistoryEnabled ? (
            <li className="active" role="presentation">
              <a
                id="distribution-history-tab"
                data-toggle="tab"
                data-testid={"distribution-history-tab"}
                href="#distribution-history"
                role="tab"
                aria-expanded="false"
                aria-selected="false"
                tabIndex={-1}
              >
                Distribution history
              </a>
            </li>
          ) : (
            <>
              {!hideDistributionHistory && distributionHistoryEnabled && (
                <li role="presentation">
                  <a
                    id="distribution-history-tab"
                    data-toggle="tab"
                    data-testid={"distribution-history-tab"}
                    href="#distribution-history"
                    role="tab"
                    aria-expanded="false"
                    aria-selected="false"
                    tabIndex={-1}
                  >
                    Distribution history
                  </a>
                </li>
              )}
            </>
          )}
        </ul>
      </Skeleton>

      <div className="tab-content">
        <br />

        {!hideDistributionElectionSummary && (
          <div
            className="tab-pane active"
            id="distribution-election-summary"
            role="tabpanel"
          >
            <div className="distribution-election-summary">
              <h2 className="header h3">Distribution election summary</h2>
              <p>{planMessage}</p>
              {isExcess && (
                <p>
                  Upon a distribution event, your distribution will be subject
                  to income tax. For more details see
                  <TaxImplication409aModal
                    infoIcon={false}
                    linkText={"this information."}
                  />
                </p>
              )}
              {is457b && (
                <p>
                  Upon a distribution event, your distribution will be subject
                  to income tax. For more details see
                  <TaxImplication457bModal
                    infoIcon={false}
                    linkText={"this information."}
                  />
                </p>
              )}
              <div className="balance-grid">
                <Skeleton selectors={loadSelectors} height={80}>
                  <AccountDropdown
                    hidePaidAccounts
                    showPaidOutAccounts={showPaidOutAccounts}
                  />
                  <DistributionExcelDownloadButton
                    scheduledDistributionPayments={futureDistributionPayments}
                    generatedDistributionPayments={
                      generatedDistributionPayments
                    }
                    showPaidOutAccounts={showPaidOutAccounts}
                  />
                  <div style={{ paddingRight: "350px" }}>
                    {paidOutAccountsExist && !allAccountsPaidOut && (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleClick}
                      >
                        {buttonText}
                      </button>
                    )}
                  </div>
                </Skeleton>
              </div>
              <div className="account-distribution-headers-and-table">
                {!isLoadingStatus && (
                  <>
                    <AccountDistributionsTable
                      showPaidOutAccounts={showPaidOutAccounts}
                    />
                  </>
                )}
              </div>
            </div>
            {(showComments || activeInserviceRedeferrals) && (
              <>
                <ModifiedMethodComments
                  accounts={accountWithUnscheduledDistributions}
                  scheduledElections={filteredScheduledElections}
                />
                <ModifiedMethodDisclaimer />
              </>
            )}
            <DistributionDisclaimer>
              <PlanCodeMessage />
              <Pre409ADisclaimer />
              <ShawDisclaimer />
              <PhantomStockDisclaimer />
            </DistributionDisclaimer>
          </div>
        )}

        {!hideDistributionForecasting && (
          <div
            className="tab-pane"
            id="distribution-forecasting"
            role="tabpanel"
          >
            <DistributionForecasting changeTabs={changeTabs} />
          </div>
        )}

        <div
          className={scheduledDistributionClassName}
          id="scheduled-distribution"
          role="tabpanel"
        >
          {!hideScheduledDistributionsTimeline && (
            <TimelineWrapper
              scheduledDistributionPayments={futureDistributionPayments}
              generatedDistributionPayments={generatedDistributionPayments}
              showGeneratedData={showGeneratedData}
            />
          )}
          <h3>Scheduled distributions</h3>
          <ScheduledDistributionsWrapper />
        </div>

        {distributionHistoryEnabled && (
          <div
            className={distributionHistoryClassName}
            id="distribution-history"
            role="tabpanel"
          >
            <h3>Distribution history</h3>
            {isExcess && (
              <p data-testid={"excess-disclaimer"}>
                Upon a distribution event, your distribution will be subject to
                income tax. For more details see
                <TaxImplication409aModal
                  infoIcon={false}
                  linkText={"this information."}
                />
              </p>
            )}
            {is457b && (
              <p data-testid={"457b-disclaimer"}>
                Upon a distribution event, your distribution will be subject to
                income tax. For more details see
                <TaxImplication457bModal
                  infoIcon={false}
                  linkText={"this information."}
                />
              </p>
            )}
            <AccountDropdown
              hidePaidAccounts
              showPaidOutAccounts={showPaidOutAccounts}
            />
            &nbsp; &nbsp; &nbsp;
            {paidOutAccountsExist && !allAccountsPaidOut && (
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleClick}
              >
                {buttonText}
              </button>
            )}
            <DistributionHistory showPaidOutAccounts={showPaidOutAccounts} />
          </div>
        )}
      </div>
    </>
  );
}
