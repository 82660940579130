import { ErrorMessage } from "@hookform/error-message";
import classNames from "classnames";
import React from "react";
import { RegisterOptions } from "react-hook-form";

type Props = {
  register: (options: RegisterOptions) => React.Ref<any>;
  errors: any;
};

export default function StateSelect(props: Props) {
  const { register, errors } = props;
  const hasErrors = errors.address?.state;
  const className = classNames("form-group", {
    "has-error": hasErrors,
  });
  return (
    <div className={className}>
      <label htmlFor="stateSelect">State</label>
      <select
        id="stateSelect"
        name="address.state"
        data-testid="stateSelect"
        className={"form-control"}
        aria-invalid={errors.address?.state ? "true" : "false"}
        ref={register({})}
      >
        <option value=""> Select a state...</option>
        <option value="AA">Armed Forces Americas</option>
        <option value="AE">
          Armed Forces Africa, Canada, Europe, or Middle East
        </option>
        <option value="AK">ALASKA</option>
        <option value="AL">ALABAMA</option>
        <option value="AP">Armed Forces Pacific</option>
        <option value="AR">ARKANSAS</option>
        <option value="AZ">ARIZONA</option>
        <option value="CA">CALIFORNIA</option>
        <option value="CO">COLORADO</option>
        <option value="CT">CONNECTICUT</option>
        <option value="DC">DISTRICT OF COLUMBIA</option>
        <option value="DE">DELAWARE</option>
        <option value="FL">FLORIDA</option>
        <option value="GA">GEORGIA</option>
        <option value="GU">GUAM</option>
        <option value="HI">HAWAII</option>
        <option value="IA">IOWA</option>
        <option value="ID">IDAHO</option>
        <option value="IL">ILLINOIS</option>
        <option value="IN">INDIANA</option>
        <option value="KS">KANSAS</option>
        <option value="KY">KENTUCKY</option>
        <option value="LA">LOUISIANA</option>
        <option value="MA">MASSACHUSETTS</option>
        <option value="MD">MARYLAND</option>
        <option value="ME">MAINE</option>
        <option value="MI">MICHIGAN</option>
        <option value="MN">MINNESOTA</option>
        <option value="MO">MISSOURI</option>
        <option value="MS">MISSISSIPPI</option>
        <option value="MT">MONTANA</option>
        <option value="NC">NORTH CAROLINA</option>
        <option value="ND">NORTH DAKOTA</option>
        <option value="NE">NEBRASKA</option>
        <option value="NH">NEW HAMPSHIRE</option>
        <option value="NJ">NEW JERSEY</option>
        <option value="NM">NEW MEXICO</option>
        <option value="NV">NEVADA</option>
        <option value="NY">NEW YORK</option>
        <option value="OH">OHIO</option>
        <option value="OK">OKLAHOMA</option>
        <option value="OR">OREGON</option>
        <option value="PA">PENNSYLVANIA</option>
        <option value="PR">PUERTO RICO</option>
        <option value="RI">RHODE ISLAND</option>
        <option value="SC">SOUTH CAROLINA</option>
        <option value="SD">SOUTH DAKOTA</option>
        <option value="TN">TENNESSEE</option>
        <option value="TX">TEXAS</option>
        <option value="UT">UTAH</option>
        <option value="VA">VIRGINIA</option>
        <option value="VI">VIRGIN ISLANDS</option>
        <option value="VT">VERMONT</option>
        <option value="WA">WASHINGTON</option>
        <option value="WI">WISCONSIN</option>
        <option value="WV">WEST VIRGINIA</option>
        <option value="WY">WYOMING</option>
      </select>
      <ErrorMessage errors={errors} name="address.state" />
    </div>
  );
}
