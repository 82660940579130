import parseISO from "date-fns/parseISO";
import React from "react";
import {
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { getColor } from "../../util/charts-util";
import { formatDateWithoutTimeZone } from "../../util/date-util";
import { getRoundedValue } from "../numbers/fixed-decimal";
import { max, nice } from "d3-array";
import "./responsive-time-series-line-chart.scss";

export default function ResponsiveTimeSeriesAreaChart(props) {
  const { startDate, endDate, data } = props;
  const domain = [parseISO(startDate).valueOf(), parseISO(endDate).valueOf()];
  const mappedData = data.map((item) => ({
    ...item,
    date: item.date.valueOf(),
  }));
  const values = data.map((item) => item["Total Balance"]);
  const maxValue = max(values);
  const [, niceYMax] = nice(0, maxValue, 10);
  return (
    <TimeSeriesAreaChart
      {...props}
      domain={domain}
      data={mappedData}
      yMax={niceYMax}
    />
  );
}

function TimeSeriesAreaChart(props) {
  const { data, formatTooltip, domain, children, yMax } = props;

  function formatXTick(dateString) {
    if (dateString === "") {
      return undefined;
    }

    if (props.formatDate) {
      return props.formatDate(dateString);
    }

    return formatDateWithoutTimeZone(dateString, "MMM yyyy");
  }

  function formatYTick(item) {
    return `$${getRoundedValue(item, 0)}`;
  }

  return (
    <ResponsiveContainer
      width="100%"
      height={300}
      className="time-series-chart-wrapper"
    >
      <AreaChart
        data={data}
        margin={{
          top: 5,
          left: 40,
          bottom: 5,
        }}
        focusable="false"
      >
        <defs>
          <linearGradient id="color0" x1="0" y1="0" x2="0" y2="1">
            <stop offset="50%" stopColor={getColor(9)} stopOpacity={1} />
            <stop offset="95%" stopColor={getColor(9)} stopOpacity={0} />
          </linearGradient>
        </defs>
        <Tooltip
          labelFormatter={(item) => `Date: ${formatDateWithoutTimeZone(item)}`}
          formatter={formatTooltip}
        />
        <XAxis
          dataKey="date"
          type="number"
          scale="time"
          domain={domain}
          tickFormatter={formatXTick}
          minTickGap={180}
          tickLine={false}
          label={""}
        />
        <CartesianGrid vertical={false} />
        <YAxis
          tickFormatter={formatYTick}
          tickLine={false}
          axisLine={false}
          domain={[0, yMax]}
        />
        {children}
      </AreaChart>
    </ResponsiveContainer>
  );
}
