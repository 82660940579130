import {Page} from "../../components/page";
import React, {useEffect, useState} from "react";
import {Address, PlanContact, PlanContacts} from "../../services/contacts";
import '@principal/design-system/pds-styles.min.css';
import {useAppDispatch, useSelector} from "../../store/store";
import {fetchPlanContacts} from "../../reducers/plan-contacts";
import {chunkList} from "../../util/collection-utils";
import {valOrDefault} from "../../components/string/string";
import {contractIdSelector} from "../../selectors/plan-info";

const PlanContactsPage = () => {
    const [isLoadingData, setIsLoading] = useState(true);
    const contractId = useSelector(contractIdSelector);
    const dispatch = useAppDispatch();
    const [planContacts, setPlanContacts] = useState<PlanContacts>({
        planSponsors: null,
        financialProfessionals: null,
        contactCenter: null
    })

    const planContactsData = useSelector(
        (state) => state.planContacts.planContacts
    )
    useEffect(() => {
        if (contractId) {
            dispatch(
                fetchPlanContacts({contractId})
            )
        }
    }, [dispatch, contractId])

    useEffect(() => {
        if (planContactsData) {
            setPlanContacts(planContactsData);
            setIsLoading(false);
            console.table(planContactsData)
        }
    }, [planContactsData])

    const renderPlanContact = (contact: PlanContact) => {
        return (
            <div className={"panel panel-default"}>
                <div className={"panel-heading"}>
                    <span>{contact.firstName} {contact.lastName} {contact.role}</span>
                </div>
                <div className={"panel-body"}>
                    <div><span><div className={"col-md-3"}>Company:</div><div
                        className={"col-md-9"}>{valOrDefault(contact.company)}</div></span></div>
                    <div>
                        <div className={"col-md-3"}>Address:</div>
                        <div className={"col-md-9"}>{addressOrDefault(contact.address)}</div>
                    </div>
                    <div>
                        <span>
                            <div className={"col-md-3"}>Phone:</div>
                            <div className={"col-md-9"}>{valOrDefault(contact.phone)}</div>
                        </span>
                    </div>
                    <div>
                        <span>
                            <div className={"col-md-3"}>Fax:</div>
                            <div className={"col-md-9"}>{valOrDefault(contact.fax)}</div>
                        </span>
                    </div>
                    <div>
                        <span>
                            <div className={"col-md-3"}>Email:</div>
                            <div className={"col-md-9"}>{valOrDefault(contact.email)}</div>
                        </span>
                    </div>
                </div>
            </div>
        )
    }


    const renderListOfPlanContact = (sectionTitle: string, planContacts: PlanContact[] | null) => {
        if (planContacts === null || planContacts.length === 0) {
            return <></>
        }

        const planContactChunks = chunkList(planContacts, 2)
        console.table(planContactChunks);

        return (
            <div className={"col-md-12"}>
                <div className={"page-header"}>
                    <h1>{sectionTitle}</h1>
                </div>
                <table>
                    {planContactChunks.map((contactChunk: PlanContact[]) =>
                        <tr>
                            {/*render first in pair*/}
                            <td className={"col-md-6"}>
                                {renderPlanContact(contactChunk[0])}
                            </td>
                            {/*render second in pair if it exists*/}
                            {contactChunk[1] ? (
                                <td className={"col-md-6"}>
                                    {renderPlanContact(contactChunk[1])}
                                </td>
                            ) : (<td></td>)}
                        </tr>
                    )}
                </table>
            </div>
        )
    }

    if (isLoadingData) {
        return (
            <h1 id={"loading"}>
                <i className="fa fa-spinner fa-spin"/> Loading...{" "}
            </h1>
        );
    }

    return (
        <Page name={"plan-contacts-page"} loading={false}>
            {planContacts ? (
                    <div>
                        {planContacts.planSponsors ? (
                            <div id={"plan-sponsors"}>
                                {renderListOfPlanContact("Plan sponsors", planContacts.planSponsors!)}
                            </div>

                        ) : (<></>)}
                        {planContacts.financialProfessionals ? (
                            <div id={"financial-professionals"}>
                                {renderListOfPlanContact("Financial professionals", planContacts.financialProfessionals !)}
                            </div>
                        ) : (<></>)}
                        <div className={"col-md-12"}>
                            <div className={"page-header"}>
                                <h1>Do you have a question for Principal?</h1>
                            </div>
                            <div>
                                <span>Call Us: (800) 547-7754</span>
                                <br/>
                                <br/>
                                <br/>
                                <p>Your account balance represents a measure of your benefit under the nonqualified deferred
                                    compensation plan and is an unfunded, contractual obligation from your employer. It is
                                    not a
                                    representation that your employer holds a particular asset for funding this obligation.
                                    This
                                    amount may change based on future contributions, market fluctuations, and any applicable
                                    vesting
                                    schedules.</p>
                            </div>
                        </div>
                    </div>
                )
                :
                (<></>)
            }
        </Page>
    )
}

export const addressOrDefault = (address: Address, defaultVal: string = "N/a") => {
    if (address.street === null ||
        address.city === null ||
        address.state === null ||
        address.zip === null) {
        // Note: the following needs to have the new line, this is to make sure that if
        // we are returning the default value there is a new line after to keep all the
        // cards on the page the same height
        return <div style={{ whiteSpace: 'pre-wrap' }}>{`${defaultVal}
            `}</div>
    }
    return <span>{address.street}, {address.city} <br/>{address.state} {address.zip}</span>
}

export default PlanContactsPage
