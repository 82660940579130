import {
  ContributionHistory,
  ContributionRates,
  ContributionSummary,
  PendingContributionRate,
} from "../models/contributions";
import { get, post } from "../repositories/participant";

type Args = {
  planId: number;
  empId: number;
  role: string;
};

type PostArgs = {
  planId: number;
  empId: number;
  role: string;
  data: ContributionRates[];
};

export async function saveContributionRates({
  planId,
  empId,
  role,
  data,
}: PostArgs) {
  return post(
    `/employees/${empId}/plans/${planId}/contribution-rates`,
    { role },
    data
  );
}

export async function getContributionRates({
  planId,
  empId,
  role,
}: Args): Promise<ContributionRates[]> {
  return get(`/employees/${empId}/plans/${planId}/contribution-rates`, {
    role,
  });
}

export async function getContributionHistory({
  planId,
  empId,
  role,
}: Args): Promise<ContributionHistory[]> {
  return get(`/employees/${empId}/plans/${planId}/contributions/history`, {
    role,
  });
}

export async function getContributionSummary({
  planId,
  empId,
  role,
}: Args): Promise<ContributionSummary[]> {
  return get(`/employees/${empId}/plans/${planId}/contributions/summary`, {
    role,
  });
}

export async function getPendingContributionRates({
  planId,
  empId,
  role,
}: Args): Promise<PendingContributionRate[]> {
  return get(
    `/employees/${empId}/plans/${planId}/contributions/pending-contributions`,
    {
      role,
    }
  );
}

export async function deleteFutureContributionRates({
  planId,
  empId,
  role,
}: Args): Promise<String> {
  return get(
    `/employees/${empId}/plans/${planId}/contributions/delete-future-contributions`,
    {
      role,
    }
  );
}
