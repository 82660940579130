import React from "react";

type Props = {
  id: number | string;
  text: string;
};

export default function InvestmentDisclosure(props: Props) {
  const { id, text } = props;

  return (
    <dl>
      <dt id={`${id}`} tabIndex={-1} className="util-focus-visible-only">
        <span className="sr-only">Footnote </span>[{id}]
      </dt>
      <dd>{text}</dd>
    </dl>
  );
}
