import {RootState} from "../store/store";

export const moneyTypesSelector = (state: RootState) =>
    state.plan.moneyTypes.data;

export const hidePrrSelector = (state: RootState) =>
    state.plan.info.planFlags.shouldHidePersonalizedRateOfReturn;

export const restrictActiveParticipantAddressSelector = (state: RootState) =>
    state.plan.planDetails.restrictActiveParticipantAddress;

export const hideAccountNumberSelector = (state: RootState) =>
    state.plan.info.planFlags.shouldHideAccountNumber;

export const hidePersonalInformationSelector = (state: RootState) =>
    state.plan.info.planFlags.shouldHidePersonalInformation;

export const hideZeroMoneyTypes = (state: RootState) =>
    state.plan.info.planFlags.shouldHideZeroMoneyTypes;

// Dashboard
export const hideFinancialWellnessArticlesSelector = (state: RootState) =>
    state.plan.info.planFlags.shouldHideFinancialWellnessArticles;

export const hidePTCTrackerSelector = (state: RootState) =>
    state.plan.info.planFlags.shouldHidePTCTracker;

export const hideAccountOverviewSelector = (state: RootState) =>
    state.plan.info.planFlags.shouldHideAccountOverviewOnDashboard;

export const hideDashboardTabbedViewSelector = (state: RootState) =>
    state.plan.info.planFlags.shouldHideDashboardTabbedView;

export const hidePerformanceTabSelector = (state: RootState) =>
    state.plan.info.planFlags.shouldHidePerformanceTabOnDashboard;

export const hideAccountsTabSelector = (state: RootState) =>
    state.plan.info.planFlags.shouldHideAccountsTabOnDashboard;

export const hideContributionPanelSelector = (state: RootState) =>
    state.plan.info.planFlags.shouldHideContributionPanel;

export const hideEnrollmentVideoLinkSelector = (state: RootState) =>
    state.plan.info.planFlags.shouldHideEnrollmentVideoLink;

// Account Overview
export const hideInvestmentsTabSelector = (state: RootState) =>
    state.plan.info.planFlags.shouldHideInvestmentsTabOnAccountOverview;

export const hideAllocationsTabSelector = (state: RootState) =>
    state.plan.info.planFlags.shouldHideAllocationsTabOnAccountOverview;

export const hideContributionsTabSelector = (state: RootState) =>
    state.plan.info.planFlags.shouldHideContributionsTabOnAccountOverview;

export const hideDistributionsTabSelector = (state: RootState) =>
    state.plan.info.planFlags.shouldHideDistributionsTabOnAccountOverview;

// Beneficiaries
export const hidePrimaryBeneficiariesSelector = (state: RootState) =>
    state.plan.info.planFlags.shouldHidePrimaryBeneficiaries;

export const hideContingentBeneficiariesSelector = (state: RootState) =>
    state.plan.info.planFlags.shouldHideContingentBeneficiaries;

// On-Demand Statement
export const hideAllAccountsSelector = (state: RootState) =>
    state.plan.info.planFlags.shouldHideAllAccountsOnOnDemandStatement;

export const hideContributionBreakdownSelector = (state: RootState) =>
    state.plan.info.planFlags.shouldHideContributionBreakdownOnOnDemandStatement;

export const hideInvestmentAllocationsSelector = (state: RootState) =>
    state.plan.info.planFlags.shouldHideInvestmentAllocationOnOnDemandStatement;

export const hideTotalsInThisPeriodSelector = (state: RootState) =>
    state.plan.info.planFlags.shouldHideTotalsInThisPeriodOnOnDemandStatement;

export const hideAccountBalanceChartSelector = (state: RootState) =>
    state.plan.info.planFlags
        .shouldHideTotalAccountBalanceChartOnOnDemandStatement;

export const hideIndividualAccountSelector = (state: RootState) =>
    state.plan.info.planFlags.shouldHideIndividualAccountStatements;

// Contribution Summary
export const hideDeferralElectionsSelector = (state: RootState) =>
    state.plan.info.planFlags.shouldHideDeferralElectionsOnContributionSummary;

export const hideContributionsResourcesSelector = (state: RootState) =>
    state.plan.info.planFlags
        .shouldHideContributionsResourcesOnContributionSummary;

export const hideContributionAmountsSelector = (state: RootState) =>
    state.plan.info.planFlags.shouldHideContributionAmountsOnContributionSummary;

// Change Investments
export const hideFutureInvestmentsSelector = (state: RootState) =>
    state.plan.info.planFlags.shouldHideFutureInvestmentsOnChangeInvestments;

export const hideCurrentInvestmentsSelector = (state: RootState) =>
    state.plan.info.planFlags.shouldHideCurrentInvestmentsOnChangeInvestments;

// Distributions
export const hideScheduledDistributionsTimelineSelector = (state: RootState) =>
    state.plan.info.planFlags
        .shouldHideScheduledDistributionsTimelineOnDistributions;

export const hideDistributionElectionSummarySelector = (state: RootState) =>
    state.plan.info.planFlags
        .shouldHideDistributionElectionSummaryOnDistributions;

export const hideDistributionForecastingSelector = (state: RootState) =>
    state.plan.info.planFlags.shouldHideDistributionForecastingTab;

export const hideScheduledDistributionsSelector = (state: RootState) =>
    state.plan.info.planFlags.shouldHideScheduledDistributionsOnDistributions;

export const hideDistributionHistorySelector = (state: RootState) =>
    state.plan.info.planFlags.shouldHideDistributionHistoryOnDistributions;

export const shouldHideStatementsSelector = (state: RootState) =>
    state.plan.info.planFlags.shouldHideStatements;

export const shouldHideOnDemandStatementSelector = (state: RootState) =>
    state.plan.info.planFlags.shouldHideOnDemandStatement;

// Plan Statements

export const showTrsStatementsSelector = (state: RootState) =>
    state.plan.info.planFlags.shouldShowTrsStatements;

// Personal Details Page
export const planAllowsWireACHSelector = (state: RootState) =>
    state.plan.planDetails.planAllowsWireACH;

export const paymentInfoSelector = (state: RootState) => state.plan.paymentInfo;

// Dynamic Learning Center Links
export const shouldHideEducationHubSelector = (state: RootState) =>
    state.plan.info.planFlags.shouldHideEducationHub;

export const shouldHideToolsSelector = (state: RootState) =>
    state.plan.info.planFlags.shouldHideTools;

export const shouldHidePlanInfoAndFormsSelector = (state: RootState) =>
    state.plan.info.planFlags.shouldHidePlanInfoAndForms;

export const contractIdSelector = (state: RootState) =>
    state.plan.info.contractId
