import React from "react";
import { ScheduledDistributionForRedeferral } from "../../../models/distributions/scheduled-distribution-event";

export interface Props {
  scheduledDistribution: ScheduledDistributionForRedeferral;
  onChange: (field: string, value: boolean) => void;
  editable: boolean;
}

export default function RedeferralCheckbox(props: Props) {
  const { scheduledDistribution, onChange, editable } = props;

  if (!editable) {
    if (scheduledDistribution.isRedeferred !== undefined) {
      return (
        <div data-testid={`isRedeferred-${scheduledDistribution.installNum}`}>
          {"" + scheduledDistribution.isRedeferred}
        </div>
      );
    }
    return null;
  }

  if (!scheduledDistribution.pendingDistributionDate) {
    return (
      <div className="checkbox styled-checkbox">
        <input
          data-testid={`redeferral-checkbox-${scheduledDistribution.installNum}`}
          type="checkbox"
          id={`${scheduledDistribution.installNum}-isRedeferred`}
          name={`${scheduledDistribution.installNum}-isRedeferred`}
          onChange={() =>
            onChange("isRedeferred", !scheduledDistribution.isRedeferred)
          }
          checked={scheduledDistribution.isRedeferred}
        />
        <label htmlFor={`${scheduledDistribution.installNum}-isRedeferred`} />
      </div>
    );
  }

  return <></>;
}
