import React from "react";
import "./dashboard-tab.scss";
import classnames from "classnames";

export interface Props {
  tabName: string;
  setSelectedTab: Function;
  selectedTab: string;
  iconClass: string;
  setShouldUpdateTour: Function;
}

export function DashboardTab(props: Props) {
  const {
    tabName,
    setSelectedTab,
    selectedTab,
    iconClass,
    setShouldUpdateTour,
  } = props;
  const isActive = selectedTab === tabName;
  const tabClass = classnames("dashboard-view-control", {
    active: isActive,
  });

  return (
    <>
      <button
        onClick={() => {
          setSelectedTab(tabName);
          setShouldUpdateTour(tabName);
        }}
        data-testid={`${tabName}-tab`}
        key={tabName}
        className={tabClass}
        id={`dashboard-tab-${tabName}`}
        role="tab"
        aria-selected={isActive ? "true" : "false"}
        tabIndex={isActive ? 0 : -1}
      >
        <i className={`fa dashboard-icon ${iconClass}`} aria-hidden="true" />
        &nbsp;{tabName}
      </button>
    </>
  );
}
