import React, { useEffect, useState } from "react";
import AccountDropdown from "../../../components/account-dropdown/account-dropdown";
import { Page, PageHeader } from "../../../components/page";
import { fetchSingleDayInvestments } from "../../../reducers/single-day-investments";
import { useDates } from "../../../selectors/dates";
import {
  accountsStatusSelector,
  datesStatusSelector,
  investmentsStatusSelector,
  singleDayInvestmentsStatus,
  useStatuses,
} from "../../../selectors/status";
import { useAppDispatch, useSelector } from "../../../store/store";
import { AccountInfoDisclaimers } from "./account-info-disclaimers";
import "./account-info-page.scss";
import {
  AccountInfoTabNames,
  AccountInfoTabs,
  AccountInfoTabsComponents,
} from "./account-info-tabs";
import { fetchAccountsAtAGlance } from "../../../reducers/accounts-at-a-glance/accounts-at-a-glance";
import { setShowPaidOutAccounts } from "../../../reducers/accounts";

export default function AccountInfoPage() {
  const { isLoadingStatus } = useStatuses([
    investmentsStatusSelector,
    accountsStatusSelector,
    datesStatusSelector,
    singleDayInvestmentsStatus,
  ]);
  const { planId, empId, role } = useSelector((state) => state.session);
  const { lastBusinessDate } = useDates();
  const dispatch = useAppDispatch();
  const { showPaidOutAccounts, paidOutAccountsExist, allAccountsPaidOut } =
    useSelector((state) => state.accounts);
  const [buttonText, setButtonText] = useState("Hide Paid Out Accounts");
  useEffect(() => {
    if (lastBusinessDate) {
      dispatch(
        fetchSingleDayInvestments({ planId, empId, role, lastBusinessDate })
      );
    }
  }, [dispatch, planId, empId, role, lastBusinessDate]);

  useEffect(() => {
    if (showPaidOutAccounts) {
      setButtonText("Hide Paid Out Accounts");
    } else {
      setButtonText("View Paid Out Accounts");
    }
  }, [showPaidOutAccounts]);

  useEffect(() => {
    dispatch(fetchAccountsAtAGlance({ planId, empId, role }));
  }, [dispatch, planId, empId, role]);

  const [selectedTab, setSelectedTab] = useState<AccountInfoTabNames>(
    AccountInfoTabNames.DETAILS
  );
  const { component: SelectedTab } = AccountInfoTabsComponents[selectedTab];

  function handleTabArrow(event: React.KeyboardEvent<HTMLDivElement>) {
    const { key } = event;
    if (key !== "ArrowRight" && key !== "ArrowLeft") {
      return;
    }
    const tabNames = Object.keys(
      AccountInfoTabsComponents
    ) as AccountInfoTabNames[];
    const currentTabIndex = tabNames.indexOf(selectedTab);

    // determine which tab to switch to, looping around if needed
    let newTabIndex = 0;
    if (key === "ArrowRight") {
      newTabIndex = currentTabIndex + 1;
      if (newTabIndex > tabNames.length - 1) {
        newTabIndex = 0;
      }
    } else if (key === "ArrowLeft") {
      newTabIndex = currentTabIndex - 1;
      if (newTabIndex < 0) {
        newTabIndex = tabNames.length - 1;
      }
    }
    const newTabName = tabNames[newTabIndex];

    setSelectedTab(newTabName);
    const newTab = event.currentTarget.querySelector<HTMLButtonElement>(
      `#dashboard-tab-${newTabName}`
    );
    newTab?.focus();
  }

  function handleViewPaidOutAccountsButtonClick() {
    if (!showPaidOutAccounts) {
      dispatch(setShowPaidOutAccounts(true));
    } else {
      dispatch(setShowPaidOutAccounts(false));
    }
  }

  return (
    <Page name={"account-details"} printable loading={isLoadingStatus}>
      <PageHeader name={"account details"}>Account at a Glance</PageHeader>
      <div className="btn-toolbar" role="toolbar">
        <div className="btn-group" role="group">
          <AccountDropdown
            isSingleSelect
            hidePaidAccounts
            showPaidOutAccounts={showPaidOutAccounts}
          />
        </div>
        {paidOutAccountsExist && !allAccountsPaidOut && (
          <div className="btn-group viewPaidOutAccountsButton" role="group">
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleViewPaidOutAccountsButtonClick}
            >
              {buttonText}
            </button>
          </div>
        )}
      </div>
      <div className="row" id="dashboard-tabs">
        <div
          className="col-md-12 text-center dashboard-controls"
          role="tablist"
          aria-label="Account views"
          onKeyDown={handleTabArrow}
        >
          <AccountInfoTabs
            setSelectedTab={setSelectedTab}
            selectedTab={selectedTab}
          />
        </div>
      </div>
      <div className="row util-margin-top-20">
        <div
          className="col-md-12 util-focus-visible-only"
          role="tabpanel"
          tabIndex={0}
          aria-labelledby={`dashboard-tab-${selectedTab}`}
        >
          <SelectedTab />
        </div>
      </div>
      <div className={"prr-disclaimer"}>
        <AccountInfoDisclaimers selectedTab={selectedTab} />
      </div>
    </Page>
  );
}
