import classNames from "classnames";
import React from "react";
import _uniqBy from "lodash/uniqBy";
import {
  ContributionType,
  ElectionOption,
} from "../../../models/contributions";

type Props = {
  compensationName: string;
  compensationType: string;
  setValue: Function;
  availableElections: ElectionOption[];
  resetErrorsInInteraction: any;
};

export default function ContributionTypeButtons(props: Props) {
  const {
    compensationName,
    compensationType,
    setValue,
    availableElections,
    resetErrorsInInteraction,
  } = props;

  const uniqueAvailableElections = _uniqBy(
    availableElections,
    (election) => election.electionType
  ).filter((item) => item.electionType !== ContributionType.NO_DEFERRAL);

  function createClickHandler(value: ContributionType) {
    const compType = `${compensationName}Type`;
    const compVal = `${compensationName}Value`;

    return () => {
      if (value !== compensationType) {
        setValue(compVal, "");
        setValue(compType, value);
        resetErrorsInInteraction();
      }
    };
  }

  if (uniqueAvailableElections.length > 0) {
    return (
      <div className="btn-group type-buttons">
        {uniqueAvailableElections.map((availableElection) => {
          const { electionType, id } = availableElection;
          return (
            <button
              key={id}
              onClick={createClickHandler(electionType)}
              type="button"
              className={classNames("btn", {
                "btn-primary": compensationType === electionType,
              })}
              data-testid={`${electionType}-btn`}
            >
              {getButtonText(electionType)}
            </button>
          );
        })}
      </div>
    );
  }
  return null;
}

function getButtonText(type: ContributionType) {
  if (type === ContributionType.DOLLAR) {
    return "$";
  }
  if (type === ContributionType.PERCENT) {
    return "%";
  }
  return "other";
}
