import React from "react";
import { useSelector } from "../../../../store/store";
import { CustomContributionsTable } from "../../../../components/table/custom-contributions-table";
import { ContributionHistory } from "../../../../models/contributions";
import _groupBy from "lodash/groupBy";

type Props = {
  isByAccount: boolean;
};

export default function ContributionSources(props: Props) {
  const { isByAccount } = props;
  const contributionHistories: ContributionHistory[] = useSelector(
    (state) => state.contributions.contributionHistory.data
  );
  if (contributionHistories.length === 0) {
    return (
      <>
        <h2 className="h4">Recent Contributions</h2>
        <p>You have no recent contributions</p>
      </>
    );
  }

  const groupedHistories = groupHistoriesByTransactionDate(
    contributionHistories
  );

  return (
    <>
      <h2 className="h4">Recent Contributions</h2>
      <CustomContributionsTable
        contributionHistories={groupedHistories}
        isByAccount={isByAccount}
      />
    </>
  );
}

export function groupHistoriesByTransactionDate(
  contributionHistories: ContributionHistory[]
) {
  return Object.values(_groupBy(contributionHistories, "transactionDate"));
}
