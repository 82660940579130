import { post, UserActivityBody } from "../repositories/user-activity";

export const logActivity: () => void = () => {
  const location = window.location;
  const params = location.search.substr(1);
  const anchors = location.hash.substr(1);
  const impersonated = localStorage.getItem("impersonated") === "true";
  const enterpriseId = localStorage.getItem("enterpriseId");
  const { planId, memberId } = JSON.parse(
    sessionStorage.getItem("session_string") ?? "{}"
  );
  const contractId = new URLSearchParams(params).get("contractId");

  const body: UserActivityBody = {
    enterpriseId: enterpriseId?.toString() ?? "",
    timestamp: new Date().getTime().toString(),
    app: "participant-ui",
    contractId: contractId?.toString() ?? "",
    empId: memberId?.toString() ?? "",
    planId: planId?.toString() ?? "",
    isImposter: impersonated,
    urlDomain: location.hostname,
    urlFilePath: location.pathname,
    urlParameters: params,
    urlAnchors: anchors,
  };
  post(body);
};
