import React from "react";
import { Page, PageHeader } from "../../../components/page";
import {
  planInfoStatusSelector,
  sessionStatusSelector,
  useStatuses,
} from "../../../selectors/status";
import ContributionsSummaryInfo from "./contributions-summary-anchor-info";
import ContributionsSummaryWrapper from "./contributions-summary-wrapper";

export default function ContributionsSummaryPage() {
  const { isLoadingStatus } = useStatuses([
    sessionStatusSelector,
    planInfoStatusSelector,
  ]);

  return (
    <Page
      printable
      className="investment-allocations"
      name="investment-allocations"
      loading={isLoadingStatus}
    >
      <PageHeader name="investment-allocations">Contributions</PageHeader>
      <ContributionsSummaryWrapper />
      <ContributionsSummaryInfo />
    </Page>
  );
}
