import React, { useEffect } from "react";
import { AsyncStatus } from "./models/async-status";
import { fetchAccounts } from "./reducers/accounts";
import { fetchAllocations } from "./reducers/allocations";
import { fetchContributionRates } from "./reducers/contributions/contribution-rates";
import { fetchContributionSummary } from "./reducers/contributions/contribution-summary";
import { fetchPendingContributionRates } from "./reducers/contributions/pending-contribution-rates";
import { fetchContributionHistory } from "./reducers/contributions/contribution-history";
import { fetchLastBusinessDate } from "./reducers/dates";
import { fetchInvestments, setUseLiabilities } from "./reducers/investments";
import { fetchNqSingleAccountDetails } from "./reducers/nq-account-single";
import { fetchMoneyTypes } from "./reducers/plan/money-types";
import { fetchPlanConfig } from "./reducers/plan/plan-config";
import { fetchPlanInfo } from "./reducers/plan/plan-info-reducer";
import { fetchPrivileges } from "./reducers/privileges/privileges";
import { useDates } from "./selectors/dates";
import { useAppDispatch, useSelector } from "./store/store";
import { fetchParticipantDetails } from "./reducers/participant";
import { useLiabilitiesRestApi } from "./selectors/feature-flags";
import { fetchStatementBalance } from "./reducers/statement-balance";

type Props = {
  children: React.ReactNode;
};

export function PrefetchWrapper(props: Props) {
  const { children } = props;
  const dispatch = useAppDispatch();
  const { planId, empId, role } = useSelector((state) => state.session);
  const { planCode, contractId } = useSelector((state) => state.plan.info);
  const participant = useSelector((state) => state.participant);
  const useLiabilitiesApi = useSelector(useLiabilitiesRestApi);
  const {
    startDate,
    endDate,
    status,
    investmentSummaryStartDate,
    investmentSummaryEndDate,
  } = useDates();

  useEffect(() => {
    dispatch(fetchLastBusinessDate());
  }, [dispatch]);

  useEffect(() => {
    dispatch(setUseLiabilities(useLiabilitiesApi));
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchAccounts({ planId, empId, role }));
    dispatch(fetchPlanInfo({ planId, empId, role }));
    dispatch(fetchPlanConfig({ planId, empId, role }));
    dispatch(fetchMoneyTypes(planId));
    dispatch(fetchAllocations({ planId, empId, role }));
    dispatch(fetchPrivileges({ planId, empId, role }));
    dispatch(fetchContributionRates({ planId, empId, role }));
    dispatch(fetchPendingContributionRates({ planId, empId, role }));
    dispatch(fetchParticipantDetails({ planId, empId, role }));
  }, [dispatch, planId, empId, role]);

  useEffect(() => {
    if (participant.status === AsyncStatus.done) {
      dispatch(
        fetchNqSingleAccountDetails({
          enterpriseId: participant.data.enterpriseId,
          planId,
        })
      );
    }
  }, [dispatch, participant, planId, role]);

  useEffect(() => {
    if (planCode && planCode.toUpperCase() !== "DB") {
      dispatch(fetchContributionHistory({ planId, empId, role }));
      dispatch(fetchContributionSummary({ planId, empId, role }));
    }
  }, [dispatch, planId, empId, role, planCode]);

  useEffect(() => {
    if (status === AsyncStatus.done) {
      dispatch(fetchInvestments({ planId, empId, role, startDate, endDate }));
    }
  }, [
    dispatch,
    planId,
    empId,
    role,
    startDate,
    endDate,
    status,
    window.location.pathname,
  ]);

  useEffect(() => {
    if (status === AsyncStatus.done && contractId) {
      dispatch(
        fetchStatementBalance({
          contractId,
          empId,
          role,
          beginDate: startDate,
          endDate,
        })
      );
    }
  }, [
    dispatch,
    contractId,
    empId,
    role,
    status,
    window.location.pathname,
    startDate,
    endDate,
  ]);

  useEffect(() => {
    if (
      status === AsyncStatus.done &&
      investmentSummaryStartDate &&
      investmentSummaryEndDate
    ) {
      dispatch(
        fetchInvestments({
          planId,
          empId,
          role,
          startDate: investmentSummaryStartDate,
          endDate: investmentSummaryEndDate,
        })
      );
    }
  }, [investmentSummaryStartDate, investmentSummaryEndDate]);

  return <>{children}</>;
}
