import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import AccountName from "../../../../components/account-name/account-name";
import Balance from "../../../../components/numbers/balance";
import accountTypeString from "../../../../models/distributions/account-type";
import { FutureDistributionTimelinePayment } from "../../../../models/distributions/distribution-payment";
import { useSelector } from "../../../../store/store";
import "../scheduled-distribution-card.scss";

type Props = {
  scheduledDistribution: FutureDistributionTimelinePayment;
  index: number;
  hasInvestmentAllocations?: boolean;
};

type AnimatedDivProps = { index: number };

const AnimatedDiv = styled.div.attrs((props: AnimatedDivProps) => props)`
  &.scheduled-distribution-card-enter,
  &.scheduled-distribution-card-appear {
    opacity: 0;
    transform: translateY(20%);
    transition-delay: ${({ index }) => index * 150}ms;
  }

  &.scheduled-distribution-card-enter-active,
  &.scheduled-distribution-card-appear-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity, transform;
    transition-duration: 500ms;
    transition-delay: ${({ index }) => index * 150}ms;
  }
`;

export function DistributionCard(props: Props) {
  const { scheduledDistribution, index } = props;
  const investments = useSelector((state) => state.investments.data);
  const hasInvestmentAllocations = investments.some(
    (investment) => investment.accountId === scheduledDistribution.accountId
  );

  if (!scheduledDistribution.value) {
    return (
      <NoInvestmentsFound
        scheduledDistribution={scheduledDistribution}
        index={index}
        hasInvestmentAllocations={hasInvestmentAllocations}
      />
    );
  }
  let color = "primary";

  if (scheduledDistribution.color) {
    color = "alt-color";
  }
  return (
    <AnimatedDiv
      data-testid="scheduled-distribution-card"
      className={`scheduled-distribution-card ${color}`}
      index={index}
    >
      <div className={`account-type ${color}`}>
        {accountTypeString(scheduledDistribution.accountType || "")}
      </div>
      <div className="card-container">
        <p className="account-name h4">
          <AccountName
            name={scheduledDistribution.accountName}
            id={scheduledDistribution.accountId}
            asLink={false}
          />
        </p>
        <table>
          <tbody>
            <tr>
              <td>Payout Date</td>
              <td>
                {scheduledDistribution.payoutDate.getMonth() + 1}/
                {scheduledDistribution.payoutDate.getDate()}/
                {scheduledDistribution.payoutDate.getFullYear()}
              </td>
            </tr>
            <tr>
              <td>Estimated Payment</td>
              <td>
                <Balance showDollar value={scheduledDistribution.value} />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </AnimatedDiv>
  );
}

function NoInvestmentsFound(props: Props) {
  const { scheduledDistribution, index, hasInvestmentAllocations } = props;
  return (
    <AnimatedDiv className="scheduled-distribution-card primary" index={index}>
      <div className="account-type">
        {accountTypeString(scheduledDistribution.accountType || "")}
      </div>
      <div className="card-container">
        <p className="account-name h4">
          <AccountName
            name={scheduledDistribution.accountName}
            id={scheduledDistribution.accountId}
            asLink={false}
          />
        </p>
        {hasInvestmentAllocations && (
          <p>This account has no balance information</p>
        )}
        {!hasInvestmentAllocations && (
          <>
            <p>No investments found for this account</p>
            <Link to="/investments/manage" className="btn btn-sm btn-primary">
              Set investments
            </Link>
          </>
        )}
      </div>
    </AnimatedDiv>
  );
}
