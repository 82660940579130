import { html } from 'lit';
import { themedefault } from '@principal/design-system-tokens';
import { property, query } from 'lit/decorators.js';
import { pdsCustomElement as customElement } from '../../decorators/pds-custom-element';
import { PdsElement } from '../PdsElement';
import styles from './global-header-dropdown-item.scss?inline';
import '@principal/design-system-icons-web/chevron-down';
import { required } from '../../decorators/required';

/**
 * @summary This component is a button element that contains content
 *
 * @slot default Required: One or more components to be rendered inside the dropdown panel
 * @slot footer Optional: A component placed in the footer of the dropdown panel after the default slot
 * @slot label Optional: A label for the dropdown item, restricted to pds-global-header-user-menu-label
 *
 * @fires pds-global-header-dropdown-item-open A custom event dispatched on opening the dropdown
 * @fires pds-global-header-dropdown-item-close A custom event dispatched on closing the dropdown
 */
@customElement('pds-global-header-dropdown-item', {
  category: 'component',
  type: 'component',
  styles,
})
export class PdsGlobalHeaderDropdownItem extends PdsElement {
  /**
   * Returns if the dropdown item is on the current page
   */
  @property({ type: Boolean })
  current: boolean = false;

  /**
   * Tracks the open class for the dropdown item
   * @internal
   */
  @property({ type: Boolean })
  open: boolean = false;

  /**
   * The label text that appears on the dropdown item. This is a **required** property.
   */
  @required
  @property({ type: String })
  label: string = '';

  /**
   * The menu div element that contains the menu content and footer slot
   * @internal
   */
  @query('.pds-c-global-header-dropdown-item__menu')
  menu: HTMLElement;

  /**
   * The button element that contains the label of the dropdown item
   * @internal
   */
  @query('.pds-c-global-header-dropdown-item__button')
  button: HTMLElement;

  /**
   * Flag for mobile view
   * @internal
   */
  isMobile() {
    return (
      this.responsiveViewportSize === 'xs' ||
      this.responsiveViewportSize === 'sm' ||
      this.responsiveViewportSize === 'md'
    );
  }

  /**
   * Flag for break faster view
   * @internal
   */
  isBreakFaster() {
    return (
      this.shadowRoot?.querySelector('.break-faster') !== null &&
      (this.responsiveViewportSize === 'sm' ||
        this.responsiveViewportSize === 'md')
    );
  }

  constructor() {
    super();
    this.handleOnClickOutside = this.handleOnClickOutside.bind(this);
  }

  connectedCallback() {
    super.connectedCallback();
    document.addEventListener('mouseup', this.handleOnClickOutside, false);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    document.removeEventListener('mouseup', this.handleOnClickOutside, false);
  }

  override firstUpdated() {
    super.firstUpdated();
    this.setWindowResizeHandler();

    this.button.addEventListener('keydown', (e) => {
      this.handleKeydown(e);
    });
    this.menu.addEventListener('keydown', (e) => {
      this.handleKeydown(e);
    });
  }

  changeWidthOfPanel() {
    if (this.shadowRoot) {
      const customWidth = this.style.getPropertyValue(
        '--pds-global-header-dropdown-item-menu-width',
      );

      const numCustomWidth = parseInt(customWidth, 10);

      // If the user has a custom menu width and it's less than 184px, set the menu min-width to 184px
      if (numCustomWidth && numCustomWidth < 184) {
        this.menu.style.minWidth = '184px';
      }
      // Else if check to see if a user does not have a custom menu width and the window viewport is greater than or equal to 1024px
      else if (
        !getComputedStyle(this.menu).getPropertyValue(
          '--pds-global-header-dropdown-item-menu-width',
        ) &&
        window.innerWidth >= themedefault.BreakpointsPixelLg
      ) {
        const buttonWidth = window
          .getComputedStyle(this.button)
          .getPropertyValue('width');

        // Have to set menu to inline-block to get correct auto menu-width since menu is display: none at first
        this.menu.style.display = 'inline-block';
        let numMenuWidth = this.menu.scrollWidth;
        const numButtonWidth = parseInt(buttonWidth, 10);

        // If the current menu width is lower than the min-width expected, set the menu width to the min-width
        if (numMenuWidth < 184) {
          this.menu.style.minWidth = '184px';
          numMenuWidth = 184;
        }

        // Reset display to default scss for dropdown menu
        this.menu.style.display = '';

        // Set menu width to button width if its width is lower than button width
        if (numMenuWidth < numButtonWidth) {
          this.menu.style.width = '';
          this.menu.style.setProperty(
            '--pds-global-header-dropdown-item-menu-width',
            `${numButtonWidth}px`,
          );
        }
      }
    }
  }

  toggleOpen() {
    this.open = !this.open;

    // This controls the slideDown and slideUp animation by setting and unsetting the maxHeight of the menu
    if (this.menu.style.maxHeight) {
      this.menu.style.top = '60px';
      this.menu.style.maxHeight = '';
      this.menu.classList.remove('no-fade-in-transition');
    } else {
      this.menu.classList.add('no-fade-in-transition');
      this.menu.style.maxHeight = `${this.menu.scrollHeight}px`;
      this.menu.style.position =
        this.isMobile() && !this.isBreakFaster() ? 'relative' : 'absolute';
      this.menu.style.top =
        this.isMobile() && !this.isBreakFaster() ? '0' : '60px';
    }
  }

  handleOnClickOutside(e: MouseEvent) {
    if (!this.open) {
      return;
    }
    const didClickInside = e.composedPath().includes(this);

    // Only apply click outside breakpoint greater than or equal to 1024px to get better accordion behavior at mobile
    if (window.innerWidth >= themedefault.BreakpointsPixelLg) {
      // If the dropdown is open and we've clicked outside of the dropdown then it should be closed.
      if (this.open && !didClickInside) {
        const customEvent = new CustomEvent(
          'pds-global-header-dropdown-item-close',
          {
            bubbles: true,
            composed: true,
          },
        );

        this.dispatchEvent(customEvent);
        this.toggleOpen();
      }
    }
  }

  handleKeydown(e: KeyboardEvent) {
    if (e.key === 'Escape') {
      e.preventDefault();
      const customEvent = new CustomEvent(
        `pds-global-header-dropdown-item-close`,
        {
          bubbles: true,
          composed: true,
        },
      );
      this.dispatchEvent(customEvent);

      this.toggleOpen();
    }
  }

  handleClick() {
    const customEvent = new CustomEvent(
      `pds-global-header-dropdown-item-${
        this.open === false ? 'open' : 'close'
      }`,
      {
        bubbles: true,
        composed: true,
      },
    );

    this.dispatchEvent(customEvent);
    this.toggleOpen();
    this.changeWidthOfPanel();
  }

  /**
   * @internal
   */
  get classNames() {
    return {
      current: !!this.current,
      'is-open': !!this.open,
    };
  }

  render() {
    return html`<div class=${this.getClass()}>
      <button
        @click=${this.handleClick}
        aria-expanded=${this.open === true}
        aria-label=${this.translateText('dropdown-button')}
        class="${this.classEl('button')}"
        part="global-header-dropdown-item-button"
      >
        <span class="${this.classEl('label')}">
          ${this.slotEmpty('label')
            ? this.label
            : html`<slot
                name="label"
                allowed-elements="pds-global-header-user-menu-label"
                @slotchange=${this.handleSlotValidation}
              ></slot>`}</span
        >
        <span
          class="${this.classEl('icon')}"
          part="global-header-dropdown-item-icon"
          ><pds-icon-chevron-down></pds-icon-chevron-down
        ></span>
      </button>
      <div class="${this.classEl('menu')}" part="global-header-dropdown-menu">
        <div
          class="${this.classEl('menu-content')}"
          part="global-header-dropdown-menu-content"
        >
          <slot></slot>
        </div>
        <div class=${this.classEl('footer')}>
          <slot name="footer" part="global-header-dropdown-menu-footer"></slot>
        </div>
      </div>
    </div>`;
  }
}
