import React from "react";
import "./statements-disclaimer.scss";

const StatementsDisclaimer = () => {
  return (
    <div className={"statements-disclaimer"}>
      <h3 className="h1">Important Information</h3>
      <p>
        As a participant in a nonqualified plan it is important that you
        understand the details of the plan and promptly review the statement for
        accuracy. Incorrect data may affect your benefits and have significant
        tax consequences to you. Please contact your plan sponsor as soon as
        possible if you believe there is an error on your statement. The amounts
        listed on this report represent a measure of your benefits under a
        nonqualified benefit agreement between you and your plan sponsor. It is
        not a representation that your plan sponsor holds any assets for paying
        these benefits or that any other assets are held in your name.
      </p>
      <p>
        Vested Balances shown in this report represent the portion of your
        benefit account available for distribution, upon a qualifying
        distribution event, as of the statement end date. This amount is your
        vested balance reduced by any prior distributions in which forfeiture of
        unvested amounts was waived.
      </p>
      <p>
        The Personalized Rate of Return (PRR) represents the performance of your
        benefit account(s) for the time period of this report. The PRR is based
        upon your specific benefit account activity. Past performance does not
        predict future results.
      </p>
      <p>
        The amounts shown as Pre-409A have been grandfathered and are not
        subject to the American Jobs Creation Act of 2004. Grandfathering of
        existing balances is dependent upon the plan remaining in compliance
        with Internal Revenue Code Section 409A and Notice 2005-1. Amounts shown
        as "409A" are subject to the provisions of the American Jobs Creation
        Act of 2004.
      </p>
    </div>
  );
};

export default StatementsDisclaimer;
