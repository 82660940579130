import { createSelector } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import {
  AsyncStatus,
  isLoadingStatus,
  isOnlyLoadingStatus,
} from "../models/async-status";
import { RootState } from "../store/store";

export type StatusSelector = (state: RootState) => AsyncStatus;

export const sessionStatusSelector: StatusSelector = (state) =>
  state.session.status;
export const investmentsStatusSelector: StatusSelector = (state) =>
  state.investments.status;
export const accountsStatusSelector: StatusSelector = (state) =>
  state.accounts.status;
export const planInfoStatusSelector: StatusSelector = (state) =>
  state.plan.info.status;
export const planConfigStatusSelector: StatusSelector = (state) =>
  state.plan.config.status;
export const datesStatusSelector: StatusSelector = (state) =>
  state.dates.status;
export const scheduledElectionsStatus: StatusSelector = (state) =>
  state.distributions.scheduledElections.status;
export const accountRedeferralStatusesStatusSelector: StatusSelector = (
  state
) => state.accountRedeferralStatuses.status;
export const featureFlagStatusSelector: StatusSelector = (state) =>
  state.featureFlags.status;
export const scheduledDistributionsStatus: StatusSelector = (state) =>
  state.distributions.scheduledDistributions.status;
export const unscheduledDistributionEventsStatus: StatusSelector = (state) =>
  state.distributions.unscheduledDistributionEvents.status;
export const unscheduledDistributionEventsByProvisionStatus: StatusSelector = (
  state
) => state.distributions.unscheduledDistributionEventsByProvision.status;
export const forecastDistributionsResourceStatus: StatusSelector = (state) =>
  state.distributions.forecastDistributionsResource.status;
export const forecastDistributionsResourceFromGraphStatus: StatusSelector = (
  state
) => state.distributions.forecastDistributionsResourceFromGraph.status;
export const redeferralInstructionStatus: StatusSelector = (state) =>
  state.redeferrals.redeferralInstructions.status;
export const scheduledDistributionsForRedeferralStatus: StatusSelector = (
  state
) => state.redeferrals.scheduledDistributionsForRedeferral.status;
export const messageStatus: StatusSelector = (state) => state.messages.status;
export const ptcStatus: StatusSelector = (state) =>
  state.distributions.ptcTracker.status;
export const participantDetailsStatus: StatusSelector = (state) =>
  state.participant.status;
export const planDetailsStatus: StatusSelector = (state) =>
  state.plan.planDetails.status;
export const paymentInfoStatus: StatusSelector = (state) =>
  state.plan.paymentInfo.status;
export const nqAccountSingleStatus: StatusSelector = (state) =>
  state.nqAccountDetailsSlice.status;
export const contributionRateStatus: StatusSelector = (state) =>
  state.contributions.contributionRates.status;
export const contributionRatePostStatus: StatusSelector = (state) =>
  state.contributions.contributionRates.updateStatus;
export const contributionHistoryStatus: StatusSelector = (state) =>
  state.contributions.contributionHistory.status;
export const allocationsStatus: StatusSelector = (state) =>
  state.allocations.status;
export const singleDayInvestmentsStatus: StatusSelector = (state) =>
  state.singleDayInvestments.status;
export const beneficiariesStatus: StatusSelector = (state) =>
  state.beneficiaries.beneficiaryStatus;
export const beneficiaryPlansStatus: StatusSelector = (state) =>
  state.beneficiaries.plansStatus;
export const beneficiariesPostStatus: StatusSelector = (state) =>
  state.beneficiaries.postStatus;
export const accountLevelElectionsStatusSelector: StatusSelector = (state) =>
  state.distributions.accountLevelElections.status;
export const participantLevelElectionsStatus: StatusSelector = (state) =>
  state.distributions.participantLevelElections.status;
export const accountBalancesByDateStatus: StatusSelector = (state) =>
  state.accountBalances.status;
export const privilegesStatus: StatusSelector = (state) =>
  state.privileges.status;
export const contextStatus: StatusSelector = (state) => state.context.status;
export const financialWellnessArticlesStatus: StatusSelector = (state) =>
  state.financialWellnessArticles.status;
export const planStatementStatus: StatusSelector = (state) =>
  state.planStatements.planstatements.status;
export const trsStatementStatus: StatusSelector = (state) =>
  state.trsStatements.trsStatements.status;
export const planInfoSummaryStatus: StatusSelector = (state) =>
  state.planInfoAndSummary.planInfoSummary.status;
export const enrollmentToolsStatus: StatusSelector = (state) =>
  state.enrollmentTools.enrollmentTools.status;
export const planningCenterToolsStatus: StatusSelector = (state) =>
  state.planningCenterTools.planningCenterTools.status;
export const streamOfPaymentPendingStatus: StatusSelector = (state) =>
  state.streamOfPaymentPending.streamOfPaymentPending.status;
export const overviewBenefitStatus: StatusSelector = (state) =>
  state.overviewBenefit.overviewBenefit.status;
export const definedBenefitAboutStatus: StatusSelector = (state) =>
  state.definedBenefitAbout.definedBenefitAbout.status;
export const balancesBySourceStatus: StatusSelector = (state) =>
  state.balancesBySource.balancesBySource.status;
export const accountsAtAGlanceStatus: StatusSelector = (state) =>
  state.accountsAtAGlance.status;
export const redeferralInstructionDtosStatus: StatusSelector = (state) =>
  state.redeferrals.redeferralInstructionDtos.status;
export const marketClosedDatesStatus: StatusSelector = (state) =>
  state.redeferrals.marketCloseDates.status;

export function useStatuses(dependencySelectors: StatusSelector[] = []) {
  const loadingSelector = createSelector(dependencySelectors, isAnyLoading);
  const onlyLoadingSelector = createSelector(
    dependencySelectors,
    isOnlyLoading
  );
  const errorSelector = createSelector(dependencySelectors, isAnyError);
  const isLoadingStatus = useSelector(loadingSelector);
  const isLoadingStatus2 = useSelector(loadingSelector);
  const isOnlyLoadingStatus = useSelector(onlyLoadingSelector);
  const hasError = useSelector(errorSelector);

  return { isLoadingStatus, hasError, isOnlyLoadingStatus, isLoadingStatus2 };
}

function isAnyLoading(...statuses: AsyncStatus[]): boolean {
  return statuses.some((status: AsyncStatus) => isLoadingStatus(status));
}

function isOnlyLoading(...statuses: AsyncStatus[]): boolean {
  return statuses.some((status: AsyncStatus) => isOnlyLoadingStatus(status));
}

function isAnyError(...statuses: AsyncStatus[]): boolean {
  return statuses.some((status: AsyncStatus) => status === AsyncStatus.error);
}
