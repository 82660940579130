import React, { useContext } from "react";
import { Link } from "react-router-dom";
import ReactToggle from "react-toggle";
import "react-toggle/style.css";
import { TogglesContext } from "../../contexts/toggles-context/toggles-context";
import { Toggles } from "../../models/toggles";
import { DistributionRoutes } from "../../routes/routes";
import DependencyHealthStatus from "./dependency-health-status";
import "./dev-tools.scss";
import { Page, PageHeader } from "../../components/page";

const landingLinks = ["/landing", "/accounts-landing"];

const overviewLinks = [
  "/overview/dashboard",
  "/overview/beneficiaries",
  "/overview/statements",
  "/enrollmentcenter/enrollment-center",
];

const investmentsLinks = [
  "/investments/manage",
  "/investments/research",
  "/investments/summary",
  "/investments/performance",
  "/investments/investment-allocations",
  "/investments/rate-of-return",
];

const contributionsLinks = [
  "/contributions/manage-contributions",
  "/contributions/account-allocations",
  "/contributions/summary",
];

const distributionsLinks = [
  "/distributions/summary",
  "/distributions/scheduled",
  DistributionRoutes.RedeferralsSummary,
];

function DevTools() {
  const { toggles, toggle } = useContext(TogglesContext);

  return (
    <Page name="dev-tools" loading={false}>
      <PageHeader name="dev-tools">Dev Tools</PageHeader>
      <br />
      <div>
        <ul className="nav nav-tabs" role="tablist">
          <li className="active" role="presentation">
            <a
              data-toggle="tab"
              data-testid={"links"}
              href="#links"
              role="tab"
              aria-expanded="false"
              aria-selected="false"
              tabIndex={-1}
            >
              Participant Web Links
            </a>
          </li>
          <li role="presentation">
            <a
              data-toggle="tab"
              data-testid={"health-checks"}
              href="#health-checks"
              role="tab"
              aria-expanded="false"
              aria-selected="false"
              tabIndex={-1}
            >
              Health Checks
            </a>
          </li>
        </ul>
      </div>

      <div className="tab-content">
        {renderToggles(toggles, toggle)}
        <div className="tab-pane active" id="links" role="tabpanel">
          <br />
          <div className=" row">
            <div className="column">
              <h2 className="card-header-text">
                <b>
                  <u>Landing</u>
                </b>
              </h2>
              <div className="links">
                {landingLinks.map((link) => (
                  <div>
                    <ul>
                      <li>
                        <Link to={link}>{link}</Link>
                      </li>
                    </ul>
                  </div>
                ))}
              </div>
            </div>

            <div className="column">
              <h2 className="card-header-text">
                <b>
                  <u>Overview</u>
                </b>
              </h2>
              <div className="links">
                {overviewLinks.map((link) => (
                  <div>
                    <ul>
                      <li>
                        <Link to={link}>{link}</Link>
                      </li>
                    </ul>
                  </div>
                ))}
              </div>
            </div>

            <div className="column">
              <h2 className="card-header-text">
                <b>
                  <u>Contributions</u>
                </b>
              </h2>
              <div className="links">
                {contributionsLinks.map((link) => (
                  <div>
                    <ul>
                      <li>
                        <Link to={link}>{link}</Link>
                      </li>
                    </ul>
                  </div>
                ))}
              </div>
            </div>

            <div className="column">
              <h2 className="card-header-text">
                <b>
                  <u>Investments</u>
                </b>
              </h2>
              <div className="links">
                {investmentsLinks.map((link) => (
                  <div>
                    <ul>
                      <li>
                        <Link to={link}>{link}</Link>
                      </li>
                    </ul>
                  </div>
                ))}
              </div>
            </div>

            <div className="column">
              <h2 className="card-header-text">
                <b>
                  <u>Distribution</u>
                </b>
              </h2>
              <div className="links">
                {distributionsLinks.map((link) => (
                  <div>
                    <ul>
                      <li>
                        <Link to={link}>{link}</Link>
                      </li>
                    </ul>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="tab-pane" id="health-checks" role="tabpanel">
          <br />
          <DependencyHealthStatus />
        </div>
      </div>
    </Page>
  );
}

function renderToggles(toggles: Toggles = {}, toggle: Function = () => {}) {
  return Object.entries(toggles).map(([key, value]) => {
    const { status, description } = value;
    return (
      <Toggle
        key={key}
        status={status}
        description={description}
        updateFn={() => toggle(key)}
      />
    );
  });
}

type Props = {
  status: boolean;
  description: string;
  updateFn: () => {};
};

function Toggle(props: Props) {
  const { status, description, updateFn } = props;
  return (
    <div className="toggle">
      <ReactToggle checked={status} onChange={updateFn} />
      <span>{description}</span>
    </div>
  );
}

export default DevTools;
