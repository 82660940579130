import { createSelector } from "@reduxjs/toolkit";
import { InvestmentV2 } from "../models/investment-v2";
import { RootState } from "../store/store";
import {
  filterInvestmentsByAccountIds,
  reduceInvestments,
} from "../util/investment-context-util";
import { selectedAccountIdsSelector } from "./accounts";

const singleDayInvestmentsSelector = (state: RootState) =>
  state.singleDayInvestments.data;
export const aggregatedSingleDayInvestmentSelector = createSelector(
  [singleDayInvestmentsSelector, selectedAccountIdsSelector],
  (investments, accountIds) => {
    const selectedInvestments = filterInvestmentsByAccountIds(
      investments,
      accountIds
    );
    if (selectedInvestments.length === 0) {
      return {} as InvestmentV2;
    }
    return reduceInvestments(selectedInvestments);
  }
);
