import React from "react";
import FixedDecimal from "./fixed-decimal";

type Props = {
  value: number;
  isPercentage?: boolean;
  defaultValue?: string;
  showSymbol?: boolean;
  roundTo?: number;
};

Percent.defaultProps = {
  isPercentage: false,
  defaultValue: "-",
  showSymbol: true,
  roundTo: 2,
};

export default function Percent(props: Props): JSX.Element {
  const { value, defaultValue, showSymbol, roundTo } = props;

  if (isNaN(value)) {
    return <>{defaultValue}</>;
  }

  if (!isFinite(value)) {
    return <>{defaultValue}</>;
  }

  let displayValue = value;

  if (!props.isPercentage) {
    displayValue = value * 100;
  }

  return (
    <>
      <FixedDecimal value={displayValue} precision={roundTo} />
      {showSymbol ? "%" : ""}
    </>
  );
}
