import classNames from "classnames";
import React from "react";
import { ColumnInterface } from "react-table";
import Balance from "../../../../components/numbers/balance";
import { Skeleton } from "../../../../components/skeleton/skeleton";
import {
  accountsStatusSelector,
  datesStatusSelector,
  investmentsStatusSelector,
} from "../../../../selectors/status";
import { getDateStringWithoutTimezoneFromDashDate } from "../../../../util/date-util";

export function buildDistributionDateColumn(): ColumnWithClass {
  const Header = () => (
    <span className="left-align header">
      Distribution Date
      <span className="no-wrap show">(MM/DD/YYYY)</span>
    </span>
  );
  return buildDateColumn(Header, "distributionDate", "left-align", false);
}

export function buildScheduledDateColumn(): ColumnWithClass {
  const Header = () => (
    <span className="left-align header">
      Distribution Date
      <span className="no-wrap show">(MM/DD/YYYY)</span>
    </span>
  );
  return buildDateColumn(Header, "scheduledDate", "left-align", false);
}

export function buildPendingDistributionDateColumn(): ColumnWithClass {
  const Header = () => (
    <span className="left-align header">
      Pending Distribution Date
      <span className="no-wrap show">(MM/DD/YYYY)</span>
    </span>
  );
  return buildDateColumn(
    Header,
    "pendingDistributionDate",
    "left-align",
    false
  );
}

export function buildStatusColumn(): ColumnWithClass {
  const Header = () => <span className="left-align header">Status</span>;
  return buildTextColumn(Header, "status", "left-align", false);
}

export function buildAccountNameNodeColumn(
  hideAccountNumber: boolean
): ColumnWithClass {
  const Header = () => <span className="header left-align">Account</span>;
  const disableGrouping = hideAccountNumber; // since account names are not unique, cannot aggregate unless we have id's
  return buildTextColumn(
    Header,
    "accountNameNode",
    "left-align",
    false,
    disableGrouping
  );
}

export function buildAccountTypeColumn(): ColumnWithClass {
  const Header = () => <span className="header left-align">Account Type</span>;
  return buildTextColumn(Header, "accountType", "left-align", false);
}

export function buildDistributionMethodColumn(): ColumnWithClass {
  const Header = () => (
    <span className="header left-align">Distribution Method</span>
  );
  return buildTextColumn(Header, "distributionMethod", "left-align");
}

export function buildInstallmentColumn(rightAlign: boolean): ColumnWithClass {
  const rightAlignClass = classNames([{ "right-align": rightAlign }]);
  const Header = () => (
    <span className={`header ${rightAlignClass}`}>Installment</span>
  );
  return buildTextColumn(Header, "installmentString", rightAlignClass);
}

export function buildInstallmentNumberColumn(
  rightAlign: boolean
): ColumnWithClass {
  const classes = classNames({ "right-align": rightAlign });
  const Header = () => <span className={`header ${classes}`}>Installment</span>;
  return buildTextColumn(Header, "installNum", classes);
}

export function buildEstimatedDistributionColumn(
  rightAlign: boolean
): ColumnWithClass {
  const rightAlignClass = classNames([{ "right-align": rightAlign }]);
  const Header = () => <span className="header">Estimated Distribution</span>;
  return buildNumberColumn(Header, "distributionAmount", rightAlignClass, true);
}

export interface ColumnWithClass extends ColumnInterface {
  accessor: string;
  className: string;
  disableSortBy: Boolean;
  disableGroupBy: Boolean;
  Cell: Function;
}

type CellInfo = {
  value?: any;
};

export function buildTextColumn(
  Header: () => JSX.Element,
  accessor: string,
  alignData: string = "left-align",
  disableSortBy: Boolean = true,
  disableGroupBy: Boolean = true
): ColumnWithClass {
  return {
    Header,
    accessor,
    disableSortBy: disableSortBy,
    disableGroupBy: disableGroupBy,
    className: `${alignData}`,
    Cell: (info: CellInfo) => {
      const { value } = info;
      if (!value) {
        return (
          <Skeleton
            selectors={[
              accountsStatusSelector,
              investmentsStatusSelector,
              datesStatusSelector,
            ]}
            height={50}
          >
            &nbsp;
          </Skeleton>
        );
      }
      return (
        <Skeleton
          selectors={[
            accountsStatusSelector,
            investmentsStatusSelector,
            datesStatusSelector,
          ]}
          height={50}
        >
          {value}
        </Skeleton>
      );
    },
  };
}

export function buildNumberColumn(
  Header: () => JSX.Element,
  accessor: string,
  align = "left-align",
  showDollar = false,
  disableSortBy: Boolean = true,
  disableGroupBy: Boolean = true
): ColumnWithClass {
  return {
    Header,
    accessor,
    className: `${align}`,
    disableSortBy: disableSortBy,
    disableGroupBy: disableGroupBy,
    Cell: ({ value = 0 }: CellInfo) => {
      return (
        <Skeleton
          selectors={[
            accountsStatusSelector,
            investmentsStatusSelector,
            datesStatusSelector,
          ]}
          height={50}
        >
          <Balance showDollar={showDollar} value={value} />
        </Skeleton>
      );
    },
  };
}

export function buildDateColumn(
  Header: () => JSX.Element,
  accessor: string,
  align = "left-align",
  disableSortBy: Boolean = true,
  disableGroupBy: Boolean = true
) {
  return {
    Header,
    accessor,
    disableSortBy: disableSortBy,
    disableGroupBy: disableGroupBy,
    className: `${align}`,
    Cell: ({ value }: CellInfo) => (
      <Skeleton
        selectors={[
          accountsStatusSelector,
          investmentsStatusSelector,
          datesStatusSelector,
        ]}
        height={50}
      >
        {getDateStringWithoutTimezoneFromDashDate(value, true)}
      </Skeleton>
    ),
  };
}
