import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getPrivileges } from "../../services/privileges-service";
import { AsyncStatus } from "../../models/async-status";
import { Privileges } from "../../models/privileges/privileges";

export const fetchPrivileges = createAsyncThunk(
  "fetch-privileges",
  async (
    { planId, empId, role }: { planId: number; empId: number; role: string },
    thunkApi
  ) => {
    return getPrivileges({ planId, empId, role });
  }
);

export type State = {
  data: Privileges;
  status: AsyncStatus;
};

const initialState: State = {
  data: {} as Privileges,
  status: AsyncStatus.not_fetched,
};

const privilegesSlice = createSlice({
  name: "privileges",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPrivileges.pending, (state, action) => {
      return { ...state, status: AsyncStatus.loading };
    });
    builder.addCase(
      fetchPrivileges.fulfilled,
      (state, action: { payload: Privileges; type: string }) => {
        const data = action.payload;

        return {
          ...state,
          data,
          status: AsyncStatus.done,
        };
      }
    );
    builder.addCase(fetchPrivileges.rejected, (state, action) => {
      return { ...state, status: AsyncStatus.error };
    });
    builder.addDefaultCase((state, action) => state);
  },
});

export default privilegesSlice.reducer;
