import { combineReducers } from "@reduxjs/toolkit";

import info from "./plan-info-reducer";
import moneyTypes from "./money-types";
import config from "./plan-config";
import planDetails from "./plan-details";
import paymentInfo from "./payment-info";

const planReducer = combineReducers({
  info,
  moneyTypes,
  config,
  planDetails,
  paymentInfo,
});

export default planReducer;
