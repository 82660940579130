import { NqAccount } from "../models/nq-accounts.model";
import { AllPlanInfo, ParticipantEvent } from "../models/ParticipantEvent";
import { ScheduledDistributionConfig } from "../models/ScheduledDistributionProvision";
import { get as getEnrollment } from "../repositories/enrollment";

export async function getOpenEvents(
  empId: number,
  planId: number,
  openOnly: boolean = true
): Promise<ParticipantEvent[]> {
  return getEnrollment(
    `/events/plan/${planId}/emp/${empId}/participantEvents?openOnly=${openOnly}`
  );
}

export async function getNqAccounts(
  enterpriseId: string,
  includeAllAccounts = true
): Promise<NqAccount[]> {
  return getEnrollment(
    `/nqAccount/enterprise/${enterpriseId}?includeAllAccounts=${includeAllAccounts}`
  );
}

export async function getNqAccount(
  enterpriseId: string,
  planId: number
): Promise<NqAccount> {
  return getEnrollment(`/nqAccount/enterprise/${enterpriseId}/plan/${planId}`);
}

export async function getAllPlanInfo(
  eventId: number,
  empId: number
): Promise<AllPlanInfo> {
  return getEnrollment(`/planinfo/event/${eventId}/emp/${empId}`);
}

export async function getMessages(
  eventId: number,
  empId: number
): Promise<AllPlanInfo> {
  return getEnrollment(`/planinfo/event/${eventId}/emp/${empId}/messages`);
}

export async function getScheduledDistributionConfig(
  planId: number,
  accountTypeCode: number
): Promise<ScheduledDistributionConfig> {
  return getEnrollment(
    `/plan/${planId}/accountType/${accountTypeCode}/scheduledDistribution/latest`
  );
}

export async function getMarketClosedDates(): Promise<string[]> {
  return getEnrollment(`/distributions/scheduled/marketClosedDates`);
}
