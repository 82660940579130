import React from "react";
import Card from "../../../components/card/card";
import CardContent from "../../../components/card/card-content";
import CardFooter from "../../../components/card/card-footer";
import CardHeader from "../../../components/card/card-header";
import classnames from "classnames";

type Props = {
  url: string;
  disableNewFutureContribution: boolean;
  futureContributionsLimitText: string;
  allowPendingTransactionChanges: boolean;
};

function FutureInvestmentsCard(props: Props) {
  const {
    url,
    disableNewFutureContribution,
    futureContributionsLimitText,
    allowPendingTransactionChanges,
  } = props;
  const buttonClasses = classnames("btn btn-primary", {
    disabled: disableNewFutureContribution && !allowPendingTransactionChanges,
  });

  return (
    <Card fillHeight>
      <CardHeader>
        Choose Where to Invest Your <strong>Future</strong> Plan Contributions
      </CardHeader>
      <CardContent>
        <div className="fa-stack fa-3x" aria-hidden="true">
          <i className="fa fa-circle fa-stack-2x text-midnight" />
          <i
            className="fa fa-pie-chart fa-stack-1x fa-inverse"
            style={{ marginLeft: 2 }}
          />
        </div>
        <p className="util-margin-vert-30">
          Change the way future contributions are allocated.
        </p>
      </CardContent>
      <CardFooter>
        <a
          data-testid="invest-future-contributions-button"
          id="invest-contributions-button"
          className={buttonClasses}
          href={url}
        >
          Invest Future Contributions
        </a>
        {getDisabledText(disableNewFutureContribution)}
        {getPendingText(
          disableNewFutureContribution,
          allowPendingTransactionChanges
        )}
        {getLimitText(futureContributionsLimitText)}
      </CardFooter>
    </Card>
  );
}

function getDisabledText(disableNewFutureContribution: boolean) {
  if (disableNewFutureContribution) {
    return (
      <div className="info-text">
        <span>You have reached your contribution limit for this plan.</span>
      </div>
    );
  }
}

function getPendingText(
  disableNewFutureContribution: boolean,
  allowPendingTransactionChanges: boolean
) {
  if (disableNewFutureContribution && allowPendingTransactionChanges) {
    return (
      <div className="info-text">
        <span>You may still cancel a pending transaction.</span>
      </div>
    );
  }
}

function getLimitText(futureContributionsLimitText: string) {
  if (futureContributionsLimitText) {
    return (
      <div className="info-text">
        <span>{futureContributionsLimitText}</span>
      </div>
    );
  }
}

export default FutureInvestmentsCard;
