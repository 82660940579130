import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import React from "react";
import InfoModal from "../../../components/modal/info-modal";
import Balance from "../../../components/numbers/balance";
import { Skeleton } from "../../../components/skeleton/skeleton";
import { AccountWithUnscheduledDistributions } from "../../../models/account";
import { ScheduledDistributionEvent } from "../../../models/distributions/scheduled-distribution-event";
import { ScheduledDistributionElection } from "../../../models/distributions/ScheduledDistributionElection";
import { InvestmentV2 } from "../../../models/investment-v2";
import { useInvestments } from "../../../selectors/investments";
import { getDateStringWithoutTimezoneFromDashDate } from "../../../util/date-util";
import {
  aggregateByInvestmentId,
  filterEmptyInvestments,
  flatten,
} from "../../../util/investment-context-util";
import {
  calculateTotalEndBalanceV2,
  calculateTotalVestedBalanceV2,
} from "../../../util/investments";
import { buildDistributionMethod } from "../distribution-scheduled/scheduled-distribution-util";
import PendingDistributionsWrapper from "./pending-distributions/pending-distributions-wrapper";
import { accountsStatusSelector } from "../../../selectors/status";

export interface Props {
  account: AccountWithUnscheduledDistributions;
  firstScheduledDistribution: ScheduledDistributionEvent;
  firstScheduledDistributionElection: ScheduledDistributionElection;
}

AccountDistributionsSummary.defaultProps = {
  firstScheduledDistribution: {},
  firstScheduledDistributionElection: {},
};

export default function AccountDistributionsSummary(props: Props) {
  const { investments } = useInvestments();
  const {
    account,
    firstScheduledDistribution,
    firstScheduledDistributionElection,
  } = props;
  const filteredInvestments: InvestmentV2[] = filterInvestmentsByAccountId(
    investments,
    account.id
  );
  const { payoutStartDate } = firstScheduledDistribution;

  const distributionMethod = buildDistributionMethod(
    firstScheduledDistribution
  );

  const distributionDate = payoutStartDate;

  const investmentsWithBalance: InvestmentV2[] =
    filterEmptyInvestments(filteredInvestments);
  const aggregatedInvestments: InvestmentV2[] = flatten(
    aggregateByInvestmentId(investmentsWithBalance)
  );
  const totalBalance: number = calculateTotalEndBalanceV2(
    aggregatedInvestments
  );
  const totalVestedBalance: number = calculateTotalVestedBalanceV2(
    aggregatedInvestments
  );
  const showComment = _get(firstScheduledDistributionElection, "showComment");
  const comments = _get(firstScheduledDistributionElection, "comments");

  return (
    <>
      <div className="col-xs-12 col-sm-6 col-md-2">
        <b>Current Balance</b>
        <div className="account-header-stacked">
          <Balance value={totalBalance} showDollar />
        </div>
      </div>
      <div className="col-xs-12 col-sm-6 col-md-2">
        <b>Vested Balance</b>
        <div className="account-header-stacked">
          <Balance value={totalVestedBalance} showDollar />
        </div>
      </div>
      <div className="col-xs-12 col-sm-6 col-md-2">
        <b>Scheduled Payout Date</b>
        <div className="account-header-stacked">
          {renderPayoutStartDate(firstScheduledDistribution, distributionDate)}
        </div>
      </div>
      <div className="col-xs-12 col-sm-6 col-md-2">
        <b>Scheduled Payout Method</b>
        <div className="account-header-stacked">
          {
            <Skeleton selectors={[]} height={24} count={1}>
              <InfoModal
                buttonLabel={"Payout Method"}
                characterLimit={50}
                info={distributionMethod}
              />
            </Skeleton>
          }
        </div>
      </div>
      <div className="col-xs-12 col-sm-6 col-md-1">
        <div className="account-header-stacked">
          {showComment ? (
            <div>
              <Skeleton
                selectors={[accountsStatusSelector]}
                height={24}
                count={1}
              >
                <InfoModal
                  buttonLabel={"Additional Info"}
                  characterLimit={20}
                  info={comments}
                />
              </Skeleton>
              <br />
              <br />
            </div>
          ) : (
            ""
          )}
          <PendingDistributionsWrapper account={account} />
        </div>
      </div>
    </>
  );

  function filterInvestmentsByAccountId(
    investments: InvestmentV2[],
    id: number
  ) {
    return investments.filter(({ accountId }) => accountId === id);
  }

  function renderPayoutStartDate(
    scheduledDistribution: ScheduledDistributionEvent,
    payoutStartDate: string
  ) {
    if (_isEmpty(scheduledDistribution)) {
      return "N/A";
    }

    return getDateStringWithoutTimezoneFromDashDate(payoutStartDate, true);
  }
}
