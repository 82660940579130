import React, { useEffect, useState } from "react";
import AccountDropdown from "../../../components/account-dropdown/account-dropdown";
import { Page, PageHeader, PageSubheader } from "../../../components/page";
import { ManageInvestments } from "../../../models/manage-investments";
import {
  accountsStatusSelector,
  allocationsStatus,
  sessionStatusSelector,
  useStatuses,
} from "../../../selectors/status";
import { getManageInvestments } from "../../../services/investment-data";
import { useSelector } from "../../../store/store";
import { formatLinkForRole } from "../../../util/nq-participant-link-builder";
import { InvestmentAllocationTableWrapper } from "./investment-allocation-table-wrapper";
import "./investment-allocations.scss";
import { hideFutureInvestmentsSelector } from "../../../selectors/plan-info";

export default function InvestmentAllocationPage() {
  const { planId, empId, role } = useSelector((state) => state.session);
  const { isLoadingStatus } = useStatuses([
    accountsStatusSelector,
    allocationsStatus,
    sessionStatusSelector,
  ]);
  const hideFutureInvestments = useSelector(hideFutureInvestmentsSelector);
  const [hasManageInvestmentsError, setHasManageInvestmentsError] =
    useState<boolean>(false);
  const [isLoadingManageInvestments, setIsLoadingManageInvestments] =
    useState<boolean>(true);
  const [manageInvestments, setManageInvestments] = useState<
    ManageInvestments | undefined
  >();
  useEffect(() => {
    fetchManageInvestmentsData();

    async function fetchManageInvestmentsData() {
      try {
        const data: ManageInvestments = await getManageInvestments(
          planId,
          empId,
          role
        );
        setManageInvestments(data);
        setIsLoadingManageInvestments(false);
      } catch (e) {
        setHasManageInvestmentsError(true);
      }
    }
  }, [planId, empId, role]);

  let changeInvestmentAllocationsLink: string = "";
  let showFutureContribution;
  if (manageInvestments) {
    showFutureContribution = manageInvestments.showFutureContribution;
    changeInvestmentAllocationsLink = formatLinkForRole(
      manageInvestments.futureContributionLink,
      role
    );
  }
  return (
    <Page
      printable
      className="investment-allocations"
      name="investment-allocations"
      loading={isLoadingStatus || isLoadingManageInvestments}
    >
      <PageHeader name="investment-allocations">
        Investment allocations
      </PageHeader>
      <PageSubheader>
        The investment allocations shown below will be used for future
        contributions, if applicable, and/or any scheduled rebalances you
        establish for this account.&nbsp;
        {showFutureContribution && !hideFutureInvestments && (
          <a
            href={changeInvestmentAllocationsLink}
            data-testid="changeInvestmentAllocationLink"
          >
            Change Your Investment Allocations for Future Contributions.
          </a>
        )}
      </PageSubheader>
      <AccountDropdown isSingleSelect hidePaidAccounts />
      <InvestmentAllocationTableWrapper />
      <p>
        The amounts listed on the website represent a measure of your benefits
        under a nonqualified benefit agreement between you and your plan
        sponsor. It is not a representation that your plan sponsor holds any
        assets for paying these benefits.
      </p>
      <p>
        At any time, the measure of your benefits is determined by reference to
        the performance of available investments as described in your Plan
        materials ("Reference Investments"). Throughout this site you will see
        terms such as Investment Option or Investment Advisor. These are
        indicators of the Reference Investments made available to measure your
        benefit(s) in your Nonqualified Plan.
      </p>
    </Page>
  );
}
