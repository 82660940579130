import { useEffect } from "react";
import { setSelectedAccounts } from "../reducers/accounts";
import { useAppDispatch } from "../store/store";

export function useSingleAccount(accountId?: number) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (accountId) {
      dispatch(setSelectedAccounts([Number(accountId)]));
    }
  }, [accountId, dispatch]);
}
