import _keyBy from "lodash/keyBy";
import _mapValues from "lodash/mapValues";
import { AccountAllocation } from "../../../models/account-allocation";
import { MoneyType } from "../../../models/moneytype";

export function mapAllocationsToAccount(
  moneyTypes: MoneyType[],
  accountAllocations: AccountAllocation[]
) {
  return accountAllocations.map((accountAllocation) => {
    const { accountName, allocationsByMoneyType, id } = accountAllocation;
    const allocations = _mapValues(
      _keyBy(allocationsByMoneyType, "moneyTypeId"),
      "percentage"
    );
    return { accountName, id, allocations };
  });
}
