import React from "react";
import Button from "../../buttons/button";
import classNames from "classnames";

export interface Props {
  handleStepChange: (currentPosition: number) => void;
  currentPosition: number;
}

export default function RedeferralNavigation(props: Props) {
  const { handleStepChange, currentPosition } = props;

  const handleForwardClick = () => {
    handleStepChange(currentPosition + 1);
  };

  const handleBackwardClick = () => {
    handleStepChange(currentPosition - 1);
  };

  function buildNavButton(
    action: string,
    handleClick: () => void,
    primaryButton: boolean,
    pullRight: boolean,
    pullLeft: boolean
  ) {
    const buttonClass = classNames({
      "btn-primary": primaryButton,
      "pull-right": pullRight,
      "pull-left": pullLeft,
    });
    const divClass = classNames({
      "pull-right": pullRight,
      "pull-left": pullLeft,
    });
    return (
      <div className={`col-md-2 ${divClass}`}>
        <Button
          data-testid={`${action}-button`}
          className={buttonClass}
          onClick={handleClick}
        >
          {action}
        </Button>
      </div>
    );
  }

  return (
    <div className="row">
      {currentPosition === 1 &&
        buildNavButton("Go back", handleBackwardClick, false, false, true)}
      {currentPosition === 0 &&
        buildNavButton("Continue", handleForwardClick, true, true, false)}
      {currentPosition === 1 &&
        buildNavButton("Submit", handleForwardClick, true, true, false)}
    </div>
  );
}
