import * as React from "react";

export interface Props {
  name: string;
  children: React.ReactNode;
}

export function PageHeader(props: Props) {
  const { name } = props;
  return <h1 data-testid={`${name}-page-header`}>{props.children}</h1>;
}
