import BSModal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/cjs/Button";
import React from "react";

type ConfirmationModalProps = {
  showModal: boolean;
};

export function BeneficiaryConfirmationModal(props: ConfirmationModalProps) {
  const { showModal } = props;
  return (
    <BSModal
      show={showModal}
      size={"lg"}
      backdropClassName="modal-backdrop"
      animation={false}
      backdrop="static"
      centered
      className="beneficiaries-confirm-modal"
    >
      <BSModal.Header>
        <BSModal.Title as={"h2"}>Beneficiary update</BSModal.Title>
      </BSModal.Header>
      <BSModal.Body>
        Congratulations! You have successfully updated your beneficiaries.
      </BSModal.Body>
      <BSModal.Footer>
        <Link to={"/overview/beneficiaries"}>
          <Button className="btn-default">
            Take me back to beneficiaries <i className="fa fa-arrow-right" />
          </Button>
        </Link>
      </BSModal.Footer>
    </BSModal>
  );
}
