import {get} from "../repositories/participant";

export async function getPlanContacts(
    contractId: string
): Promise<PlanContacts> {
    return get(
        `/plan-contacts/contract/${contractId}`
    );
}

export interface PlanContacts {
    planSponsors: PlanContact[] | null;
    financialProfessionals: PlanContact[] | null;
    contactCenter: PlanContact[] | null
}


export interface PlanContact {
    firstName: string,
    lastName: string,
    role: string | null,
    company: string | null,
    address: Address,
    phone: string | null,
    fax: string | null,
    email: string | null,
}


export interface Address {
    street: string | null;
    city: string | null;
    state: string | null;
    zip: string | null;
}
