import React from "react";

function ConditionalDisplayInput(props) {
  const { editable, displayValue, displayId } = props;

  if (editable) {
    return <>{props.children}</>;
  }
  return <span data-testid={displayId}>{displayValue}</span>;
}

export default ConditionalDisplayInput;
