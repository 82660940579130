import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { SubmitHandler } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import Icon from "../../../components/icon/icon";
import {
  Beneficiary,
  BeneficiaryInfo,
  BeneficiaryType,
} from "../../../models/beneficiaries/beneficiary";
import {
  BeneficiaryState,
  updateBeneficiary,
} from "../../../reducers/beneficiary-reducer";
import { useAppDispatch, useSelector } from "../../../store/store";
import BeneficiaryDeleteSection from "../beneficiary/beneficiary-delete-section";
import BeneficiaryForm from "./beneficiary-form";
import "./beneficiary-modal.scss";

export enum ModalAction {
  ADD = "ADD",
  VIEW = "VIEW",
}

type Props = {
  beneficiaryType: BeneficiaryType;
  modalAction: ModalAction;
  beneficiary?: Beneficiary;
  testid?: string;
};

export default function BeneficiaryModal(props: Props) {
  const { beneficiaryType, modalAction, beneficiary, testid: testId } = props;
  const { editing, canEditBeneficiaries }: BeneficiaryState = useSelector(
    (state) => state.beneficiaries
  );
  const isAdvisorOrEmployer = useSelector(
    (state) => state.session.isAdvisorOrEmployer
  );
  const [beneType, setBeneType] = useState(beneficiaryType);
  const [show, setShow] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const dispatch = useAppDispatch();
  const handleShow = () => {
    setShow(true);
  };

  const onSubmit: SubmitHandler<BeneficiaryInfo> = (beneficiaryInfo) => {
    beneficiaryInfo.type = beneType;
    if (!beneficiary) {
      const newBeneficiary = {
        guid: uuidv4(),
        beneficiaryInfo,
        allocationAmount: 0,
      };
      dispatch(updateBeneficiary(newBeneficiary));
    } else {
      dispatch(
        updateBeneficiary({ ...beneficiary, beneficiaryInfo: beneficiaryInfo })
      );
    }
    setShow(false);
  };

  const handleClose = () => {
    setShow(false);
    setShowDeleteSection(false);
  };

  const hideDeleteSection = () => {
    setShowDeleteSection(false);
  };

  const viewingBeneficiary: boolean = modalAction === ModalAction.VIEW;
  const addingBeneficiary: boolean = modalAction === ModalAction.ADD;

  const viewBeneficiaryButtonClasses =
    "btn bene-btn btn-link left-align util-padding-left-0 button-text-wrap";
  const addBeneficiaryButtonClasses =
    "btn btn-sm bene-btn btn-default whitespace-no-wrap util-margin-top-5";
  const buttonClasses = viewingBeneficiary
    ? viewBeneficiaryButtonClasses
    : addBeneficiaryButtonClasses;

  const modalButtonText = beneficiary?.beneficiaryInfo
    ? beneficiary.beneficiaryInfo.name.toUpperCase()
    : " Add";

  const title = setTitle();

  return (
    <>
      {addingBeneficiary && editing && (
        <Button
          data-testid="add-button"
          variant={viewingBeneficiary ? "secondary" : "primary"}
          onClick={handleShow}
          className={buttonClasses}
        >
          {viewingBeneficiary ? <Icon name="pencil" /> : <Icon name="plus" />}
          {modalButtonText}
          &nbsp;
        </Button>
      )}
      {viewingBeneficiary && !editing && (
        <div className={"button-text-wrap"}>{modalButtonText}</div>
      )}
      {viewingBeneficiary && editing && (
        <Button
          data-testid={viewingBeneficiary && testId ? testId : "add-button"}
          variant={viewingBeneficiary ? "secondary" : "primary"}
          onClick={handleShow}
          className={buttonClasses}
        >
          {viewingBeneficiary ? <Icon name="pencil" /> : <Icon name="plus" />}
          {modalButtonText}
          &nbsp;
        </Button>
      )}
      <Modal
        size="xl"
        backdropClassName="modal-backdrop"
        show={show}
        onHide={handleClose}
        animation={false}
        backdrop="static"
        className="beneficiaries-modal"
      >
        <Modal.Header closeButton className={"util-padding-bottom-0"}>
          <h2>{title}</h2>
        </Modal.Header>
        <Modal.Body>
          <BeneficiaryForm
            beneType={beneType}
            setBeneType={setBeneType}
            onSubmit={onSubmit}
            action={modalAction}
            beneficiaryInfo={beneficiary?.beneficiaryInfo}
          />
        </Modal.Body>
        <Modal.Footer>
          <div className={"row"}>
            <div className={"col-md-12 action-buttons"}>
              {viewingBeneficiary && canEditBeneficiaries && <DeleteButton />}
              {renderActionButtons()}
            </div>
          </div>
          <div className={"row"}>
            <div className={"col-xs-12 col-md-6"}></div>
          </div>
          {showDeleteSection && (
            <BeneficiaryDeleteSection
              guid={beneficiary?.guid}
              handleClose={handleClose}
              closeDeleteSection={hideDeleteSection}
            />
          )}
        </Modal.Footer>
      </Modal>
    </>
  );

  function renderActionButtons() {
    if (addingBeneficiary) {
      return (
        <>
          <div></div>
          <div className={"pull-right"}>
            <CloseButton />
            <SaveButton />
          </div>
        </>
      );
    }

    if (canEditBeneficiaries) {
      return (
        <>
          <div className={"pull-right"}>
            <CloseButton />
            <SaveButton />
          </div>
        </>
      );
    }

    return <CloseButton />;
  }

  function SaveButton() {
    return (
      <Button
        disabled={showDeleteSection || isAdvisorOrEmployer}
        variant="primary"
        type="submit"
        form="beneficiary-form"
      >
        Save changes
      </Button>
    );
  }

  function CloseButton() {
    return (
      <Button
        disabled={showDeleteSection}
        variant="secondary"
        onClick={handleClose}
      >
        Close
      </Button>
    );
  }

  function DeleteButton() {
    return (
      <button
        onClick={() => setShowDeleteSection(!showDeleteSection)}
        type="button"
        data-gtm="modal-cta"
        className="btn btn-danger pull-left"
        disabled={isAdvisorOrEmployer}
      >
        Delete beneficiary
      </button>
    );
  }

  function setTitle() {
    if (addingBeneficiary) {
      return "Add a Beneficiary";
    }
    if (editing) {
      return "Edit a Beneficiary";
    }
    return `${beneType} Beneficiary`;
  }
}
