import { ForecastDistributionsResource } from "../../../models/distributions/forecast-distribution";
import LoadingSpinner from "../../../components/loading-spinner/loading-spinner";
import { Modal } from "../../../components/modal/modal";
import { currencyFormatter } from "../../../util/currency";
import React from "react";
import { TabChangeFunction } from "../distribution-summary/distribution-details";

type Props = {
  forecastDistributions: ForecastDistributionsResource;
  forecastYear: string;
  changeTabs: TabChangeFunction;
  isOnlyLoadingStatus: boolean;
};

export function DistributionsNotIncludedTable(props: Props) {
  const { forecastDistributions, forecastYear, isOnlyLoadingStatus } = props;

  return (
    <>
      <h2>Distributions not included for {forecastYear}</h2>
      <p>
        Distribution options were not found for certain accounts. As such, these
        are not included in the forecast. Please see your{" "}
        <a
          onClick={(event) => {
            props.changeTabs(event, "distribution-summary-tab");
          }}
        >
          Distribution Summary
        </a>{" "}
        for additional information.
      </p>
      <table className="table">
        <tbody>
          {isOnlyLoadingStatus ? (
            <LoadingSpinner />
          ) : (
            <>
              <tr>
                <td className="text-bold">Account</td>
                <td className="text-bold">Current Amount</td>
              </tr>
              {forecastDistributions.forecastExcludedAccounts.length > 0 ? (
                forecastDistributions.forecastExcludedAccounts.map((x) => {
                  return (
                    <tr>
                      <td>
                        <div className="dashboard-account-modal hidden-sm">
                          {x.accountName}
                          <Modal title="Why could this account not be forecasted?">
                            <div id="info" className="info">
                              <p>{x.reasonForExclusion}</p>
                            </div>
                          </Modal>
                          <span
                            className={"account-number-display show text-muted"}
                          >
                            Account number: {x.accountId}
                          </span>
                        </div>
                      </td>
                      <td>{currencyFormatter(x.currentAccountBalance)}</td>
                    </tr>
                  );
                })
              ) : (
                <>
                  <p>No Distributions for this Selection</p>
                </>
              )}
            </>
          )}
        </tbody>
      </table>
    </>
  );
}
