import { combineReducers } from "@reduxjs/toolkit";
import accountBalances from "./account-balances-by-date";
import accounts from "./accounts";
import allocations from "./allocations";
import beneficiaries from "./beneficiary-reducer";
import contributions from "./contributions/contributions";
import dates from "./dates";
import distributions from "./distributions/distributions";
import investments from "./investments";
import featureFlags from "./feature-flags-reducer";
import messages from "./messages/messages";
import participant from "./participant";
import plan from "./plan";
import privileges from "./privileges/privileges";
import projectedRetirementDate from "./projected-retirement-date";
import redeferrals from "./redeferral/redeferral";
import session from "./session";
import context from "./context";
import singleDayInvestments from "./single-day-investments";
import financialWellnessArticles from "./financial-wellness-articles";
import planStatements from "./planstatements";
import downloadPdf from "./download-pdf";
import planInfoAndSummary from "./plan-info-and-summary";
import viewDocument from "./view-plan-info-summary-document";
import enrollmentTools from "./enrollment-tools";
import viewEnrollmentToolsDocument from "./view-enrollment-tools-document";
import estimatedBenefitAccountInfo from "./estimated-benefit-account-info";
import estimatedCalculation from "./estimated-projection-request";
import planningCenterTools from "./planning-center-overview";
import viewPlanningCenterDocument from "./view-planning-center-docuement";
import streamOfPaymentPending from "./stream-of-payment-pending";
import overviewBenefit from "./overview-benefit";
import balancesBySource from "./balances-by-source";
import nqAccountDetailsSlice from "./nq-account-single";
import nqMultipleAccountDetails from "./nq-accounts-multiple";
import accountsAtAGlance from "./accounts-at-a-glance/accounts-at-a-glance";
import accountRedeferralStatuses from "./redeferral/redeferral-status";
import definedBenefitAbout from "./defined-benefit-about";
import trsStatements from "./trs-statements";
import statementBalance from "./statement-balance";
import planContacts from "./plan-contacts";

export const rootReducer = combineReducers({
  accountBalances,
  accounts,
  allocations,
  beneficiaries,
  context,
  contributions,
  dates,
  distributions,
  investments,
  messages,
  nqAccountDetailsSlice,
  nqMultipleAccountDetails,
  participant,
  plan,
  privileges,
  projectedRetirementDate,
  redeferrals,
  session,
  singleDayInvestments,
  financialWellnessArticles,
  planStatements,
  trsStatements,
  downloadPdf,
  planInfoAndSummary,
  viewDocument,
  enrollmentTools,
  viewEnrollmentToolsDocument,
  estimatedBenefitAccountInfo,
  estimatedCalculation,
  planningCenterTools,
  viewPlanningCenterDocument,
  streamOfPaymentPending,
  overviewBenefit,
  balancesBySource,
  definedBenefitAbout,
  featureFlags,
  accountsAtAGlance,
  accountRedeferralStatuses,
  statementBalance,
  planContacts
});
