import React, { useEffect, useState } from "react";
import OpenEventRect from "../../components/enroll-events/openEventRect";
import { getOpenEvents } from "../../services/enrollment";
import { useAppDispatch, useSelector } from "../../store/store";
import { AsyncStatus } from "../../models/async-status";
import "./EnrollmentCenterRevamp.scss";
import EnrollmentCard from "../../components/open-enrollment-events/enrollmentCard";
import { ParticipantEvent } from "../../models/ParticipantEvent";
import { Page } from "../../components/page";
import EnrollmentHelpCard from "../../components/enrollment-help/enrollment-help-card";
import EnrollmentContacts from "../../components/enrollment-contacts/enrollment-contacts";
import { ToolLink } from "../../models/plan-info-and-summary/tool-link";
import { buildLinkForEstimatedBenefitCalculator } from "../../util/nq-participant-link-builder";
import { fetchEnrollmentTools } from "../../reducers/enrollment-tools/enrollment-tools";
import { enrollmentToolsStatus, useStatuses } from "../../selectors/status";
import InfoCircle from "../../components/info-circle/InfoCircle";
import EnrollmentHistory from "../../components/enrollment-history/enrollment-history";
import {
  shouldHideEducationHubSelector,
  shouldHidePlanInfoAndFormsSelector,
  shouldHideToolsSelector,
} from "../../selectors/plan-info";

const EnrollmentCenterRevamp = () => {
  const { status, empId, planId, role } = useSelector((state) => state.session);
  const [openEvents, setOpenEvents] = useState<ParticipantEvent[]>([]);
  const [allEvents, setAllEvents] = useState<ParticipantEvent[]>([]);
  const [closedEvents, setClosedEvents] = useState<ParticipantEvent[]>([]);
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoadingStatus] = useState(true);
  const [isLoadingData, setIsLoading] = useState(true);
  const showEducationHubEnabled = useSelector(shouldHideEducationHubSelector);
  const showToolsEnabled = useSelector(shouldHideToolsSelector);
  const showPlanInfoAndFormsEnabled = useSelector(
    shouldHidePlanInfoAndFormsSelector
  );
  const { enrollmentTools } = useSelector((state) => state.enrollmentTools);
  const [completedEventsCount, setCompletedEventsCount] = useState(0);
  const { viewEnrollmentToolsDocument } = useSelector(
    (state) => state.viewEnrollmentToolsDocument
  );
  const { isLoadingStatus } = useStatuses([enrollmentToolsStatus]);
  const [messageEvents, setMessageEvents] = useState<ParticipantEvent[]>([]);
  const [currentToolLink, setCurrentToolLink] = useState("");
  const [error, setError] = useState(false);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (
      showPlanInfoAndFormsEnabled != undefined &&
      showEducationHubEnabled != undefined &&
      showToolsEnabled != undefined
    ) {
      setIsLoading(false);
    }
  }, [showPlanInfoAndFormsEnabled, showToolsEnabled, showEducationHubEnabled]);
  useEffect(() => {
    dispatch(fetchEnrollmentTools({ planId, empId, role }));
    if (
      viewEnrollmentToolsDocument &&
      viewEnrollmentToolsDocument.status === AsyncStatus.error
    ) {
      setError(true);
    }
  }, [dispatch, planId, empId, role, viewEnrollmentToolsDocument]);

  const isBenefitCalc = (toolLink: ToolLink) => {
    return toolLink.linkName === "Estimated Benefit Calculator";
  };

  const nonQualifiedPlanner = (toolLink: ToolLink) => {
    return toolLink.linkName === "Nonqualified Deferred Compensation Planner";
  };

  const getUrl = (toolLink: ToolLink) => {
    if (isBenefitCalc(toolLink)) {
      return buildLinkForEstimatedBenefitCalculator(planId, empId, role);
    } else if (nonQualifiedPlanner(toolLink)) {
      return "https://nq.individuals.principal.com/nq-compensation-planner/#/";
    } else if (!!toolLink.outputLink) {
      return toolLink.urlLink;
    }
    return "#";
  };
  let tutorial: string;

  {
    enrollmentTools.toolLinks.map((toolLink) => (tutorial = getUrl(toolLink)));
  }

  useEffect(() => {
    if (status === AsyncStatus.done) {
      fetchOpenEvents();
    }

    async function fetchOpenEvents() {
      try {
        const data = await getOpenEvents(empId, planId, false);
        setAllEvents(data);

        setOpenEvents(
          data.filter((event) => !event.eventConfiguration.isEventClosed)
        );
        setClosedEvents(
          data.filter((event) => event.eventConfiguration.isEventClosed)
        );
      } catch (error) {
        setHasError(true);
      } finally {
        setIsLoadingStatus(false);
      }
    }
  }, [status, empId, planId]);

  useEffect(() => {
    const completedCount = openEvents.filter(
      (e) => e.electionSummary.isParticipantEnrollmentCompleted
    ).length;
    setCompletedEventsCount(completedCount);
  }, [openEvents]);

  if (isLoading) {
    return <div></div>;
  }

  if (openEvents === null) {
    return <div></div>;
  }

  const hasHistory = allEvents.length > 0;
  if (isLoadingData) {
    return (
      <h1 id={"loading"}>
        <i className="fa fa-spinner fa-spin" /> Loading...{" "}
      </h1>
    );
  } else {
    return (
      <div className="enrollment-page">
        <Page name={"enrollment-page"} loading={isLoading}>
          <div style={{ fontSize: "200%" }}>Enrollment center overview</div>
          {openEvents.length === 0 && allEvents.length !== 0 ? (
            <div style={{ flex: 3 }}>
              <div style={{ display: "flex" }}>
                <div style={{ flex: 3 }}>
                  <div className="open-events-header">
                    <h3
                      className="pds-card-title pds-typography-truncate"
                      style={{ color: "white", fontSize: "160%" }}
                    >
                      Open events
                    </h3>
                    <div style={{ marginTop: "1%", textAlign: "center" }}>
                      <InfoCircle />
                    </div>
                  </div>
                  <OpenEventRect />
                  <div style={{ paddingTop: "4%", borderRadius: "10px" }}>
                    <EnrollmentHistory
                      events={closedEvents}
                      openEvents={closedEvents}
                      allEvents={allEvents}
                    />
                  </div>
                </div>
                <div style={{ flex: 1.1, marginLeft: "20px" }}>
                  <EnrollmentHelpCard
                    planId={allEvents[0].eventConfiguration.planId}
                    empId={allEvents[0].empId}
                    planType={allEvents[0].eventConfiguration.planType}
                    tutorialLink={enrollmentTools.toolLinks.map(
                      (toolLink) => (tutorial = getUrl(toolLink))
                    )}
                  />
                  <EnrollmentContacts
                    empId={allEvents[0].empId}
                    planId={allEvents[0].eventConfiguration.planId}
                  />
                </div>
              </div>
            </div>
          ) : (
            <>
              <div style={{ display: "flex" }}>
                <div style={{ flex: 3, marginRight: "10px" }}>
                  <div className="open-events-header">
                    <h3
                      className="pds-card-title pds-typography-truncate"
                      style={{ color: "white", fontSize: "160%" }}
                    >
                      Open events
                    </h3>
                    <div style={{ marginTop: "1%", textAlign: "center" }}>
                      <InfoCircle />
                    </div>
                  </div>
                  {openEvents.map((event) => {
                    if (
                      !event.electionSummary.isParticipantEnrollmentCompleted
                    ) {
                      return (
                        <EnrollmentCard
                          date={event.eventConfiguration.effectiveDateYear}
                          title={event.eventConfiguration.companyName}
                          endDate={event.eventConfiguration.endDate}
                          eventId={event.eventId}
                          empId={event.empId}
                          event={event}
                          openEvents={openEvents}
                          completedEvents={completedEventsCount}
                        />
                      );
                    }
                    return null;
                  })}
                  {openEvents.every(
                    (e) => e.electionSummary.isParticipantEnrollmentCompleted
                  ) && <OpenEventRect />}
                  <div style={{ paddingTop: "5%", borderRadius: "10px" }}>
                    <EnrollmentHistory
                      events={closedEvents}
                      openEvents={openEvents}
                      allEvents={allEvents}
                    />
                  </div>
                </div>
                {hasHistory ? (
                  <div style={{ flex: 1.1, marginLeft: "20px" }}>
                    <EnrollmentHelpCard
                      planId={openEvents[0].eventConfiguration.planId}
                      empId={openEvents[0].empId}
                      planType={openEvents[0].eventConfiguration.planType}
                      tutorialLink={enrollmentTools.toolLinks.map(
                        (toolLink) => (tutorial = getUrl(toolLink))
                      )}
                    />
                    <EnrollmentContacts
                      empId={openEvents[0].empId}
                      planId={openEvents[0].eventConfiguration.planId}
                    />
                  </div>
                ) : (
                  <div style={{ flex: 1.1, marginLeft: "20px" }}></div>
                )}
              </div>
            </>
          )}
        </Page>
      </div>
    );
  }
};
export default EnrollmentCenterRevamp;
