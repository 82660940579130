import React from "react";

import {
  Panel,
  PanelBody,
  PanelHeading,
} from "../../../components/panel/panel";
import StatementsOverviewTable from "./statements-overview-table";

function StatementsOverview() {
  return (
    <div className={"col-md-12"}>
      <Panel>
        <PanelHeading>Overview</PanelHeading>
        <PanelBody>
          <StatementsOverviewTable />
        </PanelBody>
      </Panel>
    </div>
  );
}

export default StatementsOverview;
