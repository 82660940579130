import { useSelector } from "../../../../store/store";
import React from "react";
import { OnlineBeneficiaryPlan } from "../../../../models/beneficiaries/beneficiary";

type PlanListProps = {
  plans: OnlineBeneficiaryPlan[];
  selectedOtherPlans: boolean;
};

export function PlanList(props: PlanListProps) {
  const { info: currentPlanInfo } = useSelector((state) => state.plan);
  const { plans, selectedOtherPlans } = props;
  return (
    <>
      <p>Your designations will be applied to the following plan(s):</p>
      <p>
        <strong>{currentPlanInfo.planName}</strong>
      </p>
      {selectedOtherPlans && (
        <>
          <p>Additional plans selected:</p>
          <ul>
            {plans.map((plan) => {
              const { id, name } = plan;
              return (
                <li data-testid={`plan-${id}`} key={id}>
                  {name}
                </li>
              );
            })}
          </ul>
        </>
      )}
    </>
  );
}
