import React from "react";
import { asPercent } from "../../../../util/calculate";
import "./asset-class-chart.scss";

function ChartLabel(props) {
  const { assetClass, index } = props;
  return (
    <div
      key={index}
      data-testid={`chart-color-${assetClass.hexValue}`}
      className="label-row"
    >
      <div
        className="label-box"
        style={{ backgroundColor: assetClass.hexValue }}
      />
      <div>
        {assetClass.assetClass}
        <span className="sr-only">, {asPercent(assetClass.balanceRatio)}%</span>
      </div>
    </div>
  );
}

export default ChartLabel;
