import React, { useState } from "react";
import {
  ForecastDistribution,
  ForecastDistributionRolledUp,
  ForecastDistributionsResource,
} from "../../../models/distributions/forecast-distribution";
import { DetailedDistributionsOccurringTable } from "./detailed-distributions-occurring-table";
import { currencyFormatter } from "../../../util/currency";
import { formatDateWithoutTimeZone, parseDate } from "../../../util/date-util";

type Props = {
  forecastDistributionRolledUp: ForecastDistributionRolledUp;
  forecastDistributions: ForecastDistributionsResource;
  forecastYear: string;
  rateOfReturn: number;
};

export function EstimatedDistributionsOccurringTable(props: Props) {
  const {
    forecastDistributions,
    forecastYear,
    forecastDistributionRolledUp,
    rateOfReturn,
  } = props;
  const [isExpanded, setIsExpanded] = useState(false);

  const distributions: ForecastDistribution[] = [];

  function getDistributionsOccurringInForecastYear(
    accountId: number
  ): ForecastDistribution[] {
    forecastDistributions.forecastDistributions.forEach((distribution) => {
      const parsedDate = formatDateWithoutTimeZone(
        parseDate(distribution.payoutDate),
        "yyyy"
      );
      if (parsedDate === forecastYear && accountId === distribution.accountId) {
        distributions.push(distribution);
      }
    });
    return distributions;
  }

  function toggleExpanded() {
    setIsExpanded(!isExpanded);
  }

  const expandIcon = isExpanded ? (
    <i
      className={"fa fa-chevron-down expand-icon"}
      onClick={toggleExpanded}
      style={{ color: "#035fa4", cursor: "pointer" }}
    />
  ) : (
    <i
      className={"fa fa-chevron-right expand-icon"}
      onClick={toggleExpanded}
      style={{ color: "#035fa4", cursor: "pointer" }}
    />
  );

  return (
    <>
      <tr>
        <td>
          <span className="icon-cell year-cell">
            {expandIcon}
            {forecastDistributionRolledUp.accountName}
          </span>
          <span className={"account-number-display show text-muted"}>
            Account number: {forecastDistributionRolledUp.accountId}
          </span>
          <br />
        </td>
        <td>
          {currencyFormatter(
            forecastDistributionRolledUp.currentAccountBalance
          )}
        </td>
        <td>
          {currencyFormatter(
            forecastDistributionRolledUp.estimatedDistribution
          )}
        </td>
      </tr>
      <div>
        {isExpanded && (
          <DetailedDistributionsOccurringTable
            distributions={getDistributionsOccurringInForecastYear(
              forecastDistributionRolledUp.accountId
            )}
            rateOfReturn={rateOfReturn}
          />
        )}
      </div>
    </>
  );
}
