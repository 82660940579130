import { html, PropertyValues } from 'lit';
import {
  property,
  query,
  queryAssignedElements,
  state,
} from 'lit/decorators.js';
import { FocusableElement, tabbable } from 'tabbable';
import { pdsCustomElement as customElement } from '../../decorators/pds-custom-element';
import { PdsElement } from '../PdsElement';
import styles from './tab-content.scss?inline';
import { required } from '../../decorators/required';

/**
 * @summary This component provides the content for the tabs
 *
 * @slot default Required: Accepts the content needed to display when the corresponding tab button is selected
 */
@customElement('pds-tab-content', {
  category: 'component',
  type: 'component',
  styles,
})
export class PdsTabContent extends PdsElement {
  /**
   * Id of the button that controls this content
   */
  @required
  @property({ type: String })
  ariaLabelledby: string;

  /**
   * Id of this content
   */
  @required
  @property({ type: String })
  contentId: string;

  /**
   * A boolean indicating if this tab content is open
   */
  @property({ type: Boolean, reflect: true })
  open: boolean = false;

  /**
   * @internal
   */
  @state()
  tabbableElements: FocusableElement[] = [];

  protected firstUpdated(): void {
    this.setAttribute('role', 'tabpanel');
    if (this.ariaLabelledby) {
      this.setAttribute('aria-labelledby', this.ariaLabelledby);
    }
    if (this.contentId) {
      this.setAttribute('id', this.contentId);
    }
  }

  protected updated(changedProperties: PropertyValues): void {
    super.updated(changedProperties);

    if (changedProperties.has('ariaLabelledby')) {
      this.setAttribute('aria-labelledby', this.ariaLabelledby);
    }

    if (changedProperties.has('contentId')) {
      this.setAttribute('id', this.contentId);
    }

    if (changedProperties.has('open')) {
      // Need to wait for tab content to get opened, otherwise all tabbable elements will be inert
      if (this.open) {
        if (this.defaultSlotNodes) {
          // check all slotted nodes for tabbable elements
          this.defaultSlotNodes.forEach((node) => {
            // add tabbable elements to the tabbableElements array
            this.tabbableElements = this.tabbableElements.concat(
              tabbable(node, {
                getShadowRoot: true,
                includeContainer: true,
              }),
            );
          });

          // if there are no tabbable elements, set the container to be tabbable per a11y guidelines
          if (this.tabbableElements.length === 0) {
            this.content.setAttribute('tabindex', '0');
          } else {
            this.content.setAttribute('tabindex', '-1');
          }
        }
      } else {
        // clean out the tabbableElements array
        this.tabbableElements = [];
      }
    }
  }

  /**
   * @internal
   */
  @query('.pds-c-tab-content')
  content: HTMLDivElement;

  /**
   * @internal
   */
  @queryAssignedElements()
  defaultSlotNodes: Element[];

  /**
   * @internal
   */
  get classNames() {
    return {
      'is-active': this.open,
    };
  }

  render() {
    return html`<div class=${this.getClass()} tabindex="0">
      <slot class="${this.classEl('content')}"></slot>
    </div>`;
  }
}
