import { themedefault } from '@principal/design-system-tokens';
import { LitElement, PropertyValueMap } from 'lit';
import { property, state } from 'lit/decorators.js';

export const iconSizes = ['xs', 'sm', 'default', 'lg', 'xl', 'xxl'];

type ArrayValues<T extends readonly unknown[]> = T[number];

export type IconSize = ArrayValues<typeof iconSizes>;

export const validIconColors = [
  themedefault.SemanticBorderIconDefault,
  themedefault.SemanticBorderInvertedDefault,
  themedefault.SemanticBorderDisabled,
  themedefault.SemanticBorderInfo,
  themedefault.SemanticBorderInvertedInfo,
  themedefault.SemanticBorderSuccess,
  themedefault.SemanticBorderInvertedSuccess,
  themedefault.SemanticBorderWarning,
  themedefault.SemanticBorderError,
  themedefault.SemanticBorderInvertedError,
  themedefault.SemanticBorderInteractiveDefault,
  themedefault.SemanticBorderInteractiveStrong,
  themedefault.SemanticBorderInteractiveXstrong,
  themedefault.SemanticBorderInteractiveInvertedStrong,
  themedefault.SemanticBorderInteractiveInvertedXstrong,
] as const;

export type ValidIconColor = ArrayValues<typeof validIconColors>;

/**
 * A base element.
 */
export abstract class PdsIcon extends LitElement {
  /**
   * ICON SIZE
   * xs - 12x12
   * sm - 16x16
   * default - 20x20
   * lg - 24x24
   * xl - 32x32
   * xxl - 40x40
   */
  getSize(size: IconSize): Number {
    switch (size) {
      case 'xs':
        return 12;
      case 'sm':
        return 16;
      case 'lg':
        return 24;
      case 'xl':
        return 32;
      case 'xxl':
        return 40;
      default:
        return 20;
    }
  }

  isValidColor(color: ValidIconColor | string): boolean {
    const isValid = validIconColors.includes(color as ValidIconColor);

    if (!isValid) {
      // eslint-disable-next-line no-console
      console.error(
        'The color you passed in is not a valid icon color. Refer to PDS border tokens for valid icon colors.',
      );
    }

    return isValid;
  }

  @property({ type: String })
  size: IconSize = 'default';

  @property({ type: String })
  color?: ValidIconColor;

  @state()
  icon: string;

  updated(
    changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>,
  ): void {
    // We need to convert changedProperties to an Object
    // because size is always a property of a map and will
    // always return true otherwise
    const changedPropertiesObject = Object.fromEntries(changedProperties);

    if (changedPropertiesObject['size'] || changedPropertiesObject['color']) {
      this.setIconAttributes();
    }
  }

  setIconAttributes() {
    let iconWithCustomAttributes = this.icon
      .replace(/ width="\d*"/, ` width="${this.getSize(this.size).toString()}"`)
      .replace(
        / height="\d*"/,
        ` height="${this.getSize(this.size).toString()}"`,
      )
      .replace(/>/, `display="block"\naria-hidden="true"\n>`);

    if (this.color && this.isValidColor(this.color)) {
      iconWithCustomAttributes = iconWithCustomAttributes.replace(
        / stroke=".*?"/g,
        ` stroke="${this.color}"`,
      );
    }

    return iconWithCustomAttributes;
  }
}
