import React, { useEffect, useState } from "react";
import LoadingSpinner from "../../../components/loading-spinner/loading-spinner";
import TableV2 from "../../../components/table/tableV2";
import { AccountAllocation } from "../../../models/account-allocation";
import { getAccountAllocations } from "../../../services/account-allocations";
import { useSelector } from "../../../store/store";
import { buildMoneyTypeColumn } from "../../statements/panels/investment-allocations";
import { removeMoneyTypesWithNoAllocations } from "../investment-allocations/investment-allocation-table-util";
import "./account-allocation-table.scss";
import { mapAllocationsToAccount } from "./allocation-table-util";
import { Dictionary } from "@reduxjs/toolkit";
import AccountName from "../../../components/account-name/account-name";

export default function AccountAllocationTable() {
  const { planId, empId, role } = useSelector((state) => state.session);
  const moneyTypes = useSelector((state) => state.plan.moneyTypes.data);
  const [accountAllocations, setAccountAllocations] = useState<
    AccountAllocation[]
  >([]);
  const [, setHasError] = useState<Boolean>(false);
  const [loading, setLoading] = useState<Boolean>(true);

  useEffect(() => {
    fetchAccountAllocations();

    async function fetchAccountAllocations() {
      try {
        const data: AccountAllocation[] = await getAccountAllocations(
          planId,
          empId,
          role
        );
        setAccountAllocations(data);
        setLoading(false);
      } catch (e) {
        setHasError(e);
      }
    }
  }, [planId, empId, role]);

  if (loading) {
    return <LoadingSpinner />;
  }

  const allocationsByAccount = mapAllocationsToAccount(
    moneyTypes,
    accountAllocations
  );

  const allocationsByAccountComponent =
    mapAllocationAccountNameComponents(allocationsByAccount);

  function mapAllocationAccountNameComponents(
    allocationsByAccount: {
      accountName: string;
      id: number;
      allocations: Dictionary<number>;
    }[]
  ) {
    return allocationsByAccount.map((accountAllocation) => {
      const { accountName, id } = accountAllocation;

      return {
        accountName: <AccountName name={accountName} id={id} asLink={false} />,
        allocations: accountAllocation.allocations,
      };
    });
  }

  const accountAllocationsByMoneyType = removeMoneyTypesWithNoAllocations(
    allocationsByAccount,
    moneyTypes
  );
  const moneyTypeColumns = accountAllocationsByMoneyType.map((moneyType: any) =>
    buildMoneyTypeColumn(moneyType, "bold", true)
  );

  const columns = [
    {
      Header: <div className="bold">Account</div>,
      accessor: "accountName",
      Footer: <div className="bold">Total</div>,
    },
    {
      Header: () => <div className="group-header">Contribution Sources</div>,
      id: "contribution header",
      columns: moneyTypeColumns,
      Footer: "",
    },
  ];
  return (
    <>
      {allocationsByAccount.length > 0 ? (
        <TableV2 columns={columns} data={allocationsByAccountComponent} />
      ) : (
        <h2 className="h3 no-allocation-message">
          No Account Allocations to show
        </h2>
      )}
    </>
  );
}
