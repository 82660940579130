import React from "react";
import { Panel, PanelBody, PanelHeading } from "../../panel/panel";
import {
  participantDetailsStatus,
  useStatuses,
} from "../../../selectors/status";
import Skeleton from "react-loading-skeleton";
import { DashboardContributions } from "./dashboard-contributions";
import "./dashboard-contribution-panel.scss";

export function DashboardContributionPanel() {
  const { isLoadingStatus } = useStatuses([participantDetailsStatus]);

  if (isLoadingStatus) {
    return (
      <Panel>
        <PanelHeading>
          <Skeleton count={1} />
        </PanelHeading>
        <PanelBody>
          <Skeleton count={4} />
        </PanelBody>
      </Panel>
    );
  }

  return (
    <Panel
      className="dashboard-contribution-panel"
      data-testid="dashboard-contribution-panel"
    >
      <PanelHeading noPrint>Your Contributions</PanelHeading>
      <PanelBody>
        <DashboardContributions />
      </PanelBody>
    </Panel>
  );
}
