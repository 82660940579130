import { PlanInfoSummaryResponse } from "../models/plan-info-and-summary/plan-info-summary-response";
import { get } from "../repositories/participant";

export async function getPlanInfoSummary(
  planId: number,
  empId: number,
  role: string
): Promise<PlanInfoSummaryResponse> {
  return get(`/plans/${planId}/employees/${empId}/plan-info-summary`, { role });
}
