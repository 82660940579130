import React, { useState } from "react";
import { Toggle, Toggles } from "../../models/toggles";

type TogglesContexType = {
  toggles: Toggles;
  toggle: Function;
};

export const TogglesContext = React.createContext<Partial<TogglesContexType>>(
  {}
);
export const SHOW_PAYMENT_INFO = "SHOW_PAYMENT_INFO";
export const SHOW_DISTRIBUTION_GENERATOR = "SHOW_DISTRIBUTION_GENERATOR";

type Props = {
  children: React.ReactNode;
};

export function TogglesProvider(props: Props) {
  const [toggles, setToggles] = useState<Toggles>({});

  function toggle(key: string) {
    const chosenToggle: Toggle = toggles[key];
    const updatedToggle = {
      ...chosenToggle,
      status: !chosenToggle.status,
    };
    setToggles({ ...toggles, [key]: updatedToggle });
  }

  return (
    <TogglesContext.Provider value={{ toggles, toggle }}>
      {props.children}
    </TogglesContext.Provider>
  );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function isLocalhost() {
  return window.location.hostname.includes("localhost");
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function isPilot() {
  window.location.hostname.includes("pilot");
}
