export const getTagNameFromElement = (element: any) => {
  if (element?.tagName) {
    return element?.tagName.toLowerCase();
  }
  if (element.toString().match(/(([a-z]|-)*)/g)[1]) {
    return element.toString().match(/(([a-z]|-)*)/)[1];
  }

  return 'your PDS element';
};
