import React from "react";
import { Skeleton } from "../../../../components/skeleton/skeleton";
import { planInfoStatusSelector } from "../../../../selectors/status";
import { useSelector } from "../../../../store/store";

export default function ShawDisclaimer() {
  const { planId } = useSelector((state) => state.session);
  const showShawDisclaimer =
    planId === 8945 || planId === 8786 || planId === 8785 || planId === 8952;

  if (showShawDisclaimer) {
    return (
      <Skeleton
        selectors={[planInfoStatusSelector]}
        height={20}
        width={120}
        count={1}
      >
        <p>
          All scheduled distributions and Pre 409A distribution requests will be
          processed during monthly payroll at the end of the month, regardless
          of the payment date displayed online.
        </p>
      </Skeleton>
    );
  }
  return null;
}
