import React from "react";
import { useSelector } from "../../../store/store";
import {
  buildDeferredCompPlannerURL,
  buildPensionContextLink,
} from "../../../util/nq-participant-link-builder";

export default function ContributionsSummaryResourcesLinks() {
  const { empId, planId, role } = useSelector((state) => state.session);
  const contractId = useSelector((state) => state.plan.info.contractId);

  const links = {
    estimatedCalculator: buildPensionContextLink(
      empId,
      contractId,
      "estimatedBenefitCalculator",
      role
    ),
    compPlanner: buildDeferredCompPlannerURL(planId),
  };

  return (
    <div className="resources-links callout dashboard-callout bg-light-blue">
      <h2 className="center-align h4">Resources</h2>
      <ul>
        <li>
          <a
            href={links.estimatedCalculator}
            // eslint-disable-next-line
            target="_blank"
            data-testid="estimatedBenefitCalculator"
            rel="noopener noreferrer"
            title="Opens in a new window"
          >
            Estimated Benefit Calculator
          </a>
        </li>
        <li>
          <a
            href={links.compPlanner}
            // eslint-disable-next-line
            target="_blank"
            data-testid="nQDeferredCompPlanner"
            rel="noopener noreferrer"
            title="Opens in a new window"
          >
            Nonqualified Deferred Compensation Planner
          </a>
        </li>
      </ul>
    </div>
  );
}
