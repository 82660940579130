import _groupBy from "lodash/groupBy";
import { AccountAllocation } from "../../../models/account-allocation";
import { Allocation } from "../../../models/allocation";
import { InvestmentV2 } from "../../../models/investment-v2";
import { MoneyType } from "../../../models/moneytype";
import {
  AllocationWithInvestmentConfig,
  InvestmentAllocationByMoneyType,
} from "./investment-allocation-table";

export function getAllocationsForSelectedAccount(
  investmentAllocations: AccountAllocation[],
  selectedAccountId: number
): AccountAllocation | undefined {
  return investmentAllocations.find(({ id }) => id === selectedAccountId);
}

export function addInvestmentConfigToAllocations(
  investments: InvestmentV2[],
  accountAllocation?: AccountAllocation
): AllocationWithInvestmentConfig[] {
  if (!accountAllocation) {
    return [];
  }
  const { investmentAllocations } = accountAllocation;
  if (investmentAllocations) {
    return investmentAllocations.map((investmentAllocation: Allocation) => {
      const investment = investments.find(
        (investment) => investment.id === investmentAllocation.id
      );
      if (investment) {
        const { institutionName, name, hexValue, sortOrder } = investment;
        return {
          ...investmentAllocation,
          name,
          institutionName,
          hexValue,
          sortOrder,
        };
      }
      return {
        ...investmentAllocation,
      };
    });
  }
  return [];
}

export function groupInvestmentAllocationsByMoneyType(
  allocations: AllocationWithInvestmentConfig[] = []
): InvestmentAllocationByMoneyType[] {
  const investmentsArray = Object.values(_groupBy(allocations, "id"));
  return investmentsArray.map(
    (investmentArray: AllocationWithInvestmentConfig[]) => {
      const allocations: any = {};
      const { name, institutionName, hexValue, id, sortOrder } =
        investmentArray[0];

      investmentArray.forEach((investment: AllocationWithInvestmentConfig) => {
        allocations[investment.moneyTypeId] = investment.percentage;
      });
      const temp: InvestmentAllocationByMoneyType = {
        allocations,
        name,
        institutionName,
        hexValue,
        id,
        sortOrder,
      };
      return temp;
    }
  );
}

export function removeMoneyTypesWithNoAllocations(
  accountAllocations: any,
  moneyTypes: MoneyType[],
  b = true
): MoneyType[] {
  return moneyTypes.filter((moneyType: MoneyType) => {
    const { moneyTypeId } = moneyType;
    let hasAllocations = false;
    accountAllocations.forEach((accountAllocation: any) => {
      const { allocations } = accountAllocation;
      if (allocations[moneyTypeId] && allocations[moneyTypeId] !== 0) {
        hasAllocations = true;
      }
    });
    return hasAllocations;
  });
}
