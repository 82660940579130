import { combineReducers } from "redux";
import accountLevelElections from "./account-distributions";
import participantLevelElections from "./participantDistributions";
import ptcTracker from "./ptcTracker";
import scheduledElections from "./scheduled-elections";
import scheduledDistributions from "./scheduledDistributionEvent";
import unscheduledDistributionEvents from "./unscheduledDistributionEvents";
import unscheduledDistributionEventsByProvision from "./unscheduledDistributionEventsByProvision";
import forecastDistributionsResource from "./forecast-distributions-resource";
import forecastDistributionsResourceFromGraph from "./forecast-distributions-resource-graph";

const distributionReducer = combineReducers({
  accountLevelElections,
  scheduledElections,
  scheduledDistributions,
  participantLevelElections,
  unscheduledDistributionEvents,
  unscheduledDistributionEventsByProvision,
  forecastDistributionsResource,
  forecastDistributionsResourceFromGraph,
  ptcTracker,
});

export default distributionReducer;
