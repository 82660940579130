import { buildDistributionMethod } from "../../pages/distributions/distribution-scheduled/scheduled-distribution-util";
import { AccountType } from "./account-type";
import { PaymentType } from "./payment-type";
import { ScheduledDistributionEvent } from "./scheduled-distribution-event";

export class DistributionPayment {
  accountId: number;
  accountName: string;
  distributionDate: string;
  status: string;
  installment?: number;
  distributionAmount?: number;
  totalBalance?: number;
  payoutMethod?: string;
  accountNameNode?: React.ReactNode;

  constructor(
    scheduledDistributionEvent: ScheduledDistributionEvent,
    distributionDate: string,
    status: string,
    accountId: number,
    distributionAmount?: number,
    totalBalance?: number
  ) {
    this.accountId = scheduledDistributionEvent.accountId;
    this.installment = scheduledDistributionEvent.installNum;
    this.accountName = scheduledDistributionEvent.accountName;
    this.distributionDate = distributionDate;
    this.status = status;
    this.distributionAmount = distributionAmount;
    this.totalBalance = totalBalance;
    this.payoutMethod = buildDistributionMethod(scheduledDistributionEvent);
  }
}

export class PastDistributionPayment extends DistributionPayment {
  actualAmount: number;
  accountType?: string;
  isFuture?: boolean;

  constructor(scheduledDistributionEvent: ScheduledDistributionEvent) {
    super(
      scheduledDistributionEvent,
      scheduledDistributionEvent.completedDate!,
      "Distributed",
      scheduledDistributionEvent.accountId
    );
    this.actualAmount = scheduledDistributionEvent.transactionAmount!;
    this.accountType =
      scheduledDistributionEvent.accountType || AccountType.IN_SERVICE;
    this.isFuture = false;
  }
}

export class FutureDistributionPayment extends DistributionPayment {
  color?: string;
  accountType?: string;
  isFuture?: boolean;

  constructor(
    scheduledDistributionEvent: ScheduledDistributionEvent,
    estimatedAmount: number,
    color?: string,
    totalBalance?: number
  ) {
    super(
      scheduledDistributionEvent,
      scheduledDistributionEvent.scheduledDate,
      "Scheduled",
      scheduledDistributionEvent.accountId,
      estimatedAmount,
      totalBalance
    );
    this.color = color;
    this.accountType =
      scheduledDistributionEvent.accountType || AccountType.IN_SERVICE;
    this.isFuture = true;
  }
}

export class FutureDistributionTimelinePayment {
  payoutDate: Date;
  accountName: string;
  accountId: number;
  value?: number;
  color?: string;
  accountType?: string;
  date: Date; // for usage with horizon timeline library
  constructor(
    payoutDate: Date,
    name: string,
    accountId: number,
    date: Date,
    value?: number,
    color?: string,
    accountType?: string
  ) {
    this.payoutDate = payoutDate;
    this.accountName = name;
    this.accountId = accountId;
    this.date = date;
    this.value = value;
    this.color = color;
    this.accountType = accountType;
  }
}

export class ScheduledDistributionPagePayment extends DistributionPayment {
  accountName: string;
  accountId: number;
  accountType: string;
  distributionMethod: string;
  paymentType: PaymentType;
  installmentString: string;
  accountNameNode?: React.ReactNode | string;
  isFuture?: boolean;

  constructor(
    scheduledDistributionEvent: ScheduledDistributionEvent,
    accountId: number,
    estimatedAmount: number,
    distributionMethod: string
  ) {
    super(
      scheduledDistributionEvent,
      scheduledDistributionEvent.scheduledDate,
      "Scheduled",
      accountId,
      estimatedAmount
    );
    this.accountName = scheduledDistributionEvent.accountName;
    this.accountId = scheduledDistributionEvent.accountId;
    this.accountType = scheduledDistributionEvent.accountType;
    this.paymentType = scheduledDistributionEvent.paymentType;
    this.distributionMethod = distributionMethod;
    this.installmentString = buildInstallment(scheduledDistributionEvent);
    this.isFuture = true;
  }
}

export function buildInstallment(event: ScheduledDistributionEvent): string {
  const { paymentType, installNum, totalInstallments } = event;
  if (paymentType === PaymentType.LUMPSUM || totalInstallments === 0) {
    return "N/A";
  } else {
    return `${installNum} of ${totalInstallments}`;
  }
}
