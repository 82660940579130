import React from "react";
import Balance from "../../../components/numbers/balance";
import Percent from "../../../components/numbers/percent";
import TableV2 from "../../../components/table/tableV2";
import { useSelector } from "../../../store/store";
import {
  aggregateByMoneyTypeId,
  filterEmptyInvestments,
  flatten,
} from "../../../util/investment-context-util";

function getVestedPercentsByMoneyType(investments) {
  return investments.reduce((accum, curr) => {
    if (!accum[curr.moneyTypeId]) {
      accum[curr.moneyTypeId] = 0;
    }

    if (curr.endBalance?.cashBalance?.vestedPercent > 0) {
      accum[curr.moneyTypeId] = curr.endBalance?.cashBalance?.vestedPercent;
    }

    return accum;
  }, {});
}

function insertEndBalance(investment, vestedPercentsByMoneyType) {
  if (!investment.endBalance || !investment.endBalance.cashBalance) {
    return {
      cashBalance: {
        vestedPercent: 0,
      },
    };
  }

  return {
    ...investment.endBalance,
    cashBalance: {
      ...investment.endBalance.cashBalance,
      vestedPercent: vestedPercentsByMoneyType[investment.moneyTypeId],
    },
  };
}

function ActivityByContribSource(props) {
  const { investments } = props;

  const moneyTypes = useSelector((state) => state.plan.moneyTypes.data);

  const vestedPercentByMoneyType = getVestedPercentsByMoneyType(investments);

  const contributionSummary = useSelector(
    (state) => state.contributions.contributionSummary.data
  );
  const aggregatedInvestments = flatten(aggregateByMoneyTypeId(investments));
  const investmentsWithMoneyTypeName = aggregatedInvestments.map(
    (investment) => {
      const match = moneyTypes.find(
        (moneyType) => investment.moneyTypeId === moneyType.moneyTypeId
      );
      if (match) {
        return {
          ...investment,
          endBalance: insertEndBalance(investment, vestedPercentByMoneyType),
          moneyTypeName: match.moneyName,
        };
      }
      return investment;
    }
  );

  const nonEmptyInvestments = filterEmptyInvestments(
    investmentsWithMoneyTypeName
  );

  const investmentsUpdateWithContributionToDate = nonEmptyInvestments.map(
    (investment) => {
      const match = contributionSummary.find(
        (contribution) =>
          investment.moneyTypeId === contribution.moneyTypeId &&
          investment.accountId === contribution.accountId
      );
      if (match) {
        return {
          ...investment,
          //contributionAmtToDate: match.contributionsSinceInception,
        };
      }
      return investment;
    }
  );

  const columns = [
    {
      Header: "",
      accessor: "moneyTypeName",
      Footer: <span className="bold">Totals</span>,
    },
    {
      Header: (
        <>
          <span className="show">Contributions</span>
          this period
        </>
      ),
      accessor: "contributionAmt",
      className: "right",
      Cell: ({ value }) => <Balance value={value} showDollar />,
      Footer: (info) => {
        // Only calculate total visits if rows change
        const total = React.useMemo(
          () =>
            info.rows.reduce((sum, row) => row.values.contributionAmt + sum, 0),
          [info.rows]
        );

        return (
          <span className="bold">
            <Balance value={total} showDollar />
          </span>
        );
      },
    },
    {
      Header: (
        <>
          <span className="show">Distributions</span>
          this period
        </>
      ),
      accessor: "distributionAmt",
      className: "right",
      Cell: ({ value }) => <Balance value={value} showDollar />,
      Footer: (info) => {
        // Only calculate total visits if rows change
        const total = React.useMemo(
          () =>
            info.rows.reduce((sum, row) => row.values.distributionAmt + sum, 0),
          [info.rows]
        );

        return (
          <span className="bold">
            <Balance value={total} showDollar />
          </span>
        );
      },
    },
    {
      Header: (
        <>
          <span className="show">Forfeitures</span>
          this period
        </>
      ),
      accessor: "forfeitAmt",
      className: "right",
      Cell: ({ value }) => <Balance value={value} showDollar />,
      Footer: (info) => {
        // Only calculate total visits if rows change
        const total = React.useMemo(
          () => info.rows.reduce((sum, row) => row.values.forfeitAmt + sum, 0),
          [info.rows]
        );

        return (
          <span className="bold">
            <Balance value={total} showDollar />
          </span>
        );
      },
    },
    {
      Header: (
        <>
          <span className="show">Contributions</span>
          to date
        </>
      ),
      accessor: "contributionAmtToDate",
      className: "right",
      Cell: ({ value }) => <Balance value={value} showDollar />,
      Footer: (info) => {
        // Only calculate total visits if rows change
        const total = React.useMemo(
          () =>
            info.rows.reduce(
              (sum, row) => row.values.contributionAmtToDate + sum,
              0
            ),
          [info.rows]
        );

        return (
          <span className="bold">
            <Balance value={total} showDollar />
          </span>
        );
      },
    },
    {
      Header: (
        <>
          <span className="show">Plan transfers</span>
          to date
        </>
      ),
      accessor: "planTransfersToDate",
      className: "right",
      Cell: ({ value }) => <Balance value={value} showDollar />,
      Footer: (info) => {
        // Only calculate total visits if rows change
        const total = React.useMemo(
          () =>
            info.rows.reduce(
              (sum, row) => row.values.planTransfersToDate + sum,
              0
            ),
          [info.rows]
        );

        return (
          <span className="bold">
            <Balance value={total} showDollar />
          </span>
        );
      },
    },
    {
      Header: (
        <>
          <span className="show">Account</span>
          balance
        </>
      ),
      accessor: "endBalance.cashBalance.balance",
      className: "right",
      Cell: ({ value }) => <Balance value={value} showDollar />,
      Footer: (info) => {
        // Only calculate total visits if rows change
        const total = React.useMemo(
          () =>
            info.rows.reduce((sum, row) => {
              return row.values["endBalance.cashBalance.balance"] + sum;
            }, 0),
          [info.rows]
        );

        return (
          <span className="bold">
            <Balance value={total} showDollar />
          </span>
        );
      },
    },
    {
      Header: (
        <>
          <span className="show">Vested</span>
          balance
        </>
      ),
      accessor: "endBalance.cashBalance.vestedBalance",
      className: "right",
      Cell: ({ value }) => <Balance value={value} showDollar />,
      Footer: (info) => {
        // Only calculate total visits if rows change
        const total = React.useMemo(
          () =>
            info.rows.reduce(
              (sum, row) =>
                row.values["endBalance.cashBalance.vestedBalance"] + sum,
              0
            ),
          [info.rows]
        );

        return (
          <span className="bold">
            <Balance value={total} showDollar />
          </span>
        );
      },
    },
    {
      Header: (
        <>
          <span className="show">Vested</span>
          percent
        </>
      ),
      accessor: "endBalance.cashBalance.vestedPercent",
      className: "right",
      Cell: ({ value }) => <Percent value={value} showSymbol />,
    },
  ];

  return (
    <TableV2 columns={columns} data={investmentsUpdateWithContributionToDate} />
  );
}

export default ActivityByContribSource;
