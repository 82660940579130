import { AccountAllocation } from "../models/account-allocation";
import { get } from "../repositories/participant";

export async function getAccountAllocations(
  planId: number,
  empId: number,
  role: string
): Promise<AccountAllocation[]> {
  return get(`/plans/${planId}/employees/${empId}/allocations/`, { role });
}
