import React, { useEffect, useState } from "react";
import { getAssetClasses } from "../../services/asset-classes";
import {
  addDescriptionsToAssetClasses,
  filterAssetClasses,
} from "../../util/asset-class";
import { sortInvestments } from "../../util/investments";
import { Modal } from "../modal/modal";
import AssetClass from "./asset-class";
import descriptions from "./asset-class-descriptions-by-class-id";
import "./asset-class-modal.scss";

export default function AssetClassModal(props) {
  const { linkText } = props;
  const [assetClasses, setAssetClasses] = useState([]);

  useEffect(() => {
    fetchAssetClasses();

    async function fetchAssetClasses() {
      const data = await getAssetClasses();
      const assets = addDescriptionsToAssetClasses(data, descriptions);
      const sortedAssets = sortInvestments(assets);
      const filteredAssets = filterAssetClasses(sortedAssets);
      setAssetClasses(filteredAssets);
    }
  }, []);

  return (
    <Modal
      title="Asset Class Descriptions"
      linkText={linkText || "View detailed descriptions of all asset classes"}
    >
      <div
        data-testid="asset-classes"
        className="asset-class-modal"
        role="presentation"
      >
        {assetClasses.map((assetClass, index) => (
          <AssetClass
            key={assetClass.classId}
            index={index}
            assetClass={assetClass}
          />
        ))}
      </div>
    </Modal>
  );
}
