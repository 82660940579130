import React, { useEffect } from "react";
import { Page, PageHeader } from "src/components/page/index";
import {
  definedBenefitAboutStatus,
  useStatuses,
} from "../../../selectors/status";
import { fetchDefinedBenefitAbout } from "../../../reducers/defined-benefit-about/defined-benefit-about";
import { useAppDispatch, useSelector } from "../../../store/store";

export default function DefinedBenefitAboutPage() {
  const dispatch = useAppDispatch();
  const { isLoadingStatus } = useStatuses([definedBenefitAboutStatus]);
  const { planId, empId, role } = useSelector((state) => state.session);
  const { definedBenefitDescription1 } = useSelector(
    (state) => state.definedBenefitAbout.definedBenefitAbout
  );
  const { definedBenefitDescription2 } = useSelector(
    (state) => state.definedBenefitAbout.definedBenefitAbout
  );
  const { definedBenefitDescription3 } = useSelector(
    (state) => state.definedBenefitAbout.definedBenefitAbout
  );

  useEffect(() => {
    dispatch(fetchDefinedBenefitAbout({ planId, empId, role }));
  }, [dispatch, planId, empId, role]);

  return (
    <Page printable name={"defined-benefit-about"} loading={isLoadingStatus}>
      <PageHeader name={"defined benefit about"}>
        About Your Nonqualified Benefit
      </PageHeader>
      <div className="util-margin-top-15">{definedBenefitDescription1}</div>
      <div className="util-margin-top-15">{definedBenefitDescription2}</div>
      <div className="util-margin-top-15">{definedBenefitDescription3}</div>
    </Page>
  );
}
