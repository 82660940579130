import React from "react";
import "./filters.scss";
import { PdsButton } from "@principal/design-system-react";

interface FilterButtonsProps {
  activeFilter: string;
  onFilterChange: (value: string) => void;
}

const FilterButtons: React.FC<FilterButtonsProps> = ({
  activeFilter,
  onFilterChange,
}) => {
  const filters = [
    { id: "all", label: "All Events" },
    { id: "confirmed", label: "Action taken" },
    { id: "notConfirmed", label: "No action taken" },
  ];

  return (
    <div
      style={{
        marginLeft: "-14%",
        backgroundColor: "#E8E8E8",
        height: "35px",
        borderRadius: "50px",
        width: "110%",
        display: "flex",
      }}
    >
      {filters.map((filter) => (
        <div style={{ marginTop: "2%", marginLeft: "1%" }}>
          <PdsButton
            size={"sm"}
            key={filter.id}
            onClick={() => onFilterChange(filter.id)}
            link={"default"}
            className={activeFilter !== filter.id ? "" : "filter-button"}
          >
            <div className="word-sizing">{filter.label}</div>
          </PdsButton>
        </div>
      ))}
    </div>
  );
};

export default FilterButtons;
