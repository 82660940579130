import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AsyncStatus } from "../../models/async-status";
import { getStreamOfPaymentPendingAccountInfo } from "../../services/stream-of-payment-pending";

export const fetchPaymentPendingAccountInfo = createAsyncThunk(
  "fetch-payment-account-info",
  async (
    {
      planId,
      empId,
      accountId,
      role,
    }: { planId: number; empId: number; accountId: number; role: string },
    thunkApi
  ) => {
    return getStreamOfPaymentPendingAccountInfo(planId, empId, accountId, role);
  }
);

type State = {
  accountName: string;
  currentDistributionDate: string;
  previousPaymentMethodName: string;
  redeferralDistributionDate: string;
  paymentMethodNameForDisplay: string;
  effectiveDateForDisplayOnConfirm: string;
  redeferralNewAccountName: string;
  effectiveDate: string;
  status: AsyncStatus;
};

const initialState: State = {
  status: AsyncStatus.not_fetched,
  accountName: "",
  currentDistributionDate: new Date().toJSON(),
  previousPaymentMethodName: "",
  redeferralDistributionDate: new Date().toJSON(),
  paymentMethodNameForDisplay: "",
  effectiveDateForDisplayOnConfirm: new Date().toJSON(),
  redeferralNewAccountName: "",
  effectiveDate: new Date().toJSON(),
};

const streamOfPaymentPendingSlice = createSlice({
  name: "payment-pending-account-info",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPaymentPendingAccountInfo.pending, (state, action) => {
      return { ...state, status: AsyncStatus.loading };
    });
    builder.addCase(
      fetchPaymentPendingAccountInfo.fulfilled,
      (state, action) => {
        return { ...state, ...action.payload, status: AsyncStatus.done };
      }
    );
    builder.addCase(
      fetchPaymentPendingAccountInfo.rejected,
      (state, action) => {
        return {
          ...state,
          status: AsyncStatus.error,
        };
      }
    );
    builder.addDefaultCase((state, action) => state);
  },
});

export default streamOfPaymentPendingSlice.reducer;
