import React from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { Account } from "../../models/account";

type Props = {
  account: Account;
};

export function AccountInfoLink(props: Props) {
  const isAccountInfoPage = useRouteMatch("/overview/account-info");
  if (isAccountInfoPage) {
    return null;
  }

  const { account } = props;
  return (
    <Link
      className="account-link cta-text"
      to={{
        pathname: "/overview/account-info",
        search: `?accountId=${account.id}`,
      }}
    >
      Account overview
    </Link>
  );
}
