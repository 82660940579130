import React from "react";
import { getBeneficiariesToUse } from "../../../../components/beneficiary/beneficiary-util";
import {
  Beneficiary,
  BeneficiaryType,
} from "../../../../models/beneficiaries/beneficiary";
import { useSelector } from "../../../../store/store";
import BeneficiaryModal, {
  ModalAction,
} from "../../add-beneficiary/beneficiary-modal";
import BeneficiaryAllocation from "./beneficiary-allocation";
import "./beneficiary-panel-body.scss";
import {
  AccordionWell,
  ReusableAccordionDropdown,
} from "../../../../components/accordion/accordion";
import { ViewDetailsTable } from "./view-details-table";

type BeneficiaryDisplayProps = {
  isPrimary?: boolean;
};

export default function BeneficiaryPanelBody(props: BeneficiaryDisplayProps) {
  const { isPrimary } = props;
  const beneficiaries: Beneficiary[] = useSelector(
    (state) => state.beneficiaries.beneficiaries
  );
  const beneficiariesToUse: Beneficiary[] = getBeneficiariesToUse(
    beneficiaries,
    Boolean(isPrimary)
  );

  const hasBeneficiaries = beneficiariesToUse.length > 0;
  const beneType = isPrimary
    ? BeneficiaryType.PRIMARY
    : BeneficiaryType.CONTINGENT;

  function getTotalAllocations() {
    let total = 0;
    beneficiariesToUse.map((beneficiary) => {
      if (beneficiary.allocationAmount) {
        total += beneficiary.allocationAmount;
      } else {
        total += 0;
      }
    });
    return Math.round(total * 100) / 100;
  }

  return (
    <>
      {hasBeneficiaries ? (
        beneficiariesToUse.map((beneficiaryToUse: Beneficiary, index) => (
          <div key={beneficiaryToUse.guid} className={"row"}>
            <div
              className={
                "col-xs-12 util-margin-top-10 util-padding-top-10 bene-item js-bene-primary-list-item"
              }
            >
              <div
                className={
                  "col-sm-9 text-wrap text-wrap-mobile util-padding-left-0 util-padding-right-0"
                }
              >
                <BeneficiaryModal
                  modalAction={ModalAction.VIEW}
                  beneficiaryType={beneType}
                  beneficiary={beneficiaryToUse}
                  testid={`edit-button-${beneType}-${index}`}
                />
                <ReusableAccordionDropdown
                  id={`${beneType}-${index}`}
                  collapsedName={"View details"}
                  expandedName={"Hide details"}
                  classNames={["bene-accordion-header util-padding-right-10"]}
                ></ReusableAccordionDropdown>
              </div>
              <div className="col-sm-3 beneficiary-input">
                <BeneficiaryAllocation beneficiary={beneficiaryToUse} />
              </div>
              <AccordionWell
                id={`${beneType}-${index}`}
                classNames={[
                  "col-sm-12 util-padding-left-0 util-padding-right-0",
                ]}
              >
                <ViewDetailsTable
                  beneficiaryType={beneType}
                  index={index}
                  beneficiaryToUse={beneficiaryToUse}
                />
              </AccordionWell>
            </div>
          </div>
        ))
      ) : (
        <p>You currently have no designated {beneType} beneficiaries.</p>
      )}
      {hasBeneficiaries ? (
        <div className={"row"}>
          <hr />
          <div
            className={
              "col-xs-12 util-margin-top-10 util-padding-top-10 bene-item js-bene-primary-list-item"
            }
          >
            <div
              className={
                "col-sm-9 text-wrap text-wrap-mobile util-padding-left-0"
              }
            >
              <div>Total:</div>
            </div>
            <div className={"col-sm-3 text-wrap text-wrap-mobile"}>
              <div className={"input-suffix"}>
                {getTotalAllocations()}
                <span>%</span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
