import React, { useEffect, useRef, useState } from "react";
import { RedeferralStatus } from "../../models/redeferral/redeferral-status";
import { accountRedeferralStatusesSelector } from "../../selectors/redeferrals";
import { useAppDispatch, useSelector } from "../../store/store";
import { Page, PageHeader } from "src/components/page";
import { register } from "src/serviceWorker";
import _isEmpty from "lodash/isEmpty";
import { Account } from "../../models/account";
import { mapInvestmentsToAccounts } from "../overview/plan-overview-summary/plan-overview-util";
import { useInvestments } from "src/selectors/investments";
import { calculateTotalEndBalanceV2 } from "src/util/investments";
import Balance from "src/components/numbers/balance";
import { fetchEstimatedBenefitAccountInfo } from "../../reducers/estimated-benefit-account-info/estimated-benefit-account-info";
import { buildLinkForStreamOfPaymentPending } from "src/util/nq-participant-link-builder";
import { fetchEstimatedCalculation } from "../../reducers/estimated-projection-request/estimated-calculation";
import { objectToBase64String } from "src/util/base-64-util";
import { getRoundedValue } from "src/components/numbers/fixed-decimal";
import { ProjectionList } from "src/models/enrollment-tools/projection-list";
import { AsyncStatus } from "src/models/async-status";

export default function EstimatedBenefitCalculatorPage() {
  const accounts: Account[] = useSelector((state) => state.accounts.available);
  const accountRedeferralStatuses: RedeferralStatus[] = useSelector(
    accountRedeferralStatusesSelector
  );
  const { estimatedBenefitAccountInfo } = useSelector(
    (state) => state.estimatedBenefitAccountInfo
  );
  const { estimatedCalculation } = useSelector(
    (state) => state.estimatedCalculation
  );
  const { investments } = useInvestments();
  const { planId, empId, role } = useSelector((state) => state.session);
  const mappedAccounts = mapInvestmentsToAccounts(investments, accounts);
  const [selectedAccountId, setSelectedAccountId] = useState("");
  const dispatch = useAppDispatch();
  const RETIREMENT = "Retirement";
  const AGE_OF_RETIREMENT = "Age of Retirement";
  const PAYOUT_DATE = "Payout Date";
  const [toggleTotalCalculation, setTotalCalculation] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  type DataType = { [key: number]: string };
  const [earningRateDefaultValue, setEarningRateDefaultValue] =
    useState<DataType>({});
  const [retirementAgeDefaultValue, setRetirementAgeDefaultValue] =
    useState<DataType>({});

  const getFirstMappedAccountId = () => {
    const accountId = localStorage.getItem("accountId");
    if (mappedAccounts && mappedAccounts.length) {
      return Number(accountId) || mappedAccounts[0].id;
    }
    return "";
  };

  const accountId = selectedAccountId
    ? Number(selectedAccountId)
    : getFirstMappedAccountId();

  useEffect(() => {
    if (
      accountId > 0 &&
      estimatedBenefitAccountInfo.status === AsyncStatus.not_fetched
    ) {
      dispatch(
        fetchEstimatedBenefitAccountInfo({ planId, empId, accountId, role })
      );
      setSelectedAccountId(accountId);
    }
    if (
      estimatedCalculation &&
      estimatedCalculation.projectionList.some(
        (item) =>
          !!item.estimatedAccountBalance || item.estimatedAccountBalance === 0
      )
    ) {
      setTotalCalculation(true);
    }
  }, [
    dispatch,
    planId,
    empId,
    accountId,
    role,
    estimatedCalculation,
    estimatedBenefitAccountInfo.status,
  ]);

  const handleDropdownChange = (event: any) => {
    setSelectedAccountId(event.target.value);
    localStorage.setItem("accountId", event.target.value);
    clearInputField();
  };

  const getSelectedAccount = () =>
    mappedAccounts.find((account) => account.id === Number(selectedAccountId));
  const account = getSelectedAccount();
  const currentAccountBalance = account
    ? calculateTotalEndBalanceV2(account.investments)
    : 0;
  const isRetirementAccount = (account: Account) =>
    account ? account.type === RETIREMENT : false;
  const getAccountLabel = () =>
    (!account && isRetirementAccount(mappedAccounts[0])) ||
    isRetirementAccount(account)
      ? AGE_OF_RETIREMENT
      : PAYOUT_DATE;
  const length = getAccountLabel() === AGE_OF_RETIREMENT ? 3 : 10;
  const isPayoutDate = getAccountLabel() === PAYOUT_DATE;
  const payoutLocaleDate = isPayoutDate
    ? estimatedBenefitAccountInfo.payoutDate &&
      new Date(estimatedBenefitAccountInfo.payoutDate).toLocaleDateString()
    : "";
  const effectiveLocaleDate =
    estimatedBenefitAccountInfo.effectiveDate &&
    new Date(estimatedBenefitAccountInfo.effectiveDate).toLocaleDateString();
  const hasPendingRedeferral = accountRedeferralStatuses.some(
    (status) =>
      status.accountId === (account ? account.accountId : 0) &&
      status.hasActiveRedeferral
  );
  const { supportsShawModel, contractId } = useSelector(
    (state) => state.plan.info
  );
  const action = "streamOfPaymentPending";
  const getCalculatedValue = (name: string) =>
    estimatedCalculation.projectionList.find((item) => item.name === name);
  const estimate1CalculatedValue = getCalculatedValue(
    "estimateProjection1"
  )?.estimatedAccountBalance;
  const estimate2CalculatedValue = getCalculatedValue(
    "estimateProjection2"
  )?.estimatedAccountBalance;
  const estimate3CalculatedValue = getCalculatedValue(
    "estimateProjection3"
  )?.estimatedAccountBalance;

  const pendingRedeferralUrl = () => {
    return buildLinkForStreamOfPaymentPending(planId, empId, accountId, role);
  };

  const estimate1_annual_contribution = useRef<HTMLInputElement>(null);
  const estimate1_earning_rate = useRef<HTMLInputElement>(null);
  const estimate1_retirement_age = useRef<HTMLInputElement>(null);
  const estimate1_payout_date = useRef<HTMLInputElement>(null);

  const estimate2_annual_contribution = useRef<HTMLInputElement>(null);
  const estimate2_earning_rate = useRef<HTMLInputElement>(null);
  const estimate2_retirement_age = useRef<HTMLInputElement>(null);
  const estimate2_payout_date = useRef<HTMLInputElement>(null);

  const estimate3_annual_contribution = useRef<HTMLInputElement>(null);
  const estimate3_earning_rate = useRef<HTMLInputElement>(null);
  const estimate3_retirement_age = useRef<HTMLInputElement>(null);
  const estimate3_payout_date = useRef<HTMLInputElement>(null);

  const validate = (list: ProjectionList[]) => {
    for (const [index, value] of Array.from(list.entries())) {
      if (!validateForEachItem(value, index)) {
        break;
      }
    }
    return true;
  };

  const setEarningRate = (index: number, item: ProjectionList) => {
    setEarningRateDefaultValue((prevState) => ({ ...prevState, index: "" }));
    const newEarningRate = { ...earningRateDefaultValue };
    newEarningRate[index] = "8";
    setEarningRateDefaultValue((prevState) => ({
      ...prevState,
      ...newEarningRate,
    }));
    item.earningsRatePercent = "8";
  };

  const validateForEachItem = (item: ProjectionList, index: number) => {
    const {
      averageAnnualContributionAmount,
      earningsRatePercent,
      retirementAge,
    } = item;
    const nextIndex = index + 1;
    if (
      !_isEmpty(averageAnnualContributionAmount) &&
      !_isEmpty(payoutLocaleDate)
    ) {
      if (_isEmpty(earningsRatePercent)) {
        setEarningRate(nextIndex, item);
      }
      return errorValidation(item);
    } else if (!_isEmpty(averageAnnualContributionAmount)) {
      if (_isEmpty(earningsRatePercent)) {
        setEarningRate(nextIndex, item);
      }
      if (_isEmpty(retirementAge)) {
        setRetirementAgeDefaultValue((prevState) => ({
          ...prevState,
          nextIndex: "",
        }));
        const newRetirementAge = { ...retirementAgeDefaultValue };
        newRetirementAge[nextIndex] = "65";
        setRetirementAgeDefaultValue((prevState) => ({
          ...prevState,
          ...newRetirementAge,
        }));
        item.retirementAge = "65";
      }
      return errorValidation(item);
    }
  };

  const errorValidation = (item: ProjectionList) => {
    const { earningsRatePercent, retirementAge, payoutStartDate } = item;
    const { currentAge, effectiveDate } = estimatedBenefitAccountInfo;
    if (_isEmpty(earningsRatePercent)) {
      setErrorMessage("Earning Rate Percent required");
      return false;
    } else if (Number(earningsRatePercent) <= 0) {
      setErrorMessage("Earning Rate Percent needs to be bigger than zero");
      return false;
    } else if (
      _isEmpty(payoutStartDate) &&
      Number(retirementAge) < currentAge
    ) {
      setErrorMessage("Retirement Age less than Participant Age");
      return false;
    } else if (
      !_isEmpty(payoutStartDate) &&
      new Date(payoutStartDate) < new Date(effectiveDate)
    ) {
      setErrorMessage("Payout Date Should be greater than Effective Date");
      return false;
    }
    setErrorMessage("");
    return true;
  };

  return (
    <Page name={"estimate-benefit-calculator"} loading={false}>
      <PageHeader name={"estimate-benefit-calculator"}>
        Estimated Benefit Calculator
      </PageHeader>
      <span>
        This tool estimates your nonqualified plan benefit based on your current
        account balance(s), personal data and the assumptions you provide. By
        varying the assumptions you can see the impact to your estimated
        benefit. You can run up to three scenarios at a time.
      </span>
      {errorMessage ? (
        <div className="alert alert-danger alert-icon" role="alert">
          {errorMessage}
        </div>
      ) : (
        ""
      )}
      <div
        className="util-margin-top-15"
        style={{ fontWeight: "bold", color: "#000" }}
      >
        Select the Account you would like to Project
      </div>
      {
        <div
          className="util-margin-top-15"
          style={{ display: "flex", alignItems: "center" }}
        >
          <span style={{ marginRight: "10px" }}>Account:</span>
          <select
            ref={register}
            className="form-control"
            style={{ width: "30%", marginRight: "20px" }}
            value={accountId}
            data-testid="benefitAccountInput"
            name="accountSelection"
            onChange={handleDropdownChange}
          >
            {mappedAccounts &&
              mappedAccounts.map((account) => (
                <option value={account.id}>{account.name}</option>
              ))}
          </select>
          {account && hasPendingRedeferral && !supportsShawModel ? (
            <a href={pendingRedeferralUrl()}>
              This Account has pending distribution elections. To cancel this
              election please contact us at (234) 567-8910
            </a>
          ) : (
            ""
          )}
        </div>
      }
      <div className="util-margin-top-15">
        <span style={{ marginRight: "10px" }}>
          Current Age: {estimatedBenefitAccountInfo.currentAge}
        </span>
      </div>
      <div className="util-margin-top-15">
        Current Account Balance:{" "}
        <Balance showDollar value={currentAccountBalance} />
      </div>
      <div className="util-margin-top-15" style={{ fontWeight: "bold" }}>
        Enter Your Projections
      </div>
      {
        <div>
          <table data-testid="personal-info-table" className="table">
            <tbody>
              <tr style={{ background: "#caebfa", fontWeight: "bold" }}>
                <td colSpan={2} className="table-head"></td>
                <td colSpan={2} className="table-head">
                  Estimate 1
                </td>
                <td colSpan={2} className="table-head">
                  Estimate 2
                </td>
                <td colSpan={2} className="table-head">
                  Estimate 3
                </td>
              </tr>
              <tr style={{ background: "#f1f1f1" }}>
                <td
                  colSpan={2}
                  className="table-head"
                  style={{ fontWeight: "bold" }}
                >
                  Average Annual Contributions
                </td>
                <td colSpan={2} className="table-head">
                  $
                  <input
                    id="number"
                    dir="rtl"
                    type="number"
                    maxLength={10}
                    size={15}
                    ref={estimate1_annual_contribution}
                    defaultValue="0.00"
                  ></input>
                </td>
                <td colSpan={2} className="table-head">
                  $
                  <input
                    id="number"
                    dir="rtl"
                    type="number"
                    maxLength={10}
                    size={15}
                    ref={estimate2_annual_contribution}
                  ></input>
                </td>
                <td colSpan={2} className="table-head">
                  $
                  <input
                    id="number"
                    dir="rtl"
                    type="number"
                    maxLength={10}
                    size={15}
                    ref={estimate3_annual_contribution}
                  ></input>
                </td>
              </tr>
              <tr>
                <td
                  colSpan={2}
                  className="table-head"
                  style={{ fontWeight: "bold" }}
                >
                  Earning Rate *
                </td>
                <td colSpan={2} className="table-head">
                  <input
                    id="number"
                    dir="rtl"
                    type="number"
                    maxLength={3}
                    size={5}
                    ref={estimate1_earning_rate}
                    defaultValue={earningRateDefaultValue[1]}
                  ></input>
                  %
                </td>
                <td colSpan={2} className="table-head">
                  <input
                    id="number"
                    dir="rtl"
                    type="number"
                    maxLength={3}
                    size={5}
                    ref={estimate2_earning_rate}
                    defaultValue={earningRateDefaultValue[2]}
                  ></input>
                  %
                </td>
                <td colSpan={2} className="table-head">
                  <input
                    id="number"
                    dir="rtl"
                    type="number"
                    maxLength={3}
                    size={5}
                    ref={estimate3_earning_rate}
                    defaultValue={earningRateDefaultValue[3]}
                  ></input>
                  %
                </td>
              </tr>
              {isPayoutDate ? (
                <tr style={{ background: "#f1f1f1" }}>
                  <td
                    colSpan={2}
                    className="table-head"
                    style={{ fontWeight: "bold" }}
                  >
                    Payout Date
                  </td>
                  <td colSpan={2} className="table-head">
                    <input
                      id="number"
                      dir="rtl"
                      type="text"
                      maxLength={length}
                      size={length}
                      key={`${Math.floor(Math.random() * 1000)}-min`}
                      ref={estimate1_payout_date}
                      defaultValue={payoutLocaleDate}
                    ></input>
                  </td>
                  <td colSpan={2} className="table-head">
                    <input
                      id="number"
                      dir="rtl"
                      type="text"
                      maxLength={length}
                      size={length}
                      ref={estimate2_payout_date}
                    ></input>
                  </td>
                  <td colSpan={2} className="table-head">
                    <input
                      id="number"
                      dir="rtl"
                      type="text"
                      maxLength={length}
                      size={length}
                      ref={estimate3_payout_date}
                    ></input>
                  </td>
                </tr>
              ) : (
                <tr style={{ background: "#f1f1f1" }}>
                  <td
                    colSpan={2}
                    className="table-head"
                    style={{ fontWeight: "bold" }}
                  >
                    Age of Retirement
                  </td>
                  <td colSpan={2} className="table-head">
                    <input
                      id="number"
                      dir="rtl"
                      type="text"
                      maxLength={length}
                      size={length}
                      ref={estimate1_retirement_age}
                      defaultValue={retirementAgeDefaultValue[1]}
                    ></input>
                  </td>
                  <td colSpan={2} className="table-head">
                    <input
                      id="number"
                      dir="rtl"
                      type="text"
                      maxLength={length}
                      size={length}
                      ref={estimate2_retirement_age}
                      defaultValue={retirementAgeDefaultValue[2]}
                    ></input>
                  </td>
                  <td colSpan={2} className="table-head">
                    <input
                      id="number"
                      dir="rtl"
                      type="text"
                      maxLength={length}
                      size={length}
                      ref={estimate3_retirement_age}
                      defaultValue={retirementAgeDefaultValue[3]}
                    ></input>
                  </td>
                </tr>
              )}
              {toggleTotalCalculation ? (
                <tr>
                  <td
                    colSpan={2}
                    className="table-head"
                    style={{ fontWeight: "bold" }}
                  >
                    Estimated Account Balance at time of Distribution
                  </td>
                  <td
                    colSpan={2}
                    className="table-head"
                    style={{ fontWeight: "bold" }}
                  >
                    ${getRoundedValue(Number(estimate1CalculatedValue), 2)}
                  </td>
                  <td
                    colSpan={2}
                    className="table-head"
                    style={{ fontWeight: "bold" }}
                  >
                    ${getRoundedValue(Number(estimate2CalculatedValue), 2)}
                  </td>
                  <td
                    colSpan={2}
                    className="table-head"
                    style={{ fontWeight: "bold" }}
                  >
                    ${getRoundedValue(Number(estimate3CalculatedValue), 2)}
                  </td>
                </tr>
              ) : (
                ""
              )}
            </tbody>
          </table>
        </div>
      }

      <div style={{ textAlign: "right" }}>
        <button
          style={{ background: "#c80044", marginRight: "10px" }}
          className="btn btn-primary"
          onClick={() => clearInputField()}
        >
          Clear
        </button>
        <button
          style={{ background: "#a4a497" }}
          className="btn btn-primary"
          onClick={() => calculateEstimatedBenefitProjection()}
        >
          Calculate
        </button>
      </div>

      <div className="util-margin-top-15">
        <span>
          The amounts listed on the website represent a measure of your benefits
          under a nonqualified benefit agreement between you and your plan
          sponsor. It is not a representation that your plan sponsor holds any
          assets for paying these benefits.
        </span>

        <div className="util-margin-top-15">
          This calculator is available only as a general planning tool. Results
          depend on many factors, including the assumptions you provide. We do
          not guarantee their accuracy, or applicability to your circumstances.
          Please consult your attorney, accountant, financial or tax advisor
          with regard to your individual situation.
        </div>

        <div className="util-margin-top-15">
          *The assumed earnings rate is for illustrative purposes only and
          represents hypothetical investment returns. This program assumes your
          additional contributions will continue until retirement.
        </div>
      </div>
    </Page>
  );

  function clearInputField() {
    estimate1_annual_contribution!.current!.value = "0.00";
    setTotalCalculation(false);
    window.location.reload();
    setErrorMessage("");
  }

  function dateToIsoString(date: string) {
    if (date) {
      return new Date(date).toISOString().slice(0, 10);
    }
    return "";
  }

  function calculateEstimatedBenefitProjection() {
    const estimatedProjection = {
      projectionList: [
        {
          name: "estimateProjection1",
          averageAnnualContributionAmount:
            estimate1_annual_contribution?.current?.value,
          earningsRatePercent: estimate1_earning_rate?.current?.value,
          retirementAge: estimate1_retirement_age?.current?.value,
          payoutStartDate: dateToIsoString(
            estimate1_payout_date?.current?.value || ""
          ),
          participantAge: estimatedBenefitAccountInfo.currentAge,
          currentAccountBalanceAmount: currentAccountBalance,
          effectiveDate: dateToIsoString(effectiveLocaleDate),
          numberOfYearsUntilPayout: 0,
          estimatedAccountBalance: 0.0,
        },
        {
          name: "estimateProjection2",
          averageAnnualContributionAmount:
            estimate2_annual_contribution?.current?.value,
          earningsRatePercent: estimate2_earning_rate?.current?.value,
          retirementAge: estimate2_retirement_age?.current?.value,
          payoutStartDate: dateToIsoString(
            estimate2_payout_date?.current?.value || ""
          ),
          participantAge: estimatedBenefitAccountInfo.currentAge,
          currentAccountBalanceAmount: currentAccountBalance,
          effectiveDate: dateToIsoString(effectiveLocaleDate),
          numberOfYearsUntilPayout: 0,
          estimatedAccountBalance: 0.0,
        },
        {
          name: "estimateProjection3",
          averageAnnualContributionAmount:
            estimate3_annual_contribution?.current?.value,
          earningsRatePercent: estimate3_earning_rate?.current?.value,
          retirementAge: estimate3_retirement_age?.current?.value,
          payoutStartDate: dateToIsoString(
            estimate3_payout_date?.current?.value || ""
          ),
          participantAge: estimatedBenefitAccountInfo.currentAge,
          currentAccountBalanceAmount: currentAccountBalance,
          effectiveDate: dateToIsoString(effectiveLocaleDate),
          numberOfYearsUntilPayout: 0,
          estimatedAccountBalance: 0.0,
        },
      ],
      retirement: isRetirementAccount(getSelectedAccount()),
    };

    if (validate(estimatedProjection.projectionList)) {
      const base64EstimatedProjection =
        objectToBase64String(estimatedProjection);
      dispatch(
        fetchEstimatedCalculation({
          planId,
          empId,
          base64EstimatedProjection,
          role,
        })
      );
    }
  }
}
