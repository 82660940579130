import * as enterpriseRepository from "../repositories/enterprise";
import * as participantRepository from "../repositories/participant";

export async function getHeader(): Promise<string> {
  return enterpriseRepository.get("/theme/member/v2/header");
}

export async function getFooter(planId: number): Promise<string> {
  return participantRepository.get(
    "/enterprise/footer",
    {
      planId,
    },
    "text"
  );
}
