import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getPlanInfoSummary } from "src/services/plan-info-summary";
import { AsyncStatus } from "../../models/async-status";
import { PlanInfoSummaryResource } from "../../models/plan-info-and-summary/plan-info-and-summary";

export const fetchPlanInfoSummary = createAsyncThunk(
  "fetch-plan-info-summary",
  async (
    { planId, empId, role }: { planId: number; empId: number; role: string },
    thunkApi
  ) => {
    return getPlanInfoSummary(planId, empId, role);
  }
);

type State = {
  planInfoSummaryResource: PlanInfoSummaryResource;
  eligibleParticipant: Boolean;
  enrolledParticipant: Boolean;
  enrolledNewParticipant: Boolean;
  participantEnrolledNoContribution: Boolean;
  status: AsyncStatus;
};

const initialState: State = {
  status: AsyncStatus.not_fetched,
  eligibleParticipant: false,
  enrolledParticipant: false,
  enrolledNewParticipant: false,
  participantEnrolledNoContribution: false,
  planInfoSummaryResource: { toolLinks: [] },
};

const planInfoSummarySlice = createSlice({
  name: "plan-info-summary",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPlanInfoSummary.pending, (state, action) => {
      return { ...state, status: AsyncStatus.loading };
    });
    builder.addCase(fetchPlanInfoSummary.fulfilled, (state, action) => {
      return { ...state, ...action.payload, status: AsyncStatus.done };
    });
    builder.addCase(fetchPlanInfoSummary.rejected, (state, action) => {
      return {
        ...state,
        status: AsyncStatus.error,
      };
    });
    builder.addDefaultCase((state, action) => state);
  },
});

export default planInfoSummarySlice.reducer;
