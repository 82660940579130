import _get from "lodash/get";
import _sortBy from "lodash/sortBy";
import React from "react";
import {
  Bar,
  BarChart,
  Cell,
  LabelList,
  ResponsiveContainer,
  XAxis,
} from "recharts";
import CustomizedAxisTick from "../../../components/chart/customized-axis-tick";
import { getRoundedValue } from "../../../components/numbers/fixed-decimal";
import {
  Panel,
  PanelBody,
  PanelHeading,
} from "../../../components/panel/panel";
import { useInvestments } from "../../../selectors/investments";
import {
  aggregateByAssetClass,
  flatten,
} from "../../../util/investment-context-util";

export default function TotalInvestmentAllocationsByRisk(props) {
  const { investments } = useInvestments();

  const aggregatedInvestments = flatten(aggregateByAssetClass(investments));

  const defaultClasses = [
    {
      assetClass: "Short-Term Fixed Income",
      balance: 0,
      sortOrder: 1,
    },
    {
      assetClass: "Fixed Income",
      balance: 0,
      sortOrder: 2,
    },
    {
      assetClass: "Balanced/Asset Allocation",
      balance: 0,
      sortOrder: 3,
    },
    {
      assetClass: "Large U.S. Equity",
      balance: 0,
      sortOrder: 4,
    },
    {
      assetClass: "Small/Mid U.S. Equity",
      balance: 0,
      sortOrder: 5,
    },
    {
      assetClass: "International Equity",
      balance: 0,
      sortOrder: 6,
    },
    {
      assetClass: "Other",
      balance: 0,
      sortOrder: 7,
    },
  ];

  const tinyInvestments = aggregatedInvestments.map((investment) => ({
    assetClass: investment.assetClass,
    hexValue: investment.hexValue,
    balance: _get(investment, "endBalance.cashBalance.balance", 0),
  }));

  defaultClasses.forEach((investment) => {
    const matchingInvestment = tinyInvestments.find(
      (i) => i.assetClass === investment.assetClass
    );
    if (matchingInvestment) {
      investment.balance = matchingInvestment.balance;
      investment.hexValue = matchingInvestment.hexValue;
    }
  });
  const sortedInvestments = _sortBy(defaultClasses, "sortOrder");

  return (
    <div>
      <Panel className={"col-md-12"}>
        <PanelHeading>Investment allocations by asset type</PanelHeading>
        <PanelBody>
          <ContributionBreakdownChart sortedInvestments={sortedInvestments} />
        </PanelBody>
      </Panel>
    </div>
  );
}

function ContributionBreakdownChart(props) {
  const { sortedInvestments } = props;

  return (
    <ResponsiveContainer
      width="100%"
      height={300}
      className={"time-series-chart-wrapper"}
    >
      <BarChart
        data={sortedInvestments}
        margin={{
          bottom: 10,
          top: 20,
          left: 10,
          right: 10,
        }}
      >
        <XAxis
          dataKey="assetClass"
          tick={<CustomizedAxisTick />}
          interval={0}
          height={30}
        />
        <Bar dataKey="balance" isAnimationActive={false}>
          {sortedInvestments.map((entry) => (
            <Cell
              key={`cell-${entry.assetClass}`}
              fill={`#${entry.hexValue}`}
            />
          ))}
          <LabelList
            dataKey="balance"
            position="top"
            formatter={(label) => `$${getRoundedValue(label, 0)}`}
            fill={"#000000"}
          />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}
