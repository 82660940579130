import isEqual from "date-fns/isEqual";
import React from "react";

import { DataPointDisplay } from "../types/data-point";
import { PointFormatter } from "../types/point-formatter";
import { Styles } from "../types/styles";
import { moveToEnd } from "../util";
import { Point } from "./point";

export type PointsProps = {
  data: DataPointDisplay[];
  scale: Function;
  keyName: string;
  styles: Styles;
  handleSelect: Function;
  selectedDate?: Date;
  pointFormatter?: PointFormatter;
  groupName: string;
};

export function Points(props: PointsProps) {
  const {
    data,
    scale,
    keyName,
    styles,
    handleSelect,
    selectedDate,
    pointFormatter,
    groupName,
  } = props;

  const isSelected = (date: Date) => {
    if (!selectedDate) {
      return false;
    }
    return isEqual(selectedDate, date);
  };

  const sortedValues = moveToEnd(data, (point) => point.date === selectedDate);

  return (
    <g className="data-group">
      {sortedValues.map(({ date, values, color }) => (
        <Point
          key={date.toString()}
          date={date}
          values={values}
          scale={scale}
          keyName={keyName}
          styles={styles}
          handleSelect={handleSelect}
          isSelected={isSelected(date)}
          pointFormatter={pointFormatter}
          groupName={groupName}
          color={color}
        />
      ))}
    </g>
  );
}
