import React, { useEffect, useState } from "react";
import {
  getAccountId,
  getAccountName,
  getAmountTotal,
  getContributionsDate,
  getContributionsYear,
  getMoneyTypeName,
} from "../../pages/contributions/summary/contribution-sources/contribution-sources-util";
import { round } from "../../util/calculate";
import _groupBy from "lodash/groupBy";
import AccountName from "../account-name/account-name";
import { ContributionHistory } from "../../models/contributions";

type TableProps = {
  contributionHistories: ContributionHistory[][];
  isByAccount: boolean;
};

type YearProps = {
  contributionHistories: ContributionHistory[][][];
  isByAccount: boolean;
};

type AccountRowProps = {
  histories: ContributionHistory[];
  isByAccount: boolean;
  isExpanded: boolean;
};

type RowProps = {
  histories: ContributionHistory[];
  isByAccount: boolean;
};

export function CustomContributionsTable(props: TableProps) {
  const { contributionHistories, isByAccount } = props;
  const groupedByYear = Object.values(
    _groupBy(contributionHistories, (history) => {
      return extractYear(history[0].transactionDate);
    })
  );

  function extractYear(historyDate: string): string | undefined {
    if (typeof historyDate === "undefined") {
      return historyDate;
    } else {
      return historyDate.substring(6, 10);
    }
  }

  const headerText = isByAccount ? "Account" : "Contribution Source";
  return (
    <div className={"custom-table-wrapper"}>
      <table>
        <tbody>
          <tr>
            <th className="bold">{headerText}</th>
            <th className="bold">Amount ($)</th>
          </tr>
          <YearRowWrapper
            contributionHistories={groupedByYear}
            isByAccount={isByAccount}
          />
        </tbody>
      </table>
    </div>
  );
}

export function YearRowWrapper(props: YearProps) {
  const { contributionHistories, isByAccount } = props;
  return (
    <>
      {contributionHistories
        .slice()
        .reverse()
        .map((history, index) => {
          return (
            <SingleYearRow
              contributionHistories={history}
              isByAccount={isByAccount}
              key={index}
            />
          );
        })}
    </>
  );
}

export function SingleYearRow(props: TableProps) {
  const { contributionHistories, isByAccount } = props;
  const [isExpanded, setIsExpanded] = useState(false);

  function toggleExpanded() {
    setIsExpanded(!isExpanded);
  }

  useEffect(() => {
    setIsExpanded(false);
  }, [isByAccount]);

  const expandIcon = isExpanded ? (
    <i className={"fa fa-chevron-down expand-icon"} onClick={toggleExpanded} />
  ) : (
    <i className={"fa fa-chevron-right expand-icon"} onClick={toggleExpanded} />
  );
  return (
    <>
      <tr
        key={getContributionsYear(contributionHistories[0])}
        className="bold year-row"
      >
        <td className={"bold"}>
          <span className="icon-cell year-cell">{expandIcon}</span>
          <span>{getContributionsYear(contributionHistories[0])}</span>
        </td>
      </tr>
      {isExpanded && (
        <DateRows
          contributionHistories={contributionHistories}
          isByAccount={isByAccount}
        />
      )}
    </>
  );
}

export function DateRows(props: TableProps) {
  const { contributionHistories, isByAccount } = props;

  return (
    <>
      {contributionHistories.map((historiesByDate) => {
        return (
          <tr key={historiesByDate[0].transactionDate}>
            <td className="bold">
              {getContributionsDate(historiesByDate)}
              <table className="inner-table">
                <tbody>
                  <MoneyTypeRowWrapper
                    histories={historiesByDate}
                    isByAccount={isByAccount}
                  />
                </tbody>
              </table>
            </td>
            <td className="bold top-align">
              {getAmountTotal(historiesByDate)}
            </td>
          </tr>
        );
      })}
    </>
  );
}

export function AccountRows(props: AccountRowProps) {
  const { histories, isByAccount, isExpanded } = props;
  if (!isExpanded) {
    return null;
  }

  function buildAccountRows(
    contributionHistories: ContributionHistory[],
    isByAccount: boolean
  ) {
    return contributionHistories.map((history) => {
      return (
        <tr key={history.accountId}>
          <td>
            {isByAccount ? (
              history.moneyTypeName
            ) : (
              <AccountName
                name={history.accountName}
                id={history.accountId}
                asLink={false}
              />
            )}
          </td>
          <td>${round(history.transactionAmount)}</td>
        </tr>
      );
    });
  }

  return (
    <table>
      <tbody>{buildAccountRows(histories, isByAccount)}</tbody>
    </table>
  );
}

export function MoneyTypeRow(props: RowProps) {
  const { histories, isByAccount } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  const accountName = getAccountName(histories);
  const accountId = getAccountId(histories);

  function toggleExpanded() {
    setIsExpanded(!isExpanded);
  }

  const expandIcon = isExpanded ? (
    <i className={"fa fa-chevron-down expand-icon"} onClick={toggleExpanded} />
  ) : (
    <i className={"fa fa-chevron-right expand-icon"} onClick={toggleExpanded} />
  );

  useEffect(() => {
    setIsExpanded(false);
  }, [isByAccount]);

  if (isByAccount) {
    return (
      <>
        <tr>
          <td className="icon-cell top-align">{expandIcon}</td>
          <td>
            {<AccountName name={accountName} id={accountId} asLink={false} />}
            <table className="inner-table">
              <AccountRows
                histories={histories}
                isExpanded={isExpanded}
                isByAccount={isByAccount}
              />
            </table>
          </td>
        </tr>
      </>
    );
  }

  return (
    <>
      <tr>
        <td className="icon-cell top-align">{expandIcon}</td>
        <td>
          {getMoneyTypeName(histories)}
          <table className="inner-table">
            <AccountRows
              histories={histories}
              isExpanded={isExpanded}
              isByAccount={isByAccount}
            />
          </table>
        </td>
      </tr>
    </>
  );
}

export function MoneyTypeRowWrapper(props: RowProps) {
  const { histories: contributionHistories, isByAccount } = props;
  if (!isByAccount) {
    const historiesByMoneyType = Object.values(
      _groupBy(contributionHistories, "moneyTypeName")
    );
    return (
      <>
        {historiesByMoneyType.map((histories) => {
          return (
            <MoneyTypeRow histories={histories} isByAccount={isByAccount} />
          );
        })}
      </>
    );
  }
  const historiesByAccount = Object.values(
    _groupBy(contributionHistories, "accountName")
  );

  return (
    <>
      {historiesByAccount.map((histories) => {
        return <MoneyTypeRow histories={histories} isByAccount={isByAccount} />;
      })}
    </>
  );
}
