import React from "react";

export default function AssetClass(props) {
  const { classId, assetClass, hexValue, description } = props.assetClass;
  const { index } = props;
  return (
    <div
      className="asset-class-row"
      style={{
        borderLeft: `16px solid #${hexValue}`,
      }}
    >
      <h3 data-testid={`${assetClass}-${index}`}>{assetClass}</h3>
      <p data-testid={`${classId}-description`}>{description}</p>
    </div>
  );
}
