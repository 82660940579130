import { get } from "../repositories/participant";
import { AccountAllocation } from "../models/account-allocation";

type Args = {
  planId: number;
  empId: number;
  role: string;
};

export async function getInvestmentAllocations({
  planId,
  empId,
  role,
}: Args): Promise<AccountAllocation[]> {
  return get(`/plans/${planId}/employees/${empId}/allocations/investments`, {
    role,
  });
}
