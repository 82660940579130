import React, { useState } from "react";
import { useSelector } from "../../../store/store";
import ContributionsAccordion from "./contributions-accordion";
import ContributionsReviewSubmitButton from "./contributions-review-submit-button";
import { formatDateWithoutTimeZone } from "../../../util/date-util";

type Props = {
  register: (options: any) => React.Ref<any>;
  errors: any;
  allFields: any;
  setValue: Function;
};

export default function ManageContributionsFormInputs(props: Props) {
  const { register, errors, allFields, setValue } = props;
  const [errorCount, setErrorCount] = useState(0);
  const contributionRates = useSelector(
    (state) => state.contributions.contributionRates.data
  );
  const { contributionEffectiveDate } = useSelector((state) => state.plan.info);

  let dateFormatted = "";
  if (contributionEffectiveDate) {
    dateFormatted = formatDateWithoutTimeZone(
      contributionEffectiveDate,
      "MM/dd/yyyy"
    );
  }

  const increaseErrorCount = () => {
    setErrorCount(errorCount + 1);
  };

  const decreaseErrorCount = () => {
    if (errorCount > 0) {
      setErrorCount(errorCount - 1);
    }
  };

  return (
    <>
      <div className="manage-contribution-inputs">
        {errorCount > 0 ? (
          <div className="row">
            <div className="col-xs-12">
              <div className="alert alert-danger alert-icon" role="alert">
                There{" "}
                {errorCount > 1 ? `are ${errorCount} problems` : "is a problem"}{" "}
                on the form below that need to be addressed.
              </div>
            </div>
          </div>
        ) : (
          <div></div>
        )}
        {contributionRates.map((contribution, index) => {
          const compensationName = contribution.contributionTypeName;
          const compensationType = allFields[`${compensationName}Type`];
          return (
            <ContributionsAccordion
              key={compensationName}
              index={index}
              compensationInfo={contribution}
              register={register}
              errors={errors}
              setValue={setValue}
              compensationType={compensationType}
              increaseErrorCount={increaseErrorCount}
              decreaseErrorCount={decreaseErrorCount}
            />
          );
        })}
      </div>
      <br />
      <div className="pull-right">
        <ContributionsReviewSubmitButton
          isReview={false}
          onSubmit={() => {
            console.log("");
          }}
        />
      </div>
      <br />
      <br />
      <br />
      <p className="effective-date pull-right">
        These changes will become effective {dateFormatted}.
      </p>
    </>
  );
}
