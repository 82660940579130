import React, { useContext, useEffect, useState } from "react";
import AccountBalanceCard from "../../components/account-balance-card/account-balance-card";
import { OpenEventCard } from "../../components/enrollment/open-event-card";
import SectionWithLinks from "../../components/landing/section-with-links";
import { Page } from "../../components/page";
import { PlanHeaderTransition } from "../../components/plan-header/plan-header-transition";
import { EnrollmentContext } from "../../contexts/enrollment-context/enrollment-context";
import { AsyncStatus } from "../../models/async-status";
import { useInvestments } from "../../selectors/investments";
import { useSelector } from "../../store/store";
import { isBefore, parseDate } from "../../util/date-util";
import {
  buildLinkForNewOverviewBenefitPage,
  buildModernDashboardLink,
  buildPensionContextLink,
} from "../../util/nq-participant-link-builder";
import {
  investmentsStatusSelector,
  planInfoStatusSelector,
  sessionStatusSelector,
  useStatuses,
} from "../../selectors/status";
import { SectionWithLink } from "src/components/landing/sectionWithLink";

const learnLinks: SectionWithLink[] = [
  {
    name: "See what you can do online",
    url: "https://bcove.video/2hDRJAJ",
    isExternal: true,
  },
  {
    name: "Plan to retire on your own terms",
    url: "https://players.brightcove.net/1510329454001/a4d923de-cccc-4a40-9756-31e8368d530d_default/index.html?videoId=5719309857001",
    isExternal: true,
  },
  {
    name: "Calculate your estimated deferral percentage",
    url: "https://nq.individuals.principal.com/nq-compensation-planner/",
    isExternal: true,
  },
  {
    name: "Hear why other key employees participate",
    url: "https://players.brightcove.net/1510329454001/a4d923de-cccc-4a40-9756-31e8368d530d_default/index.html?videoId=5702261030001",
    isExternal: true,
  },
];

export default function Landing() {
  const {
    empId,
    status: sessionStatus,
    role,
    planId,
  } = useSelector((state) => state.session);
  const { transitionPageEnabled, transitionPageEndDate, status } = useSelector(
    (state) => state.plan.config
  );
  const {
    contractId,
    planCode,
    status: infoStatus,
  } = useSelector((state) => state.plan.info);
  const { openEvents } = useContext(EnrollmentContext);
  const { isLoadingStatus } = useStatuses([
    investmentsStatusSelector,
    sessionStatusSelector,
    planInfoStatusSelector,
  ]);
  const { investments } = useInvestments();
  const [showPage, setShowPage] = useState(false);

  const helpLinks: SectionWithLink[] = [
    {
      name: "Set or review investment option",
      url: "investments/manage",
      isExternal: false,
    },
    {
      name: "Add or review beneficiary",
      url: "overview/beneficiaries",
      isExternal: false,
    },
    {
      name: "View plan documents",
      url: buildPensionContextLink(empId, contractId, "planInfoFormsSummary"),
      isExternal: false,
    },
    {
      name: "Get the mobile app",
      url: "https://www.principal.com/individuals/principal-app",
      isExternal: true,
    },
  ];

  const accountLink: SectionWithLink[] = [
    {
      name: "Take me there",
      help_text:
        "If you're feeling ambitious, check out your account overview page to see your balance and other retirement resources.",
      url: buildModernDashboardLink(),
      isExternal: false,
    },
  ];

  useEffect(() => {
    setContext();

    function setSessionStorage() {
      sessionStorage.setItem(
        "session_string",
        JSON.stringify({
          memberId: empId,
          planId: planId,
          userType: role,
        })
      );
    }

    async function setContext() {
      if (
        status === AsyncStatus.done &&
        sessionStatus === AsyncStatus.done &&
        infoStatus === AsyncStatus.done
      ) {
        setSessionStorage();

        if (planCode.toUpperCase() === "DB") {
          window.location.assign(
            buildLinkForNewOverviewBenefitPage(planId, empId, contractId, role)
          );
          return;
        }
        const transitionPageDate = parseDate(transitionPageEndDate);
        const transitionPageDateExpired = isBefore(
          transitionPageDate,
          new Date()
        );
        if (!transitionPageEnabled || transitionPageDateExpired) {
          window.location.assign(buildModernDashboardLink());
        } else {
          setShowPage(true);
        }
      }
    }
  }, [
    empId,
    planId,
    contractId,
    planCode,
    role,
    sessionStatus,
    status,
    transitionPageEnabled,
    transitionPageEndDate,
    isLoadingStatus,
    infoStatus,
  ]);

  return (
    <Page
      name="transition-landing"
      className="transition-landing"
      loading={isLoadingStatus || !showPage}
      data-testid="transition-landing-page"
    >
      <PlanHeaderTransition />
      <div className="row">
        <div className="col-md-12">
          {openEvents.length > 0 ? (
            <hr className="dotted" role="presentation" />
          ) : null}
          {openEvents.map((event) => (
            <OpenEventCard key={event.eventId} event={event} />
          ))}
          <AccountBalanceCard investments={investments} />
          <hr className="dotted" role="presentation" />
          <SectionWithLinks
            title={"Working toward your savings goals"}
            links={helpLinks}
            icon="flag"
          />
          <hr className="dotted" role="presentation" />
          <SectionWithLinks
            title={"Account access"}
            links={accountLink}
            icon="edit"
          />
          <hr className="dotted" role="presentation" />
          <SectionWithLinks
            title={"Learn more"}
            links={learnLinks}
            icon="graduation-cap"
          />
        </div>
      </div>
    </Page>
  );
}
