import React from "react";

type DisplayBoxProps = {
  rectX: number;
  point: {
    height: number;
    width: number;
    stemHeight: number;
    fontSize: number;
  };
  rectY: number;
  isSelected: boolean;
  lineX: any;
  textY: number;
  textClassName: string;
  value: any;
  yOffset: number;
  color: string | undefined;
};

export function DisplayBox(props: DisplayBoxProps) {
  const {
    rectX,
    point,
    rectY,
    isSelected,
    lineX,
    textY,
    textClassName,
    value,
    yOffset,
    color,
  } = props;

  return (
    <>
      <rect
        x={rectX}
        width={point.width}
        height={point.height}
        y={rectY - yOffset}
        stroke={color}
      />
      {isSelected && (
        <rect
          x={rectX}
          width={4}
          height={point.height}
          y={rectY - yOffset}
          className="accent"
        />
      )}
      <text
        x={lineX}
        y={textY - yOffset}
        alignmentBaseline="middle"
        className={textClassName}
        fill={color}
      >
        {value}
      </text>
    </>
  );
}
