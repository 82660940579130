import React from "react";
import { FormInput } from "../../../components/form/form-input";
import Balance from "../../../components/numbers/balance";
import ManageContributionsPayCheckFrequencySelector from "./manage-contributions-pay-frequency-selector";
import {
  calculatePaycheckAmount,
  getPayFrequencyText,
} from "./manage-contributions-utilities";

type Props = {
  register: (options: any) => React.Ref<any>;
  errors: any;
  allFields: any;
};

ContributionSalaryInteraction.defaultProps = {};

export default function ContributionSalaryInteraction(
  props: Props
): JSX.Element {
  const { register, errors, allFields } = props;
  const salaryAmount = allFields.currentSalary;
  const payCheckFrequency = allFields.payCheckFrequency;
  const payCheckAmount = calculatePaycheckAmount(
    salaryAmount,
    payCheckFrequency
  );

  const payCheckFrequencyText = getPayFrequencyText(payCheckFrequency);

  return (
    <>
      <p>
        <b>Contribution calculator</b>
      </p>
      <p>
        To estimate your deferrals, input your current salary and your
        frequency.{" "}
        <i>Note that these inputs are just for estimation purposes. </i>
      </p>
      <div className="salary-form">
        <FormInput
          register={register}
          name="currentSalary"
          text="Current salary"
          errors={errors}
          data-testid="currentSalary"
          type="number"
        />
        <ManageContributionsPayCheckFrequencySelector register={register} />
      </div>

      <div className="manage-contributions-spacing">
        Est. <Balance value={payCheckAmount} showDollar />{" "}
        {payCheckFrequencyText}
      </div>
    </>
  );
}
