import React from "react";
import TableV2 from "../../../../components/table/tableV2";
import {
  buildContributionType,
  buildCurrentDeferralElectionsColumn,
  buildNewDeferralElectionsColumn,
} from "./deferal-election-util";

type Props = {
  data?: any;
};

export default function PendingDeferralElectionTable(props: Props) {
  const { data } = props;
  const defaultColumns = [
    buildContributionType(),
    buildCurrentDeferralElectionsColumn(),
    buildNewDeferralElectionsColumn(),
  ];

  return <TableV2 columns={defaultColumns} data={data} />;
}
