import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/cjs/Button";
import {
  ContributionRates,
  PendingContributionRate,
} from "../../../../models/contributions";
import { useAppDispatch, useSelector } from "../../../../store/store";
import {
  createDeferralElectionDisplay,
  deferralButtonCheck,
  DeferralElectionsDisplay,
  pendingContributionCheck,
} from "./deferal-election-util";
import DeferralElectionTable from "./deferral-election-table";
import {
  PlanCode,
  PlanInfoModel,
} from "../../../../models/plan/plan-info-model";
import { buildManageContributionsLink } from "../../../../util/nq-participant-link-builder";
import PendingDeferralElections from "./pending-deferral-elections";
import PendingDeferralSuccess from "./pending-deferral-success";
import { fetchContributionRates } from "../../../../reducers/contributions/contribution-rates";

type Props = {};

export default function DeferralElections(props: Props) {
  const dispatch = useAppDispatch();
  const contributionRates: ContributionRates[] = useSelector(
    (state) => state.contributions.contributionRates.data
  );
  const [showDeferralModal, setDeferralModal] = useState(false);
  const [showDeferralSuccessModal, setDeferralSuccessModal] = useState(false);
  const planInfo: PlanInfoModel = useSelector((state) => state.plan.info);
  const { session } = useSelector((state) => state);
  const pendingContributions: PendingContributionRate[] = useSelector(
    (state) => state.contributions.pendingContributions.data
  );
  const { planId, empId, role } = useSelector((state) => state.session);
  const availableAccounts = useSelector((state) => state.accounts.available);

  const hasAvailableAccounts = availableAccounts.length > 0;
  const is457bPlan = planInfo.planCode.toLowerCase() === PlanCode.type457b;
  const shouldShowDeferralElectionButton = is457bPlan
    ? hasAvailableAccounts
    : true;

  useEffect(() => {
    dispatch(fetchContributionRates({ planId, empId, role }));
  }, [dispatch, planId, empId, role]);

  const deferralElectionDisplay: DeferralElectionsDisplay[] =
    createDeferralElectionDisplay(contributionRates);
  const showDeferralButton = deferralButtonCheck(
    planInfo,
    pendingContributions
  );
  const showPendingContributionLink = pendingContributionCheck(
    planInfo,
    pendingContributions
  );

  function openPendingDeferralSuccess() {
    setDeferralSuccessModal(true);
    setDeferralModal(false);
  }

  function openPendingDeferral() {
    setDeferralModal(true);
  }

  function reloadDeferrals() {
    setDeferralSuccessModal(false);
    window.location.reload();
  }

  return (
    <div className="deferral-elections">
      <h2 className="h4">Your Deferral Election(s)</h2>
      {showPendingContributionLink && (
        <p>
          <Button className="pending-btn" onClick={openPendingDeferral}>
            <i className="fa fa-info-circle" />
            You have pending deferral elections
          </Button>
        </p>
      )}

      {showDeferralModal && (
        <PendingDeferralElections
          showDeferralModal={showDeferralModal}
          hideDeferralModal={() => setDeferralModal(false)}
          showDeferralSuccessModal={() => openPendingDeferralSuccess()}
        />
      )}

      {showDeferralSuccessModal && (
        <PendingDeferralSuccess
          showDeferralSuccessModal={showDeferralSuccessModal}
          deferralEffectiveDate={pendingContributions[0].deferralEffectiveDate}
          session={session}
          hideDeferralSuccessModal={() => reloadDeferrals()}
        />
      )}

      <DeferralElectionTable data={deferralElectionDisplay} />
      {showDeferralButton && shouldShowDeferralElectionButton && (
        <a
          type="button"
          className="btn btn-primary distribution-button"
          href={buildManageContributionsLink(session)}
        >
          Change Your Deferral Election(s)
        </a>
      )}
    </div>
  );
}
