import React from "react";
import TableV2 from "../../../../components/table/tableV2";
import {
  buildContributionCurrent,
  buildContributionSinceInception,
  buildContributionSources,
  buildContributionThisYear,
  buildContributionVested,
} from "./contribution-amounts-util";

type Props = {
  data?: any;
  isByAccount: boolean;
};

export default function ContributionAmountsTable(props: Props) {
  const { data, isByAccount } = props;
  const defaultColumns = [
    buildContributionSources(isByAccount),
    buildContributionThisYear(),
    buildContributionSinceInception(),
    buildContributionCurrent(),
    buildContributionVested(),
  ];

  return (
    <>
      <TableV2
        columns={defaultColumns}
        data={data}
        tableName="contribution-amounts-table"
      />
    </>
  );
}
