import React from "react";
import TableV2 from "../../../../components/table/tableV2";
import {
  buildEndBalanceColumn,
  buildFiveYearColumn,
  buildGrossExpenseRatioColumn,
  buildHexValueColumn,
  buildInceptionDateColumn,
  buildInstitutionNameColumn,
  buildLastFullMonthColumn,
  buildNavChangeDollarColumn,
  buildNavChangePercentColumn,
  buildNavColumn,
  buildNavYTDChangeDollarColumn,
  buildOneYearColumn,
  buildSharePriceColumn,
  buildSinceInceptionColumn,
  buildTenYearColumn,
  buildThreeMonthColumn,
  buildThreeYearColumn,
  buildYearToDateColumn,
} from "./build-columns-utils";

LoadingPerformanceTable.defaultProps = {
  hasFooter: true,
};

type Props = { showFundPrices: boolean };

function LoadingPerformanceTable(props: Props) {
  const historyType = "m";
  const defaultColumns = props.showFundPrices
    ? [
        buildHexValueColumn(),
        buildInstitutionNameColumn(),
        buildEndBalanceColumn(),
        buildSharePriceColumn(),
        buildNavColumn(),
        buildNavChangeDollarColumn(),
        buildNavChangePercentColumn(),
        buildNavYTDChangeDollarColumn(),
        buildYearToDateColumn(historyType),
      ]
    : [
        buildHexValueColumn(),
        buildInstitutionNameColumn(),
        buildEndBalanceColumn(),
        buildSharePriceColumn(),
        buildYearToDateColumn(historyType),
        buildLastFullMonthColumn(historyType),
        buildThreeMonthColumn(historyType),
        buildOneYearColumn(historyType),
        buildThreeYearColumn(historyType),
        buildFiveYearColumn(historyType),
        buildTenYearColumn(historyType),
        buildSinceInceptionColumn(historyType),
        buildInceptionDateColumn(),
        buildGrossExpenseRatioColumn(historyType),
      ];
  return (
    <div className={`custom-table-wrapper`}>
      <TableV2
        columns={defaultColumns}
        data={[{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}]}
      />
    </div>
  );
}

export default LoadingPerformanceTable;
