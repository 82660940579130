import { EstimatedProjectionRequest } from "src/models/enrollment-tools/estimated-projection-request";
import { get } from "../repositories/participant";

export async function calculateEstimatedBenefit(
  planId: number,
  empId: number,
  estimatedProjection: string,
  role: string
): Promise<EstimatedProjectionRequest> {
  return get(
    `/plans/${planId}/employees/${empId}/calculate-estBenefit-projections`,
    { estimatedProjection, role }
  );
}
