import { PlanningCenterTools } from "../models/planning-center-overview/planning-center-tools";
import { get } from "../repositories/participant";

export async function getPlanningCenterTools(
  planId: number,
  empId: number,
  role: string
): Promise<PlanningCenterTools> {
  return get(`/plans/${planId}/employees/${empId}/planning-center-tools`, {
    role,
  });
}
