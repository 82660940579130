import { ScheduledDistributionForRedeferral } from "../../../models/distributions/scheduled-distribution-event";
import React from "react";
import RedeferTableRow from "./redefer-table-row";
import "./redefer-individual-table.scss";

export interface Props {
  scheduledDistributions: ScheduledDistributionForRedeferral[];
  editable: boolean;
  error?: string;
}

export default function RedeferIndividualTable(props: Props) {
  const { scheduledDistributions, editable, error } = props;

  function buildHeaderColumn(name: string) {
    return (
      <th>
        <span className="header">{name}</span>
      </th>
    );
  }

  function buildTableHeaders() {
    return (
      <thead>
        <tr>
          {buildHeaderColumn("Installment")}
          {buildHeaderColumn("Distribution date")}
          {buildHeaderColumn("Pending distribution date")}
          {buildHeaderColumn("Status")}
          {buildHeaderColumn("Estimated distribution")}
          {buildHeaderColumn("Redefer this distribution")}
          {buildHeaderColumn("New distribution date")}
        </tr>
      </thead>
    );
  }

  return (
    <>
      <div
        className="custom-table-wrapper redefer-table"
        data-testid="redefer-individual-table"
      >
        {error && (
          <div className="row text-danger" data-testid="redefer-errors">
            <div className="col-md-12">{error}</div>
          </div>
        )}
        <table className="">
          {buildTableHeaders()}
          <tbody>
            {scheduledDistributions.map((scheduledDistribution) => {
              return (
                <RedeferTableRow
                  key={scheduledDistribution.installNum}
                  editable={editable}
                  scheduledDistribution={scheduledDistribution}
                />
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}
