import '@principal/design-system-icons-web/chevron-down';
import { html, nothing } from 'lit';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { property, query, state } from 'lit/decorators.js';
import debounce from 'debounce';
import { ifDefined } from 'lit/directives/if-defined.js';
import { pdsCustomElement as customElement } from '../../decorators/pds-custom-element';
import { PdsFormElement } from '../pds-form-element/PdsFormElement';
import styles from './select.scss?inline';

/**
 * @summary This component renders a styled select input component.
 *
 * @slot label Optional: Use this slot instead of the label property, if the label requires additional markup.
 * @slot help-text Optional: Use this slot instead of the helpText property, if the help text requires additional markup.
 * @slot default Required: Use this slot to contain the children <option> elements.
 */
@customElement('pds-select', {
  category: 'component',
  type: 'component',
  state: 'stable',
  styles,
})
export class PdsSelect extends PdsFormElement {
  /**
   * Style variant
   * - **default** renders the checkbox using no background
   * - **inverted** renders the checkbox using the inverted background token
   */
  @property()
  variant: 'inverted' | 'default' = 'default';

  /**
   * Size
   * -**sm** renders a the small version of the Select
   */
  @property()
  size: 'sm' | 'default' = 'default';

  /**
   * Standard input autocomplete attribute.
   * See [HTML attribute: autocomplete](https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete)
   */
  @property()
  autocomplete: 'off' | 'on';

  @property({ type: String })
  placeholder?: string;

  /** @internal */
  @state()
  childNodeObserver: any;

  /** @internal */
  @state()
  childrenHTML: any;

  @query('select')
  field: HTMLSelectElement;

  /**
   * Set the selected option based on the current value
   */
  protected override updateField() {
    // for loop for the case where a browser doesn't make a collection iterable
    for (let i = 0; i < this.field?.options?.length; i += 1) {
      const option = this.field.options[i];

      if (option.value === (this.internalValue || '')) {
        option.selected = true;
        break;
      }
    }
  }

  override firstUpdated() {
    super.firstUpdated();
    this.updateChildrenHTML();
    this.observeChildNodes();
  }

  updated() {
    this.updateField();
  }

  private handleBlur() {
    this.dispatchEvent(
      new CustomEvent('pds-select-blur', {
        bubbles: false,
        composed: true,
        detail: {
          summary: this.value,
        },
      }),
    );
  }

  private handleChange() {
    this.value = this.field.value;
    // Update options if they've changed
    this.updateChildrenHTML();
    this.dispatchEvent(
      new CustomEvent('pds-select-change', {
        bubbles: true,
        composed: true,
        detail: {
          summary: this.value,
        },
      }),
    );
  }

  private handleFocus() {
    this.dispatchEvent(
      new Event('pds-select-focus', { bubbles: false, composed: true }),
    );
  }

  /**
   * Check if an option exists in the select
   */
  selectContainsOption(select: HTMLSelectElement, value: string) {
    return (
      Array.from(select.options).filter((option) => option.value === value)
        .length > 0
    );
  }

  /**
   * Callback function for the MutationObserver to handle updates
   * when child nodes change. This function updates the children HTML
   * and ensures the select field is updated appropriately.
   */
  private async childNodeObserverCallback() {
    this.updateChildrenHTML();
    await this.updateComplete;

    // check if the value still is a valid option in the select and set it
    if (this.selectContainsOption(this.field, this.value)) {
      this.updateField();
    } else {
      this.formResetCallback();
      this.handleChange();
    }
  }

  /**
   * Updates the childrenHTML state with the current child nodes
   * of the select component. This is used to re-render the select options.
   */
  private updateChildrenHTML() {
    this.childrenHTML = Array.from(this.children).map((child: Element) => {
      return html`${unsafeHTML(child.outerHTML)}`;
    });
  }

  /**
   * Observes changes to child nodes (options) using MutationObserver
   * and triggers the childNodeObserverCallback to handle these changes.
   * This ensures the select component remains in sync with its options.
   */
  private observeChildNodes() {
    const debouncedCallback = debounce(
      () => this.childNodeObserverCallback(),
      100,
    );

    this.childNodeObserver = new MutationObserver((mutationList) => {
      mutationList.forEach((mutation) => {
        const mutationTargetHtmlElement = mutation.target as HTMLElement;
        const mutationAddedNode = mutation.addedNodes[0] as HTMLElement;
        const mutationRemovedNode = mutation.removedNodes[0] as HTMLElement;

        if (
          mutationTargetHtmlElement &&
          mutationTargetHtmlElement.tagName &&
          mutationTargetHtmlElement.tagName.toLowerCase() === 'option'
        ) {
          // option attribute modified
          debouncedCallback();
        } else if (
          mutationTargetHtmlElement &&
          mutationTargetHtmlElement.parentElement &&
          mutationTargetHtmlElement.parentElement.tagName &&
          mutationTargetHtmlElement.parentElement.tagName.toLowerCase() ===
            'option'
        ) {
          // option text modified
          debouncedCallback();
        } else if (
          mutation.addedNodes &&
          mutationAddedNode &&
          mutationAddedNode.tagName &&
          mutationAddedNode.tagName.toLowerCase() === 'option'
        ) {
          // new option added
          debouncedCallback();
        } else if (
          mutation.removedNodes &&
          mutationRemovedNode &&
          mutationRemovedNode.tagName &&
          mutationRemovedNode.tagName.toLowerCase() === 'option'
        ) {
          // option removed
          debouncedCallback();
        }
      });
    });

    const config = {
      childList: true,
      subtree: true,
      attributes: true,
      characterData: true,
    };
    this.childNodeObserver.observe(this, config);
  }

  /** @internal */
  get classNames() {
    return {
      [`${this.variant}`]: !!this.variant,
      [this.size]: !!this.size,
      'is-error': !!this.errorMessage,
      'is-disabled': this.disabled,
      'hidden-label': this.hideLabel,
    };
  }

  /**
   * Creates an HTML template for placeholder,
   * if the `placeholder` prop is defined.
   * Adds a hidden and disabled attribute
   * when this select is required.
   *
   * @returns an HTML template for placeholder or nothing
   */
  protected placeholderTemplate() {
    // Set prettier ignore to not enforce extra white space around the placeholder text.
    // prettier-ignore
    return this.placeholder
      ? html`<option id="placeholder"
          value=""
          ?hidden=${this.required}
          ?disabled=${this.required}
        >${this.placeholder}</option>`
      : nothing;
  }

  render() {
    if (!this.verifyLabel()) {
      return nothing;
    }

    return html`<div class="${this.getClass()}">
      ${this.labelTemplate()} ${this.helpTextTemplate()}
      <div class="${this.classEl('wrapper')}">
        <select
          class="${this.classEl('select')}"
          id="${this.fieldId || `${this.name}-${this.randomId}-field`}"
          ?disabled=${this.disabled}
          ?required=${this.required}
          autocomplete="${ifDefined(this.autocomplete)}"
          aria-describedby=${this.getAriaDescribedBy() || nothing}
          aria-invalid=${this.errorMessage ? 'true' : nothing}
          name="${this.name}"
          @change=${this.handleChange}
          @focus=${this.handleFocus}
          @blur=${this.handleBlur}
        >
          ${this.placeholderTemplate()} ${this.childrenHTML}
        </select>
        <span class="${this.classEl('down')}">
          <pds-icon-chevron-down size="default"></pds-icon-chevron-down>
        </span>
      </div>
      ${this.errorMessageTemplate()}
    </div>`;
  }
}
