import classnames from "classnames";
import React, { useState } from "react";
import AccountName from "../account-name/account-name";

type AccordionProps = {
  id: number;
  name?: string;
  children: React.ReactNode;
  optionalNameInfo: JSX.Element;
};

Accordion.defaultProps = {
  optionalNameInfo: <></>,
};

export function Accordion(props: AccordionProps) {
  return (
    <div data-testid="accordion" className="account-summary">
      {props.children}
    </div>
  );
}

export function AccordionDropdown(props: {
  id: number;
  name?: string | React.ReactNode;
  classNames?: string[];
  children?: React.ReactNode;
}) {
  const { id, name, children, classNames } = props;

  const classes = classnames(["accordion-header", classNames]);
  const className = id === 0 ? "" : "collapsed";
  return (
    <div className={classes}>
      <a
        data-testid="accordion-dropdown"
        data-toggle="collapse"
        href={`#collapse-${id}`}
        aria-expanded="false"
        className={className}
        aria-controls={`collapse-${id}`}
        style={{ flex: "0 0 30%" }}
      >
        <div className="account-header-div">
          <i className="fa fa-caret-down transformable" aria-hidden="true" />
          <div className="account-header distribution-table-cells">
            {typeof name === "string" ? (
              <AccountName name={name} id={id} asLink={false} />
            ) : (
              name
            )}
          </div>
        </div>
      </a>
      {children}
    </div>
  );
}

export function AccordionDropdownStacked(props: {
  id: number;
  name?: string | React.ReactNode;
  classNames?: string[];
  children?: React.ReactNode;
}) {
  const { id, name, children, classNames } = props;

  const classes = classnames(["accordion-header", classNames]);
  return (
    <div className={classes}>
      <a
        data-testid="accordion-dropdown"
        data-toggle="collapse"
        href={`#collapse-${id}`}
        aria-expanded="false"
        className={"collapsed col-xs-12 col-sm-6 col-md-3"}
        aria-controls={`collapse-${id}`}
        style={{}}
      >
        <div className="account-header">
          <i className="fa fa-caret-down transformable" aria-hidden="true" />

          {typeof name === "string" ? (
            <AccountName name={name} id={id} asLink={false} />
          ) : (
            name
          )}
        </div>
      </a>
      {children}
    </div>
  );
}

export function AccordionWell(props: {
  id: number | string;
  children: React.ReactNode;
  classNames?: string[];
}) {
  const { id, children, classNames } = props;

  const classes = classnames(["accordion-header", classNames]);

  const className = id === 0 ? "collapse in" : "collapse";

  return (
    <div
      className={className}
      id={`collapse-${id}`}
      data-testid="accordion-well"
    >
      <div className={classes}>{children}</div>
    </div>
  );
}

type ReusableAccordionProps = {
  id: string | number;
  collapsedName: string;
  expandedName: string;
  classNames?: string[];
  children?: React.ReactNode;
};

export function ReusableAccordionDropdown(props: ReusableAccordionProps) {
  const { id, collapsedName, expandedName, children, classNames } = props;
  const [collapsed, setCollapsed] = useState(true);
  const name = collapsed ? collapsedName : expandedName;

  const classes = classnames(["accordion-header", classNames]);

  return (
    <div data-testid={`accordion-header-${id}`} className={classes}>
      <a
        data-testid="accordion-dropdown"
        data-toggle="collapse"
        href={`#collapse-${id}`}
        aria-expanded="false"
        aria-controls={`collapse-${id}`}
        style={{ flex: "0 0 30%" }}
      >
        <span
          data-testid={`accordion-name-${id}`}
          onClick={() => setCollapsed(!collapsed)}
        >
          {name}
        </span>
      </a>
      {children}
    </div>
  );
}
