import React, { Dispatch, SetStateAction, useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { FormInput } from "../../../components/form/form-input";
import RelationshipTypeSelect from "../../../components/select-inputs/relationship-select";
import {
  BeneficiaryInfo,
  BeneficiaryType,
  defaultBeneficiaryInfo,
} from "../../../models/beneficiaries/beneficiary";
import { BeneficiaryState } from "../../../reducers/beneficiary-reducer";
import { useSelector } from "../../../store/store";
import BeneficiaryDetails from "./beneficiary-details";
import { ModalAction } from "./beneficiary-modal";
import BeneficiaryTypeToggle from "./beneficiary-type-toggle";

type Props = {
  beneType: BeneficiaryType;
  setBeneType: Dispatch<SetStateAction<BeneficiaryType>>;
  beneficiaryInfo?: BeneficiaryInfo;
  onSubmit: SubmitHandler<BeneficiaryInfo>;
  action: ModalAction;
};

export default function BeneficiaryForm(props: Props) {
  const { beneType, setBeneType, onSubmit, action, beneficiaryInfo } = props;
  const { editing }: BeneficiaryState = useSelector(
    (state) => state.beneficiaries
  );
  const defaultValues = beneficiaryInfo
    ? beneficiaryInfo
    : defaultBeneficiaryInfo;
  const { register, handleSubmit, watch, errors, formState, setValue, reset } =
    useForm<BeneficiaryInfo>({
      defaultValues: defaultValues,
    });

  useEffect(() => {
    if (!editing) {
      reset(defaultValues);
    }
  }, [defaultValues, editing, reset]);

  const allFields = watch();
  const addingOrEditingBeneficiary = editing || action === ModalAction.ADD;

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="beneficiary-form">
      <div className="row">
        <div className="col-md-4 modal-bene-type">
          {addingOrEditingBeneficiary && (
            <BeneficiaryTypeToggle
              register={register}
              beneType={beneType}
              setBeneType={setBeneType}
            />
          )}
          {addingOrEditingBeneficiary ? (
            <RelationshipTypeSelect
              register={register}
              errors={errors}
              relationship={beneficiaryInfo?.relationship}
            />
          ) : (
            <FormInput
              readOnly
              register={register}
              name="relationship"
              text="Relationship"
              errors={errors}
            />
          )}
        </div>
        <div className="col-md-8 modal-form" data-testid="beneficiary-details">
          {(allFields.relationship || action === ModalAction.VIEW) && (
            <BeneficiaryDetails
              register={register}
              dirtyFields={formState.dirtyFields}
              watch={watch}
              errors={errors}
              setValue={setValue}
              action={action}
            />
          )}
        </div>
      </div>
    </form>
  );
}
