import rustle from "../../static/images/rustle.png";
import React from "react";
import classes from "./rustle.module.scss";

// Note: This is a test display for a simple feature flag.
// Adding 'rustled=true' as a query string param rustles the dashboard.
const Rustle = () => {
  return (
    <span>
      <img className={classes.rustle} src={rustle} />
    </span>
  );
};

export default Rustle;
