import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ContributionSource } from "src/models/balances-by-source/contribution-source";
import { Account } from "src/models/balances-by-source/account";
import { getSourceBalanceDetails } from "src/services/balances-by-source";
import { AsyncStatus } from "../../models/async-status";

export const fetchBalancesBySource = createAsyncThunk(
  "fetch-balances-by-source",
  async (
    {
      planId,
      empId,
      role,
      effectiveDate,
      previousEffectiveDate,
      accountIds,
    }: {
      planId: number;
      empId: number;
      role: string;
      effectiveDate: string;
      previousEffectiveDate: string;
      accountIds: string;
    },
    thunkApi
  ) => {
    return getSourceBalanceDetails(
      planId,
      empId,
      role,
      effectiveDate,
      previousEffectiveDate,
      accountIds
    );
  }
);

type State = {
  accountList: Account[];
  asOfDate: string;
  effectiveDate: string;
  effectiveDateSingleAccount: string;
  previousAsOfDate: string;
  contributionSources: ContributionSource[];
  hasContributionData: Boolean;
  activityBeginDate: string;
  allAccountsSelected: Boolean;
  status: AsyncStatus;
};

const initialState: State = {
  status: AsyncStatus.not_fetched,
  accountList: [],
  asOfDate: new Date().toJSON(),
  effectiveDate: new Date().toJSON(),
  effectiveDateSingleAccount: new Date().toJSON(),
  previousAsOfDate: new Date().toJSON(),
  contributionSources: [],
  hasContributionData: false,
  activityBeginDate: new Date().toJSON(),
  allAccountsSelected: false,
};

const balancesBySourceSlice = createSlice({
  name: "balances-by-source",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBalancesBySource.pending, (state, action) => {
      return { ...state, status: AsyncStatus.loading };
    });
    builder.addCase(fetchBalancesBySource.fulfilled, (state, action) => {
      return { ...state, ...action.payload, status: AsyncStatus.done };
    });
    builder.addCase(fetchBalancesBySource.rejected, (state, action) => {
      return {
        ...state,
        status: AsyncStatus.error,
      };
    });
    builder.addDefaultCase((state, action) => state);
  },
});

export default balancesBySourceSlice.reducer;
