import _get from "lodash/get";
import React from "react";
import { Allocation } from "../../../models/allocation";
import { useAllocations } from "../../../selectors";
import AssetClassRiskSpectrum from "../../investments/summary/asset-class-risk-spectrum/asset-class-risk-spectrum";
import { InvestmentAllocationTable } from "./investment-allocation-table";
import { useSelector } from "../../../store/store";
import AccountName from "../../../components/account-name/account-name";

export function InvestmentAllocationTableWrapper() {
  const { allocationsForAccount } = useAllocations();
  const investmentAllocations: Allocation[] = _get(
    allocationsForAccount,
    "investmentAllocations",
    []
  );
  const hasInvestmentAllocations: boolean = investmentAllocations.length > 0;
  const { selected: selectedAccounts } = useSelector((state) => state.accounts);
  const selectedAccount = selectedAccounts[0];

  if (hasInvestmentAllocations) {
    return (
      <>
        <InvestmentAllocationTable
          investmentAllocationsForSelectedAccount={allocationsForAccount}
        />
        <AssetClassRiskSpectrum />
      </>
    );
  }

  return (
    <div>
      <AccountName
        name={selectedAccount.name}
        id={selectedAccount.id}
        asLink={false}
      ></AccountName>
      <p
        className="no-investments-message"
        data-testid="no-investments-message"
      >
        This account does not have investment allocations established.
      </p>
    </div>
  );
}
