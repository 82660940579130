import {PdsCard, PdsCollapsible} from "@principal/design-system-react";
import React, {useEffect, useState} from "react";
import {PlanContacts} from "../../services/contacts";
import ContactCenterNumber from "../contact-center-card";
import {useAppDispatch, useSelector} from "../../store/store";
import {fetchPlanContacts} from "../../reducers/plan-contacts";
import {addressOrDefault} from "../../pages/plan-contacts/plan-contact-page";
import {contractIdSelector} from "../../selectors/plan-info";

interface ContactProps {
    empId: number;
    planId: number;
}

const EnrollmentContacts = (props: ContactProps) => {
    const contractId = useSelector(contractIdSelector);
    const dispatch = useAppDispatch();
    const [planContacts, setPlanContacts] = useState<PlanContacts>({
        planSponsors: null,
        financialProfessionals: null,
        contactCenter: null
    })

    const planContactsData = useSelector(
        (state) => state.planContacts.planContacts
    )
    useEffect(() => {
        if (contractId) {
            dispatch(
                fetchPlanContacts({contractId})
            )
        }
    }, [dispatch, contractId])

    useEffect(() => {
        if (planContactsData) {
            setPlanContacts(planContactsData);
        }
    }, [planContactsData])

    return (
        <div
            className="card-container"
            style={{marginTop: "5%", height: "-140%"}}
        >
            <PdsCard onClick={function ou() {
            }}>
                <div slot="header" style={{fontSize: "140%", margin: "5%"}}>
                    Have more questions?
                </div>
                <div slot="header" style={{margin: "5%", marginTop: "-5%"}}>
                    Your plan contacts are here to help with any additional information
                    you have.
                </div>
                <div
                    slot="header"
                    style={{margin: "5%", marginTop: "5%", marginBottom: "-5%"}}
                >
                    {planContacts && planContacts.planSponsors ? (
                        <div>
                            <PdsCollapsible variant="default">
            <span
                slot="summary-text"
                style={{fontWeight: "bold", fontSize: "170%"}}
            >
              Plan sponsors
            </span>
                                <span slot="collapsible-content" style={{fontSize: "120%"}}>
              {planContacts.planSponsors.map((sponsor) => (
                  <p>
                      <p>{sponsor.company}</p>
                      <p>{sponsor.address.street}, {sponsor.address.city}, {sponsor.address.state} {sponsor.address.zip}</p>
                      <p>{sponsor.phone}</p>
                      <p>{sponsor.fax}</p>
                      <p>{sponsor.email}</p>
                  </p>
              ))}
            </span>
                            </PdsCollapsible>
                        </div>
                    ) : (<></>)}
                    {planContacts && planContacts.financialProfessionals ? (
                        <div>
                            <PdsCollapsible variant="default">
            <span
                slot="summary-text"
                style={{fontWeight: "bold", fontSize: "170%"}}
            >
              Financial professionals
            </span>
                                <span slot="collapsible-content" style={{fontSize: "120%"}}>
              {planContacts.financialProfessionals.map((pro) => (
                  <p>
                      <p>
                          {pro.firstName} {pro.lastName} {pro.role}
                      </p>
                      <p>{pro.company}</p>
                      <p>Address: {addressOrDefault(pro.address)}</p>
                      <p>Phone: {pro.phone}</p>
                      <p>Fax: {pro.fax}</p>
                      <p>Email: {pro.email}</p>
                  </p>
              ))}
            </span>
                            </PdsCollapsible>
                        </div>
                    ) : (<></>)}
                    <ContactCenterNumber planId={props.planId}/>
                </div>
            </PdsCard>
        </div>
    );
};

export default EnrollmentContacts;
