import React from "react";
import {
  streamOfPaymentPendingStatus,
  useStatuses,
} from "../../selectors/status";
import { useSelector } from "../../store/store";
import { Page } from "src/components/page";
import { Modal } from "../../components/modal/modal";

export default function StreamOfPaymentPendingPage() {
  const { streamOfPaymentPending } = useSelector(
    (state) => state.streamOfPaymentPending
  );
  const { isLoadingStatus } = useStatuses([streamOfPaymentPendingStatus]);
  const effectiveLocaleDateForDisplayOnConfirm =
    streamOfPaymentPending.effectiveDateForDisplayOnConfirm &&
    new Date(
      streamOfPaymentPending.effectiveDateForDisplayOnConfirm
    ).toLocaleDateString();
  const currentDistributionLocaleDate =
    streamOfPaymentPending.currentDistributionDate &&
    new Date(
      streamOfPaymentPending.currentDistributionDate
    ).toLocaleDateString();
  const redeferralDistributionLocaleDate =
    streamOfPaymentPending.redeferralDistributionDate &&
    new Date(
      streamOfPaymentPending.redeferralDistributionDate
    ).toLocaleDateString();

  return (
    <Page name={"payment-pending"} loading={isLoadingStatus}>
      <span style={{ color: "#035fa4", fontSize: "20px" }}>
        {" "}
        Pending scheduled distribution election(s): Review distribution
        election(s)
      </span>
      <div className="util-margin-top-15">
        You have requested the following change(s) to your Distribution
        Election. These change(s) will become effective as of{" "}
        {effectiveLocaleDateForDisplayOnConfirm}.
      </div>
      {
        <div className="util-margin-top-15">
          <table data-testid="personal-info-table" className="table">
            <tbody>
              <tr style={{ background: "#caebfa", fontWeight: "bold" }}>
                <td colSpan={2} className="table-head">
                  Distribution Election(s)
                </td>
                <td colSpan={2} className="table-head">
                  Current Distribution Election(s)
                </td>
                <td colSpan={2} className="table-head">
                  New Distribution Election(s)
                </td>
              </tr>
              <tr>
                <td
                  colSpan={2}
                  className="table-head"
                  style={{ fontWeight: "bold" }}
                >
                  Account
                </td>
                <td colSpan={2} className="table-head">
                  {streamOfPaymentPending.accountName}
                </td>
                <td colSpan={2} className="table-head">
                  {streamOfPaymentPending.redeferralNewAccountName}
                </td>
              </tr>
              <tr style={{ background: "#f1f1f1" }}>
                <td
                  colSpan={2}
                  className="table-head"
                  style={{ fontWeight: "bold" }}
                >
                  Distribution Date
                </td>
                <td colSpan={2} className="table-head">
                  {currentDistributionLocaleDate}
                </td>
                <td colSpan={2} className="table-head">
                  {redeferralDistributionLocaleDate}
                </td>
              </tr>
              <tr>
                <td
                  colSpan={2}
                  className="table-head"
                  style={{ fontWeight: "bold" }}
                >
                  Distribution Method
                </td>
                <td colSpan={2} className="table-head">
                  {streamOfPaymentPending.previousPaymentMethodName}
                </td>
                <td colSpan={2} className="table-head">
                  {streamOfPaymentPending.paymentMethodNameForDisplay}
                </td>
              </tr>
              <tr>
                <td colSpan={2}></td>
                <td colSpan={2}></td>
                <td
                  colSpan={2}
                  className="table-head"
                  style={{ fontWeight: "bold" }}
                >
                  Effective {effectiveLocaleDateForDisplayOnConfirm}
                  <Modal title="Effective Date Info">
                    <div id="info" className="info">
                      <p>
                        Your election will become effective on this date,{" "}
                        {effectiveLocaleDateForDisplayOnConfirm}, due to plan
                        requirements based upon your election.
                      </p>
                    </div>
                  </Modal>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      }

      <div className="util-margin-top-15">
        Your actual distribution amount will be the vested amount at the time of
        distribution which may change due to market gains or losses in your
        reference investments. Your employer is not required to segregate assets
        for payment of your account.
      </div>
      <div className="util-margin-top-15">
        It is my responsibility to obtain advice from my financial, tax, and/or
        legal advisors and to understand the terms of the plan and the tax
        consequences of making this change to the time and/or form of my
        account. Making this change does not guarantee future payments. I
        understand Principal Life Insurance Company representatives cannot
        provide tax or legal advice.
      </div>
    </Page>
  );
}
