import { html, isServer } from 'lit';
import { property, queryAssignedElements, state } from 'lit/decorators.js';
import { ResizeObserver as ResizeObserverPolyfill } from '@juggle/resize-observer';
import { pdsCustomElement as customElement } from '../../decorators/pds-custom-element';
import { PdsElement } from '../PdsElement';
import '../step-indicator-item/step-indicator-item';
import styles from './step-indicator.scss?inline';

/**
 * @summary A step indicator
 *
 * @slot default Required: Contains the step indicator items, restricted to pds-step-indicator-item element
 */
@customElement('pds-step-indicator', {
  category: 'component',
  type: 'component',
  state: 'stable',
  styles,
})
export class PdsStepIndicator extends PdsElement {
  connectedCallback() {
    super.connectedCallback();
    this.initLocalization();
  }

  /**
   * variant
   * - **horizontal** renders the step-indicator horizontal on large screens and up
   * - **vertical** renders the step-indicator always vertical
   */
  @property()
  variant: 'horizontal' | 'vertical' = 'horizontal';

  /**
   * interactive
   */
  @property({ type: Boolean })
  interactive: boolean = false;

  /**
   * inverted
   */
  @property({ type: Boolean })
  inverted: boolean = false;

  /**
   * Indicates if the parent container in narrower than step-indicator width
   * @internal
   */
  @state()
  isNarrowContainer: boolean = false;

  /** @internal */
  @state()
  ResizeObserver =
    !isServer && window && window.ResizeObserver
      ? window.ResizeObserver
      : ResizeObserverPolyfill;

  /**
   * Listen for resize events on parent and checks if the parent is narrow in width
   *
   * @internal
   */
  // We can't actually call the observer, because Jest has no concept of element width
  /* istanbul ignore next */
  resizeObserver = new this.ResizeObserver((entries: any[]) => {
    entries.forEach(async () => this.getContainerSize());
  });

  protected override firstUpdated() {
    super.firstUpdated();
    this.handleSlotValidation();
    this.addPropertiesToStepIndicatorItems();
  }

  /**
   * if the parent container is not wide enough to accomodate step-indicator
   * isNarrowContainer prop helps with the responsive design
   * @internal
   * */
  getContainerSize() {
    const { parentElement } = this;
    const { length } = this.stepIndicatorItems;
    // Calculates the minimum step indicator width, 90 is the min-width for step-indicator item
    const controlWidth = 90 * length;
    if (parentElement) {
      this.isNarrowContainer = parentElement.clientWidth <= controlWidth;
    }
  }

  /**
   * @internal
   */
  @queryAssignedElements({
    slot: undefined,
    selector: 'pds-step-indicator-item',
  })
  stepIndicatorItems: HTMLElement[];

  /**
   * @internal
   * pushes properties down to subcomponent step indicator items
   */
  addPropertiesToStepIndicatorItems() {
    this.stepIndicatorItems.forEach((stepIndicatorItem) => {
      stepIndicatorItem.setAttribute('inverted', '');
    });
  }

  handleSlotChange(e: Event) {
    this.handleSlotValidation(e);
    this.addPropertiesToStepIndicatorItems();
  }

  updated() {
    if (this.variant === 'horizontal') {
      this.resizeObserver.observe(this);
    }
  }

  /**
   * @internal
   */
  get classNames() {
    return {
      [this.variant]: !!this.variant,
      inverted: !!this.inverted,
      'narrow-container': !!this.isNarrowContainer,
    };
  }

  listTemplate() {
    return html`<ol role="list" class="${this.classEl('list')}">
      <slot
        allowed-elements="pds-step-indicator-item"
        @slotchange=${this.handleSlotChange}
        >Step indicator items</slot
      >
    </ol>`;
  }

  render() {
    if (this.interactive) {
      return html`<nav
        class="${this.getClass()}"
        aria-label="${this.translateText('progress')}"
      >
        ${this.listTemplate()}
      </nav>`;
    }

    return html`<div
      class="${this.getClass()}"
      role="group"
      aria-label="${this.translateText('progress')}"
    >
      ${this.listTemplate()}
    </div>`;
  }
}
