import { RootState } from "../store/store";
import { createSelector } from "@reduxjs/toolkit";
import { selectedAccountIdsSelector } from "./accounts";

export const accountAtAGlanceSelector = (state: RootState) =>
  state.accountsAtAGlance.accountsAtAGlance;

export const populatedAccountAtAGlanceSelector = createSelector(
  accountAtAGlanceSelector,
  (accountsAtAGlance) => {
    return accountsAtAGlance.filter((account) => {
      return (
        account.contribsThisPeriod ||
        account.contribsToDate ||
        account.distribsThisPeriod ||
        account.vestBalance ||
        account.currBalance
      );
    });
  }
);

export const selectedAccountAtAGlanceSelector = createSelector(
  populatedAccountAtAGlanceSelector,
  selectedAccountIdsSelector,
  (accountsAtAGlance, selectedAccountIds) => {
    return accountsAtAGlance.filter(
      ({ accountId }) => accountId && selectedAccountIds.includes(accountId)
    );
  }
);
