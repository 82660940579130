import React from "react";
import { Link } from "react-router-dom";
import { AccountWithScheduledDistribution } from "../../../models/account";
import { DistributionPayment } from "../../../models/distributions/distribution-payment";
import { PaymentType } from "../../../models/distributions/payment-type";
import { InvestmentV2 } from "../../../models/investment-v2";
import { PayoutStatus } from "../../../models/payout-status";
import AssetClassChart from "../../../pages/investments/summary/asset-chart/asset-class-chart";
import { useInvestments } from "../../../selectors/investments";
import { useAccountsWithScheduledDistribution } from "../../../selectors/scheduled-distribution-events";
import {
  investmentsStatusSelector,
  scheduledDistributionsStatus,
  scheduledElectionsStatus,
} from "../../../selectors/status";
import { getFullYear } from "../../../util/date-util";
import {
  aggregateByAccountId,
  flatten,
  getSortedInvestments,
} from "../../../util/investment-context-util";
import Button from "../../buttons/button";
import Balance from "../../numbers/balance";
import { Skeleton } from "../../skeleton/skeleton";
import {
  getAccountsWithBalance,
  sortAndFilterAccounts,
} from "./dashboard-account-table-util";
import "./dashboard-account-table.scss";

export function DashboardAccountTable() {
  const accountWithScheduledDistributions =
    useAccountsWithScheduledDistribution();
  const { investments, filteredInvestments } = useInvestments();
  const investmentsByAccount: InvestmentV2[] = flatten(
    aggregateByAccountId(investments)
  );

  // @ts-ignore
  const sortedInvestments: InvestmentV2[] =
    getSortedInvestments(filteredInvestments);

  const mappedAccounts = getAccountsWithBalance(
    accountWithScheduledDistributions,
    investmentsByAccount
  );
  const topAccounts = sortAndFilterAccounts(mappedAccounts);

  return (
    <>
      <div className="col-md-6 col-sm-12">
        <h3
          className="h3 util-margin-vert-0 util-margin-bottom-15 text-midnight"
          data-testid="Your accounts"
        >
          Your accounts
        </h3>
        {topAccounts.map((account) => (
          <Skeleton
            selectors={[scheduledDistributionsStatus, scheduledElectionsStatus]}
            height={150}
            key={account.id}
          >
            <Account account={account} key={account.id} />
          </Skeleton>
        ))}
        <Link to={"plan-overview"}>
          <Button className="btn-default">
            View All Accounts{" "}
            <i className="fa fa-arrow-right" aria-hidden="true" />
          </Button>
        </Link>
      </div>
      <div className="col-md-6 dashboard-asset-class-chart hidden-sm">
        <Skeleton selectors={[investmentsStatusSelector]} height={150}>
          <AssetClassChart investments={sortedInvestments} />
        </Skeleton>
      </div>
    </>
  );
}

type AccountProps = {
  account: AccountWithScheduledDistribution;
};

export function getPayoutMessage(monthsTilPayout: number) {
  const s = monthsTilPayout === 1 ? "" : "s";
  const messageEnding =
    monthsTilPayout === 0
      ? `within a month`
      : `in ${monthsTilPayout} month${s}`;
  return `This account will start payout ${messageEnding}`;
}

function EligibleRedeferBadge(props: AccountProps) {
  const { account } = props;
  const { id, monthsTilPayout, isPayingOutSoon } = account;
  if (isPayingOutSoon) {
    const message = getPayoutMessage(monthsTilPayout);
    return (
      <>
        <span
          className="badge badge-primary"
          data-testid={`redefer-badge-${id}`}
        >
          {message}
        </span>
      </>
    );
  }

  return null;
}

function PaidOutBadge(props: AccountProps) {
  const { account } = props;
  const { payoutStatus, id } = account;
  if (
    payoutStatus === PayoutStatus.COMPLETELY_PAID_OUT ||
    payoutStatus === PayoutStatus.PAID_OUT_HAS_BALANCE
  ) {
    return (
      <span className="badge badge-primary" data-testid={`paidout-badge-${id}`}>
        This account is fully distributed
      </span>
    );
  }

  return null;
}

function PayoutInfo(props: AccountProps) {
  const { account } = props;
  const { payoutStatus, scheduledDistributions, id } = account;
  if (scheduledDistributions && scheduledDistributions.length > 0) {
    const startingYear = getFullYear(
      scheduledDistributions?.[0].distributionDate
    );
    if (payoutStatus === PayoutStatus.NOT_IN_PAYOUT) {
      return (
        <div data-testid={`payoutinfo-${id}`}>
          Starting in <strong>{startingYear}</strong>&nbsp;
          <DistributionMethod account={account} />
        </div>
      );
    }
    if (payoutStatus === PayoutStatus.IN_PAYOUT) {
      return (
        <div data-testid={`payoutinfo-${id}`}>
          Payouts began in&nbsp;<strong>{startingYear}</strong>&nbsp;
          <DistributionMethod account={account} />
        </div>
      );
    }
  }

  return null;
}

type DistributionProps = {
  scheduledDistribution: DistributionPayment;
};

function LumpSumPayoutText(props: DistributionProps) {
  const { scheduledDistribution } = props;
  const { distributionAmount } = scheduledDistribution;
  return (
    <>
      you'll receive&nbsp;
      <Balance value={distributionAmount || 0} showDollar />
      &nbsp;in a lump sum
    </>
  );
}

function InstallmentPayoutText(props: DistributionProps) {
  const { scheduledDistribution } = props;
  const { distributionAmount } = scheduledDistribution;
  return (
    <>
      &nbsp;you'll receive approximately&nbsp;
      <Balance value={distributionAmount || 0} showDollar />
      &nbsp; for each scheduled installment
    </>
  );
}

function DistributionMethod(props: AccountProps) {
  const { account } = props;
  const { scheduledDistributions } = account;
  if (scheduledDistributions) {
    const paymentType = scheduledDistributions[0].paymentType;
    if (paymentType === PaymentType.LUMPSUM) {
      return (
        <LumpSumPayoutText scheduledDistribution={scheduledDistributions[0]} />
      );
    }
    if (paymentType === PaymentType.INSTALLMENT) {
      return (
        <InstallmentPayoutText
          scheduledDistribution={scheduledDistributions[0]}
        />
      );
    }
    if (paymentType === PaymentType.INITIAL) {
      return (
        <>
          <LumpSumPayoutText
            scheduledDistribution={scheduledDistributions[0]}
          />
          &nbsp; and &nbsp;
          <InstallmentPayoutText
            scheduledDistribution={scheduledDistributions[1]}
          />
          ;
        </>
      );
    }
  }

  return null;
}

function Account(props: AccountProps) {
  const { account } = props;
  const { id, name, total, vested } = account;
  return (
    <div
      className="dashboard-account util-margin-bottom-15"
      data-testid={`account-${id}`}
      key={id}
    >
      <Link to={`account-info?accountId=${id}`}>{name}</Link>
      <div className="badges">
        <EligibleRedeferBadge account={account} />
        <PaidOutBadge account={account} />
      </div>
      <PayoutInfo account={account} />
      <div className={"balance"}>
        <label>Current balance:</label>{" "}
        <Balance showDollar={true} value={total || 0} />
      </div>
      <div className={"balance"}>
        <label>Vested balance:</label>{" "}
        <Balance showDollar={true} value={vested || 0} />
      </div>
    </div>
  );
}
