import { html, nothing } from 'lit';
import { property } from 'lit/decorators.js';
import { pdsCustomElement as customElement } from '../../decorators/pds-custom-element';
import { PdsElement } from '../PdsElement';
import styles from './global-header-user-menu-label.scss?inline';
import { required } from '../../decorators/required';
import '../user-avatar/user-avatar';

/**
 * @summary This component is a span that contains the user avatar and a span with the user's name.  For use within the global header only.
 *
 * @slot user-image Optional: Replaces the default svg and initials with a user's image, restricted to img and svg
 */
@customElement('pds-global-header-user-menu-label', {
  category: 'component',
  type: 'component',
  styles,
})
export class PdsGlobalHeaderUserMenuLabel extends PdsElement {
  /**
   * The name of the user to be displayed. This is a **required** property.
   */
  @required
  @property()
  fullName: string;

  /**
   *
   * The initials of the user to be displayed
   */
  @property()
  initials?: string;

  /**
   * Shows the notifications flag on the user avatar
   */
  @property({ type: Boolean })
  notifications: boolean = false;

  /**
   * Hide the user's full name
   */
  @property({ type: Boolean })
  hideName: boolean = false;

  /**
   * @internal
   */
  get classNames() {
    return {
      'hide-name': !!this.hideName,
    };
  }

  render() {
    return html`<span class=${this.getClass()}>
      <pds-user-avatar
        initials=${this.initials}
        hasNotifications=${this.notifications ? 'true' : nothing}
        ><slot
          name="user-image"
          slot="media"
          allowed-elements="img, svg, slot, span"
          @slotchange=${this.handleSlotValidation}
        ></slot
      ></pds-user-avatar>
      ${this.hideName ? nothing : html`<span>${this.fullName}</span>`}
    </span>`;
  }
}
