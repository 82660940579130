import React from "react";
import TableV2 from "../../../components/table/tableV2";
import { AccountAllocation } from "../../../models/account-allocation";
import { MoneyType } from "../../../models/moneytype";
import { useInvestments } from "../../../selectors/investments";
import { useSelector } from "../../../store/store";
import { sortInvestments } from "../../../util/investments";
import { buildHexValueColumn } from "../../investments/performance/performance-table/build-columns-utils";
import { buildInvestmentOptionColumn } from "../../investments/summary/investment-table/build-investment-column-util";
import { buildMoneyTypeColumn } from "../../statements/panels/investment-allocations";
import {
  addInvestmentConfigToAllocations,
  groupInvestmentAllocationsByMoneyType,
  removeMoneyTypesWithNoAllocations,
} from "./investment-allocation-table-util";
import AccountName from "../../../components/account-name/account-name";

type Props = {
  investmentAllocationsForSelectedAccount?: AccountAllocation;
};

export type AllocationWithInvestmentConfig = {
  id: number;
  moneyTypeId: number;
  percentage: number;
  startDate?: string;
  institutionName?: string;
  name?: string;
  sortOrder?: number;
  hexValue?: string;
};

export type InvestmentAllocationByMoneyType = {
  allocations: any;
  id: number;
  hexValue?: string;
  name?: string;
  sortOrder?: number;
  institutionName?: string;
};

export function InvestmentAllocationTable(props: Props) {
  const { investmentAllocationsForSelectedAccount } = props;
  const { investments } = useInvestments();
  const { data: moneyTypes } = useSelector((state) => state.plan.moneyTypes);
  const { selected: selectedAccounts } = useSelector((state) => state.accounts);

  const selectedAccount = selectedAccounts[0];

  const investmentAllocationWithInvestmentConfig:
    | AllocationWithInvestmentConfig[]
    | undefined = addInvestmentConfigToAllocations(
    investments,
    investmentAllocationsForSelectedAccount
  );
  const groupedInvestmentAllocationsByMoneyType: InvestmentAllocationByMoneyType[] =
    groupInvestmentAllocationsByMoneyType(
      investmentAllocationWithInvestmentConfig
    );
  const sortedInvestmentAllocations: InvestmentAllocationByMoneyType[] =
    sortInvestments(groupedInvestmentAllocationsByMoneyType);
  const contributionsWithInvestmentAllocations: MoneyType[] =
    removeMoneyTypesWithNoAllocations(sortedInvestmentAllocations, moneyTypes);
  const moneyTypeColumns = contributionsWithInvestmentAllocations.map(
    (moneyType: MoneyType) => buildMoneyTypeColumn(moneyType, "bold", true)
  );

  const columns = [
    buildHexValueColumn(),
    {
      Header: () => (
        <div className="account-name-header">
          <AccountName
            id={selectedAccount.id}
            name={selectedAccount.name}
            asLink={false}
          ></AccountName>
        </div>
      ),
      id: "investment option header",
      columns: [buildInvestmentOptionColumn(true)],
      Footer: "",
    },
    {
      Header: () => (
        <div className="group-header">
          <span className="show">Investment allocations for </span>
          future contributions
        </div>
      ),
      id: "contribution group header",
      columns: moneyTypeColumns,
      Footer: "",
    },
  ];

  return (
    <TableV2
      tableName="investment-allocation-table"
      columns={columns}
      data={sortedInvestmentAllocations}
    />
  );
}
