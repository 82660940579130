import { ContributionHistory } from "../../../../models/contributions";
import _find from "lodash/find";
import _get from "lodash/get";
import { round } from "../../../../util/calculate";

export function getContributionsDate(
  contributionHistories: ContributionHistory[]
) {
  const contribution = _find(contributionHistories, "transactionDate");
  const date = _get(contribution, "transactionDate");
  if (date) {
    return date;
  }
  return "";
}

export function getContributionsYear(
  contributionHistories: ContributionHistory[]
) {
  const contribution = _find(contributionHistories, "transactionDate");
  const date = _get(contribution, "transactionDate");
  if (date) {
    return date.substring(6, 10);
  }
  return "";
}

export function getMoneyTypeName(contributionHistories: ContributionHistory[]) {
  const contribution = _find(contributionHistories, "moneyTypeName");
  const name = _get(contribution, "moneyTypeName");
  if (name) {
    return name;
  }
  return "";
}

export function getAccountName(contributionHistories: ContributionHistory[]) {
  const contribution = _find(contributionHistories, "accountName");
  const name = _get(contribution, "accountName");
  if (name) {
    return name;
  }
  return "";
}

export function getAccountId(
  contributionHistories: ContributionHistory[]
): number {
  const contribution = _find(contributionHistories, "accountId");
  const accountId = _get(contribution, "accountId");
  if (accountId) {
    return accountId;
  }
  return 0;
}

export function getAmountTotal(
  contributionHistories: ContributionHistory[]
): string {
  if (contributionHistories.length === 0) {
    return "";
  }

  const totalBalanceForAccount = round(
    contributionHistories.reduce(
      (prev, next) => prev + _get(next, "transactionAmount", 0),
      0
    )
  );
  if (totalBalanceForAccount) {
    return totalBalanceForAccount;
  }
  return "";
}
