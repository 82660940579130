import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AsyncStatus } from "../../models/async-status";
import { ContributionSummary } from "../../models/contributions";
import { getContributionSummary } from "../../services/contributions";
import { GetAllInvestmentsArgs } from "../../services/investment-data";

export const fetchContributionSummary = createAsyncThunk(
  "fetch-contribution-summary",
  async (args: GetAllInvestmentsArgs) => {
    return getContributionSummary(args);
  }
);

type ContributionSummaryState = {
  data: ContributionSummary[];
  status: AsyncStatus;
};
const initialState: ContributionSummaryState = {
  data: [],
  status: AsyncStatus.not_fetched,
};

const contributionSummarySlice = createSlice({
  name: "contributionSummary",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchContributionSummary.pending, (state, action) => {
      return { ...state, status: AsyncStatus.loading };
    });
    builder.addCase(fetchContributionSummary.fulfilled, (state, action) => {
      return { ...state, data: action.payload, status: AsyncStatus.done };
    });
    builder.addCase(fetchContributionSummary.rejected, (state, action) => {
      return { ...state, status: AsyncStatus.error };
    });
    builder.addDefaultCase((state, action) => state);
  },
});

export default contributionSummarySlice.reducer;
