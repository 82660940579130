import React from "react";

export default function ModifiedMethodDisclaimer() {
  return (
    <p>
      Any modifications to the distribution elections on this account will be
      described in the "Additional Information" section above and may impact the
      estimated distribution amount shown here.
    </p>
  );
}
