import { html } from 'lit';
import { MessageSquare } from 'lucide-static';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { state } from 'lit/decorators.js';
import { pdsCustomIconElement as customElement } from '../../../decorators/pds-icon';
import { PdsIcon } from '../PdsIcon';

@customElement('pds-icon-message-square')
export class PdsIconMessageSquare extends PdsIcon {
  @state()
  icon = MessageSquare;

  render() {
    return html`${unsafeHTML(this.setIconAttributes())}`;
  }
}
