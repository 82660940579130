import React, { useEffect, useState } from "react";
import { LastBusinessDate } from "../../../components/numbers/last-business-date";
import { Skeleton } from "../../../components/skeleton/skeleton";
import { ScheduledDistributionPagePayment } from "../../../models/distributions/distribution-payment";
import { ScheduledDistributionEvent } from "../../../models/distributions/scheduled-distribution-event";
import { ScheduledDistributionElection } from "../../../models/distributions/ScheduledDistributionElection";
import { useInvestments } from "../../../selectors/investments";
import {
  contributionRateStatus,
  datesStatusSelector,
  investmentsStatusSelector,
  sessionStatusSelector,
  useStatuses,
} from "../../../selectors/status";
import {
  getScheduledDistributionElections,
  getScheduledDistributions,
} from "../../../services/distributions";
import { useSelector } from "../../../store/store";
import { filterScheduledElectionsByAccountIds } from "../../../util/distributions-util";
import { DistributionDisclaimer } from "../distribution-summary/disclaimers/distribution-disclaimer";
import { buildDistributionPayments } from "../distribution-summary/scheduled-distributions-table/summary-scheduled-distributions-util";
import { getInvestmentsByDistributionAccountIds } from "./scheduled-distribution-util";
import ScheduledDistributionsTable from "./scheduled-distributions-table";
import { getScheduledDistributionsReport } from "../../../services/report";
import { TaxImplication409aModal } from "../../../components/modal/tax-implication-modals/tax-implication-409a-modal";
import { TaxImplication457bModal } from "../../../components/modal/tax-implication-modals/tax-implication-457b-modal";
import { PlanCode } from "../../../models/plan/plan-info-model";

export default function ScheduledDistributionsWrapper() {
  const { planId, empId, role } = useSelector((state) => state.session);
  const [hasError, setHasError] = useState(false);
  const [scheduledDistributions, setScheduledDistributions] = useState<
    ScheduledDistributionEvent[]
  >([]);
  const [scheduledDistributionElections, setScheduledDistributionElections] =
    useState<ScheduledDistributionElection[]>([]);
  const { investments } = useInvestments();
  const combinedInvestments = getInvestmentsByDistributionAccountIds(
    scheduledDistributions,
    investments
  );
  const { selectedIds: selectedAccountIds } = useSelector(
    (state) => state.accounts
  );
  const planCode = useSelector((state) => state.plan.info.planCode);
  const isExcess = planCode === "EXCESS";
  const is457b = planCode.toLowerCase() === PlanCode.type457b;
  const { isLoadingStatus } = useStatuses([
    investmentsStatusSelector,
    sessionStatusSelector,
    contributionRateStatus,
  ]);

  async function exportScheduledDistributionReport() {
    await getScheduledDistributionsReport(planId, empId, role);
  }

  useEffect(() => {
    (async function fetchData() {
      try {
        const [scheduledDistributions, scheduledDistributionElections] =
          await Promise.all([
            getScheduledDistributions({ planId, empId, role }),
            getScheduledDistributionElections({ planId, empId, role }),
          ]);

        setScheduledDistributions(scheduledDistributions);
        setScheduledDistributionElections(scheduledDistributionElections);
      } catch (e) {
        setHasError(true);
      }
    })();
  }, [planId, empId, role]);

  const filteredScheduledElections: ScheduledDistributionElection[] =
    filterScheduledElectionsByAccountIds(
      selectedAccountIds,
      scheduledDistributionElections
    );

  // @ts-ignore
  const formattedDistributions: ScheduledDistributionPagePayment[] =
    buildDistributionPayments(
      scheduledDistributions,
      combinedInvestments,
      filteredScheduledElections,
      true
    );

  const noDistributions = formattedDistributions.length === 0;

  return (
    <>
      {!isLoadingStatus && noDistributions && (
        <strong>Your account(s) have no scheduled distributions.</strong>
      )}
      <p>
        The following information is as of{" "}
        <Skeleton selectors={[datesStatusSelector]} width={100}>
          <LastBusinessDate />
        </Skeleton>
        <button
          className="btn btn-primary form-group pull-right"
          onClick={() => exportScheduledDistributionReport()}
        >
          Download Excel
        </button>
      </p>
      {isExcess && (
        <p>
          Upon a distribution event, your distribution will be subject to income
          tax. For more details see
          <TaxImplication409aModal
            infoIcon={false}
            linkText={"this information."}
          />
        </p>
      )}
      {is457b && (
        <p>
          Upon a distribution event, your distribution will be subject to income
          tax. For more details see
          <TaxImplication457bModal
            infoIcon={false}
            linkText={"this information."}
          />
        </p>
      )}
      <ScheduledDistributionsTable distributions={formattedDistributions} />
      <DistributionDisclaimer>
        <p>
          The Estimated Distribution amount(s) listed are based upon your
          current account balance and do not factor in a vesting schedule, if
          applicable. Your actual distribution may be different than the
          amount(s) listed above.
        </p>
      </DistributionDisclaimer>
    </>
  );
}
