import React from "react";
import { Modal } from "./modal";

type Props = {
  info: string;
  buttonLabel: string;
  characterLimit: number;
};

export default function InfoModal(props: Props) {
  const { info, buttonLabel, characterLimit } = props;
  const useModal = info.length > characterLimit;

  return useModal ? (
    <Modal
      linkText={buttonLabel}
      title="Additional Info"
      infoIcon={false}
      asButtonCss
      className={"btn-sm"}
    >
      {info}
    </Modal>
  ) : (
    <>{info}</>
  );
}
