import React from "react";
import { Link } from "react-router-dom";
import { individualInvestmentPerformanceLink } from "../../util/constants";

export default function InvestmentLink(props) {
  const { id, fundName, className } = props;
  return (
    <Link
      data-testid={`investment-link-${id}`}
      to={individualInvestmentPerformanceLink(id)}
      className={className}
    >
      {fundName}
    </Link>
  );
}
