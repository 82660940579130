import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import AccountAllocations from "../pages/contributions/account-allocations/account-allocations";
import ManageContributionsReview from "../pages/contributions/manage-contributions/review/manage-contributions-review";
import ContributionsSummaryPage from "../pages/contributions/summary/contributions-summary-page";
import ManageContributionsPage from "../pages/contributions/manage-contributions/manage-contributions-page";
import BalancesBySourcePage from "src/pages/contributions/balances-by-source/balances-by-source-page";

export default function ContributionRoutes() {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.url}/account-allocations`}>
        <AccountAllocations />
      </Route>
      <Route path={`${match.url}/summary`}>
        <ContributionsSummaryPage />
      </Route>
      <Route path={`${match.url}/manage-contributions`} exact>
        <ManageContributionsPage />
      </Route>
      <Route path={`${match.url}/manage-contributions/review`}>
        <ManageContributionsReview />
      </Route>
      <Route path={`${match.url}/balances-by-source`}>
        <BalancesBySourcePage />
      </Route>
    </Switch>
  );
}
