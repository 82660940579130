import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getTrsStatements } from "../../services/trs-statements";
import { AsyncStatus } from "../../models/async-status";
import { TrsStatementsResource } from "../../models/trs-statements";

export const fetchTrsStatements = createAsyncThunk(
  "fetch-trs-statements",
  async (
    {
      contractId,
      empId,
      authToken,
      isPilotOrTest,
      isAdvisorOrEmployer,
    }: {
      contractId: string;
      empId: number;
      authToken: string;
      isPilotOrTest: boolean;
      isAdvisorOrEmployer: boolean;
    },
    thunkApi
  ) => {
    return getTrsStatements(
      contractId,
      empId,
      authToken,
      isPilotOrTest,
      isAdvisorOrEmployer
    );
  }
);

type State = {
  trsStatementsResource: TrsStatementsResource;
  status: AsyncStatus;
};

const initialState: State = {
  trsStatementsResource: {
    trsStatements: [],
    errors: [],
    _messages: "",
  } as TrsStatementsResource,
  status: AsyncStatus.not_fetched,
};

const trsStatementsSlice = createSlice({
  name: "trs-statements-resource",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTrsStatements.pending, (state, action) => {
      return { ...state, status: AsyncStatus.loading };
    });
    builder.addCase(
      fetchTrsStatements.fulfilled,
      (state, action: { payload: TrsStatementsResource }) => {
        return {
          ...state,
          trsStatementsResource: action.payload,
          status: AsyncStatus.done,
        };
      }
    );
    builder.addCase(fetchTrsStatements.rejected, (state, action) => {
      return { ...state, status: AsyncStatus.error };
    });
    builder.addDefaultCase((state, action) => state);
  },
});

export default trsStatementsSlice.reducer;
