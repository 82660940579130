import {PlanContact} from "../services/contacts";

export function chunkList<T>(collection: T[], chunkSize: number): Array<T[]> {
    return collection.reduce((resultArray: Array<T[]>, item, index) => {
        const chunkIndex = Math.floor(index / chunkSize)

        if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = [] // start a new chunk
        }

        resultArray[chunkIndex].push(item)

        return resultArray
    }, [])
}
