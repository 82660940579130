import React, { useEffect, useState } from "react";
import { useSelector } from "../../../store/store";
import { accountsWithUnscheduledDistributionsSelector } from "../../../selectors/account-distributions";
import { DistributionPayment } from "../../../models/distributions/distribution-payment";
import { distributionPaymentsSelector } from "../../../selectors/distribution-payment";
import SummaryScheduledDistributionsTable from "../distribution-summary/scheduled-distributions-table/summary-scheduled-distributions-table";
import { DistributionDisclaimer } from "../distribution-summary/disclaimers/distribution-disclaimer";
import EstimatedDistributionPlanDisclaimer from "../distribution-summary/disclaimers/estimated-distribution-plan-disclaimer";
import Pre409ADisclaimer from "../distribution-summary/pre409A-disclaimer";

export interface Props {
  showPaidOutAccounts?: boolean;
}

export function DistributionHistory(props: Props) {
  const { showPaidOutAccounts } = props;
  const allAccountsDistributionPayments: DistributionPayment[] = useSelector(
    distributionPaymentsSelector
  );

  const selectedAccounts = useSelector((state) => state.accounts.selected);
  const filteredAccounts = useSelector(
    accountsWithUnscheduledDistributionsSelector
  );
  const filteredAccountsIds: number[] = [];

  filteredAccounts.forEach((account) => {
    filteredAccountsIds.push(account.id);
  });
  const filteredAccountsDistributionPayments: DistributionPayment[] = [];

  allAccountsDistributionPayments.forEach((payment) => {
    if (filteredAccountsIds.includes(payment.accountId)) {
      filteredAccountsDistributionPayments.push(payment);
    }
  });

  function sortAccountsByDate(
    filteredAccountsDistributionPayments: DistributionPayment[]
  ): DistributionPayment[] {
    // using quick-sort algorithm
    const length = filteredAccountsDistributionPayments.length - 1;
    let index = 0;

    if (filteredAccountsDistributionPayments.length < 2) {
      //base case
      return filteredAccountsDistributionPayments;
    }
    const pivot = filteredAccountsDistributionPayments[length];
    const left = []; // left-hand side array
    const right = []; // right-hand side array

    while (index < length) {
      // compare and push
      if (
        filteredAccountsDistributionPayments[index].distributionDate <
        pivot.distributionDate
      ) {
        left.push(filteredAccountsDistributionPayments[index]);
      } else {
        right.push(filteredAccountsDistributionPayments[index]);
      }
      index++;
    }
    return [...sortAccountsByDate(left), pivot, ...sortAccountsByDate(right)];
  }

  const initialDistributionPayments = sortAccountsByDate(
    filteredAccountsDistributionPayments
  ).filter((payment) => payment.status === "Distributed");

  const [distributionPayments, setDistributionPayments] = useState(
    filteredAccountsDistributionPayments
  );
  const [isNotPaidOutAccountsView, setIsNotPaidOutAccountsView] =
    useState(true);

  useEffect(() => {
    if (allAccountsDistributionPayments.length > 0) {
      if (showPaidOutAccounts) {
        setIsNotPaidOutAccountsView(false);
        setDistributionPayments(
          sortAccountsByDate(allAccountsDistributionPayments).filter(
            (payment) => payment.status === "Distributed"
          )
        );
      } else {
        setDistributionPayments(
          sortAccountsByDate(filteredAccountsDistributionPayments).filter(
            (payment) => payment.status === "Distributed"
          )
        );
      }
    }
  }, [showPaidOutAccounts, selectedAccounts, allAccountsDistributionPayments]);

  return (
    <>
      {isNotPaidOutAccountsView && (
        <SummaryScheduledDistributionsTable
          distributions={initialDistributionPayments}
          isDistributionHistory={true}
        />
      )}
      {!isNotPaidOutAccountsView && (
        <SummaryScheduledDistributionsTable
          distributions={distributionPayments}
          isDistributionHistory={true}
        />
      )}
      {((isNotPaidOutAccountsView &&
        initialDistributionPayments.length !== 0) ||
        (!isNotPaidOutAccountsView && distributionPayments.length !== 0)) && (
        <DistributionDisclaimer>
          <EstimatedDistributionPlanDisclaimer
            hideEstimatedDistributionsLanguage={true}
          />

          <Pre409ADisclaimer />
        </DistributionDisclaimer>
      )}
    </>
  );
}
