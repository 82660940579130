import _flatMap from "lodash/flatMap";
import _uniq from "lodash/uniq";
import React, { useEffect, useState } from "react";

import DisclosureList from "../../../components/disclosure-list";
import { Disclosures } from "../../../models/disclosures";
import { InvestmentV2 } from "../../../models/investment-v2";
import { useInvestments } from "../../../selectors/investments";
import { getAllDisclosures } from "../../../services/investment-disclosures";
import { useSelector } from "../../../store/store";
import {
  filterEmptyInvestments,
  reduceByInvestmentId,
} from "../../../util/investment-context-util";
import { sortInvestments } from "../../../util/investments";
import AssetClassRiskSpectrum from "../summary/asset-class-risk-spectrum/asset-class-risk-spectrum";
import AverageAnnualAndPricesAsOfDatesV2 from "./average-annual-and-prices-as-of-dates";
import InvestmentStaticDisclosures from "./investment-disclosures/investment-static-disclosures";
import PerformanceTableFiltersV2 from "./performance-table-filters/performance-table-filters";
import PerformanceTable from "./performance-table/performance-table";
import "./performance-wrapper.scss";
import PfioTable from "./performance-table/pfio-table/pfio-table";
import {
  getAvailableInvestments,
  getManageInvestments,
} from "../../../services/investment-data";
import {
  accountsStatusSelector,
  investmentsStatusSelector,
  useStatuses,
} from "../../../selectors/status";
import { ManageInvestments } from "../../../models/manage-investments";
import { InvestmentOption } from "../../../models/investment-option";

type Props = {
  performanceType: string;
  setPerformanceType: Function;
};

export default function PerformanceWrapper(props: Props) {
  const { performanceType, setPerformanceType } = props;
  const { planId, empId, role } = useSelector((state) => state.session);
  const { filteredInvestments, investmentsWithoutActivity } = useInvestments();
  const investments = useSelector((state) => state.investments.data);
  const [investmentOptions, setInvestmentOptions] =
    useState<InvestmentOption[]>();
  const { selected: selectedAccounts } = useSelector((state) => state.accounts);

  const [showAllAccounts, setShowAllAccounts] = useState<boolean>(true);
  const [showFundPrices, setShowFundPrices] = useState<boolean>(true);
  const [isYourFundsSelected, setIsYourFundsSelected] = useState<boolean>(true);
  const [isAvailableInPlanSelected, setAvailableInPlanSelected] =
    useState<boolean>(false);
  const [isWhatYouveSelectedClicked, setIsWhatYouveSelectedClicked] =
    useState<boolean>(false);
  const [investmentsList, setInvestmentsList] =
    useState<InvestmentV2[]>(investments);

  const [pfioInvestments, setPfioInvestments] = useState<InvestmentV2[]>([]);
  const [nonPfioInvestments, setNonPfioInvestments] = useState<InvestmentV2[]>(
    []
  );
  const [outsideColi, setOutsideColi] = useState<boolean>(false);

  const [disclosures, setDisclosures] = useState<Disclosures[]>([]);
  const [disclosuresToShow, setDisclosuresToShow] = useState<Disclosures[]>([]);

  const { isLoadingStatus } = useStatuses([
    investmentsStatusSelector,
    accountsStatusSelector,
  ]);
  useEffect(() => {
    fetchAvailableInvestmentsData();

    async function fetchAvailableInvestmentsData() {
      try {
        const data: InvestmentOption[] = await getAvailableInvestments(planId);
        setInvestmentOptions(data);
      } catch (e) {
        console.error(e.message);
      }
    }
  }, [planId]);
  useEffect(() => {
    fetchAllDisclosures();
    if (isYourFundsSelected) {
      setInvestmentsList(investments);
    } else if (isWhatYouveSelectedClicked) {
      setInvestmentsList(filteredInvestments);
    } else if (isAvailableInPlanSelected) {
      const availableInvestmentIds: number[] = investmentOptions
        ? investmentOptions
            .filter((option) => option.available)
            .map((option) => option.nqInvestmentId)
        : [];
      const availableInvestments: InvestmentV2[] = investments.filter(
        (investment) => availableInvestmentIds.includes(investment.id)
      );
      setInvestmentsList(availableInvestments);
    }
    async function fetchAllDisclosures() {
      let disclosures: Disclosures[] = await getAllDisclosures({
        planId,
        empId,
        role,
      });

      const uniqueIds: number[] = [];
      disclosures = disclosures.filter((disclosure) => {
        if (uniqueIds.indexOf(disclosure.id) === -1) {
          uniqueIds.push(disclosure.id);
          return true;
        }
        return false;
      });

      disclosures = disclosures.sort(
        (a: { id: number }, b: { id: number }) => a.id - b.id
      );

      setDisclosures(disclosures);
    }
  }, [
    planId,
    empId,
    role,
    isYourFundsSelected,
    investments,
    filteredInvestments,
    isWhatYouveSelectedClicked,
    isAvailableInPlanSelected,
  ]);

  useEffect(() => {
    const investmentsToShow: InvestmentV2[] = filterInvestmentsToShow(
      investmentsList,
      investmentsWithoutActivity,
      showAllAccounts
    );
    const aggregatedInvestments: InvestmentV2[] =
      reduceByInvestmentId(investmentsToShow);

    const sortedInvestmentsToShow: InvestmentV2[] = sortInvestments(
      aggregatedInvestments
    );

    setDisclosuresToShow(
      filterDisclosuresToShow(disclosures, sortedInvestmentsToShow)
    );
    setOutsideColi(hasInvestmentsOutsideColi(sortedInvestmentsToShow));
    setNonPfioInvestments(getNonPfioInvestments(sortedInvestmentsToShow));
    setPfioInvestments(getPfioInvestments(sortedInvestmentsToShow));
  }, [
    investmentsWithoutActivity,
    investmentsList,
    isYourFundsSelected,
    planId,
    empId,
    role,
    selectedAccounts,
    showAllAccounts,
    disclosures,
  ]);

  return (
    <div className="performance-wrapper">
      <PerformanceTableFiltersV2
        showAllAccounts={showAllAccounts}
        setShowAllAccounts={setShowAllAccounts}
        setShowAvailableInPlan={setAvailableInPlanSelected}
        showAvailableInPlan={isAvailableInPlanSelected}
        setPerformanceType={setPerformanceType}
        performanceType={performanceType}
        setIsYourFundsSelected={setIsYourFundsSelected}
        showFundPrices={showFundPrices}
        setShowFundPrices={setShowFundPrices}
        isYourFundsSelected={isYourFundsSelected}
        setIsWhatYouveSelectedClicked={setIsWhatYouveSelectedClicked}
        isWhatYouveSelectedClicked={isWhatYouveSelectedClicked}
      />
      <br />
      <div className="alert alert-info" role="alert">
        {isYourFundsSelected && (
          <p className="alert-info">
            The funds on this page are a personalized combination of funds
            available in the plan, your current fund line up, and your
            historical fund line up.
          </p>
        )}
        {isWhatYouveSelectedClicked && (
          <p className="alert-info">
            The funds on this page are your current fund allocations.
          </p>
        )}
        {isAvailableInPlanSelected && (
          <p>
            The funds on this page are all funds currently available in your
            plan.
          </p>
        )}
      </div>
      <AverageAnnualAndPricesAsOfDatesV2 />
      <PerformanceTable
        performanceType={performanceType}
        investmentsToShow={nonPfioInvestments}
        showFundPrices={showFundPrices}
      />
      {pfioInvestments.length > 0 && (
        <PfioTable pfioInvestments={pfioInvestments} />
      )}
      <AssetClassRiskSpectrum />
      <InvestmentStaticDisclosures
        hasOutsideColi={outsideColi}
        showDefaultAnchors
        selectedAccounts={selectedAccounts}
      />
      {disclosuresToShow && <DisclosureList disclosures={disclosuresToShow} />}
    </div>
  );
}

function filterInvestmentsToShow(
  filteredInvestments: InvestmentV2[],
  investmentsWithoutActivity: InvestmentV2[],
  showAllAccounts: Boolean
) {
  if (showAllAccounts) {
    return [...filteredInvestments, ...investmentsWithoutActivity];
  }
  return filterEmptyInvestments(filteredInvestments);
}

function filterDisclosuresToShow(
  disclosures: Disclosures[],
  investmentsToShow: InvestmentV2[]
) {
  const flattenedDisclosureIds = _flatMap(investmentsToShow, (investment) =>
    investment.disclosureIds?.map((disc) => disc.id)
  );

  const selectedDisclosureIds = _uniq(flattenedDisclosureIds);
  return disclosures.filter((disc) => {
    return selectedDisclosureIds.includes(disc.id);
  });
}

function hasInvestmentsOutsideColi(investments: InvestmentV2[]): boolean {
  return investments.some((inv) => inv.isOutsideColi);
}

function getPfioInvestments(investments: InvestmentV2[]): InvestmentV2[] {
  return investments.filter((investment) => investment.isPfio);
}

function getNonPfioInvestments(investments: InvestmentV2[]): InvestmentV2[] {
  return investments.filter((investment) => !investment.isPfio);
}
