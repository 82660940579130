import React from "react";
import "./responsive-time-series-line-chart.scss";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { getRoundedValue } from "../numbers/fixed-decimal";

export default function ForecastingBarGraph(props) {
  const { forecastDistributionsForGraph, formatTooltip, calculateFunction } =
    props;
  const mappedData = forecastDistributionsForGraph.map((item) => ({
    year: item.year,
    "Estimated Distribution": item.totalDistribution,
  }));

  const toolTipRef = React.createRef();

  function getDateFromToolTip() {
    const year =
      toolTipRef.current.wrapperNode.children[0].children[0].textContent;
    calculateFunction(true, year);
  }

  function formatYTick(item) {
    return `$${getRoundedValue(item, 0)}`;
  }

  return (
    <ResponsiveContainer width="102%" height={350}>
      <BarChart
        data={mappedData}
        margin={{ left: 30 }}
        onClick={() => getDateFromToolTip()}
        style={{ cursor: "pointer" }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="year" />
        <YAxis dataKey="Estimated Distribution" tickFormatter={formatYTick} />
        <Tooltip formatter={formatTooltip} ref={toolTipRef} />
        <Bar dataKey="Estimated Distribution" fill="#085ca4" />
      </BarChart>
    </ResponsiveContainer>
  );
}
