export interface ParticipantEvent {
  empId: number;
  eventId: number;
  eventConfiguration: EventConfigurations;
  electionSummary: ElectionSummary;
}

export enum EnrollmentEventStatus {
  "PENDING" = "PENDING",
  "ONGOING" = "ONGOING",
  "CANCELLED" = "CANCELLED",
  "COMPLETED" = "COMPLETED",
}

export enum PlanType {
  "EXCESS_PLAN" = "EXCESS_PLAN",
  "EXECUTIVE_457B_PLAN" = "EXECUTIVE_457B_PLAN",
  "EXECUTIVE_457F_PLAN" = "EXECUTIVE_457F_PLAN",
  "LONG_TERM_INCENTIVE_PLAN" = "LONG_TERM_INCENTIVE_PLAN",
}

export interface EventConfigurations {
  eventId: number;
  eventName: string;
  planId: number;
  planName: string;
  contractId: number;
  companyId: number;
  companyName: string;
  configId: number;
  enrollmentSystem: string;
  representative: string;
  eventType: keyof typeof EnrollmentType;
  planType: PlanType;
  planCode: string;
  isDemoPlan: boolean;
  provisionVersion: number;
  eventStatus: EnrollmentEventStatus;
  isEventClosed: boolean;
  isEventOngoing: boolean;
  isEventFox: boolean;
  sourceYear: boolean;
  isEvergreen: boolean;
  startDate: number;
  endDate: number;
  asOfDate: number;
  effectiveDate: number;
  startDateYear: number;
  effectiveDateYear: number;
}

export enum EnrollmentType {
  "NEW" = 0,
  "ANNUAL",
  "PBC",
}

export interface ElectionSummary {
  electionId: number;
  sessionId: number;
  eventId: number;
  employeeId: number;
  electionStatusDate: number;
  enrollmentCompleteDate: number;
  enrollmentCompleteOnBehalf: boolean;
  processedDate: number;
  isProcessed: boolean;
  isParticipantParticipating: boolean;
  isParticipantDeclinedEnrollment: boolean;
  isParticipantAcceptedEnrollment: boolean;
  isParticipantEnrollmentCompleted: boolean;
  participantEnrollmentStatus: string;
  participantEnrollmentStatusDescription: string;
}

export interface AllPlanInfo {
  enrollmentMessage: string;
  participantLandingMessage: string;
}
