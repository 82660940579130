import _groupBy from "lodash/groupBy";
import _sortBy from "lodash/sortBy";
import React from "react";
import Percent from "../../../components/numbers/percent";
import TableV2 from "../../../components/table/tableV2";
import { useSelector } from "../../../store/store";

const InvestmentAllocations = (props) => {
  const moneyTypes = useSelector((state) => state.plan.moneyTypes.data);
  const { investments } = props;

  const investmentsArray = Object.values(_groupBy(investments, "id"));

  const investmentsWithAllocations = investmentsArray.map((array) => {
    return array.reduce(allocationReducer, {});
  });
  const sortedInvestments = _sortBy(investmentsWithAllocations, "name");

  const moneyTypeColumns = moneyTypes.map((moneyType) => {
    return buildMoneyTypeColumn(moneyType);
  });

  const columns = [
    {
      Header: "Investment Options",
      accessor: "name",
    },
    ...moneyTypeColumns,
  ];

  return <TableV2 columns={columns} data={sortedInvestments} />;
};

export function buildMoneyTypeColumn(moneyType, classNames, hasFooter = false) {
  return {
    Header: <div className={classNames}>{moneyType.moneyName}</div>,
    accessor: `allocations[${moneyType.moneyTypeId}]`,
    className: "right",
    Cell: ({ value }) => <Percent isPercentage value={value || 0} />,
    Footer: (info) => {
      if (hasFooter) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const total = React.useMemo(
          () =>
            info.rows.reduce((sum, row) => {
              const curr = row.original.allocations[moneyType.moneyTypeId] || 0;
              return curr + sum;
            }, 0),
          [info.rows]
        );
        return (
          <div className="bold">
            <Percent isPercentage value={total} />
          </div>
        );
      }
      return null;
    },
  };
}

function allocationReducer(inv1, inv2) {
  const allocations = inv1.allocations || {};
  allocations[inv2.moneyTypeId] = inv2.allocationPercentage;
  return {
    name: inv2.name,
    allocations,
  };
}

export default InvestmentAllocations;
