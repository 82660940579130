import { Address } from "../Employee";

export interface BeneficiaryInfo {
  name: string;
  relationship: string;
  type: string;
  emailAddress?: string;
  ssn?: string;
  tin?: string;
  phoneNumber?: string;
  address: Address;
}

export const defaultBeneficiaryInfo = {
  name: "",
  type: "",
  relationship: "",
  emailAddress: "",
  ssn: "",
  tin: "",
  address: {
    addressLine1: "",
    city: "",
    zip: "",
    country: "US",
    addressType: 0,
    addressLocation: 0,
    preferredAddress: false,
    fullAddress: "",
  },
};

export const defaultBeneficiary = {
  id: 0,
  beneficiaryInfo: defaultBeneficiaryInfo,
  allocationAmount: 0,
};

export interface Beneficiary {
  guid: string;
  id?: number;
  beneficiaryInfo: BeneficiaryInfo;
  allocationAmount: number;
}

export type BeneficiaryWrapper = {
  beneficiaries: Beneficiary[];
  plans: number[];
};

export interface OnlineBeneficiaryPlan {
  id: number;
  name: string;
  selected: boolean;
  allowOnlineBeneficiaryChanges: boolean;
}

export enum IdentificationType {
  SSN = "SSN",
  TTN = "TTN",
}

export enum BeneficiaryType {
  PRIMARY = "Primary",
  CONTINGENT = "Contingent",
}
