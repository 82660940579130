import React, { useEffect, useState } from "react";
import { Session } from "../../models/session";
import { getFooter } from "../../services/page-elements";
import { useSelector } from "../../store/store";

function SiteFooter() {
  const { planId } = useSelector<Session>((state) => state.session);

  const [footer, setFooter] = useState<string>("");
  useEffect(() => {
    fetchFooter();

    async function fetchFooter() {
      const html: string = await getFooter(planId);
      setFooter(html);
    }
  }, [planId]);

  return (
    <div className={"no-print"} dangerouslySetInnerHTML={{ __html: footer }} />
  );
}

export default SiteFooter;
