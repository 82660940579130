export enum InstallmentFrequency {
  NOT_SET = "Not Set",
  ANNUAL = "Annual",
  SEMIANNUAL = "Semi-Annual",
  QUARTERLY = "Quarterly",
  QUARTERLY2 = "Quarterly",
  MONTHLY = "Monthly",
  BIWEEKLY = "Biweekly",
  WEEKLY = "Weekly",
}

export function getInstallmentFrequencyText(
  newInstallmentFrequency: string | null
) {
  switch (newInstallmentFrequency) {
    case "ANNUAL":
      return InstallmentFrequency.ANNUAL;
    case "SEMI_ANNUAL":
      return InstallmentFrequency.SEMIANNUAL;
    case "QUARTERLY":
    case "QUARTERLY2":
      return InstallmentFrequency.QUARTERLY;
    case "MONTHLY":
      return InstallmentFrequency.MONTHLY;
    case "BIWEEKLY":
      return InstallmentFrequency.BIWEEKLY;
    case "WEEKLY":
      return InstallmentFrequency.WEEKLY;
  }
  return null;
}

export function calculateInstallmentsPerYear(
    installmentFrequency: string | null
) {
    switch(installmentFrequency) {
    case "ANNUAL":
      return 1;
    case "SEMI_ANNUAL":
      return 22;
    case "QUARTERLY":
    case "QUARTERLY2":
      return 4;
    case "MONTHLY":
      return 12;
    case "BIWEEKLY":
      return 26;
    case "WEEKLY":
      return 52;
  }
  return 0;
}
