export function toBase64String(data) {
    const dataString = data.toString();
    return convertStringToBase64(dataString);
}

export function objectToBase64String(data) {
    const dataString = JSON.stringify(data);
    return convertStringToBase64(dataString);
}

function convertStringToBase64(dataString) {
    return window.btoa(dataString);
}
