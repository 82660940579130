import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AsyncStatus } from "../models/async-status";
import { getNqAccount } from "../services/enrollment";
import { NqAccount } from "../models/nq-accounts.model";

export const fetchNqSingleAccountDetails = createAsyncThunk(
  "fetch-nq-account-details",
  async (
    { enterpriseId, planId }: { enterpriseId: string; planId: number },
    thunkApi
  ) => {
    return getNqAccount(enterpriseId, planId);
  }
);

type State = {
  data: NqAccount;
  status: AsyncStatus;
};

const initialState: State = {
  data: {} as NqAccount,
  status: AsyncStatus.not_fetched,
};

const nqAccountDetailsSlice = createSlice({
  name: "nqAccountDetails",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchNqSingleAccountDetails.pending, (state, action) => {
      return { ...state, status: AsyncStatus.loading };
    });
    builder.addCase(
      fetchNqSingleAccountDetails.fulfilled,
      (state, action: { payload: NqAccount; type: string }) => {
        return { ...state, data: action.payload, status: AsyncStatus.done };
      }
    );
    builder.addCase(fetchNqSingleAccountDetails.rejected, (state, action) => {
      return { ...state, status: AsyncStatus.error };
    });
    builder.addDefaultCase((state, action) => state);
  },
});

export default nqAccountDetailsSlice.reducer;
