import { downloadPlanInfoFiles } from "../repositories/participant";

export async function viewEnrollmentToolsDocument(
  planId: number,
  empId: number,
  extension: string,
  resourceId: number,
  role: string
): Promise<String> {
  return downloadPlanInfoFiles(
    `/plans/${planId}/employees/${empId}/viewEnrollmentToolsDocument`,
    {
      role,
      extension,
      resourceId,
    }
  );
}
