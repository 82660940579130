import React, { useEffect, useState } from "react";
import {
  PdsButton,
  PdsCard,
  PdsLink,
  PdsPaginationItem,
  PdsTooltip,
  PdsPagination,
  PdsDataTableCell,
  PdsDataTableRow,
  PdsDataTable,
  PdsDataTableColumns,
  PdsHeading,
  PdsDataTableColumn,
  PdsDataTableRows,
} from "@principal/design-system-react";
import {
  PdsIconCheck,
  PdsIconHelpCircle,
} from "@principal/design-system-icons-react";
import { themedefault } from "@principal/design-system-tokens";
import "./enrollment-history.scss";
import { Page } from "../page";
import InfoCircle from "../info-circle/InfoCircle";
import FilterButtons from "../button-toggle/button-toggle";
import { ParticipantEvent } from "../../models/ParticipantEvent";
import OpenEventRect from "../enroll-events/openEventRect";
import Participant from "../../reducers/participant";
import { original } from "@reduxjs/toolkit";

const ITEMS_PER_PAGE = 5;
const MAX_PAGE_NUMBERS = 3;

interface EnrollementHistoryProps {
  events: ParticipantEvent[];
  openEvents: ParticipantEvent[];
  allEvents: ParticipantEvent[];
}

const EnrollmentHistory: React.FC<EnrollementHistoryProps> = ({
  events,
  openEvents,
  allEvents,
}) => {
  const [filter, setFilter] = useState("all");
  const [currentPage, setCurrentPage] = useState(1);
  const [paginatedEvents, setPaginatedEvents] = useState<ParticipantEvent[]>(
    []
  );
  const [pageRange, setPageRange] = useState<number[]>([]);
  const [totalP, setTotalPages] = useState(0);

  const handleFilterChange = (value: string) => {
    setFilter(value);
    setCurrentPage(1);
  };

  useEffect(() => {
    const filteredEvents = allEvents.filter((event) => {
      if (filter === "confirmed") {
        return (
          event.electionSummary.isParticipantEnrollmentCompleted ||
          event.electionSummary.isParticipantDeclinedEnrollment
        );
      } else if (filter === "notConfirmed") {
        return (
          !event.electionSummary.isParticipantDeclinedEnrollment &&
          !event.electionSummary.isParticipantEnrollmentCompleted &&
          event.eventConfiguration.isEventClosed
        );
      } else {
        return (
          event.eventConfiguration.isEventClosed ||
          event.electionSummary.isParticipantEnrollmentCompleted ||
          event.electionSummary.isParticipantDeclinedEnrollment
        );
      }
    });
    const renderEvents = () => {
      const filtered = filteredEvents;
      filtered.sort(
        (event1, event2) =>
          new Date(event2.eventConfiguration.effectiveDate).getTime() -
          new Date(event1.eventConfiguration.effectiveDate).getTime()
      );
      const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
      const endIndex = startIndex + ITEMS_PER_PAGE;
      setPaginatedEvents(filtered.slice(startIndex, endIndex));
      setTotalPages(getTotalPages(filtered.length));
      const totalPages = getTotalPages(filtered.length);
      const activePageIndex = currentPage - 1;
      const middleIndex = Math.floor(MAX_PAGE_NUMBERS / 2);
      const startPage = Math.max(0, activePageIndex - middleIndex);
      const endPage = Math.min(
        totalPages - 1,
        startPage + MAX_PAGE_NUMBERS - 1
      );
      setPageRange(
        Array.from(
          { length: endPage - startPage + 1 },
          (_, index) => startPage + index
        )
      );
    };
    renderEvents();
  }, [events, filter, currentPage, ITEMS_PER_PAGE, MAX_PAGE_NUMBERS]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };
  const getTotalPages = (totalItems: number) => {
    return Math.ceil(totalItems / ITEMS_PER_PAGE);
  };

  const getFormattedPlanType = (planType: string) => {
    if (planType === "ANNUAL" || planType === "NEW") {
      return planType.charAt(0).toUpperCase() + planType.slice(1).toLowerCase();
    }
    return planType;
  };

  const checkPagination = (summary: string, pageNumber: number) => {
    switch (summary) {
      case "fly-last":
        setCurrentPage(Number(totalP));
        break;
      case "step-forward":
        if (Number(totalP) > currentPage) {
          setCurrentPage(Number(currentPage + 1));
        }
        break;
      case "step-backward":
        if (currentPage > 1) {
          setCurrentPage(Number(currentPage - 1));
        }
        break;
      case "fly-first":
        setCurrentPage(1);
        break;
      default:
        setCurrentPage(pageNumber);
    }
  };

  const hasHistory = paginatedEvents.length > 0;
  const hasActionTakenEvents = paginatedEvents.some(
    (event) =>
      event.electionSummary.isParticipantEnrollmentCompleted ||
      event.electionSummary.isParticipantDeclinedEnrollment
  );
  const hasNoActionTakenEvents = paginatedEvents.some(
    (event) =>
      !event.electionSummary.isParticipantDeclinedEnrollment &&
      !event.electionSummary.isParticipantEnrollmentCompleted &&
      event.eventConfiguration.isEventClosed
  );

  return (
    <div className="card-format">
      <PdsCard onClick={function ou() {}} variant="bare">
        <div slot="header">
          <PdsHeading headingTag="h2" variant="display-default">
            <p className="event-history-heading">
              Event history
              <PdsTooltip placement="right" variant={undefined}>
                <PdsButton
                  ariaLabel="This button opens a tooltip"
                  size="sm"
                  slot="trigger"
                  variant="icon"
                >
                  <PdsIconHelpCircle color="#0061a0" />
                </PdsButton>

                <p style={{ fontSize: "150%" }}>Event History:</p>
                <p style={{ fontSize: "130%" }}>
                  Comprehensive record of a participant's election choices and
                  information.
                </p>
              </PdsTooltip>
            </p>
          </PdsHeading>
          <div slot="header">
            <p className="filter-button-position">
              <FilterButtons
                activeFilter={filter}
                onFilterChange={handleFilterChange}
              />
            </p>
          </div>
        </div>
        <div>
          {hasHistory ? (
            <>
              {filter === "confirmed" && !hasActionTakenEvents && (
                <div style={{ textAlign: "center", marginTop: "5%" }}>
                  <p>No history of events to show.</p>
                  <p>
                    As soon as you have taken action, your choice will show
                    here.
                  </p>
                </div>
              )}
              {filter === "notConfirmed" && !hasNoActionTakenEvents && (
                <div style={{ textAlign: "center", fontSize: "150%" }}>
                  <p>No history of events to show.</p>
                  <p>
                    As soon as you have elected not to take action, your choice
                    will show here.
                  </p>
                </div>
              )}
              {filter === "all" && !hasHistory && (
                <div style={{ textAlign: "center", marginTop: "5%" }}>
                  <p>No history of events to show.</p>
                  <p>
                    With the 'All events' tab, track your plan enrollment and
                    see all actions and events in one place.
                  </p>
                </div>
              )}
            </>
          ) : (
            <>
              {filter === "confirmed" && (
                <div style={{ textAlign: "center", marginTop: "3%" }}>
                  <p>No history of events to show.</p>
                  <p>
                    As soon as you have taken action, your choice will show
                    here.
                  </p>
                </div>
              )}
              {filter === "notConfirmed" && (
                <div style={{ textAlign: "center", marginTop: "3%" }}>
                  <p>No history of events to show.</p>
                  <p>
                    As soon as you have elected not to take action, your choice
                    will show here.
                  </p>
                </div>
              )}
              {filter === "all" && (
                <div style={{ textAlign: "center", marginTop: "3%" }}>
                  <p>No history of events to show.</p>
                  <p>
                    With the 'All events' tab, track your plan enrollment and
                    see all actions and events in one place.
                  </p>
                </div>
              )}
            </>
          )}

          {hasHistory &&
            paginatedEvents.map((event) => (
              <div key={event.eventId}>
                {event.electionSummary.isParticipantAcceptedEnrollment &&
                event.eventConfiguration.isEventOngoing ? (
                  <div className="enrolled-box">
                    <p className="currently-enrolled">Currently enrolled</p>
                    <div className="event-separator-current" />
                    <p className="event-effective-year-current-enrollment">
                      {event.eventConfiguration.effectiveDateYear}{" "}
                      {getFormattedPlanType(event.eventConfiguration.eventType)}{" "}
                      enrollment
                    </p>
                    <p
                      className="confirmation-message"
                      style={{ marginTop: "-1%", marginLeft: "1%" }}
                    >
                      You confirmed your election for this nonqualified plan on{" "}
                      {new Date(
                        event.eventConfiguration.effectiveDate
                      ).toLocaleDateString()}
                    </p>
                    <div style={{ marginTop: "-5%", marginLeft: "73%" }}>
                      <PdsButton onClick={function ou() {}}>
                        <div className="button-text-current">
                          {" "}
                          View confirmation
                        </div>
                      </PdsButton>
                    </div>
                  </div>
                ) : event.electionSummary.isParticipantDeclinedEnrollment &&
                  event.eventConfiguration.isEventOngoing ? (
                  <div className="enrolled-box">
                    <p className="currently-enrolled">Currently enrolled</p>
                    <div className="event-separator-current" />
                    <p className="event-effective-year-current-enrollment">
                      {event.eventConfiguration.effectiveDateYear}{" "}
                      {getFormattedPlanType(event.eventConfiguration.eventType)}{" "}
                      enrollment
                    </p>
                    <p
                      className="confirmation-message"
                      style={{ marginTop: "-1%", marginLeft: "1%" }}
                    >
                      You confirmed your election for this nonqualified plan on{" "}
                      {new Date(
                        event.eventConfiguration.effectiveDate
                      ).toLocaleDateString()}
                    </p>
                    <div style={{ marginTop: "-5%", marginLeft: "68.5%" }}>
                      <PdsButton disabled onClick={function ou() {}}>
                        <div className="button-text-current">
                          {" "}
                          Declined to participate
                        </div>
                      </PdsButton>
                    </div>
                  </div>
                ) : event.electionSummary.isParticipantAcceptedEnrollment &&
                  event.eventConfiguration.isEventClosed ? (
                  <div style={{ paddingTop: "3%" }}>
                    <p
                      className="event-effective-year"
                      style={{ color: "#004C97" }}
                    >
                      {event.eventConfiguration.effectiveDateYear}{" "}
                      {getFormattedPlanType(event.eventConfiguration.eventType)}{" "}
                      enrollment
                    </p>
                    <p
                      className="confirmation-message"
                      style={{ paddingLeft: "3%", marginTop: "-1%" }}
                    >
                      You confirmed your election for this nonqualified plan on{" "}
                      {new Date(
                        event.eventConfiguration.effectiveDate
                      ).toLocaleDateString()}
                    </p>
                    <div className="button-placement">
                      <PdsButton onClick={function ou() {}}>
                        <div className="button-text"> View confirmation</div>
                      </PdsButton>
                    </div>
                    <div className="event-separator" />
                  </div>
                ) : event.electionSummary.isParticipantDeclinedEnrollment &&
                  event.eventConfiguration.isEventClosed ? (
                  <div style={{ paddingTop: "3%" }}>
                    <p className="event-effective-year">
                      {event.eventConfiguration.effectiveDateYear}{" "}
                      {getFormattedPlanType(event.eventConfiguration.eventType)}{" "}
                      enrollment
                    </p>
                    <p
                      className="confirmation-message"
                      style={{ paddingLeft: "3%", marginTop: "-1%" }}
                    >
                      This plan closed on{" "}
                      {new Date(
                        event.eventConfiguration.effectiveDate
                      ).toLocaleDateString()}
                      . Our records show you chose not to participate.
                    </p>
                    <div className="button-placement-did-not-want-to-participate">
                      <PdsButton disabled onClick={function ou() {}}>
                        <div className="button-text">
                          {" "}
                          Declined to participate
                        </div>
                      </PdsButton>
                    </div>
                    <div className="event-separator" />
                  </div>
                ) : event.eventConfiguration.isEventClosed ? (
                  <div style={{ paddingTop: "3%" }}>
                    <div>
                      <p className="event-effective-year">
                        {event.eventConfiguration.effectiveDateYear}{" "}
                        {getFormattedPlanType(
                          event.eventConfiguration.eventType
                        )}{" "}
                        enrollment
                      </p>
                      <p
                        className="confirmation-message"
                        style={{ paddingLeft: "3%", marginTop: "-1%" }}
                      >
                        This plan closed on{" "}
                        {new Date(
                          event.eventConfiguration.effectiveDate
                        ).toLocaleDateString()}
                        . We have no records of you confirming your elections.
                      </p>
                      <div className="button-placement-no-action">
                        <PdsButton disabled onClick={function ou() {}}>
                          <div className="button-text"> No action taken</div>
                        </PdsButton>
                      </div>
                    </div>
                    <div className="event-separator" />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            ))}
        </div>
        {hasHistory ? (
          <div style={{ marginLeft: "63%", marginTop: "3%" }}>
            <PdsPagination
              variant="default"
              onHandleClick={(e) => {
                // @ts-ignore
                checkPagination(e.detail.summary, Number(e.detail.pageNumber));
              }}
            >
              <React.Fragment key=".0">
                {pageRange.map((pageIndex) => (
                  <PdsPaginationItem
                    key={pageIndex}
                    active={currentPage === pageIndex + 1}
                    onClick={() => handlePageChange(pageIndex + 1)}
                    pageNumber={pageIndex + 1}
                  />
                ))}
              </React.Fragment>
            </PdsPagination>
          </div>
        ) : (
          <div style={{ marginLeft: "67%", marginTop: "3%" }}>
            <PdsPagination
              variant="default"
              onHandleClick={(e) => {
                // @ts-ignore
                checkPagination(e.detail.summary, Number(e.detail.pageNumber));
              }}
            >
              <React.Fragment key=".0">
                {pageRange.map((pageIndex) => (
                  <PdsPaginationItem
                    key={pageIndex}
                    active={currentPage === pageIndex + 1}
                    onClick={() => handlePageChange(pageIndex + 1)}
                    pageNumber={pageIndex + 1}
                  />
                ))}
              </React.Fragment>
            </PdsPagination>
          </div>
        )}
      </PdsCard>
    </div>
  );
};

export default EnrollmentHistory;
