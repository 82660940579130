import React, { useState } from "react";
import { getRoundedValue } from "src/components/numbers/fixed-decimal";
import { ContributionSource } from "src/models/balances-by-source/contribution-source";
import {
  individualInvestmentPerformanceLink,
  investmentAdvisorLink,
} from "src/util/constants";

export interface CollapsableContributionSource extends ContributionSource {
  expanded: boolean;
}

export type contributionSourceProps = {
  contributionSources: CollapsableContributionSource[];
};

export default function ContributionSources(props: contributionSourceProps) {
  const [contributionSources, setContributionSources] = useState(
    props.contributionSources
  );

  const expandIconClicked = (contribution: CollapsableContributionSource) => {
    const newSource = props.contributionSources.slice();
    const index = newSource.findIndex((source) => {
      return source.contributionSourceId === contribution.contributionSourceId;
    });
    newSource[index].expanded = !contribution.expanded;
    setContributionSources(newSource);
  };

  function openInvestmentAdvisor(event: { preventDefault: () => void }) {
    event.preventDefault();
    window.open(investmentAdvisorLink, "popup", "width=600,height=500");
  }

  const openIndividualInvestmentPerformanceLink = (
    investmentId: string | number
  ) => {
    const id = Number(investmentId);
    window.open(individualInvestmentPerformanceLink(id));
  };

  return (
    <>
      <table data-testid="personal-info-table" className="table">
        <tbody>
          <tr style={{ background: "#caebfa", fontWeight: "bold" }}>
            <td className="table-head"></td>
            <td colSpan={2} className="table-head">
              Contribution Source
            </td>
            <td colSpan={1} className="table-head">
              Vested Balance ($)
            </td>
            <td colSpan={1} className="table-head">
              Current Balance ($)
            </td>
            <td colSpan={1} className="table-head">
              Current Balance (%)
            </td>
          </tr>
          {contributionSources.length ? (
            contributionSources.map((contribution) => {
              return (
                <>
                  <tr>
                    <td style={{ margin: "10px 0 0 10px" }}>
                      <a onClick={() => expandIconClicked(contribution)}>
                        {contribution.expanded ? (
                          <i
                            className="fa fa-caret-down transformable"
                            aria-hidden="true"
                          />
                        ) : (
                          <i
                            className="fa fa-caret-right transformable"
                            aria-hidden="true"
                          />
                        )}
                      </a>
                    </td>
                    <td colSpan={2} style={{ fontWeight: "bold" }}>
                      {contribution.contributionSourceName}
                    </td>
                    <td colSpan={1}>
                      ${getRoundedValue(Number(contribution.vestedBalance), 2)}
                    </td>
                    <td colSpan={1}>
                      ${getRoundedValue(Number(contribution.currentBalance), 2)}
                    </td>
                    <td colSpan={1}>
                      {getRoundedValue(Number(contribution.currentPercent), 2)}%
                    </td>
                  </tr>
                  {contribution.expanded ? (
                    <tr>
                      <td
                        colSpan={6}
                        style={{ width: "100%", paddingLeft: "20px" }}
                      >
                        <table className="table">
                          <tbody>
                            <tr
                              style={{
                                background: "#caebfa",
                                fontWeight: "bold",
                              }}
                            >
                              <td
                                colSpan={2}
                                className="table-head"
                                style={{ padding: "10px" }}
                              >
                                <a onClick={openInvestmentAdvisor}>
                                  Investment Advisor/Investment Option
                                </a>
                              </td>
                              <td
                                colSpan={1}
                                className="table-head"
                                style={{ padding: "10px" }}
                              >
                                Current Balance
                              </td>
                              <td
                                colSpan={1}
                                className="table-head"
                                style={{ padding: "10px" }}
                              >
                                Share Price/Unit Value
                              </td>
                              <td
                                colSpan={1}
                                className="table-head"
                                style={{ padding: "10px" }}
                              >
                                Vested Shares/Units
                              </td>
                            </tr>
                            {contribution.investments.map((investment) => {
                              return (
                                <tr>
                                  <td colSpan={2} style={{ padding: "10px" }}>
                                    <span
                                      style={{
                                        fontStyle: "italic",
                                        color: "lightslategray",
                                      }}
                                    >
                                      {investment.subAdvisor}
                                    </span>
                                    <br></br>
                                    <span>
                                      <a
                                        onClick={() =>
                                          openIndividualInvestmentPerformanceLink(
                                            investment.investmentId || ""
                                          )
                                        }
                                      >
                                        {investment.investmentName}
                                      </a>
                                    </span>
                                  </td>
                                  <td colSpan={1} style={{ padding: "10px" }}>
                                    $
                                    {getRoundedValue(
                                      Number(investment.currentBalance),
                                      2
                                    )}
                                  </td>
                                  <td colSpan={1} style={{ padding: "10px" }}>
                                    {getRoundedValue(
                                      Number(investment.sharePrice),
                                      2
                                    )}
                                  </td>
                                  <td colSpan={1} style={{ padding: "10px" }}>
                                    {getRoundedValue(
                                      Number(investment.shares),
                                      2
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  ) : (
                    ""
                  )}
                </>
              );
            })
          ) : (
            <tr>
              <td colSpan={6}>
                No data is available for the selections made. Please change your
                selections.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
}
