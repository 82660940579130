import _find from "lodash/find";
import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDropdownStacked,
  AccordionWell,
} from "../../../components/accordion/accordion";
import { AccountWithUnscheduledDistributions } from "../../../models/account";
import { DistributionPayment } from "../../../models/distributions/distribution-payment";
import { ScheduledDistributionEvent } from "../../../models/distributions/scheduled-distribution-event";
import { ScheduledDistributionElection } from "../../../models/distributions/ScheduledDistributionElection";
import {
  accountsWithUnscheduledDistributionsSelector,
  accountsWithUnscheduledDistributionsSelectorNotFiltered,
} from "../../../selectors/account-distributions";
import { distributionPaymentsSelector } from "../../../selectors/distribution-payment";
import { useScheduledDistributionsWithAccounts } from "../../../selectors/scheduled-distribution-events";
import { filteredScheduledElectionsSelector } from "../../../selectors/scheduled-elections";
import { useSelector } from "../../../store/store";
import { findScheduledDistributionByAccountId } from "../distribution-scheduled/scheduled-distribution-util";
import AccountDistributionDetailAccordion from "./account-distribution-detail";
import "./account-distribution-details.scss";
import AccountDistributionsSummary from "./account-distributions-summary";
import { SingleAccountDistributionDetail } from "./single-account-distribution-detail";
import AccountName from "../../../components/account-name/account-name";

export interface Props {
  showPaidOutAccounts?: boolean;
  isAccountSummary?: boolean;
}

let firstScheduledDistribution: ScheduledDistributionEvent;
let firstScheduledDistributionElection: ScheduledDistributionElection;

export default function AccountDistributionDetails(props: Props) {
  const { showPaidOutAccounts, isAccountSummary } = props;
  const distributionPayments: DistributionPayment[] = useSelector(
    distributionPaymentsSelector
  );
  const selectedAccounts = useSelector((state) => state.accounts.selected);
  const filteredAccounts = useSelector(
    accountsWithUnscheduledDistributionsSelector
  );
  const unfilteredAccounts = useSelector(
    accountsWithUnscheduledDistributionsSelectorNotFiltered
  );
  const [accounts, setAccounts] = useState(filteredAccounts);
  const scheduledDistributions = useScheduledDistributionsWithAccounts();
  const scheduledElections = useSelector(filteredScheduledElectionsSelector);

  useEffect(() => {
    if (showPaidOutAccounts) {
      setAccounts(unfilteredAccounts);
    } else {
      setAccounts(filteredAccounts);
    }
  }, [showPaidOutAccounts, selectedAccounts]);

  if (accounts.length === 1) {
    const account = accounts[0];
    return (
      <SingleAccountDistributionDetail
        account={account}
        distributionPayments={distributionPayments}
        isAccountSummary={isAccountSummary}
      />
    );
  }

  return (
    <>
      {accounts.map((account: AccountWithUnscheduledDistributions, index) => {
        const { id, name } = account;

        firstScheduledDistribution = findScheduledDistributionByAccountId(
          scheduledDistributions,
          id
        )!;

        firstScheduledDistributionElection = _find(
          scheduledElections,
          (distribution) => {
            return distribution.accountId === id;
          },
          0
        )!;

        return (
          <div className="account-details" key={id}>
            <Accordion name={name} id={id}>
              <div className="row">
                <AccordionDropdownStacked
                  id={id}
                  name={
                    <AccountName name={name} id={account.id} asLink={false} />
                  }
                >
                  <AccountDistributionsSummary
                    account={account}
                    firstScheduledDistribution={firstScheduledDistribution}
                    firstScheduledDistributionElection={
                      firstScheduledDistributionElection
                    }
                  />
                </AccordionDropdownStacked>
              </div>
              <div className="row">
                <AccordionWell id={id} classNames={["well"]}>
                  <AccountDistributionDetailAccordion
                    account={account}
                    firstFutureScheduledDistribution={
                      firstScheduledDistribution
                    }
                  />
                </AccordionWell>
              </div>
            </Accordion>
            <hr />
          </div>
        );
      })}
    </>
  );
}
