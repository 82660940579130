import React from "react";
import {datesStatusSelector} from "../../selectors/status";
import {useSelector} from "../../store/store";
import {
    getDateStringWithoutTimezoneFromDashDate,
    getLastDayOfPreviousMonth,
    getMonthShortName,
} from "../../util/date-util";
import {Skeleton} from "../skeleton/skeleton";


export function LastBusinessDate() {
    const lastBusinessDate = useSelector((state) => state.dates.lastBusinessDate);
    const date = getDateStringWithoutTimezoneFromDashDate(lastBusinessDate);
    return (
        <Skeleton selectors={[datesStatusSelector]} height={18} width={90}>
            {date}
        </Skeleton>
    );
}

export function LastReceivedDate() {
    const lastReceivedDate: string = calculateLastReceivedDate(new Date());
    return (
        <Skeleton selectors={[datesStatusSelector]} height={18} width={90}>
            {lastReceivedDate}
        </Skeleton>
    );
}

export function calculateLastReceivedDate(currentDate: Date): string {
    const lastDayOfLastMonth = getLastDayOfPreviousMonth(currentDate);

    const dayOfMonthThatAverageIsReceived = 10;

    if (currentDate.getDate() >= dayOfMonthThatAverageIsReceived) {
        return formatDate(lastDayOfLastMonth);
    }

    const lastDayOfTwoMonthsAgo = getLastDayOfPreviousMonth(lastDayOfLastMonth);
    return formatDate(lastDayOfTwoMonthsAgo);
}

function formatDate(input: Date): string {
    const month = input.getMonth();
    const dayOfMonth = input.getDate();
    const year = input.getFullYear();
    return `${getMonthShortName(month)} ${dayOfMonth}, ${year}`
}
