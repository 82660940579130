import React from "react";
import { StepStatus } from "../../models/steps";
import classNames from "classnames";

export interface Props {
  currentPosition: number;
  labels: string[];
  onStepChange?: (position: number) => void;
}

export default function StepIndicator(props: Props) {
  const { currentPosition, labels } = props;

  return (
    <>
      <ol data-testid="step-indicator" className="indicator">
        {labels.map((step, index) => {
          return displayStep(step, currentPosition, index);
        })}
      </ol>
    </>
  );
}

function displayStep(step: string, currentPosition: number, index: number) {
  const getStepStatus = (stepPosition: number) => {
    if (stepPosition === currentPosition) {
      return StepStatus.CURRENT;
    } else if (stepPosition < currentPosition) {
      return StepStatus.FINISHED;
    } else {
      return StepStatus.UNFINISHED;
    }
  };

  const classes = classNames("horizon-step-indicator", {
    "horizon-step-indicator-active":
      getStepStatus(index) === StepStatus.CURRENT,
    "horizon-step-indicator-disabled":
      getStepStatus(index) === StepStatus.UNFINISHED,
    "horizon-step-indicator-previous":
      getStepStatus(index) === StepStatus.FINISHED,
  });

  return (
    <li data-testid={`step-${index}`} key={step} className={classes}>
      <span>{step}</span>
    </li>
  );
}
