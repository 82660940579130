import React from "react";
import { PayCheckFrequency } from "./paycheck-frequency";

type Props = {
  register: (options: any) => React.Ref<any>;
};

export default function ManageContributionsPayCheckFrequencySelector(
  props: Props
): JSX.Element {
  const { register } = props;

  return (
    <div className="frequency-selector" data-testid="payFrequency-select">
      <label htmlFor="payFrequency">Pay frequency</label>
      <select
        ref={register}
        className="form-control"
        data-testid="payFrequencyInput"
        name="payCheckFrequency"
      >
        <option value={PayCheckFrequency.WEEKLY}>
          {PayCheckFrequency.WEEKLY}
        </option>
        <option value={PayCheckFrequency.BIWEEKLY}>
          {PayCheckFrequency.BIWEEKLY}
        </option>
        <option value={PayCheckFrequency.BIMONTHLY}>
          {PayCheckFrequency.BIMONTHLY}
        </option>
        <option value={PayCheckFrequency.MONTHLY}>
          {PayCheckFrequency.MONTHLY}
        </option>
      </select>
    </div>
  );
}
