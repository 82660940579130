import { createSelector } from "@reduxjs/toolkit";
import { InvestmentV2 } from "../models/investment-v2";
import { RootState, useSelector } from "../store/store";
import {
  aggregateByInvestmentId,
  filterInvestmentsByAccountIds,
  flatten,
  getInvestmentByMoneyType,
  reduceInvestments,
} from "../util/investment-context-util";
import {
  filterInvestmentsWithZeroBalance,
  sortInvestments,
} from "../util/investments";
import { selectedAccountIdsSelector } from "./accounts";
import { moneyTypesSelector } from "./plan-info";

const investmentsSelector = (state: RootState) => state.investments.data;

export const selectedInvestmentsSelector = createSelector(
  investmentsSelector,
  selectedAccountIdsSelector,
  (investments, accountIds) =>
    filterInvestmentsByAccountIds(investments, accountIds)
);

export const sortedSelectedInvestmentsSelector = createSelector(
  [selectedInvestmentsSelector],
  (investments): InvestmentV2[] => sortInvestments<InvestmentV2>(investments)
);

createSelector(
  [sortedSelectedInvestmentsSelector],
  (investments): InvestmentV2[] =>
    filterInvestmentsWithZeroBalance<InvestmentV2>(investments)
);

export const aggregatedSelectedInvestmentsSelector = createSelector(
  [sortedSelectedInvestmentsSelector],
  (investments): InvestmentV2[] => {
    const flattened = flatten(aggregateByInvestmentId(investments));
    return sortInvestments<InvestmentV2>(flattened);
  }
);

export const investmentsWithoutActivitySelector = createSelector(
  investmentsSelector,
  (investments) => investments.filter((inv) => !inv.accountId)
);

export const selectedInvestmentsByMoneyTypeSelector = createSelector(
  [selectedInvestmentsSelector, moneyTypesSelector],
  (investments, moneyTypes) => {
    return getInvestmentByMoneyType(investments, moneyTypes);
  }
);

export const aggregatedInvestmentSelector = createSelector(
  [selectedInvestmentsSelector, selectedAccountIdsSelector],
  (investments, accountIds) => {
    const selectedInvestments = filterInvestmentsByAccountIds(
      investments,
      accountIds
    );

    if (selectedInvestments.length === 0) {
      return {} as InvestmentV2;
    }

    return reduceInvestments(selectedInvestments);
  }
);

type InvestmentsSelector = {
  investments: InvestmentV2[];
  filteredInvestments: InvestmentV2[];
  investmentsWithoutActivity: InvestmentV2[];
};

export function useInvestments(): InvestmentsSelector {
  const investments: InvestmentV2[] = useSelector(investmentsSelector);
  const filteredInvestments: InvestmentV2[] = useSelector(
    selectedInvestmentsSelector
  );
  const investmentsWithoutActivity: InvestmentV2[] = useSelector(
    investmentsWithoutActivitySelector
  );

  return {
    investments,
    filteredInvestments,
    investmentsWithoutActivity,
  };
}
