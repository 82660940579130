import React from "react";
import { Page, PageHeader } from "../../components/page";
import { investmentsStatusSelector, useStatuses } from "../../selectors/status";
import "./statements.scss";
import { useSelector } from "../../store/store";
import {
  shouldHideOnDemandStatementSelector,
  shouldHideStatementsSelector,
} from "../../selectors/plan-info";
import PlanStatements from "../overview/planstatements/plan-statements-page";
import OnDemandStatement from "./on-demand-statement-component";

export default function Statements() {
  const { isLoadingStatus } = useStatuses([investmentsStatusSelector]);
  const shouldHideOnDemandStatement = useSelector(
    shouldHideOnDemandStatementSelector
  );
  const shouldHideStatements = useSelector(shouldHideStatementsSelector);
  const statementsNotConfigured =
    shouldHideOnDemandStatement && shouldHideStatements;
  const onlyOndemandEnabled =
    !shouldHideOnDemandStatement && shouldHideStatements;
  const onlyHistoricalEnabled =
    shouldHideOnDemandStatement && !shouldHideStatements;
  const showTabs = !shouldHideOnDemandStatement && !shouldHideStatements;

  return (
    <Page
      className={"statements"}
      name={"statements"}
      loading={isLoadingStatus}
    >
      <div className={"row"}>
        <div className={"col-md-12"}>
          <PageHeader name={"statements"}>Statements</PageHeader>
        </div>
      </div>
      {onlyOndemandEnabled && <OnDemandStatement />}
      {onlyHistoricalEnabled && <PlanStatements />}
      {statementsNotConfigured && (
        <div data-testid="no-statements-configured">
          <p>Your plan has not configured statement views</p>
        </div>
      )}
      {showTabs && (
        <>
          <ul
            className="nav nav-tabs"
            role="tablist"
            data-testid="statements-tab-list"
          >
            <li className="active" role="presentation">
              <a
                id="on-demand-statements-tab"
                data-toggle="tab"
                data-testid={"on-demand-statements-tab"}
                href="#on-demand-statements"
                role="tab"
                aria-expanded="true"
                aria-selected="true"
                tabIndex={0}
              >
                On-demand statements
              </a>
            </li>
            <li role="presentation">
              <a
                id="historical-statements-tab"
                data-toggle="tab"
                data-testid={"historical-statements-tab"}
                href="#historical-statements"
                role="tab"
                aria-expanded="false"
                aria-selected="false"
                tabIndex={-1}
              >
                Historical statements
              </a>
            </li>
          </ul>

          {!statementsNotConfigured && (
            <div className="tab-content">
              {!shouldHideOnDemandStatement && (
                <div
                  className="tab-pane active"
                  id="on-demand-statements"
                  data-testid="on-demand-statements"
                  role="tabpanel"
                >
                  <OnDemandStatement />
                </div>
              )}
              {!shouldHideStatements && (
                <div
                  className="tab-pane"
                  id="historical-statements"
                  data-testid="historical-statements"
                  role="tabpanel"
                >
                  <PlanStatements />
                </div>
              )}
            </div>
          )}
        </>
      )}
    </Page>
  );
}
