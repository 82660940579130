import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AsyncStatus } from "../../models/async-status";
import { getScheduledDistributionsForRedeferral } from "../../services/redeferral";
import { ScheduledDistributionForRedeferral } from "../../models/distributions/scheduled-distribution-event";

export const fetchScheduledDistributionsForRedeferral = createAsyncThunk(
  "fetch-scheduled-distribution-redeferral",
  async (
    {
      planId,
      empId,
      role,
      accountId,
    }: { planId: number; empId: number; role: string; accountId: number },
    thunkApi
  ) => {
    return getScheduledDistributionsForRedeferral({
      planId,
      empId,
      role,
      accountId,
    });
  }
);

type State = {
  scheduledDistributionsForRedeferral: ScheduledDistributionForRedeferral[];
  status: AsyncStatus;
};

const initialState: State = {
  scheduledDistributionsForRedeferral: [],
  status: AsyncStatus.not_fetched,
};

const scheduledDistributionsForRedeferralSlice = createSlice({
  name: "scheduledDistributionsForRedeferral",
  initialState,
  reducers: {
    updateScheduledDistribution: (
      state,
      action: { payload: ScheduledDistributionForRedeferral; type: string }
    ) => {
      const scheduledDistributionForRedeferral = action.payload;
      const scheduledDistributionsForRedeferral: ScheduledDistributionForRedeferral[] =
        state.scheduledDistributionsForRedeferral.map((x) => {
          if (x.installNum === scheduledDistributionForRedeferral.installNum) {
            return scheduledDistributionForRedeferral;
          }
          return x;
        });
      return {
        scheduledDistributionsForRedeferral,
        status: AsyncStatus.done,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchScheduledDistributionsForRedeferral.pending,
      (state, action) => {
        return { ...state, status: AsyncStatus.loading };
      }
    );
    builder.addCase(
      fetchScheduledDistributionsForRedeferral.fulfilled,
      (
        state,
        action: { payload: ScheduledDistributionForRedeferral[]; type: string }
      ) => {
        const scheduledDistributionsForRedeferral = action.payload;

        return {
          ...state,
          scheduledDistributionsForRedeferral,
          status: AsyncStatus.done,
        };
      }
    );
    builder.addCase(
      fetchScheduledDistributionsForRedeferral.rejected,
      (state, action) => {
        return { ...state, status: AsyncStatus.error };
      }
    );
    builder.addDefaultCase((state, action) => state);
  },
});

export const { updateScheduledDistribution } =
  scheduledDistributionsForRedeferralSlice.actions;
export default scheduledDistributionsForRedeferralSlice.reducer;
