import axios from "axios";
import { loggingEndpoint, apiHost } from "../util/constants";
import { UiLogBody } from "../models/logging/UiLogBody";

const url = apiHost + loggingEndpoint;

export async function logUnauthenticated(
  logBody: UiLogBody | null | undefined
) {
  const { data } = await axios.post(url, logBody);
  return data;
}
