import { get } from "../repositories/participant";
import {
  get as getSalesforce,
  post as postSalesforce,
} from "../repositories/salesforce";

type Args = {
  planId: number;
  empId: number;
  role?: string;
};

type PostArgs = {
  planId: number;
  empId: number;
  data: any;
};

export async function getPersonalInformation({ planId, empId, role }: Args) {
  return get(`/employees/${empId}/plans/${planId}/employee-details`, { role });
}

export async function getPaymentInformation({ planId, empId }: Args) {
  return getSalesforce(`/plans/${planId}/employees/${empId}/ach/`);
}

export async function postPaymentInformation({
  planId,
  empId,
  data,
}: PostArgs) {
  return postSalesforce(`/plans/${planId}/employees/${empId}/ach/`, data);
}

export async function getBankStateValues() {
  return getSalesforce(`/values/states/`);
}

export async function getBankAccountTypeValues() {
  return getSalesforce("/values/bankAccountTypes/");
}

export async function getSFPlanConfig({ planId, empId, role }: Args) {
  return getSalesforce(`/plans/${planId}/employees/${empId}/plan`, { role });
}

export async function getSFHealthResponse() {
  return getSalesforce(`/actuator/health`);
}
