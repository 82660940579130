import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import DistributionScheduledPage from "../pages/distributions/distribution-scheduled/distribution-scheduled-page";
import DistributionsSummaryPage from "../pages/distributions/distribution-summary/distribution-summary-page";
import RedeferSummaryPage from "../pages/distributions/redefer/redefer-summary-page";
import RedeferAccountPage from "../pages/distributions/redefer/redefer-account-page";
import RedeferralBeginPage from "../pages/distributions/redefer/redefer-begin-page";
import RedeferralReviewPage from "../pages/distributions/redefer/redefer-review-page";
import RedeferralConfirmPage from "../pages/distributions/redefer/redefer-confirm-page";
import { DistributionWrapper } from "../wrappers/distributionWrapper";
import RedeferIndividualWrapper from "../pages/distributions/redefer-individual/redefer-individual-wrapper";
import StreamOfPaymentPendingPage from "../pages/stream-of-payment-pending/stream-of-payment-pending-page";
import RedeferIndividualPaymentReview from "../pages/distributions/redefer/redefer-individual/redefer-individual-payment-review";
import RedeferIndividualPaymentConfirm from "../pages/distributions/redefer/redefer-individual/redefer-individual-payment-confirm";

export default function DistributionRoutes() {
  const match = useRouteMatch();

  return (
    <Switch>
      <DistributionWrapper>
        <Route path={`${match.url}/summary`}>
          <DistributionsSummaryPage />
        </Route>
        <Route path={`${match.url}/scheduled`}>
          <DistributionScheduledPage />
        </Route>
        <Route path={`${match.url}/redefer/individual`}>
          <RedeferIndividualWrapper />
        </Route>
        <Route path={`${match.url}/redeferral/streamOfPaymentPending`}>
          <StreamOfPaymentPendingPage />
        </Route>
        <Route path={`${match.url}/redeferral/summary`}>
          <RedeferSummaryPage />
        </Route>
        <Route path={`${match.url}/redeferral/account/:accountId`}>
          <RedeferAccountPage />
        </Route>
        <Route path={`${match.url}/redeferral/begin/account/:accountId`}>
          <RedeferralBeginPage />
        </Route>
        <Route path={`${match.url}/redeferral/review/account/:accountId`}>
          <RedeferralReviewPage />
        </Route>
        <Route
          path={`${match.url}/redeferral/confirm/account/:accountId/instruction/:instructionId`}
        >
          <RedeferralConfirmPage />
        </Route>
        <Route
          path={`${match.url}/redeferral/individual/review/account/:accountId`}
        >
          <RedeferIndividualPaymentReview />
        </Route>
        <Route
          path={`${match.url}/redeferral/individual/confirm/account/:accountId`}
        >
          <RedeferIndividualPaymentConfirm />
        </Route>
      </DistributionWrapper>
    </Switch>
  );
}
