import { html, nothing } from 'lit';
import { property, query, queryAssignedElements } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { pdsCustomElement as customElement } from '../../decorators/pds-custom-element';
import { PdsElement } from '../PdsElement';
import styles from './footer.scss?inline';
import '@principal/design-system-icons-web/facebook';
import '@principal/design-system-icons-web/twitter';
import '@principal/design-system-icons-web/linkedin';
import '@principal/design-system-icons-web/youtube';
import '@principal/design-system-icons-web/instagram';
import '@principal/design-system-icons-web/whatsapp';
import '../band/band';
import '../accordion/accordion';
import '../footer-nav/footer-nav';
import '../footer-nav-item/footer-nav-item';
import '../heading/heading';
import '../layout-container/layout-container';
import '../link/link';
import '../list/list';
import '../list-item/list-item';
import '../logo/logo';
import '../footer-contact-link/footer-contact-link';

export type SocialIconObject = {
  type:
    | 'facebook'
    | 'linkedin'
    | 'twitter'
    | 'youtube'
    | 'instagram'
    | 'whatsapp';
  url: string;
  ariaLabel: string;
};

/**
 * @summary The Principal footer component
 *
 * @slot default Optional: A pds-footer-nav element containing one or more pds-footer-nav-item elements
 * @slot heading Optional: Heading for the footer
 * @slot additional-info Optional: Adds an additional info section into the superFooter
 * @slot legal-text Optional: Custom legal text
 * @slot logo Optional: Custom logo, restricted to pds-logo, img, and svg elements
 * @slot custom-contact Optional: Custom contact links, restricted to pds-list elements
 * @slot custom-legal-links Optional: Custom links appended to the end of the Legal links, restricted to pds-list elements
 * @slot social-icons Optional: Override of the default social icons, restricted to pds-list elements
 */
@customElement('pds-footer', {
  category: 'component',
  type: 'component',
  state: 'stable',
  styles,
})
export class PdsFooter extends PdsElement {
  connectedCallback() {
    super.connectedCallback();
    this.initLocalization();
  }
  /**
   * - **default** renders the footer-nav used for primary actions on a dark background
   * - **subtle** renders the footer-nav-item used for primary actions on a subtle background
   */

  @property({ type: String })
  variant: 'default' | 'subtle' = 'default';

  /**
   * - Specifes target to open the linked document
   */
  @property()
  target?: '_self' | '_blank' | '_parent' | '_top' = '_self';

  /**
   * Specifies information about a linked document
   * Automatically set to 'noopener noreferrer' when target is '_blank'
   */
  @property()
  rel?: string;

  /**
   * Hide default contact phone link
   */
  @property({ type: Boolean })
  hideContactPhone: boolean = false;

  /**
   * Hide default help link
   */
  @property({ type: Boolean })
  hideHelpLink: boolean = false;

  /**
   * Hide default contact link
   */
  @property({ type: String })
  hideContactLink: boolean = false;

  /**
   * Link for 'Trouble logging in?' prompt
   */
  @property({ type: String })
  loginSupportLink: string = 'https://www.principal.com/help';

  /**
   * Web link for terms of use guidelines
   */
  @property({ type: String })
  termsOfUseLink: string = 'https://www.principal.com/terms-of-use';

  /**
   * Web link for disclosures
   */
  @property({ type: String })
  disclosuresLink: string =
    'https://www.principal.com/products-services-disclosures';

  /**
   * Web link for privacy policy
   */
  @property({ type: String })
  privacyLink: string = 'https://www.principal.com/privacy-policies';

  /**
   * Web link for security policy
   */
  @property({ type: String })
  securityLink: string = 'https://www.principal.com/security-policies';

  /**
   * Web link for reporting fraud
   */
  @property({ type: String })
  reportFraudLink: string =
    'https://www.principal.com/about-us/our-company/policies/report-fraud-or-unethical-conduct';

  /**
   * Web link for accessibility commitment
   */
  @property({ type: String })
  accessibilityLink: string =
    'https://www.principal.com/accessibility-commitment';

  /**
   * Context-specific footer behavior
   * - **login** provides a link for login assistance
   * - **super** provides logo above an optional footer nav populated with footer nav items
   */
  @property({ type: String })
  behavior: 'login' | 'super' | 'default' = 'default';

  /**
   * Link for the logo
   */
  @property({ type: String })
  logoHref: string = 'https://www.principal.com';

  /**
   * String for a custom aria label describing the logo element
   * - By default, aria label for the logo will read "Link to Principal homepage"
   * - Pass a string of "false" in order to suppress the aria label attribute
   */
  @property()
  logoAriaLabel: string;

  /**
   * Set to true to hide the logo in a super footer. This is NOT recommended unless you have a sticky header that will cause incorrect vertical alignment on the page.
   */
  @property({ type: Boolean })
  hideLogo: boolean = false;

  /**
   * Hide the social icons
   */
  @property({ type: Boolean })
  hideSocialIcons: boolean = false;

  /**
   * show mobile app badges
   */
  @property({ type: Boolean })
  showMobileAppBadges: boolean = false;

  /**
   * Hide all the legal links
   */
  @property({ type: Boolean })
  hideLegalLinks: boolean = false;

  /**
   * Hide terms of use link
   */
  @property({ type: Boolean })
  hideTermsOfUseLink: boolean = false;

  /**
   * Hide disclosure link
   */
  @property({ type: Boolean })
  hideDisclosureLink: boolean = false;

  /**
   * Hide privacy link
   */
  @property({ type: Boolean })
  hidePrivacyLink: boolean = false;

  /**
   * Hide security link
   */
  @property({ type: Boolean })
  hideSecurityLink: boolean = false;

  /**
   * Hide report fraud link
   */
  @property({ type: Boolean })
  hideReportFraudLink: boolean = false;

  /**
   * Hide accesibility commitment link
   */
  @property({ type: Boolean })
  hideAccessibilityLink: boolean = false;

  /**
   * Aria label for legal-nav
   */
  @property()
  legalNavAriaLabel: string;

  /**
   * variant for layout container
   * - **narrow** renders the layout-container narrower than the default
   */
  @property({ type: String })
  layoutContainerVariant: 'default' | 'narrow' = 'default';

  /**
   * Remove layout container padding
   * - **md** removes padding from the layout container below md breakpoint
   * - **all** removes padding from the layout container at all screens (used for nested layout containers)
   */
  @property({ type: String })
  layoutContainerRemovePadding?: 'md' | 'all';

  /**
   * @internal
   */
  @queryAssignedElements({ slot: undefined, selector: 'pds-footer-nav' })
  footerNavs: HTMLElement[];

  /**
   * @internal
   */
  @queryAssignedElements({ slot: 'custom-contact' })
  customContactLinks: HTMLElement[];

  /**
   * @internal
   */
  @queryAssignedElements({ slot: 'custom-legal-links' })
  customLegalLinks: HTMLElement[];

  /**
   * @internal
   */
  @queryAssignedElements({ slot: 'social-icons' })
  customSocialIcons: HTMLElement[];

  /**
   * @internal
   */
  @query('.pds-c-footer__contact-navigation')
  contactNav: HTMLElement;

  /**
   * @internal
   */
  @query('.pds-c-footer__legal-nav')
  legalNav: HTMLElement;

  /**
   * @internal
   */
  addVariantToFooterNavs() {
    const accordionVariant = this.variant === 'default' ? 'inverted' : 'strong';

    if (this.footerNavs && this.footerNavs.length !== 0) {
      this.footerNavs.forEach((footerNavItem) => {
        footerNavItem.setAttribute('variant', accordionVariant);
      });
    }

    return '';
  }

  protected override firstUpdated() {
    super.firstUpdated();
    this.setWindowResizeHandler();
    this.addVariantToFooterNavs();
    this.setCustomSocialIconStyles();
    this.handleSlotValidation('custom-contact');
    this.handleSlotValidation('social-icons');
    // Need to wrap this in a setTimeout to prevent
    // hydration issues with the custom legal links
    // since they are appended in the DOM
    setTimeout(() => {
      this.insertCustomLegalLinks();
    }, 100);
  }

  updated() {
    this.setCustomContactStyles();
    this.setContactNavStyles();
  }

  /**
   * @internal
   */
  getCurrentYear(): string {
    return new Date().getFullYear().toString();
  }

  /**
   * @internal
   */
  setLinkVariant() {
    // this assignment looks counterintuitive, but the "default" footer color scheme is inverted, not default
    return this.variant === 'default' ? 'inverted' : 'default';
  }

  /**
   * @internal
   */
  setBandVariant() {
    return this.variant === 'default' ? 'strong' : 'subtle';
  }

  /**
   * @internal
   */
  setEmphasisLinkVariant() {
    return this.variant === 'default' ? 'emphasis-inverted' : 'emphasis';
  }

  /**
   * @internal
   */
  setAccordionVariant() {
    return this.variant === 'default' ? 'inverted' : 'strong';
  }

  /**
   * @internal
   */
  loginSupport() {
    return html`<div class="${this.classEl('login-support')}">
      ${this.translateText('trouble-logging-in')}
      <pds-link
        variant="${this.setEmphasisLinkVariant()}"
        href="${this.loginSupportLink}"
        target="${ifDefined(this.target)}"
        rel=${ifDefined(this.rel)}
        >${this.translateText('get-help')}</pds-link
      >
    </div>`;
  }

  /**
   * @internal
   * If the user passes in a custom aria-label, that will be populated.
   * If not, the label will be automated and language localized.
   */
  getLogoAriaLabel() {
    if (!this.logoAriaLabel) {
      const localizedAriaLabel = this.translateText(
        'link-to-principal-homepage',
      );
      return localizedAriaLabel;
    }
    if (this.logoAriaLabel && this.logoAriaLabel !== 'false') {
      return this.logoAriaLabel;
    }
    return nothing;
  }

  /**
   * @internal
   */
  getLegalNavAriaLabel() {
    if (this.legalNavAriaLabel) {
      return this.legalNavAriaLabel;
    }

    return this.translateText('principal-legal-links');
  }

  /**
   * @internal
   */
  get classNames() {
    return {
      [this.variant]: !!this.variant,
    };
  }

  // Extracts custom legal links from slotted list and appends to the default legal links list
  insertCustomLegalLinks() {
    this.handleSlotValidation('custom-legal-links');

    if (this.customLegalLinks.length > 0) {
      const customLegalLinks =
        this.customLegalLinks[0].querySelectorAll('pds-list-item');

      customLegalLinks.forEach((link) => {
        this.renderRoot
          .querySelector(`.${this.classEl('extra-links')}`)
          ?.append(link);
      });
    }
  }

  /**
   * @internal
   */
  isSmallScreen() {
    return (
      this.responsiveViewportSize === 'xs' ||
      this.responsiveViewportSize === 'sm'
    );
  }

  /**
   * @internal
   */
  showContactRow() {
    return (
      this.hideContactPhone === false ||
      this.hideHelpLink === false ||
      this.hideContactLink === false
    );
  }

  /**
   * @internal
   */
  getContactListMarkup() {
    return html` <slot allowed-elements="pds-list" name="custom-contact">
      <pds-list
        orientation="${!this.isSmallScreen() ? 'horizontal' : 'default'}"
        class="${!this.isSmallScreen() && this.showContactRow()
          ? this.classEl('contact-row')
          : nothing}"
        title="Contact"
      >
        ${this.hideContactPhone
          ? nothing
          : html` <pds-list-item>
              <pds-footer-contact-link
                variant="${this.setLinkVariant()}"
                linkCategory="phone"
              ></pds-footer-contact-link>
            </pds-list-item>`}
        ${this.hideHelpLink
          ? nothing
          : html` <pds-list-item>
              <pds-footer-contact-link
                variant="${this.setLinkVariant()}"
                linkCategory="help"
              ></pds-footer-contact-link>
            </pds-list-item>`}
        ${this.hideContactLink
          ? nothing
          : html` <pds-list-item>
              <pds-footer-contact-link
                variant="${this.setLinkVariant()}"
                linkCategory="email"
              ></pds-footer-contact-link>
            </pds-list-item>`}
      </pds-list></slot
    >`;
  }

  /**
   * @internal
   */
  getContactMarkup() {
    return this.isSmallScreen()
      ? html` <pds-accordion
          class="${this.classEl('accordion')}"
          variant=${this.setAccordionVariant()}
        >
          <pds-heading
            variant="label-default"
            headingTag="h2"
            slot="summary-title"
            >${this.translateText('contact')}</pds-heading
          >
          <span slot="accordion-content">${this.getContactListMarkup()}</span>
        </pds-accordion>`
      : html`${this.getContactListMarkup()}`;
  }

  /**
   * @internal
   * Sets proper styling for slotted custom links so adopter doesn't need to add these
   */
  setCustomContactStyles() {
    if (this.customContactLinks && this.customContactLinks.length > 0) {
      if (!this.isSmallScreen()) {
        this.customContactLinks[0].setAttribute('orientation', 'horizontal');
        this.customContactLinks[0].classList.add(this.classEl('contact-row'));
      } else {
        this.customContactLinks[0].removeAttribute('orientation');
        this.customContactLinks[0].classList.remove(
          this.classEl('contact-row'),
        );
      }
    }
  }

  /**
   * @internal
   * Adds appropriate classes when certain sections are hidden for styling
   */
  setContactNavStyles() {
    if (this.contactNav && this.hideLegalLinks) {
      this.contactNav.classList.add('no-legal-links');
    }
    if (
      !this.contactNav &&
      this.hideContactPhone &&
      this.hideContactLink &&
      this.hideContactLink &&
      this.legalNav
    ) {
      this.legalNav.classList.add('no-contact-links');
    }
  }

  /**
   * @internal
   * Sets proper styling for slotted social media icons so adopter doesn't need to add these
   */
  setCustomSocialIconStyles() {
    if (this.customSocialIcons && this.customSocialIcons.length > 0) {
      this.customSocialIcons[0].setAttribute('orientation', 'horizontal');
      this.customSocialIcons[0].classList.add(this.classEl('social-icons'));
    }
  }

  render() {
    if (this.target === '_blank') {
      this.rel = 'noopener noreferrer';
    }
    return html`<pds-band
      variant=${this.setBandVariant()}
      spacing="default"
      class=${this.getClass()}
    >
      <pds-layout-container
        variant=${this.layoutContainerVariant}
        removePadding=${ifDefined(this.layoutContainerRemovePadding)}
        class="${this.behavior === 'super' || this.behavior === 'login'
          ? this.classMod(this.behavior)
          : nothing}"
      >
        ${this.behavior === 'super' && !this.hideLogo
          ? html`<div class="${this.classEl('logo')}">
              <a
                class="${this.classEl('logo-link')}"
                href="${this.logoHref}"
                aria-label="${this.getLogoAriaLabel()}"
              >
                <slot name="logo" allowed-elements="pds-logo, img, svg">
                  <pds-logo
                    style="position: relative; left: -8px;"
                    variant="${this.variant === 'default'
                      ? 'default-p-white-font'
                      : 'default'}"
                  ></pds-logo>
                </slot>
              </a>
            </div>`
          : nothing}
        <slot></slot>
        <slot name="heading"></slot>
        <slot name="additional-info"></slot>
        <nav
          class=${this.classEl('navigation-area')}
          aria-label="${this.translateText('footer')}"
        >
          ${this.behavior === 'login' ? this.loginSupport() : ''}
          ${this.showContactRow()
            ? html`<nav
                class=${this.classEl('contact-navigation')}
                aria-label="${this.translateText('contact-us')}"
              >
                ${this.getContactMarkup()}
              </nav>`
            : nothing}
          ${!this.hideLegalLinks
            ? html`<nav
                class="${this.classEl('legal-nav')}"
                aria-label="${this.getLegalNavAriaLabel()}"
              >
                <ul class="${this.classEl('extra-links')}" role="list">
                  ${!this.hideTermsOfUseLink
                    ? html`<li>
                        <pds-link
                          variant=${this.setLinkVariant()}
                          href="${this.termsOfUseLink}"
                          class="${this.classEl('terms-of-use')}"
                          target="${ifDefined(this.target)}"
                          rel=${ifDefined(this.rel)}
                          >${this.translateText('terms-of-use')}</pds-link
                        >
                      </li>`
                    : nothing}
                  ${!this.hideDisclosureLink
                    ? html`<li>
                        <pds-link
                          variant=${this.setLinkVariant()}
                          href="${this.disclosuresLink}"
                          class="${this.classEl('disclosure')}"
                          target="${ifDefined(this.target)}"
                          rel=${ifDefined(this.rel)}
                          >${this.translateText('disclosures')}</pds-link
                        >
                      </li>`
                    : nothing}
                  ${!this.hidePrivacyLink
                    ? html`<li>
                        <pds-link
                          variant=${this.setLinkVariant()}
                          href="${this.privacyLink}"
                          class="${this.classEl('privacy')}"
                          target="${ifDefined(this.target)}"
                          rel=${ifDefined(this.rel)}
                          >${this.translateText('privacy')}</pds-link
                        >
                      </li>`
                    : nothing}
                  ${!this.hideSecurityLink
                    ? html`<li>
                        <pds-link
                          variant=${this.setLinkVariant()}
                          href="${this.securityLink}"
                          class="${this.classEl('security')}"
                          target="${ifDefined(this.target)}"
                          rel=${ifDefined(this.rel)}
                          >${this.translateText('security')}</pds-link
                        >
                      </li>`
                    : nothing}
                  ${!this.hideReportFraudLink
                    ? html`<li>
                        <pds-link
                          variant=${this.setLinkVariant()}
                          href="${this.reportFraudLink}"
                          class="${this.classEl('report-fraud')}"
                          target="${ifDefined(this.target)}"
                          rel=${ifDefined(this.rel)}
                          >${this.translateText('report-fraud')}</pds-link
                        >
                      </li>`
                    : nothing}
                  ${!this.hideAccessibilityLink
                    ? html`<li>
                        <pds-link
                          variant=${this.setLinkVariant()}
                          href="${this.accessibilityLink}"
                          class="${this.classEl('accessibility')}"
                          target="${ifDefined(this.target)}"
                          rel=${ifDefined(this.rel)}
                          >${this.translateText('accessibility')}</pds-link
                        >
                      </li>`
                    : nothing}
                </ul>
                <slot
                  hidden
                  allowed-elements="pds-list"
                  name="custom-legal-links"
                ></slot>
              </nav>`
            : nothing}
          <div class="${this.classEl('copyright-and-social')}">
            <div class="${this.classEl('legal')}">
              <slot
                name="legal-text"
                class="${this.classEl('legal-text-container')}"
              >
                <p class="${this.classEl('legal-text')}">
                  ©${this.getCurrentYear()} Principal Financial Services, Inc.
                </p>
                <p class="${this.classEl('legal-text')}">
                  ${this.translateText('securities-offered')}
                  <pds-link
                    variant="${this.setEmphasisLinkVariant()}"
                    size="sm"
                    href="https://www.sipc.org"
                    target="${ifDefined(this.target)}"
                    rel=${ifDefined(this.rel)}
                    >${this.translateText('member-sipc')}</pds-link
                  >
                </p>
              </slot>
            </div>
            ${!this.hideSocialIcons
              ? html`<nav
                  class=${this.classEl('social-navigation')}
                  aria-label="${this.translateText('social-media')}"
                >
                  <slot allowed-elements="pds-list" name="social-icons"
                    ><pds-list
                      class="${this.classEl('social-icons')}"
                      orientation="horizontal"
                    >
                      <pds-list-item
                        ><pds-link
                          variant="${this.setLinkVariant()}"
                          href="https://facebook.com/PrincipalFinancial"
                          ariaLabel="${this.translateText(
                            'principal-on-facebook',
                          )}"
                          target="${ifDefined(this.target)}"
                          rel=${ifDefined(this.rel)}
                          ><pds-icon-facebook
                            size="xl"
                            color="currentColor"
                          ></pds-icon-facebook> </pds-link
                      ></pds-list-item>
                      <pds-list-item
                        ><pds-link
                          variant="${this.setLinkVariant()}"
                          href="https://twitter.com/Principal"
                          ariaLabel="${this.translateText(
                            'principal-on-twitter',
                          )}"
                          target="${ifDefined(this.target)}"
                          rel=${ifDefined(this.rel)}
                          ><pds-icon-twitter
                            size="xl"
                            color="currentColor"
                          ></pds-icon-twitter> </pds-link
                      ></pds-list-item>
                      <pds-list-item
                        ><pds-link
                          variant="${this.setLinkVariant()}"
                          href="https://youtube.com/PrincipalFinancial"
                          ariaLabel="${this.translateText(
                            'principal-on-youtube',
                          )}"
                          target="${ifDefined(this.target)}"
                          rel=${ifDefined(this.rel)}
                          ><pds-icon-youtube
                            size="xl"
                            color="currentColor"
                          ></pds-icon-youtube> </pds-link
                      ></pds-list-item>
                      <pds-list-item
                        ><pds-link
                          variant="${this.setLinkVariant()}"
                          href="https://linkedin.com/company/principalfinancialgroup"
                          ariaLabel="${this.translateText(
                            'principal-on-linkedin',
                          )}"
                          target="${ifDefined(this.target)}"
                          rel=${ifDefined(this.rel)}
                          ><pds-icon-linkedin
                            size="xl"
                            color="currentColor"
                          ></pds-icon-linkedin> </pds-link
                      ></pds-list-item>
                    </pds-list>
                  </slot>
                </nav>`
              : nothing}
          </div>
          ${this.showMobileAppBadges
            ? html`<div class="${this.classEl('mobile-app-badges')}">
                <pds-link
                  class="${this.classEl('badge')}"
                  variant="${this.setLinkVariant()}"
                  href="https://apps.apple.com/us/app/principal/id1433961188?ign-itscg=30200&amp;ign-itsct=apps_box_badge"
                  ariaLabel="${this.translateText('principal-on-AppStore')}"
                  target="${ifDefined(this.target)}"
                  rel=${ifDefined(this.rel)}
                >
                  <img
                    alt="Download on the App Store"
                    width="120"
                    height="auto"
                    viewBox="0 0 120 auto"
                    style="margin: 8px 0px;"
                    src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
                  />
                </pds-link>
                <pds-link
                  class="${this.classEl('badge')}"
                  variant="${this.setLinkVariant()}"
                  href="https://play.google.com/store/apps/details?id=gokart.com.principal&amp;hl=en_US&amp;pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                  ariaLabel="${this.translateText('principal-on-GooglePlay')}"
                  target="${ifDefined(this.target)}"
                  rel=${ifDefined(this.rel)}
                  ><img
                    alt="Get it on Google Play"
                    id="logo-img"
                    width="150.5"
                    height="auto"
                    src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                  />
                </pds-link>
              </div>`
            : nothing}
        </nav>
      </pds-layout-container>
    </pds-band>`;
  }
}
