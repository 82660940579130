import React from "react";
import Balance from "../../../components/numbers/balance";
import Percent from "../../../components/numbers/percent";
import TableV2 from "../../../components/table/tableV2";
import { hidePrrSelector } from "../../../selectors/plan-info";
import { useSelector } from "../../../store/store";
import {
  aggregateByInvestmentId,
  filterEmptyInvestments,
  flatten,
} from "../../../util/investment-context-util";
import { PrrModal } from "../../prr-faq/prr-modal";
import Share from "../../../components/numbers/share";

function ActivityByInvestment(props) {
  const { investments } = props;
  const hidePrr = useSelector(hidePrrSelector);

  const aggregatedInvestments = flatten(aggregateByInvestmentId(investments));
  const nonEmptyInvestments = filterEmptyInvestments(aggregatedInvestments);

  const columns = [
    {
      Header: "",
      accessor: "name",
      Footer: <span className="bold">Totals</span>,
    },
    {
      Header: "Beginning balance",
      accessor: "beginBalance.cashBalance.balance",
      className: "right",
      Cell: ({ value }) => <Balance value={value} showDollar />,
      Footer: (info) => {
        // Only calculate total visits if rows change
        const total = React.useMemo(
          () =>
            info.rows.reduce(
              (sum, row) =>
                row.values["beginBalance.cashBalance.balance"] + sum,
              0
            ),
          [info.rows]
        );

        return (
          <span className="bold">
            <Balance value={total} showDollar />
          </span>
        );
      },
    },
    {
      Header: "Contributions",
      accessor: "contributionAmt",
      className: "right",
      Cell: ({ value }) => <Balance value={value} showDollar />,
      Footer: (info) => {
        // Only calculate total visits if rows change
        const total = React.useMemo(
          () =>
            info.rows.reduce((sum, row) => row.values.contributionAmt + sum, 0),
          [info.rows]
        );

        return (
          <span className="bold">
            <Balance value={total} showDollar />
          </span>
        );
      },
    },
    {
      Header: "Distributions",
      accessor: "distributionAmt",
      className: "right",
      Cell: ({ value }) => <Balance value={value} showDollar />,
      Footer: (info) => {
        // Only calculate total visits if rows change
        const total = React.useMemo(
          () =>
            info.rows.reduce((sum, row) => row.values.distributionAmt + sum, 0),
          [info.rows]
        );

        return (
          <span className="bold">
            <Balance value={total} showDollar />
          </span>
        );
      },
    },
    {
      Header: "Forfeitures",
      accessor: "forfeitAmt",
      className: "right",
      Cell: ({ value }) => <Balance value={value} showDollar />,
      Footer: (info) => {
        // Only calculate total visits if rows change
        const total = React.useMemo(
          () => info.rows.reduce((sum, row) => row.values.forfeitAmt + sum, 0),
          [info.rows]
        );

        return (
          <span className="bold">
            <Balance value={total} showDollar />
          </span>
        );
      },
    },
    {
      Header: "Fees",
      accessor: "feeAmt",
      className: "right",
      Cell: ({ value }) => <Balance value={value} showDollar />,
      Footer: (info) => {
        // Only calculate total visits if rows change
        const total = React.useMemo(
          () => info.rows.reduce((sum, row) => row.values.feeAmt + sum, 0),
          [info.rows]
        );

        return (
          <span className="bold">
            <Balance value={total} showDollar />
          </span>
        );
      },
    },
    {
      Header: "Transfers",
      accessor: "transferAmt",
      className: "right",
      Cell: ({ value }) => <Balance value={value} showDollar />,
      Footer: (info) => {
        // Only calculate total visits if rows change
        const total = React.useMemo(
          () => info.rows.reduce((sum, row) => row.values.transferAmt + sum, 0),
          [info.rows]
        );

        return (
          <span className="bold">
            <Balance value={total} showDollar />
          </span>
        );
      },
    },
    {
      Header: "Earnings",
      accessor: "earningAmt",
      className: "right",
      Cell: ({ value }) => <Balance value={value} showDollar />,
      Footer: (info) => {
        // Only calculate total visits if rows change
        const total = React.useMemo(
          () => info.rows.reduce((sum, row) => row.values.earningAmt + sum, 0),
          [info.rows]
        );

        return (
          <span className="bold">
            <Balance value={total} showDollar />
          </span>
        );
      },
    },
    {
      Header: "Ending balance",
      accessor: "endBalance.cashBalance.balance",
      className: "right",
      Cell: ({ value }) => <Balance value={value} showDollar />,
      Footer: (info) => {
        // Only calculate total visits if rows change
        const total = React.useMemo(
          () =>
            info.rows.reduce((sum, row) => {
              return row.values["endBalance.cashBalance.balance"] + sum;
            }, 0),
          [info.rows]
        );

        return (
          <span className="bold">
            <Balance value={total} showDollar />
          </span>
        );
      },
    },
    {
      Header: "Ending shares",
      accessor: "endBalance.shareBalance.balance",
      className: "right",
      Cell: ({ value }) => <Share value={value} />,
    },
  ];

  if (!hidePrr) {
    columns.push(buildPrrColumn());
  }

  function buildPrrColumn() {
    return {
      Header: (
        <div>
          <span>PRR</span>
          <PrrModal linkText={<span className="sr-only">PRR FAQ</span>} />
        </div>
      ),
      accessor: "prrAmt",
      Cell: ({ value }) => <Percent value={value} />,
      className: "right",
    };
  }

  return <TableV2 columns={columns} data={nonEmptyInvestments} />;
}

export default ActivityByInvestment;
