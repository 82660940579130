export enum PayCheckFrequency {
  WEEKLY = "Weekly",
  BIWEEKLY = "Bi-Weekly",
  MONTHLY = "Monthly",
  BIMONTHLY = "Bi-Monthly",
  ANNUALLY = "Annually",
  QUARTERLY = "Quarterly",
  USEPAYCHECKFREQUENCY = "Use paycheck frequency",
  NOT_SET = "Not Set",
}
