import _flatMap from "lodash/flatMap";
import _groupBy from "lodash/groupBy";
import _sortBy from "lodash/sortBy";
import { UnscheduledDistributionElection } from "../../../../models/distributions/unscheduled-distribution-election";
import { UnscheduledDistributionEventCategory } from "../../../../models/distributions/unscheduled-distribution-event-category";

export function groupElectionsByCategory(
  distributionElections: UnscheduledDistributionElection[]
): UnscheduledDistributionElection[][] {
  return Object.values(_groupBy(distributionElections, "eventCategory"));
}

export function filterOutDuplicateElectionsForEventCategory(
  electionsByCategory: UnscheduledDistributionElection[][]
): UnscheduledDistributionElection[][] {
  return electionsByCategory.map((categoryOfEvents) => {
    const electionsGroupedByEventTypes: UnscheduledDistributionElection[][] =
      groupDistributionElectionsByEventType(categoryOfEvents);
    return _flatMap(
      electionsGroupedByEventTypes.map((electionsByEventType) => {
        const isAccountLevelElection =
          hasAccountLevelElection(electionsByEventType);
        return filterOutDuplicateElections(
          electionsByEventType,
          isAccountLevelElection
        );
      })
    );
  });
}

export function addDisplayOrderToElections(
  accountLevelUnscheduledDistributions: UnscheduledDistributionElection[],
  participantLevelUnscheduledDistributions: UnscheduledDistributionElection[]
): UnscheduledDistributionElection[] {
  const allElections = [
    ...accountLevelUnscheduledDistributions,
    ...participantLevelUnscheduledDistributions,
  ];
  const eventsWithDisplayOrder = allElections.map(addDisplayOrder);
  return _sortBy(eventsWithDisplayOrder, "displayOrder");
}

export function addDisplayOrder(
  event: UnscheduledDistributionElection
): UnscheduledDistributionElection {
  if (event.displayOrder) {
    return event;
  }

  const displayOrder = getUnscheduledDistributionElectionDisplayOrder(
    event.eventCategory
  );
  return { ...event, displayOrder };
}

export function groupDistributionElectionsByEventType(
  distributionElections: UnscheduledDistributionElection[]
) {
  return Object.values(_groupBy(distributionElections, "eventType"));
}

function hasAccountLevelElection(
  eventType: UnscheduledDistributionElection[]
): boolean {
  return eventType.some((x) => x.accountId);
}

function filterOutDuplicateElections(
  electionsByEventType: UnscheduledDistributionElection[],
  isAccountLevelElection: boolean
) {
  if (electionsByEventType.length > 1 && isAccountLevelElection) {
    return electionsByEventType.filter((eventType) => eventType.accountId);
  } else if (electionsByEventType.length > 1) {
    return electionsByEventType[0];
  }
  return electionsByEventType;
}

function getUnscheduledDistributionElectionDisplayOrder(
  eventType: UnscheduledDistributionEventCategory | undefined
): number {
  switch (eventType) {
    case UnscheduledDistributionEventCategory.SEPARATION_FROM_SERVICE:
      return 1;
    case UnscheduledDistributionEventCategory.CHANGE_IN_CONTROL:
      return 2;
    case UnscheduledDistributionEventCategory.DEATH:
      return 3;
    case UnscheduledDistributionEventCategory.DISABILITY:
      return 4;
    case UnscheduledDistributionEventCategory.CUSTOM_QDE:
      return 5;
    case UnscheduledDistributionEventCategory.UNFORESEEABLE_EMERGENCY:
      return 6;
    case UnscheduledDistributionEventCategory.HAIRCUT:
      return 7;
    case UnscheduledDistributionEventCategory.HEART_ACT_DISTRIBUTION:
      return 8;
    case UnscheduledDistributionEventCategory.TRANSFER_TO_457B:
      return 9;
    case UnscheduledDistributionEventCategory.ELECTIVE_DISTRIBUTION:
      return 10;
    case UnscheduledDistributionEventCategory.INVOLUNTARY_SEPARATION_FROM_SERVICE:
      return 11;
    case UnscheduledDistributionEventCategory.FORFEITURE_EVENT_VALUATION:
      return 12;
    case UnscheduledDistributionEventCategory.SEPARATION_FROM_SERVICE_DUE_TO_CHANGE_IN_CONTROL:
      return 13;
    default:
      return 14;
  }
}
