import _find from "lodash/find";
import React from "react";
import Balance from "../../../../components/numbers/balance";
import { Account } from "../../../../models/account";
import AccountName from "../../../../components/account-name/account-name";
import {
  ContributionRates,
  ContributionSummary,
  ContributionType,
} from "../../../../models/contributions";

type CellInfo = {
  value?: any;
};
type Sum = {
  values: any;
};
type CellRows = {
  rows: Sum[];
};

export type ContributionAmountsDisplay = {
  contributionSource: string;
  contributionThisYear: number;
  contributionSinceInception: number;
  contributionCurrent: number;
  contributionVested: number;
  accountId: number;
};

export function updateContributionRateFromFormValues(
  contributionRate: ContributionRates,
  formValues: Object
): ContributionRates {
  const { contributionTypeName: name } = contributionRate;
  const fields: any = getMatchingFields(formValues, name);
  const type = fields[`${name}Type`];
  const value = fields[`${name}Value`];
  let amount: number | undefined;
  let otherText: string | undefined;
  if (type === ContributionType.OTHER) {
    otherText = value;
  } else {
    amount = value;
  }
  return {
    ...contributionRate,
    contributionAmount: amount,
    otherText,
    contributionElectionType: type,
  };
}

export function getMatchingFields(formValues: any, keySubString: string) {
  return Object.keys(formValues)
    .filter((key: string) => key.includes(keySubString))
    .reduce((obj: any, key: string) => {
      obj[key] = formValues[key];
      return obj;
    }, {});
}

export function buildContributionSources(isByAccount: boolean) {
  let headerText: string = "Contribution Source";
  if (isByAccount) {
    headerText = "Account";
  }
  const Header = () => <span className="left-align header">{headerText}</span>;
  return {
    Header,
    accessor: "contributionSource",
    disableSortBy: false,
    className: "left-align bold",
    Cell: (info: any) => {
      const { value } = info;
      if (!value) {
        return "";
      }
      return isByAccount ? (
        <AccountName
          name={value}
          id={info.cell.row.original.accountId}
          asLink={false}
        />
      ) : (
        value
      );
    },
    Footer: () => {
      return "Total:";
    },
  };
}

export function buildContributionThisYear() {
  const Header = () => (
    <>
      <span className="left-align header">Contribution This Year ($)</span>
    </>
  );
  return {
    Header,
    accessor: "contributionThisYear",
    disableSortBy: false,
    className: "left-align",
    Cell: ({ value }: CellInfo) => <Balance value={value} />,
    Footer: (info: CellRows) => {
      // Only calculate total visits if rows change
      const total = React.useMemo(
        () =>
          info.rows.reduce(
            (sum, row) => row.values.contributionThisYear + sum,
            0
          ),
        [info.rows]
      );

      return <Balance value={total} />;
    },
  };
}

export function buildContributionSinceInception() {
  const Header = () => (
    <>
      <span className="left-align header">
        Contribution Since Inception ($)
      </span>
      <sup>
        [
        <a href="#[2]">
          <span className="sr-only">Footnote&nbsp;</span>2
        </a>
        ]
      </sup>
    </>
  );
  return {
    Header,
    accessor: "contributionSinceInception",
    disableSortBy: false,
    className: "left-align",
    Cell: ({ value }: CellInfo) => <Balance value={value} />,
    Footer: (info: CellRows) => {
      // Only calculate total visits if rows change
      const total = React.useMemo(
        () =>
          info.rows.reduce(
            (sum, row) => row.values.contributionSinceInception + sum,
            0
          ),
        [info.rows]
      );

      return <Balance value={total} />;
    },
  };
}

export function buildContributionCurrent() {
  const Header = () => <span className="left-align header">Current ($)</span>;
  return {
    Header,
    accessor: "contributionCurrent",
    disableSortBy: false,
    className: "left-align",
    Cell: ({ value }: CellInfo) => <Balance value={value} />,
    Footer: (info: CellRows) => {
      // Only calculate total visits if rows change
      const total = React.useMemo(
        () =>
          info.rows.reduce(
            (sum, row) => row.values.contributionCurrent + sum,
            0
          ),
        [info.rows]
      );

      return <Balance value={total} />;
    },
  };
}

export function buildContributionVested() {
  const Header = () => <span className="left-align header">Vested ($)</span>;
  return {
    Header,
    accessor: "contributionVested",
    disableSortBy: false,
    className: "left-align",
    Cell: ({ value }: CellInfo) => <Balance value={value} />,
    Footer: (info: CellRows) => {
      // Only calculate total visits if rows change
      const total = React.useMemo(
        () =>
          info.rows.reduce(
            (sum, row) => row.values.contributionVested + sum,
            0
          ),
        [info.rows]
      );

      return <Balance value={total} />;
    },
  };
}

export function createContributionAmountsDisplayByAccount(
  contributionSummaries: ContributionSummary[],
  accounts: Account[] = []
): ContributionAmountsDisplay[] {
  const amounts: ContributionAmountsDisplay[] = contributionSummaries.map(
    (contributionSummary) => {
      const contributionSource = getAccountNameForContributions(
        accounts,
        contributionSummary
      );
      return createContributionAmountsDisplay(
        contributionSummary,
        contributionSource
      );
    }
  );
  return groupAmountsByAccount(amounts);
}

export function createContributionAmountsDisplayByMoneyType(
  contributionSummaries: ContributionSummary[]
) {
  const amounts: ContributionAmountsDisplay[] = contributionSummaries.map(
    (contributionSummary) =>
      createContributionAmountsDisplay(contributionSummary)
  );
  return groupAmountsByMoneyType(amounts);
}

export function groupAmountsByMoneyType(
  amounts: ContributionAmountsDisplay[]
): ContributionAmountsDisplay[] {
  const amountsByAccount: ContributionAmountsDisplay[] = [];
  amounts.forEach((amount) => {
    const index = amountsByAccount.findIndex(
      (item) => item.contributionSource === amount.contributionSource
    );
    if (index > -1) {
      amountsByAccount[index].accountId = 0;
      amountsByAccount[index].contributionThisYear +=
        amount.contributionThisYear;
      amountsByAccount[index].contributionSinceInception +=
        amount.contributionSinceInception;
      amountsByAccount[index].contributionCurrent += amount.contributionCurrent;
      amountsByAccount[index].contributionVested += amount.contributionVested;
    } else {
      amountsByAccount.push(Object.assign({}, amount));
    }
  });
  return amountsByAccount;
}

export function groupAmountsByAccount(
  amounts: ContributionAmountsDisplay[]
): ContributionAmountsDisplay[] {
  const amountsByAccount: ContributionAmountsDisplay[] = [];
  amounts.forEach((amount) => {
    const index = amountsByAccount.findIndex(
      (item) => item.accountId === amount.accountId
    );
    if (index > -1) {
      amountsByAccount[index].contributionThisYear +=
        amount.contributionThisYear;
      amountsByAccount[index].contributionSinceInception +=
        amount.contributionSinceInception;
      amountsByAccount[index].contributionCurrent += amount.contributionCurrent;
      amountsByAccount[index].contributionVested += amount.contributionVested;
    } else {
      amountsByAccount.push(Object.assign({}, amount));
    }
  });
  return amountsByAccount;
}

export function createContributionAmountsDisplay(
  contributionSummary: ContributionSummary,
  contributionSource?: string
): ContributionAmountsDisplay {
  const contributionThisYear = contributionSummary.contributionsYTD
    ? contributionSummary.contributionsYTD
    : 0;
  const contributionSinceInception: number =
    contributionSummary.contributionsSinceInception
      ? contributionSummary.contributionsSinceInception
      : 0;
  const contributionCurrent: number = contributionSummary.currentBalance
    ? contributionSummary.currentBalance
    : 0;
  const contributionVested: number = contributionSummary.currentVestedBalance
    ? contributionSummary.currentVestedBalance
    : 0;
  const accountId = contributionSummary.accountId
    ? contributionSummary.accountId
    : 0;

  return {
    contributionSource: contributionSource
      ? contributionSource
      : contributionSummary.moneyTypeName!,
    contributionThisYear,
    contributionSinceInception,
    contributionCurrent,
    contributionVested,
    accountId,
  } as ContributionAmountsDisplay;
}

export function getAccountNameForContributions(
  accounts: Account[],
  contributionSummary: ContributionSummary
) {
  const account = _find(accounts, (account) => {
    return account.id === contributionSummary.accountId;
  });
  if (account && account.name) {
    return account.name;
  }
  return "";
}
