import { PlanConfig } from "../models/plan/plan-config";
import { PlanDetails } from "../models/plan/plan-details";
import * as participantRepository from "../repositories/participant";
import { get as getSalesforce } from "../repositories/salesforce";
import { PaymentInfo } from "../models/payment-info";

interface QueryParams {
  empId: number;
  planId?: number;
  role: string;
}

export async function getPlanInfo({
  planId,
  empId,
  role,
}: QueryParams): Promise<PlanConfig> {
  return participantRepository.get(`/plans/${planId}/employees/${empId}/`, {
    role,
  });
}

export async function getPlanDetails({
  planId,
  empId,
  role,
}: QueryParams): Promise<PlanDetails> {
  return participantRepository.get(
    `/employees/${empId}/plans/${planId}/planDetails`,
    { role }
  );
}

export async function getPlanConfig({
  planId,
  empId,
  role,
}: QueryParams): Promise<PlanConfig> {
  return getSalesforce(`/plans/${planId}/employees/${empId}/plan`, { role });
}

export async function getPaymentInfo({
  planId,
  empId,
  role,
}: QueryParams): Promise<PaymentInfo> {
  return getSalesforce(`/plans/${planId}/employees/${empId}/ach`, { role });
}
