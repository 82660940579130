import { RedeferralStatus } from "../../../models/redeferral/redeferral-status";
import { isLocal } from "../../../util/nq-participant-link-builder";
import { replaceUnderscoresWithSpaces } from "../../../util/string-util";
import { buildCompleteRedeferralLink } from "../distribution-summary/pending-distributions/pending-distributions-wrapper";

export function getRedeferralTypeDisplayName(
  redeferralStatus: RedeferralStatus | undefined
) {
  if (!redeferralStatus || !redeferralStatus.redeferralType) {
    return null;
  }
  return replaceUnderscoresWithSpaces(
    redeferralStatus.redeferralType
  ).toLowerCase();
}

export function getRedeferralStateForAccount(
  allRedeferralStates: RedeferralStatus[],
  accountId: string | number
): RedeferralStatus | undefined {
  return allRedeferralStates.find(
    (status) => Number(status.accountId) === Number(accountId)
  );
}

export function getRedeferLink(
  redeferralStatus: RedeferralStatus | undefined,
  empId: number,
  contractId: string,
  accountId: number,
  role: string
) {
  if (
    redeferralStatus &&
    (redeferralStatus.showRedeferLink ||
      redeferralStatus.redeferralType !== "STREAM_OF_PAYMENT")
  ) {
    return buildCompleteRedeferralLink(
      empId,
      contractId,
      redeferralStatus.redeferralAction,
      redeferralStatus.accountName,
      accountId,
      role
    );
  } else if (redeferralStatus && redeferralStatus.showPendingRedeferralLink) {
    if (isLocal()) {
      return redeferralStatus.pendingRedeferralUrl.replace(
        "/nq-participant",
        ""
      );
    } else {
      return redeferralStatus.pendingRedeferralUrl;
    }
  }
}
