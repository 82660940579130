import React, { useEffect, useState } from "react";
import Tour from "reactour";
import Button from "../../components/buttons/button";
import { DashboardContributionPanel } from "../../components/dashboard/dashboard-contribution-panel/dashboard-contribution-panel";
import { DashboardFinancialWellnessArticles } from "../../components/dashboard/dashboard-financial-wellness-articles/dashboard-financial-wellness-articles";
import { DashboardTabbedView } from "../../components/dashboard/dashboard-tabbed-view/dashboard-tabbed-view";
import { PTCTracker } from "../../components/dashboard/ptc-tracker/PTCTracker";
import { EnrollmentEventsWrapper } from "../../components/enrollment/enrollment-events-wrapper";
import Icon from "../../components/icon/icon";
import { MessageWrapper } from "../../components/messages/message-wrapper";
import { Page } from "../../components/page";
import { AsyncStatus } from "../../models/async-status";
import { MessageName } from "../../models/message";
import { PlanCode } from "../../models/plan/plan-info-model";
import { resetEndDate } from "../../reducers/dates";
import { fetchPTCTracker } from "../../reducers/distributions/ptcTracker";
import { fetchFinancialWellnessArticles } from "../../reducers/financial-wellness-articles";
import { fetchMessages } from "../../reducers/messages/messages";
import {
  isContributionPanelEnabled,
  isFeatureFlagTextEnabled,
} from "../../selectors/feature-flags";
import {
  hideAccountOverviewSelector,
  hideContributionPanelSelector,
  hideDashboardTabbedViewSelector,
  hideFinancialWellnessArticlesSelector,
  hidePTCTrackerSelector,
} from "../../selectors/plan-info";
import { useAppDispatch, useSelector } from "../../store/store";
import { buildLinkForNewOverviewBenefitPage } from "../../util/nq-participant-link-builder";
import "./dashboard.scss";
import { toursteps } from "./tour-steps";
import { DashboardAccountOverview } from "../../components/dashboard/dashboard-account-overview/dashboard-account-overview";
import { fetchNqSingleAccountDetails } from "../../reducers/nq-account-single";

const browserUpdate = require("browser-update");

export default function Dashboard() {
  const dispatch = useAppDispatch();
  const featureFlagTextEnabled = useSelector(isFeatureFlagTextEnabled);
  const contributionPanelEnabled = useSelector(isContributionPanelEnabled);
  const participant = useSelector((state) => state.participant);
  const { planId, empId, role } = useSelector((state) => state.session);
  const {
    contractId,
    planCode,
    status: infoStatus,
  } = useSelector((state) => state.plan.info);
  const hideFinancialWellnessArticles = useSelector(
    hideFinancialWellnessArticlesSelector
  );
  const hidePTCTracker = useSelector(hidePTCTrackerSelector);
  const hideAccountOverview = useSelector(hideAccountOverviewSelector);
  const hideTabbedView = useSelector(hideDashboardTabbedViewSelector);
  const hideContributionPanel = useSelector(hideContributionPanelSelector);
  const availableAccounts = useSelector((state) => state.accounts.available);
  const { data: nqAccountData } = useSelector(
    (state) => state.nqAccountDetailsSlice
  );
  const hasAvailableAccounts = availableAccounts.length > 0;
  const is457bPlan = planCode.toLowerCase() === PlanCode.type457b;
  const shouldShowContributionPanel = is457bPlan ? hasAvailableAccounts : true;

  const [isTourOpen, setIsTourOpen] = useState(false);
  const [shouldUpdateTour, setShouldUpdateTour] = useState("");

  useEffect(() => {
    const isPilot = window.location.hostname.includes("pilot");
    const isLocal = window.location.hostname.includes("local");
    if (nqAccountData.planTerminated) {
      if (isLocal || isPilot) {
        window.location.replace(
          "https://secure05.pilot.principal.com/member/accounts"
        );
      } else {
        window.location.replace(
          "https://secure05.principal.com/member/accounts"
        );
      }
    }
  }, [nqAccountData, role]);

  useEffect(() => {
    function redirectDBPlans() {
      if (planCode.toUpperCase() === "DB") {
        window.location.assign(
          buildLinkForNewOverviewBenefitPage(planId, empId, contractId, role)
        );
      }
    }

    if (infoStatus === AsyncStatus.done) {
      redirectDBPlans();
    }
  }, [infoStatus, empId, contractId, planCode, role]);

  useEffect(() => {
    const messageTypesParam: MessageName[] = [
      MessageName.PLAN_EVENT,
      MessageName.BENEFICIARY,
      MessageName.DEFERRAL_CHANGE,
      MessageName.DEFERRAL_PENDING,
      MessageName.REDEFERRAL,
      MessageName.PARTICIPANT,
    ];
    if (participant.status === AsyncStatus.done) {
      dispatch(
        fetchNqSingleAccountDetails({
          enterpriseId: participant.data.enterpriseId,
          planId,
        })
      );
    }
    dispatch(resetEndDate());
    dispatch(fetchPTCTracker({ planId, empId, role }));
    dispatch(fetchMessages({ planId, empId, role, messageTypesParam }));
    dispatch(fetchFinancialWellnessArticles());
    setSessionStorage();

    function setSessionStorage() {
      sessionStorage.setItem(
        "session_string",
        JSON.stringify({
          memberId: empId,
          planId: planId,
          userType: role,
        })
      );
    }
  }, [planId, empId, role, dispatch, contractId, participant]);

  useEffect(() => {
    browserUpdate({
      required: {
        e: -2,
        f: -3,
        o: -3,
        s: 10.1,
        c: "64.0.3282.16817",
        samsung: 7.0,
        vivaldi: 1.2,
      },
      insecure: true,
      api: 2021.04,
      url: "#",
      newwindow: false,
      text: {
        msg: "Your web browser ({brow_name}) is out of date.",
        msgmore:
          "Our site is best experienced with modern web browsers. We encourage you to update your browser or download a new one.",
        bupdate: "Update",
        bignore: "Close",
      },
    });
    const closeButtonNode = document.querySelector("#buorgig");
    if (closeButtonNode !== null) {
      closeButtonNode.classList.add("btn");
      closeButtonNode.classList.add("btn-default");
      closeButtonNode.classList.add("btn-sm");
      closeButtonNode.setAttribute("id", "buorgclose");
    }
  }, []);

  return (
    planCode.toUpperCase() !== "DB" && (
      <div className={"bg-light-gray external-container"}>
        <Page className="dashboard" name="dashboard" loading={false}>
          <h1 className="sr-only">Account dashboard</h1>
          <div className="row">
            <div className="col-lg-4 col-md-5">
              {!hideAccountOverview && <DashboardAccountOverview />}
              {!hidePTCTracker && <PTCTracker />}
              {contributionPanelEnabled &&
                !hideContributionPanel &&
                shouldShowContributionPanel && <DashboardContributionPanel />}
            </div>
            <div className="col-lg-8 col-md-7">
              <EnrollmentEventsWrapper />
              <MessageWrapper />
              {!hideTabbedView && (
                <DashboardTabbedView
                  setShouldUpdateTour={setShouldUpdateTour}
                />
              )}
            </div>
          </div>
          <Tour
            nextButton={
              <Button className="btn-sm btn-primary">
                Next <Icon name="angle-right" />
              </Button>
            }
            prevButton={
              <Button className="btn-sm btn-link">
                <Icon name="angle-left" /> Previous
              </Button>
            }
            showNavigation={false}
            showNumber={false}
            steps={toursteps}
            isOpen={isTourOpen}
            update={shouldUpdateTour}
            lastStepNextButton={
              <Button className={"btn-sm btn-primary"}>End tour</Button>
            }
            onRequestClose={() => setIsTourOpen(false)}
          />
          <div className="floating-button hidden-sm">
            <Button className="btn-primary" onClick={() => setIsTourOpen(true)}>
              <Icon name="question-circle" /> Guided tour
            </Button>
          </div>
          {!hideFinancialWellnessArticles && (
            <section className={"extended-background fwa-background"}>
              <div className={"container"}>
                <DashboardFinancialWellnessArticles />
              </div>
            </section>
          )}
          {featureFlagTextEnabled && (
            <div>
              <p>feature flags enabled</p>
            </div>
          )}
        </Page>
      </div>
    )
  );
}
