import React from "react";
import { BeneficiaryType as Type } from "../../../models/beneficiaries/beneficiary";
import "./beneficiary-form.scss";
import { useSelector } from "../../../store/store";
import {
  hideContingentBeneficiariesSelector,
  hidePrimaryBeneficiariesSelector,
} from "../../../selectors/plan-info";

type Props = {
  register: any;
  beneType: string;
  setBeneType: Function;
};

export default function BeneficiaryTypeToggle(props: Props) {
  const { beneType, setBeneType } = props;
  const hidePrimaryBeneficiairies = useSelector(
    hidePrimaryBeneficiariesSelector
  );
  const hideContingentBeneficiaires = useSelector(
    hideContingentBeneficiariesSelector
  );
  let primaryClassNames, secondaryClassNames;
  if (beneType === "Primary") {
    primaryClassNames = "btn btn-default active";
    secondaryClassNames = "btn btn-default";
  } else {
    primaryClassNames = "btn btn-default";
    secondaryClassNames = "btn btn-default active";
  }

  function toggleBeneficiaryType() {
    if (beneType === Type.PRIMARY) {
      setBeneType(Type.CONTINGENT);
    } else {
      setBeneType(Type.PRIMARY);
    }
  }

  return (
    <div
      className="btn-group beneficiary-type-toggle"
      role={"group"}
      data-testid="beneficiary-type-toggle"
    >
      {!hidePrimaryBeneficiairies && (
        <button
          type="button"
          name="beneficiary-category-left"
          id="beneficiary-category-primary"
          value="Primary"
          className={primaryClassNames}
          onClick={toggleBeneficiaryType}
          aria-pressed={beneType === Type.PRIMARY ? "true" : "false"}
        >
          Primary
        </button>
      )}
      {!hideContingentBeneficiaires && (
        <button
          type="button"
          name="beneficiary-category-right"
          id="beneficiary-category-contingent"
          value="Contingent"
          className={secondaryClassNames}
          onClick={toggleBeneficiaryType}
          aria-pressed={beneType === Type.CONTINGENT ? "true" : "false"}
        >
          Contingent
        </button>
      )}
    </div>
  );
}
