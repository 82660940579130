import { getRoleForString } from "../contexts/session-context/roles";
import { AsyncStatus } from "../models/async-status";
import { Session } from "../models/session";
import {
  getContext,
  getContextByEnterpriseId,
} from "../services/context-service";
import { jsfDomain } from "./constants";

export async function getSessionFromContract(
  empId: string,
  contractId: string,
  role: string
): Promise<Session> {
  const context = await getContext(contractId, empId);
  const roleText = getRoleForString(role[0]);
  return {
    empId: Number(context.empId),
    planId: Number(context.planId),
    role: roleText,
    status: AsyncStatus.done,
    isAdvisorOrEmployer: roleText === "EMPLOYER" || roleText === "ADVISOR",
    isAdvisor: roleText === "ADVISOR",
  };
}

export async function extractParamContext(
  empId: string,
  planId: string,
  role: string
): Promise<Session> {
  if (planId && empId && role) {
    const roleText = getRoleForString(role);
    return {
      planId: Number(planId),
      empId: Number(empId),
      role: roleText,
      status: AsyncStatus.done,
      isAdvisorOrEmployer: roleText === "EMPLOYER" || roleText === "ADVISOR",
      isAdvisor: roleText === "ADVISOR",
    };
  }
  return {} as Session;
}

export function getFromSessionString(): Session {
  const sessionString = sessionStorage.getItem("session_string");
  if (sessionString) {
    const session = JSON.parse(sessionString);
    if (session) {
      const roleText = getRoleForString(session.role);
      return {
        planId: Number(session.planId),
        empId: Number(session.memberId),
        role: roleText,
        status: AsyncStatus.done,
        isAdvisorOrEmployer: roleText === "EMPLOYER" || roleText === "ADVISOR",
        isAdvisor: roleText === "ADVISOR",
      };
    }
  }

  return {} as Session;
}

export async function attemptSessionFromEnterpriseId(
  role: string = "MEMBER"
): Promise<Session> {
  const context = await getContextByEnterpriseId(role);
  if (context.employeeContext) {
    const singularPlanContext = context.employeeContext[0]; // in the future, could allow a choice if > 1 context
    const roleText = getRoleForString(role);
    return {
      empId: Number(singularPlanContext.empId),
      planId: Number(singularPlanContext.planId),
      role: roleText,
      status: AsyncStatus.done,
      isAdvisorOrEmployer: roleText === "EMPLOYER" || roleText === "ADVISOR",
      isAdvisor: roleText === "ADVISOR",
    };
  }
  return {} as Session;
}

export function logout() {
  window.location.assign(jsfDomain + "member/accounts");
}
