import {AssetClassType} from "./asset-class-type";
import {Disclosures} from "./disclosures";
import {InvestmentBalance} from "./investment-balance";
import {InvestmentHistory} from "./investment-history";
import {InvestmentLinks} from "./investment-links";
import {NavChange} from "./investments/nav-change";
import {PfioInvestmentData} from "./investments/pfioInvestmentData";

export interface InvestmentV2 {
    useLiabilitiesRest: boolean;
    id: number;
    accountId?: number;
    moneyTypeId?: number;
    moneyTypeName?: string;
    allocationPercentage?: number;
    assetClassId?: AssetClassType;
    name: string;
    symbol?: string;
    links?: InvestmentLinks;
    hasActivity?: boolean;
    timeWeightedBalance?: number;
    beginBalance?: InvestmentBalance;
    endBalance?: InvestmentBalance;
    isActive?: boolean;
    beginDate?: Date;
    endDate?: Date;
    averageYearlyContributionAmt?: number;
    contributionAmtToDate?: number;
    contributionAmt?: number;
    distributionAmt?: number;
    dividendAmt?: number;
    earningAmt?: number;
    forfeitAmt?: number;
    prrAmt?: number;
    transferAmt?: number;
    ajcaTransferInAmt?: number;
    ajcaTransferOutAmt?: number;
    companyTransferInAmt?: number;
    companyTransferOutAmt?: number;
    netTransactionAmt?: number;
    feeAmt?: number;
    currentPercent?: number;
    sharePrice?: number;
    planTransfersToDate?: number;
    inceptionDate?: string;
    institutionName?: string;
    securityId?: string;
    catId?: string;
    classId?: number;
    assetClass?: string;
    hexValue?: string;
    sortOrder?: number;
    disclosureIds?: Disclosures[];
    monthlyHistory?: InvestmentHistory;
    quarterlyHistory?: InvestmentHistory;
    stableLikeValue: boolean;
    isOutsideColi: boolean;
    isPfio?: boolean;
    pfioInvestmentData: PfioInvestmentData;
    navChanges?: NavChange;
}

export function getBeginningBalance(investment: InvestmentV2) {
    // @ts-ignore
    return investment.beginBalance.cashBalance.balance;
}

export function getDistributionsForfeituresExpenses(investment: InvestmentV2) {
    // To get the total value, we add the distribution amount + forfeiture amount. The fee amount is already
    // included in the distribution amount according to Liabilities, so we don't need to add that here
    // @ts-ignore
    return investment.distributionAmt + investment.forfeitAmt;
}

export function getEndingBalance(investment: InvestmentV2) {
    // @ts-ignore
    return investment.endBalance.cashBalance.balance;
}

export function getDistributionsForfeituresExpensesSafely(investment: InvestmentV2) {
    if (investment && investment.distributionAmt && investment.forfeitAmt) {
        return getDistributionsForfeituresExpenses(investment);
    } else {
        return "N/A";
    }
}

export function getBeginningBalanceSafely(investment: InvestmentV2) {
    if (investment && investment.beginBalance && investment.beginBalance.cashBalance && investment.beginBalance.cashBalance.balance) {
        return getBeginningBalance(investment);
    } else {
        return "N/A";
    }
}

export function getEndingBalanceSafely(investment: InvestmentV2) {
    if (investment && investment.endBalance && investment.endBalance.cashBalance && investment.endBalance.cashBalance.balance) {
        return getEndingBalance(investment);
    } else {
        return "N/A";
    }
}
