import { DefinedBenefitAboutDescriptions } from "../models/overview-benefit/defined-benefit-about-descriptions";
import { get } from "../repositories/participant";

export async function getDefinedBenefitAboutPageDescriptions(
  empId: number,
  planId: number,
  role: string
): Promise<DefinedBenefitAboutDescriptions> {
  return get(
    `/employees/${empId}/plans/${planId}/defined-benefit-about/descriptions`,
    { role }
  );
}
