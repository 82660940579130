export enum BeneficiaryAlertText {
  NO_BENEFICIARIES = "You must designate at least one primary beneficiary.",
  NO_CONTINGENT_WITHOUT_PRIMARY = "You must designate primary beneficiaries in order to submit contingent beneficiaries.",
  INVALID_ALLOCATIONS = "The primary beneficiary amounts and the contingent beneficiary amounts must each add up to 100%.",
  ZERO_PERCENT_ALLOCATIONS = "Beneficiaries cannot be allocated 0%. Either delete the beneficiary or allocate a percentage greater than 0.",
  MADE_CHANGES = "You have made changes to your beneficiaries. These changes are not complete until you review and submit. These changes will be lost if you cancel or navigate away from this page.",
  ADDED_OR_DELETED = "You have added or deleted beneficiaries. Please ensure your allocations are correct before submitting your changes. Please note that these changes are not complete until you review and submit.",
}

export enum BeneficiaryModalValidationText {
  SELECT_A_RELATIONSHIP = "Please select a relationship option.",
  ENTER_A_NAME = "Please enter a name.",
  ENTER_AN_ADDRESS = "Please enter an address.",
  ENTER_A_CITY = "Please enter a city name.",
  ENTER_A_ZIPCODE = "Please enter a zip code.",
  SELECT_A_STATE = "Please select a state.",
}

export enum BeneficiaryDisclaimerText {
  ONLINE_NOT_SUPPORTED = "Your plan does not currently support online changes to your beneficiaries. If you would like to make changes to your beneficiary designations, please contact your plan sponsor.",
  IF_NO_BENEFICIARY_DESIGNATED = "If no beneficiary is designated, your benefit will be distributed in accordance with the terms of the plan document.",
  ALL_PRIMARY_DESIGNATIONS_PASSED = "If all primary beneficiaries pass away before the full benefits are distributed, the remaining benefits will be distributed to the contingent beneficiary(ies).",
  NO_ASSETS_HELD = "The amounts listed on this website represent a measure of your benefits under a non-qualified benefit agreement between you and your plan sponsor. It is not a representation that your plan sponsor holds any assets for paying these benefits.",
  PLAN_SELECTION_OTHER_PLANS = "If you would like to designate these beneficiaries for your other plans, please select the desired plans below.",
  PLAN_SELECTION_OVERWRITE_WARNING = "Please note that for each plan selected and confirmed, all existing beneficiary designations for these plans will be erased and replaced with the designations you are making now.",
}

export enum BeneficiaryConfirmationText {
  SUBMIT_SUCCESSFUL = "You have successfully updated your beneficiaries.",
  SUBMIT_ERROR = "There was an error updating your information. Please try again later.",
}
