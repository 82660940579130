import React from "react";
import { useSelector } from "../../store/store";
import Balance from "../numbers/balance";
import TableV2 from "../table/tableV2";
import { selectedAccountAtAGlanceSelector } from "../../selectors/account-at-a-glance";

type CellInfo = {
  value?: any;
};
type CellRows = {
  rows: Sum[];
};
type Sum = {
  values: any;
};

export default function AccountContributionsTable() {
  const accountAtAGlance = useSelector(selectedAccountAtAGlanceSelector);
  const selectedAccountsHaveContributions = accountAtAGlance.length > 0;

  const columns = [
    {
      Header: "",
      accessor: "moneyName",
      Footer: <strong>Total</strong>,
    },
    {
      Header: <span className="bold">Contributions this period</span>,
      accessor: "contribsThisPeriod",
      className: "right",
      Cell: ({ value }: CellInfo) => <Balance value={value} />,
      Footer: (info: CellRows) => {
        // Only calculate total visits if rows change
        const total = React.useMemo(
          () =>
            info.rows.reduce(
              (sum, row) => row.values.contribsThisPeriod + sum,
              0
            ),
          [info.rows]
        );

        return <Balance value={total} />;
      },
    },
    {
      Header: <span className="bold">Distributions this period</span>,
      accessor: "distribsThisPeriod",
      className: "right",
      Cell: ({ value }: CellInfo) => <Balance value={value} />,
      Footer: (info: CellRows) => {
        // Only calculate total visits if rows change
        const total = React.useMemo(
          () =>
            info.rows.reduce(
              (sum, row) => row.values.distribsThisPeriod + sum,
              0
            ),
          [info.rows]
        );

        return <Balance value={total} />;
      },
    },
    {
      Header: <span className="bold">Contributions since inception</span>,
      accessor: "contribsToDate",
      className: "right",
      Cell: ({ value }: CellInfo) => <Balance value={value} />,
      Footer: (info: CellRows) => {
        // Only calculate total visits if rows change
        const total = React.useMemo(
          () =>
            info.rows.reduce((sum, row) => row.values.contribsToDate + sum, 0),
          [info.rows]
        );

        return <Balance value={total} />;
      },
    },
    {
      Header: <span className="bold">Current balance ($)</span>,
      accessor: "currBalance",
      className: "right",
      Cell: ({ value }: CellInfo) => <Balance value={value} />,
      Footer: (info: CellRows) => {
        // Only calculate total visits if rows change
        const total = React.useMemo(
          () =>
            info.rows.reduce((sum, row) => {
              return row.values.currBalance + sum;
            }, 0),
          [info.rows]
        );

        return <Balance value={total} />;
      },
    },
    {
      Header: <span className="bold">Vested balance ($)</span>,
      accessor: "vestBalance",
      className: "right",
      Cell: ({ value }: CellInfo) => <Balance value={value} />,
      Footer: (info: CellRows) => {
        // Only calculate total visits if rows change
        const total = React.useMemo(
          () => info.rows.reduce((sum, row) => row.values.vestBalance + sum, 0),
          [info.rows]
        );

        return <Balance value={total} />;
      },
    },
  ];
  return (
    <div data-testid="contributions-table">
      {selectedAccountsHaveContributions ? (
        <TableV2 columns={columns} data={accountAtAGlance} />
      ) : (
        <p>This account has no recent contributions.</p>
      )}
    </div>
  );
}
