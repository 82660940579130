import { html, nothing } from 'lit';
import { property } from 'lit/decorators.js';
import { pdsCustomElement as customElement } from '../../decorators/pds-custom-element';
import { PdsElement } from '../PdsElement';
import styles from './tab-button-list.scss?inline';

/**
 * @summary This component is a wrapper for a list of pds-tab-button elements
 *
 * @slot default Required: A list of tab buttons, restricted to pds-tab-button elements
 */
@customElement('pds-tab-button-list', {
  category: 'component',
  type: 'component',
  styles,
})
export class PdsTabButtonList extends PdsElement {
  /**
   * ARIA label for the tab set, must be included if the tab set is not labeled by a heading and refenced by ariaLabelledby
   */
  @property({ type: String })
  ariaLabel: string;

  /**
   * Reference to the id of an element to be used as a label for the tab set, must be included if the tab set is not labeled by ariaLabel
   */
  @property({ type: String })
  ariaLabelledby: string;

  /**
   * Adds a justified behavior to the tab buttons
   */
  @property({ type: Boolean })
  justified: boolean = false;

  /**
   * @internal
   */
  get classNames() {
    return {
      justified: this.justified,
    };
  }

  render() {
    if (this.ariaLabelledby && this.ariaLabel) {
      console.warn(
        'Both ariaLabel and ariaLabelledby are defined.  ariaLabelledby will be ignored for the tab-button-list.',
        this,
      );
    } else if (!this.ariaLabel && !this.ariaLabelledby) {
      console.error(
        'Neither ariaLabel nor ariaLabelledby are defined. Please define one for the tab-button-list.',
        this,
      );

      return '';
    }

    return html`<div
      role="tablist"
      aria-label=${this.ariaLabel || nothing}
      aria-labelledby=${this.ariaLabelledby || nothing}
      class=${this.getClass()}
    >
      <slot></slot>
    </div>`;
  }
}
