import React from "react";
import TableV2 from "../../../../components/table/tableV2";
import {
  buildContributionType,
  buildCurrentDeferralElectionsColumn,
  buildEffectiveDateColumn,
} from "./deferal-election-util";

type Props = {
  data?: any;
};

export default function DeferralElectionTable(props: Props) {
  const { data } = props;
  const defaultColumns = [
    buildContributionType(),
    buildCurrentDeferralElectionsColumn(),
    buildEffectiveDateColumn(),
  ];

  return <TableV2 columns={defaultColumns} data={data} />;
}
