import axios from "axios";
import { User } from "oidc-client-ts";
import { securityTokenPath, tokenHost } from "../util/constants";

const url = tokenHost + securityTokenPath;

export async function getAccessToken(oktaUser: User | null | undefined) {
  const { data } = await axios.post(
    url,
    {
      oktaToken: oktaUser,
    },
    {
      params: {
        withCredentials: true,
      },
    }
  );
  return data;
}
