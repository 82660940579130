import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AsyncStatus } from "../../models/async-status";
import { GetAllInvestmentsArgs } from "../../services/investment-data";
import { getPendingContributionRates } from "../../services/contributions";
import { PendingContributionRate } from "../../models/contributions";

export const fetchPendingContributionRates = createAsyncThunk(
  "fetch-pending-contribution-rates",
  async (args: GetAllInvestmentsArgs) => {
    return getPendingContributionRates(args);
  }
);

type PendingContributionState = {
  data: PendingContributionRate[];
  status: AsyncStatus;
};

const initialState: PendingContributionState = {
  data: [],
  status: AsyncStatus.not_fetched,
};

const pendingContributionSlice = createSlice({
  name: "pendingContribution",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPendingContributionRates.pending, (state, action) => {
      return { ...state, status: AsyncStatus.loading };
    });
    builder.addCase(
      fetchPendingContributionRates.fulfilled,
      (state, action) => {
        return { ...state, data: action.payload, status: AsyncStatus.done };
      }
    );
    builder.addCase(fetchPendingContributionRates.rejected, (state, action) => {
      return { ...state, status: AsyncStatus.error };
    });
    builder.addDefaultCase((state, action) => state);
  },
});

export default pendingContributionSlice.reducer;
