import { html } from 'lit';
import { X as XIcon } from 'lucide-static';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { state } from 'lit/decorators.js';
import { pdsCustomIconElement as customElement } from '../../../decorators/pds-icon';
import { PdsIcon } from '../PdsIcon';

@customElement('pds-icon-x')
export class PdsIconX extends PdsIcon {
  @state()
  icon = XIcon;

  render() {
    return html`${unsafeHTML(this.setIconAttributes())}`;
  }
}
