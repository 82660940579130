import React from "react";
import { BeneficiaryDisclaimerText } from "../../../models/beneficiaries/beneficiary-text";

export function BeneficiariesDisclaimerDisplay() {
  return (
    <div>
      <p>{BeneficiaryDisclaimerText.IF_NO_BENEFICIARY_DESIGNATED}</p>
      <p>{BeneficiaryDisclaimerText.ALL_PRIMARY_DESIGNATIONS_PASSED}</p>
      <p>{BeneficiaryDisclaimerText.NO_ASSETS_HELD}</p>
    </div>
  );
}
