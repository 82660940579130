import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AsyncStatus } from "../../models/async-status";
import { postRedeferralInstructions } from "../../services/redeferral";
import { RedeferralInstruction } from "../../models/redeferral/redeferral-instruction";

export const saveRedeferralInstructions = createAsyncThunk(
  "save-redeferral-instructions",
  async (
    {
      planId,
      empId,
      role,
      accountId,
      data,
    }: {
      planId: number;
      empId: number;
      role: string;
      accountId: number;
      data: RedeferralInstruction[];
    },
    thunkApi
  ) => {
    return postRedeferralInstructions({ planId, empId, accountId, role, data });
  }
);

type State = {
  redeferralInstructions: RedeferralInstruction[];
  status: AsyncStatus;
};

const initialState: State = {
  redeferralInstructions: [],
  status: AsyncStatus.done,
};

const redeferralInstructionsSlice = createSlice({
  name: "redeferralInstructions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(saveRedeferralInstructions.pending, (state, action) => {
      return { ...state, status: AsyncStatus.loading };
    });
    builder.addCase(
      saveRedeferralInstructions.fulfilled,
      (state, action: { payload: RedeferralInstruction[]; type: string }) => {
        const redeferralInstructions = action.payload;

        return {
          ...state,
          redeferralInstructions,
          status: AsyncStatus.done,
        };
      }
    );
    builder.addCase(saveRedeferralInstructions.rejected, (state, action) => {
      return { ...state, status: AsyncStatus.error };
    });
    builder.addDefaultCase((state, action) => state);
  },
});

export default redeferralInstructionsSlice.reducer;
