import classnames from "classnames";
import React, { useContext, useState } from "react";
import "./panel.scss";

const TogglePanelContext = React.createContext();

function TogglePanelContextProvider(props) {
  const [showChart, setShowChart] = useState(true);

  function toggleShowChart() {
    setShowChart(!showChart);
  }

  return (
    <TogglePanelContext.Provider value={{ showChart, toggleShowChart }}>
      {props.children}
    </TogglePanelContext.Provider>
  );
}

export function TogglePanel(props) {
  return (
    <TogglePanelContextProvider>
      <div className={`panel panel-default ${props.className}`}>
        {props.children}
      </div>
    </TogglePanelContextProvider>
  );
}

export function Panel(props) {
  const className = classnames(
    "panel panel-default",
    {
      "hidden-print-panel": props.noPrint,
    },
    props.className
  );
  return <div className={className}>{props.children}</div>;
}

export function TogglePanelHeading(props) {
  const { showChart, toggleShowChart } = useContext(TogglePanelContext);
  return (
    <div className="panel-heading toggle-panel-heading">
      {props.children}
      <span>
        <label>
          <input
            type="checkbox"
            checked={showChart}
            onChange={toggleShowChart}
          />{" "}
          Chart
        </label>
      </span>
    </div>
  );
}

export function PanelTable(props) {
  const { toggleType } = useContext(TogglePanelContext);
  if (toggleType === "table") {
    return <>{props.children}</>;
  }
  return null;
}

export function PanelChart(props) {
  const { showChart } = useContext(TogglePanelContext);
  if (showChart) {
    return <>{props.children}</>;
  }
  return null;
}

export function PanelHeading(props) {
  const className = classnames("panel-heading", {
    center: props.center,
    "hidden-print-panel": props.noPrint,
  });
  return (
    <div className={`${className} ${props.className}`}>{props.children}</div>
  );
}

export function PanelBody(props) {
  return <div className="panel-body">{props.children}</div>;
}
