import React, { useEffect, useState } from "react";
import StepIndicator from "../../../components/step-indicator/step-indicator";
import { useAppDispatch, useSelector } from "../../../store/store";
import { fetchScheduledDistributionsForRedeferral } from "../../../reducers/redeferral/scheduledDistributionForRedeferral";
import { fetchMarketCloseDates } from "../../../reducers/redeferral/marketCloseDates";
import RedeferIndividualTable from "./redefer-individual-table";
import { IndividualSteps, RedeferRoutes, RedeferStep } from "./redefer-step";
import RedeferralNavigation from "../../../components/redefferal/redeferral-navigation/redeferral-navigation";
import { ScheduledDistributionForRedeferral } from "../../../models/distributions/scheduled-distribution-event";
import { RedeferralInstruction } from "../../../models/redeferral/redeferral-instruction";
import { saveRedeferralInstructions } from "../../../reducers/redeferral/redeferral-instructions";
import { selectedAccountIdsSelector } from "../../../selectors/accounts";
import { useLocation } from "react-router-dom";
import qs from "query-string";
import { useSingleAccount } from "../../../util/use-single-account";

export interface Props {
  currentPosition: RedeferRoutes;
  editable: boolean;
  handleStepChange: (position: number) => void;
  steps: RedeferRoutes[];
}

export default function RedeferIndividual(props: Props) {
  const { planId, empId, role } = useSelector((state) => state.session);
  const { currentPosition, editable, handleStepChange, steps } = props;
  const selectedIds = useSelector(selectedAccountIdsSelector);
  const scheduledDistributionsForRedeferral = useSelector(
    (state) =>
      state.redeferrals.scheduledDistributionsForRedeferral
        .scheduledDistributionsForRedeferral
  );
  const [error, setError] = useState("");
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const { accountId } = qs.parse(search);
  useSingleAccount(Number(accountId));

  useEffect(() => {
    if (selectedIds.length === 1 && currentPosition === IndividualSteps[0]) {
      dispatch(
        fetchScheduledDistributionsForRedeferral({
          planId,
          empId,
          accountId: selectedIds[0],
          role,
        })
      );
      dispatch(fetchMarketCloseDates({ planId, empId, role }));
    }
  }, [currentPosition, dispatch, empId, planId, role, selectedIds]);

  function getStepNames(): string[] {
    return steps.map((x) => x.step);
  }

  const handleSubmit = (newPosition: number) => {
    if (currentPosition.step === RedeferStep.DistributionElections) {
      if (performValidations()) {
        handleStepChange(newPosition);
      }
    }
    if (currentPosition.step === RedeferStep.Review) {
      const redeferralInstructions = scheduledDistributionsForRedeferral
        .filter((x) => x.isRedeferred)
        .map((scheduledDistribution) => {
          return new RedeferralInstruction(scheduledDistribution);
        });
      dispatch(
        saveRedeferralInstructions({
          planId,
          empId,
          role,
          accountId: selectedIds[0],
          data: redeferralInstructions,
        })
      ).then(() => {
        handleStepChange(newPosition);
      });
    }
  };

  function performValidations(): boolean {
    if (!isValid(scheduledDistributionsForRedeferral)) {
      setError("You must redefer at least one account");
      return false;
    }
    setError("");
    return true;
  }

  return (
    <>
      <StepIndicator
        labels={getStepNames()}
        currentPosition={currentPosition.stepNumber}
      />
      <RedeferIndividualTable
        editable={editable}
        scheduledDistributions={scheduledDistributionsForRedeferral}
        error={error}
      />
      <RedeferralNavigation
        currentPosition={currentPosition.stepNumber}
        handleStepChange={handleSubmit}
      />
    </>
  );
}

function isValid(
  scheduledDistributions: ScheduledDistributionForRedeferral[]
): boolean {
  return scheduledDistributions.some((x) => x.isRedeferred);
}
