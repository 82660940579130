import {format, utcToZonedTime} from "date-fns-tz";
import _addDays from "date-fns/addDays";
import _addMonths from "date-fns/addMonths";
import _addWeeks from "date-fns/addWeeks";
import _addYears from "date-fns/addYears";
import endOfMonth from "date-fns/endOfMonth";
import _getDate from "date-fns/getDate";
import getDay from "date-fns/getDay";
import _getDaysInMonth from "date-fns/getDaysInMonth";
import getWeek from "date-fns/getWeek";
import getYear from "date-fns/getYear";
import _isAfter from "date-fns/isAfter";
import _isBefore from "date-fns/isBefore";
import isDate from "date-fns/isDate";
import isEqual from "date-fns/isEqual";
import _isSameDay from "date-fns/isSameDay";
import max from "date-fns/max";
import parse from "date-fns/parse";
import parseISO from "date-fns/parseISO";
import startOfDay from "date-fns/startOfDay";
import startOfMonth from "date-fns/startOfMonth";
import startOfYear from "date-fns/startOfYear";
import subMonths from "date-fns/subMonths";
import subYears from "date-fns/subYears";
import toDate from "date-fns/toDate";
import {addStringPadding} from "./string-util";

const dateFormat = "MM-dd-yyyy";
export const yyyy_mm_dd = "yyyy-MM-dd";
const mm_dd_yyyy = "MM/dd/yyyy";
const dateWithTimeFormat = "yyyy-MM-dd HH:mm:ss.S";
export const userFacingDate = "MMM dd, yyyy";

export function adjustToNextOpenDay(
  day: number,
  month: number,
  year: number,
  marketClosedDates: string[] | null,
  adjustBack: boolean
): Date {
  const increment = adjustBack ? -1 : 1;
  let nextDay = makeDateFromDMY(day, month, year);
  while (isWeekendOrMarketClosedDate(nextDay, marketClosedDates)) {
    nextDay = addDays(nextDay, increment);
  }
  return nextDay;
}

export function isWeekendOrMarketClosedDate(
  dt: Date,
  marketClosedDates: string[] | null
): boolean {
  const month = dt.getUTCMonth() + 1;
  const day = dt.getUTCDate();
  const year = dt.getUTCFullYear();
  return isWeekendOrMarketClosedDateDMY(day, month, year, marketClosedDates);
}

export function isWeekendOrMarketClosedDateDMY(
  day: number,
  month: number,
  year: number,
  marketClosedDates: string[] | null
): boolean {
  const paddedMonth = addStringPadding(month, 2);
  const paddedDay = addStringPadding(day, 2);
  const selectedDate = `${year}-${paddedMonth}-${paddedDay}`;
  const isMarketClosedDate =
    !!marketClosedDates && marketClosedDates.includes(selectedDate);
  const isWeekend = isWeekendDMY(Number(day), month, Number(year));
  return isWeekend || isMarketClosedDate;
}

export function adjustDateToBeAvailable(
  day: number,
  month: number,
  year: number,
  adjustDateBack: boolean,
  minimumDate: Date,
  marketClosedDates: string[] | null
) {
  if (adjustDateBack) {
    const potentialNextDate = adjustToNextOpenDay(
      Number(day),
      month,
      Number(year),
      marketClosedDates,
      true
    );
    if (potentialNextDate >= minimumDate) {
      return potentialNextDate;
    }
  }
  return adjustToNextOpenDay(
    Number(day),
    month,
    Number(year),
    marketClosedDates,
    false
  );
}

export function makeDateFromDMY(
  day: string | number,
  month: string | number,
  year: string | number
): Date {
  return new Date(`${month}/${day}/${year}`);
}

export function isWeekendDMY(
  day: number,
  month: number,
  year: number
): boolean {
  const date = new Date(`${month}/${day}/${year}`);
  return isWeekend(date);
}

export function formatDateWithoutTimeZone(
  date: Date | string | number | null | undefined,
  formatter: string = dateFormat
): string {
  try {
    if (date === null || date === undefined) {
      return "";
    }
    const parsedDate = parseDate(date);
    if (typeof date === "number") {
      const centralTimeDate = utcToZonedTime(parsedDate, "America/Chicago");
      return format(centralTimeDate, formatter);
    } else {
      return format(parsedDate, formatter);
    }
  } catch (e) {
    return "N/A";
  }
}

export function formatStringDateToMonthDayYearIgnoringTimezone(
  date: string
): string {
  const dt = new Date(date);
  return `${dt.getUTCMonth() + 1}-${dt.getUTCDate()}-${dt.getUTCFullYear()}`;
}

function getUtcDate(date: string): Date {
  const parsed = parseDate(date);
  return new Date(
    parsed.getUTCFullYear(),
    parsed.getUTCMonth(),
    parsed.getUTCDate()
  );
}

export function getUtcDateString(date: string, formatString: string): string {
  return format(getUtcDate(date), formatString);
}

export function parseDate(date: Date | string | number): Date {
  if (isDate(date)) {
    // @ts-ignore
    return date;
  }

  if (typeof date === "number") {
    return toDate(date);
  }

  // @ts-ignore
  return parseDateString(date);
}

function parseDateString(date: string): Date {
  let parsedDate = parse(date, dateFormat, new Date());
  if (isNaN(parsedDate.valueOf())) {
    parsedDate = parse(date, dateWithTimeFormat, new Date());
  }
  if (isNaN(parsedDate.valueOf())) {
    parsedDate = parse(date, yyyy_mm_dd, new Date());
  }
  if (isNaN(parsedDate.valueOf())) {
    parsedDate = parse(date, mm_dd_yyyy, new Date());
  }
  if (isNaN(parsedDate.valueOf())) {
    return parseISO(date);
  }
  return parsedDate;
}

export function isSame(date1?: Date, date2?: Date): boolean {
  if (!date1 || !date2) {
    return false;
  }

  return isEqual(date1, date2);
}

export function isSameDay(date1: Date, date2: Date) {
  return _isSameDay(date1, date2);
}

export function isAfter(
  startDate: Date | string,
  endDate: Date | string
): boolean {
  const start = parseDate(startDate);
  const end = parseDate(endDate);
  return _isAfter(start, end);
}

export function isBefore(date1: Date, date2: Date) {
  return _isBefore(date1, date2);
}

export function isSameOrBefore(date1: Date | string, date2: Date | string) {
  const start = parseDate(date1);
  const end = parseDate(date2);
  return _isBefore(start, end) || isEqual(start, end);
}

export function getStartOfYear(date = new Date()) {
  return startOfYear(date);
}

export function getDate(date = new Date()): number {
  return _getDate(date);
}

export function getStartOfMonth(date = new Date()) {
  return startOfMonth(date);
}

export function getStartOfDay(date = new Date()) {
  return startOfDay(date);
}

export function getEndOfMonth(date = new Date()) {
  return getStartOfDay(endOfMonth(date));
}

export function getDayOfWeek(date = new Date()) {
  return getDay(date);
}

export function getWeekNumber(date = new Date()): number {
  return getWeek(date);
}

export function getDaysInMonth(date = new Date()): number {
  return _getDaysInMonth(date);
}

export function addDays(date: Date, daysToAdd: number): Date {
  return _addDays(date, daysToAdd);
}

export function subtractMonths(date: Date, monthsToSubtract: number): Date {
  return subMonths(date, monthsToSubtract);
}

export function subtractYears(date: Date, yearsToSubtract: number): Date {
  return subYears(date, yearsToSubtract);
}

export function getFullYear(date: Date | string): number {
  return getYear(parseDate(date));
}

export function addYears(date: Date, yearsToAdd: number): Date {
  return _addYears(date, yearsToAdd);
}

export function addMonths(date: Date, monthsToAdd: number): Date {
  return _addMonths(date, monthsToAdd);
}

export function addWeeks(date: Date, weeksToAdd: number): Date {
  return _addWeeks(date, weeksToAdd);
}

export function getTwoYearStartDate(lastBusinessDate: string): Date {
  const date = parseDate(lastBusinessDate);
  return subtractYears(date, 2);
}

export function getMostRecentDate(stringDates: string[]): string | undefined {
  if (stringDates.length < 1) {
    return undefined;
  }
  const dates: number[] = stringDates.map((date) => {
    const dat = parseDate(date);
    return Number(dat);
  });
  const maxDate = max(dates);
  return formatDateWithoutTimeZone(maxDate);
}

export function getMostRecentDateStr(
  stringDates: string[]
): string | undefined {
  if (stringDates.length < 1) {
    return undefined;
  }
  const dates: string[] = stringDates.map((date) => {
    if (date.length === 10) {
      return date.substring(6, 10) + date.substring(0, 2) + date.substring(3, 5);
    } else {
      return "          ";
    }
  });
  const maxDate = dates.sort().reverse()[0];
  return (
    maxDate.substring(4, 6) +
    "-" +
    maxDate.substring(6, 8) +
    "-" +
    maxDate.substring(0, 4)
  );
}

export function isLeapYear(year: number): boolean {
  const divisibleBy4 = year % 4 === 0;
  const notDivisibleBy100 = year % 100 !== 0;
  const divisibleBy400 = year % 400 === 0;
  return (divisibleBy4 && notDivisibleBy100) || divisibleBy400;
}

export function getNumbersBetween(min: number, max: number): number[] {
  const numbers: number[] = [];
  for (let i = min; i <= max; i++) {
    numbers.push(i);
  }
  return numbers;
}

export const MONTH_NUMBERS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

export function isWeekend(date: Date): boolean {
  const dayOfWeek = date.getDay();
  return dayOfWeek === 6 || dayOfWeek === 0;
}

export function getDayOptionsByYearAndMonth(
  year: number,
  month: number
): number[] {
  const maxDay = getDayCountByYearAndMonth(year, month);
  return getNumbersBetween(1, maxDay);
}

export function getDayCountByYearAndMonth(year: number, month: number): number {
  month = Number(month);
  switch (month) {
    case 2:
      const isLeap = year === null || isLeapYear(year);
      return isLeap ? 29 : 28;
    case 1:
    case 3:
    case 5:
    case 7:
    case 8:
    case 10:
    case 12:
      return 31;
    default:
      return 30;
  }
}

export function getMonthName(monthNumber: number): string {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return monthNames[monthNumber];
}

export function getMonthShortName(monthNumber: number): string {
  const monthShortNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return monthShortNames[monthNumber];
}

export function getDateStringWithoutTimezoneFromDashDate(
  initialDate: string | undefined,
  slashFormat = false
): string {
  if (initialDate) {
    const removeTime = initialDate.toString().split("T")[0];
    const removeSpace = removeTime.split(" ")[0];
    const datePieces = removeSpace.split("-"); // split date pieces
    const month = Number(datePieces[1]) - 1;
    const date = datePieces[2];
    const year = datePieces[0];
    if (slashFormat) {
      return `${month + 1}/${date}/${year}`;
    } else {
      return `${getMonthShortName(month)} ${date}, ${year}`;
    }
  } else {
    return "";
  }
}

export function formatStringDateToMonthDayYear(date: string): string {
  const dateArr = date.split("/");
  const month = getMonthShortName(parseInt(dateArr[0]) - 1);
  return month + " " + dateArr[1] + ", " + dateArr[2];
}

export function formatYYYYMMDDDashStringDateToMonthDayYear(
  date: string
): string {
  const dateArr = date.split("-");
  const month = getMonthShortName(parseInt(dateArr[1]) - 1);
  return month + " " + dateArr[2] + ", " + dateArr[0];
}

export function getDashFormattedDateStringFromISOString(
  dateIsoString: string | undefined
): string {
  if (dateIsoString) {
    const newDate = parseISOStringIntoDate(dateIsoString);
    return format(newDate!, "MM-dd-yyyy");
  } else {
    return "";
  }
}

export function parseISOStringIntoDate(
  dateIsoString: string | undefined
): Date {
  if (dateIsoString) {
    const splitDate = dateIsoString.split("T")[0].split("-");
    return new Date(+splitDate![0], +splitDate![1] - 1, +splitDate![2]);
  } else {
    return new Date();
  }
}

export function getNewAdjustedDate(
  selectedYear: string,
  selectedMonth: string,
  selectedDay: string,
  definedMonth: number | null,
  definedDay: number | null,
  adjustDateBack: boolean,
  marketClosedDates: string[],
  minimumDate: Date
) {
  let year = selectedYear;

  // Use Plan Defined Month if it exists
  let month = Number(selectedMonth) + 1; // month input is off-by-one
  if (definedMonth) {
    month = definedMonth;
  }

  // Use Plan Defined Day if it exists
  let day = selectedDay;
  if (definedDay) {
    day = definedDay.toString();
    const isUnavailable = isWeekendOrMarketClosedDateDMY(
      Number(day),
      month,
      Number(year),
      marketClosedDates
    );
    if (isUnavailable) {
      // If the defined day is unavailable, we need to pick an available day for the user.
      const nextDate = adjustDateToBeAvailable(
        Number(day),
        month,
        Number(year),
        adjustDateBack,
        minimumDate,
        marketClosedDates
      );

      month = nextDate.getUTCMonth() + 1;
      day = nextDate.getUTCDate().toString();
      year = nextDate.getUTCFullYear().toString();
    }
  }

  return `${addStringPadding(month, 2)}-${addStringPadding(day, 2)}-${year}`;
}

export function isMMDDYYYYSlashDate(string: string): boolean {
  const result = string?.match(
    "^(0?[1-9]|1[012])[\\/](0?[1-9]|[12][0-9]|3[01])[\\/\\-]\\d{4}$"
  );
  return result != null;
}


export function getLastDayOfPreviousMonth(input: Date): Date {
  return new Date(new Date(input).setDate(0));
}


export const JANUARY = 0;
export const FEBUARY = 1;
export const MARCH = 2;
export const APRIL = 3;
export const MAY = 4;
export const JUNE = 5;
export const JULY = 6;
export const AUGUST = 7;
export const SEPTEMBER = 8;
export const OCTOBER = 9;
export const NOVEMBER = 10;
export const DECEMBER = 11;
