import React, { useEffect, useState } from "react";
import { getSFHealthResponse } from "../../services/salesforce.service";
import { get as getParticipantWrapper } from "../../repositories/participant";
import "./dependency-health-status.scss";
import { get as getEnhancedEnrollment } from "../../repositories/enhanced-enrollment";
import { get as getEnrollment } from "../../repositories/enrollment";

type healthResponse = {
  status: string;
};

export default function DependencyHealthStatus() {
  const [salesforceHealthStatus, setSalesforceHealthStatus] =
    useState<boolean>(false);
  const [participantWrapperStatus, setParticipantWrapperStatus] =
    useState<boolean>(false);
  const [enhancedEnrollmentStatus, setEnhancedEnrollmentStatus] =
    useState<boolean>(false);
  const [enrollmentStatus, setEnrollmentStatus] = useState<boolean>(false);

  async function fetchHealthStatus(service: Function, setter: Function) {
    const healthResponse: healthResponse = await service("actuator/health");
    setter(healthResponse?.status.toUpperCase() === "UP");
  }

  useEffect(() => {
    fetchHealthStatus(getSFHealthResponse, setSalesforceHealthStatus);
    fetchHealthStatus(getParticipantWrapper, setParticipantWrapperStatus);
    fetchHealthStatus(getEnhancedEnrollment, setEnhancedEnrollmentStatus);
    fetchHealthStatus(getEnrollment, setEnrollmentStatus);
  }, []);

  function getHealthStatusClasses(healthStatus: boolean): string {
    return healthStatus ? "well status-box bg-green" : "well status-box bg-red";
  }

  return (
    <div className={"div-style row"}>
      <table className={"table status-table"}>
        <thead>
          <tr>
            <th>Service</th>
            <th>Status</th>
            <th>Refresh</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Salesforce Wrapper Service</td>
            <td>
              <div
                className={getHealthStatusClasses(salesforceHealthStatus)}
                data-testid={"salesforce-wrapper-status"}
              >
                <div>
                  <p className={"util-margin-0"}>
                    {salesforceHealthStatus ? "Up" : "Down"}
                  </p>
                </div>
              </div>
            </td>
            <td>
              <button
                className={"btn btn-default"}
                onClick={() =>
                  fetchHealthStatus(
                    getSFHealthResponse,
                    setSalesforceHealthStatus
                  )
                }
              >
                Get status
              </button>
            </td>
          </tr>
          <tr>
            <td>Participant Wrapper Service</td>
            <td>
              <div
                className={getHealthStatusClasses(participantWrapperStatus)}
                data-testid={"participant-wrapper-status"}
              >
                <div>
                  <p className={"util-margin-0"}>
                    {participantWrapperStatus ? "Up" : "Down"}
                  </p>
                </div>
              </div>
            </td>
            <td>
              <button
                className={"btn btn-default"}
                onClick={() =>
                  fetchHealthStatus(
                    getParticipantWrapper,
                    setParticipantWrapperStatus
                  )
                }
              >
                Get status
              </button>
            </td>
          </tr>
          <tr>
            <td>Enhanced Enrollment Service</td>
            <td>
              <div
                className={getHealthStatusClasses(enhancedEnrollmentStatus)}
                data-testid={"enhanced-enrollment-status"}
              >
                <div>
                  <p className={"util-margin-0"}>
                    {enhancedEnrollmentStatus ? "Up" : "Down"}
                  </p>
                </div>
              </div>
            </td>
            <td>
              <button
                className={"btn btn-default"}
                onClick={() =>
                  fetchHealthStatus(
                    getEnhancedEnrollment,
                    setEnhancedEnrollmentStatus
                  )
                }
              >
                Get status
              </button>
            </td>
          </tr>
          <tr>
            <td>Enrollment Service</td>
            <td>
              <div
                className={getHealthStatusClasses(enrollmentStatus)}
                data-testid={"enrollment-status"}
              >
                <div>
                  <p className={"util-margin-0"}>
                    {enrollmentStatus ? "Up" : "Down"}
                  </p>
                </div>
              </div>
            </td>
            <td>
              <button
                className={"btn btn-default"}
                onClick={() =>
                  fetchHealthStatus(getEnrollment, setEnrollmentStatus)
                }
              >
                Get status
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
