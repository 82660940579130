// @ts-ignore
import { ABARoutingNumberIsValid } from "bank-routing-number-validator";
import kebabCase from "lodash/kebabCase";
import React, { useEffect, useState } from "react";
import ConditionalDisplayInput from "../../components/conditional-display-input/conditional-display-input";
import ErrorWrapper from "../../components/error-wrapper/error-wrapper";
import { PaymentInfo } from "../../models/payment-info";
import { setPaymentInfo } from "../../reducers/plan/payment-info";
import { paymentInfoSelector } from "../../selectors/plan-info";
import { postPaymentInformation } from "../../services/salesforce.service";
import { useAppDispatch, useSelector } from "../../store/store";

export const fieldNotSetErrorText = "Field must be set.";
export const routingNumberErrorText = "Routing number is invalid.";
export const forFurtherCreditErrorText = "Text must be 0-150 characters.";
const getErrorMessageForMembers =
  "There was an error retrieving your information. Please try again later.";
const getErrorMessageForNonMembers =
  "There was an error retrieving your information. Only participants have access.";
const postErrorMessage =
  "There was an error updating your information. Please try again later.";
const submittingText = "Submitting...";
const accountRegex = RegExp("^[0-9a-zA-Z]{0,17}$");
const forFurtherCreditRegex = RegExp("^[0-9a-zA-Z ]{0,149}$");
const routingRegex = RegExp("^[0-9]{0,9}$");
const selectAStateText = "Select a State";
const selectAccountTypeText = "Select account type";

export type BankAccountType = {
  active: boolean;
  defaultValue: boolean;
  label: string;
  value: string;
};

export type BankState = {
  active: boolean;
  defaultValue: boolean;
  label: string;
  value: string;
};

type FormData = {
  bankName: string;
  bankState: string;
  bankRoutingNumber: string;
  bankAccountType: string;
  bankAccountNumber: string;
  bankAccountName: string;
  forFurtherCreditFFC: string;
};

type ErrorState = {
  getError: boolean;
  postError: boolean;
  achError: boolean;
};

type Props = {
  bankStates: BankState[];
  bankAccountTypes: BankAccountType[];
  ACH: boolean;
};

function PaymentInfoTable(props: Props) {
  const session = useSelector((state) => state.session);
  const paymentInfo = useSelector(paymentInfoSelector);
  const [paymentInformation, setPaymentInformation] =
    useState<Partial<PaymentInfo>>(paymentInfo);
  const dispatch = useAppDispatch();
  const initialFormState: FormData = {
    bankName: "",
    bankAccountType: "",
    bankAccountName: "",
    bankAccountNumber: "",
    forFurtherCreditFFC: "",
    bankRoutingNumber: "",
    bankState: "",
  };
  const [formData, setFormData] = useState<FormData>(initialFormState);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [editEnabled, setEditEnabled] = useState<boolean>(false);
  const [errorState, setErrorState] = useState<ErrorState>({
    getError: false,
    postError: false,
    achError: false,
  });

  useEffect(() => {
    setPaymentInformation(paymentInfo);
    initializeFormData(paymentInfo);
    function initializeFormData(data: PaymentInfo) {
      setEditEnabled(false);
      const defaultFormData = {
        bankName: "",
        bankAccountType: "",
        bankAccountName: "",
        bankAccountNumber: "",
        forFurtherCreditFFC: "",
        bankRoutingNumber: "",
        bankState: "",
      };
      let formDataPlaceholder: FormData;
      if (props.ACH) {
        formDataPlaceholder = {
          ...defaultFormData,
          bankName: data.bankName,
          bankAccountType: data.bankAccountType,
          bankAccountName: data.bankAccountName,
          bankAccountNumber: data.bankAccountNumber,
          forFurtherCreditFFC: data.forFurtherCreditFFC, // for Wire, need to be set
          bankRoutingNumber: data.bankRoutingNumber,
          bankState: data.bankState,
        };
      } else {
        formDataPlaceholder = {
          ...defaultFormData,
          bankName: data.wireBankName,
          bankAccountType: data.wireAccountType,
          bankAccountName: data.wireAccountName,
          bankAccountNumber: data.wireAccountNumber,
          forFurtherCreditFFC: data.forFurtherCreditFFC,
          bankRoutingNumber: data.wireRoutingNumber,
          bankState: data.wireState,
        };
      }
      setFormData(formDataPlaceholder);
    }
  }, [session, session.status, paymentInfo, props.ACH]); //eslint-disable-line react-hooks/exhaustive-deps

  function toggleEditEnabled() {
    setFormData(initialFormState);
    setEditEnabled(!editEnabled);
  }

  function handleSelectChange(event: React.ChangeEvent<HTMLSelectElement>) {
    const { name, value } = event.target;
    if (name === "bankRoutingNumber") {
      if (routingRegex.test(value)) {
        setFormData({ ...formData, [name]: value });
      }
    } else if (name === "bankAccountNumber") {
      if (accountRegex.test(value)) {
        setFormData({ ...formData, [name]: value });
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  }

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    if (name === "bankRoutingNumber") {
      if (routingRegex.test(value)) {
        setFormData({ ...formData, [name]: value });
      }
    } else if (name === "bankAccountNumber") {
      if (accountRegex.test(value)) {
        setFormData({ ...formData, [name]: value });
      }
    } else if (name === "forFurtherCreditFFC") {
      if (forFurtherCreditRegex.test(value)) {
        setFormData({ ...formData, [name]: value });
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  }

  function selectEntireField(event: React.ChangeEvent<HTMLInputElement>) {
    event.preventDefault();
    event.target.select();
  }

  function paymentFieldValid(fieldValue: string) {
    return (
      fieldValue !== undefined &&
      fieldValue !== "" &&
      fieldValue != null &&
      fieldValue !== selectAStateText &&
      fieldValue !== selectAccountTypeText
    );
  }

  function isAchFormValid() {
    const valuesToCheck = [
      formData.bankName,
      formData.bankAccountType,
      formData.bankAccountName,
      formData.bankAccountNumber,
      formData.bankRoutingNumber,
      formData.bankState,
    ];
    const fieldNotEmpty = valuesToCheck.every((fieldValue) =>
      paymentFieldValid(fieldValue)
    );
    const routingNumberValid = ABARoutingNumberIsValid(
      formData.bankRoutingNumber
    );
    return fieldNotEmpty && routingNumberValid;
  }

  async function savePaymentInfo() {
    if (isAchFormValid()) {
      setErrorState({ ...errorState, achError: false });
      setIsSubmitted(true);
      await updatePaymentInfo();
      setIsSubmitted(false);
    } else {
      setErrorState({ ...errorState, achError: true });
      clearErrorStateAfterDuration(5000);
    }
  }

  async function updatePaymentInfo() {
    const newPaymentInformation = mapFormDataToPaymentInfo();
    try {
      await postPaymentInformation({
        planId: session.planId,
        empId: session.empId,
        data: newPaymentInformation,
      });
      setPaymentInformation(newPaymentInformation);
      setEditEnabled(false);
      dispatch(setPaymentInfo(newPaymentInformation));
    } catch (e) {
      setErrorState({ ...errorState, postError: true });
      clearErrorStateAfterDuration(5000);
    }
  }

  function clearErrorStateAfterDuration(milliseconds: number) {
    setTimeout(() => {
      setErrorState({
        ...errorState,
        getError: false,
        postError: false,
        achError: false,
      });
    }, milliseconds);
  }

  function mapFormDataToPaymentInfo(): PaymentInfo {
    if (props.ACH) {
      return {
        ...paymentInfo,
        employeeId: session.empId,
        planId: session.planId,
        wireACH: "ACH",
        bankState: formData.bankState,
        bankRoutingNumber: formData.bankRoutingNumber,
        bankName: formData.bankName,
        bankAccountType: formData.bankAccountType,
        bankAccountNumber: formData.bankAccountNumber,
        bankAccountName: formData.bankAccountName,
      };
    } else {
      return {
        ...paymentInfo,
        employeeId: session.empId,
        planId: session.planId,
        wireACH: "Wire",
        forFurtherCreditFFC: formData.forFurtherCreditFFC,
        wireState: formData.bankState,
        wireRoutingNumber: formData.bankRoutingNumber,
        wireBankName: formData.bankName,
        wireAccountType: formData.bankAccountType,
        wireAccountNumber: formData.bankAccountNumber,
        wireAccountName: formData.bankAccountName,
      };
    }
  }

  const tableHeaderText = props.ACH
    ? "Direct deposit payment information (ACH)"
    : "Wire payment information";
  const bankName = props.ACH
    ? paymentInformation.bankName
    : paymentInformation.wireBankName;
  const bankState = props.ACH
    ? paymentInformation.bankState
    : paymentInformation.wireState;
  const bankAccountType = props.ACH
    ? paymentInformation.bankAccountType
    : paymentInformation.wireAccountType;
  const bankAccountName = props.ACH
    ? paymentInformation.bankAccountName
    : paymentInformation.wireAccountName;
  const bankRoutingNumber = props.ACH
    ? paymentInformation.bankRoutingNumber
    : paymentInformation.wireRoutingNumber;
  const bankAccountNumber = props.ACH
    ? paymentInformation.bankAccountNumber
    : paymentInformation.wireAccountNumber;
  return (
    <table data-testid="payment-info-table" className="table">
      <thead>
        <tr>
          <th>
            <h3>{tableHeaderText}</h3>
          </th>
          <th>
            {editEnabled ? (
              <>
                <button
                  className="btn btn-default pull-right"
                  data-testid="save-update-payment-button"
                  onClick={savePaymentInfo}
                  disabled={isSubmitted || errorState.postError}
                >
                  Save
                </button>
                <button
                  className="btn btn-danger pull-right"
                  id="cancel-btn"
                  data-testid="cancel-update-payment-info"
                  onClick={toggleEditEnabled}
                >
                  Cancel
                </button>
                {errorState.postError && (
                  <div
                    className={"error-message has-error post-error-message"}
                    data-testid="post-error-message"
                  >
                    <i className="fa fa-exclamation-triangle" />
                    {postErrorMessage}
                  </div>
                )}
                {isSubmitted && !errorState.postError && (
                  <div data-testid="show-submitting-text">{submittingText}</div>
                )}
              </>
            ) : errorState.getError ? (
              <span
                className={
                  "error-message has-error get-error-message pull-right"
                }
                data-testid="get-error-message"
              >
                <i className="fa fa-exclamation-triangle" />
                {session.isAdvisorOrEmployer
                  ? getErrorMessageForNonMembers
                  : getErrorMessageForMembers}
              </span>
            ) : (
              <button
                className="btn btn-default pull-right"
                data-testid="update-payment-info"
                onClick={toggleEditEnabled}
                disabled={session.isAdvisorOrEmployer}
              >
                Update payment information
              </button>
            )}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Bank Name</td>
          <td>
            <ConditionalDisplayInput
              editable={editEnabled}
              displayValue={bankName || "Not on file"}
              displayId="bankName"
            >
              <ErrorWrapper
                showError={errorState.achError}
                isValid={Boolean(formData.bankName)}
                errorMessage={fieldNotSetErrorText}
              >
                <input
                  className={"form-control"}
                  data-testid="bankNameInput"
                  name="bankName"
                  type="text"
                  value={formData.bankName}
                  disabled={isSubmitted || errorState.postError}
                  onChange={handleInputChange}
                  onFocus={selectEntireField}
                  size={25}
                />
              </ErrorWrapper>
            </ConditionalDisplayInput>
          </td>
        </tr>
        <tr>
          <td>Account Type</td>
          <td>
            <ConditionalDisplayInput
              editable={editEnabled}
              displayValue={bankAccountType || "Not on file"}
              displayId="bankAccountType"
            >
              <ErrorWrapper
                showError={errorState.achError}
                isValid={
                  Boolean(formData.bankAccountType) &&
                  formData.bankAccountType !== selectAccountTypeText
                }
                errorMessage={fieldNotSetErrorText}
              >
                <select
                  className={"form-control"}
                  data-testid="bankAccountTypeInput"
                  name="bankAccountType"
                  onChange={handleSelectChange}
                  disabled={isSubmitted || errorState.postError}
                  defaultValue={formData.bankAccountType}
                >
                  <option value={selectAccountTypeText}>
                    {selectAccountTypeText}
                  </option>
                  {props.bankAccountTypes.map(({ value: accountType }) => (
                    <option
                      data-testid={`${kebabCase(accountType)}-option`}
                      key={accountType}
                      value={accountType}
                    >
                      {accountType}
                    </option>
                  ))}
                </select>
              </ErrorWrapper>
            </ConditionalDisplayInput>
          </td>
        </tr>
        <tr>
          <td>Account Name</td>
          <td>
            <ConditionalDisplayInput
              editable={editEnabled}
              displayValue={bankAccountName || "Not on file"}
              displayId="bankAccountName"
            >
              <ErrorWrapper
                showError={errorState.achError}
                isValid={Boolean(formData.bankAccountName)}
                errorMessage={fieldNotSetErrorText}
              >
                <input
                  className={"form-control"}
                  data-testid="bankAccountNameInput"
                  name="bankAccountName"
                  type="text"
                  value={formData.bankAccountName}
                  onChange={handleInputChange}
                  disabled={isSubmitted || errorState.postError}
                  onFocus={selectEntireField}
                  size={25}
                  data-hj-suppress
                />
              </ErrorWrapper>
            </ConditionalDisplayInput>
          </td>
        </tr>
        <tr>
          <td>Account Number</td>
          <td>
            <ConditionalDisplayInput
              editable={editEnabled}
              displayValue={bankAccountNumber || "Not on file"}
              displayId="bankAccountNumber"
            >
              <ErrorWrapper
                showError={errorState.achError}
                isValid={Boolean(formData.bankAccountNumber)}
                errorMessage={fieldNotSetErrorText}
              >
                <input
                  className={"form-control"}
                  data-testid="bankAccountNumberInput"
                  name="bankAccountNumber"
                  type="text"
                  value={formData.bankAccountNumber}
                  onChange={handleInputChange}
                  disabled={isSubmitted || errorState.postError}
                  onFocus={selectEntireField}
                  size={25}
                  data-hj-suppress
                />
              </ErrorWrapper>
            </ConditionalDisplayInput>
          </td>
        </tr>
        {!props.ACH && (
          <tr>
            <td>For Further Credit</td>
            <td>
              <ConditionalDisplayInput
                editable={editEnabled}
                displayValue={
                  paymentInformation.forFurtherCreditFFC || "Not on file"
                }
                displayId="forFurtherCreditFFC"
              >
                <ErrorWrapper
                  showError={errorState.achError}
                  isValid={Boolean(formData.forFurtherCreditFFC)}
                  errorMessage={forFurtherCreditErrorText}
                >
                  <input
                    className={"form-control"}
                    data-testid="forFurtherCreditFFCInput"
                    name="forFurtherCreditFFC"
                    type="text"
                    value={formData.forFurtherCreditFFC}
                    onChange={handleInputChange}
                    disabled={isSubmitted || errorState.postError}
                    onFocus={selectEntireField}
                    size={25}
                    maxLength={150}
                    data-hj-suppress
                  />
                </ErrorWrapper>
              </ConditionalDisplayInput>
            </td>
          </tr>
        )}
        <tr>
          <td>Bank Routing Number</td>
          <td>
            <ConditionalDisplayInput
              editable={editEnabled}
              displayValue={bankRoutingNumber || "Not on file"}
              displayId="bankRoutingNumber"
            >
              <ErrorWrapper
                showError={errorState.achError}
                isValid={
                  Boolean(formData.bankRoutingNumber) &&
                  ABARoutingNumberIsValid(formData.bankRoutingNumber)
                }
                errorMessage={
                  Boolean(formData.bankRoutingNumber)
                    ? routingNumberErrorText
                    : fieldNotSetErrorText
                }
              >
                <input
                  className={"form-control"}
                  data-testid="bankRoutingNumberInput"
                  name="bankRoutingNumber"
                  type="text"
                  value={formData.bankRoutingNumber}
                  onChange={handleInputChange}
                  disabled={isSubmitted || errorState.postError}
                  onFocus={selectEntireField}
                  size={25}
                  data-hj-suppress
                />
              </ErrorWrapper>
            </ConditionalDisplayInput>
          </td>
        </tr>
        <tr>
          <td>Bank State</td>
          <td>
            <ConditionalDisplayInput
              editable={editEnabled}
              displayValue={bankState || "Not on file"}
              displayId="bankState"
            >
              <ErrorWrapper
                showError={errorState.achError}
                isValid={
                  Boolean(formData.bankState) &&
                  formData.bankState !== selectAStateText
                }
                errorMessage={fieldNotSetErrorText}
              >
                <select
                  className={"form-control"}
                  data-testid="bankStateInput"
                  name="bankState"
                  onChange={handleSelectChange}
                  disabled={isSubmitted || errorState.postError}
                  defaultValue={initialFormState.bankState}
                >
                  <option value={selectAStateText} disabled={true}>
                    Select a State
                  </option>
                  {props.bankStates.map(({ value: stateName }) => (
                    <option
                      data-testid={`${kebabCase(stateName)}-option`}
                      key={stateName}
                      value={stateName}
                    >
                      {stateName}
                    </option>
                  ))}
                </select>
              </ErrorWrapper>
            </ConditionalDisplayInput>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default PaymentInfoTable;
