import React from "react";
import { ContributionSummary } from "../../../../models/contributions";
import { InvestmentV2 } from "../../../../models/investment-v2";
import { selectedAccountsSelector } from "../../../../selectors/accounts";
import { selectedInvestmentsSelector } from "../../../../selectors/investments";
import { useSelector } from "../../../../store/store";
import {
  aggregateByAccountId,
  flatten,
} from "../../../../util/investment-context-util";
import ContributionAmountsTable from "./contribution-amounts-table";
import {
  ContributionAmountsDisplay,
  createContributionAmountsDisplayByAccount,
  createContributionAmountsDisplayByMoneyType,
} from "./contribution-amounts-util";
import { ContributionAmountsInfo } from "./contributions-amounts-info";
import { Account } from "../../../../models/account";

type Props = {
  isByAccount: boolean;
  hideZeroValues: boolean;
};

export function filterContributionSummaries(
  contributionSummaries: ContributionSummary[],
  hideZeroValues: boolean
): ContributionSummary[] {
  return contributionSummaries.filter((contribution) => {
    return (
      !hideZeroValues ||
      (contribution.currentBalance && contribution.currentBalance > 0) ||
      (contribution.contributionsSinceInception &&
        contribution.contributionsSinceInception > 0)
    );
  });
}

export default function ContributionAmounts(props: Props) {
  const { isByAccount, hideZeroValues } = props;
  const investments: InvestmentV2[] = useSelector(selectedInvestmentsSelector);
  const contributionSummaries: ContributionSummary[] = useSelector(
    (state) => state.contributions.contributionSummary.data
  );
  const accounts = useSelector(selectedAccountsSelector);
  const investmentsByAccountID: InvestmentV2[] = flatten(
    aggregateByAccountId(investments)
  );

  const contributionAmountsDisplay: ContributionAmountsDisplay[] =
    getContributionAmountsToDisplay(
      isByAccount,
      hideZeroValues,
      filterContributionSummaries(contributionSummaries, hideZeroValues),
      investmentsByAccountID,
      accounts
    );

  return (
    <div className="contribution-amounts">
      <ContributionAmountsInfo />
      <ContributionAmountsTable
        data={contributionAmountsDisplay}
        isByAccount={isByAccount}
      />
    </div>
  );
}

function getContributionAmountsToDisplay(
  isByAccount: boolean,
  hideZeroValues: boolean,
  contributionSummaries: ContributionSummary[],
  investmentsByAccount: InvestmentV2[],
  accounts: Account[]
): ContributionAmountsDisplay[] {
  if (isByAccount) {
    return createContributionAmountsDisplayByAccount(
      contributionSummaries,
      accounts
    );
  }

  return createContributionAmountsDisplayByMoneyType(contributionSummaries);
}
