import React from "react";
import { useExpanded, useGroupBy, useSortBy, useTable } from "react-table";
import "./table-v2.scss";
import Icon from "../../components/icon/icon";

SortableTable.defaultProps = {
  hasFooter: true,
};

const handleKeyDown = (e) => {
  if (e.key === "Enter" || e.key === " ") {
    e.preventDefault();
    e.currentTarget.click();
  }
};

function SortableTable(props) {
  const { columns, data, initialState, tableName, hasFooter } = props;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState,
    },
    useGroupBy,
    useSortBy,
    useExpanded
  );

  return (
    <div
      className={`custom-table-wrapper ${props.className}`}
      data-testid={tableName}
    >
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...{
                    ...column.getHeaderProps(
                      column.getSortByToggleProps({ title: "" })
                    ),
                    className: column.className,
                  }}
                >
                  <div className="sort-group" role="presentation">
                    {column.render("Header")}
                    <span className="sort-icon">
                      <SortHeader column={column} />
                    </span>
                  </div>
                  {column.canGroupBy ? (
                    <span {...column.getGroupByToggleProps()}>
                      {column.isGrouped ? (
                        <button class={"btn btn-sm btn-primary"}>
                          View individual distributions
                        </button>
                      ) : (
                        <button class={"btn btn-sm btn-primary"}>
                          Group by account
                        </button>
                      )}
                    </span>
                  ) : null}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} data-testid="table-body">
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  if (cell.column.isColorCell) {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className="color-cell"
                        style={{ backgroundColor: `#${cell.value}` }}
                      >
                        &nbsp;
                      </td>
                    );
                  }
                  return (
                    <td
                      {...cell.getCellProps()}
                      className={cell.column.className}
                    >
                      {cell.isGrouped ? (
                        <>
                          <span {...row.getToggleRowExpandedProps()}>
                            {row.isExpanded ? (
                              <i
                                className="fa fa-caret-down"
                                aria-hidden="true"
                              ></i>
                            ) : (
                              <i
                                className="fa fa-caret-right"
                                aria-hidden="true"
                              ></i>
                            )}
                          </span>{" "}
                          {cell.render("Cell")} ({row.subRows.length})
                        </>
                      ) : cell.isAggregated ? (
                        cell.render("Aggregated")
                      ) : cell.isPlaceholder ? null : (
                        cell.render("Cell")
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
        {hasFooter && (
          <tfoot>
            {footerGroups.map((group) => (
              <tr {...group.getFooterGroupProps()}>
                {group.headers.map((column) => (
                  <td
                    {...column.getFooterProps([
                      { className: column.className },
                    ])}
                  >
                    {column.render("Footer")}
                  </td>
                ))}
              </tr>
            ))}
          </tfoot>
        )}
      </table>
    </div>
  );
}

function SortHeader(props) {
  const { column } = props;
  const { canSort, isSorted, isSortedDesc } = column;
  if (!canSort) {
    return <></>;
  }

  if (!canSort) {
    return <></>;
  }
  let iconClass, srText;
  if (isSortedDesc) {
    iconClass = "sort-desc";
    srText = "sort column ascending";
  }
  if (!isSortedDesc) {
    iconClass = "sort-asc";
    srText = "sort column descending";
  }
  if (!isSorted) {
    iconClass = "sort";
    srText = "sort column";
  }
  return (
    <span role="button" tabIndex={0} onKeyDown={handleKeyDown}>
      <Icon name={iconClass} />
      <span className="sr-only">{srText}</span>
    </span>
  );
}

export default SortableTable;
