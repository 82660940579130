import React from "react";
import { Page, PageHeader, PageSubheader } from "../../components/page";

export function InvestmentAdvisor() {
  return (
    <Page name="investment-advisor" loading={false}>
      <PageHeader name="investment-advisor">
        Investment Advisor & Investment Option
      </PageHeader>
      <PageSubheader>Investment Advisor</PageSubheader>
      <p>
        The investment advisor is the organization employed by a mutual fund or
        Separate Account to advise in the investment, supervision and management
        of the assets held in the mutual fund or Separate Account.
      </p>
      <p>
        At any time, the measure of your benefits is determined by reference to
        the performance of available investments as described in your Plan
        materials ("Reference Investments"). Throughout this site you will see
        terms such as Investment Option or Investment Advisor. These are
        indicators of the Reference Investments made available to measure your
        benefit(s) in your Nonqualified Plan.
      </p>
    </Page>
  );
}
