import { html, nothing } from 'lit';
import {
  property,
  query,
  queryAssignedElements,
  state,
} from 'lit/decorators.js';
import {
  computePosition,
  autoUpdate,
  shift,
  limitShift,
  arrow,
  offset,
  size,
  Placement,
} from '@floating-ui/dom';
import * as focusTrap from 'focus-trap'; // ESM
import { themedefault } from '@principal/design-system-tokens';
import { pdsCustomElement as customElement } from '../../decorators/pds-custom-element';
import { PdsElement } from '../PdsElement';
import styles from './popover.scss?inline';
import { PdsLink } from '../link/link';

/**
 * @summary This component provides a popover when a user clicks on the trigger element
 *
 * @slot default Required: Provides the inner contents of the popover
 * @slot trigger Required: Contains the element from which the popover is triggered
 * @slot cta Optional: Provides the CTA content of the popover
 *
 * @fires pds-popover-open An event dispatched when popover is opened
 * @fires pds-popover-close An event dispatched when popover is closed
 */
@customElement('pds-popover', {
  category: 'component',
  type: 'component',
  styles,
})
export class PdsPopover extends PdsElement {
  /**
   * Style variant
   * - **dark** renders the standard variant, with dark-colored popovers.
   * - **light** renders the light variant, with light-colored popovers.
   */
  @property({ type: String })
  variant: 'dark' | 'light' = 'dark';

  /**
   * Position for popover
   * - **default** renders the popover at top.
   * - **right** renders the popover at right.
   * - **bottom** renders the popover at bottom.
   * - **left** renders the popover at left.
   */
  @property({ type: String })
  placement: 'default' | 'right' | 'bottom' | 'left' = 'default';

  /**
   * Optional prop to add a title to the popover content
   */
  @property({ type: String })
  popoverTitle: string;

  /**
   * Tracks whether or not the popover is actively open
   * @internal
   */
  @state()
  isActive: boolean = false;

  /**
   * Optional prop to prevent the popover to close on clicking outside of popover content
   */
  @property({ type: Boolean })
  persistent: boolean = false;

  /**
   * This grabs the entire popover component, trigger included
   * @internal
   */
  @query('.pds-c-popover')
  popoverComponent: HTMLDivElement;

  /**
   * This grabs the div element of the popover container
   * @internal
   */
  @query('.pds-c-popover__popover-content')
  popover: any;

  /**
   * This grabs the div element of the popover arrow
   * @internal
   */
  @query('.pds-c-popover__arrow')
  popoverArrow: HTMLElement;

  /**
   * This grabs the dialog element of the popover
   * @internal
   */
  @query('.pds-c-popover[role="dialog"]')
  popoverDialog: HTMLElement;

  /**
   * This grabs the popover trigger element
   * @internal
   */
  @queryAssignedElements({ slot: 'trigger' })
  trigger: HTMLElement[];

  /**
   * Checks to see if the Popover has valid markup
   * @internal
   */
  @state()
  isValidPopover: boolean = true;

  /**
   * @internal
   */
  @state()
  trap: any;

  /**
   * @internal
   * Initialize the focus trap
   */
  initializeTrap() {
    this.trap = focusTrap.createFocusTrap(this.popoverComponent, {
      initialFocus: this.popover,
      fallbackFocus: this.trigger[0],
      allowOutsideClick: true,
      tabbableOptions: {
        getShadowRoot: true,
      },
    });
  }

  /**
   * Attach functions
   */
  connectedCallback() {
    super.connectedCallback();
    this.initLocalization();
    this.handleOnClickOutside = this.handleOnClickOutside.bind(this);
    document.addEventListener('mouseup', this.handleOnClickOutside, false);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    document.removeEventListener('mouseup', this.handleOnClickOutside, false);
  }

  /**
   * Handle click outside the popover
   * @internal
   */
  handleOnClickOutside(e: MouseEvent) {
    if (!this.persistent) {
      // If the popover is already closed then we don't care about outside clicks and we
      // can bail early
      if (!this.isActive) {
        return;
      }

      // If clicking the trigger element again, bail here and let the toggle function take over
      if (this.trigger[0] && e.composedPath().includes(this.trigger[0])) {
        return;
      }

      let didClickInside = false;

      // Check to see if we clicked inside the popover
      if (this.popover) {
        didClickInside = e.composedPath().includes(this.popover);
      }

      // If the popover is active and we've clicked outside of the popover then it should be closed.
      if (this.isActive && !didClickInside) {
        this.hide();
      }
    }
  }

  adjustPopoverPlacement() {
    let position;
    if (typeof window !== 'undefined') {
      // if at small screen size, the popover is placed at the bottom
      if (window.innerWidth < themedefault.BreakpointsPixelSm) {
        position = 'bottom';
      } else {
        position = this.placement === 'default' ? 'top' : this.placement;
      }
    }
    return position as Placement;
  }

  createInstance() {
    const button = this.trigger[0];
    if (button) {
      const arrowLen = this.popoverArrow.offsetWidth;
      const popoverEl = this.popover;
      let maxWidthVal: number;
      let maxHeightVal: number;
      autoUpdate(button, this.popover, () => {
        // ComputePosition take two arguments first is reference element and second is floating element and returns a Promise that resolves with the coordinates that can be used to apply styles to the floating element
        // Middleware allows to customise behavior of positioning
        // Offset is used to adjust the distance between reference and floating element
        // Shift prevents floating element from overflowing along with it's axis
        // Placement is used to define direction of popover
        computePosition(button, this.popover, {
          placement: this.adjustPopoverPlacement(),
          middleware: [
            offset(14),
            shift({
              limiter: limitShift({
                // Start limiting 15px earlier
                offset: 15,
              }),
            }),
            arrow({ element: this.popoverArrow }),
            size({
              apply({ availableWidth, availableHeight }) {
                // Popovers should have a max-width of 320px and a max-height of 480px if the amount of avaliable width is larger than 320px.
                if (availableWidth > 320) {
                  maxWidthVal = 320;
                  maxHeightVal = 480;
                } else {
                  // styles for mobile
                  maxWidthVal = availableWidth;
                  maxHeightVal = availableHeight;
                }
                Object.assign(popoverEl.style, {
                  maxWidth: `${maxWidthVal}px`,
                  maxHeight: `${maxHeightVal}px`,
                });
              },
            }),
          ],
        }).then(({ x, y, middlewareData }) => {
          Object.assign(this.popover.style, {
            // left and top ensures the position of popover
            left: `${x}px`,
            top: `${y}px`,
          });
          // to provide inline-styling to popover arrow we need to find the static side according to placement of popover
          let staticSide: string;
          switch (this.adjustPopoverPlacement()) {
            case 'top':
              staticSide = 'bottom';
              break;
            case 'right':
              staticSide = 'left';
              break;
            case 'bottom':
              staticSide = 'top';
              break;
            case 'left':
              staticSide = 'right';
              break;
            default:
              staticSide = 'bottom';
          }

          const borderName: any = `border${
            staticSide.charAt(0).toUpperCase() + staticSide.slice(1)
          }Width`;

          let borderWidth: any = parseFloat(
            getComputedStyle(this.popoverArrow)[borderName].slice(0, -2),
          );

          if (
            this.variant === 'light' &&
            (this.adjustPopoverPlacement() === 'bottom' ||
              this.adjustPopoverPlacement() === 'left')
          ) {
            borderWidth -= 0.2;
          }

          if (middlewareData.arrow) {
            const { x: arrowX, y: arrowY } = middlewareData.arrow;
            Object.assign(this.popoverArrow.style, {
              // left, top and static side would ensure the position of popover arrow
              left: arrowX != null ? `${arrowX}px` : '',
              top: arrowY != null ? `${arrowY}px` : '',
              right: '',
              bottom: '',
              [staticSide]:
                this.variant === 'light'
                  ? `${-arrowLen / 2 - borderWidth}px`
                  : `${-arrowLen / 2}px`,
            });
          }
        });
      });
    }
  }

  show() {
    this.initializeTrap();
    this.trap.activate();
    this.isActive = true;
    if (this.trigger[0].tagName.toLowerCase().includes('pds-button')) {
      this.trigger[0].setAttribute('isActive', 'true');
    }
    this.popover.setAttribute('data-show', '');
    this.trigger[0].setAttribute('aria-expanded', 'true');
    this.createInstance();

    const openEvent = new Event('pds-popover-open', {
      bubbles: true,
      composed: true,
    });

    this.dispatchEvent(openEvent);
  }

  hide() {
    this.trap.deactivate();
    this.isActive = false;
    this.trigger[0].removeAttribute('isActive');
    this.popover.removeAttribute('data-show');
    this.trigger[0].setAttribute('aria-expanded', 'false');

    const closeEvent = new Event('pds-popover-close', {
      bubbles: true,
      composed: true,
    });

    this.dispatchEvent(closeEvent);
  }

  /**
   * @internal
   */
  adjustPopoverState() {
    const trigger = this.trigger[0];
    trigger.addEventListener('keydown', (e) => {
      if (e.key === 'Escape' || e.key === 'Esc') {
        this.hide();
      }

      if (e.code === 'Space' || e.key === 'Enter' || e.code === 'Enter') {
        e.preventDefault();
        if (this.isActive) {
          this.hide();
        } else {
          this.show();
        }
      }
    });

    trigger.addEventListener('click', () => {
      this.handleToggle();
    });
  }

  /**
   * @internal
   */
  handleToggle() {
    if (this.isActive) {
      this.hide();
    } else {
      this.show();
    }
  }

  /**
   * @internal
   */
  popoverTitleTemplate() {
    if (this.popoverTitle) {
      return html` <div class="${this.classEl('title')}" id="popover-title">
        ${this.popoverTitle}
      </div>`;
    }
    return nothing;
  }

  /**
   * @internal
   *
   * Checks markup for errors and adds browser console errors for misconfigurations.
   * Links inside a CTA slot can be emphasis or not, but links in the default
   * slot/main content area should always use the underlined variety for a11y.
   * Dark popovers should always use inverted links, light popovers should use default links.
   */
  adjustLinkVariants() {
    const allLinks = Array.from(this.querySelectorAll('pds-link'));
    const ctaSlot = this.querySelector('[slot="cta"]');

    if (ctaSlot) {
      // This check ensures that it can grab links in the CTA slot whether `slot="cta"` is placed on the
      // pds-link/button directly, or if it's on a wrapping div containing the CTAs.
      const ctaLinks = Array.from(
        ctaSlot.nodeName === 'PDS-LINK' || ctaSlot.nodeName === 'PDS-BUTTON'
          ? ctaSlot.parentElement?.querySelectorAll('pds-link') || []
          : ctaSlot.querySelectorAll('pds-link'),
      );

      allLinks.forEach((link: any) => {
        const pdsLink = link as PdsLink;
        const linkAttribute = pdsLink.variant
          ? pdsLink.variant
          : link.getAttribute('variant');
        const isInsideCtaSlot = ctaLinks.includes(link);
        const errorStart = `Invalid link variant: '${linkAttribute}'.  Links in a `;
        const errorEnd = `Please update this link with the correct variant to remove this warning.`;

        if (this.variant === 'light') {
          if (
            isInsideCtaSlot &&
            linkAttribute !== 'default' &&
            linkAttribute !== 'emphasis'
          ) {
            console.error(
              `${errorStart}light colored popover must use the 'default' variant with darker colored text. ${errorEnd}`,
              link,
            );
            this.isValidPopover = false;
          } else if (!isInsideCtaSlot && linkAttribute !== 'emphasis') {
            console.error(
              `${errorStart}light colored popover must use the 'emphasis' variant. ${errorEnd}`,
              link,
            );
            this.isValidPopover = false;
          }
        } else if (this.variant === 'dark') {
          if (
            isInsideCtaSlot &&
            linkAttribute !== 'inverted' &&
            linkAttribute !== 'emphasis-inverted'
          ) {
            console.error(
              `${errorStart}dark colored popover must use the 'inverted' variant with darker colored text. ${errorEnd}`,
              link,
            );
            this.isValidPopover = false;
          } else if (
            !isInsideCtaSlot &&
            linkAttribute !== 'emphasis-inverted'
          ) {
            console.error(
              `${errorStart}dark colored popover must use the 'emphasis-inverted' variant. ${errorEnd}`,
              link,
            );
            this.isValidPopover = false;
          }
        }
      });
    }
  }

  /**
   * closes the popover on escape
   * @internal
   */
  handleKeydown(e: KeyboardEvent) {
    if (e.key === 'Escape' || e.key === 'Esc') {
      this.hide();
      if (this.trigger[0].tagName.toLowerCase().includes('pds-button')) {
        this.trigger[0].shadowRoot?.querySelector('button')?.focus();
      }
    }
  }

  protected override firstUpdated() {
    super.firstUpdated();
    this.adjustPopoverState();
    this.adjustLinkVariants();
    if (this.popoverTitle) {
      this.popoverDialog.setAttribute('aria-labelledby', 'popover-title');
    } else {
      this.popoverDialog.setAttribute(
        'aria-label',
        `${this.translateText('additional-information')}`,
      );
    }
    this.popoverDialog.setAttribute('aria-describedby', 'popover-content');
  }

  /**
   * @internal
   */
  get classNames() {
    return {
      [this.variant]: !!this.variant,
    };
  }

  render() {
    if (!this.isValidPopover) {
      return nothing;
    }

    return html`<div class=${this.getClass()} role="dialog">
      <slot name="trigger" class=${this.classEl('trigger')}></slot>
      <div id="popover-content" class="${this.classEl('popover-content')}">
        <div
          class="${this.classEl('popover-content-area')}"
          @keydown=${this.handleKeydown}
        >
          ${this.popoverTitleTemplate()}
          <slot
            allowed-elements="pds-list, pds-link, pds-text-passage, pds-icon, pds-hr, p, div, br, span"
            @slotchange="${this.handleSlotValidation}"
          ></slot>
          <div class="${this.classEl('cta')}">
            <slot name="cta"></slot>
          </div>
        </div>
        <div
          class="${this.classEl('arrow')} ${this.classEl(
            'arrow',
          )}--${this.adjustPopoverPlacement()}"
        ></div>
      </div>
    </div> `;
  }
}
