import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getForecastDistributionsReport } from "../../services/report";
import { AsyncStatus } from "../../models/async-status";

export const fetchDistributionForecastingReport = createAsyncThunk(
  "fetch-distribution-forecasting-report",
  async (
    {
      planId,
      empId,
      role,
      eventName,
      forecastDate,
      rateOfReturn,
    }: {
      planId: number;
      empId: number;
      role: string;
      eventName: string;
      forecastDate: string;
      rateOfReturn: number;
    },
    thunkApi
  ) => {
    return getForecastDistributionsReport(
      planId,
      empId,
      role,
      eventName,
      forecastDate,
      rateOfReturn
    );
  }
);

type State = {
  status: AsyncStatus;
};

const initialState: State = {
  status: AsyncStatus.not_fetched,
};

const distributionForecastingReportSlice = createSlice({
  name: "distribution-forecasting-report",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchDistributionForecastingReport.pending,
      (state, action) => {
        return { ...state, status: AsyncStatus.loading };
      }
    );
    builder.addCase(
      fetchDistributionForecastingReport.fulfilled,
      (state, action) => {
        return { ...state, ...action.payload, status: AsyncStatus.done };
      }
    );
    builder.addCase(
      fetchDistributionForecastingReport.rejected,
      (state, action) => {
        return {
          ...state,
          status: AsyncStatus.error,
        };
      }
    );
    builder.addDefaultCase((state, action) => state);
  },
});

export default distributionForecastingReportSlice.reducer;
