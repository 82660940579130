import React, { useState } from "react";
import Button from "react-bootstrap/cjs/Button";
import BSModal from "react-bootstrap/Modal";
import "./pending-deferral-elections.scss";
import { formatDateWithoutTimeZone } from "../../../../util/date-util";
import { useAppDispatch, useSelector } from "../../../../store/store";
import PendingDeferralElectionTable from "./pending-deferral-election-table";
import { removeContributionRates } from "../../../../reducers/contributions/contribution-rates";
import {
  ContributionRates,
  PendingContributionRate,
} from "../../../../models/contributions";
import {
  createDeferralElectionDisplay,
  createPendingDeferralElectionDisplay,
  DeferralElectionsDisplay,
  PendingDeferralElectionsDisplay,
} from "./deferal-election-util";

type beneModalProps = {
  showDeferralModal: boolean;
  hideDeferralModal: any;
  showDeferralSuccessModal: any;
};

export default function PendingDeferralElections(props: beneModalProps) {
  const dispatch = useAppDispatch();
  const [checkBoxChecked, setCheckBoxState] = useState(false);
  const { planId, empId, role } = useSelector((state) => state.session);
  const contributionRates: ContributionRates[] = useSelector(
    (state) => state.contributions.contributionRates.data
  );
  const pendingContributionRates: PendingContributionRate[] = useSelector(
    (state) =>
      state.contributions.pendingContributions.data.map((item, index) => ({
        ...item,
        contributionElectionType:
          contributionRates[index] &&
          contributionRates[index].contributionElectionType,
      }))
  );
  const deferralElectionDisplay: DeferralElectionsDisplay[] =
    createDeferralElectionDisplay(contributionRates);
  const pendingDeferralElectionDisplay: PendingDeferralElectionsDisplay[] =
    createPendingDeferralElectionDisplay(pendingContributionRates);
  const commonElectionDisplay = deferralElectionDisplay.map((item, index) => ({
    contributionTypeName: item.contributionTypeName,
    currentDeferralElection: item.currentDeferralElection,
    pendingDeferralElection: pendingDeferralElectionDisplay[index]
      ? pendingDeferralElectionDisplay[index].pendingDeferralElection
      : "",
  }));

  const dateFormatted = formatDateWithoutTimeZone(
    pendingContributionRates[0].deferralEffectiveDate,
    "MM/dd/yyyy"
  );
  const { showDeferralModal, hideDeferralModal, showDeferralSuccessModal } =
    props;

  const cancelPendingDeferral = () => {
    if (checkBoxChecked) {
      showDeferralSuccessModal();
    }
    dispatch(
      removeContributionRates({
        planId,
        empId,
        role,
      })
    );
  };

  const toggleCheck = () => {
    setCheckBoxState(!checkBoxChecked);
  };
  return (
    <BSModal
      show={showDeferralModal}
      onHide={hideDeferralModal}
      backdropClassName="modal-backdrop"
      animation={false}
      size="lg"
    >
      <BSModal.Header closeButton>
        <BSModal.Title as={"h2"}>Pending deferral elections</BSModal.Title>
      </BSModal.Header>
      <BSModal.Body>
        <span className="effective-date">
          Effective <b>{dateFormatted}</b>
        </span>
        <div className="margin-20">
          <PendingDeferralElectionTable data={commonElectionDisplay} />
        </div>
        <div className="styled-checkbox display-flex">
          <input
            className="custom-checkbox"
            type="checkbox"
            id="toggle-all"
            checked={checkBoxChecked}
            onChange={toggleCheck}
            data-testid="toggle-all"
          />
          <label htmlFor="toggle-all" className="checkbox">
            I confirm the information above and authorize Principal Life
            Insurance Company to process this cancellation.
          </label>
        </div>
      </BSModal.Body>
      <BSModal.Footer>
        <button
          className="btn btn-link"
          type="button"
          onClick={hideDeferralModal}
        >
          Close
        </button>
        <Button onClick={cancelPendingDeferral} disabled={!checkBoxChecked}>
          Cancel pending deferral changes <i />
        </Button>
      </BSModal.Footer>
    </BSModal>
  );
}
