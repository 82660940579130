import React from "react";
import DisclosureLinks from "../../pages/investments/performance/investment-disclosures/disclosure-links";
import { buildHexValueColumn } from "../../pages/investments/performance/performance-table/build-columns-utils";
import {
  buildBalancePercentColumn,
  buildCurrentBalanceColumn,
  buildSharesColumn,
  buildTotalSharesColumn,
} from "../../pages/investments/summary/investment-table/build-investment-column-util";
import {
  accountsStatusSelector,
  datesStatusSelector,
  investmentsStatusSelector,
} from "../../selectors/status";
import InvestmentAdvisorLink from "../investments/investment-advisor-link";
import { Skeleton } from "../skeleton/skeleton";
import "../table/table-v2.scss";
import TableV2 from "../table/tableV2";

InvestmentLoadingTable.defaultProps = {
  hasFooter: true,
};

type Props = {};

function InvestmentLoadingTable(props: Props) {
  const defaultColumns = [
    buildHexValueColumn(),
    buildSkeletonInvestmentOptionColumn(),
    buildCurrentBalanceColumn(),
    buildBalancePercentColumn(),
    buildSkeletonSharePriceColumn(),
    buildTotalSharesColumn(),
    buildSharesColumn(),
  ];

  return (
    <div className={`custom-table-wrapper`}>
      <TableV2 columns={defaultColumns} data={[{}, {}, {}, {}, {}]} />
    </div>
  );
}

function buildSkeletonInvestmentOptionColumn() {
  return {
    Header: <InvestmentAdvisorLink />,
    accessor: "investmentOption",
    className: "left",
    Cell: () => (
      <>
        <em>
          <Skeleton
            selectors={[
              investmentsStatusSelector,
              accountsStatusSelector,
              datesStatusSelector,
            ]}
            height={18}
            style={{ margin: "3px 0" }}
          >
            &nbsp;
          </Skeleton>
        </em>
        <br />
        <Skeleton
          selectors={[
            investmentsStatusSelector,
            accountsStatusSelector,
            datesStatusSelector,
          ]}
          height={18}
          style={{ margin: "3px 0" }}
        >
          &nbsp;
        </Skeleton>
      </>
    ),
    Footer: <div className="bold">Total</div>,
  };
}

export function buildSkeletonSharePriceColumn() {
  const Header = () => (
    <span className="number no-wrap header">
      Share Price/
      <br />
      Unit Value
      <br />
      <DisclosureLinks disclosureIds={[1]} /> ($)
    </span>
  );

  return {
    Header: Header,
    accessor: "sharePrice",
    className: "right",
    Cell: () => (
      <>
        <Skeleton
          selectors={[
            investmentsStatusSelector,
            accountsStatusSelector,
            datesStatusSelector,
          ]}
          height={18}
          width={60}
          style={{ margin: "3px 0" }}
        >
          &nbsp;
        </Skeleton>
      </>
    ),
  };
}

export default InvestmentLoadingTable;
