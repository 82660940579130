import React from "react";
import Balance from "../../../components/numbers/balance";
import { accountsWithUnscheduledDistributionsSelectorNotFiltered } from "../../../selectors/account-distributions";
import { useInvestments } from "../../../selectors/investments";
import { useSelector } from "../../../store/store";
import {
  getDistributionTotalCurrentBalance,
  getDistributionTotalVestedBalance,
} from "../../../util/distributions-util";

export function AccountDistributionBalanceTotals() {
  const accounts = useSelector(
    accountsWithUnscheduledDistributionsSelectorNotFiltered
  );
  const { filteredInvestments } = useInvestments();
  const multipleAccountsSelected = accounts.length > 1;
  if (!multipleAccountsSelected) {
    return null;
  }

  const totalBalance: number =
    getDistributionTotalCurrentBalance(filteredInvestments);

  const totalVestedBalance: number =
    getDistributionTotalVestedBalance(filteredInvestments);

  return (
    <div className={"balance-displays"}>
      <div>
        <p>
          <strong>Total Current Balance</strong>
        </p>
        <Balance value={totalBalance} showDollar />
      </div>
      <div>
        <p>
          <strong>Total Vested Balance</strong>
        </p>
        <Balance value={totalVestedBalance} showDollar />
      </div>
    </div>
  );
}
