import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  getContingentBeneficiaries,
  getPlanIds,
  getPrimaryBeneficiaries,
  getSelectedPlans,
  participantHasMultipleOnlinePlans,
} from "../../../../components/beneficiary/beneficiary-util";
import { Page, PageHeader } from "../../../../components/page";
import { AlertLevel } from "../../../../models/alert";
import { AsyncStatus } from "../../../../models/async-status";
import { OnlineBeneficiaryPlan } from "../../../../models/beneficiaries/beneficiary";
import {
  completeEdit,
  resetBeneficiaryUpdate,
  resetPostStatus,
  saveBeneficiaries,
} from "../../../../reducers/beneficiary-reducer";
import {
  beneficiariesPostStatus,
  beneficiariesStatus,
  useStatuses,
} from "../../../../selectors/status";
import { useAppDispatch, useSelector } from "../../../../store/store";
import Alert from "../../../errors/alert";
import BeneficiariesReviewDisplayList from "./beneficiaries-review-display-list";
import "./beneficiary-review.scss";
import { BeneficiaryConfirmationText } from "../../../../models/beneficiaries/beneficiary-text";
import { BeneficiaryConfirmationModal } from "./confirmation-modal";
import { PlanList } from "./review-plan-list";
import {
  hideContingentBeneficiariesSelector,
  hidePrimaryBeneficiariesSelector,
} from "../../../../selectors/plan-info";

export default function BeneficiaryReview() {
  const dispatch = useAppDispatch();
  const { planId, empId, role, isAdvisorOrEmployer } = useSelector(
    (state) => state.session
  );
  const impersonated = localStorage.getItem("impersonated") === "true";
  const { isLoadingStatus } = useStatuses([beneficiariesStatus]);
  const { hasError } = useStatuses([beneficiariesPostStatus]);
  const [showModal, setShowModal] = useState(false);
  const { beneficiaries, onlineBeneficiaryPlans, postStatus } = useSelector(
    (state) => state.beneficiaries
  );
  const hidePrimaryBeneficiairies = useSelector(
    hidePrimaryBeneficiariesSelector
  );
  const hideContingentBeneficiairies = useSelector(
    hideContingentBeneficiariesSelector
  );

  const hasMultiplePlans = participantHasMultipleOnlinePlans(
    planId,
    onlineBeneficiaryPlans
  );
  const plansToSave: OnlineBeneficiaryPlan[] = getSelectedPlans(
    onlineBeneficiaryPlans
  );
  const selectedOtherPlans: boolean = plansToSave.length > 0;

  const hasSaved = postStatus === AsyncStatus.done;
  useEffect(() => {
    setShowModal(hasSaved);
    if (hasSaved) {
      dispatch(resetBeneficiaryUpdate());
    }
  }, [dispatch, hasSaved]);
  const hasBeneficiaries = beneficiaries.length > 0;

  useEffect(() => {
    dispatch(resetPostStatus());
  }, [dispatch]);

  function postBeneficiaries() {
    const planIdsToSave = getPlanIds(plansToSave);
    dispatch(
      saveBeneficiaries({
        planId,
        empId,
        role,
        data: { beneficiaries, plans: planIdsToSave },
      })
    );
    dispatch(completeEdit());
  }

  function BackLink() {
    return hasMultiplePlans ? (
      <Link to={"/overview/beneficiaries/plan-selection"}>
        <i className="fa fa-angle-left" aria-hidden="true" /> Back
      </Link>
    ) : (
      <Link to={"/overview/beneficiaries"}>
        <i className="fa fa-angle-left" aria-hidden="true" /> Back
      </Link>
    );
  }

  return (
    <Page
      printable
      name="beneficiaries"
      loading={isLoadingStatus}
      className="beneficiaries"
    >
      <PageHeader name="beneficiaries">
        Beneficiaries - Review and submit
      </PageHeader>
      <section className={"row"}>
        <div className={"col-md-9"}>
          <h2 className="h3 util-margin-top-40">
            {hasBeneficiaries
              ? "You are requesting to name the following beneficiaries"
              : "You are requesting to name no beneficiaries."}
          </h2>
          {hasBeneficiaries && (
            <>
              <hr className="dotted" role="presentation" />
              <div className={"row"} data-testid="beneficiaries-review-display">
                {!hidePrimaryBeneficiairies && (
                  <div className={"col-md-6 review-text-wrap"}>
                    <BeneficiariesReviewDisplayList
                      header={"Primary"}
                      beneficiaries={getPrimaryBeneficiaries(beneficiaries)}
                    />
                  </div>
                )}
                {!hideContingentBeneficiairies && (
                  <div className={"col-md-6 review-text-wrap"}>
                    <BeneficiariesReviewDisplayList
                      header={"Contingent"}
                      beneficiaries={getContingentBeneficiaries(beneficiaries)}
                    />
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </section>
      <section id="review-plan-submit-list" className={"row"}>
        <div className={"col-md-9"}>
          <hr className="dotted" role="presentation" />
          <PlanList
            plans={plansToSave}
            selectedOtherPlans={selectedOtherPlans}
          />
          <div className="util-margin-vert-30">
            <Alert
              alertLevel={AlertLevel.DANGER}
              showAlert={hasError}
              message={BeneficiaryConfirmationText.SUBMIT_ERROR}
            />
            {showModal && (
              <BeneficiaryConfirmationModal showModal={showModal} />
            )}
            {hasSaved && (
              <div className="alert alert-success alert-icon" role="alert">
                &nbsp;{BeneficiaryConfirmationText.SUBMIT_SUCCESSFUL}
              </div>
            )}
          </div>
        </div>
      </section>
      <section id={"review-navigation"} className={"row util-margin-top-30"}>
        <div className={"col-md-9"}>
          <BackLink />
          {!hasError && !hasSaved && (
            <button
              data-testid="beneficiary-submit"
              className="btn btn-primary pull-right"
              onClick={() => postBeneficiaries()}
              disabled={isAdvisorOrEmployer || impersonated}
            >
              Submit
            </button>
          )}
        </div>
      </section>
    </Page>
  );
}
