import React from "react";

import { ScheduledDistributionEvent } from "../../../../models/distributions/scheduled-distribution-event";
import "../account-distribution-details.scss";
import { useSelector } from "../../../../store/store";

type Props = {
  firstFutureScheduledDistribution: ScheduledDistributionEvent;
};

export default function DistributionContactInfo(props: Props) {
  const { employerContactPhoneNumber, allowDistributionChanges } = useSelector(
    (state) => state.plan.info
  );
  const { firstFutureScheduledDistribution } = props;

  const futureDistributionNotAvailableForRedeferral =
    firstFutureScheduledDistribution === undefined ||
    !firstFutureScheduledDistribution.availableForRedeferral;
  return futureDistributionNotAvailableForRedeferral &&
    allowDistributionChanges ? (
    <p
      className="distribution-contact-info"
      data-testid="distribution-contact-info"
    >
      <span>
        To make a change to your distribution election please contact us
        at&nbsp;
      </span>
      <span>{employerContactPhoneNumber}</span>
    </p>
  ) : (
    <></>
  );
}
