import React from "react";
import { Page, PageHeader } from "../../../components/page";
import { Skeleton } from "../../../components/skeleton/skeleton";
import { InvestmentV2 } from "../../../models/investment-v2";
import { sortedSelectedInvestmentsSelector } from "../../../selectors/investments";
import {
  accountsStatusSelector,
  datesStatusSelector,
  investmentsStatusSelector,
  useStatuses,
} from "../../../selectors/status";
import { useSelector } from "../../../store/store";
import AssetClassChart from "./asset-chart/asset-class-chart";
import Info from "./info";
import InvestmentTable from "./investment-table/investment-table";
import SummaryFilter from "./summary-filter/summary-filter";
import "./summary.scss";

export default function Summary() {
  const { isLoadingStatus } = useStatuses([investmentsStatusSelector]);
  const sortedInvestments = useSelector(sortedSelectedInvestmentsSelector);

  return (
    <Page printable name="investment-summary" loading={false}>
      <PageHeader name="investment-summary">Investment Summary</PageHeader>
      <div className="investment-summary">
        <div className="investment-summary-table">
          <SummaryFilter />
          {renderTableInfo(sortedInvestments, isLoadingStatus)}
        </div>
        <div className="investment-summary-chart">
          <Skeleton
            selectors={[
              investmentsStatusSelector,
              accountsStatusSelector,
              datesStatusSelector,
            ]}
            height={300}
            width={275}
          >
            {renderAssetClassChart(sortedInvestments, isLoadingStatus)}
          </Skeleton>
        </div>
      </div>
      <Info superScripts />
    </Page>
  );
}

function renderTableInfo(
  sortedInvestments: InvestmentV2[],
  isLoadingStatus: boolean
) {
  if (sortedInvestments.length === 0 && !isLoadingStatus) {
    return (
      <p className="no-investment-found">
        No Investments Found From Selected Account(s)
      </p>
    );
  }
  return <InvestmentTable onlyInvestmentsWithActivity />;
}

function renderAssetClassChart(
  sortedInvestments: InvestmentV2[],
  isLoading: boolean
) {
  if (sortedInvestments.length !== 0 && !isLoading) {
    return <AssetClassChart investments={sortedInvestments} headingLevel={2} />;
  }
  return <></>;
}
