import React from "react";
import { useSelector } from "../../../store/store";
import Balance from "../../numbers/balance";
import { getMostRecentDateStr } from "../../../util/date-util";
import { investmentsStatusSelector } from "../../../selectors/status";
import { AccountContributionDetail } from "../dashboard-account-overview/dashboard-account-overview-util";
import { AccountOverviewRows } from "../dashboard-account-overview/AccountOverviewRows";
import { ContributionRoutes } from "../../../routes/routes";
import { ContributionRates } from "../dashboard-contribution-overview/dashboard-contribution-overview";
import { Skeleton } from "../../skeleton/skeleton";
import {
  ContributionHistory,
  ContributionRates as Rates,
} from "../../../models/contributions";
import "./dashboard-contribution-panel.scss";
import { Link } from "react-router-dom";

export function DashboardContributions() {
  const contributionRates = useSelector(
    (state) => state.contributions.contributionRates.data
  );
  const contributionHistory: ContributionHistory[] = useSelector(
    (state) => state.contributions.contributionHistory.data
  );
  const mostRecentContribution: ContributionHistory | undefined =
    filterMostRecentContributionsFromHistory();
  const filteredContributionRates = contributionRates.filter(
    (contributionRate) => !contributionRate.sdainfoUnavailable
  );
  const effectiveDate: string | undefined = getMostRecentEffectiveDate(
    filteredContributionRates
  );
  const showEffectiveDate =
    effectiveDate !== undefined && effectiveDate !== "N/A";

  return (
    <div className="row">
      <div className="col-sm-12">
        <ContributionRates rates={filteredContributionRates} />
        <div className={"effective-date"}>
          <div className={showEffectiveDate ? "show" : "hide"}>
            Effective {effectiveDate}
          </div>
        </div>
        <Skeleton selectors={[investmentsStatusSelector]} height={98}>
          {mostRecentContribution && (
            <AccountContributionDetail
              name={AccountOverviewRows.DEPOSIT}
              type={Balance}
              value={mostRecentContribution.transactionAmount}
              date={mostRecentContribution.transactionDate}
            />
          )}
        </Skeleton>
        <div className="view-contributions">
          <Link to={ContributionRoutes.Summary}>
            View Contributions{" "}
            <i className="fa fa-angle-right" aria-hidden="true" />
          </Link>
        </div>
      </div>
    </div>
  );

  function filterMostRecentContributionsFromHistory():
    | ContributionHistory
    | undefined {
    if (contributionHistory.length > 0) {
      const stringTransactionDates = contributionHistory.map(
        (hist) => hist.transactionDate
      );
      const mostRecentTransactionDate = getMostRecentDateStr(
        stringTransactionDates
      );
      const mostRecentTransactionAmount = contributionHistory
        .filter((cont) => cont.transactionDate === mostRecentTransactionDate)
        .reduce((acc, cur) => {
          return acc + cur.transactionAmount;
        }, 0);
      return {
        transactionDate: mostRecentTransactionDate,
        transactionAmount: mostRecentTransactionAmount,
      } as ContributionHistory;
    }
  }

  function getMostRecentEffectiveDate(
    contrbRates: Rates[]
  ): string | undefined {
    const effectiveDates: string[] = contrbRates.map(
      (rate) => rate.effectiveDate
    );
    const dateStr = getMostRecentDateStr(effectiveDates);
    if (typeof dateStr === "undefined") {
      return dateStr;
    } else {
      return dateStr.replace(/-/gi, "/");
    }
  }
}
