import isEqual from "date-fns/isEqual";
import React from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { FutureDistributionTimelinePayment } from "../../../models/distributions/distribution-payment";
import { formatDateWithoutTimeZone, parseDate } from "../../../util/date-util";
import "./scheduled-distribution-card.scss";
import { DistributionCard } from "./timeline/distribution-card";

type Props = {
  selectedDate?: Date;
  scheduledDistributions: FutureDistributionTimelinePayment[];
  showMoreDistributions?: boolean;
};

export function ScheduledDistributionCards(props: Props) {
  const { scheduledDistributions, selectedDate, showMoreDistributions } = props;

  if (!selectedDate) {
    return null;
  }

  const filteredDistributionsBySelectedDate = scheduledDistributions.filter(
    (distribution) =>
      isEqual(
        parseDate(distribution.payoutDate).getFullYear(),
        selectedDate.getFullYear()
      )
  );

  const distributions = showMoreDistributions
    ? filteredDistributionsBySelectedDate
    : filteredDistributionsBySelectedDate.slice(0, 6);

  return (
    <TransitionGroup component={null}>
      {distributions.map((distribution, index) => (
        <CSSTransition
          key={`${distribution.accountName}-${formatDateWithoutTimeZone(
            distribution.payoutDate
          )}`}
          timeout={{ enter: 500 + 150 * index, exit: 250 }}
          appear
          mountOnEnter
          unmountOnExit
          exit={false}
          classNames={"scheduled-distribution-card"}
        >
          <DistributionCard
            scheduledDistribution={distribution}
            index={index}
          />
        </CSSTransition>
      ))}
    </TransitionGroup>
  );
}
