import React from "react";
import {TrsStatement, TrsStatementsResource} from "../../../models/trs-statements";
import { buildTrsStatementPdfDownloadUrl } from "../../../services/trs-statements";
import { useSelector } from "../../../store/store";
import { formatDateWithoutTimeZone } from "../../../util/date-util";

export interface Props {
  trsStatements: TrsStatement[];
}

export default function TrsStatementDownloadLink(props: Props) {
  const { trsStatements } = props;
  const { planId, empId, role } = useSelector((state) => state.session);

  const buildTrsStatementPdfHref = (
    planId: number,
    empId: number,
    documentInstanceId: string,
    role: string
  ): string => {
    return buildTrsStatementPdfDownloadUrl(
      planId,
      empId,
      documentInstanceId,
      role
    );
  };

  return (
    <div>
      {trsStatements.map((trsStatement) => (
        <div style={{ marginTop: "4px" }}>
          <a
            target={"_blank"}
            href={buildTrsStatementPdfHref(
              planId,
              empId,
              trsStatement.documentInstanceId,
              role
            )}
          >
            {formatDateWithoutTimeZone(
              trsStatement.reportBegDate,
              "MM/dd/yyyy"
            )}{" "}
            -{" "}
            {formatDateWithoutTimeZone(
              trsStatement.reportEndDate,
              "MM/dd/yyyy"
            )}
          </a>
        </div>
      ))}
    </div>
  );
}
