import React from "react";
import DisclaimersDisplay from "../../../components/disclaimers/disclaimers-display";

export function PlanOverviewDisclaimer() {
  return (
    <>
      <DisclaimersDisplay showOnlyShaw={true} />
      <p data-testid="plan-overview-disclaimer">
        The amounts listed on the website represent a measure of your benefits
        under a nonqualified benefit agreement between you and your plan
        sponsor. It is not a representation that your plan sponsor holds any
        assets for paying these benefits. Vested Balances shown on the website
        represent the portion of your benefit account(s) available for
        distribution, upon a qualifying distribution event.
      </p>
    </>
  );
}

export function InvestmentAllocationDisclaimer() {
  return (
    <>
      <DisclaimersDisplay showOnlyShaw={true} />
      <p data-testid="investment-allocation-disclaimer">
        The amounts listed on the website represent a measure of your benefits
        under a nonqualified benefit agreement between you and your plan
        sponsor. It is not a representation that your plan sponsor holds any
        assets for paying these benefits.
      </p>

      <p>
        At any time, the measure of your benefits is determined by reference to
        the performance of available investments as described in your Plan
        materials ("Reference Investments"). Throughout this site you will see
        terms such as Investment Option or Investment Advisor. These are
        indicators of the Reference Investments made available to measure your
        benefit(s) in your Nonqualified Plan.
      </p>
    </>
  );
}

export function ContributionDisclaimer() {
  return (
    <>
      <DisclaimersDisplay showOnlyShaw={true} />
      <p data-testid="contribution-disclaimer">
        <span className="sr-only">Footnote </span>
        <sup>[1]</sup>
        &nbsp;Contributions to date include only contributions made into the
        account since Principal Life Insurance Company began recordkeeping the
        Plan. The amounts listed on the website represent a measure of your
        benefits under a nonqualified benefit agreement between you and your
        plan sponsor. It is not a representation that your plan sponsor holds
        any assets for paying these benefits.
      </p>
    </>
  );
}
