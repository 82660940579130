import React from "react";
import InvestmentAdvisorLink from "../../../../../components/investments/investment-advisor-link";
import { Skeleton } from "../../../../../components/skeleton/skeleton";
import {
  accountsStatusSelector,
  datesStatusSelector,
  investmentsStatusSelector,
} from "../../../../../selectors/status";
import InvestmentLink from "../../../../../components/investments/investment-link";
import DisclosureLinks from "../../investment-disclosures/disclosure-links";

export function buildCreditingRateColumn() {
  const header = () => (
    <span className="number header">
      Crediting rate
      <span className="show">&nbsp;(%)</span>
    </span>
  );
  return buildUndefinedAsNaColumn(header, "pfioInvestmentData.creditRate");
}

export function buildRateLevelServiceFeeColumn() {
  const header = () => (
    <span className="number header">
      Rate level service fee
      <span className="show">&nbsp;(%)</span>
    </span>
  );
  return buildUndefinedAsNaColumn(header, "pfioInvestmentData.serviceFee");
}

export function buildUndefinedAsNaColumn(Header, accessor) {
  return {
    Header,
    accessor,
    className: "right",
    Cell: (info) => {
      const { value } = info;
      if (!value) {
        return <>N/A</>;
      } else {
        return <>{value}</>;
      }
    },
    Footer: () => {
      return null;
    },
  };
}

export function buildInstitutionNameWithDescColumn() {
  return {
    Header: (
      <span className="header">
        <InvestmentAdvisorLink />
      </span>
    ),
    accessor: "id",
    Cell: ({ row }) => {
      const investmentId = row.original.id;
      const institutionName = row.original.institutionName;
      const fundName = row.original.name;
      const disclosureIds = row.original.disclosureIds?.map((disc) => disc.id);
      const invDesc = row.original.pfioInvestmentData.invDesc;

      return (
        <>
          <span
            className="investment-option"
            data-testid={`fund-name-${investmentId}`}
          >
            <Skeleton
              selectors={[
                investmentsStatusSelector,
                accountsStatusSelector,
                datesStatusSelector,
              ]}
              height={18}
              style={{ margin: "3px 0" }}
            >
              <span
                className="advisor-name show"
                data-testid={`advisor-name-${investmentId}`}
              >
                {institutionName}
              </span>
            </Skeleton>
            <Skeleton
              selectors={[
                investmentsStatusSelector,
                accountsStatusSelector,
                datesStatusSelector,
              ]}
              height={18}
              style={{ margin: "3px 0" }}
            >
              <InvestmentLink
                id={investmentId}
                fundName={fundName}
                className="investment-name"
              />
            </Skeleton>
            <DisclosureLinks disclosureIds={disclosureIds} />
          </span>
          <p>{invDesc}</p>
        </>
      );
    },
    style: {
      width: "65%",
    },
  };
}
