import { getDay } from "date-fns";
import {
  PlanDeferralConfig,
  ScheduledDistributionForRedeferral,
} from "../../../models/distributions/scheduled-distribution-event";
import { MarketCloseDate } from "../../../models/market-close-date";
import { parseDate } from "../../../util/date-util";

export class RedeferralDateUtil {
  scheduledDistributionsForRedeferral: ScheduledDistributionForRedeferral;
  planDeferralConfig: PlanDeferralConfig;
  marketCloseDates: MarketCloseDate[];

  constructor(
    scheduledDistributionsForRedeferral: ScheduledDistributionForRedeferral,
    marketCloseDates: MarketCloseDate[]
  ) {
    this.scheduledDistributionsForRedeferral =
      scheduledDistributionsForRedeferral;
    this.planDeferralConfig =
      scheduledDistributionsForRedeferral.planDeferralConfig;
    this.marketCloseDates = marketCloseDates;
  }

  get minDate(): Date {
    return parseDate(
      this.scheduledDistributionsForRedeferral.minimumRedeferDate
    );
  }

  get showOnlyYearPicker(): boolean {
    if (
      this.planDeferralConfig &&
      this.planDeferralConfig.planDefinedDistribution
    ) {
      return (
        this.planDeferralConfig.hideMonth && this.planDeferralConfig.hideDay
      );
    }
    return false;
  }

  get showOnlyMonthYearPicker(): boolean {
    if (
      this.planDeferralConfig &&
      this.planDeferralConfig.planDefinedDistribution
    ) {
      return (
        this.planDeferralConfig.hideDay && !this.planDeferralConfig.hideMonth
      );
    }
    return false;
  }

  get getDateFormat(): string {
    if (this.showOnlyYearPicker) {
      return "yyyy";
    } else if (this.showOnlyMonthYearPicker) {
      return "MM-yyyy";
    } else {
      return "MM-dd-yyyy";
    }
  }

  get getMarketCloseDates(): Date[] {
    if (!this.showOnlyMonthYearPicker && !this.showOnlyYearPicker) {
      return this.marketCloseDates.map((closeDate) => {
        return parseDate(closeDate.closeDate);
      });
    }
    return [];
  }

  get getDate(): Date | null {
    const scheduledDistribution = this.scheduledDistributionsForRedeferral;
    if (scheduledDistribution.newDistributionDate) {
      return parseDate(scheduledDistribution.newDistributionDate);
    } else if (scheduledDistribution.minimumRedeferDate) {
      return parseDate(scheduledDistribution.minimumRedeferDate);
    }
    return null;
  }

  get getIncludedDate(): Date[] | undefined {
    if (!this.showOnlyMonthYearPicker && !this.showOnlyYearPicker) {
      if (this.planDeferralConfig.enabledDates) {
        return this.planDeferralConfig.enabledDates.map((date) => {
          return parseDate(date);
        });
      }
    }
    return undefined;
  }

  get isDisabled(): boolean {
    return !this.scheduledDistributionsForRedeferral.isRedeferred;
  }

  getValidDay(date: Date | null): Date | null {
    if (date) {
      if (this.showOnlyYearPicker || this.showOnlyMonthYearPicker) {
        return this.getDateForPlanDefinedDates(date);
      }
    }
    return date;
  }

  getDateForPlanDefinedDates(date: Date): Date {
    const { planDefinedMonth, planDefinedDay, planDefinedDistribution } =
      this.planDeferralConfig;

    if (planDefinedDistribution) {
      const month = planDefinedMonth ? planDefinedMonth - 1 : date.getMonth();
      const day = planDefinedDay ? planDefinedDay : date.getDate();
      return new Date(date.getFullYear(), month, day);
    }
    return date;
  }

  filterDates = (date: Date) => {
    if (!this.showOnlyMonthYearPicker && !this.showOnlyYearPicker) {
      const day = getDay(date);
      return day !== 0 && day !== 6;
    }
    return true;
  };
}
