import classnames from "classnames";
import React, { useState } from "react";
import AccountDropdown from "../../../../components/account-dropdown/account-dropdown";
import { Skeleton } from "../../../../components/skeleton/skeleton";
import { accountsStatusSelector } from "../../../../selectors/status";
import { performanceType } from "../../../../util/constants";
import "./performance-table-filters.scss";
import { useSelector } from "../../../../store/store";
import { isNavChangesEnabled } from "../../../../selectors/feature-flags";

const { monthly: MONTHLY, quarterly: QUARTERLY } = performanceType;

type Props = {
  setPerformanceType: Function;
  performanceType: string;
  setShowAllAccounts: Function;
  showAllAccounts: boolean;
  setShowAvailableInPlan: Function;
  showAvailableInPlan: boolean;
  setShowFundPrices: Function;
  showFundPrices: boolean;
  setIsYourFundsSelected: Function;
  isYourFundsSelected: boolean;
  isWhatYouveSelectedClicked: boolean;
  setIsWhatYouveSelectedClicked: Function;
};

function PerformanceTableFiltersV2(props: Props) {
  const {
    setPerformanceType,
    performanceType,
    setShowAllAccounts,
    showAllAccounts,
    setShowAvailableInPlan,
    showAvailableInPlan,
    setIsYourFundsSelected,
    setShowFundPrices,
    showFundPrices,
    isYourFundsSelected,
    setIsWhatYouveSelectedClicked,
    isWhatYouveSelectedClicked,
  } = props;
  const availableAccounts = useSelector((state) => state.accounts.available);
  const navChangesEnabled = useSelector(isNavChangesEnabled);

  const monthlyButtonClasses = classnames("btn", {
    "btn-primary": performanceType === MONTHLY,
    "btn-default": performanceType !== MONTHLY,
  });
  const quarterlyButtonClasses = classnames("btn", {
    "btn-primary": performanceType === QUARTERLY,
    "btn-default": performanceType !== QUARTERLY,
  });
  const yourFundsButtonClasses = classnames("btn", {
    "btn-primary": isYourFundsSelected,
    "btn-default": !isYourFundsSelected,
  });
  const whatYouveSelectedButtonClasses = classnames("btn", {
    "btn-primary": !showAllAccounts && !showAvailableInPlan,
    "btn-default": showAllAccounts,
  });
  const availableUnderThePlanButtonClasses = classnames("btn", {
    "btn-primary": showAvailableInPlan,
    "btn-default": !showAvailableInPlan,
  });
  const performanceViewButtonClasses = classnames("btn", {
    "btn-primary": !showFundPrices,
    "btn-default": showFundPrices,
  });
  const fundPriceViewButtonClasses = classnames("btn", {
    "btn-primary": showFundPrices,
    "btn-default": !showFundPrices,
  });
  const allAccountsButtonClasses = classnames("btn", {
    "btn-primary": !showAllAccounts,
    "btn-default": showAllAccounts,
  });

  function handleYourFundsClick() {
    setShowAllAccounts(true);
    setIsYourFundsSelected(true);
    setShowAvailableInPlan(false);
    setIsWhatYouveSelectedClicked(false);
  }

  function handleWhatYouveSelectedClick() {
    setShowAllAccounts(false);
    setIsYourFundsSelected(false);
    setShowAvailableInPlan(false);
    setIsWhatYouveSelectedClicked(true);
  }

  function handleAvailableInPlanClick() {
    setShowAllAccounts(true);
    setIsYourFundsSelected(false);
    setShowAvailableInPlan(true);
    setIsWhatYouveSelectedClicked(false);
  }

  return (
    <div
      className="performance-table-filters"
      data-testid="performance-table-filters"
    >
      <div className="filters">
        <div role="group" aria-labelledby="filter-label-investments">
          <div className="filter-label" id="filter-label-investments">
            Investments
          </div>
          <div className="btn-group">
            <button
              type="button"
              className={yourFundsButtonClasses}
              data-testid="your-funds-filter"
              onClick={() => {
                handleYourFundsClick();
              }}
              aria-pressed={isYourFundsSelected}
            >
              Your Funds
            </button>
            <button
              type="button"
              className={whatYouveSelectedButtonClasses}
              data-testid="what-youve-selected-filter"
              onClick={() => {
                handleWhatYouveSelectedClick();
              }}
              aria-pressed={!isYourFundsSelected && !showAvailableInPlan}
            >
              Selected
            </button>
            <button
              type="button"
              className={availableUnderThePlanButtonClasses}
              data-testid="available-under-the-plan-filter"
              aria-pressed={showAvailableInPlan}
              onClick={() => {
                handleAvailableInPlanClick();
              }}
            >
              Available in Plan
            </button>
          </div>
        </div>
        {navChangesEnabled && (
          <div role="group" aria-labelledby="filter-table-view">
            <div
              className="filter-label"
              id="filter-table-view"
              data-testid="filter-table-view"
            >
              Data view
            </div>
            <div className="btn-group" style={{ minWidth: 194 }}>
              <button
                type="button"
                className={fundPriceViewButtonClasses}
                data-testid="fund-prices-filter"
                onClick={() => setShowFundPrices(!showFundPrices)}
                aria-pressed={!showFundPrices}
              >
                Prices
              </button>
              <button
                type="button"
                className={performanceViewButtonClasses}
                data-testid="performance-filter"
                onClick={() => setShowFundPrices(!showFundPrices)}
                aria-pressed={!showFundPrices}
              >
                Performance
              </button>
            </div>
          </div>
        )}
        {!showFundPrices && (
          <div role="group" aria-labelledby="filter-label-history">
            <div className="filter-label" id="filter-label-history">
              History
            </div>
            <div className="btn-group" style={{ minWidth: 194 }}>
              <button
                type="button"
                className={monthlyButtonClasses}
                data-testid="monthly-filter"
                onClick={() => setPerformanceType(MONTHLY)}
                aria-pressed={performanceType === MONTHLY}
              >
                Monthly
              </button>
              <button
                type="button"
                className={quarterlyButtonClasses}
                data-testid="quarterly-filter"
                onClick={() => setPerformanceType(QUARTERLY)}
                aria-pressed={performanceType === QUARTERLY}
              >
                Quarterly
              </button>
            </div>
          </div>
        )}
        {availableAccounts.length !== 0 && isWhatYouveSelectedClicked && (
          <div>
            <div className="filter-label">Your Accounts</div>
            <Skeleton
              selectors={[accountsStatusSelector]}
              width={300}
              height={44}
              style={{ marginRight: "25px" }}
            >
              <AccountDropdown />
            </Skeleton>
          </div>
        )}
      </div>
    </div>
  );
}

export default PerformanceTableFiltersV2;
