export enum AccountType {
  RETIREMENT = "Retirement",
  IN_SERVICE = "In-Service",
  EDUCATION = "Education",
}

export default function accountTypeString(accountType: string): string {
  switch (accountType) {
    case AccountType.RETIREMENT: {
      return "Retirement";
    }
    case AccountType.IN_SERVICE: {
      return "Savings";
    }
    case AccountType.EDUCATION: {
      return "Education";
    }
  }
  return "Unknown type";
}
