import React from "react";
import Icon from "../../../components/icon/icon";
import { setDates } from "../../../reducers/dates";
import { useAppDispatch } from "../../../store/store";
import { format } from "date-fns";
import "./quick-quarter-view.scss";

function QuickQuarterView(props) {
  const dispatch = useAppDispatch();
  const quarterStartArray = ["Zero", "01-01-", "04-01-", "07-01-", "10-01-"];
  const quarterEndArray = ["Zero", "03-31-", "06-30-", "09-30-", "12-31-"];

  const { quarter, isLoading, year, dateRange, index } = props;

  function formatPeriodStartDate(quarter, year) {
    const periodSplit = quarterStartArray[quarter].split("-");
    const periodMonth = parseInt(periodSplit[0], 10) - 1;
    const periodDate = periodSplit[1];
    const periodStartDate = new Date(year, periodMonth, periodDate);
    return periodStartDate;
  }

  function formatPeriodEndDate(quarter, year) {
    const periodSplit = quarterEndArray[quarter].split("-");
    const periodMonth = parseInt(periodSplit[0], 10) - 1;
    const periodDate = periodSplit[1];
    const periodEndDate = new Date(year, periodMonth, periodDate);
    return periodEndDate;
  }

  function submitPredefinedQuarter(quarter, year) {
    let start = formatPeriodStartDate(quarter, year);
    let end = formatPeriodEndDate(quarter, year);
    start = format(start, "yyyy-MM-dd");
    end = format(end, "yyyy-MM-dd");
    dispatch(setDates({ startDate: start, endDate: end }));
  }

  return (
    <div className={"col-md-3 quarter-view-panel"}>
      <h3 className="h4">Quarter {quarter}</h3>
      <p data-testid={`qv-para-start-${index}`}>{dateRange[0]}&nbsp;-</p>
      <p data-testid={`qv-para-end-${index}`}>{dateRange[1]}</p>
      <button
        disabled={isLoading}
        data-testid={`generate-qv-button-${index}`}
        className={"btn btn-primary"}
        onClick={() => {
          submitPredefinedQuarter(quarter, year);
        }}
      >
        View Statement
        {isLoading && <Icon name="spinner fa-spin" />}
      </button>
    </div>
  );
}

export default QuickQuarterView;
