import React from "react";
import { AccountWithUnscheduledDistributions } from "../../../models/account";
import { DistributionPayment } from "../../../models/distributions/distribution-payment";
import { useScheduledDistributionsWithAccounts } from "../../../selectors/scheduled-distribution-events";
import { filteredScheduledElectionsSelector } from "../../../selectors/scheduled-elections";
import { useSelector } from "../../../store/store";
import { filterScheduledDistributionsByAccountId } from "../../../util/distributions-util";
import {
  filterFutureDistributions,
  findScheduledDistributionByAccountId,
} from "../distribution-scheduled/scheduled-distribution-util";
import AccountDistributionDetail from "./account-distribution-detail";
import AccountDistributionsSummary from "./account-distributions-summary";
import EstimatedDistributionPlanDisclaimer from "./disclaimers/estimated-distribution-plan-disclaimer";
import { SingleAccountDistributionTable } from "./single-account-distribution-table/single-account-distribution-table";
import AccountName from "../../../components/account-name/account-name";

type Props = {
  account: AccountWithUnscheduledDistributions;
  distributionPayments: DistributionPayment[];
  isAccountSummary?: boolean;
};

export function SingleAccountDistributionDetail(props: Props) {
  const { account, distributionPayments, isAccountSummary } = props;
  const { id, name } = account;

  const filteredScheduledElections = useSelector(
    filteredScheduledElectionsSelector
  );
  const scheduledDistributions = useScheduledDistributionsWithAccounts();
  const filteredDistributions = filterScheduledDistributionsByAccountId(
    id,
    scheduledDistributions
  );

  const futureDistributions = filterFutureDistributions(filteredDistributions);

  const firstFutureScheduledDistribution = findScheduledDistributionByAccountId(
    futureDistributions,
    id
  )!;

  const firstScheduledDistributionElection = filteredScheduledElections.find(
    (distribution) => {
      return distribution.accountId === id;
    }
  );

  return (
    <>
      <div
        className="account-summary"
        data-testid="account-distribution-detail"
      >
        <div className={"row"}>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <div className="account-header account-header-stacked-top">
              <AccountName id={account.id} name={name} />
            </div>
          </div>
          <AccountDistributionsSummary
            account={account}
            firstScheduledDistribution={firstFutureScheduledDistribution}
            firstScheduledDistributionElection={
              firstScheduledDistributionElection
            }
          />
        </div>
        <div className="row well">
          <AccountDistributionDetail
            account={account}
            firstFutureScheduledDistribution={firstFutureScheduledDistribution}
          />
        </div>
      </div>
      {isAccountSummary && (
        <>
          <SingleAccountDistributionTable
            account={account}
            distributionPayments={distributionPayments}
            futureDistributions={futureDistributions}
          />
          <EstimatedDistributionPlanDisclaimer />
        </>
      )}
    </>
  );
}
