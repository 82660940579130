import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { downloadPdf } from "src/services/download-pdf";
import { AsyncStatus } from "../../models/async-status";

export const fetchDownloadPdf = createAsyncThunk(
  "fetch-download-pdf",
  async (
    {
      planId,
      empId,
      fileName,
      documentId,
      role,
    }: {
      planId: number;
      empId: number;
      fileName: string;
      documentId: number;
      role: string;
    },
    thunkApi
  ) => {
    return downloadPdf(planId, empId, fileName, documentId, role);
  }
);

type State = {
  status: AsyncStatus;
};

const initialState: State = {
  status: AsyncStatus.not_fetched,
};

const downloadPdfSlice = createSlice({
  name: "download-pdf",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDownloadPdf.pending, (state, action) => {
      return { ...state, status: AsyncStatus.loading };
    });
    builder.addCase(fetchDownloadPdf.fulfilled, (state, action) => {
      return { ...state, ...action.payload, status: AsyncStatus.done };
    });
    builder.addCase(fetchDownloadPdf.rejected, (state, action) => {
      return {
        ...state,
        status: AsyncStatus.error,
      };
    });
    builder.addDefaultCase((state, action) => state);
  },
});

export default downloadPdfSlice.reducer;
