import React from "react";
import Balance from "../../../components/numbers/balance";
import Percent from "../../../components/numbers/percent";
import { ContributionType } from "../../../models/contributions";
import { ContributionOutput } from "./manage-contributions-utilities";

type Props = {
  contributionOutput: ContributionOutput;
  payCheckFrequencyText: string;
  isBonus: Boolean;
};

export default function ManageContributionsFormOutput(props: Props) {
  const { contributionOutput, payCheckFrequencyText, isBonus } = props;
  const { name, salaryPercentage, type, contributionAmount } =
    contributionOutput;

  if (type === ContributionType.OTHER) {
    return (
      <div className="individual-form-output">
        <div className="bold">{name}</div>
        {contributionAmount}
      </div>
    );
  }

  if (salaryPercentage !== undefined) {
    const contributionAmountNumber: number = Number(contributionAmount);
    return (
      <div className="individual-form-output">
        <div className="bold">{name}</div>
        <>
          <Percent value={salaryPercentage} />
        </>{" "}
        {isBonus ? "per payment" : "per paycheck"}
        <div>
          Est. <Balance value={contributionAmountNumber} showDollar />{" "}
          {payCheckFrequencyText}
        </div>
      </div>
    );
  }

  return (
    <div className="individual-form-output">
      <div className="bold">{name}</div>
    </div>
  );
}
