import React from "react";
import { Column } from "react-table";
import TableV2 from "../../../../../components/table/tableV2";
import { InvestmentV2 } from "../../../../../models/investment-v2";

import {
  buildEndBalanceColumn,
  buildHexValueColumn,
} from "../build-columns-utils";
import {
  buildCreditingRateColumn,
  buildInstitutionNameWithDescColumn,
  buildRateLevelServiceFeeColumn,
} from "./build-pfio-columns-utils";

type Props = {
  pfioInvestments: InvestmentV2[];
};

function PfioTable(props: Props) {
  const { pfioInvestments } = props;
  const columns: Column[] = [
    buildHexValueColumn(),
    buildInstitutionNameWithDescColumn(),
    buildEndBalanceColumn(),
    buildCreditingRateColumn(),
    buildRateLevelServiceFeeColumn(),
  ];

  return (
    <>
      <h1 className={"h2"}>Fixed income option</h1>
      <TableV2 columns={columns} data={pfioInvestments} />
    </>
  );
}

export default PfioTable;
