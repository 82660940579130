import React from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { getColor } from "../../util/charts-util";
import { formatDateWithoutTimeZone } from "../../util/date-util";
import { getRoundedValue } from "../numbers/fixed-decimal";
import "./responsive-time-series-line-chart.scss";

export default function ForecastingGraph(props) {
  const { data, formatTooltip, calculateFunction } = props;
  const mappedData = data.map((item) => ({
    ...item,
    date: item.date.valueOf(),
  }));

  function formatXTick(dateString) {
    if (dateString === "") {
      return undefined;
    }

    if (props.formatDate) {
      return props.formatDate(dateString);
    }

    return formatDateWithoutTimeZone(dateString, "MMM yyyy");
  }

  function formatYTick(item) {
    return `$${getRoundedValue(item, 0)}`;
  }

  const toolTipRef = React.createRef();

  function getDateFromToolTip() {
    let date =
      toolTipRef.current.wrapperNode.children[0].children[0].textContent;
    date = date.split(" ")[1];
    const selectedYear = date.split("-")[2];
    calculateFunction(true, selectedYear);
  }

  return (
    <ResponsiveContainer width="102%" height={350}>
      <AreaChart
        data={mappedData}
        onClick={() => getDateFromToolTip()}
        margin={{ left: 30 }}
        style={{ cursor: "pointer" }}
      >
        <defs>
          <linearGradient id="color" x1="0" y1="0" x2="0" y2="1">
            <stop offset="51%" stopColor={getColor(9)} stopOpacity={1} />
            <stop offset="95%" stopColor={getColor(9)} stopOpacity={0.1} />
          </linearGradient>
        </defs>

        <Area dataKey="Total Balance" stroke="#4b4687" fill="url(#color)" />
        <XAxis dataKey="date" tickFormatter={formatXTick} />
        <YAxis dataKey="Total Balance" tickFormatter={formatYTick} />
        <Tooltip
          ref={toolTipRef}
          labelFormatter={(item) => `Date: ${formatDateWithoutTimeZone(item)}`}
          formatter={formatTooltip}
        />
        <CartesianGrid vertical={false} />
      </AreaChart>
    </ResponsiveContainer>
  );
}
