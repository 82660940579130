import React from "react";
import { Link } from "react-router-dom";
import { Page } from "src/components/page";
import { Skeleton } from "../../../components/skeleton/skeleton";
import { RedeferralStatus } from "../../../models/redeferral/redeferral-status";
import { DistributionRoutes } from "../../../routes/routes";
import { accountRedeferralStatusesSelector } from "../../../selectors/redeferrals";
import { accountRedeferralStatusesStatusSelector } from "../../../selectors/status";
import { useSelector } from "../../../store/store";
import { ScheduledDistributionEvent } from "../../../models/distributions/scheduled-distribution-event";
import scheduledDistributionEvent from "../../../reducers/distributions/scheduledDistributionEvent";

export default function RedeferSummaryPage() {
  const allAccountRedeferralStates: RedeferralStatus[] = useSelector(
    accountRedeferralStatusesSelector
  );

  const accountsWithActiveRedeferral = allAccountRedeferralStates.filter(
    (accountRedeferralState: RedeferralStatus) => {
      return accountRedeferralState.showRedeferLink;
    }
  );

  const accountsWithPendingRedeferral = allAccountRedeferralStates.filter(
    (accountRedeferralState: RedeferralStatus) => {
      return (
        accountRedeferralState.showPendingRedeferralLink &&
        !accountRedeferralState.showRedeferLink
      );
    }
  );
  const accountsWithoutOptions = allAccountRedeferralStates.filter(
    (accountRedeferralState: RedeferralStatus) => {
      return (
        !accountRedeferralState.showPendingRedeferralLink &&
        !accountRedeferralState.showRedeferLink
      );
    }
  );
  return (
    <Page name={"redefer-summary"} loading={false}>
      <h1>Your redeferral status summary</h1>
      <Skeleton
        selectors={[accountRedeferralStatusesStatusSelector]}
        height={90}
        width={100}
      >
        <div className="row">
          <div className="col-md-12">
            <h3>Accounts with active redeferral options</h3>
            {accountsWithActiveRedeferral &&
              accountsWithActiveRedeferral.length === 0 && (
                <p>None available.</p>
              )}
            {accountsWithActiveRedeferral &&
              accountsWithActiveRedeferral.map(
                (accountRedeferralState: RedeferralStatus) => {
                  const accountLink =
                    DistributionRoutes.RedeferralsForAccount +
                    `/` +
                    accountRedeferralState.accountId;
                  return (
                    <>
                      <p>
                        <Link to={accountLink} className="util-margin-right-10">
                          {accountRedeferralState.accountName} (Account ID{" "}
                          {accountRedeferralState.accountId})
                        </Link>
                        {accountRedeferralState.showRedeferLink && (
                          <>
                            <span className="badge badge-primary">REDEFER</span>
                          </>
                        )}
                      </p>
                    </>
                  );
                }
              )}
          </div>
          <div className="col-md-12">
            <h3>Accounts with pending redeferral options</h3>
            {accountsWithPendingRedeferral &&
              accountsWithPendingRedeferral.length === 0 && (
                <p>None available.</p>
              )}
            {accountsWithPendingRedeferral &&
              accountsWithPendingRedeferral.map(
                (accountRedeferralState: RedeferralStatus) => {
                  const accountLink =
                    DistributionRoutes.RedeferralsForAccount +
                    `/` +
                    accountRedeferralState.accountId;
                  return (
                    <>
                      <p>
                        <Link to={accountLink} className="util-margin-right-10">
                          {accountRedeferralState.accountName} (Account ID{" "}
                          {accountRedeferralState.accountId})
                        </Link>
                        {accountRedeferralState.showPendingRedeferralLink && (
                          <>
                            <span className="badge badge-secondary">
                              PENDING REDEFERRAL
                            </span>
                          </>
                        )}
                      </p>
                    </>
                  );
                }
              )}
          </div>
          <div className="col-md-12">
            <h3>Accounts without redeferral options</h3>
            {accountsWithoutOptions && accountsWithoutOptions.length === 0 && (
              <p>No accounts without redeferral options available.</p>
            )}
            {accountsWithoutOptions &&
              accountsWithoutOptions.map(
                (accountRedeferralState: RedeferralStatus) => {
                  const accountLink =
                    DistributionRoutes.RedeferralsForAccount +
                    `/` +
                    accountRedeferralState.accountId;
                  return (
                    <>
                      <p>
                        <Link to={accountLink} className="util-margin-right-10">
                          {accountRedeferralState.accountName} (Account ID{" "}
                          {accountRedeferralState.accountId})
                        </Link>
                      </p>
                    </>
                  );
                }
              )}
          </div>
        </div>
      </Skeleton>
    </Page>
  );
}
