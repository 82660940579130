import React from "react";
import { formatDateWithoutTimeZone } from "../../util/date-util";

type Props = {
  date?: Date | string;
  formatter?: string;
};

export default function Date(props: Props) {
  const { date, formatter } = props;

  if (!date) {
    return <>N/A</>;
  }

  return <>{formatDateWithoutTimeZone(date, formatter)}</>;
}
