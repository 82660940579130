import _sumBy from "lodash/sumBy";
import React from "react";
import InvestmentAdvisorLink from "../../../../components/investments/investment-advisor-link";
import InvestmentLink from "../../../../components/investments/investment-link";
import Balance from "../../../../components/numbers/balance";
import Date from "../../../../components/numbers/date";
import Percent from "../../../../components/numbers/percent";
import { Skeleton } from "../../../../components/skeleton/skeleton";
import {
  accountsStatusSelector,
  datesStatusSelector,
  investmentsStatusSelector,
} from "../../../../selectors/status";
import DisclosureLinks from "../investment-disclosures/disclosure-links";

function getHistoryAccessor(historyType, accessor) {
  return `${historyType}.${accessor}`;
}

export function buildHexValueColumn() {
  return {
    Header: () => <span className="sr-only">Asset class</span>,
    accessor: "hexValue",
    isColorCell: true,
  };
}

export function buildInstitutionNameColumn() {
  return {
    Header: (
      <span className="header">
        <InvestmentAdvisorLink />
      </span>
    ),
    accessor: "id",
    Cell: ({ row }) => {
      const investmentId = row.original.id;
      const institutionName = row.original.institutionName;
      const fundName = row.original.name;
      const disclosureIds = row.original.disclosureIds?.map((disc) => disc.id);

      return (
        <span
          className="investment-option"
          data-testid={`fund-name-${investmentId}`}
        >
          <Skeleton
            selectors={[
              investmentsStatusSelector,
              accountsStatusSelector,
              datesStatusSelector,
            ]}
            height={18}
            style={{ margin: "3px 0" }}
          >
            <span
              className="advisor-name show"
              data-testid={`advisor-name-${investmentId}`}
            >
              {institutionName}
            </span>
          </Skeleton>
          <Skeleton
            selectors={[
              investmentsStatusSelector,
              accountsStatusSelector,
              datesStatusSelector,
            ]}
            height={18}
            style={{ margin: "3px 0" }}
          >
            <InvestmentLink
              id={investmentId}
              fundName={fundName}
              className="investment-name"
            />
          </Skeleton>
          <DisclosureLinks disclosureIds={disclosureIds} />
        </span>
      );
    },
  };
}

export function buildEndBalanceColumn() {
  const Header = () => (
    <span className="number header">
      Balance
      <span className="show">&nbsp;($)</span>
    </span>
  );
  return buildZeroAsNAColumn(Header, "endBalance.cashBalance.balance");
}

export function buildSharePriceColumn() {
  const Header = () => (
    <span className="number no-wrap header">
      <span className="show">Share Price/</span>
      <span className="show">Unit Value</span>
      <DisclosureLinks disclosureIds={["B1"]} /> ($)
    </span>
  );
  return buildBalanceColumn(Header, "sharePrice");
}

export function buildNavChangeDollarColumn() {
  return {
    Header: (
      <span className="number no-wrap header">
        <span className=" show number header">Daily NAV </span>
        <span>Change</span>
        <span className="show">&nbsp;($)</span>
      </span>
    ),
    accessor: "navChanges.navChange",
    className: "right-align",
    Cell: ({ value }) => (
      <Skeleton
        selectors={[
          investmentsStatusSelector,
          accountsStatusSelector,
          datesStatusSelector,
        ]}
        height={18}
      >
        <Balance value={value} precision={3} />{" "}
      </Skeleton>
    ),
  };
}

export function buildNavChangePercentColumn() {
  return {
    Header: (
      <span className="number no-wrap header">
        <span className="show number header">Daily NAV </span>
        <span>Change</span>
        <span className="show">&nbsp;(%)</span>
      </span>
    ),
    accessor: "navChanges.navChangePercent",
    className: "right-align",
    Cell: ({ value }) => (
      <Skeleton
        selectors={[
          investmentsStatusSelector,
          accountsStatusSelector,
          datesStatusSelector,
        ]}
        height={18}
      >
        <Percent value={value} showSymbol={false} roundTo={2} />{" "}
      </Skeleton>
    ),
  };
}

export function buildNavColumn() {
  return {
    Header: (
      <span className="number header">
        NAV
        <span className="show">&nbsp;($)</span>
      </span>
    ),
    accessor: "navChanges.nav",
    className: "right-align",
    Cell: ({ value }) => (
      <Skeleton
        selectors={[
          investmentsStatusSelector,
          accountsStatusSelector,
          datesStatusSelector,
        ]}
        height={18}
      >
        <Balance value={value} precision={3} />{" "}
      </Skeleton>
    ),
  };
}

export function buildNavYTDChangeDollarColumn() {
  return {
    Header: (
      <span className="number no-wrap header">
        <span className="show number header">YTD </span>
        <span>Change</span>
        <span className="show">&nbsp;($)</span>
      </span>
    ),
    accessor: "navChanges.ytdChange",
    className: "right-align",
    Cell: ({ value }) => (
      <Skeleton
        selectors={[
          investmentsStatusSelector,
          accountsStatusSelector,
          datesStatusSelector,
        ]}
        height={18}
      >
        <Balance value={value} precision={3} />{" "}
      </Skeleton>
    ),
  };
}

export function buildYearToDateColumn(historyType) {
  const Header = () => (
    <span className="number no-wrap header">
      <span className="show number header">YTD </span>
      <span>Change</span>
      <span className="show">
        &nbsp;
        <DisclosureLinks className="span-links" disclosureIds={["A1"]} /> (%)
      </span>
    </span>
  );
  return buildDollarOrPercentColumn(
    Header,
    getHistoryAccessor(historyType, "yearToDate")
  );
}

export function buildLastFullMonthColumn(historyType) {
  const Header = () => (
    <span className="number no-wrap header">
      Last Full
      <span className="show">&nbsp;Month</span>
      <span className="show">&nbsp;(%)</span>
    </span>
  );
  return buildDollarOrPercentColumn(
    Header,
    getHistoryAccessor(historyType, "oneMonth")
  );
}

export function buildThreeMonthColumn(historyType) {
  const Header = () => (
    <span className="number no-wrap header">
      3 Months
      <span className="show">&nbsp;(%)</span>
    </span>
  );
  return buildDollarOrPercentColumn(
    Header,
    getHistoryAccessor(historyType, "threeMonth")
  );
}

export function buildOneYearColumn(historyType) {
  const Header = () => (
    <span className="number no-wrap header">
      1 Year
      <span className="show">&nbsp;(%)</span>
    </span>
  );
  return buildDollarOrPercentColumn(
    Header,
    getHistoryAccessor(historyType, "oneYear")
  );
}

export function buildThreeYearColumn(historyType) {
  const Header = () => (
    <span className="number no-wrap header">
      3 Years
      <span className="show">&nbsp;(%)</span>
    </span>
  );
  return buildDollarOrPercentColumn(
    Header,
    getHistoryAccessor(historyType, "threeYear")
  );
}

export function buildFiveYearColumn(historyType) {
  const Header = () => (
    <span className="number no-wrap header">
      5 Years
      <span className="show">&nbsp;(%)</span>
    </span>
  );
  return buildDollarOrPercentColumn(
    Header,
    getHistoryAccessor(historyType, "fiveYear")
  );
}

export function buildTenYearColumn(historyType) {
  const Header = () => (
    <span className="number no-wrap header">
      10 Years
      <span className="show">&nbsp;(%)</span>
    </span>
  );
  return buildDollarOrPercentColumn(
    Header,
    getHistoryAccessor(historyType, "tenYear")
  );
}

export function buildSinceInceptionColumn(historyType) {
  const Header = () => (
    <span className="number header">
      Since Inception
      <span className="show">&nbsp;(%)</span>
    </span>
  );
  return buildDollarOrPercentColumn(
    Header,
    getHistoryAccessor(historyType, "incept")
  );
}

export function buildInceptionDateColumn() {
  return {
    Header: (
      <span className="number header">
        Inception Date
        <span className="no-wrap show">(MM-DD-YYYY)</span>
      </span>
    ),
    accessor: "inceptionDate",
    className: "right-align",
    Cell: ({ value }) => (
      <Skeleton
        selectors={[
          investmentsStatusSelector,
          accountsStatusSelector,
          datesStatusSelector,
        ]}
        height={18}
      >
        <Date date={value} />{" "}
      </Skeleton>
    ),
  };
}

export function buildGrossExpenseRatioColumn(historyType) {
  const Header = () => (
    <span className="number header">
      Gross Expense Ratio
      <span className="show">&nbsp;(%)</span>
    </span>
  );
  return buildPercentColumn(
    Header,
    getHistoryAccessor(historyType, "expratio")
  );
}

export function buildPercentColumn(Header, accessor, footer = false) {
  return {
    Header,
    accessor,
    className: "right",
    Cell: (info) => {
      const { value } = info;
      if (typeof value === "number") {
        return (
          <Skeleton
            selectors={[
              investmentsStatusSelector,
              accountsStatusSelector,
              datesStatusSelector,
            ]}
            height={18}
            width={60}
            style={{ margin: "3px 0" }}
          >
            <Percent value={value} showSymbol={false} isPercentage />
          </Skeleton>
        );
      }
      return (
        <Skeleton
          selectors={[
            investmentsStatusSelector,
            accountsStatusSelector,
            datesStatusSelector,
          ]}
          height={18}
          width={60}
          style={{ margin: "3px 0" }}
        >
          N/A
        </Skeleton>
      );
    },
    Footer: (info) => {
      const total = React.useMemo(
        () => _sumBy(info.rows, `values["${accessor}"]`),
        [info.rows]
      );
      if (footer) {
        return <Percent value={total} showSymbol={false} />;
      }
      return null;
    },
  };
}

export function buildBalanceColumn(Header, accessor, footer = false) {
  return {
    Header,
    accessor,
    className: "right",
    Cell: (info) => {
      const { value } = info;
      if (typeof value === "number") {
        return (
          <Skeleton
            selectors={[
              investmentsStatusSelector,
              accountsStatusSelector,
              datesStatusSelector,
            ]}
            height={18}
            width={60}
            style={{ margin: "3px 0" }}
          >
            <Balance value={value} precision={3} />
          </Skeleton>
        );
      }
      return (
        <Skeleton
          selectors={[
            investmentsStatusSelector,
            accountsStatusSelector,
            datesStatusSelector,
          ]}
          height={18}
          width={60}
          style={{ margin: "3px 0" }}
        >
          N/A
        </Skeleton>
      );
    },
    Footer: (info) => {
      const total = React.useMemo(
        () => _sumBy(info.rows, `values["${accessor}"]`),
        [info.rows]
      );
      if (footer) {
        return <Balance value={total} />;
      }
      return null;
    },
  };
}

export function buildDollarOrPercentColumn(Header, accessor, footer = false) {
  return {
    Header,
    accessor,
    className: "right",
    Cell: (info) => {
      const { value } = info;
      if (typeof value === "number") {
        return (
          <Skeleton
            selectors={[
              investmentsStatusSelector,
              accountsStatusSelector,
              datesStatusSelector,
            ]}
            height={18}
            width={60}
            style={{ margin: "3px 0" }}
          >
            <Percent
              value={value}
              defaultValue={"N/A"}
              showSymbol={false}
              isPercentage
            />
          </Skeleton>
        );
      }
      return (
        <Skeleton
          selectors={[
            investmentsStatusSelector,
            accountsStatusSelector,
            datesStatusSelector,
          ]}
          height={18}
          width={60}
          style={{ margin: "3px 0" }}
        >
          N/A
        </Skeleton>
      );
    },
    Footer: (info) => {
      const totalValue = React.useMemo(
        () => _sumBy(info.rows, `values["${accessor}"]`),
        [info.rows]
      );
      if (footer) {
        return <Percent value={totalValue} isPercentage showSymbol={false} />;
      }
      return null;
    },
  };
}

export function buildZeroAsNAColumn(Header, accessor, footer = false) {
  return {
    Header,
    accessor,
    className: "right",
    Cell: (info) => {
      const { value } = info;
      if (typeof value === "number" && value > 0) {
        return (
          <Skeleton
            selectors={[
              investmentsStatusSelector,
              accountsStatusSelector,
              datesStatusSelector,
            ]}
            height={18}
            width={60}
            style={{ margin: "3px 0" }}
          >
            <Balance value={value} />
          </Skeleton>
        );
      }
      return (
        <Skeleton
          selectors={[
            investmentsStatusSelector,
            accountsStatusSelector,
            datesStatusSelector,
          ]}
          height={18}
          width={60}
          style={{ margin: "3px 0" }}
        >
          N/A
        </Skeleton>
      );
    },
    Footer: (info) => {
      const total = React.useMemo(
        () => _sumBy(info.rows, `values["${accessor}"]`),
        [info.rows]
      );
      if (footer) {
        return (
          <Skeleton
            selectors={[
              investmentsStatusSelector,
              accountsStatusSelector,
              datesStatusSelector,
            ]}
            height={18}
            width={60}
            style={{ margin: "3px 0" }}
          >
            <Balance value={total} />
          </Skeleton>
        );
      }
      return null;
    },
  };
}
