import { AccountType } from "./account-type";
import { InstallmentFrequency } from "./installment-frequency";
import { InstallmentType } from "./installment-type";
import { PaymentType } from "./payment-type";

export type ScheduledDistributionEvent = {
  accountId: number;
  accountName: string;
  scheduledDate: string;
  accountType: AccountType;
  altMethod?: string;
  useAltMeth?: boolean;
  paymentType: PaymentType;
  paymentMethodId?: number;
  paymentMethod?: string;
  installmentFrequency: InstallmentFrequency;
  installNum: number;
  totalInstallments: number;
  totalInstallmentsRemaining?: number;
  yearsPayout: number;
  availableForRedeferral: boolean;
  isFuture: boolean;
  isPending?: boolean;
  completedDate?: string;
  transactionAmount?: number;
  status: string;
  payoutStartDate: string;
  comments?: string;
  showComments: boolean;
  installmentType?: InstallmentType;
  installmentAmount?: number;
};

export interface ScheduledDistributionForRedeferral
  extends ScheduledDistributionEvent {
  pendingDistributionDate?: string;
  minimumRedeferDate: string;
  isRedeferred: boolean;
  newDistributionDate: string | null;
  installmentString: string;
  planDeferralConfig: PlanDeferralConfig;
  distributionAmount: number;
  originalInstallmentNumber: number;
  estimatedDistribution: number;
}

export interface FinalRedeferralInstruction
  extends ScheduledDistributionForRedeferral {
  id: number;
  effectiveDate: string;
  accountName: string;
  payoutStartDate: string;
  distributionMethodDisplay: string;
  effectiveAsOfDate: string;
  installmentNumber: number;
}

export interface PlanDeferralConfig {
  hideMonth: boolean;
  hideDay: boolean;
  planDefinedDistribution: boolean;
  planDefinedDay: number;
  planDefinedMonth: number;
  adjustedDateDirection: AdjustedDateDirection;
  enabledDates?: string[];
}

export enum AdjustedDateDirection {
  NO_ADJUSTMENT,
  PREVIOUS_DAY,
  NEXT_DAY,
}

export interface GeneratedScheduledDistributionEvent
  extends ScheduledDistributionEvent {
  color?: string;
}
