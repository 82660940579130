import React from "react";
import TableV2 from "../../../../components/table/tableV2";
import AccountSpecificEstimatedDistributionDisclaimer from "../disclaimers/estimated-distribution-account-disclaimer";
import {
  buildEstimatedDistributionColumn,
  buildInstallmentColumn,
} from "../scheduled-distributions-table/build-summary-schedule-distributions-columns-utils";
import "./estimated-distributions-table.scss";
import { ColumnWithClass } from "../../distribution-scheduled/build-schedule-distributions-columns-util/build-schedule-distributions-columns-utils";

type Props = {
  estimatedDistributions: any;
  age: number | undefined;
};

export default function EstimatedDistributionsTable(props: Props) {
  const { estimatedDistributions, age } = props;
  const emptyColumn: ColumnWithClass = {
    Header: <></>,
    accessor: "empty",
    Cell: (row: {}) => <></>,
    className: "",
    disableGroupBy: false,
    disableSortBy: false,
  };
  const columns: ColumnWithClass[] = [
    buildInstallmentColumn(),
    emptyColumn,
    buildEstimatedDistributionColumn(),
  ];
  return (
    <div className="estimated-distributions-table">
      <h4>Estimated Distribution(s)</h4>
      <AccountSpecificEstimatedDistributionDisclaimer seniorityAge={age} />
      <div className="table-wrapper">
        <TableV2
          className="left-align"
          columns={columns}
          data={estimatedDistributions}
          hasFooter={false}
          tableName="summary-estimated-distributions-table"
        />
      </div>
    </div>
  );
}
