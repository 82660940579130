import { createSelector } from "@reduxjs/toolkit";
import { ScheduledDistributionElection } from "../models/distributions/ScheduledDistributionElection";
import { RootState } from "../store/store";
import { selectedAccountIdsSelector } from "./accounts";

export const scheduledElectionsSelector = (
  state: RootState
): ScheduledDistributionElection[] =>
  state.distributions.scheduledElections.scheduledElections;

export const filteredScheduledElectionsSelector = createSelector(
  [scheduledElectionsSelector, selectedAccountIdsSelector],
  (
    scheduledElections: ScheduledDistributionElection[],
    selectedIds: number[]
  ): ScheduledDistributionElection[] => {
    return scheduledElections.filter(
      (scheduledElection: ScheduledDistributionElection) =>
        selectedIds.includes(scheduledElection.accountId)
    );
  }
);
