import _round from "lodash/round";
import _sumBy from "lodash/sumBy";
import React, { useEffect } from "react";
import ResponsiveTimeSeriesAreaChart from "../../../components/chart/responsive-time-series-area-chart";
import LoadingSpinner from "../../../components/loading-spinner/loading-spinner";
import { getRoundedValue } from "../../../components/numbers/fixed-decimal";
import {
  Panel,
  PanelBody,
  PanelHeading,
} from "../../../components/panel/panel";
import { isLoadingStatus } from "../../../models/async-status";
import { fetchAccountsBalancesByDate } from "../../../reducers/account-balances-by-date";
import { useAccountBalances } from "../../../selectors/accountBalancesByDate";
import { useDates } from "../../../selectors/dates";
import { useAppDispatch, useSelector } from "../../../store/store";
import { renderArea } from "../../../util/charts-util";
import { parseDate } from "../../../util/date-util";

function TotalAccountBalanceChart() {
  const { accountBalancesByDate, status } = useAccountBalances();
  const { planId, empId, role } = useSelector((state) => state.session);
  const { startDate, endDate } = useDates();
  const dispatch = useAppDispatch();
  const isLoading = isLoadingStatus(status);

  useEffect(() => {
    dispatch(
      fetchAccountsBalancesByDate({ planId, empId, role, startDate, endDate })
    );
  }, [dispatch, planId, empId, role, startDate, endDate]);

  const aggregatedInvestments = accountBalancesByDate.map(
    ({ date, accounts }) => {
      const parsedDate = parseDate(date);
      let total = _sumBy(accounts, "balance");
      total = _round(total, 2);

      return {
        date: parsedDate,
        "Total Balance": total,
      };
    }
  );

  return (
    <Panel>
      <PanelHeading>Track your progress</PanelHeading>
      <PanelBody>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <ResponsiveTimeSeriesAreaChart
            data={aggregatedInvestments}
            formatTooltip={(value, name) => [
              `$${getRoundedValue(value, 2)}`,
              name,
            ]}
            startDate={startDate}
            endDate={endDate}
            formatLegend={() => ["Total Balance - All Accounts"]}
          >
            {renderArea("Total Balance", 0, { strokeWidth: 3 })}
          </ResponsiveTimeSeriesAreaChart>
        )}
      </PanelBody>
    </Panel>
  );
}

export default TotalAccountBalanceChart;
