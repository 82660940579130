import _get from "lodash/get";
import _sortBy from "lodash/sortBy";
import _uniq from "lodash/uniq";
import { Account } from "../models/account";
import { InvestmentV2 } from "../models/investment-v2";

type RequireAtLeastOne<T, Keys extends keyof T = keyof T> = Pick<
  T,
  Exclude<keyof T, Keys>
> &
  {
    [K in Keys]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<Keys, K>>>;
  }[Keys];

type HasInvestmentId = RequireAtLeastOne<any, "id" | "investmentId">;

export const parseAccountIds = (accounts: any[]) => {
  return _uniq(accounts.map(({ accountId, id }) => accountId || id));
};

export function filterInvestmentsByAccounts(
  investments: InvestmentV2[],
  selectedAccountIs: Account[]
) {
  const accountIds = parseAccountIds(selectedAccountIs);
  return investments.filter(
    (investment: InvestmentV2) =>
      investment.accountId && accountIds.includes(investment.accountId)
  );
}

export function calculateTotalEndBalanceV2(investments: InvestmentV2[]) {
  return investments.reduce(
    (prev, next) => prev + _get(next, "endBalance.cashBalance.balance", 0),
    0
  );
}

export function calculateTotalVestedBalanceV2(investments: InvestmentV2[]) {
  return investments.reduce(
    (prev, next) =>
      prev + _get(next, "endBalance.cashBalance.vestedBalance", 0),
    0
  );
}

export function calculateTotalRateOfReturn(investments: InvestmentV2[] = []) {
  const totals = investments.reduce(
    (acc, { timeWeightedBalance = 0, earningAmt = 0 }) => {
      acc.timeWeightedBalance += timeWeightedBalance;
      acc.earningAmt += earningAmt;
      return acc;
    },
    {
      timeWeightedBalance: 0,
      earningAmt: 0,
    }
  );
  const zeroTimeWeightedBalance = Math.abs(totals.timeWeightedBalance) < 0.01;
  if (
    totals.earningAmt === 0 ||
    totals.timeWeightedBalance === 0 ||
    zeroTimeWeightedBalance
  ) {
    return 0;
  }
  return totals.earningAmt / totals.timeWeightedBalance;
}

export function calculateTotalPRR(investments: InvestmentV2[] = []) {
  const beginCashBalances = investments.reduce(
    (prev, next) =>
      prev + _get(next, "beginBalance.cashBalance.balance", 0),
    0
  );
  const totals = investments.reduce(
    (acc, { timeWeightedBalance = 0, earningAmt = 0, }) => {
      acc.timeWeightedBalance += timeWeightedBalance;
      acc.earningAmt += earningAmt;
      return acc;
    },
    {
      timeWeightedBalance: 0,
      earningAmt: 0,
    }
  );
  const zeroTimeWeightedBalance = Math.abs(totals.timeWeightedBalance + beginCashBalances) < 0.01;
  if (
    totals.earningAmt === 0 ||
    zeroTimeWeightedBalance
  ) {
    return 0;
  }
  return totals.earningAmt / (totals.timeWeightedBalance + beginCashBalances);
}

export function sortInvestments<T extends HasInvestmentId>(
  investments: T[] = []
): T[] {
  return _sortBy(investments, "sortOrder", "name");
}

export function filterInvestmentsWithZeroBalance<T extends HasInvestmentId>(
  investments: T[]
): T[] {
  return investments.filter((investment) => {
    return _get(investment, "endBalance.cashBalance.balance", 0) !== 0;
  });
}

export type AssetClass = {
  assetClass: string;
  balanceRatio: number;
  hexValue: string;
};

export interface AssetClassInvestment extends InvestmentV2 {
  balanceRatio?: number;
}

export function calculateAssetClassTotals(investments: AssetClassInvestment[]) {
  const classes: { [name: string]: AssetClass } = {};
  investments.forEach((investment) => {
    const { assetClass, hexValue, balanceRatio = 0 } = investment;
    if (!assetClass) {
      return;
    }
    let currentClass = classes[assetClass];
    if (!currentClass) {
      currentClass = {
        assetClass,
        hexValue: `#${hexValue}`,
        balanceRatio: 0,
      };
    }

    const currentBalanceRatio = currentClass.balanceRatio;
    currentClass.balanceRatio = currentBalanceRatio + balanceRatio;
    classes[assetClass] = currentClass;
  });

  return Object.values(classes);
}
