import React from "react";
import { Account } from "../../../../models/account";
import { RedeferralStatus } from "../../../../models/redeferral/redeferral-status";
import { accountRedeferralStatusesSelector } from "../../../../selectors/redeferrals";
import { useSelector } from "../../../../store/store";
import { toBase64String } from "../../../../util/base-64-util";
import {
  buildPensionContextLink,
  buildRedeferralLinkParams,
} from "../../../../util/nq-participant-link-builder";
import PendingDistributionsButton from "./pending-distributions-button";
import "./pending-distributions-button.scss";
import {
  getRedeferralFlowType,
  RedeferralType,
} from "../../redefer/redefer-type-enum";

type Props = {
  account: Account;
};

export default function PendingDistributionsWrapper(props: Props) {
  const { contractId } = useSelector((state) => state.plan.info);
  const { planId, empId, role, isAdvisorOrEmployer } = useSelector(
    (state) => state.session
  );

  const accountRedeferralStatuses: RedeferralStatus[] = useSelector(
    accountRedeferralStatusesSelector
  );
  const { account } = props;
  const accountRedeferralStatus: RedeferralStatus | undefined =
    accountRedeferralStatuses.find((status) => status.accountId === account.id);

  const redeferralType = getRedeferralFlowType(accountRedeferralStatus);

  function getRedeferButton() {
    const redeferralLink = getRedeferralLink(
      empId,
      contractId,
      accountRedeferralStatus,
      redeferralType,
      account,
      isAdvisorOrEmployer,
      role
    );
    return (
      <PendingDistributionsButton
        link={redeferralLink}
        buttonText={"Redefer"}
        isAdvisorOrEmployer={isAdvisorOrEmployer}
        primaryButton
      />
    );
  }

  function getPendingRedeferralButton() {
    const pendingRedeferralLink = getPendingRedeferralLink(
      empId,
      contractId,
      accountRedeferralStatus,
      redeferralType,
      account,
      isAdvisorOrEmployer,
      role
    );
    return (
      <PendingDistributionsButton
        link={pendingRedeferralLink}
        buttonText={"Pending redeferrals"}
        isAdvisorOrEmployer={isAdvisorOrEmployer}
      />
    );
  }

  if (!accountRedeferralStatus) {
    return null;
  } else if (accountRedeferralStatus.showRedeferLink) {
    return getRedeferButton();
  } else if (accountRedeferralStatus.showPendingRedeferralLink) {
    return getPendingRedeferralButton();
  } else {
    return null;
  }
}

export function getRedeferralLink(
  empId: number,
  contractId: string,
  accountRedeferralStatus: RedeferralStatus | undefined,
  redeferralType: RedeferralType,
  account: Account,
  isAdvisorOrEmployer: boolean,
  role: string
) {
  let redeferralLink;
  if (!accountRedeferralStatus) {
    redeferralLink = "";
  } else if (redeferralType === RedeferralType.STREAM_OF_PAYMENT) {
    redeferralLink = `/nq-participant/distributions/redeferral/begin/account/${account.id}`;
  } else {
    const action = accountRedeferralStatus
      ? accountRedeferralStatus.redeferralAction
      : null;
    redeferralLink = buildCompleteRedeferralLink(
      empId,
      contractId,
      action,
      account.name,
      account.id,
      role
    );
  }
  return redeferralLink;
}

export function getPendingRedeferralLink(
  empId: number,
  contractId: string,
  accountRedeferralStatus: RedeferralStatus | undefined,
  redeferralType: RedeferralType,
  account: Account,
  isAdvisorOrEmployer: boolean,
  role: string
) {
  let pendingRedeferralLink;
  if (!accountRedeferralStatus) {
    pendingRedeferralLink = "";
  } else if (redeferralType === RedeferralType.STREAM_OF_PAYMENT) {
    pendingRedeferralLink = accountRedeferralStatus.pendingRedeferralUrl;
  } else {
    const action = accountRedeferralStatus
      ? accountRedeferralStatus.redeferralAction
      : null;
    pendingRedeferralLink = buildCompleteRedeferralLink(
      empId,
      contractId,
      action,
      account.name,
      account.id,
      role
    );
  }
  return pendingRedeferralLink;
}

export function buildCompleteRedeferralLink(
  empId: number,
  contractId: string,
  action: string | null,
  accountName: string,
  accountId: number,
  role: string
) {
  const accountIdB64 = toBase64String(accountId);
  const redeferralLinkParams = buildRedeferralLinkParams(
    accountIdB64,
    accountName
  );
  return buildPensionContextLink(
    empId,
    contractId,
    action,
    role,
    redeferralLinkParams
  );
}
