import { PlanStatements } from "../models/planstatements/plan-statements";
import { get } from "../repositories/participant";

export async function getPlanStatements(
  planId: number,
  empId: number,
  role: string
): Promise<PlanStatements> {
  return get(`/plans/${planId}/employees/${empId}/plan-statements`, { role });
}
