import React from "react";
import { useSelector } from "../../store/store";
import { hideAccountNumberSelector } from "../../selectors/plan-info";

type Props = {
  id: number;
  name: string;
  selected: boolean;
  handleSelect: Function;
};

export function AccountOption(props: Props) {
  const { id, name, selected, handleSelect } = props;
  const hideAccountNumber = useSelector(hideAccountNumberSelector);

  function handleKeyDown(e: React.KeyboardEvent<HTMLLIElement>) {
    if (e.key === "Enter" || e.key === " ") {
      /**
       * Click the element instead of calling the onClick
       * so that the Horizon dropdown closes.
       */
      e.currentTarget.click();
    }
  }

  return (
    <li
      key={id}
      onClick={() => handleSelect(id, !selected)}
      onKeyDown={handleKeyDown}
      role="button"
      tabIndex={0}
      data-testid={`option-${id}`}
      className={"selector-object"}
    >
      <span>
        {selected ? (
          <>
            <i
              data-testid={`option-${id}-checked`}
              className="fa fa-check"
              aria-hidden="true"
            />
            <span className="sr-only">Selected</span>
          </>
        ) : (
          <span className={"spacer"} />
        )}
        <span>{name}&nbsp;</span>
      </span>
      {!hideAccountNumber && <span className={"account-id-display"}>{id}</span>}
    </li>
  );
}
