import { html, nothing } from 'lit';
import { property, state, queryAssignedElements } from 'lit/decorators.js';
import { pdsCustomElement as customElement } from '../../decorators/pds-custom-element';
import { PdsElement } from '../PdsElement';
import styles from './toast.scss?inline';
import '@principal/design-system-icons-web/check';
import '@principal/design-system-icons-web/loader';
import '@principal/design-system-icons-web/alert-circle';
import '@principal/design-system-icons-web/info';
import '@principal/design-system-icons-web/x';

export type ToastVariant = 'info' | 'loading' | 'success' | 'error';
export type ToastBackground = 'dark' | 'light';

/**
 * @summary This component displays short messages to the user, typically used to confirm successful or unsuccessful actions.
 * It must be used in conjunction with the `pds-toast-container` component for proper positioning.
 *
 * @slot cta Optional: Call to action link or button, restricted to pds-button or pds-link elements
 * @slot default Required: Body text of the toast
 * @slot icon Optional: An icon, restricted to pds-icon elements
 *
 * @fires pds-toast-dismiss-button-click A custom event dispatched on click of the toast's dismiss button
 * @fires pds-toast-cta-link-click A custom event dispatched on click of a pds-link CTA
 * @fires pds-toast-cta-button-click A custom event dispatched on click of a pds-button CTA
 */
@customElement('pds-toast', {
  category: 'component',
  type: 'component',
  styles,
})
export class PdsToast extends PdsElement {
  /**
   * Style variant
   * - **info** default, renders the toast used for informational messages
   * - **loading** renders the toast used for loading messages
   * - **success** renders the toast used for successful messages
   * - **error** renders the toast used for error messages
   */
  @property()
  variant: ToastVariant = 'info';

  /**
   * Background display mode
   * - **light** Renders a toast in light mode.  Should be used against dark backgrounds.
   * - **dark** Default, renders a toast in dark mode.  Should be used against light backgrounds.
   */
  @property()
  background: ToastBackground = 'dark';

  /**
   * Causes the toast elements to persist on the page until they are manually dismissed. Defaults to false/non-persistent.
   */
  @property({ type: Boolean })
  persistent: boolean = false;

  /**
   * The duration of the toast, defaults to 6000ms
   */
  @property({ type: Number })
  duration: number = 6000;

  /**
   * @internal
   */
  @state()
  visible: boolean = true;

  /**
   * @internal
   */
  @state()
  hidden: boolean = false;

  /**
   * @internal
   */
  @state()
  timeoutId: any;

  /**
   * Toast duration's starting number of milliseconds
   * @internal
   */
  @state()
  startTime: number;

  /**
   * Toast duration's remaining number of milliseconds before it closes
   * @internal
   */
  @state()
  remainingTime: number;

  /**
   * @internal
   */
  @queryAssignedElements({ slot: 'cta' })
  callToAction: HTMLElement[];

  /**
   * @internal
   *
   * @returns Icon for the toast
   */
  getIcon() {
    switch (this.variant) {
      case 'success':
        return html`<pds-icon-check size="md"></pds-icon-check>`;
      case 'loading':
        return html`<pds-icon-loader size="md"></pds-icon-loader>`;
      case 'error':
        return html`<pds-icon-alert-circle size="md"></pds-icon-alert-circle>`;
      default:
        return html`<pds-icon-info size="md"></pds-icon-info>`;
    }
  }

  /**
   * @internal
   *
   * @returns Close button markup for dismissable alerts
   */
  getCloseButtonMarkup() {
    if (this.variant === 'loading') {
      return nothing;
    }
    return html`
      <button
        class=${this.background === 'dark'
          ? this.classEl('close-button-dark')
          : this.classEl('close-button')}
        aria-label=${this.translateText('close')}
        @click=${() => {
          this.hideToast();
          this.dispatchEvent(
            new CustomEvent('pds-toast-dismiss-button-click', {
              detail: { message: 'Dismiss button clicked' },
              bubbles: true,
            }),
          );
        }}
      >
        <pds-icon-x
          size="md"
          class=${this.classEl('close-button-x-icon')}
        ></pds-icon-x>
      </button>
    `;
  }

  /**
   * @internal
   *
   * @returns decorator accent for toasts
   */
  getDecorator() {
    switch (this.variant) {
      case 'loading':
        return nothing;
      default:
        return html`<div class=${this.classEl('decorator')}></div>`;
    }
  }

  /**
   * @internal
   *
   * Starts the timer to show the toast for the given duration
   */
  startTimeout() {
    this.remainingTime = this.duration;
    this.startTime = Date.now();
    this.timeoutId = setTimeout(() => {
      this.hideToast();
    }, this.remainingTime);
  }

  /**
   * @internal
   *
   * Shows the toast. If the variant is not persistent, the startTimeout() will be called to start the timer
   */
  showToast() {
    if (!this.persistent && !this.timeoutId) {
      this.startTimeout();
    }
  }

  /**
   * @internal
   *
   * Hides the toast
   */
  hideToast() {
    this.hidden = true;
    this.visible = false;

    if (
      window &&
      window.matchMedia('(prefers-reduced-motion: reduce)').matches
    ) {
      this.remove();
    }
  }

  /**
   * @internal
   */
  get classNames() {
    return {
      [this.variant]: !!this.variant,
      [this.background]: !!this.background,
      'is-hidden': this.hidden,
      'is-visible': this.visible,
    };
  }

  /**
   * @internal
   *
   * Fire a custom event for CTA clicks
   */
  handleCtaClick(e: Event) {
    e.stopPropagation();
    const customEvent = e as CustomEvent;

    // CTA element can only be a pds-link or pds-button
    const ctaElement = e.target as HTMLElement;
    const element = ctaElement.tagName === 'PDS-LINK' ? 'link' : 'button';

    this.dispatchEvent(
      new CustomEvent(`pds-toast-cta-${element}-click`, {
        bubbles: true,
        composed: true,
        detail: customEvent.detail,
      }),
    );
  }

  onAnimationEnd() {
    if (this.hidden) {
      this.remove();
    }
  }

  override firstUpdated() {
    super.firstUpdated();

    if (this.duration < 6000) {
      console.warn(
        'Warning: For readability purposes, pds-toast duration should be at least 6000ms.',
        this,
      );
    }

    this.showToast();

    // event listeners for mouseover and mouseout to change toast to be persistent for non-persistent, non-loading toasts
    if (this.persistent !== true && this.variant !== 'loading') {
      this.addEventListener('mouseover', () => {
        clearTimeout(this.timeoutId);
        this.persistent = true;
      });

      this.addEventListener('touch', () => {
        clearTimeout(this.timeoutId);
        this.persistent = true;
      });
    }

    this.shadowRoot
      ?.querySelector('.pds-c-toast')
      ?.addEventListener('animationend', this.onAnimationEnd);
  }

  connectedCallback() {
    super.connectedCallback();
    this.initLocalization();
    this.handleCtaClick = this.handleCtaClick.bind(this);
    this.onAnimationEnd = this.onAnimationEnd.bind(this);

    // Catch the pds-link-click event on a slotted CTA link and dispatch a toast-specific custom event
    this.addEventListener('pds-link-click', this.handleCtaClick);

    // Catch the pds-button-click event on a slotted CTA button and dispatch a toast-specific custom event
    this.addEventListener('pds-button-click', this.handleCtaClick);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.removeEventListener('pds-link-click', this.handleCtaClick);
    this.removeEventListener('pds-button-click', this.handleCtaClick);
    this.removeEventListener('animationend', this.onAnimationEnd);
  }

  render() {
    const darkSuffix = this.background === 'dark' ? '-dark' : '';

    return html`<div
      class=${this.getClass()}
      ?persistent=${this.persistent}
      duration=${this.duration}
      background=${this.background}
      variant=${this.variant}
    >
      <div class=${this.classEl(`toast-body${darkSuffix}`)}>
        <div class=${this.classEl('toast-content')}>
          <div class=${this.classEl(`icon${darkSuffix}`)}>
            <slot name="icon">${this.getIcon()}</slot>
          </div>
          <slot></slot>
        </div>
        ${this.slotNotEmpty('cta')
          ? html`<div class=${this.classEl(`cta-wrapper${darkSuffix}`)}>
              <slot
                name="cta"
                allowed-elements="pds-button, pds-link"
                @slotchange=${this.handleSlotValidation}
              ></slot>
            </div>`
          : nothing}
        ${this.persistent ? this.getCloseButtonMarkup() : nothing}
        ${this.getDecorator()}
      </div>
    </div>`;
  }
}
