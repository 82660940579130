import axios, { ResponseType } from "axios";
import "core-js/es/object/from-entries";
import { apiHost } from "../util/constants";

function buildUrl(path: string) {
  const formattedPath = path.charAt(0) === "/" ? path : `/${path}`;
  return `${apiHost}/rnq/services/enhanced${formattedPath}`;
}

type GetParams = {
  role?: string;
  planId?: number;
  empId?: number;
  messageTypes?: string;
  filter?: string;
};

export async function get(
  path: string,
  params: GetParams = {},
  responseType?: ResponseType
): Promise<any> {
  const apiToken = localStorage.getItem("accessToken");

  const { data } = await axios.get(buildUrl(path), {
    ...(responseType && { responseType: responseType }),
    headers: {
      authorization: `Bearer ${apiToken}`,
    },
    params,
  });
  return data;
}

export async function post(
  path: string,
  params: GetParams = {},
  body?: any,
  responseType?: ResponseType
): Promise<any> {
  const apiToken = localStorage.getItem("accessToken");

  const { data } = await axios.post(buildUrl(path), body, {
    ...(responseType && { responseType: responseType }),
    headers: {
      authorization: `Bearer ${apiToken}`,
    },
    params,
  });
  return data;
}
