import { Message } from "../../models/message";
import { useSelector } from "../../store/store";
import React from "react";
import { Collapse } from "../collapse/collapse";
import {
  buildContributionsSummaryLink,
  buildManageContributionsLink,
} from "../../util/nq-participant-link-builder";

type Props = {
  message: Message;
  index: number;
};

export function MessageCard(props: Props) {
  const { session } = useSelector((state) => state);
  const { message, index } = props;
  return (
    <div
      className={`row message-${index}`}
      key={index}
      data-testid={`message-card-${index}`}
    >
      <div className="col-md-12">
        <div className="panel panel-default">
          {renderMessage(message, session)}
        </div>
      </div>
    </div>
  );
}

export function getUrl(message: Message, session: any) {
  if (message.messageName === "DEFERRAL_CHANGE") {
    return buildManageContributionsLink(session);
  } else if (message.messageName === "DEFERRAL_PENDING") {
    return buildContributionsSummaryLink(session);
  } else {
    return message.messageAction.url;
  }
}

function renderMessage(message: Message, empInfo: any) {
  return (
    <>
      {message.header && (
        <div
          className="panel-heading-dashboard text-midnight"
          data-testid="message-card-header"
          dangerouslySetInnerHTML={{ __html: message.header }}
        ></div>
      )}

      <div className="panel-body">
        {message.subHeader && (
          <div
            data-testid="message-card-subhead"
            dangerouslySetInnerHTML={{ __html: message.subHeader }}
          />
        )}
        {message.description && (
          <div
            data-testid="message-card-description"
            dangerouslySetInnerHTML={{ __html: message.description }}
          />
        )}
        <div data-testid="message-card-text">
          {message.messageAction && (
            <a href={getUrl(message, empInfo)} data-testid={"message-action"}>
              {message.messageAction.name}{" "}
              <i className="fa fa-angle-right" aria-hidden="true"></i>
            </a>
          )}
          {message.longText && <Collapse text={message.longText} />}
        </div>
      </div>
    </>
  );
}
