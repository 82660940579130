import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AssetClassModal from "../../../components/asset-class-modal/asset-class-modal";
import { Page, PageHeader } from "../../../components/page";
import { InvestmentV2 } from "../../../models/investment-v2";
import { getIndividualInvestment } from "../../../services/investment-data";
import { useSelector } from "../../../store/store";
import { IndividualInvestmentDisplay } from "./individual-investment-display";
import "./individual-investment.scss";

export default function IndividualInvestment() {
  const { planId, empId, role } = useSelector((state) => state.session);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [investment, setInvestment] = useState<InvestmentV2>();
  const { investmentId } = useParams();

  useEffect(() => {
    fetchIndividualInvestmentsData();

    async function fetchIndividualInvestmentsData() {
      try {
        const data: InvestmentV2 = await getIndividualInvestment({
          planId,
          empId,
          role,
          investmentId,
        });
        setInvestment(data);
        setLoading(false);
      } catch (e) {
        setError(true);
      }
    }
  }, [planId, empId, role, investmentId]);

  return (
    <Page
      loading={loading}
      name="individual-investment"
      className="individual-investment"
    >
      <PageHeader name="individual-investment">
        Investment Performance: Individual Values
      </PageHeader>
      {investment && <IndividualInvestmentDisplay investment={investment} />}
      <div style={{ float: "left" }}>
        <span>Click here to </span>
        <AssetClassModal />
      </div>
    </Page>
  );
}
