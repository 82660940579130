import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { Page, PageHeader } from "../../components/page";
import {
  participantDetailsStatus,
  privilegesStatus,
  sessionStatusSelector,
  useStatuses,
} from "../../selectors/status";
import { useAppDispatch, useSelector } from "../../store/store";
import "./personal-details.scss";
import PersonalInfoTable from "./personal-info-table";
import {
  hidePersonalInformationSelector,
  restrictActiveParticipantAddressSelector,
} from "../../selectors/plan-info";
import PaymentInfo from "./payment-info";
import {
  getBankAccountTypeValues,
  getBankStateValues,
} from "../../services/salesforce.service";
import { AsyncStatus } from "../../models/async-status";
import { fetchPlanDetails } from "../../reducers/plan/plan-details";

const name = "personal-details";

function PersonalDetails() {
  const { planId, empId, role } = useSelector((state) => state.session);
  const { advisorAccessToPlanConfidential } = useSelector(
    (state) => state.privileges.data
  );
  const dispatch = useAppDispatch();
  const session = useSelector((state) => state.session);
  const [loadingState, setLoadingState] = useState(true);
  const { isLoadingStatus } = useStatuses([
    participantDetailsStatus,
    sessionStatusSelector,
    privilegesStatus,
  ]);
  const hidePersonalInformation = useSelector(hidePersonalInformationSelector);
  const restrictActiveParticipantAddress = useSelector(
    restrictActiveParticipantAddressSelector
  );
  const personalDetails = useSelector((state) => state.participant.data);
  const shouldHidePersonalInformation =
    (restrictActiveParticipantAddress && personalDetails.isEmployed) ||
    hidePersonalInformation;
  const [errorState, setErrorState] = useState({
    accountTypeError: false,
    bankStateError: false,
    getError: false,
    postError: false,
    achError: false,
  });
  const [bankStates, setBankStates] = useState([
    {
      active: true,
      defaultValue: false,
      label: "Not Applicable",
      value: "Not Applicable",
    },
  ]);
  const [bankAccountTypes, setBankAccountTypes] = useState([
    {
      active: true,
      defaultValue: false,
      label: "Select account type",
      value: "Select account type",
    },
  ]);

  useEffect(() => {
    if (session.status === AsyncStatus.done) {
      initializeLoadingStates();
    }
    dispatch(fetchPlanDetails({ planId, empId, role }));
    async function initializeLoadingStates() {
      await Promise.all([fetchBankStates(), fetchBankAccountTypes()]);
      setLoadingState(false);
    }

    async function fetchBankStates() {
      try {
        const data = await getBankStateValues();
        setBankStates(data);
      } catch (e) {
        setErrorState({ ...errorState, bankStateError: true });
      }
    }

    async function fetchBankAccountTypes() {
      try {
        const data = await getBankAccountTypeValues();
        setBankAccountTypes(data);
      } catch (e) {
        setErrorState({ ...errorState, accountTypeError: true });
      }
    }
  }, [session, session.status]); //eslint-disable-line react-hooks/exhaustive-deps

  if (session.isAdvisor && !advisorAccessToPlanConfidential) {
    return (
      <Redirect
        to={{
          pathname: "/overview/dashboard",
          state: {
            planId: { planId },
            empId: { empId },
            role: "ADVISOR",
            parentEid: "",
          },
        }}
      />
    );
  } else {
    return (
      <Page
        name={name}
        loading={isLoadingStatus}
        className={"personal-details"}
      >
        <PageHeader name={name}>Personal Details</PageHeader>
        {shouldHidePersonalInformation && (
          <div className="alert alert-info">
            <span>
              Please contact your employer if you need to make changes to your
              contact information.
            </span>
          </div>
        )}
        {!shouldHidePersonalInformation && (
          <div className="row personal-details">
            <div className="col-xs-12">
              <PersonalInfoTable />
            </div>
          </div>
        )}
        <PaymentInfo
          bankStates={bankStates}
          bankAccountTypes={bankAccountTypes}
        />
      </Page>
    );
  }
}

export default PersonalDetails;
