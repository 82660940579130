import React from "react";
import { RedeferralStatus } from "../../../models/redeferral/redeferral-status";
import {
  addYears,
  formatDateWithoutTimeZone,
  parseDate,
} from "../../../util/date-util";
import { Modal } from "../../../components/modal/modal";

interface Props {
  newDate: string | null | undefined;
  newMethod: string | null | undefined;
  newName: string | undefined;
  effectiveDate?: string | undefined;
  accountRedeferralStatus: RedeferralStatus;
  isConfirm?: boolean;
}

export default function RedeferChangeDisplay(props: Props) {
  let { newDate } = props;
  const {
    newMethod,
    newName,
    effectiveDate,
    accountRedeferralStatus,
    isConfirm,
  } = props;

  if (!accountRedeferralStatus.planAllowsTimeChangeRedeferral) {
    let parsedDate = parseDate(
      accountRedeferralStatus.scheduledDistributionPayoutStartDate
    );
    parsedDate = addYears(parsedDate, 5);
    newDate = formatDateWithoutTimeZone(parsedDate, "MM/dd/yyyy");
  }

  const dateChanged =
    newDate &&
    accountRedeferralStatus.scheduledDistributionPayoutStartDate !== newDate;
  const methodChanged =
    newMethod &&
    accountRedeferralStatus.scheduledDistributionMethod !== newMethod;

  return (
    <>
      <div className="util-margin-top-15">
        <table data-testid="personal-info-table" className="table">
          <tbody>
            <tr style={{ background: "#caebfa", fontWeight: "bold" }}>
              <td colSpan={2} className="table-head">
                Distribution Election(s)
              </td>
              <td colSpan={2} className="table-head">
                Current Distribution Election(s)
              </td>
              <td colSpan={2} className="table-head">
                New Distribution Election(s)
              </td>
            </tr>
            <tr>
              <td
                colSpan={2}
                className="table-head"
                style={{ fontWeight: "bold" }}
              >
                Account Name
              </td>
              <td colSpan={2} className="table-head">
                {accountRedeferralStatus.accountName}
              </td>
              <td colSpan={2} className="table-head">
                {newName}
              </td>
            </tr>
            <tr style={{ background: "#f1f1f1" }}>
              <td
                colSpan={2}
                className="table-head"
                style={{ fontWeight: "bold" }}
              >
                Distribution Date
              </td>
              <td colSpan={2} className="table-head">
                {accountRedeferralStatus.scheduledDistributionPayoutStartDate}
              </td>
              <td colSpan={2} className="table-head">
                {!dateChanged && (
                  <>
                    {
                      accountRedeferralStatus.scheduledDistributionPayoutStartDate
                    }
                  </>
                )}
                {dateChanged && <>{newDate}</>}
              </td>
            </tr>
            <tr>
              <td
                colSpan={2}
                className="table-head"
                style={{ fontWeight: "bold" }}
              >
                Distribution Method
              </td>
              <td colSpan={2} className="table-head">
                {accountRedeferralStatus.scheduledDistributionMethod}
              </td>
              <td colSpan={2} className="table-head">
                {!methodChanged && (
                  <>{accountRedeferralStatus.scheduledDistributionMethod}</>
                )}
                {methodChanged && <>{newMethod}</>}
              </td>
            </tr>
            {isConfirm && (
              <tr>
                <td colSpan={2}></td>
                <td colSpan={2}></td>
                <td
                  colSpan={2}
                  className="table-head"
                  style={{ fontWeight: "bold" }}
                >
                  Effective {effectiveDate}
                  <Modal title="Effective Date Info">
                    <div id="info" className="info">
                      <p>
                        Your election will become effective on this date,{" "}
                        {effectiveDate}, due to plan requirements based upon
                        your election.
                      </p>
                    </div>
                  </Modal>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}
