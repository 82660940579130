import React, { useEffect, useState } from "react";
import {
  hideAccountsTabSelector,
  hidePerformanceTabSelector,
} from "../../../selectors/plan-info";
import { investmentsStatusSelector } from "../../../selectors/status";
import { useSelector } from "../../../store/store";
import { Panel, PanelBody } from "../../panel/panel";
import { Skeleton } from "../../skeleton/skeleton";
import { DashboardAccountTable } from "../dashboard-account-table/dashboard-account-table";
import DashboardTotalAccountBalance from "../dashboard-total-account-balance/dashboard-total-account-balance";
import { DashboardTab } from "./dashboard-tab";
import "./dashboard-tabbed-view.scss";
import { DashboardTabs } from "./dashboard-tabs";

export interface Props {
  setShouldUpdateTour: Function;
}

export function DashboardTabbedView(props: Props) {
  const { setShouldUpdateTour } = props;
  const [selectedTab, setSelectedTab] = useState(DashboardTabs.PERFORMANCE);
  const hidePerformanceTab = useSelector(hidePerformanceTabSelector);
  const hideAccountsTab = useSelector(hideAccountsTabSelector);

  useEffect(() => {
    if (hidePerformanceTab) {
      setSelectedTab(DashboardTabs.ACCOUNTS);
    }
  }, [hidePerformanceTab]);

  const tabs = {
    [DashboardTabs.PERFORMANCE]: {
      Component: DashboardTotalAccountBalance,
      iconClass: "fa-line-chart",
    },
    [DashboardTabs.ACCOUNTS]: {
      Component: DashboardAccountTable,
      iconClass: "fa-list-ul",
    },
  };

  const SelectedTab = tabs[selectedTab].Component;

  function handleTabArrow(event: React.KeyboardEvent<HTMLDivElement>) {
    const { key } = event;
    if (key !== "ArrowRight" && key !== "ArrowLeft") {
      return;
    }
    const tabNames = Object.keys(tabs) as DashboardTabs[];
    const currentTabIndex = tabNames.indexOf(selectedTab);

    // determine which tab to switch to, looping around if needed
    let newTabIndex = 0;
    if (key === "ArrowRight") {
      newTabIndex = currentTabIndex + 1;
      if (newTabIndex > tabNames.length - 1) {
        newTabIndex = 0;
      }
    } else if (key === "ArrowLeft") {
      newTabIndex = currentTabIndex - 1;
      if (newTabIndex < 0) {
        newTabIndex = tabNames.length - 1;
      }
    }
    const newTabName = tabNames[newTabIndex];

    setSelectedTab(newTabName);
    const newTab = event.currentTarget.querySelector<HTMLButtonElement>(
      `#dashboard-tab-${newTabName}`
    );
    newTab?.focus();
  }

  return (
    <div id="dashboard-tabs">
      <Panel>
        <PanelBody>
          <div
            className="col-sm-12 text-center dashboard-controls"
            role="tablist"
            aria-label="Dashboard views"
            onKeyDown={handleTabArrow}
          >
            {Object.entries(tabs).map(([name, { iconClass }]) => {
              return (
                <>
                  {((hidePerformanceTab && name === "Accounts") ||
                    (hideAccountsTab && name === "Performance") ||
                    (!hidePerformanceTab && !hideAccountsTab)) && (
                    <DashboardTab
                      key={name}
                      tabName={name}
                      setSelectedTab={setSelectedTab}
                      setShouldUpdateTour={setShouldUpdateTour}
                      selectedTab={selectedTab}
                      iconClass={iconClass}
                    />
                  )}
                </>
              );
            })}
          </div>
        </PanelBody>
        <PanelBody>
          <div
            role="tabpanel"
            tabIndex={0}
            aria-labelledby={`dashboard-tab-${selectedTab}`}
          >
            <Skeleton selectors={[investmentsStatusSelector]} height={300}>
              <SelectedTab />
            </Skeleton>
          </div>
        </PanelBody>
      </Panel>
    </div>
  );
}
