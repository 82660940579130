"use client";
import SkipLink from "../skip-link/skip-link";
import "@principal/design-system/pds-styles.min.css";
import "@principal/design-system/pds-styles-csr.min.css";
import { GlobalHeader } from "@principal/individual-header-component";
import "./site-nav.scss";
import { isPilotOrTest } from "../../util/constants";
import { useLocalStorage } from "react-use";
import { Logger } from "../../Logger";

export default function SiteNav() {
  const [oktaToken] = useLocalStorage("oktaToken", "", { raw: true });
  const [enterpriseId] = useLocalStorage("enterpriseId", "", { raw: true });
  const logger = new Logger();

  logger.info("testing logger", oktaToken);

  return (
    <>
      <SkipLink />
      <div id="pds-global-header">
        <GlobalHeader
          eid={enterpriseId}
          getAccessToken={() => oktaToken}
          logger={logger}
          env={isPilotOrTest ? "staging" : "prod"}
          enablePlanningAndGuidance={true}
        />
      </div>
    </>
  );
}
