import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { logActivity } from "../services/user-activity";
import { isUserActivityLoggingEnabled } from "../selectors/feature-flags";
import { useSelector } from "react-redux";

export default function LogUserActivity(): null {
  const { pathname } = useLocation();
  const isUserLoggingEnabled = useSelector(isUserActivityLoggingEnabled);

  useEffect(() => {
    if (isUserLoggingEnabled) {
      logActivity();
    }
  }, [pathname, isUserLoggingEnabled]);

  return null;
}
