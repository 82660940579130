import { html } from 'lit';
import { property, query } from 'lit/decorators.js';
import sanitizeHTML from 'sanitize-html';
import { pdsCustomElement as customElement } from '../../decorators/pds-custom-element';
import { PdsElement } from '../PdsElement';
import { PdsToast, ToastBackground, ToastVariant } from '../toast/toast';
import type { PdsButton } from '../button/button';
import type { PdsLink } from '../link/link';
import styles from './toast-container.scss?inline';
import '@principal/design-system-icons-web/check';
import '@principal/design-system-icons-web/loader';
import '@principal/design-system-icons-web/alert-circle';
import '@principal/design-system-icons-web/info';
import '@principal/design-system-icons-web/x';

interface CtaOptions {
  type: 'link' | 'button';
  text: string;
  ctaClickHandler?: () => void;
  attributes?: { [key: string]: any };
}

export interface ToastOptions {
  variant?: ToastVariant;
  background?: ToastBackground;
  text: string;
  duration?: number;
  persistent?: boolean;
  cta?: CtaOptions;
  dismissHandler?: () => void;
}

/**
 * @summary Container which appends toasts to the viewport and handles the aria role for announcing them.
 * This must be used in conjunction with the `pds-toast` component.
 */
@customElement('pds-toast-container', {
  category: 'component',
  type: 'component',
  styles,
})
export class PdsToastContainer extends PdsElement {
  /**
   * Toast container positions
   * - **top-left** default, renders toasts at the top-left
   * - **top-center** renders toasts at the top-center
   * - **top-right** renders toasts at the top-right
   * - **bottom-left** renders toasts at the bottom-right
   * - **bottom-center** renders toasts at the bottom-center
   * - **bottom-right** renders toasts at the bottom-right
   */
  @property()
  position:
    | 'top-left'
    | 'top-center'
    | 'top-right'
    | 'bottom-left'
    | 'bottom-center'
    | 'bottom-right' = 'top-left';

  @property()
  ariaRole: 'status' | 'alert' | 'log' = 'status';

  /**
   * @internal
   */
  @query('.pds-c-toast-container')
  container: HTMLElement;

  /**
   * Creates a Toast element based on configuration object and adds it to a positioning container.
   * Should be called by the consuming application.
   */
  addToast(options: ToastOptions) {
    const toast = document.createElement('pds-toast') as PdsToast;
    const {
      variant,
      background,
      text,
      duration,
      persistent,
      cta,
      dismissHandler,
    } = options;

    toast.innerHTML = sanitizeHTML(text);
    toast.variant = variant || 'info';
    toast.background = background || 'dark';
    toast.duration = duration || 6000;
    toast.persistent = persistent || false;

    if (dismissHandler) {
      toast.addEventListener('pds-toast-dismiss-button-click', dismissHandler);
    }

    if (cta) {
      const isLight = background && background === 'light';
      let ctaEl: PdsButton | PdsLink | null = null;

      if (cta.type === 'button') {
        ctaEl = document.createElement('pds-button') as PdsButton;
        ctaEl.setAttribute('link', isLight ? 'emphasis' : 'emphasis-inverted');
        ctaEl.setAttribute('removeLinkPadding', '');

        if (cta && cta.ctaClickHandler) {
          toast.addEventListener(
            'pds-toast-cta-button-click',
            cta.ctaClickHandler,
          );
        }
      } else if (cta.type === 'link') {
        ctaEl = document.createElement('pds-link') as PdsLink;
        ctaEl.setAttribute(
          'variant',
          isLight ? 'emphasis' : 'emphasis-inverted',
        );

        if (cta && cta.ctaClickHandler) {
          toast.addEventListener(
            'pds-toast-cta-link-click',
            cta.ctaClickHandler,
          );
        }
      }

      if (ctaEl) {
        ctaEl.setAttribute('slot', 'cta');

        if (cta.attributes) {
          Object.entries(cta.attributes).forEach(([key, value]) => {
            ctaEl?.setAttribute(key, value);
          });
        }

        ctaEl.innerHTML = sanitizeHTML(cta.text);
        toast.appendChild(ctaEl);
      }
    }

    if (this.container) {
      this.container.appendChild(toast);
    }
  }

  /**
   * @internal
   */
  get classNames() {
    return {
      [this.position]: !!this.position,
    };
  }

  render() {
    return html`<div class=${this.getClass()} role=${this.ariaRole}></div>`;
  }
}
