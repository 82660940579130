import React from "react";
import { OnlineBeneficiaryPlan } from "../../../../models/beneficiaries/beneficiary";
import { BeneficiaryPlanProps } from "../beneficiary-plans";
import { BeneficiaryDisclaimerText } from "../../../../models/beneficiaries/beneficiary-text";

export function AdditionalPlanSelect(props: BeneficiaryPlanProps) {
  const { plans, register, watch } = props;
  return (
    <>
      <PlanSelectionDisclaimer />
      {plans.map((plan) => {
        const field = watch(`${plan.id}`, true);
        return <PlanCheckBox register={register} plan={plan} field={field} />;
      })}
    </>
  );
}

type PlanCheckBoxProps = {
  plan: OnlineBeneficiaryPlan;
  register: any;
  field: any;
};

function PlanSelectionDisclaimer() {
  return (
    <>
      <p>
        {BeneficiaryDisclaimerText.PLAN_SELECTION_OTHER_PLANS}
        <strong>
          {BeneficiaryDisclaimerText.PLAN_SELECTION_OVERWRITE_WARNING}
        </strong>
      </p>
    </>
  );
}

function PlanCheckBox(props: PlanCheckBoxProps) {
  const { plan, register } = props;
  const { id, name } = plan;

  return (
    <div className="form-group" key={id}>
      <div className={"plan-checkbox"}>
        <label>
          <input
            name={`${id}`}
            type="checkbox"
            ref={register}
            data-testid={name}
          />
          &nbsp; {name}
        </label>
      </div>
    </div>
  );
}
