import axios from "axios";
import { apiHost } from "../util/constants";

function buildUrl(path: string) {
  const formattedPath = path.charAt(0) === "/" ? path : `/${path}`;
  return `${apiHost}/rnq/services/enrollment${formattedPath}`;
}

export async function get(path: string, params?: object): Promise<any> {
  const apiToken = localStorage.getItem("accessToken");
  const url = buildUrl(path);
  const { data } = await axios.get(url, {
    headers: {
      authorization: `Bearer ${apiToken}`,
    },
    params,
  });

  return data;
}
