import React, { useEffect, useState } from "react";
import { RegisterOptions } from "react-hook-form";
import { FormInput } from "../../../components/form/form-input";
import CountrySelect from "../../../components/select-inputs/country-select";
import StateSelect from "../../../components/select-inputs/state-select";
import { Address } from "../../../models/Employee";
import { BeneficiaryState } from "../../../reducers/beneficiary-reducer";
import { useSelector } from "../../../store/store";
import { ModalAction } from "./beneficiary-modal";
import { BeneficiaryModalValidationText } from "../../../models/beneficiaries/beneficiary-text";

type Props = {
  register: (options: RegisterOptions) => React.Ref<any>;
  watch: any;
  dirtyFields: any;
  errors: any;
  setValue: any;
  action: ModalAction;
};

export default function BeneficiaryDetails(props: Props) {
  const [enterNewAddress, setEnterNewAddress] = useState(true);
  const { register, dirtyFields, watch, errors, setValue, action } = props;
  const address: Address = useSelector(
    (state) => state.participant.data.address
  );
  const { editing }: BeneficiaryState = useSelector(
    (state) => state.beneficiaries
  );

  const selectedCountryIsUnitedStates = watch("address.country") === "US";

  useEffect(() => {
    if (selectedCountryIsUnitedStates && !enterNewAddress) {
      const city = address.city;
      const state = address.state ? address.state : "";
      const zip = address.zip;

      setValue("address.city", city);
      setValue("address.state", state);
      setValue("address.zip", zip);
    }
  }, [selectedCountryIsUnitedStates, address, enterNewAddress, setValue]);

  function updateFormAddress(setNewAddress: boolean) {
    setEnterNewAddress(setNewAddress);
    let addressLine1 = "";
    let addressLine2 = "";
    let addressLine3 = "";
    let addressLine4 = "";
    let addressLine5 = "";
    let addressLine6 = "";
    let country = "";
    let city = "";
    let state = "";
    let zip = "";
    if (!setNewAddress) {
      addressLine1 = address.addressLine1;
      addressLine2 = address.addressLine2 ? address.addressLine2 : "";
      addressLine3 = address.addressLine3 ? address.addressLine3 : "";
      addressLine4 = address.addressLine4 ? address.addressLine4 : "";
      addressLine5 = address.addressLine5 ? address.addressLine5 : "";
      addressLine6 = address.addressLine6 ? address.addressLine6 : "";
      country = address.country;
      city = address.city;
      state = address.state ? address.state : "";
      zip = address.zip;
    }
    setValue("address.addressLine1", addressLine1);
    setValue("address.addressLine2", addressLine2);
    setValue("address.addressLine3", addressLine3);
    setValue("address.addressLine4", addressLine4);
    setValue("address.addressLine5", addressLine5);
    setValue("address.addressLine6", addressLine6);
    setValue("address.country", country);
    setValue("address.city", city);
    setValue("address.state", state);
    setValue("address.zip", zip);
  }

  const addingOrEditingBeneficiary = editing || action === ModalAction.ADD;

  return (
    <>
      {" "}
      <div className="row">
        <div className="col-md-12">
          <FormInput
            readOnly={!addingOrEditingBeneficiary}
            register={register}
            name={"name"}
            text={"Full name"}
            maxLength={250}
            options={{
              required: BeneficiaryModalValidationText.ENTER_A_NAME,
              maxLength: {
                value: 250,
                message: "Your name cannot exceed 250 characters.",
              },
            }}
            hasError={errors.name}
            errors={errors}
          />
        </div>
      </div>
      {addingOrEditingBeneficiary && (
        <>
          <div>
            <label className="radio-label">
              <input
                data-testid="enter-address"
                name="enterNewAddress"
                type="radio"
                checked={enterNewAddress}
                onChange={() => updateFormAddress(true)}
              />
              &nbsp;Enter an address
            </label>
          </div>
          <div>
            <label className="radio-label">
              <input
                data-testid="autofill-address"
                name="enterNewAddress"
                type="radio"
                checked={!enterNewAddress}
                onChange={() => updateFormAddress(false)}
              />
              &nbsp;Auto-fill with my address on file
            </label>
          </div>
        </>
      )}
      {addingOrEditingBeneficiary ? (
        <CountrySelect register={register} errors={errors} />
      ) : (
        <FormInput
          readOnly
          register={register}
          name="address.country"
          text="Country"
          errors={errors}
        />
      )}
      <FormInput
        maxLength={50}
        readOnly={!addingOrEditingBeneficiary}
        register={register}
        name={"address.addressLine1"}
        text={"Address line 1"}
        options={{
          maxLength: {
            value: 50,
            message: "Address lines cannot exceed 50 characters",
          },
        }}
        hasError={errors.address?.addressLine1}
        errors={errors}
      />
      <FormInput
        maxLength={50}
        readOnly={!addingOrEditingBeneficiary}
        register={register}
        name={"address.addressLine2"}
        text={"Address line 2"}
        hasError={errors.address?.addressLine2}
        errors={errors}
        options={{
          maxLength: {
            value: 50,
            message: "Address lines cannot exceed 50 characters",
          },
        }}
      />
      <FormInput
        maxLength={50}
        readOnly={!addingOrEditingBeneficiary}
        register={register}
        name={"address.addressLine3"}
        text={"Address line 3"}
        hasError={errors.address?.addressLine3}
        options={{
          maxLength: {
            value: 50,
            message: "Address lines cannot exceed 50 characters",
          },
        }}
        errors={errors}
      />
      <FormInput
        maxLength={50}
        readOnly={!addingOrEditingBeneficiary}
        register={register}
        name={"address.addressLine4"}
        text={"Address line 4"}
        hasError={errors.address?.addressLine4}
        options={{
          maxLength: {
            value: 50,
            message: "Address lines cannot exceed 50 characters",
          },
        }}
        errors={errors}
      />
      <FormInput
        maxLength={50}
        readOnly={!addingOrEditingBeneficiary}
        register={register}
        name={"address.addressLine5"}
        text={"Address line 5"}
        hasError={errors.address?.addressLine5}
        options={{
          maxLength: {
            value: 50,
            message: "Address lines cannot exceed 50 characters",
          },
        }}
        errors={errors}
      />
      <FormInput
        maxLength={50}
        readOnly={!addingOrEditingBeneficiary}
        register={register}
        name={"address.addressLine6"}
        text={"Address line 6"}
        hasError={errors.address?.addressLine6}
        options={{
          maxLength: {
            value: 50,
            message: "Address lines cannot exceed 50 characters",
          },
        }}
        errors={errors}
      />
      {selectedCountryIsUnitedStates && (
        <div className="row">
          <div className="col-md-4">
            <FormInput
              maxLength={30}
              readOnly={!addingOrEditingBeneficiary}
              register={register}
              name={"address.city"}
              text={"City"}
              options={{
                maxLength: {
                  value: 30,
                  message: "City name cannot exceed 30 characters",
                },
              }}
              hasError={errors.address?.city}
              errors={errors}
            />
          </div>
          <div className="col-md-4">
            {addingOrEditingBeneficiary ? (
              <StateSelect register={register} errors={errors} />
            ) : (
              <FormInput
                readOnly
                register={register}
                name={"address.state"}
                text={"State"}
                hasError={errors.address?.city}
                errors={errors}
              />
            )}
          </div>
          <div className="col-md-4">
            <FormInput
              readOnly={!addingOrEditingBeneficiary}
              register={register}
              name={"address.zip"}
              text={"ZIP code"}
              aria-describedby="postalCodeError"
              options={{}}
              hasError={errors.address?.zip}
              errors={errors}
            />
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-md-6">
          <FormInput
            readOnly={!addingOrEditingBeneficiary}
            register={register}
            name={"ssn"}
            errorMessage={"SsnTinBox"}
            pattern={"^[0-9]{0,9}$"}
            text={"Social security number (SSN)"}
            disabled={dirtyFields.tin}
            errors={errors}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <FormInput
            readOnly={!addingOrEditingBeneficiary}
            register={register}
            name={"tin"}
            pattern={"^[0-9]{0,9}$"}
            errorMessage={"SsnTinBox"}
            text={"Tax identification number (TIN)"}
            disabled={dirtyFields.ssn}
            errors={errors}
          />
        </div>
      </div>
    </>
  );
}
