import { Account } from "../../../../models/account";

export function filterAccountsByActiveRedeferral(
  accounts: Account[],
  accountIdsWithActiveRedeferrals: number[]
) {
  return accounts.filter((account) =>
    accountIdsWithActiveRedeferrals.includes(account.id)
  );
}
