import { createSelector } from "@reduxjs/toolkit";
import { AccountWithScheduledDistribution } from "../models/account";
import { ScheduledDistributionPagePayment } from "../models/distributions/distribution-payment";
import { ScheduledDistributionEvent } from "../models/distributions/scheduled-distribution-event";
import { buildDistributionPayments } from "../pages/distributions/distribution-summary/scheduled-distributions-table/summary-scheduled-distributions-util";
import { RootState, useSelector } from "../store/store";
import { useInvestments } from "./investments";

export const scheduledDistributionsSelector = (state: RootState) =>
  state.distributions.scheduledDistributions.scheduledDistributionEvents;

export const selectedAccountIdsSelector = (state: RootState) =>
  state.accounts.selectedIds;

export const scheduledDistributionsWithAccountsSelector = createSelector(
  scheduledDistributionsSelector,
  selectedAccountIdsSelector,
  (scheduledDistributionEvents, selectedIds) => {
    return scheduledDistributionEvents.filter(
      (scheduledDistribution: ScheduledDistributionEvent) =>
        scheduledDistribution.accountId &&
        selectedIds.includes(scheduledDistribution.accountId)
    );
  }
);

export function useScheduledDistributionsWithAccounts(): ScheduledDistributionEvent[] {
  return useSelector(scheduledDistributionsWithAccountsSelector);
}

export function useAccountsWithScheduledDistribution(): AccountWithScheduledDistribution[] {
  const { scheduledDistributions, scheduledElections } = useSelector(
    (state) => state.distributions
  );
  const { investments } = useInvestments();
  const { available } = useSelector((state) => state.accounts);

  // @ts-ignore
  const formattedDistributionPayments: ScheduledDistributionPagePayment[] =
    buildDistributionPayments(
      scheduledDistributions.scheduledDistributionEvents,
      investments,
      scheduledElections.scheduledElections,
      true
    );

  return available.map((account) => {
    return {
      ...account,
      scheduledDistributions: filterScheduledDistributionsByAccountId(
        account.id,
        formattedDistributionPayments
      ),
    };
  });
}

export function useAccountWithScheduledDistribution(): AccountWithScheduledDistribution {
  const { scheduledDistributions, scheduledElections } = useSelector(
    (state) => state.distributions
  );
  const { investments } = useInvestments();
  const { selected } = useSelector((state) => state.accounts);

  // @ts-ignore
  const formattedDistributions: ScheduledDistributionRedeferPage[] =
    buildDistributionPayments(
      scheduledDistributions.scheduledDistributionEvents,
      investments,
      scheduledElections.scheduledElections,
      false
    );

  if (selected[0]) {
    return {
      ...selected[0],
      scheduledDistributions: filterScheduledDistributionsByAccountId(
        selected[0].id,
        formattedDistributions
      ),
    };
  }
  return {} as AccountWithScheduledDistribution;
}

function filterScheduledDistributionsByAccountId(
  accountId: number,
  scheduledDistributionPagePayments: ScheduledDistributionPagePayment[]
): ScheduledDistributionPagePayment[] {
  return scheduledDistributionPagePayments.filter(
    (scheduledDistributionPagePayment) =>
      scheduledDistributionPagePayment.accountId === accountId
  );
}
