import React from "react";
import { datesStatusSelector, useStatuses } from "../../selectors/status";
import LoadingSpinner from "../loading-spinner/loading-spinner";
import { EndDate } from "../numbers/end-date";
import { StartDate } from "../numbers/start-date";

function DateDisplay() {
  const { isLoadingStatus } = useStatuses([datesStatusSelector]);
  if (isLoadingStatus) {
    return <LoadingSpinner />;
  }

  return (
    <div className="placeholder">
      <StartDate /> through <EndDate />
    </div>
  );
}

export default DateDisplay;
