import _round from "lodash/round";
import _sumBy from "lodash/sumBy";
import React from "react";
import { useAccountBalances } from "../../../selectors/accountBalancesByDate";
import { useDates } from "../../../selectors/dates";
import { accountBalancesByDateStatus } from "../../../selectors/status";
import { renderArea } from "../../../util/charts-util";
import {
  formatDateWithoutTimeZone,
  getTwoYearStartDate,
  parseDate,
} from "../../../util/date-util";
import ResponsiveTimeSeriesAreaChart from "../../chart/responsive-time-series-area-chart";
import { Modal } from "../../modal/modal";
import { getRoundedValue } from "../../numbers/fixed-decimal";
import { Skeleton } from "../../skeleton/skeleton";

export default function DashboardTotalAccountBalance() {
  const { endDate, lastBusinessDate } = useDates();
  const twoYearsAgo = getTwoYearStartDate(lastBusinessDate!);
  const startDate = formatDateWithoutTimeZone(twoYearsAgo);
  const { accountBalancesByDate } = useAccountBalances(startDate);

  const aggregatedInvestments: BalancePoint[] = accountBalancesByDate.map(
    ({ date, accounts }) => {
      const parsedDate = parseDate(date);
      let total = _sumBy(accounts, "balance");
      total = _round(total, 2);

      return {
        date: parsedDate,
        "Total Balance": total,
      };
    }
  );

  return (
    <div className="col-md-12">
      <div
        className="h3 util-margin-vert-0 util-margin-bottom-15 text-midnight"
        data-testid="track-your-progress"
      >
        Your total balance over time
      </div>
      <Skeleton selectors={[accountBalancesByDateStatus]} height={300}>
        <Chart
          aggregatedInvestments={aggregatedInvestments}
          startDate={startDate}
          endDate={endDate || ""}
        />
      </Skeleton>
      <div className="about-this-chart">
        <Modal
          title="Your total balance over time"
          infoIcon={true}
          linkText="About this chart&nbsp;"
        >
          This is the total combined balance of all your accounts over time.
        </Modal>
      </div>
    </div>
  );
}

type BalancePoint = { date: Date; "Total Balance": number };
type ChartProps = {
  aggregatedInvestments: BalancePoint[];
  startDate: string;
  endDate: string;
};

function Chart(props: ChartProps) {
  const { aggregatedInvestments, startDate, endDate } = props;

  return (
    <ResponsiveTimeSeriesAreaChart
      startDate={startDate}
      endDate={endDate}
      data={aggregatedInvestments}
      formatTooltip={(value: any, name: string) => [
        `$${getRoundedValue(value, 2)}`,
        name,
      ]}
      formatLegend={() => ["Total Balance - All Accounts"]}
      formatDate={(date: string) => formatDateWithoutTimeZone(date, "MMM yyyy")}
    >
      {renderArea("Total Balance", 0, { strokeWidth: 3 })}
    </ResponsiveTimeSeriesAreaChart>
  );
}
