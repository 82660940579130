import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getPersonalInformation } from "../services/salesforce.service";
import { Employee } from "../models/Employee";
import { AsyncStatus } from "../models/async-status";

export const fetchParticipantDetails = createAsyncThunk(
  "fetch-participant-details",
  async (
    { planId, empId, role }: { planId: number; empId: number; role: string },
    thunkApi
  ) => {
    return getPersonalInformation({ planId, empId, role });
  }
);

type State = {
  data: Employee;
  status: AsyncStatus;
};

const initialState: State = {
  data: {} as Employee,
  status: AsyncStatus.not_fetched,
};

const participantDetailsSlice = createSlice({
  name: "participantDetails",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchParticipantDetails.pending, (state, action) => {
      return { ...state, status: AsyncStatus.loading };
    });
    builder.addCase(
      fetchParticipantDetails.fulfilled,
      (state, action: { payload: Employee; type: string }) => {
        return { ...state, data: action.payload, status: AsyncStatus.done };
      }
    );
    builder.addCase(fetchParticipantDetails.rejected, (state, action) => {
      return { ...state, status: AsyncStatus.error };
    });
    builder.addDefaultCase((state, action) => state);
  },
});

export default participantDetailsSlice.reducer;
