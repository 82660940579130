import isFuture from "date-fns/isFuture";
import min from "date-fns/min";
import React, { useEffect, useState } from "react";
import { Skeleton } from "../../../../components/skeleton/skeleton";
import {
  DistributionPayment,
  FutureDistributionPayment,
  FutureDistributionTimelinePayment,
} from "../../../../models/distributions/distribution-payment";
import {
  investmentsStatusSelector,
  scheduledDistributionsStatus,
  scheduledElectionsStatus,
} from "../../../../selectors/status";
import { parseDate } from "../../../../util/date-util";
import { ScheduledDistributionCards } from "../scheduled-distribution-cards";
import ScheduledDistributionTotal from "./scheduled-distribution-total";
import { DistributionTimeline } from "./distribution-timeline";
import "./timeline-wrapper.scss";
import isEqual from "date-fns/isEqual";

type Props = {
  scheduledDistributionPayments: DistributionPayment[];
  generatedDistributionPayments: FutureDistributionPayment[];
  showGeneratedData: Boolean;
};

export default function TimelineWrapper(props: Props) {
  const {
    scheduledDistributionPayments,
    generatedDistributionPayments,
    showGeneratedData,
  } = props;
  const [showMoreDistributions, setShowMoreDistributions] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [futureDistributionsWithDates, setFutureDistributionsWithDates] =
    useState<FutureDistributionTimelinePayment[]>([]);
  const [filteredDistributions, setFilteredDistributions] = useState<
    FutureDistributionTimelinePayment[]
  >([]);
  const [distributions, setDistributions] = useState<
    FutureDistributionTimelinePayment[]
  >([]);
  let headerText = "Scheduled distributions timeline";

  if (
    generatedDistributionPayments !== undefined &&
    generatedDistributionPayments.length > 0 &&
    showGeneratedData
  ) {
    headerText =
      "Scheduled distributions with estimated distributions timeline";
  }

  let combinedDistributionPayments = scheduledDistributionPayments;
  if (showGeneratedData) {
    combinedDistributionPayments = [
      ...combinedDistributionPayments,
      ...generatedDistributionPayments,
    ];
  }

  useEffect(() => {
    const dates: FutureDistributionPayment[] =
      combinedDistributionPayments.filter((distribution) => {
        return distribution.distributionDate;
      });

    function createTimelineDateBasedOnDate(distributionDate: Date): Date {
      return new Date(distributionDate.getFullYear(), 0, 1);
    }

    const withDatePropertyAndColor: FutureDistributionTimelinePayment[] =
      dates.map((distribution) => {
        const {
          distributionDate,
          accountName,
          distributionAmount,
          color,
          accountType,
          accountId,
        } = distribution;
        return new FutureDistributionTimelinePayment(
          parseDate(distributionDate),
          accountName,
          accountId,
          createTimelineDateBasedOnDate(parseDate(distributionDate)),
          distributionAmount,
          color,
          accountType
        );
      });

    const futureDates = dates
      .map(({ distributionDate }) => parseDate(distributionDate))
      .filter(isFuture);

    const minDate = createTimelineDateBasedOnDate(min(futureDates));
    setFutureDistributionsWithDates(withDatePropertyAndColor);
    setSelectedDate(minDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    scheduledDistributionPayments,
    generatedDistributionPayments,
    showGeneratedData,
  ]);

  const startDate = getDateAtStartOfYear();
  const hasScheduledDistributions: boolean =
    futureDistributionsWithDates.length > 0;
  const loadSelectors = [
    scheduledDistributionsStatus,
    investmentsStatusSelector,
    scheduledElectionsStatus,
  ];

  function handleOnClick() {
    setShowMoreDistributions(!showMoreDistributions);
  }

  useEffect(() => {
    if (selectedDate !== undefined) {
      const filteredDistributions = futureDistributionsWithDates.filter(
        (distribution) =>
          isEqual(
            parseDate(distribution.payoutDate).getFullYear(),
            selectedDate.getFullYear()
          )
      );
      setFilteredDistributions(filteredDistributions);
      const distributions = showMoreDistributions
        ? filteredDistributions
        : filteredDistributions.slice(0, 6);
      setDistributions(distributions);
    }
  }, [selectedDate, showMoreDistributions]);

  if (hasScheduledDistributions) {
    return (
      <div
        className="scheduled-distributions-timeline"
        data-testid="timeline-wrapper"
      >
        <Skeleton
          selectors={[scheduledDistributionsStatus]}
          height={26}
          width={150}
        >
          <h2 className="header h3">{headerText}</h2>
        </Skeleton>
        <Skeleton selectors={loadSelectors} height={90}>
          <DistributionTimeline
            setSelectedDate={setSelectedDate}
            selectedDate={selectedDate}
            startDate={startDate}
            data={futureDistributionsWithDates}
          />
        </Skeleton>
        <ScheduledDistributionTotal
          scheduledDistributions={futureDistributionsWithDates}
          selectedDate={selectedDate}
        />
        <div className="scheduled-distribution-cards">
          <Skeleton selectors={loadSelectors} height={229}>
            <ScheduledDistributionCards
              selectedDate={selectedDate}
              scheduledDistributions={futureDistributionsWithDates}
              showMoreDistributions={showMoreDistributions}
            />
          </Skeleton>
        </div>
        {filteredDistributions.length > 6 && distributions.length >= 6 && (
          <div>
            {!showMoreDistributions && (
              <button
                className="btn btn-primary"
                data-testid="view-more-button"
                onClick={() => handleOnClick()}
              >
                <i className="fa fa-angle-down"></i>
                View more distributions
              </button>
            )}
            {showMoreDistributions && (
              <button
                className="btn btn-primary"
                data-testid="view-less-button"
                onClick={() => handleOnClick()}
              >
                <i className="fa fa-angle-up"></i>
                View less distributions
              </button>
            )}
          </div>
        )}
      </div>
    );
  }
  return null;
}

function getDateAtStartOfYear(): Date {
  const today = new Date();
  return new Date(today.getFullYear(), 0, 1);
}
