import React from "react";
import { Skeleton } from "../../../../components/skeleton/skeleton";
import { planInfoStatusSelector } from "../../../../selectors/status";
import { useSelector } from "../../../../store/store";

type Props = {
  children?: React.ReactNode;
};

export function DistributionDisclaimer(props: Props) {
  const { children } = props;
  const { employerContactPhoneNumber, allowDistributionChanges } = useSelector(
    (state) => state.plan.info
  );

  return (
    <>
      {children}
      {allowDistributionChanges && (
        <DistributionElectionChangeDisclaimer
          employerContactPhoneNumber={employerContactPhoneNumber}
        />
      )}
      <p>
        The amounts listed on the website represent a measure of your benefits
        under a nonqualified benefit agreement between you and your plan
        sponsor. It is not a representation that your plan sponsor holds any
        assets for paying these benefits.
      </p>
    </>
  );
}

type DisclaimerProps = {
  employerContactPhoneNumber: string;
};

function DistributionElectionChangeDisclaimer(props: DisclaimerProps) {
  const { employerContactPhoneNumber } = props;
  return (
    <p data-testid={"distribution-election-disclaimer"}>
      Please contact us at{" "}
      <Skeleton
        selectors={[planInfoStatusSelector]}
        height={20}
        width={120}
        count={1}
      >
        {employerContactPhoneNumber}
      </Skeleton>{" "}
      if you would like to make a change to your election(s) for your
      unscheduled qualifying distribution event(s). Please note, not all plans
      allow for changes to distribution elections for each qualifying
      distribution event.
    </p>
  );
}
