import { useSelector } from "../../store/store";
import { messageStatus, useStatuses } from "../../selectors/status";
import { MessageCard } from "./message-card";
import React from "react";
import { Panel, PanelBody, PanelHeading } from "../panel/panel";
import Skeleton from "react-loading-skeleton";

export function MessageWrapper() {
  const messages = useSelector((state) => state.messages.messages);
  const { isLoadingStatus } = useStatuses([messageStatus]);

  if (isLoadingStatus) {
    return (
      <Panel>
        <PanelHeading>
          <Skeleton count={1} />
        </PanelHeading>
        <PanelBody>
          <Skeleton count={5} />
        </PanelBody>
      </Panel>
    );
  }

  return (
    <>
      {messages.map((message, index) => (
        <MessageCard key={`key-${index}`} message={message} index={index} />
      ))}
    </>
  );
}
