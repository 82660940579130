import React from "react";
import { useSelector } from "../../../../store/store";

export interface EstimatedDistributionPlanDisclaimerProps {
  hideEstimatedDistributionsLanguage?: boolean;
}

export default function EstimatedDistributionPlanDisclaimer(
  props: EstimatedDistributionPlanDisclaimerProps
) {
  const { planCode } = useSelector((state) => state.plan.info);
  let planDisclaimer: string | undefined;

  switch (planCode) {
    case "EXCESS": {
      const defaultLanguage =
        "Your actual distribution may be different than the amount(s) listed above.";
      if (props.hideEstimatedDistributionsLanguage) {
        planDisclaimer = defaultLanguage;
      } else {
        planDisclaimer = `The Estimated Distribution amount(s) listed are based upon your current account balance and does not factor in a vesting schedule, if applicable. ${defaultLanguage}`;
      }
      break;
    }
    case "457(F)": {
      planDisclaimer =
        "The Estimated Distribution amount(s) listed are based upon your current account balance and the assumption you will reach your vested date. Your actual distribution may be different than the amount(s) listed above.";
      break;
    }
    case "457(b)": {
      planDisclaimer =
        "The Estimated Distribution amount(s) listed are based upon your current account balance. Your actual distribution may be different than the amount(s) listed above.";
      break;
    }
    case "LTIP": {
      planDisclaimer =
        "The Estimated Distribution amount(s) listed are based upon your current account balance and the assumption you will reach your payout date. Your actual distribution may be different than the amount(s) listed above.";
    }
  }

  if (planDisclaimer) {
    return <p>{planDisclaimer}</p>;
  }
  return null;
}
