export enum AccountOverviewRows {
  VESTED = "Vested balance",
  BEGIN = "Begin balance",
  PRR = "Personalized rate of return",
  GL = "Gain/loss",
  SALARY = "Base salary",
  BONUS = "Bonus",
  AGE = "Retirement age",
  DEPOSIT = "Most recent deposit",
  END = "End balance",
}
