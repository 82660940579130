import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AsyncStatus } from "../models/async-status";
import {
  Beneficiary,
  BeneficiaryWrapper,
  OnlineBeneficiaryPlan,
} from "../models/beneficiaries/beneficiary";
import {
  getBeneficiaries,
  getOnlineBeneficiaryPlans,
  postBeneficiaries,
} from "../services/beneficiary-service";
import _findIndex from "lodash/findIndex";
import {
  buildDefaultOnlineBeneficiaryPlans,
  determineIfPlanAllowsBeneficiaryChanges,
} from "../components/beneficiary/beneficiary-util";

export const fetchBeneficiaries = createAsyncThunk(
  "fetch-beneficiaries",
  async (
    { planId, empId, role }: { planId: number; empId: number; role: string },
    thunkApi
  ) => {
    return getBeneficiaries({ planId, empId, role });
  }
);

export const fetchOnlineBeneficiaryPlans = createAsyncThunk(
  "fetch-online-beneficiary-plans",
  async (
    { empId, role, filter }: { empId: number; role: string; filter: string },
    thunkApi
  ) => {
    return getOnlineBeneficiaryPlans({ empId, role, filter });
  }
);

export const saveBeneficiaries = createAsyncThunk(
  "save-beneficiaries",
  async (
    {
      planId,
      empId,
      role,
      data,
    }: {
      planId: number;
      empId: number;
      role: string;
      data: BeneficiaryWrapper;
    },
    thunkApi
  ) => {
    const { plans } = data;
    const plansWithCurrentPlanID = [...plans, planId];
    return postBeneficiaries({
      planId,
      empId,
      role,
      data: { ...data, plans: plansWithCurrentPlanID },
    });
  }
);

export type BeneficiaryState = {
  beneficiaries: Array<Beneficiary>;
  beneficiaryStatus: AsyncStatus;
  plansStatus: AsyncStatus;
  postStatus: AsyncStatus;
  onlineBeneficiaryPlans: OnlineBeneficiaryPlan[];
  beneficiarySnapshot: Array<Beneficiary>;
  editing: boolean;
  hasChanged: boolean;
  hasAddedOrDeleted: boolean;
  canEditBeneficiaries: boolean;
};

const initialState: BeneficiaryState = {
  beneficiaries: [],
  beneficiaryStatus: AsyncStatus.not_fetched,
  plansStatus: AsyncStatus.not_fetched,
  postStatus: AsyncStatus.not_fetched,
  onlineBeneficiaryPlans: [],
  beneficiarySnapshot: [],
  editing: false,
  hasChanged: false,
  hasAddedOrDeleted: false,
  canEditBeneficiaries: false,
};

const beneficiariesSlice = createSlice({
  name: "beneficiaries",
  initialState,
  reducers: {
    resetBeneficiaryUpdate: (state) => {
      state.onlineBeneficiaryPlans.forEach((plan: OnlineBeneficiaryPlan) => {
        plan.selected = false;
      });
      state.hasChanged = false;
      state.beneficiarySnapshot = [];
    },
    startEditing: (state) => {
      return {
        ...state,
        beneficiarySnapshot: [...state.beneficiaries],
        editing: true,
      };
    },
    cancelEdit: (state) => {
      return {
        ...state,
        beneficiaries: [...state.beneficiarySnapshot],
        beneficiarySnapshot: [],
        editing: false,
        hasChanged: false,
        hasAddedOrDeleted: false,
      };
    },
    completeEdit: (state) => {
      return {
        ...state,
        beneficiarySnapshot: [...state.beneficiaries],
        editing: false,
        hasChanged: false,
      };
    },
    resetPostStatus: (state) => {
      return { ...state, postStatus: AsyncStatus.not_fetched };
    },
    setIsEditable: (state, action: { payload: number; type: string }) => {
      const planId = action.payload;
      const canEditBeneficiaries = determineIfPlanAllowsBeneficiaryChanges(
        planId,
        state.onlineBeneficiaryPlans
      );
      return {
        ...state,
        canEditBeneficiaries,
      };
    },
    updateOnlineBeneficiaryPlans: (
      state,
      action: { payload: OnlineBeneficiaryPlan[]; type: string }
    ) => {
      const { payload } = action;
      return {
        ...state,
        onlineBeneficiaryPlans: payload,
      };
    },
    updateBeneficiary: (
      state,
      action: { payload: Beneficiary; type: string }
    ) => {
      const { payload } = action;
      const updatedBeneficiaries = [...state.beneficiaries];
      const index = _findIndex(updatedBeneficiaries, { guid: payload.guid });
      const isNewBeneficiary = index === -1;
      if (isNewBeneficiary) {
        return {
          ...state,
          beneficiaries: [...state.beneficiaries, payload],
          hasAddedOrDeleted: true,
          hasChanged: true,
        };
      }
      updatedBeneficiaries.splice(index, 1, payload);
      return {
        ...state,
        beneficiaries: updatedBeneficiaries,
        hasChanged: true,
      };
    },
    deleteBeneficiary: (state, action: { payload: string; type: string }) => {
      const beneficiaries = state.beneficiaries.filter(
        (beneficiary: Beneficiary) => {
          return beneficiary.guid !== action.payload;
        }
      );
      return {
        ...state,
        beneficiaries,
        hasAddedOrDeleted: true,
        hasChanged: true,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(saveBeneficiaries.pending, (state, action) => {
      return { ...state, postStatus: AsyncStatus.loading };
    });
    builder.addCase(saveBeneficiaries.fulfilled, (state) => {
      return {
        ...state,
        postStatus: AsyncStatus.done,
      };
    });
    builder.addCase(saveBeneficiaries.rejected, (state, action) => {
      return { ...state, postStatus: AsyncStatus.error };
    });
    builder.addCase(fetchBeneficiaries.pending, (state, action) => {
      return { ...state, beneficiaryStatus: AsyncStatus.loading };
    });
    builder.addCase(
      fetchBeneficiaries.fulfilled,
      (state, action: { payload: Beneficiary[]; type: string }) => {
        const beneficiaries = action.payload;

        return {
          ...state,
          beneficiaries: beneficiaries,
          beneficiaryStatus: AsyncStatus.done,
        };
      }
    );
    builder.addCase(fetchBeneficiaries.rejected, (state, action) => {
      return { ...state, beneficiaryStatus: AsyncStatus.error };
    });
    builder.addCase(fetchOnlineBeneficiaryPlans.pending, (state, action) => {
      return { ...state, plansStatus: AsyncStatus.loading };
    });
    builder.addCase(
      fetchOnlineBeneficiaryPlans.fulfilled,
      (state, action: { payload: any[]; type: string }) => {
        const plans = action.payload;
        const onlineBeneficiaryPlans =
          buildDefaultOnlineBeneficiaryPlans(plans);
        return {
          ...state,
          onlineBeneficiaryPlans,
          plansStatus: AsyncStatus.done,
        };
      }
    );
    builder.addCase(fetchOnlineBeneficiaryPlans.rejected, (state, action) => {
      return { ...state, plansStatus: AsyncStatus.error };
    });
    builder.addDefaultCase((state, action) => state);
  },
});

export const {
  updateOnlineBeneficiaryPlans,
  updateBeneficiary,
  deleteBeneficiary,
  resetPostStatus,
  startEditing,
  cancelEdit,
  completeEdit,
  setIsEditable,
  resetBeneficiaryUpdate,
} = beneficiariesSlice.actions;
export default beneficiariesSlice.reducer;
