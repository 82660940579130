import React from "react";
import InfoCircle from "../info-circle/InfoCircle";
import "./openEventRect.scss";
import { ParticipantEvent } from "../../models/ParticipantEvent";

const OpenEventRect = () => {
  type Props = {
    event: ParticipantEvent;
  };

  return (
    <div className="pds-card pds-productCard">
      <div className="last-event">
        <div className="pds-card-section">
          <div className="row">
            <p className="pds-typography-body-14" style={{ textAlign: "left" }}>
              No remaining open events.
            </p>
            <p className="pds-typography-body-14" style={{ textAlign: "left" }}>
              You have already confirmed all events available or no other open
              events are available at this time.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OpenEventRect;
