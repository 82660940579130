import { RootState } from "../store/store";

export const unscheduledDistributionEventsSelector = (state: RootState) =>
  state.distributions.unscheduledDistributionEvents.availableUnscheduledEvents;

export const unscheduledDistributionEventsForAllProvisionsSelector = (
  state: RootState
) =>
  state.distributions.unscheduledDistributionEventsByProvision
    .availableEventsForAllProvisions;
