import React, { useEffect, useState } from "react";
import {
  DistributionPayment,
  FutureDistributionPayment,
} from "../../../../models/distributions/distribution-payment";
import {
  accountsWithUnscheduledDistributionsSelector,
  accountsWithUnscheduledDistributionsSelectorNotFiltered,
} from "../../../../selectors/account-distributions";
import { useSelector } from "../../../../store/store";
import { exportFile } from "./excel-util";

type Props = {
  scheduledDistributionPayments: DistributionPayment[];
  generatedDistributionPayments: FutureDistributionPayment[];
  showPaidOutAccounts: boolean;
};

export default function DistributionExcelDownloadButton(props: Props) {
  const {
    scheduledDistributionPayments,
    generatedDistributionPayments,
    showPaidOutAccounts,
  } = props;

  const { empId, planId, role } = useSelector((state) => state.session);
  const selectedAccounts = useSelector((state) => state.accounts.selected);
  const filteredAccounts = useSelector(
    accountsWithUnscheduledDistributionsSelector
  );
  const unfilteredAccounts = useSelector(
    accountsWithUnscheduledDistributionsSelectorNotFiltered
  );
  const [accounts, setAccounts] = useState(filteredAccounts);

  useEffect(() => {
    if (showPaidOutAccounts) {
      setAccounts(unfilteredAccounts);
    } else {
      setAccounts(filteredAccounts);
    }
  }, [showPaidOutAccounts, selectedAccounts]);

  const selectedAccountIds: number[] = selectedAccounts.map(
    (account) => account.id
  );

  return (
    <div>
      <button
        type="button"
        className="btn btn-primary"
        style={{display: "none"}}
        onClick={() => exportFile(planId, empId, role, selectedAccountIds)}
      >
        Download Excel
      </button>
    </div>
  );
}
